import { Stack, Paper, Typography } from "@mui/material"

const CustomPaper = ({ title, subtitle, children, actionBar, padding=2, sx }) =>{

    return(
        <Paper sx={sx}>
            <Stack sx={{ height: '100%', width: '100%' }}>
                <Stack
                    alignItems="center"
                    justifyContent="space-between"
                    direction='row'
                    gap={3}
                    sx={{ minHeight: 60, pl:2, pr:2, pt:2 , pb:0}}
                >
                    {(title || subtitle) &&
                        <Stack>
                            <Typography variant="title_paper">{title}</Typography>
                            <Typography variant="text_m" color="primary.main">{subtitle}</Typography>
                        </Stack>
                    }
                    {actionBar}
                </Stack>
                <Stack sx={{ width: '100%', p: padding, pt:2, flexGrow: 1 }}>
                    {children}
                </Stack>
            </Stack>
        </Paper>
    )
}

export default CustomPaper
