import { PALETTE } from "components/theme";



const LogoFullWhite = ({ width=300, height=200 }) => {

    return (
        <svg
            width={width}
            height={height}
            viewBox="0 0 857 175"
            version="1.1"
            xmlns="http://www.w3.org/2000/svg"
            xmlnsXlink="http://www.w3.org/1999/xlink"
            xmlSpace="preserve"
            xmlnsserif="http://www.serif.com/"
            style={{
                fillRule: 'evenodd',
                clipRule: 'evenodd',
                strokeLinejoin: 'round',
                strokeMiterlimit: 2,
            }}
        >
            <g>
                <path
                    d="M-0,98.126C-0,88.142 2.088,79.238 6.264,71.414C10.44,63.59 16.368,57.494 24.048,53.126C31.728,48.758 40.56,46.574 50.544,46.574C63.216,46.574 73.872,49.958 82.512,56.726C91.152,63.494 96.72,72.686 99.216,84.302L68.832,84.302C67.008,80.462 64.488,77.534 61.272,75.518C58.056,73.502 54.336,72.494 50.112,72.494C43.584,72.494 38.376,74.822 34.488,79.478C30.6,84.134 28.656,90.35 28.656,98.126C28.656,105.998 30.6,112.262 34.488,116.918C38.376,121.574 43.584,123.902 50.112,123.902C54.336,123.902 58.056,122.894 61.272,120.878C64.488,118.862 67.008,115.934 68.832,112.094L99.216,112.094C96.72,123.71 91.152,132.902 82.512,139.67C73.872,146.438 63.216,149.822 50.544,149.822C40.56,149.822 31.728,147.638 24.048,143.27C16.368,138.902 10.44,132.806 6.264,124.982C2.088,117.158 -0,108.206 -0,98.126Z"
                    style={{
                        fill: PALETTE.background.content,
                        fillRule: 'nonzero'
                    }}
                />
                <path
                    d="M161.28,150.11C151.776,150.11 143.04,147.878 135.072,143.414C127.104,138.95 120.792,132.758 116.136,124.838C111.48,116.918 109.152,107.966 109.152,97.982C109.152,87.998 111.48,79.046 116.136,71.126C120.792,63.206 127.104,57.038 135.072,52.622C143.04,48.206 151.776,45.998 161.28,45.998C170.88,45.998 179.64,48.206 187.56,52.622C195.48,57.038 201.744,63.206 206.352,71.126C210.96,79.046 213.264,87.998 213.264,97.982C213.264,107.966 210.96,116.918 206.352,124.838C201.744,132.758 195.456,138.95 187.488,143.414C179.52,147.878 170.784,150.11 161.28,150.11ZM161.28,123.902C168.48,123.902 174.168,121.55 178.344,116.846C182.52,112.142 184.608,105.854 184.608,97.982C184.608,89.918 182.52,83.558 178.344,78.902C174.168,74.246 168.48,71.918 161.28,71.918C153.984,71.918 148.272,74.246 144.144,78.902C140.016,83.558 137.952,89.918 137.952,97.982C137.952,105.95 140.016,112.262 144.144,116.918C148.272,121.574 153.984,123.902 161.28,123.902Z"
                    style={{
                        fill: PALETTE.background.content,
                        fillRule: 'nonzero'
                    }}
                />
                <path
                    d="M319.968,149.102L291.744,149.102L254.16,92.366L254.16,149.102L225.936,149.102L225.936,47.582L254.16,47.582L291.744,105.038L291.744,47.582L319.968,47.582L319.968,149.102Z"
                    style={{
                        fill: PALETTE.background.content,
                        fillRule: 'nonzero'
                    }}
                />
                <g>
                    <path
                        d="M404.266,95.789L460.824,63.248C464.845,60.943 466.507,55.957 464.63,51.668L462.754,47.38C461.843,45.021 462.272,43.627 464.899,43.144L480.606,39.821C482.965,39.285 483.018,37.569 481.142,36.926L455.142,27.544C454.337,27.276 453.694,26.633 453.372,25.882L444.473,5.511C442.275,0.525 436.217,-1.512 431.5,1.222L374.942,33.763C370.921,36.068 369.259,41.054 371.136,45.342L391.239,91.5C393.491,96.539 399.495,98.523 404.266,95.789Z"
                        style={{
                            fill: 'url(#_Linear1)',
                            fillRule: 'nonzero'
                        }}
                    />
                    <path
                        d="M458.733,73.219L407.536,102.651C398.423,108.065 397.351,111.121 397.351,120.288L397.351,167.036C397.351,172.289 403.033,175.56 407.536,172.933L436.539,156.207C437.182,155.885 437.987,156.099 438.469,156.528C445.492,163.069 459.806,162.211 465.595,161.192C466.292,161.085 466.4,160.067 465.703,159.798C458.251,156.796 457.018,151.221 457.018,145.592C457.018,144.895 457.447,144.252 458.09,143.876L458.733,143.501C466.185,139.212 468.866,135.996 468.866,125.757L468.866,79.009C468.919,73.863 463.29,70.592 458.733,73.219Z"
                        style={{
                            fill: 'url(#_Linear2)',
                            fillRule: 'nonzero'
                        }}
                    />
                    <g>
                        <path
                            d="M360.789,50.435C361.272,50.435 361.808,50.864 362.076,51.454L381.161,95.306L381.268,95.414C385.825,104.527 386.629,107.851 386.629,117.876L386.629,168.269C386.629,170.038 385.128,171.11 383.787,171.11C383.305,171.11 382.823,171.003 382.394,170.735L362.397,159.209C361.593,158.726 360.682,158.512 359.77,158.512C358.162,158.512 356.715,159.209 355.696,160.442C353.498,163.069 350.657,165.213 347.869,166.768C349.585,162.747 349.906,158.673 349.96,155.295C349.96,153.258 348.995,151.435 347.333,150.47L341.114,146.879C340.257,146.396 339.721,145.431 339.721,144.413L339.721,90.16C339.721,84.531 339.721,80.832 342.079,77.562L342.187,77.454L359.878,50.971C359.985,50.703 360.36,50.435 360.789,50.435M360.789,47.219C359.556,47.219 358.269,47.755 357.197,48.988L339.399,75.632C336.397,79.76 336.397,84.263 336.397,90.106L336.397,144.413C336.397,146.557 337.576,148.594 339.399,149.666L345.618,153.258C346.315,153.687 346.636,154.438 346.636,155.242C346.583,160.12 345.886,165.856 341.329,171.003C340.417,172.021 340.9,172.558 341.865,172.558C342.294,172.558 342.776,172.45 343.366,172.289C347.011,171.056 353.552,167.947 358.055,162.479C358.484,161.996 359.074,161.728 359.663,161.728C360.039,161.728 360.36,161.836 360.682,161.996L380.678,173.522C381.643,174.059 382.662,174.327 383.68,174.327C386.843,174.327 389.738,171.807 389.738,168.269L389.738,117.876C389.738,107.529 388.827,103.562 384.056,94.02L365.024,50.221C364.22,48.291 362.558,47.219 360.789,47.219Z"
                            style={{
                                fill: '#6F778F',
                                fillOpacity: 0.50,
                                fillRule: 'nonzero'
                            }}
                        />
                    </g>
                </g>
                <path
                    d="M606.411,47.59L606.411,149.11L578.187,149.11L578.187,93.094L559.035,149.11L535.419,149.11L516.123,92.662L516.123,149.11L487.899,149.11L487.899,47.59L522.027,47.59L547.515,113.542L572.427,47.59L606.411,47.59Z"
                    style={{
                        fill: PALETTE.background.content,
                        fillRule: 'nonzero'
                    }}
                />
                <path
                    d="M650.763,70.198L650.763,86.758L683.163,86.758L683.163,108.214L650.763,108.214L650.763,126.502L687.483,126.502L687.483,149.11L622.539,149.11L622.539,47.59L687.483,47.59L687.483,70.198L650.763,70.198Z"
                    style={{
                        fill: PALETTE.background.content,
                        fillRule: 'nonzero'
                    }}
                />
                <path
                    d="M729.675,70.198L729.675,86.758L762.075,86.758L762.075,108.214L729.675,108.214L729.675,126.502L766.395,126.502L766.395,149.11L701.451,149.11L701.451,47.59L766.395,47.59L766.395,70.198L729.675,70.198Z"
                    style={{
                        fill: PALETTE.background.content,
                        fillRule: 'nonzero'
                    }}
                />
                <path
                    d="M856.827,47.59L856.827,70.054L829.755,70.054L829.755,149.11L801.531,149.11L801.531,70.054L774.747,70.054L774.747,47.59L856.827,47.59Z"
                    style={{
                        fill: PALETTE.background.content,
                        fillRule: 'nonzero'
                    }}
                />
            </g>
            <defs>
                <linearGradient
                    id="_Linear1"
                    x1="0"
                    y1="0"
                    x2="1"
                    y2="0"
                    gradientUnits="userSpaceOnUse"
                    gradientTransform="matrix(104.508,-129.24,129.24,104.508,401.89,87.1633)"
                >
                    <stop
                        offset="0"
                        style={{
                            stopColor: 'rgb(231,164,92)',
                            stopOpacity: 1
                        }}
                    />
                    <stop
                        offset="0.48"
                        style={{
                            stopColor: 'rgb(207,107,1)',
                            stopOpacity: 1
                        }}
                    />
                    <stop
                        offset="1"
                        style={{
                            stopColor: 'rgb(207,107,1)',
                            stopOpacity: 1
                        }}
                    />
                </linearGradient>
                <linearGradient
                    id="_Linear2"
                    x1="0"
                    y1="0"
                    x2="1"
                    y2="0"
                    gradientUnits="userSpaceOnUse"
                    gradientTransform="matrix(-98.5002,-40.3303,40.3303,-98.5002,468.866,137.351)"
                >
                    <stop
                        offset="0"
                        style={{
                            stopColor: 'rgb(0,121,117)',
                            stopOpacity: 1
                        }}
                    />
                    <stop
                        offset="1"
                        style={{
                            stopColor: 'rgb(0,173,167)',
                            stopOpacity: 1
                        }}
                    />
                </linearGradient>
            </defs>
        </svg>

    );
}

export default LogoFullWhite;