import { useContext, useState, useEffect } from 'react'
import { useForm } from 'react-hook-form'

import { Grid } from '@mui/material'

import useStyledSnackbar from '../../../hooks/useStyledSnackbar'

import { getCookie } from '../../../utils/getCookie'

import UserContext from '../../../context/UserContext'

import ControlledTextField from '../../Widgets/ControlledInputs/ControlledTextField'
import CustomFormPaper from '../../Widgets/CustomFormPaper'


const ChangeUser = () => {
    const [user] = useContext(UserContext)

    const [loading, setLoading] = useState(false)
    const [success, setSuccess] = useState(false)

    const { handleSubmit, setError, control, formState: { errors, isDirty, isValid }} = useForm({
        mode: 'onChange',
        defaultValues: {
            username: user.username,
            email: user.email
        }
    })

    const { enqueueSnackbar } = useStyledSnackbar()

    const onSubmit = async data => {
        setLoading(true)
        const res = await fetch('/account/', {
            method: 'PATCH',
            headers: {
                'Content-Type': 'application/json',
                'X-CSRFToken': getCookie('csrftoken'),
            },
            body: JSON.stringify(data)
        })
        const json = await res.json()
        if (res.ok) {
            setLoading(false)
            setSuccess(true)
            enqueueSnackbar('Ihre Nutzerdaten wurden erfolgreich aktualisiert!', {
                variant: 'success'
            })
        } else {
            Object.entries(json).forEach(([field, message]) => {
                console.log(field)
                console.log(message)
                setError(field, {
                    type: 'server',
                    message: message
                })
            })
            setLoading(false)
        }
    }

    useEffect(() => {
        setSuccess(false);
    }, [isDirty])

    return (
        <CustomFormPaper
            title="Benutzername &amp; Email"
            onSubmit={handleSubmit(onSubmit)}
            tooltip={'Speichern'}
            success={success}
            loading={loading}
            disabled={!isDirty || !isValid}
        >
            <Grid container spacing={2}>
                <Grid item mobile={12} laptop={6}>
                    <ControlledTextField
                        control={control}
                        name='username'
                        rules={{ required: true }}
                        type='text'
                        label='Benutzername'
                        errors={errors}
                    />
                </Grid>
                <Grid item mobile={12} laptop={6}>
                    <ControlledTextField
                        control={control}
                        name='email'
                        rules={{ required: true }}
                        type='text'
                        label='E-Mail'
                        errors={errors}
                    />
                </Grid>
            </Grid>
        </CustomFormPaper>
    )
}

export default ChangeUser