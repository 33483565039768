import {FormControlLabel, FormGroup, Switch} from "@mui/material";
import {getCookie} from "../../../../../utils/getCookie";

const Outdated = ({ document, getUploads }) => {
    const handleOutdatedChange = async e => {
        const res = await fetch('/upload/' + document.id, {
            method: 'PATCH',
            headers: {
                'Content-Type': 'application/json',
                'X-CSRFToken': getCookie('csrftoken')
            },
            body: JSON.stringify({ outdated: e.target.checked })
        })

        if (res.ok) {
            getUploads()
        }
    }

    return (
        <FormGroup>
            <FormControlLabel
                control={
                    <Switch
                        checked={document.outdated}
                        onChange={handleOutdatedChange}
                    />
                }
                label="Veraltet"
            />
        </FormGroup>
    )
}

export default Outdated