import { useState, useContext } from 'react';
import { Navigate } from 'react-router-dom';
import { Grid, Stack } from "@mui/material";

import ProjectContext from '../../../../context/ProjectContext';

import ProjectChangeLead from './ProjectChangeLead';
import ProjectEditForm from './ProjectEditForm';
import ProjectOptionForm from './ProjectOptionForm';
import ProjectDelete from './ProjectDelete';
import ProjectDone from './ProjectDone';



const ProjectSettings = () => {

    const [project,, projectTeamMembers,, getProject] = useContext(ProjectContext)

    const [redirect, setRedirect] = useState(false)

    if(redirect){
        return(
            <Navigate to='/dashboard'/>
        )
    }

    return(
        <Grid
            container
            justifyContent="flex-start"
            alignItems="flex-start"
            spacing={3}
        >
            <Grid
                item
                desktop={8}
                laptop={8}
                tablet={12}
                mobil={12}

            >
                <Stack spacing={3}>
                    <ProjectEditForm
                        project={project}
                        getProject={getProject}
                    />
                    <ProjectChangeLead
                        project={project}
                        projectTeamMembers={projectTeamMembers}
                        setRedirect={setRedirect}
                    />
                </Stack>
            </Grid>
            <Grid
                item
                desktop={4}
                laptop={4}
                tablet={12}
                mobil={12}

            >
                <Stack spacing={3}>
                    <ProjectOptionForm
                        project={project}
                        getProject={getProject}
                    />
                    <ProjectDone
                        project={project}
                        projectTeamMembers={projectTeamMembers}
                        getProject={getProject}
                        setRedirect={setRedirect}
                    />
                    <ProjectDelete
                        project={project}
                        projectTeamMembers={projectTeamMembers}
                        getProject={getProject}
                        setRedirect={setRedirect}
                    />
                </Stack>
            </Grid>
        </Grid>
    )
}

export default ProjectSettings