import { useState } from "react";
import { getCookie } from "../../../../utils/getCookie";
import { useGridApiRef } from '@mui/x-data-grid-pro';

import TimestampToolbar from './TimestampToolbar';

import { checkSelectionZero, convertDictToValuesAndStringSelection, formatNumber } from '../../../../utils/helper';
import { DateColumn, TimeColumn } from '../../../Widgets/CustomDatagrids/CustomColumns'

import PaperDataGrid from "../../../Widgets/CustomDatagrids/NoPagination/PaperDataGrid";


const TimestampsTable = (props) =>{

	const { company_member_id, selectedDate, rows, is_manager, handleUpdate, timestampTypeKeys, projectKeys, isLoading, hideAllConfirm } = props

	const apiRef = useGridApiRef();
	const [errors, setErrors] = useState({});

	const handleEditTimestamp = async(row) =>{

        const json = JSON.stringify({
			...row,
			company_member_id: company_member_id,
			sub_project_key: checkSelectionZero(row.sub_project_key),
		})

        const res = await fetch('/api/time-recording', {
            method: 'PATCH',
            headers: {
                'Content-Type': 'application/json',
                'X-CSRFToken': getCookie('csrftoken')
            },
            body: json
        })
		const data = await res.json()
		if(res.ok){
			apiRef.current.updateRows([{ ...row, isNew: false }]);
			setErrors({})
			handleUpdate()
		} else{
			setErrors(data)
			// console.log(data)
		}
    }

	const handleDelete = async(rowId) => {

        const json = JSON.stringify({
			timestamp_id: rowId
		})
        const res = await fetch('/api/time-recording', {
            method: 'DELETE',
            headers: {
                'Content-Type': 'application/json',
                'X-CSRFToken': getCookie('csrftoken')
            },
            body: json
        })
		if(res.ok){
			handleUpdate()
		}
		return res.ok
    }

	const columns = [
		{
			field: 'confirmed',
			headerName: 'Bestätigt',
			type: 'boolean',
			minWidth: 90,
			editable: is_manager ? true : false,
		},
		DateColumn({
			field: 'date',
			headerName: 'Datum',
			minWidth: 200,
			editable: true,
		}),
		TimeColumn({
			field: 'start',
			headerName: 'Beginn',
			minWidth: 120,
			editable: true,
		}),
		TimeColumn({
			field: 'end',
			headerName: 'Ende',
			minWidth: 120,
			editable: true,
		}),
		{
			field: 'workload_gross',
			headerName: 'Bruttozeit',
			type: 'number',
			minWidth: 100,
			editable: false,
		},
		{
			field: 'breaktime',
			headerName: 'Pause',
			type: 'number',
			minWidth: 100,
			editable: true,
			valueFormatter: ({value}) => formatNumber(value, 0, 'min.')
		},
		{
			field: 'workload_net',
			headerName: 'Nettozeit',
			type: 'number',
			minWidth: 100,
			editable: false,
		},
		{
			field: 'type_key',
			headerName: 'Stundentyp',
			type: 'singleSelect',
			minWidth: 120,
			editable: true,
			valueOptions: convertDictToValuesAndStringSelection(timestampTypeKeys),
			valueFormatter: ({ value }) => {
				return timestampTypeKeys[value]
			},
		},
		{
			field: 'sub_project_key',
			headerName: 'Zuweisung',
			type: 'singleSelect',
			minWidth: 120,
			editable: true,
			valueOptions: convertDictToValuesAndStringSelection(projectKeys),
			valueFormatter: ({ value }) => {
				return projectKeys[value]
			},
		},
		{
			field: 'with_drive_rate',
			headerName: 'Fahrtkostenpauschale',
			type: 'boolean',
			minWidth: 20,
			editable: true,
		},
		{
			field: 'description',
			headerName: 'Beschreibung',
			type: 'string',
			flex:'1',
			minWidth: 100,
			editable: true,
		},
	];

	const getConfirmMessage = row => `Möchtest du die Zeiteintragung wirklich löschen?`

	return(
		<PaperDataGrid
			title={`Alle Zeiteinträge im ${selectedDate.toLocaleString('de-DE', { month: 'long', year: 'numeric'})}`}
			rows={rows}
			columns={columns}
			apiRef={apiRef}
			actionBar={
					<TimestampToolbar
						apiRef={apiRef}
						company_member_id={company_member_id}
						selectedDate={selectedDate}
						is_manager={is_manager}
						projectKeys={projectKeys}
                        timestampTypeKeys={timestampTypeKeys}
                        handleUpdate={handleUpdate}
						hideAllConfirm={hideAllConfirm}
					/>
			}
			loading={isLoading}
			errors={errors}
			onSaveRow={handleEditTimestamp}
			onDeleteRow={handleDelete}
			getConfirmMessage={getConfirmMessage}
		/>
	)
}

export default TimestampsTable