import { Stack } from '@mui/material'

const CustomTabPanel = (props) => {

    const { children, value, index, hasSubTabs, sx, ...other } = props;
    return (
        <Stack
            className='custom-tab-panel'
            role="tabpanel"
            hidden={value !== index}
            id={`vertical-tabpanel-${index}`}
            aria-labelledby={`vertical-tab-${index}`}
            flexDirection='column'
            sx={{
                // flex: '1 1 0',
                display: value === index ? 'inherit' : 'none',
                // overflowX: 'initial',
                // overflowY: 'auto',
                // py: hasSubTabs ? 0 : 2,
                // scrollbarGutter: hasSubTabs ? 'unset' : 'stable both-edges',
                ...sx
            }}
            {...other}
        >
            {value === index && children}
        </Stack>
    );
}

export default CustomTabPanel