import { useState, useEffect } from 'react'

import { getConstants } from '../../utils/getConstants';

import { Stack, Typography, Grid,  Divider, useMediaQuery } from '@mui/material';

import ControlledTextField from '../Widgets/ControlledInputs/ControlledTextField';
import ControlledSelect from '../Widgets/ControlledInputs/ControlledSelect';

const RegisterForm = ({ registrationType, control, errors }) =>{

    const [genders, setgenders]=  useState({})
    const callback_genders = (c) =>{setgenders(c)}
    useEffect(() =>{getConstants('genders', callback_genders)},[])

    const [industries, setIndustries]=  useState({})
    const callback_industries = (c) =>{setIndustries(c)}
    useEffect(() =>{getConstants('industries', callback_industries)},[])

    const compact = useMediaQuery('(max-width: 850px)');

    return(
        <Stack
            spacing={2}
        >
            <Stack
                direction="column"
                spacing={2}
                width="100%"
            >
                <Typography
                    variant="text_m_justify"
                    sx={{ pt: 2}}
                >
                    Du meldest dich gerade als <strong>{registrationType.type}</strong> an. Trage bitte deine Daten ein.
                </Typography>
                <Stack>
                    <Grid
                        container
                        spacing={1}
                    >
                        <Grid
                            item
                            desktop={6}
                            laptop={6}
                            tablet={12}
                            mobile={12}
                        >
                            <ControlledTextField
                                name="firstname"
                                label="Vorname"
                                control={control}
                                rules={{ required: true }}
                                errors={errors}
                            />
                        </Grid>
                        <Grid
                            item
                            desktop={6}
                            laptop={6}
                            tablet={12}
                            mobile={12}
                        >
                            <ControlledTextField
                                name="lastname"
                                label="Nachname"
                                control={control}
                                rules={{ required: true }}
                                errors={errors}
                            />
                        </Grid>
                        <Grid
                            item
                            desktop={8}
                            laptop={8}
                            tablet={12}
                            mobile={12}
                        >
                            <ControlledTextField
                                name="username"
                                label="Benutzername"
                                control={control}
                                rules={{ required: true }}
                                errors={errors}
                            />
                        </Grid>
                        <Grid
                            item
                            desktop={4}
                            laptop={4}
                            tablet={12}
                            mobile={12}
                        >
                            <ControlledSelect
                                name="gender_key"
                                label="Geschlecht"
                                control={control}
                                rules={{ required: true }}
                                errors={errors}
                                items={genders}
                            />
                        </Grid>
                        <Grid
                            item
                            desktop={6}
                            laptop={6}
                            tablet={12}
                            mobile={12}
                        >
                            <ControlledTextField
                                name="email"
                                label="E-Mail"
                                control={control}
                                rules={{ required: true }}
                                errors={errors}
                            />
                        </Grid>
                        <Grid
                            item
                            desktop={6}
                            laptop={6}
                            tablet={12}
                            mobile={12}
                        >
                            <ControlledTextField
                                name="phone_number"
                                label="Telefonnummer (Arbeitstelefon)"
                                control={control}
                                rules={{ required: true }}
                                errors={errors}
                            />
                        </Grid>
                        <Grid
                            item
                            desktop={6}
                            laptop={6}
                            tablet={12}
                            mobile={12}
                        >
                            <ControlledTextField
                                name="password"
                                label="Passwort"
                                control={control}
                                rules={{ required: true }}
                                errors={errors}
                                type="password"
                            />
                        </Grid>
                        <Grid
                            item
                            desktop={6}
                            laptop={6}
                            tablet={12}
                            mobile={12}
                        >
                            <ControlledTextField
                                name="password2"
                                label="Passwort wiederholen"
                                control={control}
                                rules={{ required: true }}
                                errors={errors}
                                type="password"
                            />
                        </Grid>
                    </Grid>
                    {registrationType.type === 'Unternehmen' && <Divider variant="middle" sx={{mt:1, mb: 1.5}}/>}
                    <Grid
                        container
                        spacing={1}
                    >
                        {registrationType.type === 'Unternehmen' &&
                            <>
                                <Grid
                                    item
                                    desktop={6}
                                    laptop={6}
                                    tablet={12}
                                    mobile={12}
                                >
                                    <ControlledTextField
                                        name="name"
                                        label="Name des Unternehmens"
                                        control={control}
                                        rules={{ required: true }}
                                        errors={errors}
                                    />
                                </Grid>
                                <Grid
                                    item
                                    desktop={6}
                                    laptop={6}
                                    tablet={12}
                                    mobile={12}
                                >
                                    <ControlledSelect
                                        name="industry_key"
                                        label="Branche"
                                        control={control}
                                        rules={{ required: true }}
                                        errors={errors}
                                        items={industries}
                                    />
                                </Grid>
                                <Grid
                                    item
                                    desktop={12}
                                    laptop={12}
                                    tablet={12}
                                    mobile={12}
                                >
                                    <ControlledTextField
                                        name="central_phone_number"
                                        label="Telefon"
                                        control={control}
                                        rules={{ required: true }}
                                        errors={errors}
                                    />
                                </Grid>
                                <Grid
                                    item
                                    desktop={8}
                                    laptop={8}
                                    tablet={12}
                                    mobile={12}
                                >
                                    <ControlledTextField
                                        name="street"
                                        label="Straße"
                                        control={control}
                                        rules={{ required: true }}
                                        errors={errors}
                                    />
                                </Grid>
                                <Grid
                                    item
                                    desktop={4}
                                    laptop={4}
                                    tablet={12}
                                    mobile={12}
                                >
                                    <ControlledTextField
                                        name="house_number"
                                        label="Hausnr."
                                        control={control}
                                        rules={{ required: true }}
                                        errors={errors}
                                    />
                                </Grid>
                                <Grid
                                    item
                                    desktop={12}
                                    laptop={12}
                                    tablet={12}
                                    mobile={12}
                                >
                                    <ControlledTextField
                                        name="add_on"
                                        label="Zusatz"
                                        control={control}
                                        errors={errors}
                                    />
                                </Grid>
                                <Grid
                                    item
                                    desktop={4}
                                    laptop={4}
                                    tablet={12}
                                    mobile={12}
                                >
                                    <ControlledTextField
                                        name="postal_code"
                                        label="PLZ"
                                        control={control}
                                        rules={{ required: true }}
                                        errors={errors}
                                    />
                                </Grid>
                                <Grid
                                    item
                                    desktop={8}
                                    laptop={8}
                                    tablet={12}
                                    mobile={12}
                                >
                                    <ControlledTextField
                                        name="city"
                                        label="Stadt"
                                        control={control}
                                        rules={{ required: true }}
                                        errors={errors}
                                    />
                                </Grid>
                            </>
                        }
                    </Grid>
                </Stack>
            </Stack>
        </Stack>
    )
}

export default RegisterForm
