import './AnimationLogo.css'


export const AnimationLogo = () => {

        return(
            <svg id="svg" width="15%" height="15%" viewBox="-10 -25 273 400" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" xmlSpace="preserve" xmlnsserif="http://www.serif.com/" >
                <g  transform="matrix(1,0,0,1,-459,-258.321)">
                    <g >
                        <path id="bubble_top" d="M585.6,437L691.1,376.3C698.6,372 701.7,362.7 698.2,354.7L694.7,346.7C693,342.3 693.8,339.7 698.7,338.8L728,332.6C732.4,331.6 732.5,328.4 729,327.2L680.5,309.7C679,309.2 677.8,308 677.2,306.6L660.6,268.6C656.5,259.3 645.2,255.5 636.4,260.6L530.9,321.3C523.4,325.6 520.3,334.9 523.8,342.9L561.3,429C565.5,438.4 576.7,442.1 585.6,437Z" />
                        <path id="bubble_right" d="M687.2,394.9L591.7,449.8C574.7,459.9 572.7,465.6 572.7,482.7L572.7,569.9C572.7,579.7 583.3,585.8 591.7,580.9L645.8,549.7C647,549.1 648.5,549.5 649.4,550.3C662.5,562.5 689.2,560.9 700,559C701.3,558.8 701.5,556.9 700.2,556.4C686.3,550.8 684,540.4 684,529.9C684,528.6 684.8,527.4 686,526.7L687.2,526C701.1,518 706.1,512 706.1,492.9L706.1,405.7C706.2,396.1 695.7,390 687.2,394.9Z"/>
                        <path id="bubble_left" d="M504.5,352.4C505.4,352.4 506.4,353.2 506.9,354.3L542.5,436.1L542.7,436.3C551.2,453.3 552.7,459.5 552.7,478.2L552.7,572.2C552.7,575.5 549.9,577.5 547.4,577.5C546.5,577.5 545.6,577.3 544.8,576.8L507.5,555.3C506,554.4 504.3,554 502.6,554C499.6,554 496.9,555.3 495,557.6C490.9,562.5 485.6,566.5 480.4,569.4C483.6,561.9 484.2,554.3 484.3,548C484.3,544.2 482.5,540.8 479.4,539L467.8,532.3C466.2,531.4 465.2,529.6 465.2,527.7L465.2,426.5C465.2,416 465.2,409.1 469.6,403L469.8,402.8L502.8,353.4C503,352.9 503.7,352.4 504.5,352.4M504.5,346.4C502.2,346.4 499.8,347.4 497.8,349.7L464.6,399.4C459,407.1 459,415.5 459,426.4L459,527.7C459,531.7 461.2,535.5 464.6,537.5L476.2,544.2C477.5,545 478.1,546.4 478.1,547.9C478,557 476.7,567.7 468.2,577.3C466.5,579.2 467.4,580.2 469.2,580.2C470,580.2 470.9,580 472,579.7C478.8,577.4 491,571.6 499.4,561.4C500.2,560.5 501.3,560 502.4,560C503.1,560 503.7,560.2 504.3,560.5L541.6,582C543.4,583 545.3,583.5 547.2,583.5C553.1,583.5 558.5,578.8 558.5,572.2L558.5,478.2C558.5,458.9 556.8,451.5 547.9,433.7L512.4,352C510.9,348.4 507.8,346.4 504.5,346.4Z" />
                    </g>
                </g>
            </svg>
        )
    }

export default AnimationLogo

