import NavItem from './NavItem'

import { FaPowerOff } from 'react-icons/fa'

import { logout } from '../../utils/logout.js'

import { SETTINGS  } from '.'

const Logout = () => {
    return (
        <NavItem
            SETTINGS={SETTINGS}
            item={{
                title: 'Abmelden',
                path: '/login',
                icon: <FaPowerOff size={SETTINGS.iconSize} />
            }}
            onClick={logout}
        />
    )
}

export default Logout
