import { useState } from "react";
import { Stack, Button, DialogContent, DialogContentText, Dialog, DialogTitle, DialogActions, IconButton, Tooltip, Paper, Grid, ButtonBase, AvatarGroup, Avatar, Typography, Divider } from "@mui/material";
import { FaUsersSlash } from "react-icons/fa";
import useStyledSnackbar from '../../../../../hooks/useStyledSnackbar';
import ConfirmDialog from "../../../../Widgets/ConfirmDialog";
import { getCookie } from "../../../../../utils/getCookie";


const DeleteGroupDialog = (props) =>{

    const { workingGroups, getWorkingGroups } = props;
    const { enqueueSnackbar } = useStyledSnackbar()
    const [ open, setOpen ] = useState(false)

    const handleOnClose = () =>{
        setOpen(false)
    }

    const handleDeleteGroup = async (goup_id) => {
        const json = JSON.stringify({
            working_group_id: goup_id,
        })
        const res = await fetch('/api/company/workplanner/working_groups', {
            method: 'DELETE',
            headers: {
                'Content-Type': 'application/json',
                'X-CSRFToken': getCookie('csrftoken')
            },
            body: json,
        })
        if (res.ok) {
            enqueueSnackbar('Die Arbeitsgruppe wurde erfolgreich gelöscht!', {
                variant: 'success'
            })
            getWorkingGroups();
            // handleOnClose()
        }
    }

    return (
        <>
            <Tooltip
                title="Arbeitsgruppe löschen"
            >
                <IconButton color='primary' size="large" onClick={() => setOpen(true)}><FaUsersSlash /></IconButton>
            </Tooltip>
            <Dialog
                open={open}
                onClose={handleOnClose}
                fullWidth={true}
                maxWidth={'laptop'}
            >
                <DialogTitle>
                    Arbeitsgruppe löschen
                </DialogTitle>
                <DialogContent>
                    <DialogContentText sx={{mb: 3}}>
                        Wähle eine Arbetisgruppe aus, um sie zu löschen.
                    </DialogContentText>
                    <Grid
                        container
                        spacing={2}
                    >
                        {workingGroups.map((group)=>{
                            return(
                                <Grid
                                    key={`goup-item-${group.id}`}
                                    item
                                    desktop={3}
                                    laptop={3}
                                    tablet={6}
                                    mobil={6}
                                >
                                    <ConfirmDialog
                                        title='Achtung'
                                        message={`Möchtest du wirklich die Arbeitsgruppe ${group.name} löschen?`}
                                        confirmButtonText="Ja"
                                        cancelButtonText="Nein"
                                        onConfirm={()=> handleDeleteGroup(group.id)}
                                        renderButton={(onClick) => (
                                            <Paper>
                                                <ButtonBase
                                                    onClick={onClick}
                                                    sx={{
                                                        display: 'flex',
                                                        flexDirection: 'column',
                                                        justifyContent: 'flex-start',
                                                        alignItems: 'flex-start',
                                                        borderRadius: 2,
                                                        width: '100%',
                                                        height: '100%',
                                                    }}
                                                >
                                                    <Stack
                                                        direction='row'
                                                        justifyContent='flex-start'
                                                        alignItems= 'center'
                                                        spacing={2}
                                                        sx={{ width: '100%', p:2}}
                                                    >
                                                        <Avatar
                                                            key={`cm-${group.id}`}
                                                            alt={group.name}
                                                            src={group.image}
                                                            sx={{height: 56, width: 56}}
                                                        />
                                                        <Divider orientation="vertical" flexItem />
                                                        <Stack
                                                            justifyContent= 'flex-start'
                                                            alignItems= 'flex-start'
                                                            spacing={1}
                                                        >
                                                            <Typography
                                                                variant="paper_title"
                                                                sx={{ maxWidth: 250, overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap' }}
                                                            >
                                                                {`${group.name}`}
                                                            </Typography>
                                                            <Typography
                                                                variant="small_text"
                                                            >
                                                                {`${group.description}`}
                                                            </Typography>
                                                            <AvatarGroup max={10}>
                                                                {group.members.map((member)=>{
                                                                    return(
                                                                        <Avatar
                                                                            key={`cm-${member.id}`}
                                                                            alt={member.firstname}
                                                                            src={member.avatar}
                                                                        />
                                                                    )
                                                                })}
                                                            </AvatarGroup>
                                                        </Stack>
                                                    </Stack>
                                                </ButtonBase>
                                            </Paper>
                                        )}
                                    />

                                </Grid>
                            )
                        })}

                    </Grid>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleOnClose}>Abbrechen</Button>
                </DialogActions>
            </Dialog>
        </>
    )
}

export default DeleteGroupDialog
