import { useEffect, useState, useContext, useCallback } from "react"
import { useLocation } from 'react-router-dom'
import AccountContext from "../../../context/AccountContext";

import { Grid, Fade, Fab, Slide, TextField, Stack, Typography, CircularProgress } from "@mui/material";
import { Event } from "@mui/icons-material";
import WorkingTimeline from "./WorkingTimeline";
import CustomPage from "../../Widgets/CustomPage";
import ProjectCardsDispatcher from "./ProjectCardsDispatcher";
import InfiniteScroll from "react-infinite-scroll-component";
import CustomTabs from "components/Widgets/CustomTabs";
import useDebounce from "hooks/useDebounce";


const STATUS = {
    active: '1',
    completed: '2',
}

const addToMap = newProjects => old => {
    const copy = new Map(old)
    newProjects.forEach(p => copy.set(p.id, p))
    return copy
}

const Projects = () => {

    const location = useLocation()
    const [account] = useContext(AccountContext)

    const [tab, setTab] = useState('1')

    const [projectsCount, setProjectsCount] = useState({
        [STATUS.active]: 0,
        [STATUS.completed]: 0,
    })

    const [activeProjects, setActiveProjects] = useState(new Map())
    const [activeNext, setActiveNext] = useState(null)

    const [completedProjects, setCompletedProjects] = useState(new Map())
    const [completedNext, setCompletedNext] = useState(null)

    const [workplan, setWorkplan] = useState([])
    const [show, setShow] = useState(false)
    const [open, setOpen] = useState(false)

    const handleClose = () => {
        setOpen(false)
    }

    const [filterTerm, setFitlerTerm] = useState('');
    const handleFilter = useDebounce((event) => {
        setFitlerTerm(event.target.value)
        setActiveProjects(new Map())
        setCompletedProjects(new Map())
    })

    const getProjects = useCallback(async (status, url) => {
        const res = await fetch(url ? url : `/api/projects-statistics?search=${filterTerm}&status=${status}&limit=6`)
        if (res.ok) {
            const data = await res.json()
            if (status === STATUS.active) {
                setActiveProjects(addToMap(data.results))
                setActiveNext(data.next)
            } else if (status === STATUS.completed) {
                setCompletedProjects(addToMap(data.results))
                setCompletedNext(data.next)
            }
        }
    },[setActiveProjects, setActiveNext, setCompletedProjects, setCompletedNext, filterTerm])

    const getProjectsCount = async () => {
        const res = await fetch('/api/projects/count')
        if (res.ok) {
            const data = await res.json()
            if (data[STATUS.active] > 0)
                setTab(STATUS.active)
            else if (data[STATUS.completed] > 0)
                setTab(STATUS.completed)
            setProjectsCount(data)
        }
    }

    const getWorkplan = useCallback(async() => {
        if (!account.company.paid)
            return
        const res = await fetch('/api/company/workplanner/workplan', {
            method: 'GET',
        })
        if (res.ok) {
            const data = await res.json()
            if (data.length !== 0){
                setShow(true)
                setWorkplan(data)
                console.log(data)
            }
        }
    }, [account])

    const fetchNext = status => () => {
        if (status === STATUS.active)
            getProjects(status, activeNext)
        else if (status === STATUS.completed)
            getProjects(status, completedNext)
    }

    useEffect(() => {
        getProjectsCount()
        getWorkplan();
    }, [getWorkplan])

    const handleTabChange = (_, t) => {
        setTab(t)
    }

    useEffect(() => {
        if (tab === STATUS.active && projectsCount[STATUS.active] > 0 && activeProjects.size === 0)
            getProjects(STATUS.active)
        else if (tab === STATUS.completed && projectsCount[STATUS.completed] > 0 && completedProjects.size === 0)
            getProjects(STATUS.completed)
    }, [activeProjects.size, completedProjects.size, tab, projectsCount, filterTerm, getProjects])

    let projects = activeProjects
    let next = activeNext

    if (tab === STATUS.completed) {
        projects = completedProjects
        next = completedNext
    }

    return (
        <CustomPage>
            <Stack
                flexDirection="row"
                justifyContent="space-between"
                alignItems="center"
                sx={{ pb: 2 }}
            >
                <Stack>
                    <Typography variant="title_page" sx={{lineHeight: '54px'}}>
                        Projekte
                    </Typography>
                </Stack>
                <CustomTabs
                    value={tab}
                    handleChange={handleTabChange}
                    tabs={[
                        { index: '1', label: 'Aktive Projekte', badgeCount: projectsCount[STATUS.active]},
                        { index: '2', label: 'Abgeschlossene Projekte', badgeCount: projectsCount[STATUS.completed] },
                    ]}
                    fullWidth={false}
                    buttonSx={{
                        pl: 1,
                        pr: 1,
                        width: 320
                    }}
                />
                <TextField
                    id="project-filter"
                    label="Filter"
                    variant="outlined"
                    onChange={handleFilter}
                    sx={{maxWidth: 250}}
                />
            </Stack>
            <InfiniteScroll
                dataLength={projects.size}
                next={fetchNext(tab)}
                hasMore={next !== null}
                loader={
                    <Stack sx={{ height: '50px', }} alignItems='center'>
                        <CircularProgress />
                    </Stack>
                }
                style={{
                    display: 'flex',
                    flexDirection: 'column',
                    gap: 16,
                    overflow: 'visible'
                }}
            >
                <Grid
                    container
                    spacing={3}
                >
                    {Array.from(projects).map(([ id, project ]) => {
                        return(
                            <Fade
                                key={id}
                                in={true}
                                style={{ transitionDelay: 45 + 'ms'}}
                            >
                                <Grid
                                    key={`project-card-intern-${id}`}
                                    item
                                    desktop={6}
                                    laptop={12}
                                    tablet={12}
                                    mobile={12}
                                >
                                    <ProjectCardsDispatcher
                                        key={id}
                                        project={project}
                                        path={location.pathname}
                                        paid={account.company.paid}
                                        enable_paid_material={account.company.enable_paid_material}
                                        is_manager={account.is_manager || account.is_admin}
                                    />
                                </Grid>
                            </Fade>
                        )
                    })}
                </Grid>
            </InfiniteScroll>
            <Slide direction="left" in={show} timeout={2000} style={{ transitionDelay: '150ms' }}>
                <Fab
                    variant="extended"
                    color="primary"
                    sx={{ position: 'fixed', bottom: 44, right: 44, zIndex: 100 }}
                    onClick={()=> setOpen(true)}
                >
                    <Event sx={{ mr: 1 }} />
                        Arbeitsplan
                </Fab>
            </Slide>
            <WorkingTimeline
                open={open}
                onClose={handleClose}
                workplan={workplan}
            />
        </CustomPage>
    )
}

export default Projects
