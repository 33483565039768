import { useCallback, useEffect, useState } from "react";
import { Button, IconButton, Stack, TextField, Typography } from "@mui/material";
import { Download, Save } from "@mui/icons-material";
import { useGridApiRef } from "@mui/x-data-grid-pro";

import CustomPage from "components/Widgets/CustomPage";
import CustomPaper from "components/Widgets/CustomPaper";

import useStyledSnackbar from "hooks/useStyledSnackbar";
import PaperPaginationDataGrid from "components/Widgets/CustomDatagrids/Pagination/PaperPaginationDataGrid";

import { getCookie } from "utils/getCookie";
import { formatTimestamp, getLocalDate } from "utils/date_functions";
import { DatePicker } from "@mui/x-date-pickers-pro";
import { addYears } from "date-fns";


const CustomFileCell = (params) => {
    return(
        <IconButton color='primary' href={params.row.file} download={params.row.file_name}>
            <Download fontSize='inherit' />
        </IconButton>
    )
}

const TimeRecordingExport = () => {

    const { enqueueSnackbar } = useStyledSnackbar()

	const apiRef = useGridApiRef();
    const [searchTerm, setSearchTerm] = useState('');
	const [orderBy, setOrderBy] = useState('');
	const [timeRecordExports, setTimeRecordsExports] = useState([]);
	const [pagInfo, setPagInfo] = useState({
		count: 0,
		next : null,
		previous: null,
	});
	const [isLoading, setIsLoading] = useState(false)
	const [pageSize, setPageSize] = useState(25)
	// const [errors, setErrors] = useState({});

    const [selectedDate, setSelectedDate] = useState(new Date())

    const handlePageChange = (x) =>{
		getTimeRecordExports(pagInfo[x])
	}

	const handleOrderByGetParametersChange = params => {
		setOrderBy(params)
	}

	const getTimeRecordExports = useCallback(async(url) => {
		setIsLoading(true)
        const res = await fetch(url ? url :'/api/time-recording/exports?search=' + searchTerm +'&limit=' + pageSize + '&' + orderBy, {
            method: 'GET'
        })
		const data = await res.json()
		if(res.ok){
			setTimeRecordsExports(data.results)
			setPagInfo({
				count: data.count,
				next : data.next,
				previous: data.previous,
			})
			console.log(data)
		}
		setIsLoading(false)
    }, [pageSize, searchTerm, orderBy])


    useEffect(() => {
		getTimeRecordExports();
	}, [getTimeRecordExports])


    const columns = [
        {
			field: 'month',
			headerName: 'Monat',
			type: 'string',
			minWidth: 150,
			editable: false,
		},
        {
			field: 'year',
			headerName: 'Jahr',
			type: 'string',
			minWidth: 150,
			editable: false,
		},
		{
			field: 'created',
			headerName: 'erstellt am',
			type: 'string',
			flex: 1,
			editable: false,
            valueFormatter: ({value}) => formatTimestamp(value)
		},
        {
			field: 'file',
			headerName: 'Datei',
			type: 'actions',
			minWidth: 150,
            renderCell: CustomFileCell,
			editable: false,
            sortable: false,
		},
	];

    const handleDelete = async(id) => {
        const json = JSON.stringify({
			id: id
		})
        const res = await fetch('/api/time-recording/exports', {
            method: 'DELETE',
            headers: {
                'Content-Type': 'application/json',
                'X-CSRFToken': getCookie('csrftoken')
            },
            body: json
        })
		return res.ok
    }

    const getConfirmMessage = row => `Möchtest du wirklich die Abrechnung vom ${row.month}.${row.year} unwiderruflich löschen?`

    // -----------------
    // Create Export
    // -----------------

    const handleCreateExport = async() => {
        const json = JSON.stringify({
            date: getLocalDate(selectedDate),
        })
        const res =  await fetch('/api/time-recording/export/create', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'X-CSRFToken': getCookie('csrftoken')
            },
            body: json
        })
        if(res.ok){
            getTimeRecordExports()
            enqueueSnackbar('Die Abrechnung wurde erfolgreich erstellt!', {
                variant: 'success'
            })
        } else{
            enqueueSnackbar('Upsi, die Zeit wurde nicht erfasst! Es ist irgendwas fehlgelaufen.', {
                variant: 'error'
            })
        }
    }



    return (
        <CustomPage
            title="Export für den Steuerberater"
        >
            <Stack
                direction="row"
                alignItems={'flex-start'}
                gap={3}
            >
                <CustomPaper
                    title="Stunden exportieren"
                >
                    <Stack
                        gap={2}
                        sx={{ width: 300}}
                    >
                        <Typography
                            variant="text_m_justify"
                            gutterBottom
                        >
                            Um deine Stunden zu exportieren wähle den Monat aus und klicke auf exportieren. Nun siehst du rechts im Bild deine erstellte Zeiterfassungsdatei. Diese CSV-Datei kannst du dir lokal herunterladen und an dein Abrechnungsbüro weiterleiten.
                        </Typography>
                        <DatePicker
                            views={['year', 'month']}
                            label="Abrechnung für den"
                            minDate={new Date('2021-01-01')}
                            maxDate={addYears(new Date(), 2)}
                            value={selectedDate}
                            onChange={(newDate) => {
                                setSelectedDate(newDate);
                            }}
                            renderInput={(params) => <TextField {...params} helperText={null} />}
                        />
                        <Button
                            variant="outlined"
                            color="primary"
                            startIcon={<Save/>}
                            onClick={handleCreateExport}
                            disabled={isLoading}
                        >
                            Exportieren
                        </Button>
                    </Stack>
                </CustomPaper>
                <Stack sx={{flex: 1}}>
                    <PaperPaginationDataGrid
                        rows={timeRecordExports}
                        columns={columns}
                        apiRef={apiRef}
                        pageSize={pageSize}
                        onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
                        rowCount={pagInfo.count}
                        onPageChange={handlePageChange}
                        loading={isLoading}
                        title={"Exports"}
                        // actionBar={}
                        onDeleteRow={handleDelete}
                        // onSaveRow={onSaveRow}
                        getConfirmMessage={getConfirmMessage}
                        searchTerm={searchTerm}
                        setSearchTerm={setSearchTerm}
                        onOrderByGetParametersChange={handleOrderByGetParametersChange}
                    />
                </Stack>
            </Stack>


        </CustomPage>
    );
}

export default TimeRecordingExport;