import { Stack, Typography } from "@mui/material"

const DateTimeDisplaySegment = ({ value, label, isDanger }) => {
	return (
		<Stack
			alignItems='center'
		>
			<Typography variant="h4" color={isDanger ? 'error' : 'primary'}>
				{value}
			</Typography>
			<Typography  variant="overline">
				{label}
			</Typography>
		</Stack>
	);
};

export default DateTimeDisplaySegment;
