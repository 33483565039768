export const logout = () =>{
    fetch('/account/logout', {
        method: 'GET'
    }).then(response => {
        response.json().then(data => {
            if(response.ok){
                window.location.reload()
                console.log(data)
            }else{

            }
        }).catch(error => {
            console.error(error)
        })
    }).catch(error => {
        console.error(error)
    })
}
