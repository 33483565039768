import { Avatar, Chip, FormControl, InputLabel, MenuItem, Select, Stack, Typography } from "@mui/material"
import { Box } from "@mui/system"
import AccountContext from "context/AccountContext"
import ProjectContext from "context/ProjectContext"
import { useContext, useState } from "react"


const ProjectMemberSelection = ({ selected, setSelected, locked=[], companyMemberMode=false, showDisabled=false }) => {
    const [account] = useContext(AccountContext)
    const [ ,, projectTeamMembers ] = useContext(ProjectContext)

    const handleChange = (e) => {
        let n = Array.from(new Set([...e.target.value, ...locked]))
        setSelected(n)
    }

    const [ company, setCompany ] = useState(0)
    const handleCompanyChange = (e) => {
        setCompany(e.target.value)
    }

    const getId = (pm) => {
        if (companyMemberMode) return pm.cm_id
        return pm.id
    }

    return (
        <Stack>
            <Stack
                direction='row'
                gap={2}
                flexWrap='wrap'
                sx={{
                    mb: selected.length > 0 ? 2 : 0
                }}
            >
                {projectTeamMembers.map(c => {
                    return c.project_members.map(pm => {
                        if (!selected.includes(getId(pm))) return
                        return <Chip
                            key={getId(pm)}
                            label={pm.firstname + ' ' + pm.lastname}
                            avatar={<Avatar alt='' src={pm.avatar} />}
                        />
                    })
                })}
            </Stack>
            <FormControl fullWidth>
                <InputLabel>Unternehmen</InputLabel>
                <Select
                    value={company}
                    onChange={handleCompanyChange}
                    sx={{
                        mb: 2
                    }}
                    label="Unternehmen"
                >
                    {projectTeamMembers.map((c, k) => (
                        <MenuItem value={k} key={k}>{c.name}</MenuItem>
                    ))}
                </Select>
            </FormControl>
            <FormControl fullWidth>
                <InputLabel>Projektmitglied</InputLabel>
                <Select
                    label="Projektmitglied"
                    value={selected}
                    onChange={handleChange}
                    renderValue={(selected) => (
                        <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                            {selected.map((key) => {
                                for (let pm of projectTeamMembers[company].project_members) {
                                    if (getId(pm) === key) {
                                        return <Chip
                                            key={key}
                                            label={pm.firstname + ' ' + pm.lastname}
                                            avatar={<Avatar alt='' src={pm.avatar} />}
                                        />
                                    }
                                }
                            })}
                        </Box>
                    )}
                    multiple
                >
                    {projectTeamMembers[company]?.project_members.map(pm => {
                        if (pm.cm_id === account.id || (!showDisabled && pm.disabled)) return

                        return <MenuItem value={getId(pm)} key={getId(pm)}>
                            <Stack
                                direction="row"
                                alignItems='center'
                                spacing={1}
                            >
                                <Avatar alt='' src={pm.avatar} />
                                <Typography>{pm.firstname} {pm.lastname}</Typography>
                            </Stack>
                        </MenuItem>
                    })}
                </Select>
            </FormControl>
        </Stack>
    )
}

export default ProjectMemberSelection
