import { BarChart, Bar, XAxis, YAxis, ReferenceLine } from 'recharts';

import { Stack, CircularProgress, Typography } from '@mui/material';
import { BsCircleFill } from "react-icons/bs";

import  useWidth  from '../../../../../hooks/useWidth'

import { PALETTE } from "../../../../theme/index.jsx";
import CustomPaper from "../../../../Widgets/CustomPaper/index.jsx";
import { formatNumber } from 'utils/helper';

const StaticMultiStackBarchart = ({ title, max, data, height, maxFactor, fill1, fill2, fill3, unit , barSize}) =>{

    const [ width, ref ] = useWidth(0)

    const getRadius = (next_value) =>{
        if(next_value <= 0){
            return true
        }
        return false
    }

    return(
        <CustomPaper
            title="Gesamte Auftragskosten"
            padding={0}
            actionBar={
                <Stack
                    direction={'row'}
                    spacing={2}
                >
                    <Stack
                        direction='row'
                        justifyContent="center"
                        alignItems="center"
                        spacing={1}
                    >
                        <BsCircleFill size={10} color={fill1}/>
                        <Typography variant="text_m">Materialkosten</Typography>
                    </Stack>
                    <Stack
                        direction='row'
                        justifyContent="center"
                        alignItems="center"
                        spacing={1}
                    >
                        <BsCircleFill size={10} color={fill2}/>
                        <Typography variant="text_m">Lohnkosten</Typography>
                    </Stack>
                    <Stack
                        direction='row'
                        justifyContent="center"
                        alignItems="center"
                        spacing={1}
                    >
                        <BsCircleFill size={10} color={fill3}/>
                        <Typography variant="text_m">Auftragskosten</Typography>
                    </Stack>
                </Stack>
            }
        >
            <Stack
                justifyContent="center"
                alignItems="center"
                width="100%"
                height={height}
                ref={ref}
            >
                {width !== 0 ?
                    <BarChart
                        width={width}
                        height={height}
                        data={data}
                        layout='vertical'
                        margin={{
                            top: 0,
                            right: 60,
                            left: 0,
                            bottom: 15,
                        }}
                    >
                        <XAxis
                            type="number"
                            unit={unit}
                            tickLine={false}
                            tickCount={5}
                            interval="preserveEnd"
                            domain={[0, dataMax => dataMax < max ? (Math.round(max * maxFactor)) : (Math.round(dataMax * maxFactor))]}
                        />
                        <YAxis
                            type="category"
                            dataKey="name"
                            hide={true}
                            tickLine={false}
                        />
                        <Bar
                            dataKey="material"
                            stackId="a"
                            fill={fill1}
                            barSize={barSize}
                            animationDuration={1000}
                            radius={getRadius(data[0].workload) ? [0, 10, 10, 0] : [0, 0, 0, 0]}
                        />
                        <Bar
                            dataKey="workload"
                            stackId="a"
                            fill={fill2}
                            barSize={barSize}
                            animationDuration={1000}
                            radius={getRadius(data[0].task) ? [0, 10, 10, 0] : [0, 0, 0, 0]}
                        />
                        <Bar
                            dataKey="task"
                            stackId="a"
                            fill={fill3}
                            barSize={barSize}
                            animationDuration={1000}
                            radius={[0, 10, 10, 0]}
                        />
                        <ReferenceLine
                            x={max}
                            label={{position: 'insideTopRight',  value: `Kalk. ${title} ${formatNumber(max, 2, unit)}`, fill: PALETTE.background.light10, fontSize: 15 }}
                            strokeWidth={1.5}
                            stroke={PALETTE.secondary.light}
                        />
                    </BarChart>
                :
                    <CircularProgress/>
                }
            </Stack>
        </CustomPaper>
    )
}

export default StaticMultiStackBarchart