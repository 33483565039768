import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useLocation } from 'react-router';
import { Link } from 'react-router-dom';

import { Button, CircularProgress, Paper, Stack, Typography } from '@mui/material'
import ErrorIcon from '@mui/icons-material/Error'
import ThumbUp from '@mui/icons-material/ThumbUp'

import ControlledTextField from 'components/Widgets/ControlledInputs/ControlledTextField';
import LogoFullWhite from 'components/Widgets/Logos/LogoFullWhite'

import { getCookie } from 'utils/getCookie';
import setServerErrors from 'utils/setServerErrors';


const Reset = () => {
    const location = useLocation()
    const [state, setState] = useState('checking')
    const token = new URLSearchParams(location.search).get('token')

    const { control, handleSubmit, setError, formState: { errors, isValid, isDirty } } = useForm({
        mode:"onChange",
        defaultValues:{
            password: '',
            password2: '',
        }
    });

    useEffect(() => {
        (async () => {
            const res = await fetch('/account/password_reset/validate_token/', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'X-CSRFToken': getCookie('csrftoken')
                },
                body: JSON.stringify({
                    token
                })
            })
            if (res.ok) {
                setState('success')
            } else {
                setState('failed')
            }
        })()
    }, [token])

    const resetPassword = async data => {
        if (data.password !== data.password2) {
            setError('password2', {
                type: 'server',
                message: ['Die Passwörter stimmen nicht überein.']
            })
            return
        }

        const res = await fetch('/account/password_reset/confirm/', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'X-CSRFToken': getCookie('csrftoken')
            },
            body: JSON.stringify({
                token,
                password: data.password
            })
        })
        if (res.ok) {
            setState('done')
        } else {
            const json = await res.json()
            setServerErrors(json, setError)
        }
    }

    return (
        <Stack
            spacing={3}
            sx={{
                backgroundColor: 'background.main',
                justifyContent: 'center',
                alignItems: 'center',
                height: '100vh',
                p: 2
            }}
        >
            <LogoFullWhite width='100%' height={50}/>
            <Paper
                component='form'
                onSubmit={handleSubmit(resetPassword)}
                sx={{
                    p: 5,
                }}
            >
                {state === 'checking' && <CircularProgress />}
                {state === 'success' &&
                    <Stack direction='column' gap={2}>
                        <ControlledTextField
                            control={control}
                            name='password'
                            rules={{ required: true }}
                            type='password'
                            label='Passwort'
                            errors={errors}
                        />
                        <ControlledTextField
                            control={control}
                            name='password2'
                            rules={{ required: true }}
                            type='password'
                            label='Passwort wiederholen'
                            errors={errors}
                        />
                        <Button
                            type="submit"
                            color="primary"
                            variant="contained"
                            size="large"
                            fullWidth
                            disabled={!isDirty || !isValid}
                        >
                            Passwort zurücksetzen
                        </Button>
                    </Stack>
                }
                {state === 'failed' &&
                    <Stack justifyContent='center' alignItems='center' gap={2}>
                        <ErrorIcon sx={{ color: 'error.main' }} fontSize='large' />
                        <Typography variant="overline" sx={{ color: 'error.main' }}>
                            Der Token ist ungültig.
                        </Typography>
                        <Link to='/password-reset/request' style={{ textDecoration: 'none' }}>
                            <Button variant='outlined' color='error'>
                                Passwort zurücksetzen
                            </Button>
                        </Link>
                    </Stack>
                }
                {state === 'done' &&
                    <>
                        <Stack justifyContent='center' alignItems='center' gap={2}>
                            <ThumbUp color="primary" fontSize='large'/>
                            <Typography variant="overline" sx={{ color: 'success.main'}}>
                                Ihr Passwort wurde geändert.
                            </Typography>
                            <Link to='/login' style={{ textDecoration: 'none' }}>
                                <Button variant='outlined' color='primary'>
                                    Login
                                </Button>
                            </Link>
                        </Stack>
                    </>
                }
            </Paper>
        </Stack>

    )
}

export default Reset
