import { useContext, useEffect, useState } from 'react'

import useStyledSnackbar from '../../../hooks/useStyledSnackbar'

import { getCookie } from '../../../utils/getCookie'

import AccountContext from '../../../context/AccountContext'
import CustomFormPaper from '../../Widgets/CustomFormPaper'
import ImageUploader from '../../Widgets/ImageUplaoder'


const ChangeProfileAvatar = () => {
    const [account, getAccount] = useContext(AccountContext)

    const [loading, setLoading] = useState(false)
    const [success, setSuccess] = useState(false)
    const [selectedFile, setSelectedFile] = useState(null)

    const { enqueueSnackbar } = useStyledSnackbar()

    const uploadFile = async e => {
        setLoading(true)
        e.preventDefault()
        const formData = new FormData()
        formData.append('file', selectedFile)
        const res = await fetch('/api/account/avatar', {
            method: 'PUT',
            body: formData,
            headers: {
                'X-CSRFToken': getCookie('csrftoken')
            }
        })
        if (res.ok) {
            getAccount()
            setLoading(false)
            setSuccess(true)
            enqueueSnackbar('Ihr Avatar wurde erfolgreich aktualisiert!', {
                variant: 'success'
            })
        }
    }

    useEffect(()=>{
        setSuccess(false)
    },[selectedFile])

    return (
        <CustomFormPaper
            title="Profilbild ändern"
            onSubmit={uploadFile}
            tooltip={'Speichern'}
            success={success}
            loading={loading}
            disabled={selectedFile === null}
        >
            <ImageUploader
                selectedFile={selectedFile}
                setSelectedFile={setSelectedFile}
                initialImg={account.avatar}
            />
        </CustomFormPaper>
    )
}

export default ChangeProfileAvatar
