import {useContext} from "react";
import {Box, Stack, Typography} from "@mui/material";

import AccountContext from "../../../context/AccountContext";
import LogoFullBlack from "../Logos/LogoFullBlack";


const Paywall = ({ children }) => {
    const [account] = useContext(AccountContext)

    if (account.company.paid)
        return children

    return (
        <Stack
            justifyContent='center'
            alignItems='center'
            sx={{
                flex: 1,
                padding: 2
            }}
        >
            <LogoFullBlack width={450} height={100}/>
            <Box
                sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    gap: 4,
                    padding: 4,
                    maxWidth: 600
                }}
            >
                <Typography variant='subtitle_m' color='secondary'>Kostenplichtige Funktion</Typography>
                <Typography
                    variant='text_m_justify'
                >
                    Du hast wahrscheinlich gemerkt, dass CONMEET noch viel mehr kann.
                </Typography>
                <ul style={{
                    paddingLeft: 40,
                    paddingRight: 40,
                }}>
                    <li>
                        <Typography
                            variant='text_m_justify'
                        >
                            Du willst deine eigenen Projekte erstellen, mit deiner eigenen Kalkulation und Stundenerfassung?
                        </Typography>
                    </li>
                    <li>
                        <Typography
                            variant='text_m_justify'
                        >
                            Du willst deine Baustellen automatisch protokollieren und Teil eines Baunetzwerkes werden?
                        </Typography>
                    </li>
                    <li>
                        <Typography
                            variant='text_m_justify'
                        >
                            Und noch vieles mehr...
                        </Typography>
                    </li>
                </ul>
                <Typography
                    variant='text_m_justify'
                >
                    Dann komm doch in unsere CONMEET-Community und sicher dir deinen kostenpflichtigen Account.
                    Keine Angst, es gibt für dich keine Vertragslaufzeit oder Lizenzen für einzelne Mitarbeiter.
                    Wenn du uns verlassen willst, kannst du ganz einfach jeden Monat kündigen.
                    Tue dies am besten am Ende eines Monats, da der angefangene Monat zu Ende gezahlt werden muss.
                    Wenn du weitere Informationen haben willst, ruf uns doch einfach mal unter unserer Kunden-Hotline an: <b>0175/2049801</b>.
                </Typography>
            </Box>
        </Stack>
    )
}

export default Paywall