import {RiErrorWarningFill} from "react-icons/ri";
import {Stack} from "@mui/material";

const Title = ({ document }) => {
    return (
        <Stack
            direction='row'
            alignItems='center'
            gap={1}
        >
            {document.outdated && <RiErrorWarningFill size={22} />}
            {document.description}
        </Stack>
    )
}

export default Title