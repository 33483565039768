import { Link } from "react-router-dom"

import ProjectCardNoSubs from "../../../Widgets/ProjectCards/ProjectCardNoSubs"
import ProjectCardWithSubs from "../../../Widgets/ProjectCards/ProjectCardWithSubs"
import ProjectCardNoSubsLimited from "../../../Widgets/ProjectCards/ProjectCardNoSubsLimited"
import ProjectCardWithSubsLimited  from "../../../Widgets/ProjectCards/ProjectCardWithSubsLimited"
import ProjectLeaderCard from "components/Widgets/ProjectCards/ProjectLeaderCard"
import ProjectLeaderCardWithSubs from "components/Widgets/ProjectCards/ProjectLeaderCardWithSubs"


const ProjectCardsDispatcher = (props) =>{

    const { idx, project, path, is_manager, paid, enable_paid_material} = props

    const handleCardDispatch = () =>{
        if(paid && !enable_paid_material && !project.has_subs){
            return <ProjectLeaderCard project={project}/>
        }
        if(paid && !enable_paid_material && project.has_subs){
            return <ProjectLeaderCardWithSubs project={project}/>
        }
        if(is_manager && !project.has_subs){
            return <ProjectCardNoSubs project={project}/>
        }
        if(is_manager && project.has_subs){
            return <ProjectCardWithSubs project={project}/>
        }
        if(!is_manager && !project.has_subs){
            return <ProjectCardNoSubsLimited project={project}/>
        }
        if(!is_manager && project.has_subs){
            return <ProjectCardWithSubsLimited project={project}/>
        }
    }

    return(
        <Link key={idx} to={path + '/project/' + project.id + (is_manager ? '/statistics' : '/chat')} style={{ textDecoration: 'none', color: 'inherit'}}>
            {handleCardDispatch()}
        </Link>
    )
}

export default ProjectCardsDispatcher