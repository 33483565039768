import React from 'react'
import ReactDom from 'react-dom'

import {Stack, Typography} from '@mui/material';
import AnimationLogo from './AnimationLogo'
import './AnimationLogo.css'

const LoadingScreen = ({open}) =>{
    if(!open) return null
    return ReactDom.createPortal(
        <div className="overlay-background">
            <div className="loading-screen">
                <AnimationLogo/>
                <Stack direction="row" spacing={2}>
                    <Typography
                        component="div"
                        variant="text_m"
                        sx={{
                            display: "flex",
                            flexDirection: "column",
                            justifyContent: "center",
                            color: 'background.light10'}}
                        >
                            Lädt gerade...
                        </Typography>
                    {/* <CircularProgress/> */}
                </Stack>
            </div>
        </div>,
        document.getElementById('portal')
    )
}

export default LoadingScreen
