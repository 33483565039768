import { Controller } from 'react-hook-form'
import { Switch, FormControlLabel, Typography } from '@mui/material'

const ControlledSwitch = ({
        control,
        name,
        color,
        label,
        rules={ required: false }
    }) =>{

    return(
        <Controller
            name={name}
            control={control}
            rules={rules}
            render={({field: { value, onChange }}) =>(
                <FormControlLabel
                    label={
                        <Typography variant="text_m">
                            {label}
                        </Typography>
                    }
                    control={
                        <Switch
                            checked={value}
                            onChange={(e)=> onChange(e.target.checked)}
                            name={name}
                            color={color}
                        />
                    }
                />
            )}
        />
    )
}

export default ControlledSwitch