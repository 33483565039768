import { useState, useEffect } from "react";
import { useForm } from "react-hook-form";

import { getCookie } from '../../../../utils/getCookie';
import useStyledSnackbar from '../../../../hooks/useStyledSnackbar'

import CustomFormPaper from '../../../Widgets/CustomFormPaper';
import ControlledSwitch from '../../../Widgets/ControlledInputs/ControlledSwitch';


const ProjectOptionForm = ({ project, getProject }) =>{

    const { enqueueSnackbar } =  useStyledSnackbar()

    const [loading, setLoading] = useState(false)
    const [success, setSuccess] = useState(false)

    const { control, handleSubmit, reset,formState: { isDirty, isValid }} = useForm({
        mode: 'onChange',
        defaultValues:{
            enable_C2C_chat: project.enable_C2C_chat,
            visible: project.visible,
            official: project.official,
            archived: project.status === 2,
        }
    });

    const updateOption = async(data) =>{
        setLoading(true)
        const json = JSON.stringify(data)
        const res = await fetch('/api/project/' + project.id + '/options', {
            method: 'PATCH',
            headers: {
                'Content-Type': 'application/json',
                'X-CSRFToken': getCookie('csrftoken')
            },
            body: json
        })
        if(res.ok){
            setLoading(false)
            setSuccess(true);
            getProject()
            enqueueSnackbar('Die Projekteinstellungen wurden erfolgreich aktualisiert!', {
                variant: 'success'
            })
        } else{
            setLoading(false)
        }
    }

    useEffect(() => {
        reset({
            enable_C2C_chat: project.enable_C2C_chat,
            visible: project.visible,
            official: project.official,
            archived: project.status === 2,
        });
    }, [project, reset])

    useEffect(() => {
        setSuccess(false);
    }, [isDirty])

    return(
        <CustomFormPaper
            title='Projekteinstellungen ändern'
            onSubmit={handleSubmit(updateOption)}
            success={success}
            loading={loading}
            disabled={!isDirty || !isValid}
        >
            <ControlledSwitch
                name="enable_C2C_chat"
                label="Chatten zwischen den Unternehmen erlauben"
                control={control}
                color="primary"
            />
            <ControlledSwitch
                name="visible"
                label="Projekt sichtbar für das CONMEET Netzwerk"
                control={control}
                color="primary"
            />
            <ControlledSwitch
                name="official"
                label="Projektleitung"
                control={control}
                color="primary"
            />
        </CustomFormPaper>
    )
}

export default ProjectOptionForm