import { Tooltip as MUITooltip } from '@mui/material'

const Tooltip = ({document, children}) => {
    return (
        <MUITooltip title={document.outdated ? 'Dieses Dokument ist veraltet' : ''} followCursor={true}>
            {children}
        </MUITooltip>
    )
}

export default Tooltip