import { useState, useEffect, useCallback } from "react";
import { Box, Stack, Typography } from "@mui/material"

import ControlledCheckbox from "../Widgets/ControlledInputs/ControlledCheckbox";
import { PALETTE } from "components/theme";

const RegisterFormRights = ({ control, errors, setAgreementId }) =>{

    const [agreement, setAgreement] = useState(null)

    const getAgreement = useCallback(async() => {
		const res = await fetch('/account/agreement/latest', {
            method: 'GET'
        })
		const data = await res.json()
		if(res.ok){
            setAgreement(data)
			setAgreementId(data.id)
		}
    },[setAgreement, setAgreementId])

    useEffect(()=>{
        getAgreement()
    },[getAgreement])

    return(
        <Stack
            sx={{ p: 3 }}
            gap={2}
        >
            <Box
                sx={{
                    padding: 1,
                    border: 1,
                    borderRadius: '7px',
                    borderColor: PALETTE.background.light70,
                    height: 400,
                    overflow: 'auto'
                }}
            >
                <Typography
                    variant="text_m_justify"
                >
                    {agreement?.text}
                </Typography>
            </Box>
            <ControlledCheckbox
                control={control}
                name="agreement"
                label="Ja, ich bin damit einverstanden"
            />
        </Stack>
    )
}

export default RegisterFormRights