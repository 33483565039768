import { createTheme } from "@mui/material";
import { LicenseInfo, deDE } from '@mui/x-data-grid-pro';
import { deDE as coreDeDE } from '@mui/material/locale';
import variables from './variables.scss'

LicenseInfo.setLicenseKey(
  '7151232f4c83404542ccd57c5395b826T1JERVI6Mzc4ODQsRVhQSVJZPTE2NzY1NDEwNjQwMDAsS0VZVkVSU0lPTj0x',
	// '42f8ba67ef69edda04f7cdb7769bbb51T1JERVI6Mzc4ODQsRVhQSVJZPTE2ODMxMzk3NjMsS0VZVkVSU0lPTj0x',
);

export const PALETTE = {
	secondary: {
		ultralight: variables.secondaryColorUltraLight,
		light: variables.secondaryColorLight,
		main: variables.secondaryColor,
		dark: variables.secondaryColorDark,
		contrastText: variables.backgroundColorContent
	},
	primary: {
		ultralight: variables.primaryColorUltraLight,
		light70: variables.primaryColorLight70,
		light: variables.primaryColorLight,
		main: variables.primaryColor,
		dark: variables.primaryColorDark,
		ultradark: variables.primaryColorUltraDark,
		contrastText: variables.backgroundColorContent
	},
	success: {
		ultralight: variables.successColorUltraLight,
		light: variables.successColorLight,
		main: variables.successColor,
		dark: variables.successColorDark,
		contrastText: variables.backgroundColorContent
	},
	error: {
		ultralight: variables.errorColorUltraLight,
		light: variables.errorColorLight,
		main: variables.errorColor,
		dark: variables.errorColorDark,
		contrastText: variables.backgroundColorContent
	},
	warning: {
		ultralight: variables.warningColorUltraLight,
		light: variables.warningColorLight,
		main: variables.warningColor,
		dark: variables.warningColorDark,
		contrastText: variables.backgroundColorContent
	},
	info: {
		ultralight: variables.infoColorUltraLight,
		light: variables.infoColorLight,
		main: variables.infoColor,
		dark: variables.infoColorDark,
		contrastText: variables.backgroundColorContent
	},
	text: {
		light: variables.textColorLight,
		primary: variables.textColorPrimary,
		secondary: variables.textColorSecondary,
		disabled: variables.textColorDisabled,
	},
	background: {
		light5: variables.backgroundColorLight5,
		light10: variables.backgroundColorLight10,
		light15: variables.backgroundColorLight15,
		light20: variables.backgroundColorLight20,
		light25: variables.backgroundColorLight25,
		light45: variables.backgroundColorLight45,
		light50: variables.backgroundColorLight50,
		light60: variables.backgroundColorLight60,
		light70: variables.backgroundColorLight70,
		main: variables.backgroundColor,
		dark5: variables.backgroundColorDark5,
		dark10: variables.backgroundColorDark10,
		content: variables.backgroundColorContent,
	},
	white: {
		main: variables.backgroundColorContent
	}
}

// console.log(PALETTE)

export const THEME_SETTINGS = {
	boxShadow: '1px 1px 3px 0.5px rgba(0,0,0,0.10)'
}

const theme = createTheme({
		palette: PALETTE,
		typography:{
			allVariants:{
				fontFamily: `"Poppins", sans-serif`,
				lineHeight: 1.3,
			},
			// titles
			title_l:{
				color: PALETTE.background.light10,
				fontSize: 45,
				fontWeight: '700',
			},
			title_m:{
				color: PALETTE.background.light10,
				fontSize: 32,
				fontWeight: '700',
			},
			title_page:{
				color: PALETTE.background.light20,
				fontSize: 28,
				fontWeight: '700',
			},
			title_paper:{
				color: PALETTE.background.light20,
				fontSize: 18,
				fontWeight: '700',
			},

			//sub titles
			subtitle_m:{
				color: PALETTE.background.light10,
				fontSize: 18,
				fontWeight: '600',
			},
			subtitle_s:{
				color: PALETTE.background.light10,
				fontSize: 14,
				fontWeight: '600',
			},

			// text
			text_m :{
				color: PALETTE.background.light10,
				fontSize: 14,
				fontWeight: '300',
			},
			text_m_justify:{
				color: PALETTE.background.light10,
				textAlign: 'justify',
				textJustify: 'inter-word',
				fontSize: 14,
				fontWeight: '300',
			},
			text_s :{
				color: PALETTE.background.light10,
				fontSize: 12,
				fontWeight: '300',
			},


			// values
			value:{
				fontSize: 18,
				fontWeight: 'bold',
				letterSpacing: '0.05em',
				color: PALETTE.text.secondary,
				whiteSpace: 'nowrap'
			},
			value_highlight:{
				fontSize: 40,
				fontWeight: 'bold',
				color: PALETTE.text.secondary,
				whiteSpace: 'nowrap'
			},

			//
			caption_m :{
				color: PALETTE.background.light20,
				fontSize: 12,
				textTransform: 'uppercase',
				fontWeight: '500',
			},
			caption_s :{
				color: PALETTE.background.light20,
				fontSize: 10,
				textTransform: 'uppercase',
				fontWeight: '500',
			},


			// Chat
			message:{
				fontSize: 14,
			},

			// link
			link_s:{
				cursor: 'pointer',
				color: PALETTE.primary.main,
				fontSize: 12,
				fontWeight: '600',
				textDecoration: 'none',
				transition: 'color 0.2s ease-in-out',

				'&:hover' :{
					color: PALETTE.secondary.main,
				}
			},
			// breadcrumb
			breadcrumb:{
				fontSize: 14,
				color: PALETTE.primary.main,
				whiteSpace: 'nowrap'
			},
		},
		components: {
			MuiListItemText:{
				styleOverrides:{
					primary:{
						fontSize: 14,
					},
					secondary:{
						fontSize: 10,
					},
				}
			},
			MuiButton: {
				styleOverrides:{
					root:{
						borderRadius: 8
					}
				},
			},
			MuiTextField:{
				styleOverrides:{
					root:{
						borderRadius: 8,
					},
				}
			},
			MuiInputBase:{
				styleOverrides:{
					root:{
						fontSize: 14,
						borderRadius: 8,
					},
				}
			},
			MuiFormLabel:{
				styleOverrides:{
					root:{
						fontSize: 14,
					},
				}
			},
			MuiOutlinedInput:{
				styleOverrides:{
					root:{
						fontSize: 14,
						borderRadius: 8,
					},
				}
			},
			MuiToggleButton: {
				styleOverrides:{
					root:{
						borderRadius: 8,
					}
				}
			},
			MuiPaper:{
				styleOverrides:{
					root:{
						borderRadius: 10
					}
				},
				variants: [{
					props: { variant: 'elevation' },
					style: {
						boxShadow: THEME_SETTINGS.boxShadow,
					}},
				]
			},
			MuiTooltip:{
				styleOverrides:{
					tooltip:{
						fontSize: '0.8rem',
						color: 'white',
						backgroundColor: PALETTE.background.light10,
						padding:'0.5rem',
						maxWidth: 500,
					}
				}
			},
			MuiDataGrid: {
				styleOverrides: {
				  	root: {
						border: 0,
				  	},
				  	cell: {
						fontSize: 14,
						'&:hover':{
							color: PALETTE.secondary.main,
						}
				  	},
				  	columnHeader: {
						fontSize: 12,
						color: PALETTE.background.light15,
						fontWeight: '700',
						'&:hover':{
							color: PALETTE.primary.main,
						}
				  	}
				}
			},
			MuiTabs: {
				styleOverrides: {
					root: {
						overflow: "visible"
					},
				}
			},
			MuiAccordion:{
				styleOverrides:{
					root:{
						border: `1px solid rgba(0, 0, 0, .125)`,
						boxShadow: 'none',
						borderRadius: '0px',
						'&:not(:last-child)': {
							borderBottom: '0px',
						},
						'&:before': {
							display: 'none',
						},
					},
				},
				defaultProps: {
					disableGutters: true,
					elevation: 0,
					square: true,
				},
			},
			MuiAccordionSummary:{
				styleOverrides:{
					root:{
						backgroundColor: 'rgba(28, 30, 36, .025)',
						flexDirection: 'row-reverse',
						'& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
							transform: 'rotate(90deg)',
						},
						'& .MuiAccordionSummary-content': {
							marginLeft: '10px',
						},
					},
				}
			},
			MuiAccordionDetails:{
				styleOverrides:{
					root:{
						backgroundColor: variables.backgroundColorContent,
						padding: '20px',
						borderTop: '1px solid rgba(0, 0, 0, .125)',
					},
				}
			},
			MuiLinearProgress:{
				styleOverrides:{
					root:{
						borderRadius: 8,
						height: 4,
					}
				}
			},
			MuiDialogTitle:{
				styleOverrides:{
					root:{
						fontSize: 20,
						fontWeight: '700',
						color: PALETTE.background.light10,
					}
				}
			},
			MuiDialogContentText:{
				styleOverrides:{
					root:{
						color: PALETTE.background.light10,
						fontSize: 14,
						fontWeight: '300',
					}
				}
			},
			MuiChip:{
				styleOverrides:{
					label:{
						fontSize: 13,
						lineHeight: 1,
					}
				}
			},
			MuiFormControlLabel:{
				styleOverrides:{
					label:{
						fontSize: 14,
					}
				}
			}
		},
		breakpoints: {
			values: {
				mobile: 0,
				tablet: 640,
				laptop: 1280,
				desktop: 1600,
			},
		},
	},
	deDE,
	coreDeDE
	);

export default theme