import { StrictMode } from 'react';
import { createRoot } from 'react-dom/client';

import App from './components/App';

import { ThemeProvider } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import GlobalStyles from '@mui/material/GlobalStyles';
import { LocalizationProvider } from '@mui/x-date-pickers-pro'
import { AdapterDateFns } from '@mui/x-date-pickers-pro/AdapterDateFns';
import { de } from 'date-fns/locale'

import theme, { PALETTE } from './components/theme'
import './index.scss';

import CustomSnackbarProvider from './components/Widgets/CustomSnackbarProvider';


const rootElement = document.getElementById('root');
const root = createRoot(rootElement);

root.render(
	<StrictMode>
		<LocalizationProvider adapterLocale={de} dateAdapter={AdapterDateFns}>
			<ThemeProvider theme={theme}>
				<CustomSnackbarProvider>
					<CssBaseline/>
					<GlobalStyles
						styles={{
							body: { backgroundColor: PALETTE.background.content },
						}}
					/>
					<App />
				</CustomSnackbarProvider>
			</ThemeProvider>
		</LocalizationProvider>
	</StrictMode>
)
