import { useState } from 'react';
import { ACTIONS } from '../TicketHelpers'
import { getCookie } from '../../../../../utils/getCookie';
import { Typography, Divider, Card, CardMedia, CardContent, CardActions, CardActionArea, Chip, FormGroup, FormControlLabel, Checkbox } from '@mui/material';

import { formatTimestamp } from '../../../../../utils/date_functions';

export const TicketCardLimited = ({ ticket, color, variant, dispatch, done, project_id}) =>{

    const [checked, setChecked] = useState(done);

    const handleChange = async(event) => {
        let check = event.target.checked
        const json = JSON.stringify({
            ticket_id: ticket.id,
            done: check
        })
        const res = await fetch('/api/project/' + project_id + '/ticket/share', {
            method: 'PATCH',
            headers: {
                'Content-Type': 'application/json',
                'X-CSRFToken': getCookie('csrftoken')
            },
            body: json,
        })
        if (res.ok) {
            setChecked(check);
        }
    };

    return(
        <Card
            sx={{
                // maxWidth: 500,
                minWidth: 250,
            }}
        >
            <CardActionArea onClick={() => dispatch({ type: ACTIONS.OPEN_TICKET_INFO, payload: { ticket: ticket }})}>
                <CardMedia
                    component="img"
                    height="194"
                    image={ticket.image}
                    alt="no image"
                />
                <CardContent>
                    <Typography gutterBottom variant="subtitle_m" component="div" sx={{margin: 0}}>
                        {ticket.title}
                    </Typography>
                    <Typography
                        variant="text_m"
                        component="div"
                        color= {ticket.provider_rights ? "primary" : "default"}
                    >
                        {ticket.service_provider.name}
                    </Typography>
                    <Typography gutterBottom variant="text_s" component="div">
                        Deadline: {formatTimestamp(ticket.deadline)}
                    </Typography>
                    <Chip
                        label={ticket.status}
                        variant={variant}
                        color={color}
                        sx={{mb:1}}
                    />
                    <Divider textAlign="left" sx={{m:1}}>
                        <Typography variant="subtitle_s" sx={{color: 'background.light10', fontWeight: 'bold', fontSize:15}}>
                            Beschreibung
                        </Typography>
                    </Divider>
                    <Typography
                        variant="text_m"
                        color="text.primary"
                        sx={{display: 'block', height: 80, overflow: 'scroll'}}
                    >
                        {ticket.instruction}
                    </Typography>
                </CardContent>
            </CardActionArea>
            <CardActions disableSpacing>
                <FormGroup
                    sx={{pl:1}}
                >
                    <FormControlLabel control={
                        <Checkbox
                            checked={checked}
                            onChange={handleChange}
                            color="primary"
                        />

                    } label="Ticket bearbeitet" />
                </FormGroup>
            </CardActions>
        </Card>
    )
}

export default TicketCardLimited