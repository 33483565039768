import { Paper, Stack, Typography } from '@mui/material';

const CompanyInfoCard = (props) => {

    const { title, subtitle, variant } = props

    if(variant === 'number'){
        return(
            <Paper>
                <Stack
                    direction={'row'}
                    spacing={2}
                    justifyContent='center'
                    alignItems='center'
                    sx={{p:2}}
                >
                    <Typography variant="subtitle_m" color='primary.main'>
                        {subtitle}
                    </Typography>
                    <Typography variant="text_s">
                        {title}
                    </Typography>
                </Stack>
            </Paper>
        )
    }
    return(
        <Stack>
            <Typography variant="subtitle_m" color='primary.main'>
                {title}
            </Typography>
            <Typography variant="text_m">
                {subtitle}
            </Typography>
        </Stack>
    )
}

export default CompanyInfoCard