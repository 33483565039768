import { useEffect, useState } from "react";
import { getCookie } from "../../../../../utils/getCookie";
import { Radio, Button,  DialogContent, Dialog, DialogTitle, DialogActions, FormControl, FormLabel, RadioGroup, FormControlLabel} from "@mui/material";


const TicketChangeStateDialog = (props) =>{

    const { open, onClose, ticket, states, project_id, getTicketChains } = props;

    const [state, setState] = useState('0');

    const handleChange = (event) => {
      setState(event.target.value);
    };

    const buildRadioItems = (states) =>{
        let temp = []
        for(const [key, value] of Object.entries(states)){
            temp.push(
                <FormControlLabel key={key} value={key} control={<Radio color="primary"/>} label={value}  />
            )
        }
        return temp
    }

    const changeTicketState = async () => {
        if(ticket == null) return
        const json = JSON.stringify({
            ticket_id: ticket.id,
            status_key: state
        })
        const res = await fetch('/api/project/' + project_id + '/tickets', {
            method: 'PATCH',
            headers: {
                'Content-Type': 'application/json',
                'X-CSRFToken': getCookie('csrftoken')
            },
            body: json,
        })
        const data = await res.json()
        if (res.ok) {
            getTicketChains()
            onClose()
        } else {
            console.log(data)
        }
    }

    useEffect(()=>{
        if(ticket!== null){
            setState(ticket?.status_key)
        }
    }, [ticket])

    return(
        <Dialog
            open={open}
            onClose={onClose}
            maxWidth={'tablet'}
        >
            <DialogTitle>
                Wähle den aktuellen Status
            </DialogTitle>
            <DialogContent>
                <FormControl>
                    <FormLabel>
                        Ticket: {ticket?.name}
                    </FormLabel>
                    <RadioGroup
                        value={state}
                        onChange={handleChange}
                    >
                        {buildRadioItems(states)}
                    </RadioGroup>
                </FormControl>
            </DialogContent>
            <DialogActions>
                <Button onClick={onClose} color="secondary">Schließen</Button>
                <Button onClick={changeTicketState} color="primary">Status ändern</Button>
            </DialogActions>
        </Dialog>
    )
}

export default TicketChangeStateDialog