import { useContext, useState, useRef, useEffect }  from 'react'
import Tree from 'react-d3-tree';

import AccountContext from '../../../../../context/AccountContext';

import { Box } from '@mui/material';

import { PALETTE } from '../../../../theme';
import CustomPaper from '../../../../Widgets/CustomPaper';


const renderCustomNode = ({
    nodeDatum,
    toggleNode,
    account
    }) => (
        <g onClick={toggleNode}>
            <circle
                fill={nodeDatum.id === account.company.id ? PALETTE.secondary.main : PALETTE.primary.main}
                r="10"
                strokeWidth="0"
            />
            <text fill="black" fontSize="16" strokeWidth="0.5" x="20" dy="-8">
                    {nodeDatum.name}
            </text>
            <text fontSize="14" x="20" dy="10" strokeWidth="0.3">
                {nodeDatum.attributes?.industry}
            </text>
            <text fontSize="11" x="20" dy="25" strokeWidth="0.1">
                {nodeDatum.attributes?.central_phone_number}
            </text>
            <text fontSize="11" x="20" dy="38" strokeWidth="0.1">
                {nodeDatum.attributes?.address}
            </text>
        </g>
);


const ProjectTeamTree = ({ data }) =>{

    const [account] = useContext(AccountContext)

    const orientation = 'vertical'
    const [translate, setTranslate] = useState({});
    const treeContainerRef = useRef(null);

    useEffect(() => {
        if (treeContainerRef !== null){
            const treeContainerNode = treeContainerRef.current;
            const { width, height } = treeContainerNode.getBoundingClientRect();
            setTranslate({ x: width / 2, y: height * ( 1 / 4 ) })
        }
    }, []);

    return(
        <CustomPaper
            title="Projekthierarchie"
            sx={{
                width:'100%',
                height: 600
            }}
        >
            <Box
                ref={treeContainerRef}
                height="100%"
                width="100%"
                sx={{
                    border: 1,
                    borderRadius: '10px',
                    borderColor: PALETTE.background.light70
                }}
            >
                <Tree
                    data={data}
                    translate={translate}
                    renderCustomNodeElement={(rd3tProps) =>
                        renderCustomNode({ ...rd3tProps, account })
                    }
                    orientation={orientation}
                    enableLegacyTransitions="true"
                    centeringTransitionDuration={500}
                    transitionDuration={400}
                    pathFunc="diagonal"
                    scaleExtent={Object({min: 0.1, max: 1.9})}
                    separation={Object({siblings: 1.8, nonSiblings: 3})}
                    //initialDepth={1}
                />
            </Box>
        </CustomPaper>
    )
}

export default ProjectTeamTree