import { useCallback, useEffect, useState } from "react";
import { useGridApiRef } from "@mui/x-data-grid-pro";

import MaterialTable from "./MaterialTable/MaterialTable"
import { CustomPDFMailCell, formatDate, formatPrice, formatValueWithUnit } from "./MaterialDatagridUtils";
import { getCookie } from "utils/getCookie";


const MaterialCorrectionTable = ({ open, onClose, project_id, selectedSubProject, subtitle, is_admin, is_manager, getValues, getSubProjectMaterial, sublabel }) =>{

    const apiRef = useGridApiRef();
	const [isLoading, setIsLoading] = useState(false)
    const [txs, setTxs] = useState([])
    const [pageSize, setPageSize] = useState(50)
	const [searchTerm, setSearchTerm] = useState('')
	const [orderBy, setOrderBy] = useState('');
    const [pagInfo, setPagInfo] = useState({
		count: 0,
		next : null,
		previous: null,
	});

    const handlePageChange = (x) =>{
		getCTX(pagInfo[x])
	}

	const handleOrderByGetParametersChange = params => {
		setOrderBy(params)
	}

    const getCTX = useCallback(async(url) => {
		if(selectedSubProject === undefined || open === false) return
		setIsLoading(true)
        const res = await fetch(url ? url :'/api/project/' + project_id + '/sub-project/' + selectedSubProject + '/material/tx/correction-tx?limit=' + pageSize + '&search=' + searchTerm + '&' + orderBy,  {
            method: 'GET'
        })
		const data = await res.json()
		if(res.ok){
			setTxs(data.results)
			setPagInfo({
				count: data.count,
				next : data.next,
				previous: data.previous,
			})
			// console.log(data)
		}
		setIsLoading(false)
    }, [project_id, selectedSubProject, pageSize, open, searchTerm, orderBy])

    useEffect(()=>{
		getCTX()
	}, [open, getCTX, pageSize])

	const handleCTXPatch = async(row) => {
		const json = JSON.stringify({
			id : row.id,
			done: row.done,
		})
		const res = await fetch('/api/project/' + project_id + '/sub-project/'+ selectedSubProject +'/material/tx/correction-tx', {
			method: 'PATCH',
			headers: {
				'Content-Type': 'application/json',
				'X-CSRFToken': getCookie('csrftoken')
			},
			body: json
		})
		if(res.ok){
			getCTX()
			getValues()
			getSubProjectMaterial()
		}
    }

	const handleCTXDelete = async(id) => {
        const json = JSON.stringify({
			ctx_id: id
		})
        const res = await fetch('/api/project/' + project_id + '/sub-project/'+ selectedSubProject +'/material/tx/correction-tx', {
            method: 'DELETE',
            headers: {
                'Content-Type': 'application/json',
                'X-CSRFToken': getCookie('csrftoken')
            },
            body: json
        })
		if(res.ok){
			getCTX()
			getValues()
			getSubProjectMaterial()
		}
		return res.ok
    }

	const columns = [
		{
			field: 'custom_id',
			headerName: 'R-ID',
			type: 'string',
			minWidth: 40,
			editable: false,
			valueFormatter: (params) => {
				if (params.value == null) {
				  return '';
				}
				return `#R${params.value}`;
			},
			sortable: false,
		},
		{
			field: 'unique_material_id',
			headerName: 'Artikel',
			type: 'string',
			minWidth: 50,
			editable: false,
			sortable: false,
		},
		{
			field: 'description',
			headerName: 'Artikelbezeichnung',
			type: 'string',
			minWidth: 400,
			editable: false,
			sortable: false,
		},
		{
			field: 'value',
			headerName: 'Menge',
			type: 'number',
			minWidth: 100,
			editable: false,
			valueFormatter: formatValueWithUnit
		},
		{
			field: 'type',
			headerName: 'Art der Transaktion',
			type: 'string',
			minWidth: 120,
			editable: false,
			sortable: false,
		},
		{
			field: 'supplier_name',
			headerName: 'Lieferant',
			type: 'string',
			minWidth: 150,
			editable: false,
			sortable: false,
		},
		{
			field: 'supplier_contact_person',
			headerName: 'Ansprechpartner',
			type: 'string',
			minWidth: 100,
			editable: false,
			sortable: false,
		},
		{
			field: 'annotation',
			headerName: 'Bemerkung',
			type: 'string',
			flex:'1',
			minWidth: 80,
			editable: false,
			sortable: false,
		},
		{
			field: 'creator',
			headerName: 'Ersteller',
			type: 'string',
			minWidth: 150,
			sortable: false,
		},
		{
			field: 'file',
			headerName: 'Dokument',
			type: 'actions',
			minWidth: 10,
			renderCell: CustomPDFMailCell,
			editable: false,
			sortable: false,
		},
		{
			field: 'done',
			headerName: 'Versendet',
			type: 'boolean',
			editable: is_manager ? true : false,
			minWidth: 40,
		},
		{
			field: 'created',
			headerName: 'erstellt am',
			minWidth: 150,
			align: 'right',
			editable: false,
			valueGetter: formatDate,
		},
	]

	const getConfirmMessage = row => `Möchtest du die Transaktion wirklich löschen?`

	return(
        <MaterialTable
            open={open}
            onClose={onClose}
			title="Rückgabe"
			subtitle={subtitle}
			sublabel={sublabel}
            apiRef={apiRef}
            rows={txs}
            pageSize={pageSize}
            setPageSize={setPageSize}
			rowCount={pagInfo.count}
            handlePageChange={handlePageChange}
            columns={columns}
            loading={isLoading}
            initialPageSize={100}
			onSaveRow={is_admin ? handleCTXPatch : undefined}
			onDeleteRow={is_admin ? handleCTXDelete : undefined}
			getConfirmMessage={is_admin ? getConfirmMessage : undefined}
			setSearchTerm={setSearchTerm}
			searchTerm={searchTerm}
			onOrderByGetParametersChange={handleOrderByGetParametersChange}
        />
    )
}

export default MaterialCorrectionTable
