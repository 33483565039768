import { Stack, Typography, Box } from '@mui/material';
import { FilterAlt } from '@mui/icons-material';

import TooltipIconButton from '../../TooltipIconButton';
import { PALETTE } from '../../../theme';
import {GridToolbarContainer, GridToolbarQuickFilter} from "@mui/x-data-grid-pro";



const Toolbar = (props) => {

    const { apiRef, title, actionBar, hideSearch } = props;

    return (
        <GridToolbarContainer>
            <Stack
                direction='row'
                justifyContent='space-between'
                alignItems='center'
                width='100%'
                sx={{ minHeight: 60, pl:2, pr:2, pt:1 , pb:1}}
            >
                <Typography variant='title_paper' color={PALETTE.primary.main}>{title}</Typography>
                <Stack
                    direction='row'
                    spacing={1}
                >
                    {actionBar}
                    <TooltipIconButton
                        title="Depot filtern"
                        icon={<FilterAlt />}
                        onClick={()=> apiRef.current.showFilterPanel()}
                    />
                    {!hideSearch &&
                        <Box
                            sx={{
                                p: 0.5,
                            }}
                        >
                            <GridToolbarQuickFilter
                                quickFilterParser={(searchInput) =>
                                    searchInput
                                        .split(',')
                                        .map((value) => value.trim())
                                        .filter((value) => value !== '')
                                }
                                debounceMs={200}
                                variant="outlined"
                                size='small'
                                color='primary'
                                sx={{
                                    maxWidth: 250,
                                    '& .MuiSvgIcon-root':{
                                        color: 'primary.main'
                                    }
                                }}
                            />
                        </Box>
                    }
                </Stack>
            </Stack>
        </GridToolbarContainer>
    );
}

export default Toolbar