import { Accordion, AccordionSummary, AccordionDetails } from "@mui/material"
import { ArrowForwardIosSharp } from "@mui/icons-material"

const CustomLightWeightAccordion = ({title, accordionbar, children, disabled=false }) =>{

    return(
        <Accordion
            id={`${title}-accordion`}
            disabled={disabled}
            sx={{
                margin: 0,
                border: 'none',
                backgroundColor: 'none',
                '&.Mui-disabled':{
                    background: 'none',
                }
            }}
        >
            <AccordionSummary
                expandIcon={<ArrowForwardIosSharp fontSize={'small'}/>}
                id={`${title}-accordion-header`}
                sx={{
                    backgroundColor: '#fff'
                }}
            >
                {accordionbar}
            </AccordionSummary>
            <AccordionDetails
                sx={{
                    border: 'none',
                    padding: 0,
                    pt: 1,
                    pb: 2,
                    backgroundColor: '#fff'
                }}
            >
                {children}
            </AccordionDetails>
        </Accordion>
    )
}

export default CustomLightWeightAccordion