import { Stack, Typography } from "@mui/material"
import { formatNumber } from "../../../../utils/helper"



const TxInfoStack = ({ tx }) =>{
    const unit_str = tx.unit.split('/')
    const unit_suf = unit_str[1]

    return(
        <Stack
            sx={{flex: 1}}
            gap={0.2}
        >
            <Typography
                    variant="text_m"
                >
                    {`Bestell-ID:  ${tx.custom_id}`}
            </Typography>
            <Typography
                    variant="subtitle_s"
                >
                    {tx.description}
            </Typography>
            <Typography
                    variant="caption_m"
                >
                    {`Art.-Nr. ${tx.unique_material_id}`}
            </Typography>

            <Typography
                    variant="text_m"
                >
                    {`${tx.supplier_name} | ${tx.supplier_contact_person}`}
            </Typography>
            <Typography
                    variant="text_s"
                >
                    {`Standardpreis ${formatNumber(tx.default_price, 2, tx.unit)} | Vorkalk. ${formatNumber(tx.pre_price, 2, tx.unit)} | Bestelleinzelpreis ${formatNumber(tx.post_price, 2, tx.unit)}`}
            </Typography>
            <Typography
                    variant="text_m"
                    color="primary"
                    sx={{ fontWeight: 'bold', fontSize: 15}}
                >
                    {`Bestellmenge: ${tx.value} ${unit_suf} `}
            </Typography>
            <Typography
                    variant="text_s"
                >
                    {`bestellt von ${tx.creator}`}
            </Typography>
        </Stack>
    )
}

export default TxInfoStack