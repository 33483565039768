import { useState, useEffect } from 'react'
import { Route, Routes, useLocation }  from 'react-router-dom';

import { CompanyProvider } from '../../../context/CompanyContext'

import CompanyOverview from './CompanyOverview'
import CompanyInvite from './CompanyInvite'
import CompanyManagement from './CompanyManagement';
import CheckPermission from '../../CheckPermission';
import LoadingScreen from '../../LoadingScreen';
import CompanyStatistic from './CompanyStatistic';
import Paywall from "../../Widgets/Paywall";
import CompanyWages from './CompanyWages';
import CompanyWageRate from './CompanyWageRate';

const Company = () => {
    const location = useLocation()

    const [company, setCompany] = useState({
        address: {},
    })
    const [companyMembers, setCompanyMembers] = useState([])
    const [companyDisabledMembers, setCompanyDisabledMembers] = useState([])
    const [companyLoaded, setCompanyLoaded] = useState(false)
    const [companyMembersLoaded, setCompanyMembersLoaded] = useState(false)

    const getCompany = async () => {
        const res = await fetch('/api/company', {
            method: 'GET',
        })
        if (res.ok) {
            const data = await res.json()
            setCompany(data)
            setCompanyLoaded(true)
            // console.log(data)
        }
    }

    const getCompanyMembers = async () => {
        const res = await fetch('/api/company/members?includeDisabled=1', {
            method: 'GET',
        })
        if (res.ok) {
            const data = await res.json()
            setCompanyMembers(data.filter(x => x.user !== null))
            setCompanyDisabledMembers(data.filter(x => x.user === null))
            setCompanyMembersLoaded(true)
        }
    }

    useEffect(() => {
        getCompany();
        getCompanyMembers();
    },[])

    if (!companyLoaded || !companyMembersLoaded){
        return(
            <LoadingScreen/>
        )
    }

    return(
        <CompanyProvider value={[company, companyMembers, getCompany, getCompanyMembers, companyDisabledMembers]}>
            <Routes>
                <Route path='' element={<CompanyOverview />} />
                <Route path='statistics' element={
                    <Paywall>
                        <CheckPermission redirectTo={location.pathname}>
                            <CompanyStatistic/>
                        </CheckPermission>
                    </Paywall>
                } />
                <Route path='management' element={
                    <CheckPermission redirectTo={location.pathname} onlyAdmin={true}>
                        <CompanyManagement />
                    </CheckPermission>
                } />
                <Route path='salaries' element={
                    <CheckPermission redirectTo={location.pathname} onlyAdmin={true}>
                        <CompanyWages/>
                    </CheckPermission>
                } />
                <Route path='wage-rate' element={
                    <CheckPermission redirectTo={location.pathname} onlyAdmin={true}>
                        <CompanyWageRate/>
                    </CheckPermission>
                } />
                <Route path='invite' element={
                    <CheckPermission redirectTo={location.pathname}>
                        <CompanyInvite />
                    </CheckPermission>
                } />
            </Routes>
        </CompanyProvider>
    )
}

export default Company;