import { Fragment } from 'react'
import { Controller } from 'react-hook-form'

import { Stack, TextField, Tooltip, Typography, InputAdornment } from '@mui/material'

const ControlledTextFieldWithLeftTextAndToolTip = ({ control, name, type, rules, errors, variant, label, suffix, multiline, rows, tooltip, leftText, disabled, readOnly=false}) => {
    return (
        <Stack
            direction='row'
            justifyContent="space-between"
            alignItems='center'
        >
            <Tooltip title={tooltip} arrow>
                <Typography variant="text_m">{leftText}</Typography>
            </Tooltip>
            <Controller
                control={control}
                name={name}
                rules={rules}
                render={({field: { value, onChange }}) => (
                    <TextField
                        type={type}
                        value={value}
                        onChange={onChange}
                        error={Boolean(errors[name])}
                        variant={variant}
                        label={label}
                        multiline={multiline}
                        rows={rows}
                        disabled={disabled}
                        fullWidth
                        size="small"
                        InputProps={{
                            readOnly: readOnly,
                            endAdornment: <InputAdornment position="end">{suffix}</InputAdornment>,
                            inputProps: {
                                // style: { textAlign: "right" },
                            }
                        }}
                        sx={{
                            '& .MuiOutlinedInput-root':{
                                backgroundColor: readOnly ? 'secondary.light' : 'none',
                            },
                            flexBasis: 180,
                            flexShrink: 0
                        }}
                        helperText={<>
                            {errors[name]?.type === 'server' ?
                                errors[name].message.map((m, i) => (
                                    <Fragment key={i}>
                                        {m}
                                        <br />
                                    </Fragment>
                                ))
                                :
                                errors[name]?.message
                            }
                        </>}
                    />
                )}
            />
        </Stack>

    )
}

export default ControlledTextFieldWithLeftTextAndToolTip