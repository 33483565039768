import { useState, useEffect, useContext } from "react";
import { useForm } from "react-hook-form";

import CompanyContext from '../../../../context/CompanyContext'
import { Grid } from '@mui/material';

import { getCookie } from '../../../../utils/getCookie'
import { getConstants } from '../../../../utils/getConstants'

import ControlledTextField from "../../../Widgets/ControlledInputs/ControlledTextField";
import ControlledSelect from '../../../Widgets/ControlledInputs/ControlledSelect';
import CustomFormPaper from "../../../Widgets/CustomFormPaper";


const CompanyUpdateForm = () =>{

    const [company, , getCompany] = useContext(CompanyContext)
    const [industries, setIndustries] =  useState({})
    const [loading, setLoading] = useState(false)
    const [success, setSuccess] = useState(false)

    const { control, handleSubmit, setValue, formState: {errors, isDirty, isValid }} = useForm({
        mode: "onChange",
        defaultValues: {
            name: company.name,
            industry_key: "",
            central_phone_number: company.central_phone_number,
            street: company.address.street,
            house_number: company.address.house_number,
            add_on: company.address.add_on,
            postal_code: company.address.postal_code,
            city: company.address.city,
        }
    });

    const updateCompanyData = async(data) => {
        setLoading(true)
        const json = JSON.stringify({
            ...data,
            address: {
                street: data.street,
                house_number: data.house_number,
                add_on: data.add_on,
                postal_code: data.postal_code,
                city: data.city
            }
        })
        const res = await fetch('/api/company', {
            method: 'PATCH',
            headers: {
                'Content-Type': 'application/json',
                'X-CSRFToken': getCookie('csrftoken')
            },
            body: json
        })
        if (res.ok) {
            getCompany()
            setLoading(false)
            setSuccess(true)
        } else{
            setLoading(false)
        }
    }

    useEffect(() => {
        setTimeout(() => {
            setSuccess(false)
        }, 3000);
        if(Object.keys(industries).length !== 0) {
            setValue("industry_key", company.industry_key)
        } else {
            getConstants('industries', (c) => setIndustries(c))
        }
    }, [industries, company.industry_key, setValue])

    return(
        <CustomFormPaper
            title="Unternehmensdaten ändern"
            onSubmit={handleSubmit(updateCompanyData)}
            buttonTitle="Aktualisieren"
            success={success}
            loading={loading}
            disabled={!isDirty ||!isValid}
        >
            <Grid
                container
                justifyContent="flex-start"
                alignItems="flex-start"
                spacing={2}
            >
                <Grid item mobile={12} laptop={6} desktop={6}>
                    <ControlledTextField
                        control={control}
                        name="name"
                        label="Name des Unternehmens"
                        rules={{ required: true }}
                        errors={errors}
                    />
                </Grid>
                <Grid item mobile={12} laptop={6} desktop={6}>
                    <ControlledSelect
                        control={control}
                        name="industry_key"
                        label="Branche"
                        rules={{ required: true }}
                        errors={errors}
                        items={industries}
                    />
                </Grid>
                <Grid item mobile={12} laptop={6} desktop={6}>
                    <ControlledTextField
                        control={control}
                        name="central_phone_number"
                        label="Zentrale Telefonnummer"
                        rules={{ required: true }}
                        errors={errors}
                    />
                </Grid>
                <Grid item mobile={12} laptop={8} desktop={8}>
                    <ControlledTextField
                        control={control}
                        name="street"
                        label="Straße"
                        rules={{ required: true }}
                        errors={errors}
                    />
                </Grid>
                <Grid item mobile={12} laptop={4} desktop={4}>
                    <ControlledTextField
                        control={control}
                        name="house_number"
                        label="Hausnr."
                        rules={{ required: true }}
                        errors={errors}
                    />
                </Grid>
                <Grid item mobile={12} laptop={12} desktop={12}>
                    <ControlledTextField
                        control={control}
                        name="add_on"
                        label="Zusatz"
                        rules={{ required: false }}
                        errors={errors}
                    />
                </Grid>
                <Grid item mobile={12} laptop={4} desktop={4}>
                    <ControlledTextField
                        control={control}
                        name="postal_code"
                        label="PLZ"
                        rules={{
                            required: true,
                            minLength: { value: 5, message: "Es gibt keine Postleitzahl mit weniger als 5 Ziffern!"},
                            maxLength: { value: 5, message: "Es gibt keine Postleitzahl mit mehr als 5 Ziffern!"}
                        }}
                        errors={errors}
                    />
                </Grid>
                <Grid item mobile={12} laptop={8} desktop={8}>
                    <ControlledTextField
                        control={control}
                        name="city"
                        label="Stadt"
                        rules={{ required: true }}
                        errors={errors}
                    />
                </Grid>
            </Grid>
        </CustomFormPaper>
    )
}

export default CompanyUpdateForm
