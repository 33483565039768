import { ITEMS as DEFAULT_ITEMS, DROPDOWNS as DEFAULT_DROPDOWNS, PROJECT as DEFAULT_PROJECT_ITEMS  } from './Default'


const ITEMS = {
}

const PROJECT_ITEMS = {
}


export const NavbarARCH = [
    [
        {
            ...DEFAULT_ITEMS.projects,
            dropDown: Object.values(DEFAULT_DROPDOWNS.projects)
        },
        DEFAULT_ITEMS.calendar,
        DEFAULT_ITEMS.internalChat,
        DEFAULT_ITEMS.todo,
        {
            ...DEFAULT_ITEMS.timerecording,
            dropDown: Object.values(DEFAULT_DROPDOWNS.timerecording)
        },
        {
            ...DEFAULT_ITEMS.company,
            dropDown: Object.values(DEFAULT_DROPDOWNS.company)
        },
        DEFAULT_ITEMS.customer,
        DEFAULT_ITEMS.supplier,
        DEFAULT_ITEMS.conmeetConnections,
    ],
]
export const ProjectToolbarARCH = [
    [
    ],
    [
        DEFAULT_PROJECT_ITEMS.tasks,
        DEFAULT_PROJECT_ITEMS.chat,
        DEFAULT_PROJECT_ITEMS.members,
        DEFAULT_PROJECT_ITEMS.timestamps,
        DEFAULT_PROJECT_ITEMS.agreements,
        DEFAULT_PROJECT_ITEMS.events,
        DEFAULT_PROJECT_ITEMS.notes,
        DEFAULT_PROJECT_ITEMS.todos,
        DEFAULT_PROJECT_ITEMS.tickets,
        DEFAULT_PROJECT_ITEMS.documents,
        DEFAULT_PROJECT_ITEMS.chatmedia,
        DEFAULT_PROJECT_ITEMS.invite,
        DEFAULT_PROJECT_ITEMS.documentation,
        DEFAULT_PROJECT_ITEMS.management,
    ]
]
