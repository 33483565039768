import { getCookie } from "../../../../../utils/getCookie";
import { Button,  DialogContent, DialogContentText, Dialog, DialogTitle, DialogActions, Stack, Typography} from "@mui/material";


const TicketDeleteDialog = (props) =>{

    const { open, onClose, ticket, project_id, getTicketChains } = props;

    const handleDeleteTicket = async () => {
        if(ticket == null) return
        const json = JSON.stringify({
            ticket_id: ticket.id,
        })
        const res = await fetch('/api/project/' + project_id + '/tickets', {
            method: 'DELETE',
            headers: {
                'Content-Type': 'application/json',
                'X-CSRFToken': getCookie('csrftoken')
            },
            body: json,
        })
        if (res.ok) {
            getTicketChains()
            onClose()
        }
    }

    return(
        <Dialog
            open={open}
            onClose={onClose}
            fullWidth={true}
            maxWidth={'tablet'}
        >
            <DialogTitle>
                Ticket löschen
            </DialogTitle>
            <DialogContent sx={{flexGrow: 1}}>
                <DialogContentText>
                    Möchtest du wirklich das Ticket löschen?
                </DialogContentText>
                <Stack
                    alignItems='center'
                    sx={{ p: 5 }}
                >
                    <Typography variant="subtitle_m">{`${ticket?.title}`}</Typography>
                </Stack>
            </DialogContent>
            <DialogActions>
                <Button onClick={onClose} color="secondary">Nein</Button>
                <Button
                    color="primary"
                    autoFocus
                    onClick={handleDeleteTicket}
                >
                    Ja
                </Button>
            </DialogActions>
        </Dialog>
    )
}

export default TicketDeleteDialog