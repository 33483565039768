import { useState } from 'react';

import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';

import './style.css'

const ImagePopup = ({ src }) => {
    const [open, setOpen] = useState(false);

    const handleClickOpen = () => {
        setOpen(true)
    }

    const handleClose = () => {
        setOpen(false)
    }

    return (
        <div className='ImagePopup'>
            <div
                className='preview'
                onClick={handleClickOpen}
                style={{
                    backgroundColor: 'black',
                    aspectRatio: 1/1
                }}
            >
                <img
                    src={src}
                    alt=''
                    style={{
                        objectFit: 'cover'
                    }}
                />
            </div>
            <Dialog open={open} onClose={handleClose}>
                <DialogTitle>Foto</DialogTitle>
                <DialogContent
                    sx={{
                        'overflow': 'hidden',
                        '& > img': {
                            maxWidth: '100%',
                            maxHeight: 'calc(100vh - 64px - 64px - 52.5px)',
                        }
                    }}
                >
                    <img src={src} alt='' />
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose}>Schließen</Button>
                </DialogActions>
            </Dialog>
        </div>
    )
}

export default ImagePopup