import { Stack, Typography, Grid } from '@mui/material'
import { formatDateString } from 'utils/date_functions'

import ProjectCardWrapperLimited from '../ProjectCardWrapperLimited'
import ProjectIconBar from '../ProjectIconBar'
import '../ProjectsCard.css'


const ProjectLeaderCard = ({ project }) =>{

    return(
        <ProjectCardWrapperLimited
            project={project}
        >
            <Grid
                container
                spacing={1}
            >
                <Grid
                    item
                    desktop={6}
                    labtop={6}
                    tablet={6}
                    mobile={12}
                >
                    <Stack>
                        <Typography variant="caption_s">Projektleitung</Typography>
                        <Typography variant="text_s">{project.company.name}</Typography>
                    </Stack>
                </Grid>
                <Grid
                    item
                    desktop={6}
                    labtop={6}
                    tablet={12}
                    mobile={12}
                >
                    <Stack>
                        <Typography variant="caption_s">Projektadresse</Typography>
                        <Typography variant="text_s"> {`${project.address.street} ${project.address.house_number}, ${project.address.postal_code} ${project.address.city}`}</Typography>
                    </Stack>
                </Grid>
                <Grid
                    item
                    desktop={6}
                    labtop={6}
                    tablet={12}
                    mobile={12}
                >
                    <Stack>
                        <Typography variant="caption_s">Auftrag</Typography>
                        <Typography variant="text_s"> {`--`}</Typography>
                    </Stack>
                </Grid>
                <Grid
                    item
                    desktop={6}
                    labtop={6}
                    tablet={12}
                    mobile={12}
                >
                    <Stack>
                        <Typography variant="caption_s">Projektzeitraum</Typography>
                        <Typography variant="text_s">
                            {formatDateString(project.expected_to_start)} - {formatDateString(project.expected_to_end)}
                        </Typography>
                    </Stack>
                </Grid>
            </Grid>
            <Stack
                justifyContent='flex-end'
                alignItems='flex-end'
                height="100%"
                spacing={1}
            >
                <ProjectIconBar
                    official={project.official}
                    members={project.all_members}
                    companies={project.all_companies}
                />
            </Stack>
        </ProjectCardWrapperLimited>
    )
}
export default ProjectLeaderCard