import { Button, Dialog, DialogTitle, DialogContent, Stack, DialogActions } from '@mui/material'
import { useForm } from 'react-hook-form'

import ControlledTextField from '../../../Widgets/ControlledInputs/ControlledTextField';
import { getCookie } from "../../../../utils/getCookie"
import ControlledSwitch from '../../../Widgets/ControlledInputs/ControlledSwitch';


const AddNote = ({ open, onClose, project, getProjectNotes }) => {

    const { control, handleSubmit, reset, formState: { errors, isDirty, isValid }} = useForm({
        mode: 'onChange',
        defaultValues: {
            text: '',
            private: false,
        }
    })

    const addProjectNote = async(data) =>{
        const json = JSON.stringify(data)
        const res =  await fetch('/api/project/' + project.id + '/notes', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'X-CSRFToken': getCookie('csrftoken')
            },
            body: json
        })
        if(res.ok){
            reset()
            getProjectNotes()
            onClose()
        }
    }

    const handleClose = () => {
        reset()
        onClose()
    }

    return (
        <Dialog
            maxWidth="tablet"
            open={Boolean(open)}
            onClose={handleClose}
            fullWidth
        >
            <DialogTitle>Neue Projektinformation erstellen</DialogTitle>
            <DialogContent>
                <Stack
                    component="form"
                    id="add-note-form"
                    spacing={2}
                    onSubmit={handleSubmit(addProjectNote)}
                    sx={{pt:1}}
                >
                    <ControlledTextField
                        name="text"
                        control={control}
                        rules={{required: true}}
                        errors={errors}
                        rows={10}
                        multiline={true}
                        placeholder="Los geht's..."
                    />
                    <ControlledSwitch
                        name="private"
                        label="Als private Projektinformation erstellen"
                        control={control}
                        rules={{required: false}}
                        errors={errors}
                    />
                </Stack>
            </DialogContent>
            <DialogActions>
                <Button
                    onClick={handleClose}
                    color="secondary"
                >
                    Abbrechen
                </Button>
                <Button
                    type='submit'
                    form="add-note-form"
                    disabled={!isValid || !isDirty}
                >
                    Erstellen
                </Button>
            </DialogActions>
        </Dialog>
    )
}

export default AddNote