import { Stack } from '@mui/material'
import ProjectCardWrapper from '../ProjectCardWrapper'

import '../ProjectsCard.css'
import NoData from 'components/Widgets/NoData'

const ProjectCard = ({ project }) =>{
    return(
        <ProjectCardWrapper
            project={project}
        >
            <Stack
                justifyContent='center'
                alignItems='center'
                spacing={1}
                height="100%"
            >
                <NoData text="Derzeit kein Projektauftrag"/>
            </Stack>
        </ProjectCardWrapper>
    )
}

export default ProjectCard