import { useState, useEffect, useContext, useCallback, useMemo } from "react";
import { Stack} from '@mui/material';
import { Folder } from '@mui/icons-material';

import { BsTable, BsGraphUp, BsGraphDown, BsPencilSquare, BsBoxSeam, BsListCheck, BsFileEarmarkPlusFill, BsArrowReturnLeft } from "react-icons/bs";
import { MdLocalGroceryStore, MdPlaylistAdd, MdTimeline, MdStoreMallDirectory, MdLocalOffer } from "react-icons/md";

import ProjectContext from "../../../../context/ProjectContext";
import AccountContext from "../../../../context/AccountContext";
import { useTaskTab } from "../../../../context/TaskTabContext";

import CheckSubProjects from "../../../CheckSubProjects";

import CustomTabs from "../../../Widgets/CustomTabs";
import CustomIconPaperButton from "../../../Widgets/CustomIconPaperButton";
import CustomIconDoublePaperButton from "../../../Widgets/CustomIconDoublePaperButton";
import CustomIconTriplePaperButton from "../../../Widgets/CustomIconTriplePaperButton";

import ActivityDialog from "./MaterialDialogs/ActivityDialog";
import MargeDialog from "./MaterialDialogs/MargeDialog";
import SearchMaterialDialog from "./MaterialDialogs/SearchMaterialDialog/SearchMaterialDialog";
import WarehouseMaterialDialog from "./MaterialDialogs/WarehouseMaterialDialog";
import UsedMaterialDialog from "./MaterialDialogs/UsedMaterialDialog";
import OrderMaterialDialog from "./MaterialDialogs/OrderMaterialDialog";

import { formatNumber } from "../../../../utils/helper";
import { getCookie } from "../../../../utils/getCookie";
import { PALETTE } from '../../../theme'

import MaterialTxTable from "./MaterialTables/MaterialTxTable";
import UsedMaterialTable from "./MaterialTables/MaterialUsedTable";
import MaterialOrderTable from "./MaterialTables/MaterialOrderTable";
import ProjectMaterialTable from "./MaterialTables/ProjectMaterialTable";
import MaterialCorrectionTable from "./MaterialTables/MaterialCorrectionTable";
import { FaWarehouse } from "react-icons/fa";
import OfferDialog from "./MaterialDialogs/OfferDialog";
import ChargeBackDialog from "./MaterialDialogs/ChargeBackDialog";
import ImportDialog from "./MaterialDialogs/ImportDialog";
import CorrectionMaterialDialog from "./MaterialDialogs/CorrectionMaterialDialog";
import SupplierRequestDialog from "./MaterialDialogs/SupplierRequestDialog";
import SupplierResponseDialog from "./MaterialDialogs/SupplierResponseDialog";


const ICONSIZE = 38


const ProjectMaterial = () => {

    const [account] = useContext(AccountContext)
	const [project, , , , , , _subProjects] = useContext(ProjectContext)
	const [suppliers, setSuppliers] = useState([])

	const subProjects = useMemo(() => _subProjects !== undefined ? _subProjects : {},[_subProjects])
	const tabs = Object.entries(subProjects).map(([key, value], idx) => ({ index: idx, id: key, label: value, icon: <Folder sx={{fontSize: '20px'}}/> }))

	const [searchTerm, setSearchTerm] = useState('');
	const [orderBy, setOrderBy] = useState('');

	const handleOrderByGetParametersChange = params => {
		setOrderBy(params)
	}

	const [value, setValue] = useTaskTab();
    const handleTabChange = (newValue) => {
		setSelectedSubProject(Object.keys(subProjects)[newValue])
        setValue(newValue);
    };

	const [values, setValues] = useState({
		'activities_count': 0,
		'general_tx_count': 0,
		'used_tx_count': 0,
		'order_tx_count': 0,
		'correction_tx_count': 0,
		'current_material': 0,
		'max_material': 0,
		'general_tx_sum': 0,
		'order_post_sum': 0,
		'missing_materials_count': 0,
		'supplier_request_count': 0
	})
	const [materials, setMaterials] = useState([]);
	const [pagMaterialsInfo, setPagMaterialsInfo] = useState({
		count: 0,
		next : null,
		previous: null,
	});
	const [isLoading, setIsLoading] = useState(false)
	const [pageSize, setPageSize] = useState(50)

	const [selectedRows, setSelectedRows] = useState([]);
	const [selectedSubProject, setSelectedSubProject] = useState();

	useEffect(() => {
		if (Object.keys(subProjects).length === 0) return
		setSelectedSubProject(Object.keys(subProjects)[value])
	}, [subProjects, value])

	const [openActivity, setOpenActivity] = useState(false)
	const [openMarge, setOpenMarge] = useState(false)
	const [openFill, setOpenFill] = useState(false)
    const [openAdd, setOpenAdd] = useState(false)
    const [openUsed, setOpenUsed] = useState(false)
    const [openOrder, setOpenOrder] = useState(false)
	const [openOffer, setOpenOffer] = useState(false)
	const [openSupplierRequest, setOpenSupplierRequest] = useState(false)
	const [openCorrection, setOpenCorrection] = useState(false)
	const [openChargeBack, setOpenChargeBack] = useState(false)
	const [openImport, setOpenImport] = useState(false)
	const [openSupplierResponse, setOpenSupplierResponse] = useState(false)

	const [openTableAdd, setOpenTableAdd] = useState(false)
	const [openTableUsed, setOpenTableUsed] = useState(false)
	const [openTableOrder, setOpenTableOrder] = useState(false)
	const [openTableCorrection, setOpenTableCorrection] = useState(false)

	const handlePageChange = (x) =>{
		getSubProjectMaterial(pagMaterialsInfo[x])
	}

	const getSubProjectMaterial = useCallback(async(url) => {
		if(selectedSubProject === undefined) return
		setIsLoading(true)
        const res = await fetch(url ? url :'/api/project/' + project.id + '/sub-project/' + selectedSubProject + '/material?limit=' + pageSize + '&search=' + searchTerm  + '&' + orderBy, {
            method: 'GET'
        })
		const data = await res.json()
		if(res.ok){
			setMaterials([...selectedRows, ...data.results].filter((value, index, self) =>
				index === self.findIndex((t) => (
					t.id === value.id
				))
			))
			setPagMaterialsInfo({
				count: data.count,
				next : data.next,
				previous: data.previous,
			})
		}
		setIsLoading(false)
    }, [project.id, selectedSubProject, pageSize, searchTerm, orderBy])

	const getValues = useCallback(async() => {
		if(selectedSubProject === undefined) return
        const res = await fetch('/api/project/' + project.id + '/sub-project/' + selectedSubProject + '/material/values', {
            method: 'GET'
        })
		const data = await res.json()
		if(res.ok){
			setValues(data)
			// console.log(data)
		}
    }, [project.id, selectedSubProject])

	const getSuppliers = async () => {
        const res = await fetch('/api/company/supplier', {
            method: 'GET',
        })
        const _data = await res.json()
        if (res.ok) {
            const transformed = {}
            for (const supplier of _data) {
                transformed[supplier.id] = supplier.name + ' / ' + supplier.contact_person
            }
            setSuppliers(transformed)
        }
    }

	useEffect(()=>{
		getSuppliers()
	}, [])

	useEffect(()=>{
		getValues()
		getSubProjectMaterial()
	}, [getValues, getSubProjectMaterial])

	useEffect(()=>{
		getSubProjectMaterial()
	}, [pageSize, getSubProjectMaterial])

	const handleTXPatch = (getTx) =>  async(row) => {
		const json = JSON.stringify({
			id : row.id,
			post_price: row.post_price,
			is_calculated: row.is_calculated,
			done: row.done,
		})
		const res = await fetch('/api/project/' + project.id + '/sub-project/'+ selectedSubProject +'/material/tx/general', {
			method: 'PATCH',
			headers: {
				'Content-Type': 'application/json',
				'X-CSRFToken': getCookie('csrftoken')
			},
			body: json
		})
		if(res.ok){
			getTx()
			getValues()
			getSubProjectMaterial()
		}
    }

	const handleTXDelete = (getTx) => async(id) => {
        const json = JSON.stringify({
			tx_id: id
		})
        const res = await fetch('/api/project/' + project.id + '/sub-project/'+ selectedSubProject +'/material/tx/general', {
            method: 'DELETE',
            headers: {
                'Content-Type': 'application/json',
                'X-CSRFToken': getCookie('csrftoken')
            },
            body: json
        })
		if(res.ok){
			getTx()
			getValues()
			getSubProjectMaterial()
		}
		return res.ok
    }
	const general_tx_sum = formatNumber(values.general_tx_sum, 2, '€')
	const current_material = formatNumber(values.current_material, 2, '€')
	const order_post_sum = formatNumber(values.order_post_sum, 2, '€')
	const marge = values.max_material - values.current_material

    return(
		<CheckSubProjects
			subs={tabs}
			isLoaded={true}
			info_text="Du brauchst erstmal ein Projektauftrag, um Material buchen zu können"
		>
			<CustomTabs
				value={value}
				handleChange={(e,value) => handleTabChange(value)}
				tabs={tabs}
			/>
			<Stack
				gap={3}
				sx={{mt: 1}}
			>
				<Stack
					flexDirection='row'
					justifyContent='space-between'
					alignItems='stretch'
					gap={3}
				>
					<CustomIconTriplePaperButton
						mainlabel={`Nachkalkulation`}
						mainsublabel={`${formatNumber(values.general_tx_sum + values.order_post_sum, 2, '€')}`}
						label1="Materialtranskationen"
						icon1={<BsTable size={ICONSIZE} color={PALETTE.background.light20}/>}
						count1={values.general_tx_count}
						sublabel1={`Transaktionswert: ${general_tx_sum}`}
						onClick1={()=> setOpenTableAdd(true)}
						label2="Bestellungen"
						icon2={<BsTable size={ICONSIZE} color={PALETTE.background.light20}/>}
						count2={values.order_tx_count}
						sublabel2={`Bestellwert: ${order_post_sum}`}
						onClick2={()=> setOpenTableOrder(true)}
						label3="Rückgabe"
						icon3={<BsTable size={ICONSIZE} color={PALETTE.background.light20}/>}
						count3={values.correction_tx_count}
						sublabel3={`Stornierungen / Retouren`}
						onClick3={()=> setOpenTableCorrection(true)}
					/>
					<CustomIconDoublePaperButton
						mainlabel={"Materialbilanz"}
						label1="Angebotendes Material"
						icon1={<BsListCheck size={ICONSIZE} color={PALETTE.background.light20}/>}
						count1={0}
						sublabel1={`Angebotswert: coming soon...`}
						onClick1={()=> setOpenOffer(true)}
						label2="Materialverbrauch"
						icon2={<BsTable size={ICONSIZE} color={PALETTE.background.light20}/>}
						count2={values.used_tx_count}
						sublabel2={`Materialwert: ${current_material}`}
						onClick2={()=> setOpenTableUsed(true)}
					/>
				</Stack>
				<Stack
					flexDirection='row'
					justifyContent='space-between'
					alignItems='stretch'
					gap={3}
				>
					<CustomIconPaperButton
						label="Angebot importieren"
						icon={<BsFileEarmarkPlusFill size={ICONSIZE} color={PALETTE.background.light20}/>}
						count={0}
						onClick={()=> setOpenImport(true)}
					/>
					<CustomIconPaperButton
						label="Materialanfragen"
						icon={<MdLocalOffer size={ICONSIZE} color={PALETTE.background.light20}/>}
						count={values.supplier_request_count}
						onClick={()=> setOpenSupplierResponse(true)}
					/>
					<CustomIconPaperButton
						label="Alle Aktivitäten"
						icon={<MdTimeline size={ICONSIZE} color={PALETTE.background.light20}/>}
						count={values.activities_count}
						onClick={()=> setOpenActivity(true)}
					/>
					{account.is_manager &&
						<CustomIconPaperButton
							label="Marge"
							icon={marge > 0 ? <BsGraphUp size={ICONSIZE} color={PALETTE.primary.main}/> : <BsGraphDown size={ICONSIZE} color={PALETTE.secondary.main}/> }
							sublabel={`Marge: ${formatNumber(marge, 2, '€')}`}
							sublabelColor={marge > 0 ? "primary.main" : "secondary.main"}
							onClick={()=> setOpenMarge(true)}
						/>
					}
				</Stack>
				<Stack
					flexDirection='row'
					justifyContent='space-between'
					alignItems='center'
					gap={3}
				>
					<CustomIconPaperButton
						label="Hinzufügen"
						icon={<MdPlaylistAdd size={ICONSIZE} color={PALETTE.background.light20}/>}
						count={values.missing_materials_count}
						onClick={()=> setOpenFill(true)}
						disabled={false}
					/>
					{account.is_manager &&
						<>
							<CustomIconPaperButton
								label="Anfragen"
								icon={<MdStoreMallDirectory size={ICONSIZE} color={PALETTE.background.light20}/>}
								onClick={()=> setOpenSupplierRequest(true)}
								disabled={selectedRows.length !== 0 && account.is_manager ? false : true}
							/>
							<CustomIconPaperButton
								label="Bestellen"
								icon={<MdLocalGroceryStore size={ICONSIZE} color={PALETTE.background.light20}/>}
								onClick={()=> setOpenOrder(true)}
								disabled={selectedRows.length !== 0 && account.is_manager ? false : true}
							/>
							<CustomIconPaperButton
								label="Aus dem Lager"
								icon={<BsBoxSeam size={ICONSIZE} color={PALETTE.background.light20}/>}
								onClick={()=> setOpenAdd(true)}
								disabled={selectedRows.length !== 0 && account.is_manager ? false : true}
							/>
						</>
					}
					<CustomIconPaperButton
						label="Verbrauchen"
						icon={<BsPencilSquare size={ICONSIZE} color={PALETTE.background.light20}/>}
						onClick={()=> setOpenUsed(true)}
						disabled={selectedRows.length !== 0 && account.is_manager ? false : true}
					/>
					{account.is_manager &&
						<>
							<CustomIconPaperButton
								label="Rückgabe"
								icon={<BsArrowReturnLeft size={ICONSIZE} color={PALETTE.background.light20}/>}
								onClick={()=> setOpenCorrection(true)}
								disabled={false}
							/>
							<CustomIconPaperButton
								label="Ins Lager"
								icon={<FaWarehouse  size={ICONSIZE} color={PALETTE.background.light20}/>}
								onClick={()=> setOpenChargeBack(true)}
							/>
						</>
					}
				</Stack>
					<ProjectMaterialTable
						selectedRows={selectedRows}
						setSelectedRows={setSelectedRows}
						project_id={project.id}
						selectedSubProject={selectedSubProject}
						materials={materials}
						getSubProjectMaterial={getSubProjectMaterial}
						getValues={getValues}
						pagMaterialsInfo={pagMaterialsInfo}
						isLoading={isLoading}
						pageSize={pageSize}
						setPageSize={setPageSize}
						handlePageChange={handlePageChange}
						is_admin={account.is_admin}
						is_manager={account.is_manager}
						searchTerm={searchTerm}
						setSearchTerm={setSearchTerm}
			            onOrderByGetParametersChange={handleOrderByGetParametersChange}
					/>
					<ActivityDialog
						open={openActivity}
						onClose={()=> setOpenActivity(false)}
						project_id={project.id}
						selectedSubProject={selectedSubProject}
					/>
					{account.is_manager &&
						<MargeDialog
							open={openMarge}
							onClose={()=> setOpenMarge(false)}
							current_material={values.current_material}
							max_material={values.max_material}
							marge={marge}
						/>

					}
					<MaterialTxTable
						open={openTableAdd}
						onClose={()=> setOpenTableAdd(false)}
						project_id={project.id}
						selectedSubProject={selectedSubProject}
						subtitle={`Gesamttransaktionswert: ${general_tx_sum}`}
						is_admin={account.is_admin}
						is_manager={account.is_manager}
						handleTXPatch={handleTXPatch}
						handleTXDelete={handleTXDelete}
						sublabel={`${project.name} | ${tabs[value]?.label}`}
					/>
					<UsedMaterialTable
						open={openTableUsed}
						onClose={()=> setOpenTableUsed(false)}
						project_id={project.id}
						selectedSubProject={selectedSubProject}
						subtitle={`Materialwert: ${current_material}`}
						is_admin={account.is_admin}
						is_manager={account.is_manager}
						handleTXPatch={handleTXPatch}
						handleTXDelete={handleTXDelete}
						sublabel={`${project.name} | ${tabs[value]?.label}`}
					/>
					{account.is_manager &&
						<>
							<MaterialOrderTable
								open={openTableOrder}
								onClose={()=> setOpenTableOrder(false)}
								project_id={project.id}
								selectedSubProject={selectedSubProject}
								subtitle={`Gesamtbestellwert: ${order_post_sum}`}
								is_admin={account.is_admin}
								is_manager={account.is_manager}
								handleTXPatch={handleTXPatch}
								handleTXDelete={handleTXDelete}
								sublabel={`${project.name} | ${tabs[value]?.label}`}
							/>
							<MaterialCorrectionTable
								open={openTableCorrection}
								onClose={()=> setOpenTableCorrection(false)}
								project_id={project.id}
								selectedSubProject={selectedSubProject}
								subtitle={`Stornierungen / Retouren`}
								is_admin={account.is_admin}
								is_manager={account.is_manager}
								getSubProjectMaterial={getSubProjectMaterial}
								getValues={getValues}
								sublabel={`${project.name} | ${tabs[value]?.label}`}
							/>
						</>
					}
			</Stack>
			<SearchMaterialDialog
                open={openFill}
                onClose={() => setOpenFill(false)}
				project_id={project.id}
                selectedSubProject={selectedSubProject}
                getSubProjectMaterial={getSubProjectMaterial}
				getValues={getValues}
				setSelectedRows={setSelectedRows}
				suppliers={suppliers}
				sublabel={`${project.name} | ${tabs[value]?.label}`}
            />
            <WarehouseMaterialDialog
                open={openAdd}
                onClose={() => setOpenAdd(false)}
                materials={selectedRows}
                setSelectedMaterials={setSelectedRows}
                project_id={project.id}
                sub_project_id={selectedSubProject}
                getSubProjectMaterial={getSubProjectMaterial}
				getValues={getValues}
            />
            <UsedMaterialDialog
                open={openUsed}
                onClose={() => setOpenUsed(false)}
                materials={selectedRows}
                setSelectedMaterials={setSelectedRows}
                project_id={project.id}
                sub_project_id={selectedSubProject}
                getSubProjectMaterial={getSubProjectMaterial}
				getValues={getValues}
            />
            <OrderMaterialDialog
                open={openOrder}
                onClose={() => setOpenOrder(false)}
                materials={selectedRows}
                setSelectedMaterials={setSelectedRows}
                project_id={project.id}
                sub_project_id={selectedSubProject}
                getSubProjectMaterial={getSubProjectMaterial}
				getValues={getValues}
            />
			<SupplierRequestDialog
				open={openSupplierRequest}
				onClose={() => setOpenSupplierRequest(false)}
				materials={selectedRows}
				setSelectedMaterials={setSelectedRows}
				project_id={project.id}
				sub_project_id={selectedSubProject}
				getValues={getValues}
			/>
			<CorrectionMaterialDialog
                open={openCorrection}
                onClose={() => setOpenCorrection(false)}
                project_id={project.id}
                sub_project_id={selectedSubProject}
                getSubProjectMaterial={getSubProjectMaterial}
				getValues={getValues}
            />
			<OfferDialog
				open={openOffer}
				onClose={() => setOpenOffer(false)}
				project_id={project.id}
				selectedSubProject={selectedSubProject}
			/>
			<ChargeBackDialog
				open={openChargeBack}
				onClose={() => setOpenChargeBack(false)}
				project_id={project.id}
				selectedSubProject={selectedSubProject}
			/>
			<ImportDialog
				open={openImport}
				onClose={() => setOpenImport(false)}
				project_id={project.id}
				selectedSubProject={selectedSubProject}
				getSubProjectMaterial={getSubProjectMaterial}
			/>
			<SupplierResponseDialog
				open={openSupplierResponse}
				onClose={()=> setOpenSupplierResponse(false)}
				project_id={project.id}
				selectedSubProject={selectedSubProject}
				getValues={getValues}
			/>
		</CheckSubProjects>
    )
}

export default ProjectMaterial
