import { useCallback, useMemo, useState } from "react";
import Dropzone from "react-dropzone";
import { Typography } from "@mui/material";

import { PALETTE } from "components/theme";


const baseStyle = {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: '35px',
    borderWidth: 1,
    borderRadius: 10,
    borderColor: PALETTE.background.light20,
    borderStyle: 'dashed',
    backgroundColor: PALETTE.background.content,
    outline: 'none',
    transition: 'border .24s ease-in-out',
    cursor: 'pointer'
};

const acceptStyle = {
    borderColor: PALETTE.primary.main
};

const rejectStyle = {
    borderColor: PALETTE.error.main
};

const DragNDrop = ({ setFile, acceptFileTypes, text, acceptSubtext, rejectedSubtext }) => {

    const [isAccepted, setIsAccept] = useState(false)
    const [isRejected, setIsRejected] = useState(false)

    const style = useMemo(() => ({
        ...baseStyle,
        ...(isAccepted ? acceptStyle : {}),
        ...(isRejected ? rejectStyle : {})
    }), [
        isAccepted,
        isRejected
    ]);

    const onDrop = useCallback((acceptedFiles) => {
        acceptedFiles.forEach((file) => {
                setFile(file)
            });
            setIsAccept(true)
            setIsRejected(false)
    }, [])


    return (
        <Dropzone
            onDrop={onDrop}
            autoFocus
            disabled={false}
            multiple={false}
            onDropRejected={() => {
                setIsRejected(true)
                setIsAccept(false)
            }}
            accept={acceptFileTypes}
        >
            {({ getRootProps, getInputProps }) => (
                <section>
                    <div {...getRootProps(({ style }))}>
                        <input {...getInputProps()} />
                        <Typography variant='text_m'>{text}</Typography>
                        {isAccepted &&
                            <Typography variant="subtitle_m" color="primary">{acceptSubtext}</Typography>
                        }
                        {isRejected &&
                            <Typography variant="subtitle_m" color="error">{rejectedSubtext}</Typography>
                        }
                    </div>
                </section>
            )}
        </Dropzone>
    );
}

export default DragNDrop;