import { useContext } from "react"
import { Link, matchPath } from "react-router-dom"

import { Avatar, ListItemButton, ListItemText, ListItemAvatar } from '@mui/material';

import ProfileContext from '../../context/AccountContext'
import { SETTINGS } from "."


const Account = ({ collapsed, openWidth, activeBackground }) => {
    const [profile] = useContext(ProfileContext)
    const link = '/dashboard/account'
    const isActive = matchPath(link, window.location.pathname)

    return (
        <ListItemButton
            sx={{
                backgroundColor: isActive ? activeBackground : 'none',
                flex: '0 0 55px',
                borderRadius: SETTINGS.itemBorderRadius,
                overflow: 'hidden',
                pl: 0.5,
                pr: 2,
                py: 2,
                gap: 1.5,
                '&:hover': {
                    backgroundColor: isActive ? activeBackground : SETTINGS.colors.hoverBackground
                }
            }}
            component={Link}
            to={link}
        >
            <ListItemAvatar
                sx={{ minWidth: 0 }}
            >
                <Avatar
                    alt="avatar"
                    src={profile.avatar}
                    sx={{ backgroundColor: SETTINGS.colors.textColor }}
                />
            </ListItemAvatar>
            <ListItemText
                primary={profile.firstname + ' ' + profile.lastname}
                sx={{
                    whiteSpace: 'nowrap'
                }}
            />
        </ListItemButton>
    )
}

export default Account