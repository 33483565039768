import { Box } from '@mui/material'
import ImageList from '@mui/material/ImageList';
import ImageListItem from '@mui/material/ImageListItem';
import ImageListItemBar from '@mui/material/ImageListItemBar';

import Title from "./Title";
import Tooltip from "./Tooltip";
import SettingsButton from "./SettingsButton";

import ImagePopup from '../../../Widgets/ImagePopup'


const ImageGrid = ({ files, getUploads }) => {
    return (
        <Box>
            <ImageList cols={4} gap={16} sx={{ overflow: 'visible' }}>
                {files.map(img =>
                    <ImageListItem key={img.id}>
                        <Tooltip document={img}>
                            <Box sx={{ overflow: 'hidden', borderRadius: 2 }}>
                                <ImagePopup src={img.file} />
                                <ImageListItemBar
                                    title={<Title document={img} />}
                                    subtitle={img.name + ' - ' + new Date(img.timestamp).toLocaleString()}
                                    sx={{ overflow: 'hidden', borderBottomLeftRadius: 8, borderBottomRightRadius: 8 }}
                                    // actionIcon={<SettingsButton document={img} getUploads={getUploads} />}
                                />
                            </Box>
                        </Tooltip>
                    </ImageListItem>
                )}
            </ImageList>
        </Box>
    )
}

export default ImageGrid
