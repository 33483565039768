import { useState } from "react";
import { useForm } from "react-hook-form";
import { Stack, Button, DialogContent, DialogContentText, Dialog, DialogTitle, DialogActions, IconButton, Tooltip } from "@mui/material";

import useStyledSnackbar from '../../../../../hooks/useStyledSnackbar';
import { getCookie } from "../../../../../utils/getCookie";

import ControlledTextField from "../../../../Widgets/ControlledInputs/ControlledTextField";
import ImageUploader from '../../../../Widgets/ImageUplaoder'

import { FaUsers } from "react-icons/fa";


const NewGroupDialog = (props) =>{

    const { getWorkingGroups } = props;
    const { enqueueSnackbar } = useStyledSnackbar()
    const [ open, setOpen ] = useState(false)

    const { control, handleSubmit, formState: { errors, isDirty, isValid }, reset } = useForm({
        mode: 'onChange',
        defaultValues:{
            name: '',
            description: '',
        }
    })

    const handleOnClose = () =>{
        setOpen(false)
        setSelectedFile(null)
        reset();
    }

    const [selectedFile, setSelectedFile] = useState(null)

    const handleAddGroup = async (data) => {
        if (selectedFile == null) {
            enqueueSnackbar('Du musst ein Bild auswählen!', {
                variant: 'error'
            })
            return
        }
        const json = JSON.stringify(data)

        const formData = new FormData()
        formData.append('file', selectedFile)
        formData.append('json', json)

        const res = await fetch('/api/company/workplanner/working_groups', {
            method: 'POST',
            headers: {
                'X-CSRFToken': getCookie('csrftoken')
            },
            body: formData,
        })
        if (res.ok) {
            enqueueSnackbar('Die Arbeitsgruppe wurde erfolgreich erstellt!', {
                variant: 'success'
            })
            getWorkingGroups();
            handleOnClose()
        }
    }

    return (
        <>
            <Tooltip
                title="Neue Arbeitsgruppe"
            >
                <IconButton color='primary' size="large" onClick={() => setOpen(true)}><FaUsers /></IconButton>
            </Tooltip>
            <Dialog
                open={open}
                onClose={handleOnClose}
                fullWidth={true}
                maxWidth={'laptop'}
            >
                <DialogTitle>
                    Neue Arbeitsgruppe
                </DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        Lege hier eine neue Arbetisgruppe an.
                    </DialogContentText>
                    <Stack
                        component='form'
                        id='new-group-form'
                        onSubmit={handleSubmit(handleAddGroup)}
                        sx={{mt:4}}
                        width="100%"
                    >
                        <Stack
                            direction='row'
                            spacing={5}
                        >
                            <ImageUploader
                                selectedFile={selectedFile}
                                setSelectedFile={setSelectedFile}
                                width={350}
                            />
                            <Stack
                                spacing={2}
                                width="100%"
                            >
                                <ControlledTextField
                                    control={control}
                                    name="name"
                                    label="Name der Arbeitsgruppe"
                                    rules={{ required: true }}
                                    errors={errors}
                                />
                                <ControlledTextField
                                    control={control}
                                    name="description"
                                    label="Beschreibung"
                                    rules={{ required: true }}
                                    errors={errors}
                                    multiline
                                    rows={4}
                                />
                            </Stack>
                        </Stack>
                    </Stack>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleOnClose}>Abbrechen</Button>
                    <Button
                        color="primary"
                        type="submit"
                        form="new-group-form"
                        autoFocus
                        disabled={!isDirty || !isValid}
                    >
                        Speichern
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    )
}

export default NewGroupDialog
