import { useContext } from 'react';
import { Navigate } from 'react-router';
import UserContext from '../../context/UserContext'

const RequireAuth = ({ children, redirectTo, requiredLoginStatus }) => {
    const [user] = useContext(UserContext)

    return user.isLoggedIn === requiredLoginStatus ?
            children
            : <Navigate to={redirectTo} />
}

export default RequireAuth