import { format } from "date-fns"
import { de } from "date-fns/locale"

export const getLocalDate = d => {
    return d.getFullYear() + '-' + (d.getMonth() + 1) + '-' + d.getDate()
}

export const getLocalTime = d => {
    return d.getHours() + ':' + d.getMinutes()
}

export const getLocalTimeStamp = d => {
    return d.toLocaleTimeString([], {hour: '2-digit', minute:'2-digit'})
}

export const getISOTimeStamp = d => {
    return format(d, 'kk:mm:ss')
}

export const formatTimestamp = timestamp => {
    const today = new Date()
    const ts = new Date(timestamp)

    if (
        ts.getDate() === today.getDate() &&
        ts.getMonth() === today.getMonth() &&
        ts.getFullYear() === today.getFullYear()
    ){
        return ts.toLocaleTimeString([], {hour: '2-digit', minute:'2-digit'})
    }
    return ts.toLocaleString([], {year: 'numeric', month: 'numeric', day: 'numeric', hour: '2-digit', minute:'2-digit'})
}

export const formatDate = d => {
    return d.getDate() + '.' + (d.getMonth() + 1) + '.' + d.getFullYear()
}

export const formatDateString = s => formatDate(new Date(s))

export const convertTimeString = s => {
    const fragments = s.split(':')
    return fragments[0] + ' ' + fragments[1]
}

export const getLocalDateTime = d => {
    if (d === null) return ''
    return format(d, "EEEE, dd.MM.yyyy", {locale: de})
}

export const secondsToTime = (seconds) => {
    let hours = Math.floor(seconds / 3600)
    seconds %= 3600
    let minutes = Math.floor(seconds / 60)

    minutes = String(minutes).padStart(2, "0");
    hours = String(hours).padStart(2, "0");
    return hours + ":" + minutes;
}

export const secondsToHours = (seconds) => {
    let hours = seconds / 3600
    return hours ;
}

export const getAllDatesInMonth = (year, month) => {
    const date = new Date(year, month, 1);

    const dates = [];

    while (date.getMonth() === month) {
        dates.push({
            'date': getLocalDate(new Date(date))
        });
        date.setDate(date.getDate() + 1);
    }

    return dates;
  }
