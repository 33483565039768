import { Stack, Typography, Divider, DialogContent, Dialog, DialogTitle } from "@mui/material"

import { formatNumber } from "../../../../../../utils/helper"


const MargeContainer = ({ open, onClose, current_material, max_material, marge }) =>{

    return(
        <Dialog
            open={open}
            onClose={onClose}
            fullWidth
            maxWidth="tablet"
        >
            <DialogTitle>
                Marge der Materialkosten
            </DialogTitle>
            <DialogContent>
                <Stack
                    width="100%"
                    alignItems='center'
                >
                    <Stack
                        flexDirection='row'
                        justifyContent='space-between'
                        alignItems='center'
                        gap={3}
                        width="100%"
                    >
                        <Typography variant="text_m">Kalkulierte Materialkosten</Typography>
                        <Typography variant="value" sx={{textAlign: 'right'}}>{formatNumber(max_material, 2,'€')}</Typography>
                    </Stack>
                    <Stack
                        flexDirection='row'
                        justifyContent='space-between'
                        alignItems='center'
                        gap={3}
                        width="100%"
                    >
                        <Typography variant="text_m">Aktuelle Materialkosten</Typography>
                        <Typography variant="value" sx={{textAlign: 'right'}}>{`- ${formatNumber(current_material, 2,'€')}`}</Typography>
                    </Stack>
                    <Divider flexItem sx={{mt:1, mb:1}}/>
                    <Stack
                        flexDirection='row'
                        justifyContent='space-between'
                        alignItems='space-between'
                        gap={3}
                        width="100%"
                    >
                        <Typography variant="subtitle_m">Kostendifferenz</Typography>
                        <Typography variant="value" color={marge >= 0 ? "primary" : "secondary"} sx={{textAlign: 'right'}}>{formatNumber(marge, 2,'€')}</Typography>
                    </Stack>
                </Stack>
            </DialogContent>
        </Dialog>
    )
}

export default MargeContainer