import { useEffect, useState } from 'react'

import { Button, Stack } from '@mui/material'
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';

import SelectList from './SelectList'


const LeftRightList = ({ leftItems, setLeftItems, leftTitle, rightItems, setRightItems, rightTitle, itemName, listItemRender, handleSave }) => {
    const [leftSelectedItems, setLeftSelectedItems] = useState([])
    const [rightSelectedItems, setRightSelectedItems] = useState([])

    useEffect(() => {
        setLeftSelectedItems([])
    }, [leftItems])

    useEffect(() => {
        setRightSelectedItems([])
    }, [rightItems])

    const itemIsInList = (list, item) => list.some(i => i.id === item.id)

    const setItems = selectedItems => old => {
        let newList = [...old]
        for (let i = newList.length - 1; i >= 0 ; i--) {
            if (itemIsInList(selectedItems, newList[i])) {
                newList.splice(i, 1)
            }
        }
        return newList
    }

    const moveRight = () => {
        setLeftItems(setItems(leftSelectedItems))
        setRightItems(old => [...old, ...leftSelectedItems])
    }

    const moveLeft = () => {
        setRightItems(setItems(rightSelectedItems))
        setLeftItems(old => [...old, ...rightSelectedItems])
    }

    return (
        <Stack
            direction='row'
            alignItems='stretch'
            gap={2}
            sx={{ mt: 1}}
        >
            <Wrapper>
                <SelectList
                    title={leftTitle}
                    items={leftItems}
                    listItemRender={listItemRender}
                    selectedItems={leftSelectedItems}
                    setSelectedItems={setLeftSelectedItems}
                    itemName={itemName}
                />
            </Wrapper>
            <Stack
                justifyContent='center'
                gap={2}
            >
                <Button
                    variant="outlined"
                    size="large"
                    color="secondary"
                    onClick={moveRight}
                    disabled={leftSelectedItems.length === 0}
                >
                    <ArrowForwardIcon/>
                </Button>
                <Button
                    variant="contained"
                    size="large"
                    color="primary"
                    onClick={handleSave}
                >
                    Speichern
                </Button>
                <Button
                    variant="outlined"
                    size="large"
                    color="secondary"
                    onClick={moveLeft}
                    disabled={rightSelectedItems.length === 0}
                >
                    <ArrowBackIcon/>
                </Button>
            </Stack>
            <Wrapper>
                <SelectList
                    title={rightTitle}
                    items={rightItems}
                    listItemRender={listItemRender}
                    selectedItems={rightSelectedItems}
                    setSelectedItems={setRightSelectedItems}
                    itemName={itemName}
                />
            </Wrapper>
        </Stack>
    )
}

export default LeftRightList


const Wrapper = ({ children }) => (
    <Stack
        direction='column'
        gap={2}
        sx={{
            flex: '1 1 0'
        }}
    >
        {children}
    </Stack>
)