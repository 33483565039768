import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, IconButton, Stack, Tooltip } from "@mui/material";
import useStyledSnackbar from "hooks/useStyledSnackbar";
import { CopyToClipboard } from "react-copy-to-clipboard";
import { MdMail, MdLink } from "react-icons/md";


const generateEmailText = (supplier, creator, url) => {
	return(encodeURIComponent(
        `Hallo, ${supplier},

        ich hätte gerne die Preise für folgende Artikel. Bitte einfach auf den Link klicken und das Formular ausfüllen.

		${url}


		Vielen Dank!


		Mit freundlichen Grüßen

		${creator}`))
}

const SendUrlByMailSupplierRequestDialog = ({open, onClose, res, size=65, disabled=false}) => {

    const { enqueueSnackbar } = useStyledSnackbar()

    const copyUrlSnackbar = () => {
        enqueueSnackbar('Der Link wurde erfolgreich in die Zwischenablage gespeichert!', {
            variant: 'success'
        })
    }

    const handleClose = () =>{
        onClose()
    }

    return (
        <Dialog
            open={open}
            onClose={handleClose}
            maxWidth="tablet"
            fullWidth
        >
            <DialogTitle>
                {"Link versenden"}
            </DialogTitle>
            <DialogContent>
                <DialogContentText>
                    Schicke deinem Lieferanten den Link damit er die Materialanfrage ausfüllen kann.
                </DialogContentText>
                <Stack
                    flexDirection="row"
                    justifyContent="center"
                    gap={5}
                    sx={{p:2}}
                >
                    <CopyToClipboard onCopy={copyUrlSnackbar} text={res.url}>
                        <Tooltip title="Link für den Lieferanten kopieren" disableHoverListener={disabled}>
                            <span>
                                <IconButton color='primary' disabled={disabled}>
                                    <MdLink size={size}/>
                                </IconButton>
                            </span>
                        </Tooltip>
                    </CopyToClipboard>
                    <a href={`mailto:${res.supplier_email}?subject=${`Materialanfrage | ${res.company_name}`}&body=${generateEmailText(res.supplier_contact_person, res.creator, res.url)}`}>
                        <Tooltip title="Link per Mail versenden" disableHoverListener={disabled}>
                            <span>
                                <IconButton color='primary' disabled={disabled}>
                                    <MdMail size={size} />
                                </IconButton>
                            </span>
                        </Tooltip>
                    </a>
                </Stack>
            </DialogContent>
            <DialogActions>
                <Button
                    color="secondary"
                    onClick={handleClose}
                >
                    Schließen
                </Button>
            </DialogActions>
        </Dialog>
    );
}

export default SendUrlByMailSupplierRequestDialog;