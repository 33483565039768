import { useState } from 'react'
import { useForm } from 'react-hook-form'

import { Grid, Stack } from '@mui/material'

import { getCookie } from '../../../utils/getCookie'

import ControlledTextField from '../../Widgets/ControlledInputs/ControlledTextField'
import NotificationDialog from '../../Widgets/Dialogs/NotificationDialog'
import CustomFormPaper from '../../Widgets/CustomFormPaper'


const ChangePassword = () => {

    const [loading, setLoading] = useState(false)
    const [success, setSuccess] = useState(false)

    const { handleSubmit, setError, control, formState: { errors, isDirty, isValid }} = useForm({
        mode: 'onChange',
        defaultValues: {
            password: '',
            password2: ''
        }
    })

    const [dialogOpen, setDialogOpen] = useState(false)
    const handleDialogClose = () => {
        window.location.reload()
    }

    const onSubmit = async data => {
        // console.log(data)
        setLoading(true)
        const res = await fetch('/account/', {
            method: 'PATCH',
            headers: {
                'Content-Type': 'application/json',
                'X-CSRFToken': getCookie('csrftoken'),
            },
            body: JSON.stringify(data)
        })
        const json = await res.json()
        if (res.ok) {
            setDialogOpen(true)
            setLoading(false)
            setSuccess(true)
        } else {
            Object.entries(json).forEach(([field, message]) => {
                console.log(field)
                console.log(message)
                setError(field, {
                    type: 'server',
                    message: message
                })
            })
            setLoading(false)
        }
    }

    return (
        <Stack>
            <CustomFormPaper
                title="Passwort ändern"
                onSubmit={handleSubmit(onSubmit)}
                tooltip={'Speichern'}
                success={success}
                loading={loading}
                disabled={!isDirty || !isValid}
            >
                <Grid container spacing={2}>
                    <Grid item mobile={12} laptop={6} desktop={6}>
                        <ControlledTextField
                            control={control}
                            name='password'
                            rules={{ required: true }}
                            type='password'
                            label='Passwort'
                            errors={errors}
                        />
                    </Grid>
                    <Grid item mobile={12} laptop={6} desktop={6}>
                        <ControlledTextField
                            control={control}
                            name='password2'
                            rules={{ required: true }}
                            type='password'
                            label='Passwort wiederholen'
                            errors={errors}
                        />
                    </Grid>
                </Grid>
            </CustomFormPaper>
            <NotificationDialog
                open={dialogOpen}
                handleClose={handleDialogClose}
                title='Passwort geändert'
                text='Sie werden jetzt abgemeldet!'
                buttonText='OK'
            />
        </Stack>
    )
}

export default ChangePassword