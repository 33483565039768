import { Box, Avatar, Paper, IconButton, Stack, Typography, Chip } from "@mui/material"
import { Delete, Edit } from "@mui/icons-material"
import { BsCircleFill } from "react-icons/bs"

import ConfirmDialog from "../../../../Widgets/ConfirmDialog"

import { convertTimeString } from "../../../../../utils/date_functions"
import { PALETTE } from "../../../../theme"
import { getCookie } from "../../../../../utils/getCookie"



const EventItem = ({ event, handleSelectedEvent, getDayEvents, haveToLoadAllEvents }) =>{

    const handleDeleteEvent = async () => {
        const json = JSON.stringify({
            event_id: event.id,
        })
        const res = await fetch('/api/company/workplanner', {
            method: 'DELETE',
            headers: {
                'Content-Type': 'application/json',
                'X-CSRFToken': getCookie('csrftoken')
            },
            body: json,
        })
        if (res.ok) {
            haveToLoadAllEvents.current = true
            getDayEvents()
        }
    }

    return(
        <Paper
            sx={{
                display: 'flex',
                p: 2,
                width: 450,
            }}
        >
            <Stack
                spacing={2}
                justifyContent='space-between'
                width="100%"
            >
                <Stack
                    direction='row'
                    justifyContent='space-between'
                    spacing={5}
                    width="100%"
                >
                    <Stack
                        width="100%"
                        spacing={2}
                    >
                        <Stack>
                            <Typography
                                variant="subtitle_s"
                                sx={{ maxWidth: 230, overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap' }}
                            >
                                {`${event.title}`}
                            </Typography>
                            <Typography
                                variant="caption_m"
                                color="primary.main"
                                sx={{ maxWidth: 200, overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap' }}
                            >
                                {`Auftrag: ${event.sub_project.title}`}
                            </Typography>
                        </Stack>
                        <Stack
                            direction='row'
                            alignItems='center'
                            spacing={1}
                        >
                            <BsCircleFill size={10} color={PALETTE.primary.main}/>
                            <Typography variant="text_s" sx ={{fontSize: 15, lineHeight: 0}}>{`${convertTimeString(event.since)} - ${convertTimeString(event.till)}`}</Typography>
                        </Stack>
                    </Stack>
                    <Stack
                        direction='row'
                        spacing={1}
                    >
                        <Box>
                            <IconButton
                                onClick={()=>{handleSelectedEvent(event)}}
                            >
                                <Edit/>
                            </IconButton>
                        </Box>
                        <Box>
                            <ConfirmDialog
                                title='Achtung!'
                                message={`Möchtest du wirklich das Event - ${event.title} - löschen?`}
                                onConfirm={() => handleDeleteEvent(event)}
                                renderButton={(onClick) => (
                                    <IconButton
                                        color="error"
                                        onClick={onClick}
                                    >
                                        <Delete/>
                                    </IconButton>
                                )}
                            />
                        </Box>
                    </Stack>
                </Stack>
                <Stack
                    sx={{
                        flexWrap: 'wrap',
                        height: 200,
                        overflow: 'auto',
                    }}
                >
                    <Typography
                        variant="text_m"
                    >
                        {`${event.description}`}
                    </Typography>
                </Stack>
                <Stack
                    flexDirection="row"
                    alignContent="flex-start"
                    sx={{
                        height: '100%',
                        flexWrap: 'wrap',
                        overflow: 'auto',
                        p:1,
                        border: 1,
                        borderRadius: '7px',
                        borderColor: PALETTE.background.light70,
                    }}
                    gap={1}
                >
                    {event.allocator.map((allocator)=>{
                        return(
                            <Chip
                                key={`cm-${allocator.id}`}
                                avatar={
                                    <Avatar
                                        alt={allocator.company_member.firstname}
                                        src={allocator.company_member.avatar}
                                    />
                                }
                                label={`${allocator.company_member.firstname} ${allocator.company_member.lastname}`}
                                variant="outlined"
                            />
                        )
                    })}
                </Stack>
            </Stack>
        </Paper>
    )
}

export default EventItem