import { useRef } from "react"
import { DataGridPro } from "@mui/x-data-grid-pro"


const CustomPaginationDataGridPro = ({ onSortModelChange, onOrderByGetParametersChange, onPageChange, apiRef, ...props}) =>{

    const lastPage = useRef(0)

    const handlePageChange = (p) =>{
        if(lastPage.current < p){
            onPageChange('next')
        }
        if(lastPage.current > p){
            onPageChange('previous')
        }
        lastPage.current = p
    }

    const handleSortModelChange = sortModel => {
        if (onSortModelChange)
            onSortModelChange(sortModel)
        if (onOrderByGetParametersChange)
            onOrderByGetParametersChange(sortModel.map(x => 'order_by=' + (x.sort === 'desc' ? '-' : '') + x.field).join('&'))
    }

    return(
        <DataGridPro
            apiRef={apiRef}
            pagination
            paginationMode="server"
            onPageChange={handlePageChange}
            sortingMode="server"
            onSortModelChange={handleSortModelChange}
            {...props}
        />
    )
}

export default CustomPaginationDataGridPro
