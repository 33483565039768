const setServerErrors = (errors, setError) => {
    Object.entries(errors).forEach(([field, message]) => {
        if (!Array.isArray(message)) {
            setServerErrors(message, setError)
        } else {
            console.log(message)
            setError(field, {
                type: 'server',
                message: message
            })
        }
    })
}

export default setServerErrors
