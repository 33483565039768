import { useState } from "react";
import { useForm } from "react-hook-form";
import { getCookie } from "../../../../../utils/getCookie";
import {  Button,  DialogContent, DialogContentText, Dialog, DialogTitle, DialogActions, Stack } from "@mui/material";

// import { MARKS } from '../TicketHelpers'
import ControlledTextField from "../../../../Widgets/ControlledInputs/ControlledTextField";
import ControlledDateTimePicker from "../../../../Widgets/ControlledInputs/ControlledDateTimePicker";
import ControlledSelect from "../../../../Widgets/ControlledInputs/ControlledSelect";
import ControlledSlider from "../../../../Widgets/ControlledInputs/ControlledSlider";
import ImageUploader from "../../../../Widgets/ImageUplaoder";
import { addWeeks } from "date-fns/esm";

const TicketAddDialog = (props) =>{

    const { open, onClose, ticketChain, project_id, getTicketChains, subCompanies } = props;
    const [selectedFile, setSelectedFile] = useState(null)
    const disabled = Object.keys(subCompanies).length === 0

    const { control, handleSubmit, formState: { errors, isDirty, isValid }, reset } = useForm({
        mode: 'onChange',
        defaultValues: {
            title: '',
            instruction: '',
            service_provider: '',
            deadline: addWeeks(new Date(), 2),
            rank: 10,
        }
    })

    const handleOnCLose = () => {
        onClose()
        reset()
        setSelectedFile(null)
    }

    const handleAddTicket = async (data) => {

        const json = JSON.stringify({
            allocator: ticketChain.id,
            ...data
        })
        const formData = new FormData()
        formData.append('file', selectedFile)
        formData.append('json', json)

        const res = await fetch('/api/project/' + project_id + '/tickets', {
            method: 'POST',
            headers: {
                'X-CSRFToken': getCookie('csrftoken')
            },
            body: formData,
        })
        if (res.ok) {
            getTicketChains()
            handleOnCLose()
        }
    }

    return(
        <Dialog
            open={open}
            onClose={handleOnCLose}
            fullWidth={true}
            maxWidth={'laptop'}
        >
            <DialogTitle>
                Ticket hinzufügen
            </DialogTitle>
            <DialogContent>
                <DialogContentText >
                    Hier können Sie zur Ticketkette ein weiteres Ticket hinzufügen
                </DialogContentText>
                <Stack
                    component='form'
                    id='add-ticket-form'
                    onSubmit={handleSubmit(handleAddTicket)}
                    spacing={2}
                    sx={{mt:2}}
                >
                    <Stack
                        direction="row"
                        spacing={5}
                    >
                        <ImageUploader
                            selectedFile={selectedFile}
                            setSelectedFile={setSelectedFile}
                            width={320}
                        />
                        <Stack
                            spacing={2}
                            width="100%"
                        >
                            <ControlledTextField
                                name="title"
                                label="Titel"
                                control={control}
                                rules={{ required: true }}
                                errors={errors}
                                disabled={disabled}
                            />
                            <ControlledTextField
                                name="instruction"
                                label="Beschreibung"
                                control={control}
                                rules={{ required: true }}
                                errors={errors}
                                multiline
                                rows={4}
                                disabled={disabled}
                            />
                            <ControlledSlider
                                name="rank"
                                control={control}
                                rules={{ required:  true }}
                                label="Priorität"
                                step={1}
                                min={1}
                                max={100}
                                disabled={disabled}
                                // marks={MARKS}
                            />
                            <ControlledDateTimePicker
                                name="deadline"
                                label="Frist"
                                control={control}
                                rules={{ required:  true }}
                                errors={errors}
                                disabled={disabled}
                            />
                            <ControlledSelect
                                name="service_provider"
                                label="Empfänger"
                                control={control}
                                rules={{ required: true}}
                                errors={errors}
                                items={subCompanies}
                                disabled={disabled}
                            />
                        </Stack>
                    </Stack>
                </Stack>
            </DialogContent>
            <DialogActions>
                <Button onClick={handleOnCLose} color="secondary">Abbrechen</Button>
                <Button
                    color="primary"
                    type="submit"
                    form="add-ticket-form"
                    autoFocus
                    disabled={!isDirty || !isValid}
                >
                    Speichern
                </Button>
            </DialogActions>
        </Dialog>
    )
}

export default TicketAddDialog