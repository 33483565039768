import { Box, Stack, ListItem, ListItemButton, Typography, LinearProgress } from '@mui/material'

import FolderPrivate from 'assets/svgs/conmeet_folder_private.svg'
import FolderPosted from 'assets/svgs/conmeet_folder_posted.svg'
import FolderReceived from 'assets/svgs/conmeet_folder_received.svg'

import { formatDateString } from 'utils/date_functions'
import { formatNumber } from 'utils/helper'



const TaskListItem = ({ task, handleOpen }) => {

    const handleFolderImg= () =>{
        if(task.posted) return FolderPosted
        if(task.private) return FolderPrivate
        if(task.received) return FolderReceived
        return FolderPrivate
    }

    return(
        <ListItem
            disablePadding
        >
            <ListItemButton
                onClick={()=> handleOpen(task)}
            >
                <Stack
                    direction={'row'}
                    sx={{
                        width: '100%'
                    }}
                >
                    <Stack>
                        <Box
                            component="img"
                            src={handleFolderImg()}
                            alt="not found"
                            sx={{
                                width: 200,
                                height: 160,
                                p:2
                            }}
                        />
                    </Stack>
                    <Stack
                        direction={'row'}
                        justifyContent={'space-between'}
                        sx={{
                            width: '100%'
                        }}
                    >
                        <Stack
                            justifyContent={'center'}
                            alignItems={'flex-start'}
                            gap={1}
                        >
                            <Typography variant="subtitle_m">{task.title}</Typography>
                            <LinearProgress
                                variant="determinate"
                                color="primary"
                                value={task.progress}
                                sx={{
                                    width: 300,
                                }}
                            />
                            <Typography variant="subtitle_s">{formatNumber(task.value, 2,'€')}</Typography>
                        </Stack>
                        <Stack>
                            <Stack 
                                gap={0.5}
                                sx={{
                                    p: 1,
                                }}
                            >
                                <Typography variant="text_m">
                                    {formatDateString(task.expected_to_start)} - {formatDateString(task.expected_to_end)}
                                </Typography>
                            </Stack>
                        </Stack>
                    </Stack>
                </Stack>
            </ListItemButton>
        </ListItem>
    )
}

export default TaskListItem