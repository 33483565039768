import { Controller } from 'react-hook-form'
import { Select, MenuItem, FormControl, InputLabel, ListSubheader} from '@mui/material'

const ControlledSelectGrouping = ({ control, name, rules, errors, variant, label, items, multiple, itemRender, renderValue, disabled=false }) => {
    return (
        <Controller
            control={control}
            name={name}
            rules={rules}
            render={({ field: { value, onChange } }) => (
                <FormControl
                    variant={variant}
                    fullWidth
                    disabled={disabled}
                >
                    <InputLabel>{label}</InputLabel>
                    <Select
                        label={label}
                        variant={variant}
                        fullWidth
                        multiple={multiple}
                        renderValue={renderValue}
                        error={Boolean(errors[name])}
                        onChange={onChange}
                        // empty value if no items
                        value={(!items || items.length === 0) ? '' : value}
                    >
                        {items.map((item, subgroup_idx) => {
                            let temp = []
                            temp.push(<ListSubheader key={subgroup_idx}>{item.subheader}</ListSubheader>)
                            Object.entries(item.subitems).map(([key, value], idx) => {
                                temp.push(
                                    <MenuItem key={`${label}-key-${idx}`} value={key}>
                                        {itemRender ? itemRender(value) : value}
                                    </MenuItem>
                                )
                            })
                            return temp
                        })}
                    </Select>
                </FormControl>
            )}
        />
    )
}

export default ControlledSelectGrouping