import { useContext } from 'react'
import { List, } from '@mui/material'

import NavItem from './NavItem'

import { checkActive } from '.'
import AccountContext from '../../context/AccountContext'


const NavList = ({ items, match, collapsed, sx, openWidth, activeBackground, style, SETTINGS, projectMember }) => {
    const [ account ] = useContext(AccountContext)

    return (
        <List
            sx={{
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'flex-start',
                p: items ? SETTINGS.listPadding : 0,
                py: SETTINGS.listPadding,
                gap: SETTINGS.listItemGap,
                width: items ? (collapsed ? SETTINGS.collapsedWidth : openWidth) : 0,
                transition: 'all ' + SETTINGS.transitionDuration + ' ease-in-out',
                overflow: 'hidden',
                '&:hover': {
                    overflow: 'auto'
                },
                ...sx
            }}
            style={style}
        >
            {items?.map((i, idx) => {
                if (i.show && !i.show(account, projectMember)) return null
                return <NavItem
                    key={idx}
                    item={i}
                    SETTINGS={SETTINGS}
                    isActive={checkActive(i, match)}
                    basePath={match.pathnameBase}
                    onClick={i.onClick}
                    activeBackground={activeBackground}
                />
            })}
        </List>
    )
}

export default NavList
