import { useEffect, useState } from 'react';

const useCountdown = (targetDate, reverse=false) => {
	const countDownDate = new Date(targetDate).getTime();

	const [countDown, setCountDown] = useState(() => {
		return reverse ? new Date().getTime() - countDownDate : countDownDate - new Date().getTime()
	});

	useEffect(() => {
		const interval = setInterval(() => {
			setCountDown(() => {
				return reverse ? new Date().getTime() - countDownDate : countDownDate - new Date().getTime()
			});
	}, 1000);

	return () => clearInterval(interval);
	}, [countDownDate, reverse]);

	return getReturnValues(countDown);
};

const getReturnValues = (countDown) => {
	// calculate time left
	const days = Math.floor(countDown / (1000 * 60 * 60 * 24));
	const hours = Math.floor(
		(countDown % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)
	);
	const minutes = Math.floor((countDown % (1000 * 60 * 60)) / (1000 * 60));
	const seconds = Math.floor((countDown % (1000 * 60)) / 1000);

	return [days, hours, minutes, seconds];
};

export default useCountdown;
