export const getConstants = (key, callback) =>{
    fetch('/api/constants?key=' + key, {
        method: 'GET',
    }).then(response => {
        response.json().then(data => {
            if(response.ok){
                callback(data)
            }else{
            }
        }).catch(error => {
            console.error(error)
        })
    }).catch(error => {
        console.error(error)
    })
}
