import React, { useContext } from 'react';
import { Navigate } from 'react-router-dom';

import ProjectContext from '../../context/ProjectContext'

const CheckProjectPermission = ({children, safety_level, redirectTo}) => {
    const [, projectMember] = useContext(ProjectContext)

    const checkAccess = () =>{
        if(safety_level === "leader"){
            return projectMember.is_leader
        } else if (safety_level === "manager"){
            return projectMember.is_leader || projectMember.is_manager
        }
        return false
    }
    return checkAccess() ?
        children
        : <Navigate to={redirectTo} />
};

export default CheckProjectPermission;