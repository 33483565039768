import {useState, useEffect, useContext} from "react"
import ProjectContext from '../../../../context/ProjectContext'

import { Grid, Stack } from "@mui/material"
import { Folder } from "@mui/icons-material";

import CustomTabPanel from "../../../Widgets/CustomTabPanel";
import CheckSubProjects from "../../../CheckSubProjects";
import CustomTabs from "../../../Widgets/CustomTabs";
import ProjectTimestampsInfo from "./ProjectTimestampsInfo";
import ProjectTimestampsDiagram from "./ProjectTimestampsDiagram";
import ProjectTimestampsTable from "./ProjectTimestampsTable";
import { useTaskTab } from "../../../../context/TaskTabContext";



const ProjectTimestamps = () =>{

    const [project] = useContext(ProjectContext)
    const [value, setValue] = useTaskTab();
    const [tabs, setTabs] = useState([]);
    const [isLoaded, setIsLoaded] = useState(false)

    const [projectTimeRecording, setProjectTimeRecording] = useState([
        {
            'title': null,
            'project_timestamps': [],
            'member_timestamps_sum':[],
            'current_workload': 0,
            'max_workload': 0,
        }
    ])
    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    // getProjectTimeRecording
    useEffect(() => {
        (async() =>{
            const res = await fetch('/api/project/' + project.id + '/timestamps', {
                method: 'GET'
            })
            const data = await res.json()
            if(res.ok){
                setProjectTimeRecording(data)
                setTabs(data.map((timestamp, idx) => ({ index: idx, label: timestamp.title, icon: <Folder sx={{fontSize: '20px'}}/> })))
                setIsLoaded(true)
            }
        })()
    }, [project.id])

    return(
        <CheckSubProjects
            subs={projectTimeRecording}
            isLoaded={isLoaded}
            info_text="Du brauchst erstmal ein Projektauftrag, damit die Mitarbeiter Stunden auf den Auftrag buchen können"
        >
            <Stack sx={{ flex: '1 1 0' }} gap={3}>
                <CustomTabs
                    value={value}
                    handleChange={handleChange}
                    tabs={tabs}
                />
                {projectTimeRecording.map((projectTimestamps, idx)=>{
                    let memberTimestamps = projectTimestamps.member_timestamps_sum.sort((a,b) => (a.workload > b.workload) ? -1 : ((b.workload > a.workload) ? 1 : 0))
                    return(
                        <CustomTabPanel
                            key={`${projectTimestamps.title}-${idx}`}
                            value={value}
                            index={idx}
                        >
                            <Grid
                                container
                                spacing={3}
                            >
                                <Grid
                                    item
                                    desktop={6}
                                    laptop={6}
                                    tablet={12}
                                    mobile={12}
                                >
                                    <ProjectTimestampsDiagram
                                        memberTimestamps={memberTimestamps}
                                    />
                                </Grid>
                                <Grid
                                    item
                                    desktop={6}
                                    laptop={6}
                                    tablet={12}
                                    mobile={12}
                                >
                                    <ProjectTimestampsInfo
                                        projectTimestamps={projectTimestamps}
                                    />
                                </Grid>
                                <Grid
                                    item
                                    desktop={12}
                                    laptop={12}
                                    tablet={12}
                                    mobile={12}
                                >
                                    <ProjectTimestampsTable
                                        project_timestamps={projectTimestamps.project_timestamps}
                                    />
                                </Grid>
                            </Grid>
                        </CustomTabPanel>
                    )
                })}
            </Stack>
        </CheckSubProjects>
    )
}

export default ProjectTimestamps
