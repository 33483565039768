import { useState } from "react"
import { useForm } from "react-hook-form"
import { Stack, Dialog, DialogContent, DialogContentText, DialogTitle, Divider, Typography, Button, FormGroup, FormControlLabel, Switch, Collapse, Alert, Box, AlertTitle } from "@mui/material"

import CSVReader from "components/Widgets/CSVReader"
import ControlledSelect from "components/Widgets/ControlledInputs/ControlledSelect"
import { getCookie } from "utils/getCookie"
import useStyledSnackbar from "hooks/useStyledSnackbar"



const ImportDialog = ({ open, onClose, project_id, selectedSubProject, getSubProjectMaterial }) =>{

    const { enqueueSnackbar } = useStyledSnackbar()
    const [csvData, setCsvData] = useState([])

    const [useReferenceID, setUseReferenceID] = useState(false);

    const handleChange = (event) => {
        trigger(['unique_material_id', 'reference_id'])
        setUseReferenceID(event.target.checked);
    };

    const haveData = csvData.length !== 0
    const items = haveData ?  csvData[0] : []

    const { handleSubmit, control, reset, trigger, formState: { errors, isDirty, isValid }} = useForm({
        mode: 'onChange',
        defaultValues: {
            unique_material_id: '',
            article_description:'',
            value_offered: '',
            pre_price: '',
            unit_key: '',
            reference_id: '',
            use_reference_id: false
        }
    })

    const handlleImportCSV = async(data) => {
        const json = JSON.stringify({
            mapping: data,
            data: csvData,
            use_reference_id: useReferenceID
        })
        const res = await fetch('/api/project/' + project_id + '/sub-project/'+ selectedSubProject +'/material/import', {
			method: 'POST',
			headers: {
				'Content-Type': 'application/json',
				'X-CSRFToken': getCookie('csrftoken')
			},
			body: json
		})
        const _data = await res.json()
		if(res.ok){
            enqueueSnackbar('Das Angebot wurde erfolgreich importiert', {
                variant: 'success'
            })
            getSubProjectMaterial()
			handleOnClose()
		} else {
            enqueueSnackbar(_data?.error, {
                variant: 'error'
            })
        }
    }

    const handleOnClose = () =>{
        onClose()
        setCsvData([])
        reset()
    }

    return(
        <Dialog
            open={open}
            onClose={handleOnClose}
            fullWidth
            maxWidth="laptop"
        >
            <DialogTitle>
                Angebot importieren
            </DialogTitle>
            <DialogContent>
                <DialogContentText>
                    Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et justo duo dolores et ea rebum.
                </DialogContentText>
                <Stack
                    direction="row"
                    gap={2}
                    sx={{mt: 2}}
                >
                    <CSVReader setCsvData={setCsvData}/>
                    <Divider orientation="vertical" flexItem/>
                    <Stack
                        gap={2}
                        sx={{width: 350}}
                    >
                        <Typography variant='subtitle_m'>
                            Zuordnung
                        </Typography>
                        <Stack
                            component="form"
                            onSubmit={handleSubmit(handlleImportCSV)}
                            gap={2}
                            sx={{flex: 0.5, minWidth: 300}}
                        >
                            <ControlledSelect
                                name='unique_material_id'
                                label='Artikelnummner (CONMEET ID)'
                                items={items}
                                rules={{ required: !useReferenceID}}
                                errors={errors}
                                control={control}
                                disabled={!haveData || useReferenceID}
                            />
                            <ControlledSelect
                                name='reference_id'
                                label='Referenznummer'
                                items={items}
                                rules={{ required: useReferenceID }}
                                errors={errors}
                                control={control}
                                disabled={!haveData || !useReferenceID}
                            />
                            <Box>
                                <FormGroup>
                                    <FormControlLabel
                                        control={
                                            <Switch
                                                checked={useReferenceID}
                                                onChange={handleChange}
                                                disabled={!haveData}
                                            />
                                        }
                                        label='Referenznummer verwenden'
                                    />
                                </FormGroup>
                                <Collapse in={useReferenceID}>
                                    <Alert
                                        severity="warning"
                                        sx={{boxShadow: 'none'}}
                                    >
                                        <AlertTitle>Hinweis!</AlertTitle>
                                        Wenn die Referenznummer zu keinem deiner Artikel in deiner Materiabank passt, wir automatisch ein neuer Artikel mit dieser Referenznummer in deiner Materialdatenbank anlegt!
                                    </Alert>
                                </Collapse>
                            </Box>
                            <ControlledSelect
                                name='article_description'
                                label='Artikelbezeichung'
                                items={items}
                                rules={{ required: true }}
                                errors={errors}
                                control={control}
                                disabled={!haveData}
                            />
                            <ControlledSelect
                                name='value_offered'
                                label='Angebotsmenge'
                                items={items}
                                rules={{ required: true }}
                                errors={errors}
                                control={control}
                                disabled={!haveData}
                            />
                            <ControlledSelect
                                name='pre_price'
                                label='Angebotspreis'
                                items={items}
                                rules={{ required: true }}
                                errors={errors}
                                control={control}
                                disabled={!haveData}
                            />
                            <ControlledSelect
                                name='unit_key'
                                label='Einheit'
                                items={items}
                                rules={{ required: true }}
                                errors={errors}
                                control={control}
                                disabled={!haveData}
                            />
                            <Button
                                variant="outlined"
                                type="submit"
                                disabled={!isValid || !isDirty}
                            >
                                Importieren
                            </Button>
                        </Stack>
                    </Stack>
                </Stack>
            </DialogContent>
        </Dialog>
    )
}

export default ImportDialog