import { Stack, Button, Typography } from '@mui/material';

import { getCookie } from '../../../../utils/getCookie';
import { formatTimestamp, getLocalDateTime } from '../../../../utils/date_functions';
import useStyledSnackbar from '../../../../hooks/useStyledSnackbar'


import CustomPaper from '../../../Widgets/CustomPaper';
import ConfirmDialog from '../../../Widgets/ConfirmDialog';
import CustomTimeCountdown from '../../../Widgets/CustomTimeCountdown';


const ProjectDone = ({ project, getProject, setRedirect }) =>{

    const { enqueueSnackbar } =  useStyledSnackbar()

    const handleDone = async() =>{
        const json = JSON.stringify("")
        const res = await fetch('/api/project/' + project.id+ '/complete', {
            method: 'PATCH',
            headers: {
                'Content-Type': 'application/json',
                'X-CSRFToken': getCookie('csrftoken')
            },
            body: json
        })
        const _data = await res.json()
        if(res.ok){
            getProject()
            if(_data.completed){
                enqueueSnackbar(`Das Projekt wird um ${formatTimestamp(_data.completed_on)} als abgeschlossen gekennzeichnet!`, {
                    variant: 'success'
                })
            } else {
                enqueueSnackbar(`Der Projektabschluss wurde erfolgreich aufgehoben!`, {
                    variant: 'success'
                })
            }
        } else {
            setRedirect(true)
        }
    }

    return(
        <CustomPaper
            title={project.completed_on ? 'Projekt abgeschlossen seit' : 'Projekt abschließen'}
        >
            <Stack spacing={2}>
                {project.completed_on ?
                        <>
                            <CustomTimeCountdown
                                targetDate={project.completed_on}
                                reverse={true}
                            />
                            <Typography variant="text_m_justify">
                                Das Projekt wurde am {getLocalDateTime(new Date(project.completed_on))} abgeschlossen.
                            </Typography>
                        </>
                    :
                        <Typography variant="text_m_justify" >
                            Hier kannst du das Projekt abschließen.
                        </Typography>
                    }
                <ConfirmDialog
                    title='Sicher?'
                    message={project.completed_on ? 'Bist du dir sicher, dass du den Projektabschluss widerrufen möchtest?': "Bist du dir sicher, dass du Projekt abschließen willst?" }
                    onConfirm={handleDone}
                    renderButton={(onClick) => (
                        <Button
                            onClick={onClick}
                            variant="outlined"
                            color={project.completed_on ? "secondary" : "primary"}
                            size="large"
                        >
                            {project.completed_on ? "Projektabschluss widerrufen" : "Projekt abschliessen"}
                        </Button>
                    )}
                />
            </Stack>
        </CustomPaper>
    )
}

export default ProjectDone
