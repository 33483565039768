import { useContext, useState, useEffect } from 'react'
import { useForm } from 'react-hook-form'

import { Grid } from '@mui/material'

import { getCookie } from '../../../utils/getCookie'
import { getConstants } from '../../../utils/getConstants'

import useStyledSnackbar from '../../../hooks/useStyledSnackbar'

import AccountContext from '../../../context/AccountContext'

import ControlledTextField from '../../Widgets/ControlledInputs/ControlledTextField'
import ControlledSelect from '../../Widgets/ControlledInputs/ControlledSelect'
import CustomFormPaper from '../../Widgets/CustomFormPaper'


const ChangeProfile = () => {

    const [account, , setAccount] = useContext(AccountContext)
    const [loading, setLoading] = useState(false)
    const [success, setSuccess] = useState(false)

    const { handleSubmit, setError, control, formState: { errors, isDirty, isValid }} = useForm({
        mode: 'onChange',
        defaultValues: {
            gender: account.gender_key,
            firstname: account.firstname,
            lastname: account.lastname,
            phone_number: account.phone_number
        }
    })

    const { enqueueSnackbar } =  useStyledSnackbar()

    const onSubmit = async data => {
        setLoading(true)
        const res = await fetch('/api/account', {
            method: 'PATCH',
            headers: {
                'Content-Type': 'application/json',
                'X-CSRFToken': getCookie('csrftoken'),
            },
            body: JSON.stringify(data)
        })
        const json = await res.json()
        if (res.ok) {
            setAccount(json)
            setLoading(false)
            setSuccess(true)
            enqueueSnackbar('Ihr Profil wurde erfolgreich aktualisiert!', {
                variant: 'success'
            })
        } else {
            Object.entries(json).forEach(([field, message]) => {
                console.log(field)
                console.log(message)
                setError(field, {
                    type: 'server',
                    message: message
                })
            })
            setLoading(false)
        }
    }
    const [genders, setGenders] =  useState({})

    useEffect(() => {
        getConstants('genders', c => setGenders(c));
    }, [])

    useEffect(() => {
        setSuccess(false);
    }, [isDirty])

    return (
        <CustomFormPaper
            title="Profil ändern"
            onSubmit={handleSubmit(onSubmit)}
            tooltip={'Speichern'}
            success={success}
            loading={loading}
            disabled={!isDirty || !isValid}
        >
            <Grid container spacing={2}>
                <Grid item mobile={12} laptop={6}>
                    <ControlledTextField
                        control={control}
                        name='firstname'
                        rules={{ required: true }}
                        type='text'
                        label='Vorname'
                        errors={errors}
                    />
                </Grid>
                <Grid item mobile={12} laptop={6}>
                    <ControlledTextField
                        control={control}
                        name='lastname'
                        rules={{ required: true }}
                        type='text'
                        label='Nachname'
                        errors={errors}
                    />
                </Grid>
                <Grid item mobile={12} laptop={6}>
                    <ControlledSelect
                        control={control}
                        name='gender'
                        label='Geschlecht'
                        errors={errors}
                        items={genders}
                    />
                </Grid>
                <Grid item mobile={12} laptop={6}>
                    <ControlledTextField
                        control={control}
                        name='phone_number'
                        rules={{ required: true }}
                        type='text'
                        label='Telefonnummer'
                        errors={errors}
                    />
                </Grid>
            </Grid>
        </CustomFormPaper>
    )
}

export default ChangeProfile