import { CircularProgress, Button} from "@mui/material"
import { Save, Check } from "@mui/icons-material"


const CustomFromButton = ({ title, onClick, disabled, loading, success }) =>{

    return(
        <Button
            onClick={onClick}
            disabled={disabled || loading || success}
            color="primary"
            size="large"
            type="submit"
            variant="outlined" startIcon={
                success ?
                    <Check size="large" color="primary"/>
                :
                    loading ?
                        <CircularProgress color="secondary" size={20}/>
                    :
                        <Save color={disabled ? "default" :"primary" } fontSize="medium"/>
                }
        >
            {title}
        </Button>
    )
}

export default CustomFromButton