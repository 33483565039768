import { useState } from 'react'
import { matchPath } from 'react-router-dom'

import { Stack, List } from '@mui/material'

import NavList from './NavList'
import Logo from './Logo'
import Account from './Account'
import Logout from './Logout'
import Support from './Support'

import theme, { PALETTE, THEME_SETTINGS } from '../theme'
import { ICONSIZE } from '../NavBar/Data/Default'


export const SETTINGS = {
    transitionDuration: '0.1s',
    collapsedWidth: 48 + 16,
    openWidth: 230,
    listPadding: 1,
    listItemGap: 1,
    itemContentGap: 2,
    itemHeight: '48px',
    itemBorderRadius: 2,
    iconSize: ICONSIZE,
    fontSize: '16px',
    colors: {
        mainNav: 'background.main',
        subNav: 'background.light10',
        mainActiveBackground: 'background.light10',
        subActiveBackground: 'background.main',
        hoverBackground: 'background.dark5',
        textColor: 'text.light',
        iconActive: 'inherit'
    }
}

export const checkActive = (item, match) => {
    return matchPath(
        (item.relative ? match.pathnameBase : '') +
            (item.isActivePath ? item.isActivePath : item.path) +
            (item.dropDown ? '/*' : ''),
        window.location.pathname)
}

const NavBar = ({ data, basePath }) => {
    const match = matchPath(basePath, window.location.pathname)
    const dropDown = data[0].find(i => checkActive(i, match))?.dropDown
    const [overrideCollapse, setOverrideCollapse] = useState(false)

    const collapsed = Boolean(dropDown) && !overrideCollapse
    const openWidth = Boolean(dropDown) ? SETTINGS.openWidth : SETTINGS.openWidth + SETTINGS.collapsedWidth

    return (
        <Stack
            direction='column'
            sx={{
                backgroundColor: SETTINGS.colors.subNav,
                boxShadow: THEME_SETTINGS.boxShadow,
                zIndex: 999,
                height: '100vh',
                minHeight: 0,
                position: 'fixed',

            }}
        >
            <Stack
                alignItems='center'
                gap={3}
                sx={{ backgroundColor: SETTINGS.colors.mainNav, color: SETTINGS.colors.textColor, pb: 0}}
            >
                <Logo
                    collapsed={false}
                    openWidth={SETTINGS.openWidth + SETTINGS.collapsedWidth}
                />
            </Stack>
            <Stack
                direction='row'
                sx={{
                    backgroundColor:
                    SETTINGS.colors.mainNav,
                    color: SETTINGS.colors.textColor,
                    flex: 1,
                    overflow: 'hidden'
                }}
            >
                <Stack
                    onMouseEnter={() => setOverrideCollapse(true)}
                    onMouseLeave={e => {
                        if (e.clientX < 0) return
                        setOverrideCollapse(false)
                    }}
                    sx={{ backgroundColor: SETTINGS.colors.mainNav }}
                    justifyContent='space-between'
                    alignItems='center'
                >
                    <NavList
                        items={data[0]}
                        match={match}
                        collapsed={collapsed}
                        SETTINGS={SETTINGS}
                        openWidth={openWidth}
                        activeBackground={SETTINGS.colors.mainActiveBackground}
                        sx={{
                            "::-webkit-scrollbar-thumb": {
                                background: theme.palette.background.light10,
                                borderRadius: '5px',
                            },
                        }}
                    />
                    <Stack>
                        <NavList
                            items={data[1]}
                            match={match}
                            SETTINGS={SETTINGS}
                            collapsed={collapsed}
                            openWidth={openWidth}
                            activeBackground={SETTINGS.colors.mainActiveBackground}
                        />
                        <List
                            sx={{
                                display: 'flex',
                                flexDirection: 'column',
                                justifyContent: 'flex-start',
                                overflow: 'hidden',
                                p: SETTINGS.listPadding,
                                py: SETTINGS.listPadding,
                                gap: SETTINGS.listItemGap,
                                width: collapsed ? SETTINGS.collapsedWidth : openWidth,
                                transition: 'all ' + SETTINGS.transitionDuration + ' ease-in-out',
                            }}
                        >
                            <Support activeBackground={SETTINGS.colors.mainActiveBackground}/>
                            <Account activeBackground={SETTINGS.colors.mainActiveBackground} />
                            <Logout />
                        </List>
                    </Stack>
                </Stack>
                <NavList
                    items={dropDown}
                    match={match}
                    SETTINGS={SETTINGS}
                    collapsed={overrideCollapse}
                    sx={{
                        background: 'linear-gradient(to top left, ' + PALETTE.background.main + ', ' + PALETTE.background.light10 +  ' 80%)',
                        boxShadow: 'inset 2px 2px 3px -1px rgba(0,0,0,0.10)',
                        "::-webkit-scrollbar-thumb": {
                            background: theme.palette.background.main,
                            borderRadius: '5px',
                        },
                    }}
                    style={{ borderTopLeftRadius: SETTINGS.itemBorderRadius * 8 }}
                    openWidth={SETTINGS.openWidth}
                    activeBackground={SETTINGS.colors.subActiveBackground}
                />
            </Stack>
        </Stack>
    )
}

export default NavBar
