import { useState, useCallback } from "react";
import { useForm } from "react-hook-form";

import { Alert, AlertTitle, Button, Dialog, DialogActions, DialogContent, DialogTitle, Stack, Typography, Chip, Divider, Box, IconButton} from "@mui/material";
import { Delete, Mail } from "@mui/icons-material";

import useStyledSnackbar from "hooks/useStyledSnackbar";
import { getCookie } from "utils/getCookie";
import ControlledSelect from "components/Widgets/ControlledInputs/ControlledSelect";
import ControlledTextField from "components/Widgets/ControlledInputs/ControlledTextField";
import { useEffect } from "react";
import NoData from "components/Widgets/NoData";
import { getLocalTimeStamp } from "utils/date_functions";
import { PALETTE, THEME_SETTINGS } from "components/theme";

const handleChip = (status_key, status) => {
    switch(status_key){
        case 6:
            return <Chip label={status} variant="outlined"/>
        case 7:
            return <Chip label={status} variant="filled"/>
        case 8:
            return <Chip label={status} variant="outlined" color="primary"/>
        case 9:
            return <Chip label={status} variant="filled" color="error"/>
        case 10:
            return <Chip label={status} variant="filled" color="primary"/>
        case 11:
            return <Chip label={status} variant="outlined" color="error"/>
        default:
            return <Chip label={status} variant="outlined"/>
    }

}

const SupportDialog = ({ open, onClose }) => {

    const { enqueueSnackbar } = useStyledSnackbar()
    const [supportMessages, setSupportMessages] = useState([])

    const handleClose= () => {
        onClose()
        reset()
    }

    const { control, handleSubmit, reset, formState: { errors, isValid, isDirty }} = useForm({
        mode: 'onChange',
        defaultValues:{
            type_key: '',
            description: ''
        }
    });

    const handleRequestSupport = async(data) => {
        const json = JSON.stringify(data)
        const res =  await fetch('/api/support', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'X-CSRFToken': getCookie('csrftoken')
            },
            body: json
        })
        if(res.ok){
            enqueueSnackbar('Dein Anliegen wurde erfolgreich erfasst und wird bearbeitet!', {
                variant: 'success'
            })
            reset()
            getSupportMessages()
        } else{
            enqueueSnackbar('Sorry, es ist irgendwas schiefgelaufen. Bitte probiere es später nochmal oder rufe uns einfach an!', {
                variant: 'error'
            })
        }
    }

    const deleteRequestSupport = async(id) => {
        const json = JSON.stringify({
            support_message_id: id
        })
        const res =  await fetch('/api/support', {
            method: 'Delete',
            headers: {
                'Content-Type': 'application/json',
                'X-CSRFToken': getCookie('csrftoken')
            },
            body: json
        })
        if(res.ok){
            enqueueSnackbar('Dein Anliegen wurde erfolgreich gelöscht!', {
                variant: 'success'
            })
            getSupportMessages()
        } else{
            enqueueSnackbar('Sorry, es ist irgendwas schiefgelaufen. Bitte probiere es später nochmal oder rufe uns einfach an!', {
                variant: 'error'
            })
        }
    }

    const getSupportMessages = useCallback(async() => {
        const res =  await fetch('/api/support', {
            method: 'GET'
        })
        const data = await res.json()
        if(res.ok){
            // console.log(data)
            setSupportMessages(data)
        }
    },[])

    useEffect(()=>{
        if(open){
            getSupportMessages()
        }
    },[open, getSupportMessages])

    return (
        <Dialog
            maxWidth="laptop"
            open={open}
            onClose={handleClose}
            fullWidth
        >
            <DialogTitle>Kundensupport</DialogTitle>
            <DialogContent>
                <Stack
                    flexDirection="row"
                    gap={3}
                    sx={{
                        mt: 1,
                        height: 750
                    }}
                >
                    <Stack
                        component="form"
                        onSubmit={handleSubmit(handleRequestSupport)}
                        gap={2}
                        justifyContent="space-between"
                        height="100%"
                        width="42%"

                    >
                        <Stack gap={2}>
                            <ControlledSelect
                                name="type_key"
                                label="Worum geht es?"
                                control={control}
                                rules={{ required: true }}
                                items={{
                                    '1': 'Problem melden',
                                    '2': 'Feedback abgeben',
                                    '3': 'Idee zur Verbesserung vorschlagen',
                                    '4': 'Fragen zur Löschung eines Mitarbeites?',
                                    '5': 'Ich möchte mein Unternehmen aus CONMEET löschen'
                                }}
                                sx={{mt: 5}}
                                errors={errors}
                            />
                            <ControlledTextField
                                name="description"
                                label="Beschreibung"
                                control={control}
                                rules={{ required: true }}
                                multiline
                                rows={15}
                                errors={errors}
                            />
                            <Button
                                type="submit"
                                variant="outlined"
                                color="primary"
                                size="large"
                                disabled={!isValid || !isDirty}
                                startIcon={<Mail/>}
                            >
                                Einreichen
                            </Button>
                        </Stack>
                        <Alert
                            severity="info"
                            variant="outlined"
                            sx={{boxShadow: 0, mt:5}}
                        >
                            <AlertTitle>Telefonisch</AlertTitle>
                                Falls du ein dringliches Problem hast kannst du uns zwischen <b>8:00 - 16:00</b> Uhr unter folgender Nummer <b>0175/2049801</b> erreichen. Ansonsten nutze doch einfach die Kundensupport Funktion. Wir bearbeiten dein Anliegen schnellstmöglich und melden uns bei dir. <br/><br/> Dein CONMEET Team!
                        </Alert>
                    </Stack>
                    <Stack
                        gap={3}
                        width='58%'
                        sx={{
                            overflow: 'auto',
                            p: 2,
                            border: 1,
                            borderRadius: '7px',
                            borderColor: PALETTE.background.light70,
                        }}
                    >
                        {supportMessages.length !== 0 ?
                            supportMessages.map((sm, idx) => {
                                const haveReplies = sm.replies.length !== 0
                                const repliesMoreThenOne = sm.replies.length >= 2
                                return(
                                    <Stack
                                        key={`support-card-${sm.id}`}
                                        gap={0.5}
                                        sx={{
                                            borderRadius: '7px',
                                            boxShadow: THEME_SETTINGS.boxShadow,
                                            p:1.5
                                        }}
                                    >
                                        <Stack
                                            flexDirection="row"
                                            justifyContent="space-between"
                                        >
                                            <Typography
                                                variant="subtitle_m"
                                                gutterBottom
                                            >
                                                {sm.type}
                                            </Typography>
                                            <Typography variant="text_s">
                                                {`${getLocalTimeStamp(new Date(sm.created))}`}
                                            </Typography>
                                        </Stack>
                                        <Stack
                                            flexDirection="row"
                                            alignItems="center"
                                            gap={1}
                                            sx={{pb: 2}}
                                        >
                                            {handleChip(sm.status_key, sm.status)}
                                            <IconButton
                                                onClick={()=> deleteRequestSupport(sm.id)}
                                                color="error"
                                            >
                                                <Delete/>
                                            </IconButton>
                                        </Stack>
                                        <Typography variant="text_m_justify">
                                            {sm.description}
                                        </Typography>
                                        {haveReplies &&
                                            <Stack
                                                gap={1}
                                                sx={{ mt:2 }}
                                            >
                                                <Box>
                                                    <Divider textAlign="left">
                                                        <Typography
                                                            variant="subtitle_s"
                                                        >
                                                            {repliesMoreThenOne ? 'Antworten' : 'Anwort'}
                                                        </Typography>
                                                    </Divider>
                                                </Box>
                                                <Stack
                                                    gap={1}
                                                >
                                                    {sm.replies.map((reply, idx)=>{
                                                        return(
                                                            <Stack
                                                                key={`reply-${idx}`}
                                                                gap={0.5}
                                                            >
                                                                <Typography variant="text_m_justify">
                                                                    {reply.message}
                                                                </Typography>
                                                                <Typography variant="text_s" color="primary">
                                                                    {`${getLocalTimeStamp(new Date(reply.created))}`}
                                                                </Typography>
                                                            </Stack>
                                                        )
                                                    })}
                                                </Stack>
                                            </Stack>
                                        }
                                    </Stack>
                                )
                            })
                        :
                            <NoData text="Derzeit kein Anliegen"/>
                        }
                    </Stack>
                </Stack>
            </DialogContent>
            <DialogActions>
                <Button
                    onClick={handleClose}
                    color="secondary"
                >
                    Schließen
                </Button>
            </DialogActions>
        </Dialog>
    );
}

export default SupportDialog;