import { useSnackbar } from 'notistack'
import { useCallback } from 'react'

const useStyledSnackbar = () => {
    const snackbar = useSnackbar()
    const enqueueSnackbar = useCallback((message, options) => {
        return snackbar.enqueueSnackbar(message, {
            ...options,
            sx: {
                '& .SnackbarContent-root': {
                    backgroundColor:
                        (options?.variant === 'success' && 'success.main') ||
                        (options?.variant === 'error' && 'error.main') ||
                        (options?.variant === 'warning' && 'warning.main') ||
                        (options?.variant === 'info' && 'info.main') ||
                        'background.main'
                },
                ...options?.sx
            }
        })
    }, [snackbar])

    return {
        ...snackbar,
        enqueueSnackbar: enqueueSnackbar
    }
}

export default useStyledSnackbar
