import { useCallback, useEffect, useState } from "react"
import { Stack } from "@mui/material"

import { BsArrowReturnLeft, BsBoxSeam, BsTable } from "react-icons/bs"

import { MdTimeline } from "react-icons/md"

import CustomIconPaperButton from "../../Widgets/CustomIconPaperButton"
import CustomPage from "../../Widgets/CustomPage"

import WarehouseTable from "./WarehouseTables/WarehouseTable"
import WarehouseTransactionTable from "./WarehouseTables/WarehouseTransactionTable"
import WarehouseTransactionDialog from "./WarehouseDialogs/WarehouseTransactionDialog"
import WarehouseActivityDialog from "./WarehouseDialogs/WarehouseActivityDialog"
import WarehouseOrderTable from "./WarehouseTables/WarehouseOrderTable"

import { formatNumber } from "../../../utils/helper"
import { PALETTE } from "../../theme"
import { getCookie } from "../../../utils/getCookie"
import WarehouseReservationTable from "./WarehouseTables/WarehouseReservationTable"
import CustomIconQuadPaperButton from "components/Widgets/CustomIconQuadPaperButton"
import WarehouseCorrectionTable from "./WarehouseTables/WarehouseCorrectionTable"
import WarehouseCorrectionMaterialDialog from "./WarehouseDialogs/WarehouseCorrectionMaterialDialog"


const ICONSIZE = 50

const CompanyWarehouse = () =>{

    const [openActivity, setOpenActivity] = useState(false)
	const [openTx, setOpenTx] = useState(false)
	const [openCorrection, setOpenCorrection] = useState(false)

    const [openTableTx, setOpenTableTx] = useState(false)
    const [openTableOrder, setOpenTableOrder] = useState(false)
	const [openTableRes, setOpenTableRes] = useState(false)
	const [openTableCorrection, setOpenTableCorrection] = useState(false)
	const [orderBy, setOrderBy] = useState('');

    const [stockValues, setStockValues] = useState({
		'activities_count': 0,
		'tx_count': 0,
		'order_tx_count': 0,
		'res_tx_count': 0,
		'correction_tx_count': 0,
		'tx_sum': 0,
		'order_sum': 0,
	})
	const [searchTerm, setSearchTerm] = useState('');
	const [materials, setMaterials] = useState([]);
	const [pagInfo, setPagInfo] = useState({
		count: 0,
		next : null,
		previous: null,
	});
	const [isLoading, setIsLoading] = useState(false)
	const [pageSize, setPageSize] = useState(50)

	const [selectedRows, setSelectedRows] = useState([]);

    const handlePageChange = (x) =>{
		getStockMaterial(pagInfo[x])
	}

	const handleOrderByGetParametersChange = params => {
		setOrderBy(params)
	}

	const getStockMaterial = useCallback(async(url) => {
		setIsLoading(true)
        const res = await fetch(url ? url :'/api/company/warehouse/table?search=' + searchTerm +'&limit=' + pageSize + '&' + orderBy, {
            method: 'GET'
        })
		const data = await res.json()
		if(res.ok){
			setMaterials([...selectedRows, ...data.results].filter((value, index, self) =>
				index === self.findIndex((t) => (
					t.id === value.id
				))
			))
			setPagInfo({
				count: data.count,
				next : data.next,
				previous: data.previous,
			})
		}
		setIsLoading(false)
    }, [pageSize, searchTerm, orderBy])

	const getStockValues = useCallback(async() => {
        const res = await fetch('/api/company/warehouse/values', {
            method: 'GET'
        })
		const data = await res.json()
		if(res.ok){
			setStockValues(data)
			// console.log(data)
		}
    }, [])

	useEffect(()=>{
		getStockValues()
		getStockMaterial()
	}, [getStockValues, getStockMaterial])

	const handleTXPatch = async(row) => {
		const json = JSON.stringify({
			tx: {
				id : row.id,
				value: row.value,
				price: row.price,
				done: row.done
			}
		})
		const res = await fetch('/api/company/warehouse/tx', {
			method: 'PATCH',
			headers: {
				'Content-Type': 'application/json',
				'X-CSRFToken': getCookie('csrftoken')
			},
			body: json
		})
		if(res.ok){
			getStockValues()
			getStockMaterial()
		}
    }

	const handleTXDelete = (getTx) => async(id) => {
        const json = JSON.stringify({
			tx_id: id
		})
        const res = await fetch('/api/company/warehouse/tx', {
            method: 'DELETE',
            headers: {
                'Content-Type': 'application/json',
                'X-CSRFToken': getCookie('csrftoken')
            },
            body: json
        })
		if(res.ok){
			getTx()
			getStockValues()
			getStockMaterial()
		}
		return res.ok
    }

    return(
        <CustomPage
            title="Lager"
        >
            <Stack
                gap={3}
            >
				<CustomIconQuadPaperButton
					mainlabel={`Lagerwert`}
					mainsublabel={`${formatNumber(stockValues.tx_sum + stockValues.order_sum, 2, '€')}`}
					label1="Lagertransaktionen"
					icon1={<BsTable size={ICONSIZE} color={PALETTE.background.light20}/>}
					count1={stockValues.tx_count}
					sublabel1={`Transaktionsumsatz: ${formatNumber(stockValues.tx_sum, 2, '€')}`}
					onClick1={()=> setOpenTableTx(true)}
					label2="Lagerbestellungen"
					icon2={<BsTable size={ICONSIZE} color={PALETTE.background.light20}/>}
					count2={stockValues.order_tx_count}
					sublabel2={`Bestellwert: ${formatNumber(stockValues.order_sum, 2, '€')}`}
					onClick2={()=> setOpenTableOrder(true)}
					label3="Rückgabe"
					icon3={<BsTable size={ICONSIZE} color={PALETTE.background.light20}/>}
					count3={stockValues.correction_tx_count}
					sublabel3={`Stornierungen / Retouren`}
					onClick3={()=> setOpenTableCorrection(true)}
					label4="Lagerreservierungen"
					icon4={<BsTable size={ICONSIZE} color={PALETTE.background.light20}/>}
					count4={stockValues.res_tx_count}
					sublabel4={`Liste aller reservierten Materialien`}
					onClick4={()=> setOpenTableRes(true)}
				/>
				<Stack
					flexDirection='row'
					justifyContent='space-between'
					alignItems='stretch'
					gap={3}
				>
					<CustomIconPaperButton
						label="Transaktionen"
						icon={<BsBoxSeam size={ICONSIZE} color={PALETTE.background.light20}/>}
						onClick={()=> setOpenTx(true)}
						disabled={selectedRows.length === 0}
					/>
					<CustomIconPaperButton
						label="Rückgabe"
						icon={<BsArrowReturnLeft size={ICONSIZE} color={PALETTE.background.light20}/>}
						onClick={()=> setOpenCorrection(true)}
						disabled={false}
					/>
					<CustomIconPaperButton
						label="Aktivitäten"
						icon={<MdTimeline size={ICONSIZE} color={PALETTE.background.light20}/>}
						count={stockValues.activities_count}
						onClick={()=> setOpenActivity(true)}
					/>
				</Stack>
                <WarehouseTable
                    selectedRows={selectedRows}
                    setSelectedRows={setSelectedRows}
                    materials={materials}
                    getStockMaterial={getStockMaterial}
                    getStockValues={getStockValues}
                    pagInfo={pagInfo}
                    isLoading={isLoading}
                    pageSize={pageSize}
                    setPageSize={setPageSize}
                    handlePageChange={handlePageChange}
					searchTerm={searchTerm}
					setSearchTerm={setSearchTerm}
				    onOrderByGetParametersChange={handleOrderByGetParametersChange}
                />
            </Stack>
			<WarehouseTransactionTable
				open={openTableTx}
				onClose={()=> setOpenTableTx(false)}
				subtitle={`Transaktionsumsatz: ${formatNumber(stockValues.tx_sum, 2, '€')}`}
				handleTXPatch={handleTXPatch}
				handleTXDelete={handleTXDelete}
			/>
			<WarehouseOrderTable
				open={openTableOrder}
				onClose={()=> setOpenTableOrder(false)}
				subtitle={`Bestellwert: ${formatNumber(stockValues.order_sum, 2, '€')}`}
				handleTXPatch={handleTXPatch}
				handleTXDelete={handleTXDelete}
			/>
			<WarehouseReservationTable
				open={openTableRes}
				onClose={()=> setOpenTableRes(false)}
				subtitle={`Liste aller reservierten Materialien`}
				handleTXPatch={handleTXPatch}
				handleTXDelete={handleTXDelete}
			/>
			<WarehouseCorrectionTable
				open={openTableCorrection}
				onClose={()=> setOpenTableCorrection(false)}
				subtitle={`Stornierungen / Retouren`}
				getStockMaterial={getStockMaterial}
				getStockValues={getStockValues}
			/>
			<WarehouseActivityDialog
				open={openActivity}
				onClose={()=> setOpenActivity(false)}
			/>
			<WarehouseTransactionDialog
				open={openTx}
				onClose={()=> setOpenTx(false)}
				materials={selectedRows}
				getStockMaterial={getStockMaterial}
				getStockValues={getStockValues}
			/>
			<WarehouseCorrectionMaterialDialog
				open={openCorrection}
				onClose={()=> setOpenCorrection(false)}
				materials={selectedRows}
				getStockMaterial={getStockMaterial}
				getStockValues={getStockValues}
			/>
        </CustomPage>
    )
}

export default CompanyWarehouse
