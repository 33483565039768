import { useState } from 'react';
import { Delete } from '@mui/icons-material';
import { Stack, Box, Button, Divider, Dialog, DialogTitle, DialogContent, DialogActions, ListItem, ListItemAvatar, ListItemText, Avatar, IconButton } from '@mui/material';

import { getCookie } from "../../../../../utils/getCookie";

import useStyledSnackbar from '../../../../../hooks/useStyledSnackbar';

import ConfirmDialog from '../../../../Widgets/ConfirmDialog';
import AddManufacturer from './AddManufacturer';


const ManufacturerDialog = ({ open, onClose, manufacturers, getMaterialManufacturers, getMaterial}) =>{

    const { enqueueSnackbar } = useStyledSnackbar()
    const [haveToLoad, setHaveToLoad] = useState(false)
    const handleClose = () =>{
        if(haveToLoad){
            getMaterial()
        }
        onClose()
    }

    const handleDelete = async(category_id) =>{
        if(category_id === '') return
        const json = JSON.stringify({
            category_id: category_id
        })
		const res = await fetch('/api/company/materials/manufacturer', {
            method: 'DELETE',
            headers: {
                'Content-Type': 'application/json',
                'X-CSRFToken': getCookie('csrftoken')
            },
            body: json
        })
        if(res.ok){
            setHaveToLoad(true)
            getMaterialManufacturers()
            enqueueSnackbar('Die Kategorie wurde erfolgreich gelöscht!', {
                variant: 'success'
            })
        }else{
            enqueueSnackbar('Es ist ein Fehler aufgetreten', {
                variant: 'error'
            })
        }
    }

    return(
        <Dialog
            open={open}
            onClose={handleClose}
            fullWidth
            maxWidth="tablet"
        >
            <DialogTitle>
                Hersteller
            </DialogTitle>
            <DialogContent>
                <Stack
                    spacing={5}
                    width="100%"
                >
                    <AddManufacturer
                        getMaterialManufacturers={getMaterialManufacturers}
                    />
                    <Box
                        height={350}
                        width={'100%'}
                        sx={{
                            overflow: 'auto',

                        }}
                    >
                        {Object.entries(manufacturers).map(([key, value], idx)=>{
                            if(key === '0') return null
                            return(
                                <Box key={`catergory-list-item${key}`}>
                                    <ListItem>
                                        <ListItemAvatar>
                                            <Avatar>
                                                {`H${idx}`}
                                            </Avatar>
                                        </ListItemAvatar>
                                        <ListItemText primary={value}/>
                                        <ConfirmDialog
                                            title='Achtung'
                                            message={'Möchtest du wirklich diesen Hersteller löschen?'}
                                            onConfirm={()=> handleDelete(key)}
                                            renderButton={(onClick) => (
                                                <IconButton
                                                    onClick={onClick}
                                                >
                                                    <Delete/>
                                                </IconButton>
                                            )}
                                        />
                                    </ListItem>
                                    {Object.entries(manufacturers).length - 1 !== idx && <Divider/>}
                                </Box>
                            )
                        })}
                    </Box>
                </Stack>
            </DialogContent>
            <DialogActions>
                <Button
                    onClick={handleClose}
                >
                    Schließen
                </Button>
            </DialogActions>
        </Dialog>
    )
}

export default ManufacturerDialog