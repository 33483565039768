import { useContext, useEffect, useState } from "react";
import ProjectContext from "../../../../../context/ProjectContext";
import { Alert, AlertTitle, Button, MenuItem, Select, Stack, Typography } from "@mui/material";
import AccountContext from "../../../../../context/AccountContext";
import useStyledSnackbar from "hooks/useStyledSnackbar";

const Share = ({ document }) => {
    const [account] = useContext(AccountContext)
    const [project] = useContext(ProjectContext)

    const { enqueueSnackbar } = useStyledSnackbar()
    const [ contacts, setContacts ] = useState([])
    const [ selectedContactIds, setSelectedContactIds ] = useState([])

    useEffect(() => {
        (async () => {
            const res = await fetch(`/api/project/${project.id}/chat`)
            const json = await res.json()
            if (res.ok) {
                const contacts = []
                for (const company of json) {
                    for (const member of company.members) {
                        if (account.id !== member.id && member.chat !== null)
                            contacts.push(member)
                    }
                }
                setContacts(contacts)
            }
        })()
    }, [project.id, account.id])

    const postUploadToChat = (chatId, upload_id, description) => {
        const wsScheme = window.location.protocol === 'https:' ? 'wss' : 'ws'
        const port = process.env.NODE_ENV === 'development' ? ':8000' : ''
        const socketUrl = wsScheme + '://' + window.location.hostname + port +'/chat/' + chatId + '/'
        const socket = new WebSocket(socketUrl)
        socket.addEventListener('open', event => {
            const obj = {
                'command': 'send',
                'message': description,
                'upload_id': upload_id
            }
            socket.send(JSON.stringify(obj));
        })
        socket.addEventListener('message', event => {
            if (JSON.parse(event.data).company_member_id === account.id) socket.close()
        })
        socket.addEventListener('close', event => {

        })
    }

    const handleShare = () => {
        for (const id of selectedContactIds) {
            const cm = contacts.find(cm => cm.id === id)
            postUploadToChat(cm.chat, document.id, document.description)
        }
        enqueueSnackbar('Das Dokument wurde erfolgreich geteilt!', {
            variant: 'success'
        })
    }

    return (
        <Stack gap={2}>
            <Typography variant='subtitle_m'>Mit Projektmitgliedern Teilen</Typography>
            <Alert severity="warning" variant="outlined" sx={{boxShadow: 0}}>
                <AlertTitle>Information zum Teilen von Dokumenten</AlertTitle>
                Du kannst erst Dokumente mit einem Projektmitglied teilen, wenn ein Chat zwischen der Person besteht!.
            </Alert>
            <Select
                value={selectedContactIds}
                onChange={e => setSelectedContactIds(e.target.value)}
                multiple
            >
                {contacts.map(cm => (
                    <MenuItem key={cm.id} value={cm.id}>{cm.firstname} {cm.lastname}</MenuItem>
                ))}
            </Select>
            <Button
                variant="outlined"
                onClick={handleShare}
                fullWidth
            >
                Teilen
            </Button>

        </Stack>
    )
}

export default Share