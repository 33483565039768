import { useState, useEffect, useContext, useCallback } from 'react';

import { ToggleButtonGroup, ToggleButton, Stack, Button, Box, Divider, Grid } from '@mui/material';
import { Add } from '@mui/icons-material';
import { BsBoxArrowInDownRight, BsBoxArrowUpRight, BsBoxArrowInDown } from "react-icons/bs";

import TaskEditDialog from './TaskEditDialog';
import TaskAddDialog from './TaskAddDialog';

import ProjectContext from '../../../../context/ProjectContext'
import NoData from 'components/Widgets/NoData';
import TaskListItem from './TaskListItem';
import TaskDialog from './TaskDialog';

const handleFilter = (filter, tasks) => {
    let filter_tasks = tasks
    switch (filter){
        case 'all':
            break;
        case 'private':
            filter_tasks = tasks.filter(x => x.private)
            break;
        case 'received':
            filter_tasks = tasks.filter(x => x.received)
            break;
        case 'posted':
            filter_tasks = tasks.filter(x => x.posted)
            break;
        default:
            break;
    }
    return filter_tasks
}

const ProjectTasks = () => {

    const [project] = useContext(ProjectContext)

    const [filter, setFilter] = useState('all');
    const [projectTasks, setProjectTasks] = useState([])
    const [filterTasks, setFilterTasks] = useState([])

    const [open, setOpen] = useState(false)
    const [openEdit, setOpenEdit] = useState(false)
    const [openAdd, setOpenAdd] = useState(false)
    const [selectedTask, setSelectedTask] = useState(null)

    const handleOpen = (task) =>{
        setSelectedTask(task)
        setOpen(true)
    }

    const handleOpenEdit = (task) =>{
        setSelectedTask(task)
        setOpenEdit(true)
    }

    const handleCloseEdit = () =>{
        getProjectTasks()
        setOpenEdit(false)
    }

    const handleOpenAdd = () =>{
        setOpenAdd(true)
    }

    const handleCloseAdd = () =>{
        getProjectTasks()
        setOpenAdd(false)
    }

    const handleChangeFilter = (event, newFilter) => {
        if (newFilter !== null) {
            setFilterTasks(handleFilter(newFilter, projectTasks))
            setFilter(newFilter);
        }
    };

    const getProjectTasks = useCallback(async () => {
        const res = await fetch('/api/project/' + project.id + '/tasks', {
            method: 'GET',
        })
        if (res.ok) {
            const data = await res.json()
            setProjectTasks(data)
            setFilterTasks(handleFilter(filter, data))
            // console.log("project tasks", data)
        }
    }, [project.id, filter])

    useEffect(() => {
        getProjectTasks()
    }, [getProjectTasks])

    useEffect(() => {
        if(selectedTask !== null){
            setSelectedTask(projectTasks.find(t => t.id === selectedTask.id))
        }
    }, [projectTasks])

    return (
        <>
            <Stack spacing={3}>
                <Stack
                    direction='row'
                    alignItems='center'
                    spacing={3}
                >
                    <ToggleButtonGroup
                        color="primary"
                        value={filter}
                        onChange={handleChangeFilter}
                        size="small"
                        exclusive
                        fullWidth
                    >
                        <ToggleButton value={'all'}>Alle</ToggleButton>
                        <ToggleButton value={'private'}><Stack direction={'row'} alignItems="center" spacing={1}><BsBoxArrowInDown size={17}/><Box>Eigene</Box></Stack></ToggleButton>
                        <ToggleButton value={'received'}><Stack direction={'row'} alignItems="center" spacing={1}><BsBoxArrowInDownRight size={17}/><Box>Erhaltene</Box></Stack></ToggleButton>
                        <ToggleButton value={'posted'}><Stack direction={'row'} alignItems="center" spacing={1}><BsBoxArrowUpRight size={16}/><Box>Vergebene</Box></Stack></ToggleButton>
                    </ToggleButtonGroup>
                    <Button
                        variant="text"
                        startIcon={<Add/>}
                        color="primary"
                        sx={{width: 250}}
                        onClick={() => handleOpenAdd()}
                    >
                        Neuer Auftrag
                    </Button>
                </Stack>
                {projectTasks !== null && filterTasks.length !== 0?
                    <Grid
                        container
                        spacing={0}
                    >
                        {filterTasks.map((task, idx)=>{
                            return(
                                <Grid
                                    item
                                    key={'task-list-item-wrapper'+ idx}
                                    mobile={12}
                                    tablet={12}
                                    laptop={12}
                                    desktop={12}
                                >
                                    <TaskListItem
                                        key={'task-list-item-'+ idx}
                                        task={task}
                                        handleOpen={handleOpen}
                                        project_id={project.id}
                                    />
                                    {filterTasks.length > idx + 1 && <Divider/>}
                                </Grid>
                            )
                        })}
                    </Grid>
                :
                    <Box
                        sx={{
                            height: 650,
                        }}
                    >
                        <NoData text={`Du hast derzeit keine Aufträge ${filter === 'private' ? 'für dich selbst erstellt' : filter === 'received' ? 'erhalten': 'vergeben'}...`}/>
                    </Box>
                }
            </Stack>
            <TaskDialog
                open={open}
                task={selectedTask === null ? {} : selectedTask}
                project_id={project.id}
                onClose={()=> setOpen(false)}
                handleOpenEdit={handleOpenEdit}
                getProjectTasks={getProjectTasks}
            />
            <TaskEditDialog
                open={openEdit}
                onClose={handleCloseEdit}
                task={selectedTask}
                project_id={project.id}
            />
            <TaskAddDialog
                open={openAdd}
                onClose={handleCloseAdd}
                project_id={project.id}
            />
        </>
    );
}

export default ProjectTasks