import {useContext, useState} from "react";
import {MdSettings} from "react-icons/md";
import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Divider,
    IconButton, Stack,
} from "@mui/material";


import AccountContext from "../../../../../context/AccountContext";
import Outdated from "./Outdated";
import Share from "./Share";


const SettingsButton = ({ document, onOpen, color='white' }) => {
    const [account] = useContext(AccountContext)

    if (account.id !== document.company_member.id)
        return null

    return (
        <IconButton onClick={() => onOpen(document)} color={color}>
            <MdSettings />
        </IconButton>
    )
}

export default SettingsButton
