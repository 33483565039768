import {useCallback, useEffect, useState} from "react"
import {
    Stack,
    Box,
    Avatar,
    Typography,
    Divider,
    DialogContent,
    Dialog,
    DialogTitle,
    CircularProgress
} from "@mui/material"
import InfiniteScroll from 'react-infinite-scroll-component';

import NoData from "../../../../../Widgets/NoData"
import CustomLightWeightAccordion from "../../../../../Widgets/CustomLightWeightAccordion"

import ActivityMaterialListItem from "./ActivityMaterialListItem"

import { formatTimestamp } from '../../../../../../utils/date_functions'
import { PALETTE } from "../../../../../theme"

const handleVerb = (type_key) =>{
    switch(type_key){
        case 1:
            return 'vom Lager hinzugefügt'
        case 2:
            return 'hinzugefügt'
        case 3:
            return 'bestellt'
        case 4:
            return 'verbraucht'
        case 5:
            return 'ins Lager gebracht'
        case 6:
            return 'storniert'
        case 7:
            return 'retourniert'
        default:
            return ''
    }
}

const ActivityDialog = ({ open, onClose, project_id, selectedSubProject }) =>{

    const [lastActivities, setLastActivities] = useState([])
    const [nextUrl, setNextUrl] = useState(null)

    const getLastActivities = useCallback(async(nextUrl) => {
		if(selectedSubProject === undefined || open === false) return
        const res = await fetch(nextUrl ? nextUrl : '/api/project/' + project_id + '/sub-project/' + selectedSubProject + '/material/tx/activity', {
            method: 'GET'
        })
		const data = await res.json()
		if(res.ok){
            if (nextUrl) {
                setLastActivities(old => old.concat(data.results))
            } else {
                setLastActivities(data.results)
            }
            setNextUrl(data.next)
			// console.log(data)
		}
    }, [project_id, selectedSubProject, open])

    useEffect(()=>{
		getLastActivities()
	}, [open, getLastActivities])

    const fetchNext = () => {
        if (nextUrl === null) return
        getLastActivities(nextUrl)
    }

    return(
        <Dialog
            open={open}
            onClose={onClose}
            fullWidth
            maxWidth="laptop"
        >
            <DialogTitle>
                Alle Aktivitäten
            </DialogTitle>
            <DialogContent id='activityDialogContent'>
                <Box
                    sx={{ height: '100%', overflow: 'hidden'}}
                >
                    {lastActivities.length === 0 &&
                        <NoData text='Keine Aktivitäten' />
                    }
                    <InfiniteScroll
                        dataLength={lastActivities.length}
                        next={fetchNext}
                        hasMore={nextUrl !== null}
                        loader={
                            <Stack sx={{ height: '50px', }} alignItems='center'>
                                <CircularProgress />
                            </Stack>
                        }
                        scrollableTarget="activityDialogContent"
                    >
                        {lastActivities.map((activity, idx) => {
                            const pm = activity.project_member
                            const txs = activity.txs
                            const ctxs = activity.ctxs
                            const quantity = txs.length + ctxs.length
                            const mword = quantity !== 0 ? 'Materialien' : 'Material'
                            const verb  = handleVerb(activity.type_key)
                            return (
                                <Box key={`actvity-accordion-container-${idx}`}>
                                    <CustomLightWeightAccordion
                                        key={`actvity-accordion-${idx}`}
                                        title={`${activity.type}`}
                                        accordionbar={
                                            <Stack
                                                direction="row"
                                                alignItems="center"
                                                spacing={2}
                                                width="100%"
                                            >
                                                <Avatar alt="pm" src={pm.avatar} />
                                                <Stack
                                                    width="100%"
                                                    justifyContent="center"
                                                >
                                                    <Typography
                                                        variant="subtitle_s"
                                                        color="text.secondary"
                                                    >
                                                        {`${activity.type}`}
                                                    </Typography>
                                                    <Stack
                                                        direction='row'
                                                        justifyContent="space-between"
                                                    >
                                                        <Typography
                                                            variant="text_s"
                                                            color="text.secondary"
                                                        >
                                                            {`${pm.firstname} ${pm.lastname} hat ${quantity} ${mword} ${verb}!`}
                                                        </Typography>
                                                        <Typography
                                                            variant="text_s"
                                                            color="text.secondary"
                                                        >
                                                            {`${formatTimestamp(activity.created)}`}
                                                        </Typography>
                                                    </Stack>

                                                </Stack>
                                            </Stack>
                                        }
                                    >
                                        <Stack
                                            spacing={1}
                                            sx={{
                                                marginLeft: 5,
                                                marginRight: 1,
                                                padding: 2,
                                                border: 1,
                                                borderRadius: '10px',
                                                borderColor: PALETTE.background.light70
                                            }}
                                        >
                                            <Stack spacing={0.5}>
                                                {txs.map(( tx, idx)=>{
                                                    return(
                                                        <ActivityMaterialListItem
                                                            key={`actvity-tx-${idx}`}
                                                            pos={idx + 1}
                                                            tx={tx}
                                                        />
                                                    )
                                                })}
                                                {ctxs.map((ctx, idx)=>{
                                                    return(
                                                        <ActivityMaterialListItem
                                                            key={`actvity-tx-${idx}`}
                                                            pos={idx + 1}
                                                            tx={ctx}
                                                            correction={true}
                                                        />
                                                    )
                                                })}
                                            </Stack>
                                        </Stack>
                                    </CustomLightWeightAccordion>
                                    {lastActivities.length !== (idx + 1) && <Divider/>}
                                </Box>
                            );
                        })}
                    </InfiniteScroll>
                </Box>
            </DialogContent>
        </Dialog>
    )
}

export default ActivityDialog
