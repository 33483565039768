import { useEffect, useState } from 'react';
import { useForm } from "react-hook-form";

import { Divider, Grid, Box, Typography } from "@mui/material";

import { getConstants } from '../../../../utils/getConstants';
import { getCookie } from '../../../../utils/getCookie';
import { getLocalDate } from '../../../../utils/date_functions';
import useStyledSnackbar from '../../../../hooks/useStyledSnackbar'

import CustomFormPaper from '../../../Widgets/CustomFormPaper';
import ControlledTextField from '../../../Widgets/ControlledInputs/ControlledTextField';
import ControlledSelect from '../../../Widgets/ControlledInputs/ControlledSelect';
import ControlledDateRangePicker from '../../../Widgets/ControlledInputs/ControlledDateRangePicker';


const ProjectEditForm = ({ project, getProject }) =>{

    const { enqueueSnackbar } =  useStyledSnackbar()

    const [loading, setLoading] = useState(false)
    const [success, setSuccess] = useState(false)

    const { control, handleSubmit, setError, formState: { errors, isDirty, isValid }} = useForm({
        mode: 'onChange',
        defaultValues:{
            name: project.name,
            builder: project.builder,
            description: project.description,
            street: project.address.street,
            house_number: project.address.house_number,
            add_on: project.address.add_on,
            postal_code: project.address.postal_code,
            city: project.address.city,
            type_key: project.type_key,
            date_range: [new Date(project.expected_to_start), new Date(project.expected_to_end)]
        }
    });

    const editProject = async(data) =>{
        setLoading(true)
        const json = JSON.stringify({
            ...data,
            expected_to_start: getLocalDate(data.date_range[0]),
            expected_to_end: getLocalDate(data.date_range[1]),
            address: {
                street: data.street,
                house_number: data.house_number,
                add_on: data.add_on,
                postal_code: data.postal_code,
                city: data.city
            }
		})
        const res = await fetch('/api/project/' + project.id , {
            method: 'PATCH',
            headers: {
                'Content-Type': 'application/json',
                'X-CSRFToken': getCookie('csrftoken')
            },
            body: json
        })

        const _data = await res.json()
        if(res.ok){
            setLoading(false)
            getProject()
            enqueueSnackbar('Projektdaten wurde erfolgreich aktualisiert!', {
                variant: 'success'
            })
            setSuccess(true)
        } else{
            setLoading(false)
            Object.entries(_data).forEach(([field, message]) => {
                setError(field, {
                    type: 'server',
                    message: message
                })
            })
        }
    }

    const [projectTypes, setProjectTypes]=  useState({})
    const callback_project_types = (c) =>{setProjectTypes(c)}
    useEffect(() =>{getConstants('project_types', callback_project_types)},[])

    useEffect(() => {
        setSuccess(false);
    }, [isDirty])

    return(
        <CustomFormPaper
            title='Projektdaten ändern'
            onSubmit={handleSubmit(editProject)}
            success={success}
            loading={loading}
            disabled={!isValid || !isDirty}
        >
            <Grid
                container
                justifyContent="flex-start"
                alignItems="flex-start"
                spacing={2}
            >
                <Grid
                    item
                    mobile={12}
                    laptop={12}
                    tablet={12}
                    desktop={12}
                >
                    <ControlledTextField
                        name="name"
                        label="Projektname"
                        control={control}
                        rules={{ required: true }}
                        errors={errors}
                    />
                </Grid>
                <Grid
                    item
                    mobile={12}
                    laptop={6}
                    tablet={6}
                    desktop={6}
                >
                    <ControlledTextField
                        name="description"
                        label="Projektbeschreibung"
                        control={control}
                        rules={{ required: true }}
                        errors={errors}
                        multiline
                        rows={6}
                    />
                </Grid>
                <Grid
                    item
                    mobile={12}
                    laptop={6}
                    tablet={6}
                    desktop={6}
                >
                    <ControlledSelect
                        name="type_key"
                        label="Projektart"
                        control={control}
                        rules={{ required: true }}
                        errors={errors}
                        items={projectTypes}
                    />
                </Grid>
                <Grid
                    item
                    desktop={12}
                    labtop={12}
                    tablet={12}
                    mobil={12}
                >
                    <ControlledDateRangePicker
                        name="date_range"
                        control={control}
                        startText="Projektstart"
                        endText="Projektende"
                    />
                </Grid>
                <Grid
                    item
                    desktop={12}
                    labtop={12}
                    tablet={12}
                    mobil={12}
                >
                    <Box>
                        <Divider textAlign='left'><Typography variant='text_m'>Adresse des Projektes</Typography></Divider>
                    </Box>
                </Grid>
                <Grid
                    item
                    desktop={8}
                    labtop={8}
                    tablet={8}
                    mobil={12}
                >
                    <ControlledTextField
                        name="street"
                        control={control}
                        label="Straße"
                        rules={{ required: true }}
                        errors={errors}
                    />
                </Grid>
                <Grid
                    item
                    desktop={4}
                    labtop={4}
                    tablet={4}
                    mobil={12}
                >
                    <ControlledTextField
                        name="house_number"
                        control={control}
                        label="Hausnummer"
                        rules={{ required: true }}
                        errors={errors}
                    />
                </Grid>
                <Grid
                    item
                    desktop={12}
                    labtop={12}
                    tablet={12}
                    mobil={12}
                >
                    <ControlledTextField
                        name="add_on"
                        control={control}
                        label="Zusatz"
                        rules={{
                            required: false,
                        }}
                        errors={errors}
                    />
                </Grid>
                <Grid item desktop={4} labtop={4} tablet={4} mobil={12}>
                    <ControlledTextField
                        name="postal_code"
                        control={control}
                        label="Postleitzahl"
                        rules={{
                            required: true,
                            minLength: { value: 5, message: "Es gibt keine Postleitzahl mit weniger als 5 Ziffern!"},
                            maxLength: { value: 5, message: "Es gibt keine Postleitzahl mit mehr als 5 Ziffern!"}
                        }}
                        errors={errors}
                    />
                </Grid>
                <Grid item desktop={8} labtop={8} tablet={8} mobil={12}>
                    <ControlledTextField
                        name="city"
                        control={control}
                        label="Stadt"
                        rules={{ required: true }}
                        errors={errors}
                    />
                </Grid>
            </Grid>
        </CustomFormPaper>
    )
}

export default ProjectEditForm
