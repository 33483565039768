import { useState, useEffect, useContext, useCallback } from "react";
import { Typography, Stack, Box, Paper } from "@mui/material";

import AccountContext from "../../../../context/AccountContext";

import TimeRecordingChart from "../TimeRecordingChart";
import TimestampsTable from "../TimestampTable";
import NoDataImg from '../../../../assets/svgs/no_data.svg'

import { getAllDatesInMonth, getLocalDate } from "utils/date_functions";
import { getConstants } from "utils/getConstants";

import CustomPage from "components/Widgets/CustomPage";
import CompanyMemberListItem from "components/Widgets/CompanyMemberListItem";
import CompanyMemberSelection from "components/Widgets/CompanyMemberSelection";

import { PALETTE } from "components/theme";
import TimeRecordingInfoBar from "../TimeRecordingInfoBar";


const TimeRecordingManagement = () =>{

    const [account] = useContext(AccountContext)

    const [selectedCompanyMember, setSelectedCompanyMember] = useState(null)
    const [selectedDate, setSelectedDate] = useState(new Date())

    const [timestamps, setTimestamps] = useState([])
    const [timechartdata, setTimeChartdata] = useState(null)
    const [timestampValues, setTimestampsValues] = useState({})
    const [timeRecordingValues, setTimeRecordingValues] = useState({
        current_mth: '',
        not_confirmed: 0,
        not_confirmed_all: 0,
        to_confirm_list: []
    })

    const [isLoading, setIsLoading] = useState({
        timestamps: false,
        chartData: false,
        values: false,
        mValues: false,
    })


    const [timestampTypeKeys, setTimestampTypeKeys] =  useState({})
    const [projectKeys, setProjectKeys] = useState({})

    const getProjectKeys = async() => {
        const res = await fetch('/api/projects/get-sub-selection?includeCompleted=1&allProjects=' + (account.is_manager ? '1' : '0'), {
            method: 'GET'
        })
        const data = await res.json()
        if(res.ok){
            setProjectKeys(data)
        }
    }

    const handleUpdate = useCallback(() => {

        let initialChartData = getAllDatesInMonth(selectedDate.getFullYear(), selectedDate.getMonth())

        setIsLoading({
            timestamps: true,
            chartData: true,
            values: true
        })

        const getTimeChartdata = async() => {
            if(selectedCompanyMember === null) return
            const res = await fetch('/api/time-recording/chart?company_member_id=' + selectedCompanyMember.id + '&date=' + getLocalDate(selectedDate), {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json'
                },
            })
            const data = await res.json()
            if(res.ok){
                for(let d of data){
                    const index = new Date(d.date).getDate() - 1
                    const _d = initialChartData[index]
                    initialChartData[index] = {
                        ..._d,
                        ...d,
                        Pause: (_d.Pause ? _d.Pause : 0)  + d?.Pause
                    }
                }
                setTimeChartdata(initialChartData)
                setIsLoading((prevState) => ({ ...prevState, chartData: false }))
                // console.log(data)
            }
        }

        const getTimestamps = async() => {
            if(selectedCompanyMember === null) return
            const res = await fetch('/api/time-recording?company_member_id=' + selectedCompanyMember.id + '&date=' + getLocalDate(selectedDate), {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json'
                },
            })
            const data = await res.json()
            if(res.ok){
                setTimestamps(data)
                setIsLoading((prevState) => ({ ...prevState, timestamps: false }))
                console.log(data)
            }
        }

        const getTimeRecordingValues = async() => {
            if(selectedCompanyMember === null) return
            const res = await fetch('/api/time-recording/values?company_member_id=' + selectedCompanyMember.id + '&date=' + getLocalDate(selectedDate), {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json'
                },
            })
            const data = await res.json()
            if(res.ok){
                setTimestampsValues(data)
                setIsLoading((prevState) => ({ ...prevState, values: false }))
                console.log(data)
            }
        }

        const getTimeRecordingManagementValues = async() => {
            const res =  await fetch('/api/time-recording/management?date=' + getLocalDate(selectedDate), {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                },
            })
            const data = await res.json()
            if(res.ok){
                setTimeRecordingValues(data)
                setIsLoading((prevState) => ({ ...prevState, mValues: false }))
                console.log(data)
            }
        }

        getTimeRecordingManagementValues()
        getTimestamps()
        getTimeChartdata()
        getTimeRecordingValues()
    }, [selectedDate, selectedCompanyMember])

    useEffect(() => {
        handleUpdate();
    }, [handleUpdate])

    useEffect(() => {
        getProjectKeys();
        getConstants('timestamp_types', (c) =>{setTimestampTypeKeys(c)});
    }, []);


    return(
        <CustomPage
            title="Überprüfung der Zeiterfassung"
        >
            <Stack gap={3} sx={{flex: 1}}>
                <Paper>
                    <Stack
                        direction="row"
                        alignItems='center'
                        gap={3}
                        sx={{p : 2}}
                    >
                        <CompanyMemberSelection
                            label="Mitarbeiter auswählen"
                            selectedCompanyMember={selectedCompanyMember}
                            setSelectedCompanyMember={setSelectedCompanyMember}
                            onlyWorkers={!account.is_admin}
                            includeDisabled={true}
                            sx={{flex: 1}}
                        />
                        <Box
                            sx={{
                                height: 62,
                                overflow: 'auto',
                                width: 350,
                                border: 1,
                                borderRadius: '7px',
                                borderColor: PALETTE.background.light70,
                            }}
                        >
                            {timeRecordingValues.to_confirm_list.length !== 0 ?
                                timeRecordingValues.to_confirm_list.map((companyMember, idx)=>{
                                    companyMember.job_title = `Unbestätigte Zeiten ${companyMember.not_confirmed_count}`
                                    return(
                                        <CompanyMemberListItem
                                            key={idx}
                                            data={companyMember}
                                        />
                                    )
                                })
                            :
                                <Box
                                    sx={{ p:2 }}
                                >
                                    <Typography variant="text_s" >{`Keine unbestätigte Zeiten in ${selectedDate.toLocaleString('de-DE', { month: 'long', year: 'numeric'})}`}</Typography>
                                </Box>
                            }
                        </Box>
                        <Stack
                            justifyContent='center'
                            alignItems='center'
                            spacing={1}
                            width={180}
                        >
                            <Typography
                                variant="value_highlight"
                                color="secondary.main"
                            >
                                {` ${timeRecordingValues.not_confirmed} | ${timeRecordingValues.not_confirmed_all}`}
                            </Typography>
                            <Typography
                                variant="subtitle_s"
                            >
                                {`Unbestätigte Zeiten`}
                            </Typography>
                            <Typography
                                variant="text_s"
                            >
                                {`(${selectedDate.toLocaleString('de-DE', { month: 'long', year: '2-digit'})} | Gesamt )`}
                            </Typography>
                        </Stack>
                    </Stack>
                </Paper>
                {selectedCompanyMember !== null ?
                    <Stack gap={3}>
                        <TimeRecordingChart
                            companyMember={selectedCompanyMember}
                            timechartdata={timechartdata}
                            selectedDate={selectedDate}
                            setSelectedDate={setSelectedDate}
                        />
                        <TimeRecordingInfoBar
                            data={timestampValues}
                        />
                        <TimestampsTable
                            company_member_id={selectedCompanyMember.id}
                            selectedDate={selectedDate}
                            rows={timestamps}
                            is_manager={account.is_manager}
                            handleUpdate={handleUpdate}
                            timestampTypeKeys={timestampTypeKeys}
                            projectKeys={projectKeys}
                            isLoading={isLoading.timestamps || isLoading.chartData || isLoading.values || isLoading.mValues }
                            hideAllConfirm={!account.is_manager}
                        />
                    </Stack>
                :
                    <Stack
                        justifyContent='center'
                        alignItems='center'
                        height="100%"
                    >
                        <Box
                            component="img"
                            src={NoDataImg}
                            alt="not found"
                            sx={{
                                p: 2,
                                width: '30%'
                            }}
                        />
                        <Typography
                            variant="info_text"
                            sx={{ textAlign: 'center' }}
                        >
                                Wähle ein Mitarbeiter aus...
                        </Typography>
                    </Stack>
                }
            </Stack>
        </CustomPage>
    )
}

export default TimeRecordingManagement
