import { Stack, IconButton, CircularProgress} from "@mui/material"
import { Save, Check } from "@mui/icons-material"


const CustomFromIconButton = ({ disabled, loading, success }) =>{

    return(
        <Stack sx={styles.box}>
            {success ?
                <Check fontSize="large" color="primary"/>
            :
                loading ?
                    <CircularProgress color="secondary" size={25}/>
                :
                    <IconButton
                        color="primary"
                        size="medium"
                        type="submit"
                        disabled={disabled}
                    >
                        <Save fontSize="medium"/>
                    </IconButton>
            }
        </Stack>
    )
}

const styles = {
    box:{
        justifyContent: 'center',
        alignItems: 'center',
        height: 40,
        width: 40,
    },
}

export default CustomFromIconButton