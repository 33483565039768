import { Rating } from '@mui/material';
import { Star, StarBorderOutlined } from '@mui/icons-material';

const CustomizedRating =(props)=>{
	const { value } = props
	return (
		<Rating
			readOnly
			value={value}
			precision={0.5}
			max={6}
			icon={<Star fontSize="inherit"/>}
			emptyIcon={<StarBorderOutlined fontSize="inherit"/>}
		/>
	);
}

export default CustomizedRating;