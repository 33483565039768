import { useForm } from "react-hook-form";

import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Stack } from "@mui/material";

import { getCookie } from "../../../../../utils/getCookie";
import { getLocalTime, getLocalDate } from '../../../../../utils/date_functions'

import ControlledTextField from "../../../../Widgets/ControlledInputs/ControlledTextField";
import ControlledSelect from "../../../../Widgets/ControlledInputs/ControlledSelect";
import ControlledTimePicker from "../../../../Widgets/ControlledInputs/ControlledTimepicker";


const AddEvent = ({ open, onClose, dayDate, workingGroups, projectKeys, getDayEvents, haveToLoadAllEvents }) =>{

    const { control, handleSubmit, watch, formState: { errors, isDirty, isValid }, reset } = useForm({
        mode: 'onChange',
        defaultValues:{
            title: '',
            working_group: '',
            sub_project_key: '',
            since: null,
            till: null,
            repeat: 0,
            description: ''
        }
    })

    const buildWorkingGroupItems = (workingGroups) =>{
        let _items = {}
        workingGroups.forEach((group)=>{
            _items[group.id] = group.name
        })
        return _items
    }

    const handleNewEvent = async (data) => {
        const json = JSON.stringify({
            ...data,
            since: getLocalTime(data.since),
            till: getLocalTime(data.till),
            day: getLocalDate(dayDate),
        })
        const res = await fetch('/api/company/workplanner', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'X-CSRFToken': getCookie('csrftoken')
            },
            body: json,
        })
        const _data = await res.json()
        if (res.ok) {
            getDayEvents()
            onClose()
            haveToLoadAllEvents.current = true
            reset()
        } else{
            console.log(_data)
        }
    }

    return(
        <Dialog
            open={open}
            onClose={onClose}
            maxWidth='tablet'
        >
            <DialogTitle>
                Neues Event
            </DialogTitle>
            <DialogContent>
                <Stack
                    component='form'
                    id='new-event-form'
                    onSubmit={handleSubmit(handleNewEvent)}
                    spacing={2}
                    sx={{pt: 1}}
                >
                    <ControlledTextField
                        control={control}
                        name="title"
                        label="Name"
                        rules={{ required: true }}
                        errors={errors}
                    />
                    <ControlledSelect
                        control={control}
                        name="working_group"
                        label="Arbeitsgruppe"
                        rules={{ required: true }}
                        errors={errors}
                        items={buildWorkingGroupItems(workingGroups)}
                    />
                    <ControlledSelect
                        control={control}
                        name="sub_project_key"
                        label="Projektauftrag"
                        rules={{ required: true }}
                        errors={errors}
                        items={projectKeys}
                    />
                    <Stack
                        direction='row'
                        spacing={2}
                    >
                        <ControlledTimePicker
                            control={control}
                            rules={{ required: true }}
                            name='since'
                            label="Beginn"
                        />
                        <ControlledTimePicker
                            control={control}
                            rules={{ required: true }}
                            name='till'
                            label="Ende"
                            minTime={watch('since')}
                        />
                    </Stack>
                    <ControlledTextField
                        control={control}
                        name="repeat"
                        label="Wiederholungen"
                        type="number"
                        rules={{ required: true, min: 0}}
                        errors={errors}
                    />
                    <ControlledTextField
                        control={control}
                        name="description"
                        label="Beschreibung"
                        rules={{ required: true }}
                        errors={errors}
                        multiline
                        rows={5}
                    />
                </Stack>
            </DialogContent>
            <DialogActions>
                <Button
                    onClick={onClose}
                    color="secondary"
                >
                    Zurück
                </Button>
                <Button
                    type="submit"
                    form="new-event-form"
                    disabled={!isDirty || !isValid}
                >
                    Erstellen
                </Button>
            </DialogActions>
        </Dialog>
    )
}

export default AddEvent
