import { useContext } from 'react';
import {
    Box,
    Stack,
    Typography,
    Divider,
    Chip,
    Badge,
    Avatar
} from '@mui/material'

import AccountContext from '../../../../../context/AccountContext';
import { formatTimestamp } from '../../../../../utils/date_functions'

import CustomPaper from '../../../../Widgets/CustomPaper';
import SubscriberList from '../../../CompanyCalendar/Calendar/SubscriberList';


const ProjectEventCard = ({ event }) => {

    const [account] = useContext(AccountContext)
    const externalEvent = event.company_member.company !== account.company.id

    return (
        <CustomPaper
            title={`Termin: ${event.title}`}
        >
            <Stack gap={2} sx={{maxHeight: 800, overflow: 'auto'}}>
                <Stack>
                    {!event.allDay ?
                        <>
                            <Typography variant="subtitle2" sx={{whiteSpace: 'break-spaces'}}>{`Beginn: \t\t ${formatTimestamp(event.start)}`}</Typography>
                            <Typography variant="subtitle2" sx={{whiteSpace: 'break-spaces'}}>{`Ende: \t\t ${formatTimestamp(event.end)}`}</Typography>
                        </>
                    :
                        <Typography variant="subtitle2">{`Ganztägig`}</Typography>
                    }
                     <Typography variant="subtitle2" sx={{whiteSpace: 'break-spaces'}}>{`Ort: \t\t ${event?.place !== null ? event.place : "----"}`}</Typography>
                </Stack>
                <Stack
                    direction='row'
                    spacing={1}
                >
                    <Chip color="primary" variant="filled" size="small" label='Mit diesem Projekt verknüpft'/>
                    {externalEvent && <Chip color="secondary" variant="filled" size="small" label="Externer Termin"/>}
                </Stack>
                <Stack
                    spacing={1}
                >
                    <Typography variant="caption">{`Eingeladen von:`}</Typography>
                    <Stack
                        direction="row"
                        alignItems='center'
                        spacing={1}
                    >
                        <Badge
                            color="primary"
                            overlap="circular"
                            badgeContent=''
                            variant="dot"
                            anchorOrigin={{
                                vertical: 'bottom',
                                horizontal: 'right',
                            }}
                        >
                            <Avatar alt='' src={event?.company_member.avatar} />
                        </Badge>
                        <Stack>
                            <Typography variant="subtitle2">{`${event?.company_member.firstname} ${event?.company_member.lastname}`}</Typography>
                            <Typography variant="small_info_text">{`${event?.company_member.company_name}`}</Typography>
                        </Stack>
                    </Stack>
                </Stack>
                <Box>
                    <Divider textAlign="left" flexItem>
                        <Typography
                            variant="caption"
                            sx={{
                                fontWeight: 'bold',
                                color: 'background.light10',
                                letterSpacing: 1.5
                        }}>
                            Terminbeschreibung
                        </Typography>
                    </Divider>
                </Box>
                <Typography variant="body1">{event.description}</Typography>
                <SubscriberList
                    extern={externalEvent}
                    subscribers={event.company_subscribers}
                    account={account}
                />
                <SubscriberList
                    extern={!externalEvent}
                    subscribers={event.project_subscribers}
                    account={account}
                />

            </Stack>
        </CustomPaper>
    )
}

export default ProjectEventCard