import { List, ListItemButton, ListItemAvatar, Badge, Avatar, ListItemText, Stack } from "@mui/material"


const ExistingChats = ({ contacts, account, notifications, handleListItemClick, selectedContact }) => {
    return (
        <Stack sx={{ height: '100%' }} className="ExistingChats">
            <List sx={{ overflow: 'auto' }}>
                {contacts.map(company => company.members.map(contact => {
                    if (contact.id === account.id || !contact.chat) return null
                    const hasNewMessages = notifications.some(n => n.chat_id === contact.chat)
                    return (
                        <ListItemButton
                            key={contact.id}
                            selected={selectedContact?.chat === contact?.chat}
                            onClick={(event) => handleListItemClick(event, contact)}
                        >
                            <ListItemAvatar>
                                <Badge color="primary" variant="dot" invisible={!hasNewMessages}>
                                    <Avatar alt={contact.firstname + ' ' + contact.lastname} src={contact.avatar} />
                                </Badge>
                            </ListItemAvatar>
                            <ListItemText primary={contact.firstname + ' ' + contact.lastname} secondary={company.name} />
                        </ListItemButton>
                    )
                }))}
            </List>
        </Stack>
    )
}

export default ExistingChats