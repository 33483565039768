import  { Card, CardContent, CardActions, Chip, Divider, IconButton, Stack, Typography, Box, Avatar } from '@mui/material'
import { Delete, Edit, Numbers } from '@mui/icons-material'

import { formatDateString, formatTimestamp } from '../../../../utils/date_functions'
import { getTitlebyGender } from '../../../../utils/helper'

import LogoIcon from '../../../Widgets/Logos/LogoIcon'
import { getCookie } from '../../../../utils/getCookie'
import { PALETTE } from '../../../theme'
import ConfirmDialog from '../../../Widgets/ConfirmDialog'


const PossibleProjectCard = ({ pp, statusTypes, handleOpenEdit, getPossibleProjects }) =>{

    const handleDelete = async() => {
        const json = JSON.stringify({
            pp_id: pp.id
        })
        const res = await fetch('/api/company/possibleproject', {
            method: 'DELETE',
            headers: {
                'Content-Type': 'application/json',
                'X-CSRFToken': getCookie('csrftoken')
            },
            body: json,
        })
        if(res.ok){
            getPossibleProjects()
        }
    };

    return(
        <Card
            sx={{
                minWidth: 250,
            }}
        >
            <CardContent>
                <Box sx={{ position: 'relative', height: 250}}>
                    <Avatar
                        alt="company_member"
                        src= {pp.creator.avatar}
                        sx={{ position: 'absolute', top: 5, left: 5, border: '1px solid ' + PALETTE.primary.main, height: 45, width: 45}}

                    />
                    <Box
                        component="img"
                        src={pp.img}
                        alt="not found"
                        sx={{
                            p: 2,
                            height: '100%',
                            width: '100%',
                        }}
                    />
                </Box>
                <Typography
                    gutterBottom
                    variant="subtitle_m"
                    component="div"
                    sx={{margin: 0}}
                >
                    {pp.name}
                </Typography>
                <Typography variant="caption_m" color="primary.main">
                        Möglicher Projektstart: {formatDateString(pp.expected_to_start)}
                </Typography>
                <Stack sx={{  pb: 1}}>
                    <Typography
                        variant="text_s"
                    >
                            {`${pp.address.street} ${pp.address.house_number}${pp.address.add_on ? ', ' + pp.address.add_on : ''}`}
                    </Typography>
                    <Typography
                        variant="text_s"
                        gutterBottom
                    >
                            {`${pp.address.postal_code} ${pp.address.city} `}
                    </Typography>
                </Stack>
                <Chip
                    label={statusTypes[pp.status_key]}
                    // variant={variant}
                    // color={color}
                    sx={{mb:1}}
                />
                {pp.privat_customer_outside &&
                    <>
                        <Divider textAlign="left">
                            <Typography variant="subtitle_s">
                                Privatkunde
                            </Typography>
                        </Divider>
                        <Stack sx={{ pt: 0.5, pb: 0.5 }}>
                            <Typography variant="text_s">{`${getTitlebyGender(pp.privat_customer_outside.gender_key)} ${pp.privat_customer_outside.firstname} ${pp.privat_customer_outside.lastname} `}</Typography>
                            <Typography variant="text_s">{`Tel.: ${pp.privat_customer_outside.fixed_phone_number}`}</Typography>
                            <Typography variant="text_s">{`Mobil: ${pp.privat_customer_outside.mobil_phone_number} `}</Typography>
                            <Typography variant="text_s">{`${pp.privat_customer_outside.email}`}</Typography>
                            <Typography variant="text_s">{`${pp.privat_customer_outside.address.street} ${pp.privat_customer_outside.address.house_number}${pp.privat_customer_outside.address.add_on ? ', ' + pp.privat_customer_outside.address.add_on : ''}`}</Typography>
                            <Typography variant="text_s">{`${pp.privat_customer_outside.address.postal_code} ${pp.privat_customer_outside.address.city} `}</Typography>
                        </Stack>
                    </>
                }
                {pp.buisness_customer_outside &&
                    <>
                        <Divider textAlign="left">
                            <Typography variant="subtitle_s">
                                Gewerbekunde
                            </Typography>
                        </Divider>
                        <Stack sx={{ pt: 0.5, pb: 0.5 }}>
                            <Typography variant="text_s">{`${pp.buisness_customer_outside.name} `}</Typography>
                            <Typography variant="text_s">{`${pp.buisness_customer_outside.contact_person} `}</Typography>
                            <Typography variant="text_s">{`Tel.: ${pp.buisness_customer_outside.fixed_phone_number}`}</Typography>
                            <Typography variant="text_s">{`Mobil: ${pp.buisness_customer_outside.mobil_phone_number} `}</Typography>
                            <Typography variant="text_s">{`${pp.buisness_customer_outside.email}`}</Typography>
                            <Typography variant="text_s">{`${pp.buisness_customer_outside.address.street} ${pp.buisness_customer_outside.address.house_number}${pp.buisness_customer_outside.address.add_on ? ', ' + pp.buisness_customer_outside.address.add_on : ''}`}</Typography>
                            <Typography variant="text_s">{`${pp.buisness_customer_outside.address.postal_code} ${pp.buisness_customer_outside.address.city} `}</Typography>
                        </Stack>
                    </>
                }
                {pp.conmeet_client &&
                    <>
                        <Divider textAlign="left">
                            <Stack direction="row" justifyContent='center' spacing={1}>
                                <LogoIcon size={18}/>
                                <Typography variant="subtitle_s">
                                    Auftraggeber
                                </Typography>
                            </Stack>
                        </Divider>
                        <Stack sx={{ pt: 0.5, pb: 0.5  }}>
                            <Typography variant="text_s">{`${pp.conmeet_client.name}`}</Typography>
                            <Typography variant="text_s">{`Tel. ${pp.conmeet_client.central_phone_number}`}</Typography>
                            <Typography variant="text_s">{`${pp.conmeet_client.address.street} ${pp.conmeet_client.address.house_number}${pp.conmeet_client.address.add_on ? ', ' + pp.conmeet_client.address.add_on : ''}`}</Typography>
                            <Typography variant="text_s">{`${pp.conmeet_client.address.postal_code} ${pp.conmeet_client.address.city} `}</Typography>
                        </Stack>
                    </>
                }
                <Divider textAlign="left">
                    <Typography variant="subtitle_s">
                        Projektbeschreibung
                    </Typography>
                </Divider>
                <Typography gutterBottom
                    variant="text_m_justify"
                    sx={{display: 'block', height: 150, overflow: 'scroll', pr:1}}
                >
                    {pp.description}
                </Typography>
                <Typography  variant="text_s" component="div">
                    erstellt am: {formatTimestamp(pp.created)}
                </Typography>
                <Typography gutterBottom variant="text_s" component="div">
                    erstellt von: {pp.creator.firstname + ' ' + pp.creator.lastname}
                </Typography>
            </CardContent>
            <CardActions
                disableSpacing
            >
                <Stack
                    direction='row'
                    justifyContent='space-between'
                    width='100%'
                >
                    <Stack
                        direction='row'
                    >
                        <IconButton onClick={() => handleOpenEdit(pp)}>
                            <Edit/>
                        </IconButton>
                        <ConfirmDialog
                            title={'Potenzielles Projekt löschen?'}
                            message={'Bist du dir wirklich sicher, dass du das potenzielle Projekt löschen möchtest?'}
                            onConfirm={() =>  handleDelete()}
                            renderButton={(onClick) => (
                                <IconButton
                                    onClick={onClick}
                                    color="error"
                                >
                                    <Delete/>
                                </IconButton>
                            )}
                        />
                    </Stack>
                    <Chip label={<Typography variant="value">{pp.rank}</Typography>} variant='outlined' icon={<Numbers/>}/>
                </Stack>
            </CardActions>
        </Card>
    )
}

export default PossibleProjectCard
