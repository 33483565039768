import { Avatar, Badge, IconButton, ListItem, ListItemAvatar, Stack, Typography } from "@mui/material"
import { BsChatFill, BsX } from "react-icons/bs"

import { formatTimestamp } from "../../../../utils/date_functions"
import { getCookie } from "../../../../utils/getCookie"
import { PALETTE } from "../../../theme"


const Note = ({ note, project, getProjectNotes}) =>{

    const deleteProjectNote = async(note_id) =>{
        const json = JSON.stringify({
            note_id: note_id
        })
        const res = await fetch('/api/project/' + project.id + '/notes', {
            method: 'DELETE',
            headers: {
                'Content-Type': 'application/json',
                'X-CSRFToken': getCookie('csrftoken')
            },
            body: json
        })
        if(res.ok){
            getProjectNotes()
        }
    }

    return(
        <ListItem sx={{
            justifyContent: 'space-between',
        }}>
            <Stack
                direction='row'
                alignItems='flex-start'
            >
                <ListItemAvatar
                    sx={{ pt: 0.5 }}
                >
                    <Avatar
                        alt=''
                        src={note.creator.avatar}
                    />
                </ListItemAvatar>
                <Stack>
                    <Typography gutterBottom variant='text_m' sx={{ mr: 4, mb: 2, whiteSpace: 'pre-wrap', wordBreak: 'break-all' }}>
                        {note.text}
                    </Typography>
                    {note.private ?
                        <Typography variant='caption_m' sx={{ color: 'primary.main' }}>
                            {`Privat`}
                        </Typography>
                    :
                        <Typography variant='caption_m' sx={{ color: 'secondary.main' }}>
                            {`Intern`}
                        </Typography>
                    }
                    <Typography variant='body2' sx={{ color: 'text.primary' }}>
                        {note.creator.firstname + ' ' + note.creator.lastname}
                        {' - '}
                        {formatTimestamp(note.created)}
                    </Typography>
                </Stack>
            </Stack>
            <IconButton
                edge="end"
                aria-label={"delete-" + note.id}
                onClick={() => deleteProjectNote(note.id)}
                size="small"
            >
                <BsX size={27}/>
            </IconButton>
        </ListItem>
    )
}

export default Note