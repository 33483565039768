import { Controller } from 'react-hook-form'

import { Slider, Stack, Typography } from '@mui/material'


const ControlledSlider = ({ name, control, rules, label,step=1, min=0, max=100, marks, withLabel=true}) =>{

    return(

            <Controller
                name={name}
                control={control}
                rules={rules}
                render={({ field : {onChange , value } }) => (
                    <Stack
                        direction="row"
                        alignItems='center'
                        width={'100%'}
                    >
                        <Slider
                            value={value}
                            onChange={onChange}
                            valueLabelDisplay="auto"
                            color="primary"
                            step={step}
                            min={min}
                            max={max}
                            marks={marks}
                        />
                        {withLabel && <Typography variant="overline" sx={{ width: 150, textAlign: 'right'}} >{`${value} ${label}`}</Typography>}
                    </Stack>
                )}
            />
    )
}

export default ControlledSlider