import { useState } from "react";
import { useForm } from "react-hook-form";

import { Stack, Typography } from "@mui/material";

import { getCookie } from '../../../../utils/getCookie';

import useStyledSnackbar from '../../../../hooks/useStyledSnackbar'
import CustomFormPaper from '../../../Widgets/CustomFormPaper';
import ControlledSelect from '../../../Widgets/ControlledInputs/ControlledSelect';


const ProjectChangeLead = ({ project, projectTeamMembers, setRedirect }) =>{

    const { enqueueSnackbar } =  useStyledSnackbar()

    const [loading, setLoading] = useState(false)
    const [success, setSuccess] = useState(false)

    const { control, handleSubmit, formState: { errors, isDirty, isValid  }} = useForm({
        mode: 'onChange',
        defaultValues:{
            selected_company_id: '',
        }
    });

    const getCompaniesSelectItems = () =>{
        let companies = {}
        projectTeamMembers.forEach((company)=> {
            if(project.company.id !== company.id) {
                companies[company.id] = company.name
            }
        })
        return companies
    }

    const changeProjectLead = async(data) =>{
        setLoading(true)
        const json = JSON.stringify(data)
        const res = await fetch('/api/project/' + project.id + '/lead', {
            method: 'PATCH',
            headers: {
                'Content-Type': 'application/json',
                'X-CSRFToken': getCookie('csrftoken')
            },
            body: json
        })
        const _data = await res.json()
        if(res.ok){
            setLoading(false)
            setSuccess(true)
            setRedirect(true)
            enqueueSnackbar('Das Projektwurde erfolgreich abgegeben!', {
                variant: 'success'
            })
        } else {
            if(res.status == 400){
                enqueueSnackbar(_data.detail, {
                    variant: 'error'
                })
            }
            setLoading(false)
        }
    }

    return(
        <CustomFormPaper
            title="Projektleitung übergeben"
            onSubmit={handleSubmit(changeProjectLead)}
            success={success}
            loading={loading}
            disabled={!isDirty || !isValid}
        >
            <Stack spacing={2}>
                <Typography variant="text_m">
                    An welches Unternehmen möchtest du die Projektleitung übergeben?
                </Typography>
                <ControlledSelect
                    name="selected_company_id"
                    label="Unternehmen auswählen"
                    control={control}
                    errors={errors}
                    items={getCompaniesSelectItems()}
                />
            </Stack>
        </CustomFormPaper>
    )
}

export default ProjectChangeLead