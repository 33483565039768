import { Stack, Typography, Fade } from "@mui/material"
import { PALETTE } from "components/theme"
import { AiFillWarning } from "react-icons/ai"

const WarningHint = ({ text, size=45, redAlert=false, height=180 }) => {
    return (
        <Fade in={true} timeout={500} style={{ transitionDelay: '250ms' }}>
            <Stack gap={0.5} sx={{ p: 2, height: height, justifyContent: 'center' }} alignItems='center'>
                    <AiFillWarning color={redAlert ? PALETTE.error.main : PALETTE.warning.main } size={size}/>
                    <Typography variant="text_m">{ text }</Typography>
            </Stack>
        </Fade>
    )
}

export default WarningHint
