import { getCookie } from '../../../../../../utils/getCookie'

import { Stack, Typography, IconButton } from "@mui/material"
import { Clear  }from '@mui/icons-material';

import { PALETTE } from '../../../../../theme';
import CustomPaper from '../../../../../Widgets/CustomPaper';


const ActiveInvites = ({ project, activeInvites, getActiveInvites }) =>{

    const deleteActiveInvite = async(pc_allocator_id) => {
        const json = JSON.stringify({
            pc_allocator_id: pc_allocator_id
        })
        const res = await fetch('/api/project/' + project.id + '/companyinvite', {
            method: 'DELETE',
            headers: {
                'Content-Type': 'application/json',
                'X-CSRFToken': getCookie('csrftoken')
            },
            body: json
        })
        // const data = await res.json()
        if(res.ok){
            getActiveInvites()
            // console.log(data)
        }
    }

    return(
        <CustomPaper
            title="Aktive Einladungen"
        >
            <Stack
                spacing={1}
            >
                <Typography variant="text_m">Hier finden Sie alle Ihrer aktive Einladungen</Typography>
                <Stack
                    spacing={2}
                >
                    {activeInvites.length !== 0 ?
                        activeInvites.map((invite, idx)=>{
                            return (
                                <Stack
                                    key={"active-invites-" + idx}
                                    direction="row"
                                    justifyContent="space-between"
                                    alignItems="flex-start"
                                    sx={{
                                        p:2,
                                        border: 1,
                                        borderRadius: '10px',
                                        borderColor: PALETTE.background.light70
                                    }}
                                >
                                    <Stack>
                                        <Typography
                                            variant="subtitle_m"
                                        >
                                            {invite.company.name}
                                        </Typography>
                                        <Typography
                                            variant="text_m"
                                            color="primary"
                                        >
                                            {invite.company.industry}
                                        </Typography>
                                    </Stack>
                                    <IconButton
                                        onClick={()=>{deleteActiveInvite(invite.id)}}
                                        color="background"
                                        size="small"
                                    >
                                        <Clear/>
                                    </IconButton>
                                </Stack>
                            );
                        }) :
                        <Typography variant="caption_m" color="primary"> Derzeit gibt es keine aktiven Einladungen...</Typography>
                    }
                </Stack>
            </Stack>
        </CustomPaper>
    )
}

export default ActiveInvites