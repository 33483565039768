import { useContext, useEffect, useState } from 'react'

import CompanyContext from 'context/CompanyContext'
import useStyledSnackbar from 'hooks/useStyledSnackbar'

import CustomFormPaper from 'components//Widgets/CustomFormPaper'
import ImageUplaoder from 'components/Widgets/ImageUplaoder'

import { getCookie } from 'utils/getCookie'
import { Stack, Typography } from '@mui/material'


const CompanyLogoUpload = () => {
    const [company, , getCompany] = useContext(CompanyContext)

    const [loading, setLoading] = useState(false)
    const [success, setSuccess] = useState(false)
    const [selectedFile, setSelectedFile] = useState(null)

    const { enqueueSnackbar } = useStyledSnackbar()

    const uploadFile = async e => {
        setLoading(true)
        e.preventDefault()
        const formData = new FormData()
        formData.append('file', selectedFile)
        const res = await fetch('/api/company/logo', {
            method: 'PUT',
            body: formData,
            headers: {
                'X-CSRFToken': getCookie('csrftoken')
            }
        })
        if (res.ok) {
            getCompany()
            setLoading(false)
            setSuccess(true)
            enqueueSnackbar('Ihr Logo wurde erfolgreich aktualisiert!', {
                variant: 'success'
            })
        }
    }

    useEffect(()=>{
        setSuccess(false)
    },[selectedFile])

    return (
        <CustomFormPaper
            title="Logo ändern"
            onSubmit={uploadFile}
            tooltip={'Speichern'}
            success={success}
            loading={loading}
            disabled={selectedFile === null}
        >
            <Stack
                gap={3}
                alignItems="center"
                sx={{ 
                    flex: 1,
                    width: 280
                 }}
            >
                <Typography variant='text_m_justify'>Damit dein Logo auf Dokumenten wie Angeboten oder Rechnungen professionell und klar aussieht, empfehlen wir die Verwendung einer PNG-Datei ohne Hintergrund.</Typography>
                <ImageUplaoder
                    selectedFile={selectedFile}
                    setSelectedFile={setSelectedFile}
                    initialImg={company.logo}
                    width={280}                
                />
            </Stack>
        </CustomFormPaper>
    )
}

export default CompanyLogoUpload
