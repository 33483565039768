import { useState } from 'react'
import { useForm } from "react-hook-form";

import { Stack, Button, Dialog, DialogTitle,  DialogContent, DialogContentText, DialogActions, Box, Typography, IconButton } from "@mui/material"
import { Refresh } from '@mui/icons-material';
import { getCookie } from '../../../../../utils/getCookie';
import { getLocalDate } from '../../../../../utils/date_functions';
import { checkSelectionZero } from '../../../../../utils/helper';

import ControlledTextField from '../../../../Widgets/ControlledInputs/ControlledTextField';
import ControlledDatePicker from '../../../../Widgets/ControlledInputs/ControlledDatePicker';
import ControlledSelect from '../../../../Widgets/ControlledInputs/ControlledSelect';
import ControlledSlider from '../../../../Widgets/ControlledInputs/ControlledSlider';

import CompanySearch from "../../../../Widgets/CompanySearch"
import LogoIcon from '../../../../Widgets/Logos/LogoIcon'
import { PALETTE } from '../../../../theme';



const PossibleProjectAddDialog = (props) =>{

    const { open, onClose, customers, buisnessCustomers, projectTypes, statusTypes, getPossibleProjects } = props

    const [conmeetClient, setConmeetClient] = useState({});

    const { control, handleSubmit, reset, formState: { errors, isValid, isDirty }} = useForm({
        mode: 'onChange',
        defaultValues:{
            name: '',
            description: '',
            rank: 1,
            expected_to_start: null,
            type_key: '',
            status_key: '',
            conmeet_client: '',
            privat_customer_outside: '',
            buisness_customer_outside: '',
            done: false,
            street: '',
            house_number: '',
            add_on: '',
            postal_code: '',
            city: '',
        }
    });

    const handleOnClose = () =>{
        onClose()
        reset()
    }

    const handleSelectedCompany = (data) =>{
        setConmeetClient(data)
    }

    const onSubmit = async(data) => {
        const json = JSON.stringify({
            ...data,
            expected_to_start: getLocalDate(data.expected_to_start),
            privat_customer_outside: checkSelectionZero(data.privat_customer_outside),
            buisness_customer_outside: checkSelectionZero(data.buisness_customer_outside),
            conmeet_client:  conmeetClient?.id,
            address: {
                street: data.street,
                house_number: data.house_number,
                add_on: data.add_on,
                postal_code: data.postal_code,
                city: data.city
            }
        })
        console.log(json)
        const res = await fetch('/api/company/possibleproject', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'X-CSRFToken': getCookie('csrftoken')
            },
            body: json
        })
        const _data = await res.json()
        if (res.ok) {
            getPossibleProjects()
            handleOnClose()
        } else{
            console.log(_data)
        }
    }

    return(
        <Dialog
            maxWidth="desktop"
            open={open}
            onSubmit={handleSubmit(onSubmit)}
            onClose={handleOnClose}
            fullWidth
        >
            <form>
                <DialogTitle>Potenzieller Projektauftrag erstellen</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        Erstelle ein potenziellen Projektauftrag um den Überblick deiner Projektanfragen zu behalten.
                    </DialogContentText>
                    <Stack
                        direction='row'
                        spacing={2}
                        sx={{mt:2}}
                    >
                        <Stack
                            spacing={2}
                            sx={{flex: 1}}
                        >
                            <ControlledTextField
                                name="name"
                                control={control}
                                rules={{ required: true }}
                                label="Projektauftragesname"
                                errors={errors}
                            />
                            <ControlledTextField
                                name="description"
                                control={control}
                                rules={{ required: true }}
                                label="Projektauftragsbeschreibung"
                                errors={errors}
                                multiline
                                rows={8}
                            />
                            <ControlledSelect
                                name="type_key"
                                control={control}
                                label="Projektart"
                                rules={{ required: true }}
                                errors={errors}
                                items={projectTypes}
                            />
                            <ControlledDatePicker
                                name="expected_to_start"
                                control={control}
                                rules={{ required: true}}
                                label="Projektauftragesstart"
                            />
                            <Stack
                                direction="row"
                                spacing={2}
                            >
                                <ControlledTextField
                                    name="street"
                                    control={control}
                                    label="Straße"
                                    rules={{ required: true }}
                                    errors={errors}
                                    sx={{
                                        flex: 3
                                    }}
                                />
                                <ControlledTextField
                                    name="house_number"
                                    control={control}
                                    label="Hausnummer"
                                    rules={{ required: true }}
                                    errors={errors}
                                    sx={{
                                        flex: 2
                                    }}
                                />
                            </Stack>
                            <ControlledTextField
                                name="add_on"
                                control={control}
                                label="Adresszusatz"
                                rules={{
                                    required: false,
                                }}
                                errors={errors}
                            />
                            <Stack
                                direction="row"
                                spacing={2}
                            >
                                <ControlledTextField
                                    name="postal_code"
                                    control={control}
                                    label="Postleitzahl"
                                    rules={{
                                        required: true,
                                        minLength: { value: 5, message: "Es gibt keine Postleitzahl mit weniger als 5 Ziffern!"},
                                        maxLength: { value: 5, message: "Es gibt keine Postleitzahl mit mehr als 5 Ziffern!"}
                                    }}
                                    errors={errors}
                                    sx={{
                                        flex: 2
                                    }}
                                />
                                <ControlledTextField
                                    name="city"
                                    control={control}
                                    label="Stadt"
                                    rules={{ required: true }}
                                    errors={errors}
                                    sx={{
                                        flex: 3
                                    }}
                                />
                            </Stack>
                        </Stack>
                        <Stack
                            spacing={2}
                            sx={{flex: 1}}
                        >
                            <ControlledSelect
                                name="privat_customer_outside"
                                control={control}
                                label="Privatkunde (optional)"
                                rules={{ required: false }}
                                errors={errors}
                                items={customers}
                            />
                            <ControlledSelect
                                name="buisness_customer_outside"
                                control={control}
                                label="Gewerbekunde (optional)"
                                rules={{ required: false }}
                                errors={errors}
                                items={buisnessCustomers}
                            />
                            <Box
                                sx={{
                                    width: "100%",
                                    overflow: 'auto',
                                    p:2,
                                    border: 1,
                                    borderRadius: '10px',
                                    borderColor: PALETTE.background.light70
                                }}
                            >
                                <Stack spacing={2}>
                                    <Stack direction='row' alignItems='center' spacing={1}>
                                        <LogoIcon size={30}/>
                                        <Typography variant="subtitle_s">{`Such nach einem Auftraggeber in CONMEET (optional)`}</Typography>
                                    </Stack>
                                    <Stack direction='row' justifyContent="space-between" alignItems="center" spacing={1}>
                                        <Typography color={conmeetClient.name ? "primary" : "default"}>{`Ausgewählter Auftraggeber: ${conmeetClient.name ? conmeetClient.name : 'Nicht Vorhanden'}`}</Typography>
                                        <IconButton
                                            color="primary"
                                            onClick={()=> setConmeetClient({})}
                                        >
                                            <Refresh/>
                                        </IconButton>
                                    </Stack>

                                    <CompanySearch
                                        handleSelection={handleSelectedCompany}
                                        sx={{height: 250}}
                                    />
                                </Stack>
                            </Box>
                            <ControlledSelect
                                name="status_key"
                                control={control}
                                label="Aktueller Status"
                                rules={{ required: true }}
                                errors={errors}
                                items={statusTypes}
                            />
                            <Box sx={{ pl: 2 }}>
                                <ControlledSlider
                                    name="rank"
                                    control={control}
                                    rules={{ required:  true }}
                                    label="Priorität"
                                    step={1}
                                    min={1}
                                    max={100}
                                    // marks={MARKS}
                                />
                            </Box>
                        </Stack>
                    </Stack>
                    <DialogActions>
                        <Button
                            color="secondary"
                            onClick={onClose}
                        >
                            Abbrechen
                        </Button>
                        <Button
                            type="submit"
                            color="primary"
                            disabled={!isValid || !isDirty}
                        >
                            Erstellen
                        </Button>
                    </DialogActions>
                </DialogContent>
            </form>
        </Dialog>
    )
}

export default PossibleProjectAddDialog;
