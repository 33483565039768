import { useState, useContext } from "react"

import { Stack, Typography } from '@mui/material';

import CompanySearch from "../../Widgets/CompanySearch"
import NewChatsComponent from "../../Widgets/Chat/NewChats";

import AccountContext from "../../../context/AccountContext";

const NewChats = ({contacts, handleListItemClick, selectedContact }) => {
    const [account] = useContext(AccountContext)

    const [companyContacts, setCompanyContacts] = useState(null)
    const handleSelection = async company => {
        const res = await fetch('/api/conmeet-connections/chat/company/' + company.id)
        let json = await res.json()
        // get existing chats from company
        const existingChats = contacts.filter(c => c.name === company.name)
        // remove existing chats
        if (existingChats.length > 0) {
            json[0].members = json[0].members.filter(m => {
                return !existingChats[0].members.some(m2 => m2.id === m.id)
            })
        }
        setCompanyContacts(json)
        // console.log(json)
    }

    return (
        <Stack sx={{ height: '100%' }} className="NewChats">
            {companyContacts ?
                <NewChatsComponent
                    contacts={companyContacts}
                    account={account}
                    handleListItemClick={handleListItemClick}
                    selectedContact={selectedContact}
                />
                :
                <>
                    <Typography variant='h6' sx={{ textAlign: 'center', padding: '5px' }}>Neuen Chat starten</Typography>
                    <CompanySearch sx={{ p: 2, overflow: 'hidden' }} handleSelection={handleSelection} autoFocus/>
                </>
            }
        </Stack>
    )
}

export default NewChats
