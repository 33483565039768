import { BarChart, Bar, XAxis, YAxis, ReferenceLine } from 'recharts';

import { Stack, CircularProgress, Typography, Box } from '@mui/material';
import { BsCircleFill } from "react-icons/bs";

import  useWidth  from '../../../../../hooks/useWidth'

import { PALETTE } from "../../../../theme/index.jsx";
import no_data_img from '../../../../../assets/svgs/no_data.svg'
import CustomPaper from "../../../../Widgets/CustomPaper/index.jsx";
import { formatNumber } from 'utils/helper';



const StaticDoubleStackBarchart = ({ title, max, data, height, maxFactor, fill1, fill2, unit, barSize}) =>{

    const [ width, ref ] = useWidth(0)

    const getRadius = (next_value) =>{
        if(next_value <= 0 || next_value === undefined){
            return true
        }
        return false
    }

    const currentDriveRateCosts = data[0].currentDriveRateCosts
    const currentWorkloadCosts = data[0].currentWorkloadCosts

    return(
        <CustomPaper
            title={title}
            padding={0}
            actionBar={
                <Stack
                    direction={'row'}
                    spacing={2}
                >
                    <Stack
                        direction='row'
                        justifyContent="center"
                        alignItems="center"
                        spacing={1}
                    >
                        <BsCircleFill size={10} color={fill1}/>
                        <Typography variant="text_m">Lohnkosten</Typography>
                        <Typography
                            variant="value"
                            sx={{
                                color: fill1,
                                fontSize: 14,
                            }}
                        >
                                {formatNumber(currentWorkloadCosts, 2, unit)}
                        </Typography>
                    </Stack>
                    <Stack
                        direction='row'
                        justifyContent="center"
                        alignItems="center"
                        spacing={1}
                    >
                        <BsCircleFill size={10} color={fill2}/>
                        <Typography variant="text_m">Fahrtkosten</Typography>
                        <Typography
                            variant="value"
                            sx={{
                                color: fill2,
                                fontSize: 14,
                            }}
                        >
                                {formatNumber(currentDriveRateCosts, 2, unit)}
                        </Typography>
                    </Stack>
                </Stack>
            }
        >
            <Stack
                justifyContent="center"
                alignItems="center"
                width="100%"
                height={height}
                ref={ref}
            >
                {currentDriveRateCosts + currentWorkloadCosts > 0  ?
                    width !== 0 ?
                        <BarChart
                            width={width}
                            height={height}
                            data={data}
                            layout='vertical'
                            margin={{
                                top: 0,
                                right: 60,
                                left: 0,
                                bottom: 15,
                            }}
                        >
                            <XAxis
                                type="number"
                                unit={unit}
                                tickLine={false}
                                tickCount={5}
                                interval="preserveEnd"
                                domain={[0, dataMax => dataMax < max ? (Math.round(max * maxFactor)) : (Math.round(dataMax * maxFactor))]}
                            />
                            <YAxis
                                type="category"
                                dataKey="name"
                                hide={true}
                                tickLine={false}
                            />
                            <Bar
                                dataKey="currentWorkloadCosts"
                                stackId="a"
                                fill={fill1}
                                barSize={barSize}
                                animationDuration={1000}
                                radius={getRadius(data[0].currentDriveRateCosts) ? [0, 10, 10, 0] : [0, 0, 0, 0]}
                            />
                            <Bar
                                dataKey="currentDriveRateCosts"
                                stackId="a"
                                fill={fill2}
                                barSize={barSize}
                                animationDuration={1000}
                                radius={[0, 10, 10, 0]}
                            />
                            <ReferenceLine
                                x={max}
                                label={{position: 'insideTopRight',  value: `Kalk. ${title} ${formatNumber(max, 2, unit)}`, fill: PALETTE.background.light10, fontSize: 15 }}
                                strokeWidth={1.5}
                                stroke={PALETTE.secondary.light}
                            />
                        </BarChart>
                    :
                        <CircularProgress/>
                :
                    <>
                        <Box
                            component="img"
                            src={no_data_img}
                            alt="not found"
                            sx={{ height: '70%', p:1}}
                        />
                        <Typography variant="text_" >Noch keine Daten vorhanden</Typography>
                    </>
                }
            </Stack>
        </CustomPaper>
    )
}

export default StaticDoubleStackBarchart