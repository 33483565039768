import { useForm } from "react-hook-form";
import { Dialog, DialogContent, DialogTitle, DialogActions, DialogContentText, Button, Stack } from "@mui/material"

import { getCookie } from "../../../../../utils/getCookie";

import ControlledTextField from "../../../../Widgets/ControlledInputs/ControlledTextField";


const AddAgreementsItemDialog = ({ open, onClose, project_id, agreement_carrier_id , getProjectAgreements }) =>{

    const { control, handleSubmit, reset, formState: { errors, isDirty, isValid }} = useForm({
        mode: 'onChange',
        defaultValues:{
            subject:'',
            description:'',
        }
    });

    const handleClose = () =>{
        onClose()
        reset()
    }

    const onSubmit = async(data) => {
        const json = JSON.stringify({
            ...data,
            agreement_carrier_id: agreement_carrier_id,
        })
        const res = await fetch('/api/project/' + project_id + '/agreements/agreement', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'X-CSRFToken': getCookie('csrftoken')
            },
            body: json
        })
        const _data = await res.json()
        if (res.ok) {
            getProjectAgreements()
            handleClose()
        } else{
            console.log(_data)
        }
    }

    return(
        <Dialog
            maxWidth="tablet"
            open={open}
            onSubmit={handleSubmit(onSubmit)}
            onClose={handleClose}
        >
            <form>
                <DialogTitle>
                    Neuer Vereinbarungspunkt
                </DialogTitle>
                <DialogContent>
                    <Stack spacing={2}>
                        <DialogContentText>
                            {`Trage hier ein Unterpunkt für die Vereinbarung ein.`}
                        </DialogContentText>
                        <ControlledTextField
                            control={control}
                            name="subject"
                            rules={{ required : true }}
                            label="Thema"
                            errors={errors}
                        />
                        <ControlledTextField
                            control={control}
                            name="description"
                            rules={{ required : true }}
                            label="Detailbeschreibung"
                            errors={errors}
                            multiline
                            rows={5}
                        />
                    </Stack>
                </DialogContent>
                <DialogActions>
                    <Button
                        color="secondary"
                        onClick={handleClose}
                    >
                        Abbrechen
                    </Button>
                    <Button
                        type="submit"
                        color="primary"
                        disabled={!isValid || !isDirty}
                    >
                        Erstellen
                    </Button>
                </DialogActions>
            </form>
        </Dialog>
    )
}

export default AddAgreementsItemDialog