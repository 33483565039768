import { useState, useEffect } from 'react';
import { useForm } from 'react-hook-form';

import {
    Box,
    Stack,
    Button,
    Dialog,
    DialogTitle,
    DialogContent,
    Typography,
    ToggleButtonGroup,
    ToggleButton,
    IconButton,
    Divider,
    TextField,
    InputAdornment,
} from '@mui/material';

import useStyledSnackbar from '../../../../hooks/useStyledSnackbar';
import { checkSelectionZero, formatNumber } from '../../../../utils/helper';
import { getCookie } from '../../../../utils/getCookie';

import ControlledTextField from '../../../Widgets/ControlledInputs/ControlledTextField';
import ControlledSelect from '../../../Widgets/ControlledInputs/ControlledSelect';
import { Clear } from '@mui/icons-material';
import { PALETTE } from '../../../theme';
import LogoIcon from '../../../Widgets/Logos/LogoIcon';
import WarehouseMailOrderDialog from './WarehouseMailOrderDialog';

const handleVerb = (type_key) =>{
    switch(type_key){
        case 1:
            return 'buchen'
        case 2:
            return 'abbuchen'
        case 3:
            return 'bestellen'
        case 6:
            return 'reservieren'
        default:
            return ''
    }
}

const WarehouseTransactionDialog = (props) =>{

    let sum = 0
    let globalError = false
    const { open, onClose,  materials, getStockMaterial, getStockValues } = props
    const { enqueueSnackbar } =  useStyledSnackbar()
    const [projectKeys, setProjectKeys] = useState({})
    const [suppliers, setSuppliers] = useState({})
    const [materials_, setMaterials_] = useState([]);
    const [isLoading, setIsLoading] = useState(false)

    const [openDialog, setOpenDialog] = useState(false)
    const [res, setRes] = useState(null)

    const { handleSubmit, control, reset, formState: { errors, isDirty, isValid }} = useForm({
        mode: 'onChange',
        defaultValues: {
            supplier: '',
            place_of_delivery: '',
            annotation: '',
            sub_project_id: '',
            supplier_id: '',
        }
    })

    const [txTypekey, setTXTypeKey] = useState(1);
    const [verb, setVerb] = useState('buchen');
    const handleTXTypeKey = (event, value) => {
        if (value !== null) {
            setTXTypeKey(value);
            setVerb(handleVerb(value))
        }
    };

    const handleValueChange = (e, idx, key) => {
        let data_ = [...materials]
        data_[idx][key] = e.target.value
        setMaterials_(data_);
    };

    const getSuppliers = async () => {
        const res = await fetch('/api/company/supplier', {
            method: 'GET',
        })
        const _data = await res.json()
        if (res.ok) {
            const transformed = {0: 'kein Lieferant'}
            for (const supplier of _data) {
                transformed[supplier.id] = supplier.name + ' / ' + supplier.contact_person
            }
            setSuppliers(transformed)
        }
    }

    const getProjectKeys = async() => {
        const res = await fetch('/api/projects/get-sub-selection', {
            method: 'GET'
        })
        const data = await res.json()
        if(res.ok){
            setProjectKeys(data)
        }
    }

    const handleOnClose = () =>{
        reset()
        onClose()
    }

    const handleWarehouserTx = async(data) =>{
        setIsLoading(true)
        let is_valid = true
        materials_.forEach(m =>{
            if(m.tx_value === '' || m.tx_value === 0 ){
                is_valid = false
            }
        })

        if(!is_valid){
            enqueueSnackbar(`Mindestens ein Material hat keine Menge angeben!`, {
                variant: 'error'
            })
            setIsLoading(false)
            return
        }

        const json = JSON.stringify({
            ...data,
            company_stock_materials: materials_,
            sub_project_id : checkSelectionZero(data.sub_project_id),
            supplier_id: checkSelectionZero(data.supplier_id),
            type_key: parseInt(txTypekey)
        })
		const res = await fetch('/api/company/warehouse/tx', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'X-CSRFToken': getCookie('csrftoken')
            },
            body: json
        })
        const _data = await res.json()
        if(res.ok){
            if(txTypekey === 3){
                setRes(_data)
                setOpenDialog(true)
            } else {
                handleOnClose()
            }
            getStockValues()
            getStockMaterial()
            enqueueSnackbar(`Die Lagertransaktion wurde erfolgreich durchgefürht!`, {
                variant: 'success'
            })
        } else {
            Object.entries(_data).forEach(([field, message]) => {
                enqueueSnackbar(`${field}: ${message} `, {
                    variant: 'error'
                })
            })
        }
        setIsLoading(false)
    }

    useEffect(()=>{
        getProjectKeys()
        getSuppliers()
    },[])

    useEffect(()=>{
        let m_ = []
        materials.forEach(m => {
            m.tx_value =  ''
            m.tx_price =  ''
            m_.push(m)
        });
        setMaterials_(m_)
    },[materials])

    useEffect(()=>{
        if(openDialog === false){
            handleOnClose()
        }
    },[openDialog])

    return(
        <Dialog
            open={open}
            onClose={handleOnClose}
            fullScreen
            fullWidth
        >
            <Stack
                direction='row'
                justifyContent='space-between'
                alignItems="center"
                sx={{ p: 2 }}
            >
                <Stack
                    direction='row'
                    justifyContent='center'
                    alignItems="center"
                >
                    <LogoIcon size='40px'/>
                    <DialogTitle variant="h5">
                        {`Lagermaterial ${verb}`}
                    </DialogTitle>
                </Stack>
                <Stack
                    direction='row'
                    alignItems='center'
                    spacing={2}
                >
                    <IconButton
                        color="secondary"
                        onClick={onClose}
                        size="large"
                    >
                        <Clear fontSize="large"/>
                    </IconButton>
                </Stack>
            </Stack>
            <Divider flexItem/>
            <DialogContent>
                <Stack
                    flexDirection="row"
                    gap={3}
                    component="form"
                    id='tx-form'
                    onSubmit={handleSubmit(handleWarehouserTx)}
                    sx={{ height: '100%' }}
                >
                    <Stack
                        width="100%"
                        sx={{
                            border: 1,
                            borderRadius: '7px',
                            borderColor: PALETTE.background.light70,
                            height: '100%',
                            flex: 1,
                            p: 1,
                            pl: 2,
                            overflow: 'auto'
                        }}
                    >
                        {materials_.map((material, idx)=>{
                            const suffix = material.unit?.split('/')[1]
                            const tx_value = parseFloat(material.tx_value !== '' ? material.tx_value : 0)
                            const tx_price = parseFloat(material.tx_price !== ''  ? material.tx_price : material.price)
                            sum += tx_value * tx_price
                            const aviable_balance = material.balance + material.reserved
                            const error = material.tx_value > aviable_balance && (txTypekey === 6 || txTypekey === 2)
                            if(error){ globalError = true}
                            return(
                                <Box
                                    key={idx}
                                    sx={{pb: 1}}
                                >
                                    <Stack
                                        direction={'row'}
                                        justifyContent="space-between"
                                        alignItems='center'
                                        spacing={5}
                                    >
                                        <Stack
                                            sx={{flex: 1}}
                                            gap={0.2}
                                        >
                                            <Typography
                                                    variant="subtitle_s"
                                                >
                                                    {material.description}
                                            </Typography>
                                            <Typography
                                                variant="caption_m"
                                            >
                                                {`Art.-Nr. ${material.unique_material_id} | ${material.manufacturer !== null ?  material.manufacturer : '--'}`}
                                            </Typography>
                                            <Typography
                                                color="primary.main"
                                                variant="text_m"
                                            >
                                                {`Standardpreis: ${formatNumber(material.price, 2, material.unit)} | Verfügbare Menge ${formatNumber(aviable_balance, 2, suffix)}`}
                                            </Typography>
                                        </Stack>
                                        <Stack
                                            direction={'row'}
                                            justifyContent='center'
                                            alignItems="center"
                                            spacing={2}
                                        >
                                            <TextField
                                                placeholder="0"
                                                type="number"
                                                label="Menge"
                                                size="small"
                                                InputProps={{
                                                    endAdornment: <InputAdornment position="end">{suffix}</InputAdornment>,
                                                }}
                                                sx={{ width: 160}}
                                                value={material.tx_value}
                                                onChange={(e) => handleValueChange(e, idx, 'tx_value')}
                                                error={error}
                                                helperText={error ? 'Nicht verfügbar' : '' }
                                            />
                                            <TextField
                                                placeholder="0"
                                                label="Einzelpreis"
                                                type="number"
                                                size="small"
                                                InputProps={{
                                                    endAdornment: <InputAdornment position="end">€</InputAdornment>,
                                                }}
                                                sx={{ width: 160}}
                                                value={material.tx_price}
                                                onChange={(e)=> handleValueChange(e, idx, 'tx_price')}
                                                disabled={txTypekey === 6 || txTypekey === 2}
                                            />
                                        </Stack>
                                    </Stack>
                                </Box>
                            )
                        })}
                    </Stack>
                    <Stack
                        gap={2}
                        sx={{flex: 1}}
                    >
                        <ToggleButtonGroup
                            color="primary"
                            value={txTypekey}
                            exclusive
                            onChange={handleTXTypeKey}
                            size="small"
                            fullWidth
                        >
                            <ToggleButton value={1}>
                                Buchen
                            </ToggleButton>
                            <ToggleButton value={2}>
                                Abbuchen
                            </ToggleButton>
                            <ToggleButton value={3}>
                                Bestellen
                            </ToggleButton>
                            <ToggleButton value={6}>
                                Reservieren
                            </ToggleButton>
                        </ToggleButtonGroup>
                        <ControlledSelect
                            control={control}
                            name="sub_project_id"
                            label={`Projektauftrag (optional)`}
                            rules={{ required: false  }}
                            errors={errors}
                            items={projectKeys}
                        />
                        <Stack
                            direction='row'
                            spacing={2}
                        >
                            <ControlledSelect
                                control={control}
                                errors={errors}
                                rules={{required: txTypekey === 3 ? true: false }}
                                name="supplier_id"
                                label={`Lieferant ${txTypekey === 3 ? ' ': '(optional)'}`}
                                items={suppliers}
                            />
                            <ControlledTextField
                                control={control}
                                errors={errors}
                                name="place_of_delivery"
                                label="Lieferort (optional)"
                                rules={{required: false}}
                            />
                        </Stack>
                        <ControlledTextField
                            control={control}
                            errors={errors}
                            name="annotation"
                            label="Anmerkung"
                            rules={{required: true}}
                            rows={3}
                            multiline
                        />
                        <Button
                            type="submit"
                            form="tx-form"
                            variant='outlined'
                            disabled={!isDirty || !isValid || globalError || isLoading}
                        >
                             {verb}
                        </Button>
                        <Box>
                            <Typography variant="text_s">{`${materials.length} Artikel ausgewählt | Gesamtsumme ${formatNumber(sum, 2, '€')}`}</Typography>
                        </Box>
                    </Stack>
                </Stack>
            </DialogContent>
            <WarehouseMailOrderDialog
                open={openDialog}
                setOpen={setOpenDialog}
                res={res}
                setRes={setRes}
            />
        </Dialog>
    )
}

export default WarehouseTransactionDialog