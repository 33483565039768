import { useState, useContext, useEffect } from 'react';

import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import AddIcon from '@mui/icons-material/Add';
import { CircularProgress, FormControlLabel, MenuItem, Select, Switch, Typography } from '@mui/material';
import { Box, Stack } from '@mui/system';
import ProjectContext from 'context/ProjectContext';
import AccountContext from 'context/AccountContext';
import TooltipIconButton from 'components/Widgets/TooltipIconButton';
import FileUploaderMultiple from 'components/Widgets/FileUploaderMultiple';
import { VISIBILITIES } from '.';
import { getCookie } from 'utils/getCookie';



const UploadDialog = ({ title, getUploads, disabled }) => {
    const [open, setOpen] = useState(false);
    const [selectedFiles, setSelectedFiles] = useState([])
    const [project] = useContext(ProjectContext)
    const [account] = useContext(AccountContext)
    const [description, setDescription] = useState('')
    const [ successCount, setSuccessCount ] = useState(0)
    const [ failedCount, setFailedCount ] = useState(0)
    const [ filesCount, setFilesCount ] = useState(0)
    const [ uploading, setUploading ] = useState(false)

    const [ visibility, setVisibility ] = useState(VISIBILITIES.private);
    const handleVisibilityChange = (e) => {
        setVisibility(e.target.value)
    }

    const [ plan, setPlan ] = useState(false);
    const handlePlanChange = (e) => {
        setPlan(e.target.checked)
    }

    const handleClickOpen = () => {
        setOpen(true)
    }

    const resetInputs = () => {
        setSelectedFiles([])
        setDescription('')
        setPlan(false)
        setVisibility(VISIBILITIES.private)
    }

    const handleClose = () => {
        setOpen(false)
        setSuccessCount(0)
        setFailedCount(0)
        setFilesCount(0)
        resetInputs()
    }

    const uploadFile = async (file, description) => {
        if (file == null) return
        const formData = new FormData()
        formData.append('file', file)
        formData.append('description', description)
        formData.append('plan', plan)
        formData.append('visibility', visibility)
        try {
            const res = await fetch('/documents/project/' + project.id, {
                method: 'POST',
                body: formData,
                headers: {
                    'X-CSRFToken': getCookie('csrftoken')
                }
            })
            if (res.ok) {
                setSuccessCount(c => c + 1)
            } else {
                setFailedCount(c => c + 1)
            }
        } catch {
            setFailedCount(c => c + 1)
        }
    }

    const uploadFiles = async () => {
        setSuccessCount(0)
        setFailedCount(0)
        setFilesCount(selectedFiles.length)
        setUploading(true)
        if (description === '')
            return
        if (selectedFiles.length === 1) {
            await uploadFile(selectedFiles[0], description)
        } else {
            for (let i = 0; i < selectedFiles.length; i++) {
                await uploadFile(selectedFiles[i], `(${i + 1}/${selectedFiles.length}) ${description}`)
            }
        }
        setUploading(false)
        resetInputs()
        getUploads()
    }

    //TODO fix not loading more, when initial files not enough to cause scroll

    return (
        <div>
            <TooltipIconButton
                onClick={handleClickOpen}
                size='large'
                color='primary'
                disabled={disabled}
                icon={<AddIcon fontSize='inherit' />}
                title="Hochladen"
            />
            <Dialog open={open} onClose={handleClose}>
                <DialogTitle>Dokument hochladen</DialogTitle>
                <DialogContent sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
                    <FileUploaderMultiple
                        selectedFiles={selectedFiles}
                        setSelectedFiles={setSelectedFiles}
                        disabled={uploading}
                    />
                    <TextField
                        autoFocus
                        margin="dense"
                        id="description"
                        label="Beschreibung"
                        type="text"
                        fullWidth
                        variant="outlined"
                        onChange={e => setDescription(e.target.value)}
                        value={description}
                        disabled={uploading}
                    />
                    <Select
                        value={visibility}
                        onChange={handleVisibilityChange}
                        disabled={uploading}
                    >
                        <MenuItem value={VISIBILITIES.public}>Öffentlich</MenuItem>
                        {(account.is_admin || account.is_manager) && <MenuItem value={VISIBILITIES.privileged}>Planer</MenuItem>}
                        <MenuItem value={VISIBILITIES.internal_all}>Intern-Alle</MenuItem>
                        {(account.is_admin || account.is_manager) && <MenuItem value={VISIBILITIES.internal_privileged}>Intern-Planer</MenuItem>}
                        <MenuItem value={VISIBILITIES.private}>Private</MenuItem>
                    </Select>
                    <FormControlLabel
                        control={
                            <Switch
                                checked={plan}
                                onChange={handlePlanChange}
                                disabled={uploading}
                            />
                        }
                        label="Als Plan markieren"
                    />
                    <Stack sx={{
                        flex: 1,
                        flexDirection: 'row',
                        alignItems: 'center',
                        gap: 2,
                        paddingLeft: 1
                    }}>
                        {uploading && <CircularProgress size={24} />}
                        {//(uploading || successCount > 0 || failedCount > 0) &&
                        filesCount > 0 &&
                            <Typography>
                                {successCount}/{filesCount} hochgeladen{failedCount > 0 ? ' (' + failedCount + ' Fehlgeschlagen)' : ''}
                            </Typography>
                        }
                    </Stack>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose} color="secondary">Abbrechen</Button>
                    <Button
                        onClick={uploadFiles}
                        disabled={uploading || selectedFiles.length === 0 || description === ''}
                    >
                        Hochladen
                    </Button>
                </DialogActions>
            </Dialog>
        </div>
    )
}

export default UploadDialog
