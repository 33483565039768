import { useContext } from "react"

import { Stack } from '@mui/material'
import useMediaQuery from '@mui/material/useMediaQuery';

import Contacts from "../../Widgets/Chat/Contacts"
import CustomPage from '../../Widgets/CustomPage'

import { NotificationContext } from "../../../context/NotificationContext"
import CompanyBlackboard from "./CompanyBlackboard";


const CompanyChat = () => {
    const breakpoint = useMediaQuery('(min-width: 1420px)');

    const [notifications] = useContext(NotificationContext)

    return (
        <CustomPage
            title="Betriebsfeed & Betriebschats"
        >
            <Stack
                sx={{ flex: 1 }}
                direction={breakpoint ? 'row' : 'column'}
                alignItems='stretch'
                gap={3}
            >
                <Stack sx={{ flex: '1 0 0', minHeight: '700px' }}>
                    <CompanyBlackboard />
                </Stack>
                <Stack sx={{ flex: '2 0 0', minHeight: '700px' }}>
                    <Contacts
                        contactsURL={'/api/company/chat'}
                        deleteChatUrl={'/api/company/chat/'}
                        notifications={notifications.internal_chat}
                    />
                </Stack>
            </Stack>
        </CustomPage>
    )
}

export default CompanyChat
