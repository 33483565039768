import { Stack, IconButton, Tooltip } from '@mui/material';
import { FilterAlt, Add  } from '@mui/icons-material';
import {useState} from "react";
import TooltipIconButton from "../../../Widgets/TooltipIconButton";
import AddDialog from "./AddDialog";


const CustomSupplierToolbar = ({ apiRef, getSuppliers }) =>{
    const [ open, setOpen ] = useState(false)

    return (
        <Stack
            direction='row'
        >
            <TooltipIconButton
                title='Neuer Lieferant'
                onClick={() => setOpen(true)}
                icon={<Add />}
            />
            <AddDialog
                open={open}
                onClose={() => setOpen(false)}
                getSuppliers={getSuppliers}
            />
        </Stack>
    )
}

export default CustomSupplierToolbar