import React from 'react'
import { useState } from 'react'

export const NotificationContext = React.createContext()

export const NotificationContextWrapper = props => {
    const [ notifications, setNotifications ] = useState({
        project_blackboard: [],
        company_blackboard: [],
        private_project_chat: [],
        internal_chat: [],
        conmeet_connections_chat: [],
        company_calendar: [],
        to_do: [],
        project_request: [],
        project_join_request: [],
        sub_project: [],
        ticket: [],
        project_note: [],
        project_agreement: []
    })

    return (
        <NotificationContext.Provider value={[ notifications, setNotifications ]}>
            {props.children}
        </NotificationContext.Provider>
    )
}
