import { useEffect } from "react";
import { useForm } from "react-hook-form";
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Stack } from "@mui/material";

import { getLocalDate, getLocalTime } from '../../../../../utils/date_functions'
import { getCookie } from "../../../../../utils/getCookie";

import ControlledTextField from "../../../../Widgets/ControlledInputs/ControlledTextField";
import ControlledTimePicker from "../../../../Widgets/ControlledInputs/ControlledTimepicker";
import CompanyMemberMultiSelection from "../../../../Widgets/CompanyMemberMultiSelection";
import ControlledSelect from "../../../../Widgets/ControlledInputs/ControlledSelect";
import ControlledDatePicker from "../../../../Widgets/ControlledInputs/ControlledDatePicker";



const EditEvent = (props) =>{

    const { open, onClose, selectedEvent, getDayEvents, projectKeys, haveToLoadAllEvents } = props;

    const { control, handleSubmit, formState: { errors, isDirty, isValid }, watch, reset, setValue} = useForm({
        mode: 'onChange',
        defaultValues:{
            title: '',
            since: null,
            till: null,
            day: null,
            sub_project_key: '',
            company_subscribers: [],
            description: '',
        }
    })

    const handleEditEvent = async (data) => {
        const json = JSON.stringify({
            ...selectedEvent,
            ...data,
            day:  getLocalDate(data.day),
            since: getLocalTime(data.since),
            till: getLocalTime(data.till),
        })
        const res = await fetch('/api/company/workplanner', {
            method: 'PATCH',
            headers: {
                'Content-Type': 'application/json',
                'X-CSRFToken': getCookie('csrftoken')
            },
            body: json,
        })
        if (res.ok) {
            getDayEvents()
            onClose()
            haveToLoadAllEvents.current = true
            reset();
        }
    }

    useEffect(()=>{
        if (selectedEvent === null) return
        for (const [key, value] of Object.entries(selectedEvent)) {
            if (key === 'sub_project') {
                setValue('sub_project_key', value.id, {
                    shouldValidate: true,
                    shouldDirty: true
                })
                continue
            }
            if (key === 'day') {
                setValue(key, new Date(value), {
                    shouldValidate: true,
                    shouldDirty: true
                })
                continue
            }
            if (key === 'since' || key === 'till') {
                let time = new Date('2022-01-01T' + value);
                setValue(key, time, {
                    shouldValidate: true,
                    shouldDirty: true
                })
                continue
            }
            setValue(key, value, {
                shouldValidate: true,
                shouldDirty: true
            })
        }
        let _temp = []
        selectedEvent.allocator.forEach(allocator => {
            _temp.push(allocator.company_member.id.toString())
        });
        setValue('company_subscribers', _temp, {
            shouldValidate: true,
            shouldDirty: true
        })
    }, [selectedEvent, setValue])

    return(
        <Dialog
            open={open}
            onClose={onClose}
            maxWidth='tablet'
        >
            <DialogTitle>
                Event ändern
            </DialogTitle>
            <DialogContent>
                <Stack
                    component='form'
                    id='edit-event-form'
                    onSubmit={handleSubmit(handleEditEvent)}
                    spacing={2}
                    sx={{pt: 1}}
                >
                    <ControlledTextField
                        control={control}
                        name="title"
                        label="Name"
                        rules={{ required: true }}
                        errors={errors}
                    />
                    <ControlledSelect
                        control={control}
                        name="sub_project_key"
                        label="Projektauftrag"
                        rules={{ required: true }}
                        errors={errors}
                        items={projectKeys}
                    />
                    <ControlledDatePicker
                        control={control}
                        name="day"
                        label="Tag des Auftrags"
                        rules={{ required: true }}
                        errors={errors}
                    />
                    <Stack
                        direction='row'
                        spacing={2}
                    >
                        <ControlledTimePicker
                            control={control}
                            rules={{ required: true }}
                            name='since'
                            label="Beginn"

                        />
                        <ControlledTimePicker
                            control={control}
                            rules={{ required: true }}
                            name='till'
                            label="Ende"
                            minTime={watch('since')}
                        />
                    </Stack>
                    <CompanyMemberMultiSelection
                        control={control}
                        errors={errors}
                        name="company_subscribers"
                        label="Teilnehmer"
                        includeCurrentUser={true}
                        onlyWorkers={false}
                    />
                    <ControlledTextField
                        control={control}
                        name="description"
                        label="Beschreibung"
                        rules={{ required: true }}
                        errors={errors}
                        multiline
                        rows={8}
                    />

                </Stack>
            </DialogContent>
            <DialogActions>
                <Button
                    onClick={onClose}
                    color="secondary"
                >
                    Zurück
                </Button>
                <Button
                    color="primary"
                    type="submit"
                    form="edit-event-form"
                    autoFocus
                    disabled={!isDirty || !isValid}
                >
                    Änderung speichern
                </Button>
            </DialogActions>
        </Dialog>
    )
}

export default EditEvent
