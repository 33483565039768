import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useLocation, useParams } from 'react-router';
import { Link } from 'react-router-dom';

import { Box, Button, Checkbox, CircularProgress, FormControlLabel, FormGroup, Paper, Stack, Typography } from '@mui/material'
import ErrorIcon from '@mui/icons-material/Error'
import ThumbUp from '@mui/icons-material/ThumbUp'

import ControlledTextField from 'components/Widgets/ControlledInputs/ControlledTextField';
import LogoFullWhite from 'components/Widgets/Logos/LogoFullWhite'

import { getCookie } from 'utils/getCookie';
import setServerErrors from 'utils/setServerErrors';
import { PALETTE } from 'components/theme';


const Agreement = () => {
    const { id } = useParams()

    const [ success, setSuccess ] = useState(false)
    const [ agreement, setAgreement ] = useState()
    const [ checked, setChecked ] = useState(false)

    useEffect(() => {
        (async () => {
            const res = await fetch('/account/agreement/' + id)
            if (res.ok) {
                const json = await res.json()
                setAgreement(json)
            }
        })()
    }, [id])

    const handleClick = async () => {
        if (!checked || !agreement)
            return

        const res = await fetch('/account/agreement/' + agreement.id, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'X-CSRFToken': getCookie('csrftoken')
            },
            body: JSON.stringify({
                agreement_id: agreement.id
            })
        })
        if (res.ok) {
            setSuccess(true)
        }
    }

    return (
        <Stack
            spacing={3}
            sx={{
                backgroundColor: 'background.main',
                justifyContent: 'center',
                alignItems: 'center',
                height: '100vh',
                p: 2
            }}
        >
            <LogoFullWhite width='100%' height={50}/>
            <Paper
                sx={{
                    p: 5,
                }}
            >
                {!success &&
                    <Stack direction='column' gap={2}>
                        <Box
                            sx={{
                                padding: 1,
                                border: 1,
                                borderRadius: '7px',
                                borderColor: PALETTE.background.light70,
                                height: 400,
                                overflow: 'auto'
                            }}
                        >
                            <Typography
                                variant="text_m_justify"
                            >
                                {agreement?.text}
                            </Typography>
                        </Box>
                        <FormControlLabel control={<Checkbox checked={checked}  onChange={e => setChecked(e.target.checked)} />} label="Ich stimme den Nutzerbedingungen zu" />
                        <Button
                            color="primary"
                            variant="contained"
                            size="large"
                            fullWidth
                            disabled={!checked}
                            onClick={handleClick}
                        >
                            Speichern
                        </Button>
                    </Stack>
                }
                {success &&
                    <>
                        <Stack justifyContent='center' alignItems='center' gap={2}>
                            <ThumbUp color="primary" fontSize='large'/>
                            <Typography variant="overline" sx={{ color: 'success.main'}}>
                                Sie haben die Nutzerbedingungen erfolgreich angenommen.
                            </Typography>
                            {/* <Link to='/login' style={{ textDecoration: 'none' }}> */}
                            {/*     <Button variant='outlined' color='primary'> */}
                            {/*         Login */}
                            {/*     </Button> */}
                            {/* </Link> */}
                        </Stack>
                    </>
                }
            </Paper>
        </Stack>
    )
}

export default Agreement
