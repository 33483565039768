import { IconButton, Stack, Tooltip } from "@mui/material";
import { BsFillFileEarmarkPdfFill } from "react-icons/bs";
import { formatTimestamp } from "../../../../../utils/date_functions";
import { formatNumber } from "../../../../../utils/helper";

import OrderMailToButton from "components/Widgets/OderMailToButton";


export function formatValueWithUnit(params){
	const unit = params.api.getRow(params.id).unit
	if (params.value == null) {
		return "";
	}
	return `${formatNumber(params.value, 2, '')} ${unit.split('/')[1]}`;
}

export function formatPrice({value}){
	return `${ formatNumber(value, 2, '€')}`
}

export function formatDate({value}) {
	return formatTimestamp(value)
}
export function getValueWithUnit(params, value_key, unit_suffix){
	return `${formatNumber(params.row[value_key],  2, unit_suffix ? params.row.unit.split('/')[1] : '€' )}`;
}

export function getDiffOffer(params) {
	return params.row.value_offered + params.row.used
}

export function getPreBalance(params) {
	if(params.row.balance  < 0) return 0
	return params.row.balance + params.row.used
}

export function getTotalOrderValue(params) {
	return params.row.value - params.row.correction_value
}

export function getTotalPrice(params){
	const value  = params.row.value - params.row.correction_value
	const default_price = params.row.default_price
	const pre_price = params.row.pre_price
	const post_price = params.row.post_price
	return  value * (post_price !==  0 ? post_price : (pre_price !==  0 ? pre_price : default_price))
}

export function CustomPDFMailCell(params){
	const typeName = params.row.type
	const fileLink = params.row.file
	const supplierEmail = params.row.supplier_email
	const creator = params.row.creator
	const companyName = params.row.company_name

	const disabled = fileLink === null
	return(
		<Stack
			direction="row"
			alignItems="center"
			justifyContent="center"
			gap={0.5}
		>
			<Tooltip title="Dokument" disableHoverListener={disabled}>
				<span>
					<IconButton
						color='primary'
						href={fileLink}
						target="_blank"
						download={typeName}
						disabled={disabled}
					>
						<BsFillFileEarmarkPdfFill size={20} />
					</IconButton>
				</span>
			</Tooltip>
			<OrderMailToButton
				typeName={typeName}
				fileLink={fileLink}
				supplierEmail={supplierEmail}
				creator={creator}
				companyName={companyName}
				disabled={disabled}
			/>
		</Stack>
	)
}