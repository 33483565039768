import NoData from "components/Widgets/NoData";
import Loading from "../Widgets/Loading";

const ChechSubProjects = ({ subs, isLoaded, info_text, children }) => {

    const check = () =>{
        if(subs.length !== 0) return true
        return false
    }

    return isLoaded ? check() ? children : <NoData text={info_text}/> : <Loading/>
};

export default ChechSubProjects;