import { useContext, useEffect, useState } from 'react'
import CompanyContext from '../../../../context/CompanyContext'

import { Stack } from '@mui/material';
import { useGridApiRef } from '@mui/x-data-grid-pro';

import CompanyInfo from './CompanyInfo';
import CustomPage from '../../../Widgets/CustomPage'
import { getConstants } from '../../../../utils/getConstants';
import PaperDataGrid from "../../../Widgets/CustomDatagrids/NoPagination/PaperDataGrid";


const CompanyOverview = () => {

	const apiRef = useGridApiRef();
    const [company, companyMembers] = useContext(CompanyContext)
	const [jobTitles, setJobTitles] =  useState({})

    useEffect(() => {
        getConstants('job_titles', (c) =>{setJobTitles(c)})
    }, [])

    const columns = [
		{
			field: 'firstname',
			headerName: 'Vorname',
			type: 'string',
			minWidth: 150,
		},
		{
			field: 'lastname',
			headerName: 'Nachname',
			type: 'string',
			minWidth: 150,
		},
		{
			field: 'job_title',
			headerName: 'Job',
			type: 'string',
			flex:'1',
			minWidth: 100,
		},
        {
			field: 'phone_number',
			headerName: 'Telefonnummer',
			type: 'string',
			minWidth: 200,
		},
        {
			field: 'is_manager',
			headerName: 'Manager',
			type: 'boolean',
			minWidth: 200,
		},
	]

    return(
        <CustomPage title='Unternehmen'>
            <Stack spacing={3} width="100%">
                <CompanyInfo
					company={company}
					company_members={companyMembers}
					jobTitles={jobTitles}
				/>
				<PaperDataGrid
					title="Mitarbeiter"
					apiRef={apiRef}
					rows={companyMembers}
					columns={columns}
				/>
            </Stack>
        </CustomPage>
    )
}

export default CompanyOverview