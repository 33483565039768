import {useState, useEffect, useContext} from 'react'
import { useForm } from "react-hook-form";

import { Stack, Button, Dialog, DialogTitle,  DialogContent, DialogContentText, DialogActions, ToggleButtonGroup, ToggleButton, Typography } from "@mui/material"

import { getCookie } from '../../../../../utils/getCookie';
import { getLocalDate } from '../../../../../utils/date_functions';

import ControlledTextField from '../../../../Widgets/ControlledInputs/ControlledTextField';
import ControlledDateRangePicker from '../../../../Widgets/ControlledInputs/ControlledDateRangePicker';
import ControlledSelect from '../../../../Widgets/ControlledInputs/ControlledSelect';
import { checkSelectionZero } from '../../../../../utils/helper';

import Paywall from "../../../../Widgets/Paywall";
import AccountContext from "../../../../../context/AccountContext";
import ControlledSwitch from 'components/Widgets/ControlledInputs/ControlledSwitch';


const ProjectAddTasks = (props) =>{

    const { open, onClose, project_id } = props

    const [account] = useContext(AccountContext)

    const [subType, setSubType] = useState('private')
    const [subCompanies, setSubCompanies] = useState({});
    const [parentSubProjects, setParentSubProjects] = useState({});
    const [customers, setCustomers] = useState([])
    const [buisnessCustomers, setBuisnessCustomers] = useState([])

    const { control, handleSubmit, reset, watch, formState: { errors, isValid, isDirty }} = useForm({
        mode: 'onChange',
        defaultValues:{
            title: '',
            instruction: '',
            value: '',
            date_range: [null, null],
            parent_sub_project: 0,
            service_provider: '',
            contract_number_customer:'',
            contract_number_service_provider:'',
            privat_customer_outside: '',
            buisness_customer_outside: '',
            max_material: '',
            max_workload: '',
            max_task: '',
            wage_rate: account.company.wage_rate,
            at_cost: false,
            drive_rate: 0,
        }
    });

    const handleOnClose = () =>{
        onClose()
        reset()
    }

    const handleSubType= (event, newType) => {
        if (newType !== null) {
            setSubType(newType);
        }
    };

    const onSubmit = async(data) => {
        const _private = subType === 'private'
        if(!_private){
            delete data.max_material
            delete data.max_workload
            delete data.max_task
            delete data.wage_rate
            delete data.contract_number_service_provider
        }
        const json = JSON.stringify({
            ...data,
            private: _private,
            service_provider: checkSelectionZero(data.service_provider),
            privat_customer_outside: checkSelectionZero(data.privat_customer_outside),
            buisness_customer_outside: checkSelectionZero(data.buisness_customer_outside),
            date_range: [getLocalDate(data.date_range[0]), getLocalDate(data.date_range[1])],
        })
        const res = await fetch('/api/project/' + project_id + '/tasks', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'X-CSRFToken': getCookie('csrftoken')
            },
            body: json
        })
        const _data = await res.json()
        if (res.ok) {
            handleOnClose()
        } else{
            console.log(_data)
        }
    }

    useEffect(() => {
        const getSubCompanies = async() => {
            const res = await fetch('/api/project/' + project_id + '/subcompanies', {
                method: 'GET',
            })
            if (res.ok) {
                const data = await res.json()
                setSubCompanies({...{0: 'Nicht Vorhanden | Pseudoauftrag vergeben'}, ...data})
            }
        }
        getSubCompanies()
    }, [project_id])

    useEffect(() => {
        const getParentSubProjects = async() => {
            const res = await fetch('/api/project/' + project_id + '/parent_sub_projects', {
                method: 'GET',
            })
            if (res.ok) {
                const data = await res.json()
                setParentSubProjects(data)
            }
        }
        getParentSubProjects()
    }, [project_id])

    useEffect(() => {
        if (!account.company.paid)
            return

        const getCustomers = async() => {
            const res = await fetch('/api/company/customer', {
                method: 'GET',
            })
            const _data = await res.json()
            if (res.ok) {
                const transformed = { 0: "Nicht vorhanden"}
                for (const customer of _data) {
                    transformed[customer.id] = customer.firstname + ' ' + customer.lastname
                }
                setCustomers(transformed)
            }
        }
        const getBuisnessCustomers = async() => {
            const res = await fetch('/api/company/buisness_customer', {
                method: 'GET',
            })
            const _data = await res.json()
            if (res.ok) {
                const transformed = { 0: "Nicht vorhanden"}
                for (const customer of _data) {
                    transformed[customer.id] = customer.name
                }
                setBuisnessCustomers(transformed)
            }
        }
        getCustomers()
        getBuisnessCustomers()
    }, [account])

    return(
        <Dialog
            maxWidth="laptop"
            open={open}
            onSubmit={handleSubmit(onSubmit)}
            onClose={handleOnClose}
            fullWidth
        >
            <Paywall>
                <form>
                    <DialogTitle>Projektauftrag erstellen</DialogTitle>
                    <DialogContent>
                        <DialogContentText>
                            Erstelle ein Projektauftrag für dich oder für ein deiner Subs.
                        </DialogContentText>
                        <Stack
                            direction="row"
                            sx={{
                                mt:1.5
                            }}
                            gap={2}
                        >
                            <Stack
                                gap={2}
                                width="100%"
                            >
                                <ControlledTextField
                                    name="title"
                                    control={control}
                                    rules={{ required: true }}
                                    label="Name des Projektauftrages"
                                    errors={errors}
                                />
                                <ControlledTextField
                                    name="contract_number_customer"
                                    control={control}
                                    rules={{ required: false }}
                                    label="Auftragsnummer - Auftraggeber (optional)"
                                    errors={errors}
                                />
                                {subType === 'private' &&
                                        <ControlledTextField
                                            name="contract_number_service_provider"
                                            control={control}
                                            rules={{ required: false }}
                                            label="Auftragsnummer - Dienstleister (optional)"
                                            errors={errors}
                                        />
                                }
                                <ControlledTextField
                                    name="instruction"
                                    control={control}
                                    rules={{ required: true }}
                                    label="Projektauftragsbeschreibung"
                                    errors={errors}
                                    multiline
                                    rows={6}
                                />
                                <ControlledTextField
                                    name="value"
                                    control={control}
                                    rules={{ required: true,  maxLength: { value: 8, message: "Zu viele Ziffern! max. 8 Ziffern" } }}
                                    label="Projektauftragssumme (netto)"
                                    errors={errors}
                                    type="number"
                                    suffix="€"
                                />
                                <ControlledDateRangePicker
                                    name="date_range"
                                    control={control}
                                    rules={{ required: true}}
                                    startText="Start des Projektauftrages"
                                    endText="Ende des Projektauftrages"
                                />
                                <ControlledSelect
                                    name="privat_customer_outside"
                                    control={control}
                                    label="Privatkunde (optional)"
                                    rules={{ required: false }}
                                    errors={errors}
                                    items={customers}
                                />
                                <ControlledSelect
                                    name="buisness_customer_outside"
                                    control={control}
                                    label="Gewerbekunde (optional)"
                                    rules={{ required: false }}
                                    errors={errors}
                                    items={buisnessCustomers}
                                />
                            </Stack>
                            <Stack
                                gap={2}
                                width="100%"
                            >
                                <ToggleButtonGroup
                                    color="primary"
                                    value={subType}
                                    exclusive
                                    onChange={handleSubType}
                                    aria-label="sub-project-type"
                                    fullWidth
                                    sx={{ height: 53 , mb: 0.45}}
                                >
                                    <ToggleButton value="private">Eigener Auftrag</ToggleButton>
                                    <ToggleButton value="posted">Auftrag vergeben</ToggleButton>
                                </ToggleButtonGroup>
                                <ControlledSelect
                                    name="parent_sub_project"
                                    control={control}
                                    rules={{ required: true }}
                                    disabled={Object.keys(parentSubProjects).length === 0}
                                    label="Übergestellter Projektauftrag"
                                    errors={errors}
                                    items={parentSubProjects}
                                />
                                {subType === 'private' ?
                                    <>
                                        <ControlledTextField
                                            name="max_workload"
                                            control={control}
                                            rules={{ required: subType === 'private' }}
                                            label="Kalkulierte Arbeitsstunden"
                                            errors={errors}
                                            type="number"
                                            suffix="Std."
                                        />
                                        <ControlledTextField
                                            name="drive_rate"
                                            control={control}
                                            label="Fahrtkostenpauschale"
                                            rules={{ required: false }}
                                            errors={errors}
                                            suffix="€"
                                            type="number"
                                        />
                                        <ControlledTextField
                                            name="wage_rate"
                                            control={control}
                                            label="Stundenverrechnungssatz ohne Lohngewinn"
                                            rules={{ required: subType === 'private' }}
                                            errors={errors}
                                            suffix="€/Std."
                                            type="number"
                                        />
                                        <ControlledTextField
                                            name="max_material"
                                            control={control}
                                            rules={{ required: subType === 'private' }}
                                            label="Kalkulierte Materialkosten"
                                            errors={errors}
                                            type="number"
                                            suffix="€"
                                        />
                                        <ControlledTextField
                                            name="max_task"
                                            control={control}
                                            rules={{ required: subType === 'private' }}
                                            label="Kalkulierte externe Auftragskosten"
                                            errors={errors}
                                            type="number"
                                            suffix="€"
                                        />
                                    </>
                                :
                                    <ControlledSelect
                                        name="service_provider"
                                        control={control}
                                        rules={{ required: subType === 'posted'}}
                                        disabled={Object.keys(subCompanies).length === 0}
                                        label="Unternehmen"
                                        errors={errors}
                                        items={subCompanies}
                                    />
                                }
                                <Stack>
                                    <ControlledSwitch
                                        name="at_cost"
                                        control={control}
                                        label="Auftrag nach Aufwand berechnen"
                                        color="primary"
                                    />
                                    <Typography variant="text_m" sx={{pt:1, pb:1}}>
                                        {watch('at_cost') === true ?
                                            "Bitte gibe trotzdem die geschätzten Kosten für die Vorkalkulation des Auftrags ein, um den Überblick über die Nachkalkulation des Auftrags zu gewährleisten."
                                        :
                                            ""
                                        }
                                    </Typography>
                                </Stack>
                            </Stack>
                        </Stack>
                        <DialogActions>
                            <Button
                                color="secondary"
                                onClick={onClose}
                            >
                                Abbrechen
                            </Button>
                            <Button
                                type="submit"
                                color="primary"
                                variant="outlined"
                                disabled={!isValid || !isDirty}
                            >
                                Auftrag erstellen
                            </Button>
                        </DialogActions>
                    </DialogContent>
                </form>
            </Paywall>
        </Dialog>
    )
}

export default ProjectAddTasks;