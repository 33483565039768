import React, {useContext, useEffect, useState} from "react";
import CustomPaper from "../../Widgets/CustomPaper";
import Chat from "../../Chat";
import InformationDialog from "../../Widgets/Dialogs/InformationDialog";
import { companyBlackboardInfoText } from "../../../utils/texts";


const CompanyBlackBoard = ({ sx }) =>{
    const [ company, setCompany ] = useState()

    useEffect(() => {
        const getCompany = async () => {
            const res = await fetch('/api/company', {
                method: 'GET',
            })
            if (res.ok) {
                const data = await res.json()
                setCompany(data)
            }
        }
        getCompany()
    }, [])

    return (
        <CustomPaper
            title='Betriebsfeed'
            subtitle="Alle Beiträge"
            sx={{ flex: '1 1 0', overflow: 'hidden' }}
            padding={0}
        >
            {company &&
                <Chat
                    socketUrl={'/chat/' + company.chat_id + '/'}
                    isProjectBlackboard={true}
                />
            }
        </CustomPaper>
    )
}

export default CompanyBlackBoard
