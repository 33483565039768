import { useEffect, useState } from 'react';

import { Button, Stack, Grid } from "@mui/material";

import { getCookie } from '../../../../utils/getCookie';

import CustomPage from '../../../Widgets/CustomPage';
import ProjectCardWrapperSR from '../../../Widgets/ProjectCards/ProjectCardWrapperSR';
import NoData from 'components/Widgets/NoData';

const ProjectRequests = () => {

    const [requests, setRequests] = useState([])

    const getRequests = async() => {
        const res = await fetch('/api/projects/requests', {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
            },
        })
        const data = await res.json()
        if (res.ok) {
            setRequests(data)
            console.log(data)
        }
    }

    const handleAccept = async(project_allocator_id) => {

        const json = JSON.stringify({
            'project_allocator_id': project_allocator_id,
        })

        const res = await fetch('/api/projects/requests', {
            method: 'PATCH',
            headers: {
                'Content-Type': 'application/json',
                'X-CSRFToken': getCookie('csrftoken')
            },
            body: json
        })
        const data = await res.json()
        if(res.ok){
            getRequests()
            console.log(data)
        }
    }

    const handleDelete = async(project_allocator_id) => {

        const json = JSON.stringify({
            'project_allocator_id': project_allocator_id
        })

        const res = await fetch('/api/projects/requests', {
            method: 'DELETE',
            headers: {
                'Content-Type': 'application/json',
                'X-CSRFToken': getCookie('csrftoken')
            },
            body: json
        })
        const data = await res.json()
        if(res.ok){
            getRequests()
            console.log(data)
        }
    }

    useEffect(() => {
        getRequests()
    }, [])

    return(
        <CustomPage
            title="Projektanfragen"
        >
            {requests.length !== 0 ?
                <Grid
                    container
                    justifyContent='center'
                    spacing={3}
                >
                    {requests.map((request, idx) => {
                        return(
                            <Grid
                                item
                                desktop={4}
                                laptop={6}
                                tablet={12}
                                mobile={12}
                                key={`project-request-card-${idx}`}
                            >
                                <ProjectCardWrapperSR
                                    project={request.project}
                                >
                                    <Stack spacing={2}>
                                        <Button
                                            onClick={() =>{handleDelete(request.id)}}
                                            color="secondary"
                                            variant="outlined"
                                            size="large"
                                            fullWidth
                                        >
                                            Ablehnen
                                        </Button>
                                        <Button
                                            onClick={() =>{handleAccept(request.id)}}
                                            color="primary"
                                            variant="contained"
                                            fullWidth
                                            size="large"
                                        >
                                            {request.with_task ? "Annehmen mit Projektauftrag" : "Anehmen als freies Mitglied"}
                                        </Button>
                                    </Stack>
                                </ProjectCardWrapperSR>
                            </Grid>
                        )
                    })}
                </Grid>:
                <Stack
                    justifyContent="center"
                    alignItems="center"
                    height="100%"
                >
                    <NoData text={'Du hast derzeit keine Projektanfragen'}/>
                </Stack>}
        </CustomPage>
    )
}

export default ProjectRequests