import { Box, Button,  DialogContent, DialogContentText, Dialog, DialogTitle, DialogActions, Typography, Stack, Chip} from "@mui/material";
import { handleChipColorVariant } from "../../../../../utils/helper";

import { formatTimestamp } from '../../../../../utils/date_functions'

const TicketDialog = (props) =>{

    const { open, onClose, ticket } = props;

    let cv = handleChipColorVariant(ticket?.status)
    let color = cv[0]
    let variant = cv[1]

    return(
        <Dialog
            open={open}
            onClose={onClose}
            maxWidth={'desktop'}
        >
            <Stack
                direction='row'
                alignItems='stretch'
            >
                <Box
                    component="img"
                    height="100%"
                    src={ticket?.image}
                    alt="no image"
                    sx={{
                        maxHeight:900,
                        maxWidth:1200
                    }}
                />
                <Stack
                    sx={{ width: 400 }}
                >
                    <DialogTitle>
                        Ticket - {ticket?.title}
                    </DialogTitle>
                    <DialogContent sx={{flexGrow: 1}}>
                        <DialogContentText sx={{maxWidth:500}}>
                            {ticket?.instruction}
                        </DialogContentText>
                        <Stack>
                            <Box>
                                <Chip
                                    label={ticket?.status}
                                    variant={variant}
                                    color={color}
                                    sx={{
                                        mt: 2,
                                        mb: 2
                                    }}
                                />
                            </Box>
                            <Typography variant="text_s" component="div">
                                Dienstleister: {ticket?.service_provider.name}
                            </Typography>
                            <Typography  variant="text_s" component="div">
                                Deadline: {formatTimestamp(ticket?.deadline)}
                            </Typography>
                        </Stack>
                    </DialogContent>
                    <DialogActions>
                            <Button onClick={onClose} color="secondary">Schließen</Button>
                    </DialogActions>
                </Stack>
            </Stack>
        </Dialog>
    )
}

export default TicketDialog