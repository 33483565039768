import { Stack, CircularProgress, Typography } from '@mui/material'


const Loading = () =>{
    return(
        <Stack
            justifyContent='center'
            alignItems='center'
            flex={1}
            spacing={2}
        >
            <CircularProgress size={80}/>
            <Typography variant='text_m' sx={{textAlign: 'center'}}>
                Bitte einen kurzen Moment warten..
            </Typography>
        </Stack>
    )
}

export default Loading