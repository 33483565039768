import CustomPaper from "components/Widgets/CustomPaper"
import DeleteIcon from '@mui/icons-material/Delete';
import { Button, Stack } from "@mui/material";
import ConfirmDialog from "components/Widgets/ConfirmDialog";
import { useState } from "react";
import { getCookie } from "utils/getCookie";
import useStyledSnackbar from "hooks/useStyledSnackbar";



const DeleteAccount = () => {

    const [ error, setError ] = useState()
    const { enqueueSnackbar } = useStyledSnackbar()

    const deleteAccount = async () => {
        const res = await fetch('/api/account', {
            method: 'DELETE',
            headers: {
                'X-CSRFToken': getCookie('csrftoken')
            },
        })

        if (res.ok) {
            window.location.reload()
        } else {
            const json = await res.json()
            setError(json.detail)
            enqueueSnackbar(error, {
                variant: 'error'
            })
        }
    }

    return (
        <CustomPaper
            title="Konto löschen"

        >
            <Stack gap={2}>
                <ConfirmDialog
                    title='Konto löschen'
                    message='Wollen Sie Ihr Konto wirklich löschen? Dieser Vorgang ist unwiderruflich!'
                    onConfirm={deleteAccount}
                    renderButton={(onClick) => (
                        <Button
                            variant="outlined"
                            color="error"
                            component="span"
                            startIcon={<DeleteIcon />}
                            sx={{
                                alignSelf: 'center'
                            }}
                            onClick={onClick}
                        >
                            Konto löschen
                        </Button>
                    )}
                />
            </Stack>
        </CustomPaper>
    )
}

export default DeleteAccount
