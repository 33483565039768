import { Stack, Typography } from "@mui/material"
import { BsFillLockFill, BsFillUnlockFill } from "react-icons/bs"
import { formatNumber } from "../../../../../utils/helper"
import { PALETTE } from "../../../../theme"

const MaterialInfoStack = ({ material }) =>{
    const unit_str = material.unit.split('/')
    const unit_suf = unit_str[1]

    return(
        <Stack
            sx={{flex: 1}}
            gap={0.2}
        >
            <Typography
                    variant="subtitle_s"
                >
                    {material.description}
            </Typography>
            <Typography
                    variant="caption_m"
                >
                    {`Art.-Nr. ${material.unique_material_id} | ${material.manufacturer}`}
            </Typography>
            <Typography
                    variant="text_m"
                >
                    {`Standardpreis ${formatNumber(material.default_price, 2, material.unit)} | Lagerpreis ${formatNumber(material.stock_price_per_piece, 2, material.unit)}`}
            </Typography>
            <Stack
                direction='row'
                alignItems="center"
                spacing={1}
            >
                <Typography
                        variant="text_s"
                        color="primary"
                        sx={{fontWeight: 'bold'}}
                    >
                        {`Im Lager ${material.stock_balance} ${unit_suf} `}
                </Typography>
                {material.stock_is_locked ?
                    <BsFillLockFill size={12} color={PALETTE.error.main}/>
                :
                    <BsFillUnlockFill size={12} color={PALETTE.primary.main}/>
                }
                <Typography
                        variant="text_s"
                        sx={{fontWeight: 'bold'}}
                    >
                        {`| Im Depot ${material.balance} ${unit_suf} `}
                </Typography>
            </Stack>
        </Stack>
    )
}

export default MaterialInfoStack