import { useContext, useState } from "react";

import { MenuItem, Select, Stack } from "@mui/material";
import UploadDialog from './UploadDialog'

import CustomPaper from '../../../../Widgets/CustomPaper'
import NoData from "../../../../Widgets/NoData";
import AccountContext from "context/AccountContext";


const VisibilityTabs = ({ files, component: Component, title, getUploads, fileType, selectedVisibility, handleVisibilityChange }) => {
    const [account] = useContext(AccountContext)

    const ITEMS = [
        {
            dialogTitle: '',
            visibility: 'all',
            visibilityText: ' '
        },
        {
            dialogTitle: title + ' öffentlich',
            visibility: 'public',
            visibilityText: 'Für alle Projektteams sichtbar'
        },
        {
            dialogTitle: title + ' intern',
            visibility: 'internal',
            visibilityText: 'Nur für dein Unternehmen sichtbar'
        },
        {
            dialogTitle: title + ' privat',
            visibility: 'private',
            visibilityText: 'Nur für dich sichtbar'
        }
    ]

    const filteredFiles = (ITEMS[selectedVisibility].visibility === 'all' ?
        files.public
            .concat(files.internal)
            .concat(files.private)
            .sort((a, b) => {
                if (a.timestamp < b.timestamp) {
                    return 1
                } else if (a.timestamp > b.timestamp) {
                    return -1
                } else {
                    return 0
                }
            })
        :
        files[ITEMS[selectedVisibility].visibility]).filter((f, idx, self) =>
            idx === self.findIndex(f2 => f.id === f2.id)
        )

    return (
        <Stack sx={{ flex: '1 1 0' }} direction='column' gap={3}>
            <CustomPaper
                title={ITEMS[selectedVisibility].visibilityText}
                actionBar={
                    <Stack direction='row' gap={2} sx={{ pt: 1 }}>
                        {/* <UploadDialog */}
                        {/*     title={ITEMS[selectedVisibility].dialogTitle} */}
                        {/*     visibility={ITEMS[selectedVisibility].visibility} */}
                        {/*     getUploads={getUploads} */}
                        {/*     fileType={fileType} */}
                        {/*     disabled={ITEMS[selectedVisibility].visibility === 'all'} */}
                        {/* /> */}
                        <Select
                            value={selectedVisibility}
                            onChange={handleVisibilityChange}
                            sx={{ width: 200 }}
                        >
                            <MenuItem value={0}>Alle</MenuItem>
                            <MenuItem value={1}>Öffentlich</MenuItem>
                            <MenuItem value={2}>Intern</MenuItem>
                            <MenuItem value={3}>Privat</MenuItem>
                        </Select>
                    </Stack>
                }
            >
                {filteredFiles.length > 0 ?
                    <Component files={filteredFiles} getUploads={getUploads}/>
                    :
                    <NoData text='Keine Dokumente vorhanden' />
                }
            </CustomPaper>
      </Stack>
    )
}

export default VisibilityTabs

