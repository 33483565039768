import { useCallback, useEffect, useState } from "react"
import { Avatar, Box, CircularProgress, Dialog, DialogContent, DialogTitle, Divider, Stack, Typography } from "@mui/material"

import InfiniteScroll from "react-infinite-scroll-component"
import NoData from "../../../../Widgets/NoData"
import CustomLightWeightAccordion from "../../../../Widgets/CustomLightWeightAccordion"
import { formatTimestamp } from "../../../../../utils/date_functions"
import { PALETTE } from "../../../../theme"
import WarehouseActivityListItem from "./WarehouseActivityListItem"

const handleVerb = (type_key) =>{
    switch(type_key){
        case 1:
            return 'gebucht'
        case 2:
            return 'abgebucht'
        case 3:
            return 'bestellt'
        case 4:
            return 'storniert'
        case 5:
            return 'retourniert'
        case 6:
            return 'reserviert'
        case 7:
            return 'aus einem Projektauftrag gebucht'
        case 8:
            return 'aus einem Projektauftrag abgebucht'
        default:
            return ''
    }
}

const WarehouseActivityDialog = ({ open, onClose }) =>{

    const [lastActivities, setLastActivities] = useState([])
    const [nextUrl, setNextUrl] = useState(null)

    const getLastActivities = useCallback(async(nextUrl) => {
        if(open === false) return
        const res = await fetch(nextUrl ? nextUrl : '/api/company/warehouse/tx/activities', {
            method: 'GET'
        })
        const data = await res.json()
        if(res.ok){
            if (nextUrl) {
                setLastActivities(old => old.concat(data.results))
            } else {
                setLastActivities(data.results)
            }
            setNextUrl(data.next)
            console.log(data)
        }
    }, [open])

    useEffect(()=>{
        getLastActivities()
    }, [open, getLastActivities])

    const fetchNext = () => {
        if (nextUrl === null) return
        getLastActivities(nextUrl)
    }

    return(
        <Dialog
            open={open}
            onClose={onClose}
            fullWidth
            maxWidth="laptop"
        >
            <DialogTitle>
                Alle Aktivitäten
            </DialogTitle>
            <DialogContent id='activityDialogContent'>
                <Box
                    sx={{ height: '100%', overflow: 'hidden'}}
                >
                    {lastActivities.length === 0 &&
                        <NoData text='Keine Aktivitäten' />
                    }
                    <InfiniteScroll
                        dataLength={lastActivities.length}
                        next={fetchNext}
                        hasMore={nextUrl !== null}
                        loader={
                            <Stack sx={{ height: '50px', }} alignItems='center'>
                                <CircularProgress />
                            </Stack>
                        }
                        scrollableTarget="activityDialogContent"
                    >
                        {lastActivities.map((activity, idx) => {
                            const creator = activity.creator
                            const txs = activity.txs
                            const ctxs = activity.ctxs
                            const quantity = txs.length + ctxs.length
                            const mword = quantity !== 0 ? 'Materialien' : 'Material'
                            const verb  = handleVerb(activity.type_key)
                            return (
                                <Box key={`actvity-accordion-container-${idx}`}>
                                    <CustomLightWeightAccordion
                                        key={`actvity-accordion-${idx}`}
                                        title={`${activity.type}`}
                                        accordionbar={
                                            <Stack
                                                direction="row"
                                                alignItems="center"
                                                spacing={2}
                                                width="100%"
                                            >
                                                <Avatar alt="pm" src={creator.avatar} />
                                                <Stack
                                                    width="100%"
                                                    justifyContent="center"
                                                    gap={0.5}
                                                >
                                                    <Typography
                                                        variant="subtitle_s"
                                                        color="text.secondary"
                                                    >
                                                        {`${activity.type} ${activity.sub_project_name ? `| ${activity.sub_project_name}` : ''} ${activity.supplier_name ? `| ${activity.supplier_name}` : ''}`}
                                                    </Typography>
                                                    <Stack
                                                        direction='row'
                                                        justifyContent="space-between"
                                                    >
                                                        <Typography
                                                            variant="text_s"
                                                            color="text.secondary"
                                                        >
                                                            {`${creator.name} hat ${quantity} ${mword} ${verb}!`}
                                                        </Typography>
                                                        <Typography
                                                            variant="text_s"
                                                            color="text.secondary"
                                                        >
                                                            {`${formatTimestamp(activity.created)}`}
                                                        </Typography>
                                                    </Stack>

                                                </Stack>
                                            </Stack>
                                        }
                                    >
                                        <Stack
                                            spacing={1}
                                            sx={{
                                                marginLeft: 5,
                                                marginRight: 1,
                                                padding: 2,
                                                border: 1,
                                                borderRadius: '10px',
                                                borderColor: PALETTE.background.light70
                                            }}
                                        >
                                            <Stack spacing={0.5}>
                                                {txs.map(( tx, idx)=>{
                                                    return(
                                                        <WarehouseActivityListItem
                                                            key={`actvity-tx-${idx}`}
                                                            pos={idx + 1}
                                                            tx={tx}
                                                        />
                                                    )
                                                })}
                                                {ctxs.map(( ctx, idx)=>{
                                                    return(
                                                        <WarehouseActivityListItem
                                                            key={`actvity-tx-${idx}`}
                                                            pos={idx + 1}
                                                            tx={ctx}
                                                            correction={true}
                                                        />
                                                    )
                                                })}
                                            </Stack>
                                        </Stack>
                                    </CustomLightWeightAccordion>
                                    {lastActivities.length !== (idx + 1) && <Divider/>}
                                </Box>
                            );
                        })}
                    </InfiniteScroll>
                </Box>
            </DialogContent>
        </Dialog>
    )
}

export default WarehouseActivityDialog