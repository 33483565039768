import { useState, useEffect, useCallback } from 'react';
import { useForm } from 'react-hook-form';

import {
    Box,
    Stack,
    Button,
    Dialog,
    DialogTitle,
    DialogContent,
    DialogContentText,
    DialogActions,
    Typography,
    ListItem,
    TextField,
    InputAdornment,
    Divider,
    ToggleButtonGroup,
    ToggleButton,
    IconButton,
} from '@mui/material';
import { Clear } from '@mui/icons-material';

import { getCookie } from '../../../../utils/getCookie';
import useStyledSnackbar from '../../../../hooks/useStyledSnackbar';

import ControlledTextField from '../../../Widgets/ControlledInputs/ControlledTextField';
import ControlledSelect from '../../../Widgets/ControlledInputs/ControlledSelect';

import TxInfoStack from './TxInfoStack';
import NoData from 'components/Widgets/NoData';
import WarehouseMailOrderDialog from './WarehouseMailOrderDialog';



const handleWording = (typeKey) =>{
    switch(typeKey){
        case 4:
            return ['stornieren', 'Stornierung']
        case 5:
            return ['retournieren', 'Retoure']
        default:
            return ['stornieren', 'Stornierung']
    }
}

const WarehouseCorrectionMaterialDialog = (props) =>{

    const { open, onClose, getStockValues, getStockMaterial } = props
    const { enqueueSnackbar } =  useStyledSnackbar()

    const [typeKey, setTypeKey] = useState(6)
    const [supplierTxs, setSupplierTx] = useState([])
    const [suppliers, setSuppliers] = useState([])
    const [res, setRes] = useState(null)
    const [openDialog, setOpenDialog] = useState(false)


    const { handleSubmit, control, reset, watch,formState: { errors, isDirty, isValid }} = useForm({
        mode: 'onChange',
        defaultValues: {
            supplier: '',
            annotation: '',
        }
    })

    const supplier_id = watch('supplier')

    const getSuppliers = async () => {
        const res = await fetch('/api/company/supplier', {
            method: 'GET',
        })
        const _data = await res.json()
        if (res.ok) {
            const transformed = {}
            for (const supplier of _data) {
                transformed[supplier.id] = supplier.name + ' | ' + supplier.contact_person
            }
            setSuppliers(transformed)
        }
    }

    const handleOnClose = useCallback(() =>{
        reset()
        onClose()
        setSupplierTx([])
    },[reset])

    const handleValueChange = (e, idx, key) => {
        let data_ = [...supplierTxs]
        data_[idx][key] = e.target.value
        setSupplierTx(data_);
    };

    const handleTypeKey= (event, newType) => {
        if (newType !== null) {
            setTypeKey(newType);
        }
    };

    const removeTx = (idx) => {
        setSupplierTx(txs => {
            const _txs = [...txs]
            _txs.splice(idx, 1)
            return _txs
        })
    };

    const getSupplierTx = useCallback(async() =>{
        if(supplier_id === '') return
		const res = await fetch('/api/company/warehouse/tx/correction/' + supplier_id,{
            method: 'GET',
        })
        const _data = await res.json()
        if(res.ok){

            let _tx = []
            _data.forEach(tx => {
                tx.correction_value =  tx.value
                _tx.push(tx)
            });
            setSupplierTx(_tx)
            console.log(_tx)
        }
    },[supplier_id])

    const handleCorrectionTx = async(data) =>{
        setOpenDialog(true)
        const json = JSON.stringify({
            ...data,
            type_key: typeKey,
            correction_txs: supplierTxs
        })
		const res = await fetch('/api/company/warehouse/tx/correction/'  + supplier_id, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'X-CSRFToken': getCookie('csrftoken')
            },
            body: json
        })
        const _data = await res.json()
        if(res.ok){
            setRes(_data)
            getStockValues()
            getStockMaterial()

            enqueueSnackbar(`Die ${handleWording(typeKey)[1]} wurde erfolgreich durchgefürht!`, {
                variant: 'success'
            })
        } else {
            Object.entries(_data).forEach(([field, message]) => {
                enqueueSnackbar(`${field}: ${message} `, {
                    variant: 'error'
                })
            })
            setOpenDialog(false)
        }
    }

    useEffect(()=>{
        getSuppliers()
    },[])

    useEffect(()=>{
        getSupplierTx()
    },[getSupplierTx])

    useEffect(()=>{
        if(openDialog === false){
            handleOnClose()
        }
    },[openDialog, handleOnClose])

    return(
        <Dialog
            open={open}
            onClose={handleOnClose}
            maxWidth="laptop"
            fullWidth
        >
            <DialogTitle>
                {`Material ${handleWording(typeKey)[0]}`}
            </DialogTitle>
            <DialogContent>
                <DialogContentText>
                    Hier kannst du die bestellten Materialien stonieren bzw.retournieren. Wähle den Lieferanten aus, den es betrifft.
                </DialogContentText>
                <Stack
                    spacing={2}
                    component="form"
                    id='order-form'
                    onSubmit={handleSubmit(handleCorrectionTx)}
                    sx={{ mt:2, mb: 2 }}
                >
                    <ControlledSelect
                        control={control}
                        errors={errors}
                        rules={{required: true}}
                        name="supplier"
                        label="Lieferant auswählen"
                        items={suppliers}
                    />
                    <Box sx={{ width: "100%", height: 420, overflow: 'auto'}}>
                        {supplierTxs.length !== 0 ?
                            supplierTxs.map((tx, idx) => {
                                return(
                                    <Box key={`tx-list-item${idx}`}>
                                        <ListItem>
                                            <Stack
                                                direction='row'
                                                justifyContent="space-between"
                                                alignItems='center'
                                                width="100%"
                                                gap={2}
                                            >
                                                <TxInfoStack tx={tx}/>
                                                <Stack
                                                    direction={'row'}
                                                    justifyContent='center'
                                                    alignItems="center"
                                                    spacing={2}
                                                >
                                                    <TextField
                                                        placeholder={"0"}
                                                        type="number"
                                                        label="Menge"
                                                        size="small"
                                                        InputProps={{
                                                            endAdornment: <InputAdornment position="end">{tx.unit?.split('/')[1]}</InputAdornment>,
                                                        }}
                                                        sx={{ width: 150}}
                                                        value={tx.correction_value}
                                                        onChange={(e) => handleValueChange(e, idx, 'correction_value')}
                                                    />
                                                    <IconButton onClick={()=> removeTx(idx)}>
                                                        <Clear/>
                                                    </IconButton>
                                                </Stack>
                                            </Stack>
                                        </ListItem>
                                        {supplierTxs.length > idx + 1 && <Divider flexItem/>}
                                    </Box>
                                )
                            })
                        :
                            <NoData text="Keine Bestelltransaktionen vorhanden..."/>
                        }
                    </Box>
                    <ToggleButtonGroup
                        color="primary"
                        value={typeKey}
                        exclusive
                        onChange={handleTypeKey}
                        fullWidth
                        sx={{ height: 40 , mb: 0.45}}
                    >
                        <ToggleButton value={4}>Stonierung</ToggleButton>
                        <ToggleButton value={5}>Retoure</ToggleButton>
                    </ToggleButtonGroup>
                    <ControlledTextField
                        control={control}
                        errors={errors}
                        name="annotation"
                        label={`Anmerkung zur ${handleWording(typeKey)[1]}`}
                        rules={{required: true}}
                        rows={3}
                        multiline
                    />
                </Stack>
                <Box>
                    <Typography variant="text_s">{supplierTxs.length} Artikel in der Auswahl</Typography>
                </Box>
            </DialogContent>
            <DialogActions>
                <Button
                    color="secondary"
                    onClick={handleOnClose}
                >
                    Abbrechen
                </Button>
                <Button
                    type="submit"
                    form="order-form"
                    disabled={!isDirty || !isValid || openDialog}
                >
                    {handleWording(typeKey)[0]}
                </Button>
            </DialogActions>
            <WarehouseMailOrderDialog
                open={openDialog}
                setOpen={setOpenDialog}
                res={res}
                setRes={setRes}
            />
        </Dialog>
    )
}

export default WarehouseCorrectionMaterialDialog