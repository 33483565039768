import { useState } from "react"
import { Dialog, DialogContent, DialogTitle, FormControlLabel, FormGroup, IconButton, Stack, Switch } from "@mui/material"
import { Clear } from "@mui/icons-material"
import {DataGridPro} from "@mui/x-data-grid-pro";


import { PALETTE } from "../../../../theme"
import CustomDataGrid from "../../CustomDataGrid";
import Toolbar from "../Toolbar";


const DialogPaginationDataGrid = (props) =>{

    const {
        open,
        onClose,
        apiRef,
        title,
        subtitle,
        rows,
        columns,
        pageSize,
        onPageSizeChange,
        onPageChange,
        rowCount,
        loading,
        actionBar,
        errors={},
        onDeleteRow,
        onSaveRow,
        rowHeight=52
    } = props

    const [fullScreen, setFullScreen] = useState(true);

    return(
        <Dialog
            open={open}
            onClose={onClose}
            fullScreen={fullScreen}
            maxWidth={'desktop'}
            fullWidth={true}
        >
            <Stack
                direction='row'
                justifyContent='space-between'
                alignItems="center"
                sx={{ p: 2 }}
            >
                <DialogTitle>
                    {title}
                </DialogTitle>
                <Stack
                    direction='row'
                    alignItems='center'
                    spacing={2}
                >
                    <FormGroup>
                        <FormControlLabel
                            control={
                                <Switch
                                    checked={fullScreen}
                                    onChange={()=> setFullScreen(!fullScreen)}
                                    color="primary"
                                />
                            }
                            label="Große Ansicht"
                        />
                    </FormGroup>
                    <IconButton
                        color="secondary"
                        onClick={onClose}
                        size="large"
                    >
                        <Clear fontSize="large"/>
                    </IconButton>
                </Stack>
            </Stack>
            <DialogContent
                width="100%"
                sx={{
                    border: 1,
                    borderRadius: '10px',
                    borderColor: PALETTE.background.light70,
                    mr:2,
                    ml:2,
                    mb:2,
                    p: 0,
                }}
            >
                <CustomDataGrid
                    autoHeight={false}
                    rows={rows}
                    columns={columns}
                    apiRef={apiRef}
                    Component={DataGridPro}
                    loading={loading}
                    errors={errors}
                    Toolbar={Toolbar}
                    toolbarProps={{
                        apiRef: apiRef,
                        title: subtitle,
                        actionBar: actionBar
                    }}
                    initialPageSize={25}
                    pageSize={pageSize}
                    onPageSizeChange={onPageSizeChange}
                    onPageChange={onPageChange}
                    rowCount={rowCount}
                    onDeleteRow={onDeleteRow}
                    onSaveRow={onSaveRow}
                    rowHeight={rowHeight}
                />
            </DialogContent>
        </Dialog>
    )
}

export default DialogPaginationDataGrid