import { Stack, Button, Box, Typography } from '@mui/material'
import { BsFillFileEarmarkFill } from 'react-icons/bs'

import img from '../../../assets/svgs/file_upload.svg'

const FileUploaderMultiple = ({ selectedFiles, setSelectedFiles, width=200, disabled=false }) =>{

    return(
        <Stack
            justifyContent='center'
            alignItems='center'
            spacing={2}
        >
            {selectedFiles.length > 0 ?
                <Typography sx={{ p: 2 }}>
                    {Array.from(selectedFiles).map(f => f.name).join(', ')}
                </Typography>
            :
                <Box
                    component="img"
                    width={width}
                    src={img}
                    sx={{
                        borderRadius: '5px'
                    }}
                />
            }
            <label width="100%" htmlFor="video-uplaod-image">
                    <input
                        id="video-uplaod-image"
                        type="file"
                        hidden
                        multiple='multiple'
                        onChange={e => setSelectedFiles(e.target.files)}
                        disabled={disabled}
                    />
                    <Button
                        variant="outlined"
                        color="primary"
                        component="span"
                        startIcon={<BsFillFileEarmarkFill />}
                        sx={{width: width}}
                        disabled={disabled}
                    >
                        Datei hochladen
                    </Button>
            </label>
            {/* <Typography variant="h6">{selectedFile?.name}</Typography> */}

        </Stack>
    )
}

export default FileUploaderMultiple
