import { useState, useEffect } from 'react'

import { Avatar, Box, Chip, Stack, Typography } from '@mui/material'

import ControlledSelect from './ControlledSelect'

const CompanyMemberMultiSelection = ({ control, errors, name, label, multiple=false, rules }) => {

    const [companyMembers, setCompanyMembers] = useState([])

    const getCompanyMembers = async () => {
        const res = await fetch('/api/company/members', {
            method: 'GET'
        })
        const json = await res.json()
        if (res.ok) {
            const transformed = {}
            for (const cm of json) {
                transformed[cm.id] = cm
            }
            // console.log(transformed)
            setCompanyMembers(transformed)
        }
    }

    useEffect(() => {
        getCompanyMembers()
    }, [])

    return (
        <ControlledSelect
            control={control}
            errors={errors}
            name={name}
            label={label}
            items={companyMembers}
            multiple={multiple}
            rules={rules}
            renderValue={multiple ?
                (selected) => (
                    <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                        {selected.map((key) => (
                            <Chip key={key} label={companyMembers[key].firstname + ' ' + companyMembers[key].lastname} />
                        ))}
                    </Box>
                )
                :
                null
            }
            itemRender={item =>
                <Stack gap={2} direction='row' alignItems='center'>
                    <Avatar alt='' src={item.avatar} />
                    <Typography>{item.firstname} {item.lastname}</Typography>
                </Stack>
            }
        />
    )
}

export default CompanyMemberMultiSelection