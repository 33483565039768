import { useState, useCallback, useMemo } from 'react';
import Dropzone from 'react-dropzone'
import Papa from 'papaparse'

import { Button, FormControl, InputLabel, MenuItem, Select, Stack, Typography } from '@mui/material';
import { PALETTE } from 'components/theme';
import NoData from '../NoData';
import { useEffect } from 'react';

const baseStyle = {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: '35px',
    borderWidth: 1,
    borderRadius: 10,
    borderColor: PALETTE.background.light20,
    borderStyle: 'dashed',
    backgroundColor: PALETTE.background.content,
    outline: 'none',
    transition: 'border .24s ease-in-out',
    cursor: 'pointer'
};

const acceptStyle = {
    borderColor: PALETTE.primary.main
};

const rejectStyle = {
    borderColor: PALETTE.error.main
};

const encodingOptions = [
    'utf-8',
    'iso8859-1',
    "windows-1251",
    "windows-1252",
]

const delimiterOptions = [
    ';',
    ',',
]

const quoteCharOptions = [
    "kein Zeichen",
    '"',
    "'",
]

const CSVReader = (props) => {

    const { setCsvData } = props
    const [file_, setFile_] = useState(null)
    const [prevData, setPrevData] = useState([])
    const [encoding, setEncoding] = useState(encodingOptions[0]);
    const [delimiter, setDelimiter] = useState(delimiterOptions[0]);
    const [quoteChar, setQuoteChar] = useState(quoteCharOptions[1]);
    const [isAccepted, setIsAccept] = useState(false)
    const [isRejected, setIsRejected] = useState(false)

    const handleEncodingThanPreview = (event) => {
        const _encoding = event.target.value
        setEncoding(_encoding);
    }

    const handleDelimiterThanPreview = (event) => {
        const _delimiter = event.target.value
        setDelimiter(_delimiter);
    }

    const handleQuoteCharThanPreview = (event) => {
        const _quoteChar = event.target.value
        if(_quoteChar === quoteCharOptions[0]){
            setQuoteChar('')
        } else {
            setQuoteChar(_quoteChar);
        }
    }

    useEffect(() =>{
        const handlePreview = () => {
            if(file_ !== null){
                Papa.parse(file_, {
                    complete: function (results) {
                        setPrevData(results.data)
                    },
                    header: false,
                    encoding: encoding,
                    delimiter: delimiter,
                    quoteChar: quoteChar,
                    dynamicTyping: true,
                    preview: 20,
                    skipEmptyLines: true,
                });
            }
        }
        handlePreview()
    }, [encoding, delimiter, quoteChar])


    const handleEncoding = () => {
        if(file_ !== null){
            Papa.parse(file_, {
                complete: function (results) {
                    setCsvData(results.data)
                },
                header: false,
                encoding: encoding,
                dynamicTyping: true,
                delimiter: delimiter,
                quoteChar: quoteChar,
                preview: 0,
                skipEmptyLines: true,
            });
        }
    };

    const onDrop = useCallback((acceptedFiles) => {
        acceptedFiles.forEach((file) => {
            setFile_(file)
            Papa.parse(file, {
                complete: function (results) {
                    setPrevData(results.data)
                },
                header: false,
                encoding: encoding,
                dynamicTyping: true,
                delimiter: delimiter,
                quoteChar: quoteChar,
                preview: 20,
                skipEmptyLines: true,
            });
            setIsAccept(true)
            setIsRejected(false)
        })
    }, [encoding, delimiter, quoteChar])


    const style = useMemo(() => ({
        ...baseStyle,
        ...(isAccepted ? acceptStyle : {}),
        ...(isRejected ? rejectStyle : {})
    }), [
        isAccepted,
        isRejected
    ]);

    return (
        <Stack
            gap={3}
            sx={{flex:1, maxWidth: 900}}
        >
            <Dropzone
                onDrop={onDrop}
                autoFocus
                disabled={false}
                multiple={false}
                onDropRejected={() => {
                    setIsRejected(true)
                    setIsAccept(false)
                }}
                accept={{
                    'text/csv': ['.csv']
                }}
            >
                {({ getRootProps, getInputProps }) => (
                    <section>
                        <div {...getRootProps(({ style }))}>
                            <input {...getInputProps()} />
                            <Typography variant='text_m'>Ziehe dein Angebot als CSV-Datei rein oder klick drauf..</Typography>
                            {isAccepted &&
                                <Typography variant="subtitle_m" color="primary">Datei akzeptiert!</Typography>
                            }
                            {isRejected &&
                                <Typography variant="subtitle_m" color="error">Das Datei-Format wir nicht akzeptiert!</Typography>
                            }
                        </div>
                    </section>
                )}
            </Dropzone>
            <Stack
                direction="row"
                gap={2}
            >
                <FormControl  sx={{flex: 1}}>
                    <InputLabel>Kodierung auswählen</InputLabel>
                    <Select
                        value={encoding}
                        label="Kodierung auswählen"
                        onChange={handleEncodingThanPreview}
                    >
                        {encodingOptions.map((option, idx)=>(
                            <MenuItem key={`encoding-option-${idx}`} value={option}>{option}</MenuItem>
                        ))}
                    </Select>
                </FormControl>
                <FormControl sx={{flex: 1}}>
                    <InputLabel>Trennzeichen auswählen</InputLabel>
                    <Select
                        value={delimiter}
                        label="Trennzeichen auswählen"
                        onChange={handleDelimiterThanPreview}
                    >
                        {delimiterOptions.map((option, idx)=>(
                            <MenuItem key={`delimiter-option-${idx}`} value={option}>{option}</MenuItem>
                        ))}
                    </Select>
                </FormControl>
                <FormControl sx={{flex: 1}}>
                    <InputLabel>Textzeichen auswählen</InputLabel>
                    <Select
                        value={quoteChar}
                        label="Textzeichen auswählen"
                        onChange={handleQuoteCharThanPreview}
                    >
                        {quoteCharOptions.map((option, idx)=>(
                            <MenuItem key={`quotechar-option-${idx}`} value={option}>{option}</MenuItem>
                        ))}
                    </Select>
                </FormControl>
            </Stack>
            <Stack
                gap={0.5}
            >
                <Typography variant='subtitle_m'>
                    Vorschau
                </Typography>
                <Stack
                    sx={{
                        p:2,
                        border: 1,
                        borderRadius: '7px',
                        borderColor: PALETTE.background.light70,
                        height: 400,
                        maxWidth: '100%',
                        overflow: 'auto'
                    }}
                >
                    {prevData.length !== 0 ?
                        prevData.map((row, idx)=>{
                            const firstRow = (idx === 0)
                            return(
                                <Stack
                                    key={`preview-row-${idx}`}
                                    direction="row"
                                    justifyContent="flex-start"
                                    alignItems="center"
                                    gap={2}
                                >
                                    {row.map((e, _idx)=>{
                                        return(
                                            <Typography
                                                key={`preview-item-${_idx}`}
                                                variant='text_m'
                                                sx={{
                                                    color: firstRow ? PALETTE.primary.main: 'inherit',
                                                    fontWeight: firstRow ? 'bold': '400',
                                                    mb: firstRow ? 1 : 0,
                                                    minWidth: 200
                                                }}
                                            >
                                                    {e}
                                            </Typography>
                                        )
                                    })}
                                </Stack>
                            )
                        })
                    :
                        <NoData text="Keine CSV-Datei vorhanden..."/>
                    }
                </Stack>
            </Stack>
            <Button
                variant="outlined"
                onClick={handleEncoding}
            >
                Akzeptieren
            </Button>
        </Stack>
    );
}

export default CSVReader;