import { useState } from 'react';

import { Stack, IconButton, Tooltip, Link, } from '@mui/material';
import { GridToolbarContainer, GridToolbarExport } from '@mui/x-data-grid';
import { Add, Category, Download, Factory } from '@mui/icons-material';

import CategoryDialog from './CategoryDialog';
import ManufacturDialog from './ManufacturerDialog.jsx/index.jsx';
import AddMaterialDialog from './AddMaterialDialog.jsx';


const CustomEditToolbar = (props) => {

    const { getMaterialCategories, selectionCategories, categories, units, manufacturers, addCompanyMaterial, getMaterialManufacturers, getMaterial } = props;

    const [openAdd, setOpenAdd] = useState(false)
    const [openCat, setOpenCat] = useState(false)
    const [openManu, setOpenManu] = useState(false)

    return (
        <Stack>
            <Stack
                direction={'row'}
                spacing={1}
            >
                <Tooltip
                    title="Neues Material anlegen"
                >
                    <IconButton
                        color="primary"
                        onClick={() => setOpenAdd(true)}
                    >
                        <Add variant="inherit"/>
                    </IconButton>
                </Tooltip>
                <Tooltip
                    title="Kategorien verwalten"
                >
                    <IconButton
                        color="primary"
                        onClick={()=> setOpenCat(true)}
                    >
                        <Category variant="inherit"/>
                    </IconButton>
                </Tooltip>
                <Tooltip
                    title="Hersteller verwalten"
                >
                    <IconButton
                        color="primary"
                        onClick={()=> setOpenManu(true)}
                    >
                        <Factory variant="inherit"/>
                    </IconButton>
                </Tooltip>
				<Tooltip
					title="Materialdaten exportieren"
				>
					<Link
						href={`/api/company/material/database/export`}
						download
					>
						<IconButton
							color="primary"
						>
							<Download/>
						</IconButton>
					</Link>
				</Tooltip>
            </Stack>
            <AddMaterialDialog
                open={openAdd}
                onClose={() => setOpenAdd(false)}
                selectionCategories={selectionCategories}
                manufacturers={manufacturers}
                units={units}
                addCompanyMaterial={addCompanyMaterial}
            />
            <CategoryDialog
                open={openCat}
                onClose={() => setOpenCat(false)}
                categories={categories}
                getMaterialCategories={getMaterialCategories}
                getMaterial={getMaterial}
            />
            <ManufacturDialog
                open={openManu}
                onClose={() => setOpenManu(false)}
                manufacturers={manufacturers}
                getMaterialManufacturers={getMaterialManufacturers}
                getMaterial={getMaterial}
            />
        </Stack>
    );
}

export default CustomEditToolbar
