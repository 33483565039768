import theme from 'components/theme';
import CustomPage from '../../Widgets/CustomPage'
import FullWorkplanner from "./FullWorkplanner";


const WorkPlanner = () => {
    return (
        <CustomPage
            title={<>Arbeitsplanung<sup style={{ color: theme.palette.secondary.main, fontSize: 18 }}> BETA</sup></>}
        >
            <FullWorkplanner/>
        </CustomPage>
    )
}

export default WorkPlanner
