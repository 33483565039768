import { useState, useEffect } from 'react';

import { Stack } from '@mui/material';
import { Delete, Edit, Save, Cancel } from '@mui/icons-material';
import { useGridApiRef, GridActionsCellItem } from '@mui/x-data-grid-pro';

import { getCookie } from "../../../utils/getCookie";

import CustomPage from '../../Widgets/CustomPage'
import CustomSupplierToolbar from './CustomSupplierToolbar';
import PaperDataGrid from "../../Widgets/CustomDatagrids/NoPagination/PaperDataGrid";

const getAddress = key => params => {
    return params.row.address[key]
}

const CompanySupplier = () => {

    const apiRef = useGridApiRef();
    const [suppliers, setSuppliers] = useState([])
    const [isLoaded, setIsLoaded] = useState(false)
    const [errors, setErrors] = useState({});

    const getSuppliers = async () => {
        const res = await fetch('/api/company/supplier', {
            method: 'GET',
        })
        if (res.ok) {
            const data = await res.json()
            setSuppliers(data)
            setIsLoaded(true)
            console.log(data)
        }
    }

    useEffect(()=>{
        getSuppliers()
    }, [])


	const handleSave = async (row) => {
		editSupplier(row)
  	};

    const editSupplier = async(row) =>{
		const json = JSON.stringify({
            ...row,
            address: {
                street: row.street,
                house_number: row.house_number,
                add_on: row.add_on,
                postal_code: row.postal_code,
                city: row.city
            }
        })
        const res = await fetch('/api/company/supplier', {
            method: 'PATCH',
            headers: {
                'Content-Type': 'application/json',
                'X-CSRFToken': getCookie('csrftoken')
            },
            body: json
        })
		const data = await res.json()
		if(res.ok){
			apiRef.current.updateRows([{ ...data, isNew: false }]);
            setErrors({})
		} else{
			console.log(data)
            setErrors(data)
		}
    }


	const handleDelete = async(rowId) => {
        const json = JSON.stringify({supplier_id: rowId})
        const res = await fetch('/api/company/supplier', {
            method: 'DELETE',
            headers: {
                'Content-Type': 'application/json',
                'X-CSRFToken': getCookie('csrftoken')
            },
            body: json
        })
		if(res.ok){
			apiRef.current.updateRows([{ id: rowId, _action: 'delete' }]);
		}
    }

    const columns = [
        {
			field: 'id',
			headerName: 'Lieferanten-ID',
			type: 'number',
			minWidth: 100,
            hide: true,
		},
		{
			field: 'name',
			headerName: 'Name',
			type: 'string',
			minWidth: 200,
            editable: true,
		},
        {
			field: 'contact_person',
			headerName: 'Ansprechpartner',
			type: 'string',
			minWidth: 200,
            editable: true,
		},
		{
			field: 'email',
			headerName: 'E-Mail',
			type: 'string',
            flex:'1',
			minWidth: 150,
            editable: true,
		},
		{
			field: 'street',
			headerName: 'Straße',
			type: 'string',
			minWidth: 200,
            editable: true,
            valueGetter: getAddress('street'),
		},
		{
			field: 'house_number',
			headerName: 'Hausnr.',
			type: 'string',
			minWidth: 30,
            editable: true,
            valueGetter: getAddress('house_number'),
		},
        {
			field: 'add_on',
			headerName: 'Zusatz',
			type: 'string',
			minWidth: 20,
            editable: true,
            valueGetter: getAddress('add_on'),
		},
        {
			field: 'postal_code',
			headerName: 'PLZ',
			type: 'string',
			minWidth: 50,
            editable: true,
            valueGetter: getAddress('postal_code'),
		},
        {
			field: 'city',
			headerName: 'Stadt',
			type: 'string',
			minWidth: 200,
            editable: true,
            valueGetter: getAddress('city'),
		},

	]

	const getConfirmMessage = row => `Möchtest du den Lieferanten ${row.name} wirklich löschen?`

    return(
        <CustomPage title='Lieferanten'>
            <Stack spacing={3}>
                <PaperDataGrid
                    title="Lieferantenverzeichnis"
                    rows={suppliers}
                    columns={columns}
                    apiRef={apiRef}
                    actionBar={<CustomSupplierToolbar apiRef={apiRef} getSuppliers={getSuppliers} />}
                    loading={!isLoaded}
                    errors={errors}
					onSaveRow={handleSave}
					onDeleteRow={handleDelete}
					getConfirmMessage={getConfirmMessage}
                />
            </Stack>
        </CustomPage>
    )
}

export default CompanySupplier
