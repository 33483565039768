export const MARKS = [
    {
        value: 1,
        label: 'Priorität 1',
    },
    {
        value: 25,
        label: 'Priorität 25',
    },
    {
        value: 50,
        label: 'Priorität 50',
    },
    {
        value: 75,
        label: 'Priorität 75',
    },
    {
        value: 100,
        label: 'Priorität 100',
    }
];

export const ACTIONS = {
    OPEN_TICKET_INFO: 'open-ticket-info',
    CLOSE_TICKET_INFO: 'close-ticket-info',
    OPEN_TICKET_EDIT: 'open-ticket-edit',
    CLOSE_TICKET_EDIT: 'close-ticket-edit',
    OPEN_TICKET_ADD: 'open-ticket-add',
    CLOSE_TICKET_ADD: 'close-ticket-add',
    OPEN_TICKET_DELETE: 'open-ticket-delete',
    CLOSE_TICKET_DELETE: 'close-ticket-delete',
    OPEN_TICKET_SHARE: 'open-ticket-share',
    CLOSE_TICKET_SHARE: 'close-ticket-share',
    OPEN_TICKET_STATE_CHANGE: 'open-ticket-state-change',
    CLOSE_TICKET_STATE_CHANGE: 'close-ticket-state-change',
    OPEN_TICKET_CHAIN_ADD: 'open-ticket-chain-add',
    CLOSE_TICKET_CHAIN_ADD: 'close-ticket-chain-add',
    OPEN_TICKET_CHAIN_DELETE: 'open-ticket-chain-delete',
    CLOSE_TICKET_CHAIN_DELETE: 'close-ticket-chain-delete',
}

export const handleTicketFilter = (filter, ticket_line_container_array) => {
    let filter_ticket_line_container_array = ticket_line_container_array
    switch (filter){
        case 'all':
            break;
        case 'get':
            filter_ticket_line_container_array = ticket_line_container_array.filter(x => x.external !== false)
            break;
        case 'post':
            filter_ticket_line_container_array = ticket_line_container_array.filter(x => x.external !== true)
            break;
        default:
            break;
    }
    return filter_ticket_line_container_array
}

export const handleTicketFilterInfo = (filter) => {
    let filter_info_str = 'Keine Tickets'
    switch (filter){
        case 'all':
            break;
        case 'get':
            filter_info_str = 'Keine Tickets erhalten'
            break;
        case 'post':
            filter_info_str = 'Keine Tickets vergeben'
            break;
        default:
            break;
    }
    return filter_info_str
}


