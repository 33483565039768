import { TextField, Typography, Fade, Stack, Avatar, Box } from "@mui/material";
import { DatePicker } from '@mui/x-date-pickers-pro';

import { BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ReferenceLine } from 'recharts';

import useWidth from '../../../../hooks/useWidth'
import { PALETTE } from "../../../theme";
import CustomPaper from "../../../Widgets/CustomPaper";

import { formatDate, secondsToTime } from "utils/date_functions";
import { addYears } from "date-fns";


const TimeRecordingChart = ({ timechartdata, selectedDate, setSelectedDate, companyMember }) => {
    const [ width, ref ] = useWidth()

    const renderCustomXColorLabel = (props) => {
        const { x, y, payload } = props;
        const date = new Date(payload.value)
        const weekDay = date.getDay()

        return (
            <g transform={`translate(${x},${y})`}>
                <text x={0} y={0} dy={16} textAnchor="end" fill={weekDay === 6 ||  weekDay === 0 ? PALETTE.background.light70 : PALETTE.background.light10} transform="rotate(-40)">
                    {date.toLocaleString('de-DE', {weekday: 'short', day:'2-digit', month: '2-digit'})}
                </text>
            </g>
        );
    }

    return(
        <CustomPaper
            title="Monatsüberblick"
            actionBar={
                <Stack
                    direction='row'
                    sx={{pt:2}}
                    spacing={3}
                >
                     {companyMember != null &&
                        <Stack
                            direction='row'
                            justifyContent='center'
                            alignItems='center'
                            spacing={2}
                        >
                            <Avatar
                                alt="user avatar"
                                src={companyMember.avatar}
                                sx={{ width: 45, height: 45}}
                            />
                            <Typography variant="subtitle_m" color="background.light20">{companyMember.firstname} {companyMember.lastname}</Typography>
                        </Stack>
                     }
                    <DatePicker
                        views={['year', 'month']}
                        label="Zeiterfassung vom"
                        minDate={new Date('2021-01-01')}
                        maxDate={addYears(new Date(), 2)}
                        value={selectedDate}
                        onChange={(newDate) => {
                            setSelectedDate(newDate);
                        }}
                        renderInput={(params) => <TextField {...params} helperText={null} />}
                    />
                </Stack>
            }
        >
            <Fade
                in={timechartdata !== null ? true : false}
                timeout={250}
            >
                <Stack
                    alignItems='flex-end'
                    spacing={2}
                    width='100%'
                    ref={ref}
                >
                    <Box sx={{ height: 500, width: width }}>
                        <BarChart
                            width={width}
                            height={500}
                            data={timechartdata}
                            margin={{
                                top: 5,
                                right: 0,
                                left: 0,
                                bottom: 5,
                            }}
                        >
                            <CartesianGrid strokeDasharray="5 5" />
                            <Legend
                                verticalAlign="top"
                                align='right'
                                layout='vertical'
                                iconSize={10}
                                iconType='circle'
                                wrapperStyle={{ paddingLeft: 15 }}
                            />
                            <XAxis dataKey="date" interval={0} height={80} textAnchor="end" tick={renderCustomXColorLabel}/>
                            <YAxis unit=" Std." domain={[0, 12]}/>
                            <Tooltip
                                labelFormatter={(label) => `Datum: ${formatDate(new Date(label))}`}
                                filterNull={true}
                                cursor={{ fill: PALETTE.secondary.main , opacity: "0.2"}}
                                contentStyle={{borderRadius: 10, padding: 10}}
                                labelStyle={{borderBottom: `1px solid ${PALETTE.background.main}`, padding: 2}}
                                formatter={(value, name, props) => `${secondsToTime(value * 3600)}`}
                            />
                            <Bar dataKey="Normalstunden" stackId='a' fill={PALETTE.primary.dark}/>
                            <Bar dataKey="Regiestunden" stackId='a' fill={PALETTE.primary.dark}/>
                            <Bar dataKey="Pause"  stackId='a' fill={PALETTE.primary.light}/>
                            <Bar dataKey="Krankheit"  stackId='a' fill={PALETTE.error.main}/>
                            <Bar dataKey="Urlaub" stackId='a' fill={PALETTE.secondary.main}/>
                            <Bar dataKey="Schlechtwetter"  stackId='a' fill={PALETTE.background.light5}/>
                            <Bar dataKey="Kurzarbeit"  stackId='a' fill={PALETTE.background.light10}/>
                            <Bar dataKey="Feiertag"  stackId='a' fill={PALETTE.background.light45}/>
                            <Bar dataKey="Schule"  stackId='a' fill={PALETTE.background.light45}/>
                            <Bar dataKey="Bildungsurlaub"  stackId='a' fill={PALETTE.background.light45}/>
                            <Bar dataKey="Sonstiges"  stackId='a' fill={PALETTE.background.light45}/>
                            <ReferenceLine y={8} label={{position: 'left',  value:'8 Std.', fill: PALETTE.secondary.light, fontSize: 20 }} strokeWidth={2} stroke={PALETTE.secondary.light}/>
                        </BarChart>
                    </Box>
                </Stack>
            </Fade>
        </CustomPaper>
    )
}

export default TimeRecordingChart