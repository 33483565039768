import { Stack, Divider } from "@mui/material"

const CustomDivider = ({ height }) =>{
    return(
        <Stack
            justifyContent='center'
            height={height}
        >
            <Divider/>
        </Stack>
    )
}

export default CustomDivider