import { Stack, Button, Box } from '@mui/material'
import { PhotoCamera } from '@mui/icons-material'

import img from '../../../assets/svgs/image_upload.svg'

const ImageUploader = ({ initialImg=null, selectedFile, setSelectedFile, width=200 }) =>{

    return(
        <Stack
            justifyContent='center'
            alignItems='center'
            spacing={2}
            sx={{
                height: "100%",
            }}
        >
            <Box
                component="img"
                width={width}
                src={ selectedFile ? URL.createObjectURL(selectedFile) : initialImg ? initialImg : img}
                sx={{
                    borderRadius: '8px',
                }}
            />
            <label width="100%" htmlFor="button-uplaod-image">
                    <input
                        id="button-uplaod-image"
                        type="file"
                        hidden
                        onChange={e => setSelectedFile(e.target.files[0])}
                        accept={'.png,.jpg,.jpeg,.PNG,.JPG,.JPEG'}
                    />
                    <Button
                        variant="outlined"
                        color="primary"
                        component="span"
                        startIcon={<PhotoCamera />}
                        sx={{width: width}}
                    >
                        Bild auswählen
                    </Button>
            </label>
            {/* <Typography variant="h6">{selectedFile?.name}</Typography> */}

        </Stack>
    )
}

export default ImageUploader
