import { Paper, Stack, Typography } from "@mui/material"


const TimeRecordingInfoBar = ({data: { vacation_days, Urlaub, Krankheit, Schlechtwetter, Kurzarbeit }}) => {

    const vacation_days_= vacation_days !== undefined ? vacation_days : 0
    const Urlaub_ = Urlaub !== undefined ? Urlaub : 0
    const Krankheit_ = Krankheit !== undefined ? Krankheit : 0
    const Schlechtwetter_ = Schlechtwetter !== undefined ? Schlechtwetter : 0
    const Kurzarbeit_ = Kurzarbeit !== undefined ? Kurzarbeit : 0

    return(
        <Paper>
            <Stack
                direction="row"
                justifyContent="space-evenly"
                alignItems="center"
                sx={{pb: 3, pt: 3}}
                gap={2}
            >
                <Stack
                    justifyContent="center"
                    alignItems="center"
                    gap={0.5}
                >
                    <Typography variant="value_highlight" color="secondary.main">{`${Urlaub_} / ${vacation_days_}`}</Typography>
                    <Typography variant="subtitle_s">Urlaubstage</Typography>
                </Stack>
                <Stack
                    justifyContent="center"
                    alignItems="center"
                    gap={0.5}
                >
                    <Typography variant="value_highlight" color="error.main">{`${Krankheit_}`}</Typography>
                    <Typography variant="subtitle_s">Krankheitstage</Typography>
                </Stack>
                <Stack
                    justifyContent="center"
                    alignItems="center"
                    gap={0.5}
                >
                    <Typography variant="value_highlight" color="background.light10">{`${Schlechtwetter_}`}</Typography>
                    <Typography variant="subtitle_s">Schlechtwettertage</Typography>
                </Stack>
                <Stack
                    justifyContent="center"
                    alignItems="center"
                    gap={0.5}
                >
                    <Typography variant="value_highlight" color="background.light20">{`${Kurzarbeit_}`}</Typography>
                    <Typography variant="subtitle_s">Kurzarbeitstage</Typography>
                </Stack>
            </Stack>
        </Paper>
    )
}

export default TimeRecordingInfoBar