import { useEffect } from "react";
import { useForm } from "react-hook-form";
import { getCookie } from "../../../../../utils/getCookie";
import { Box, Button, DialogContent, Dialog, DialogTitle, DialogActions, Stack } from "@mui/material";


import ControlledTextField from "../../../../Widgets/ControlledInputs/ControlledTextField";
import ControlledDateTimePicker from "../../../../Widgets/ControlledInputs/ControlledDateTimePicker";
import ControlledSelect from "../../../../Widgets/ControlledInputs/ControlledSelect";
import ControlledSlider from "../../../../Widgets/ControlledInputs/ControlledSlider";

const TicketEditDialog = (props) =>{

    const { open, onClose, ticket, project_id, getTicketChains, subCompanies } = props;

    const { control, handleSubmit, formState: { errors, isDirty, isValid }, reset, setValue} = useForm({
        mode: 'onChange',
        defaultValues: {
            title: '',
            instruction: '',
            service_provider: '',
            deadline: '',
            rank: 0,
        }
    })

    const handleEditTicket = async (data) => {
        if(ticket == null) return
        const json = JSON.stringify({
            ticket_id: ticket.id,
            ...data
        })
        const res = await fetch('/api/project/' + project_id + '/tickets', {
            method: 'PATCH',
            headers: {
                'Content-Type': 'application/json',
                'X-CSRFToken': getCookie('csrftoken')
            },
            body: json,
        })
        if (res.ok) {
            getTicketChains()
            onClose()
            reset()
        }
    }

    useEffect(() => {
        if(ticket == null) return
        for (const [key, value] of Object.entries(ticket)) {
            if(key === "service_provider"){
                // console.log(key, value.id.toString())
                setValue(key, value.id, { shouldValidate: true, shouldDirty: true })
                continue
            }
            setValue(key, value, { shouldValidate: true, shouldDirty: true })
        }
    }, [ticket, setValue])

    return(
        <Dialog
            open={open}
            onClose={onClose}
            maxWidth={'desktop'}
        >
            <Stack
                direction='row'
            >
                <Box
                    component="img"
                    height="100%"
                    src={ticket?.image}
                    alt="no image"
                    sx={{
                        maxHeight:900,
                        maxWidth:1200
                    }}
                />
                <Stack
                    sx={{
                        width:400
                    }}
                >
                    <DialogTitle>
                        Ticket bearbeiten
                    </DialogTitle>
                    <DialogContent sx={{flexGrow: 1}}>
                        <Stack
                            component='form'
                            id='edit-ticket-form'
                            onSubmit={handleSubmit(handleEditTicket)}
                            spacing={2}
                            sx={{mt:2}}
                        >
                            <ControlledTextField
                                name="title"
                                label="Titel"
                                control={control}
                                rules={{ required: true }}
                                errors={errors}
                            />
                            <ControlledTextField
                                name="instruction"
                                label="Beschreibung"
                                control={control}
                                rules={{ required: true }}
                                errors={errors}
                                multiline
                                rows={4}
                            />
                            <ControlledSlider
                                name="rank"
                                control={control}
                                rules={{ required:  true }}
                                label="Priorität"
                                step={1}
                                min={1}
                                max={100}
                                // marks={MARKS}
                            />
                            <ControlledDateTimePicker
                                name="deadline"
                                label="Frist"
                                control={control}
                                rules={{ required:  true }}
                                errors={errors}
                            />
                            <ControlledSelect
                                name="service_provider"
                                label="Subunternehmer"
                                control={control}
                                rules={{ required: true}}
                                errors={errors}
                                items={subCompanies}
                            />
                        </Stack>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={onClose} color="secondary">Abbrechen</Button>
                        <Button
                            color="primary"
                            type="submit"
                            form="edit-ticket-form"
                            autoFocus
                            disabled={!isDirty || !isValid}
                        >
                            Speichern
                        </Button>
                    </DialogActions>
                </Stack>
            </Stack>
        </Dialog>
    )
}

export default TicketEditDialog