import { useContext, useEffect, useState } from 'react'
import { useForm } from "react-hook-form";

import { Box, Button, Grid, Typography, Stack, Collapse, Alert, AlertTitle, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Tooltip, IconButton, Divider } from "@mui/material";

import { MdFactCheck } from "react-icons/md";

import AccountContext from 'context/AccountContext';

import CustomPage from '../../../Widgets/CustomPage';
import CustomPaper from '../../../Widgets/CustomPaper';
import ControlledTextField from '../../../Widgets/ControlledInputs/ControlledTextField';
import ControlledSelect from '../../../Widgets/ControlledInputs/ControlledSelect';
import ControlledSwitch from '../../../Widgets/ControlledInputs/ControlledSwitch';
import ControlledDateRangePicker from '../../../Widgets/ControlledInputs/ControlledDateRangePicker';

import { getCookie } from '../../../../utils/getCookie'
import { getConstants } from '../../../../utils/getConstants'
import { autoHideDuration, checkSelectionZero } from '../../../../utils/helper'

import { PALETTE } from '../../../theme'
import CustomButton from '../../../Widgets/CustomButtons/CustomFormButton';



const ProjectAdd = () => {

    const [account] = useContext(AccountContext)
    const [loading, setLoading] = useState(false)
    const [success, setSuccess] = useState(false)
    const [openCheck, setOpenCheck] = useState(false)
    const [checkAddress, setCheckAddress] = useState({
        exists: false,
        count: 0,
        addresses: []
    })
    const [customers, setCustomers] = useState([])
    const [buisnessCustomers, setBuisnessCustomers] = useState([])

    const { control, handleSubmit, watch, reset,formState: { errors, isDirty, isValid } } = useForm({
        mode: 'onChange',
        defaultValues:{
            name: '',
            description: '',
            street: '',
            house_number: '',
            add_on: '',
            postal_code: '',
            city: '',
            visible: false,
            enable_C2C_chat: true,
            official: false,
            with_sub: true,
            at_cost: false,
            title: '',
            instruction: '',
            contract_number_service_provider:'',
            privat_customer_outside: '',
            buisness_customer_outside: '',
            type_key: '',
            max_material: '',
            max_workload: '',
            max_task: '',
            drive_rate: '',
            wage_rate: account.company.wage_rate,
            value: '',
            date_range_project: [null, null],
            date_range: [null, null],
        }
    });

    const addProject = async(data) => {
        setLoading(true)
        const json = JSON.stringify({
            ...data,
            privat_customer_outside: checkSelectionZero(data.privat_customer_outside),
            buisness_customer_outside: checkSelectionZero(data.buisness_customer_outside),
            address: {
                street: data.street,
                house_number: data.house_number,
                add_on: data.add_on,
                postal_code: data.postal_code,
                city: data.city
            }
		})
        const res = await fetch('/api/projects', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'X-CSRFToken': getCookie('csrftoken')
            },
            body: json
        })
        if (res.ok) {
            // const data = await res.json()
            setLoading(false)
            setSuccess(true)
            reset()
        } else{
            setLoading(false)
        }
    }

    const checkProjectAddress = async() =>{
        const json = JSON.stringify({
            address: {
                street: watch('street'),
                house_number: watch('house_number'),
                postal_code: watch('postal_code'),
                city: watch('city')
            }
		})
        const res = await fetch('/api/projects/check_address', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'X-CSRFToken': getCookie('csrftoken')
            },
            body: json
        })
        if (res.ok) {
            const data = await res.json()
            setCheckAddress(data)
            setOpenCheck(true)
        }
    }

    const [projectTypes, setProjectTypes]=  useState({})
    const callback_project_types = (c) =>{setProjectTypes(c)}
    useEffect(() =>{getConstants('project_types', callback_project_types)},[])

    const getCustomers = async () => {
        const res = await fetch('/api/company/customer', {
            method: 'GET',
        })
        const _data = await res.json()
        if (res.ok) {
            const transformed = {0 : "Nicht vorhanden"}
            for (const customer of _data) {
                transformed[customer.id] = customer.firstname + ' ' + customer.lastname
            }
            setCustomers(transformed)
        }
    }

    const getBuisnessCustomers = async() => {
        const res = await fetch('/api/company/buisness_customer', {
            method: 'GET',
        })
        const _data = await res.json()
        if (res.ok) {
            const transformed = { 0: "Nicht vorhanden"}
            for (const customer of _data) {
                transformed[customer.id] = customer.name
            }
            setBuisnessCustomers(transformed)
        }
    }

    useEffect(()=>{
        autoHideDuration(setSuccess, 5)
        getCustomers()
        getBuisnessCustomers()
    },[success])

    return(
        <CustomPage
            title="Erstelle dein eigenes Projekt"
        >
            <Box
                component="form"
                onSubmit={handleSubmit(addProject)}
            >
                <Grid container justifyContent="flex-start" alignItems="flex-start" spacing={3}>
                    <Grid item desktop={7} laptop={6} tablet={12} mobil={12}>
                        <Stack spacing={3}>
                            <CustomPaper
                                title="Projekt"
                            >
                                <Grid container justifyContent="center" alignItems="flex-start" spacing={2}>
                                    <Grid item desktop={6} labtop={6} tablet={6} mobil={12}>
                                        <Stack spacing={2}>
                                            <ControlledTextField
                                                name="name"
                                                control={control}
                                                label="Projektname"
                                                rules={{ required: true }}
                                                errors={errors}
                                            />
                                            <ControlledSelect
                                                name="type_key"
                                                control={control}
                                                label="Projektart"
                                                rules={{ required: true }}
                                                errors={errors}
                                                items={projectTypes}
                                            />
                                        </Stack>
                                    </Grid>
                                    <Grid item desktop={6} labtop={6} tablet={6} mobil={12}>
                                        <ControlledTextField
                                            name="description"
                                            control={control}
                                            label="Projektbeschreibung"
                                            rules={{ required: true }}
                                            errors={errors}
                                            multiline={true}
                                            rows={7.5}
                                        />
                                    </Grid>
                                </Grid>
                            </CustomPaper>
                            <CustomPaper
                                title="Projektauftrag"
                            >
                                <Stack gap={2}>
                                    <Grid container justifyContent="center" alignItems="flex-start" spacing={2}>
                                        <Grid item desktop={6} labtop={6} tablet={12} mobil={12}>
                                            <ControlledTextField
                                                name="title"
                                                control={control}
                                                label="Auftragsname"
                                                rules={{ required: watch('with_sub') }}
                                                errors={errors}
                                                disabled={!watch('with_sub')}
                                            />
                                        </Grid>
                                        <Grid item desktop={6} labtop={6} tablet={12} mobil={12}>
                                            <ControlledTextField
                                                name="contract_number_service_provider"
                                                control={control}
                                                label="Auftragsnummer (optional)"
                                                rules={{ required: false }}
                                                errors={errors}
                                                disabled={!watch('with_sub')}
                                            />
                                        </Grid>
                                        <Grid item desktop={6} labtop={12} tablet={12} mobil={12}>
                                            <ControlledSelect
                                                name="privat_customer_outside"
                                                control={control}
                                                label="Privatkunde (optional)"
                                                rules={{ required: false }}
                                                errors={errors}
                                                items={customers}
                                                disabled={!watch('with_sub')}
                                            />
                                        </Grid>
                                        <Grid item desktop={6} labtop={12} tablet={12} mobil={12}>
                                            <ControlledSelect
                                                name="buisness_customer_outside"
                                                control={control}
                                                label="Gewerbekunde (optional)"
                                                rules={{ required: false }}
                                                errors={errors}
                                                items={buisnessCustomers}
                                                disabled={!watch('with_sub')}
                                            />
                                        </Grid>
                                        <Grid item desktop={12} labtop={12} tablet={12} mobil={12}>
                                            <ControlledTextField
                                                name="instruction"
                                                control={control}
                                                label="Auftragsbeschreibung"
                                                rules={{ required: watch('with_sub') }}
                                                errors={errors}
                                                multiline={true}
                                                rows={4}
                                                disabled={!watch('with_sub')}
                                            />
                                        </Grid>
                                    </Grid>
                                    <Divider textAlign="left"><Typography variant='subtitle_s' color="primary">Auftragsvorkalkulation</Typography></Divider>
                                    <Grid container justifyContent="center" alignItems="flex-start" spacing={2}>
                                        <Grid item desktop={6} labtop={6} tablet={6} mobil={12}>
                                            <ControlledTextField
                                                name="max_material"
                                                control={control}
                                                label="Materialkosten"
                                                rules={{ required: watch('with_sub') }}
                                                errors={errors}
                                                suffix="€"
                                                type="number"
                                                disabled={!watch('with_sub')}
                                            />
                                        </Grid>
                                        <Grid item desktop={6} labtop={6} tablet={6} mobil={12}>
                                            <ControlledTextField
                                                name="max_task"
                                                control={control}
                                                label="Externe Auftragskosten"
                                                rules={{ required: watch('with_sub') }}
                                                errors={errors}
                                                suffix="€"
                                                type="number"
                                                disabled={!watch('with_sub')}
                                            />
                                        </Grid>
                                        <Grid item desktop={6} labtop={6} tablet={6} mobil={12}>
                                            <ControlledTextField
                                                name="max_workload"
                                                control={control}
                                                label="Arbeitsstunden"
                                                rules={{ required: watch('with_sub') }}
                                                errors={errors}
                                                suffix="Std."
                                                type="number"
                                                disabled={!watch('with_sub')}
                                            />
                                        </Grid>
                                        <Grid item desktop={6} labtop={6} tablet={6} mobil={12}>
                                            <ControlledTextField
                                                name="wage_rate"
                                                control={control}
                                                label="Stundenverrechnungssatz ohne Lohngewinn"
                                                rules={{ required: watch('with_sub') }}
                                                errors={errors}
                                                suffix="€/Std."
                                                type="number"
                                                disabled={!watch('with_sub')}
                                            />
                                        </Grid>
                                        <Grid item desktop={6} labtop={6} tablet={6} mobil={12}>
                                            <ControlledTextField
                                                name="drive_rate"
                                                control={control}
                                                label="Fahrtkosten (pauschal)"
                                                rules={{ required: watch('with_sub') }}
                                                errors={errors}
                                                suffix="€"
                                                type="number"
                                                disabled={!watch('with_sub')}
                                            />
                                        </Grid>
                                        <Grid item desktop={6} labtop={6} tablet={6} mobil={12}>
                                            <ControlledTextField
                                                name="value"
                                                control={control}
                                                label="Auftragssumme (netto)"
                                                rules={{ required: watch('with_sub') }}
                                                errors={errors}
                                                suffix="€"
                                                type="number"
                                                disabled={!watch('with_sub')}
                                            />
                                        </Grid>
                                        <Grid item desktop={12} labtop={12} tablet={12} mobil={12}>
                                            <Collapse in={watch('with_sub')}>
                                                <Stack>
                                                    <ControlledSwitch
                                                        name="at_cost"
                                                        control={control}
                                                        label="Auftrag nach Aufwand berechnen"
                                                        color="primary"
                                                    />
                                                    <Typography variant="text_m" sx={{pt:1, pb:1}}>
                                                        {watch('at_cost') === true ?
                                                            "Bitte gibe trotzdem die geschätzten Kosten für die Vorkalkulation des Auftrags ein, um den Überblick über die Nachkalkulation des Auftrags zu gewährleisten."
                                                        :
                                                            ""
                                                        }
                                                    </Typography>
                                                </Stack>
                                            </Collapse>
                                        </Grid>
                                    </Grid>
                                </Stack>
                            </CustomPaper>
                            <Collapse in={success}>
                                <Alert severity="success">
                                <   AlertTitle>Erfolgreich!</AlertTitle>
                                        Dein Projekt wurde erfolgreich erstellt — <strong>Los geht's!</strong>
                                </Alert>
                            </Collapse>
                        </Stack>
                    </Grid>
                    <Grid item desktop={5} laptop={6} tablet={12} mobil={12}>
                        <Stack spacing={3}>
                            <CustomPaper
                                title= "Projektanschrift"
                                actionBar={
                                    <Tooltip
                                        title="Hier können Sie überprüfen ob ein Projekt an dieser Anschrift bereits existiert."
                                    >
                                        <IconButton onClick={checkProjectAddress}>
                                            <MdFactCheck color={PALETTE.primary.main}/>
                                        </IconButton>
                                    </Tooltip>
                                }
                            >
                                <Grid container justifyContent="center" alignItems="flex-start" spacing={2}>
                                    <Grid item desktop={8} labtop={8} tablet={8} mobil={12}>
                                        <ControlledTextField
                                            name="street"
                                            control={control}
                                            label="Straße"
                                            rules={{ required: true }}
                                            errors={errors}
                                        />
                                    </Grid>
                                    <Grid item desktop={4} labtop={4} tablet={4} mobil={12}>
                                        <ControlledTextField
                                            name="house_number"
                                            control={control}
                                            label="Hausnummer"
                                            rules={{ required: true }}
                                            errors={errors}
                                        />
                                    </Grid>
                                    <Grid item desktop={12} labtop={12} tablet={12} mobil={12}>
                                        <ControlledTextField
                                            name="add_on"
                                            control={control}
                                            label="Zusatz"
                                            rules={{
                                                required: false,
                                            }}
                                            errors={errors}
                                        />
                                    </Grid>
                                    <Grid item desktop={4} labtop={4} tablet={4} mobil={12}>
                                        <ControlledTextField
                                            name="postal_code"
                                            control={control}
                                            label="Postleitzahl"
                                            rules={{
                                                required: true,
                                                minLength: { value: 5, message: "Es gibt keine Postleitzahl mit weniger als 5 Ziffern!"},
                                                maxLength: { value: 5, message: "Es gibt keine Postleitzahl mit mehr als 5 Ziffern!"}
                                            }}
                                            errors={errors}
                                        />
                                    </Grid>
                                    <Grid item desktop={8} labtop={8} tablet={8} mobil={12}>
                                        <ControlledTextField
                                            name="city"
                                            control={control}
                                            label="Stadt"
                                            rules={{ required: true }}
                                            errors={errors}
                                        />
                                    </Grid>
                                </Grid>
                            </CustomPaper>
                            <CustomPaper
                                title="Projekt- /Auftragszeitraum"
                            >
                                <Stack
                                    spacing={2}
                                >
                                    <ControlledDateRangePicker
                                        name="date_range_project"
                                        control={control}
                                        startText="Projektstart"
                                        endText="Projektende"
                                        calendarRange={3}
                                        rules={{ required: true }}
                                    />
                                    <ControlledDateRangePicker
                                        name="date_range"
                                        control={control}
                                        disabled={!watch('with_sub')}
                                        startText="Auftragsstart"
                                        endText="Auftragsende"
                                        calendarRange={3}
                                        rules={{ required: true }}
                                    />
                                    <Typography variant='text_s'>
                                        * Falls die Zeiträume noch nicht bekannt sind, bitte einfach vorläufige oder geschätzte Zeiten angeben. Du kannst die Zeiten jederzeit ändern.
                                    </Typography>
                                </Stack>

                            </CustomPaper>
                            <CustomPaper
                                title="Einstellungen"
                            >
                                <Stack>
                                    <ControlledSwitch
                                        name="with_sub"
                                        control={control}
                                        label="Projekt mit Projektauftrag erstellen"
                                        color="primary"
                                    />
                                    <ControlledSwitch
                                        name="visible"
                                        control={control}
                                        label="Sichtbar für unsere CONMEET Community"
                                        color="primary"
                                    />
                                    <ControlledSwitch
                                        name="enable_C2C_chat"
                                        control={control}
                                        label="Chat zwischen den Unternehmen erlauben"
                                        color="primary"
                                    />
                                    <ControlledSwitch
                                        name="official"
                                        control={control}
                                        label="Projektleitung"
                                        color="primary"
                                    />
                                    <Typography variant="text_m" sx={{pt:1, pb:1}}>
                                        {watch('official') === true ? "Du bist jetzt der Projektleiter!" :
                                        "Keine Projektleitung übernehmen."}
                                    </Typography>
                                </Stack>
                            </CustomPaper>
                            <CustomButton
                                title="Projekt erstellen"
                                loading={loading}
                                success={success}
                                disabled={!isDirty || !isValid}
                            />
                        </Stack>
                    </Grid>
                </Grid>
            </Box>
            <Dialog
                open={openCheck}
                onClose={()=> setOpenCheck(false)}
                maxWidth="tablet"
            >
                <DialogTitle>
                    Anschrift überprüft!
                </DialogTitle>
                <DialogContent>
                    {checkAddress.exists ?
                        <DialogContentText>
                            {`Es existiert ${checkAddress.count} Projekt auf der dieser Straße.
                            Möchten Sie vielleicht erstmal danach suchen und eventuell dem Projekt beitreten.
                            Falls nicht, können Sie das Projekt trotzdem erstellen.`}
                        </DialogContentText>
                        :
                        <DialogContentText>
                            Es gibt kein Projekt an der angegebene Anschrift.
                        </DialogContentText>
                    }
                    <Box
                        sx={{p:2}}
                    >
                        {checkAddress.addresses.map((address, idx)=>{
                            return(
                                <Typography key={`check-address-${idx}`}>{`${idx + 1}. ${address.street} ${address.house_number}, ${address.postal_code} ${address.city}`}</Typography>
                            )
                        })}
                    </Box>
                </DialogContent>
                <DialogActions>
                    <Button onClick={()=> setOpenCheck(false)} autoFocus>
                        Okay, verstanden
                    </Button>
                </DialogActions>
            </Dialog>
        </CustomPage>
    )
}

export default ProjectAdd;
