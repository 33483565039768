import { AvatarGroup, Avatar, Typography } from "@mui/material";
import { useGridApiRef  } from '@mui/x-data-grid-pro';

import Clock from "../../../Widgets/Clock";
import { DateColumn, TimeColumn } from '../../../Widgets/CustomDatagrids/CustomColumns'
import DialogDataGrid from "../../../Widgets/CustomDatagrids/NoPagination/DialogDataGrid";


function getAddress(params) {
	return `${params.row.address.street} ${params.row.address.house_number}${params.row.address.add_on ? ', ' + params.row.address.add_on : ''}, ${params.row.address.postal_code} ${params.row.address.city}`;
}

const customMemberCell = (params) => {
    return(
        <AvatarGroup max={6}>
            {params.row.allocator.map((allo) =>{
                const cm = allo.company_member
                return(
                    <Avatar
                        alt={cm.firstname + '' + cm.lastname}
                        src={cm.avatar}
                    />
                )
            })}
        </AvatarGroup>
    )
}

const WorkingTimeline = (props) =>{

    const { open, onClose, workplan } = props;
    const apiRef = useGridApiRef();

    const columns = [
        DateColumn({
            field: 'day',
            headerName: 'Datum',
            minWidth: 250,
        }),
        {
            field: 'title',
            headerName: 'Titel',
            type: 'string',
            minWidth: 160,
        },
        TimeColumn({
            field: 'since',
            headerName: 'Beginn',
            minWidth: 50,
        }),
        TimeColumn({
            field: 'till',
            headerName: 'Ende',
            minWidth: 50,
        }),
        {
            field: 'sub_project_name',
            headerName: 'Auftrag',
            type: 'string',
            minWidth: 250,
        },
        {
            field: 'description',
            headerName: 'Beschreibung',
            type: 'string',
            flex:'1',
        },
        {
            field: 'address',
            headerName: 'Adresse',
            type: 'string',
            minWidth: 300,
            valueGetter: getAddress,
        },
        {
            field: 'allocator',
            headerName: 'Mitarbeiter',
            type: 'string',
            minWidth: 500,
            renderCell: customMemberCell,
        },

    ];

    return(
        <DialogDataGrid
            open={open}
            onClose={onClose}
            title='Arbeitsplan'
            subtitle="Liste aller anstehende Arbeitsanweisungen"
            apiRef={apiRef}
            rows={workplan}
            columns={columns}
            actionBar={<Typography variant="title_paper" sx={{ alignSelf: 'center' }}><Clock/></Typography>}
            rowHeight={80}
        />
    )
}

export default WorkingTimeline
