import { useForm } from "react-hook-form";

import { getCookie } from '../../../../../../utils/getCookie'

import { Grid, Button, Stack, Box, Dialog, DialogActions, DialogContent, DialogTitle, Collapse, Typography, DialogContentText } from '@mui/material';

import MailIMG from '../../../../../../assets/svgs/mail.svg'

import ControlledSwitch from '../../../../../Widgets/ControlledInputs/ControlledSwitch';
import ControlledTextField from '../../../../../Widgets/ControlledInputs/ControlledTextField';
import ControlledDateRangePicker from '../../../../../Widgets/ControlledInputs/ControlledDateRangePicker';

const InviteCompanyDialog = ({ project, open, handleClose, selectedCompany, getActiveInvites }) =>{

    const { control, handleSubmit, watch, formState: { errors } } = useForm({
        mode: 'onChange',
        defaultValues:{
            title: '',
            instruction: '',
            value: '',
            with_task: false,
            date_range: [null, null]
        }
    });

    const handleInvite = async(data) => {
        const json = JSON.stringify({
            invite_company_id: selectedCompany.id,
            ...data,
        })

        const res = await fetch('/api/project/' + project.id + '/companyinvite', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'X-CSRFToken': getCookie('csrftoken')
            },
            body: json
        })
        const res_data = await res.json()
        if(res.ok){
            handleClose()
            getActiveInvites()
            console.log(res_data)
        }
    }


    return(
        <Dialog
            open={open}
            onClose={handleClose}
            maxWidth="tablet"
            onSubmit={handleSubmit(handleInvite)}
        >
            <form>
                <DialogTitle>
                    Projekteinladung versenden
                </DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        Folgendes Unternehmen wird als Nachunternemher ins Projekt einladen...
                    </DialogContentText>
                    <Stack
                        direction="row"
                        justifyContent='center'
                        spacing={5}
                        sx={{p:5}}
                    >
                        <Box
                            component="img"
                            src={MailIMG}
                            alt="not found"
                            sx={{
                                width: 150
                            }}
                        />
                        <Stack
                            justifyContent='center'
                        >
                            <Typography variant="text_m">{selectedCompany.name}</Typography>
                            <Typography variant="text_m">{selectedCompany.industry}</Typography>
                            <Typography variant="text_m">{selectedCompany.address.street} {selectedCompany.address.house_number}</Typography>
                            <Typography variant="text_m">{selectedCompany.address.postal_code} {selectedCompany.address.city}</Typography>
                        </Stack>
                    </Stack>
                    <ControlledSwitch
                        control={control}
                        label="Mit Projektauftrag"
                        color="primary"
                        name="with_task"
                    />
                    <Collapse in={watch('with_task')}>
                        <Grid
                            container
                            spacing={2}
                        >
                            <Grid
                                item
                                desktop={12}
                                laptop={12}
                                tablet={12}
                                mobile={12}
                            >
                                <ControlledTextField
                                    control={control}
                                    name="title"
                                    label="Name des Projektaufträges"
                                    rules={{ required: watch('with_task')}}
                                    errors={errors}
                                />
                            </Grid>
                            <Grid
                                item
                                desktop={12}
                                laptop={12}
                                tablet={12}
                                mobile={12}
                            >
                                <ControlledTextField
                                    control={control}
                                    name="instruction"
                                    label="Projektauftragsbeschreibung"
                                    rules={{ required: watch('with_task')}}
                                    errors={errors}
                                    multiline
                                    rows={4}
                                />
                            </Grid>
                            <Grid
                                item
                                desktop={12}
                                laptop={12}
                                tablet={12}
                                mobile={12}
                            >
                                <ControlledTextField
                                    control={control}
                                    name="value"
                                    label="Projektauftragssumme"
                                    rules={{ required: watch('with_task')}}
                                    errors={errors}
                                    type="number"
                                />
                            </Grid>
                            <Grid
                                item
                                desktop={12}
                                laptop={12}
                                tablet={12}
                                mobile={12}
                            >
                                <ControlledDateRangePicker
                                    control={control}
                                    name="date_range"
                                    startText="Start des Projektauftrages"
                                    endText="Ende des Projektauftrages"
                                />
                            </Grid>
                        </Grid>
                    </Collapse>
                </DialogContent>
                <DialogActions sx={{ mb: 2, p:2}}>
                    <Button onClick={handleClose} color="secondary">
                        Abbrechen
                    </Button>
                    <Button
                        type="submit"
                        color="primary"
                        variant="outlined"
                    >
                        Versenden
                    </Button>
                </DialogActions>
            </form>
        </Dialog>
    )
}

export default InviteCompanyDialog