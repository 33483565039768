import { useState, useEffect, useCallback } from "react";
import { useGridApiRef } from '@mui/x-data-grid-pro';

import useStyledSnackbar from '../../../hooks/useStyledSnackbar';

import CustomEditToolbar from "./CustomEditToolbar";
import CustomizedRating from "../../Widgets/XDataGridRating";

import { getCookie } from "../../../utils/getCookie";
import { getConstants } from "../../../utils/getConstants";
import { checkSelectionZero, formatNumber } from '../../../utils/helper'

import CustomPage from "../../Widgets/CustomPage";

import { convertStringSelection } from "../../../utils/datagrid_helpers";
import MaterialTable from "./MaterialTable";


const MaterialDatabase = () => {

	const { enqueueSnackbar } = useStyledSnackbar()

	const apiRef = useGridApiRef();
	const [searchTerm, setSearchTerm] = useState('');
	const [orderBy, setOrderBy] = useState('');
	const [materials, setMaterials] = useState([]);
	const [pagInfo, setPagInfo] = useState({
		count: 0,
		next : null,
		previous: null,
	});
	const [isLoading, setIsLoading] = useState(false)
	const [pageSize, setPageSize] = useState(25)
	const [errors, setErrors] = useState({});
	const [categories, setCategories] = useState([]);
	const [selectionCategories, setSelectionCategories] = useState({});
	const [manufacturers, setManufacturers] = useState({});

	const [units, setUnits] =  useState({})
	const callback_units = (c) =>{setUnits(c)}

	const [isLoaded, setIsLoaded] = useState({
		material: false,
		categories: false,
		manufacturers: false,
	});

	const handlePageChange = (x) =>{
		getMaterial(pagInfo[x])
	}

	const handleOrderByGetParametersChange = params => {
		setOrderBy(params)
	}

	const getMaterial = useCallback(async(url) => {
		setIsLoading(true)
        const res = await fetch(url ? url :'/api/company/material/database?search=' + searchTerm +'&limit=' + pageSize + '&' + orderBy, {
            method: 'GET'
        })
		const data = await res.json()
		if(res.ok){
			setMaterials(data.results)
			setPagInfo({
				count: data.count,
				next : data.next,
				previous: data.previous,
			})
			// console.log(data)
		}
		setIsLoading(false)
    }, [pageSize, searchTerm, orderBy])

	const getMaterialCategories = async() => {
		const res = await fetch('/api/company/materials/category', {
            method: 'GET'
        })
		const data = await res.json()
		if(res.ok){
			setCategories(data.serialized)
			setSelectionCategories(data.selection)
			setIsLoaded(prevState => ({
				...prevState,
				categories: true,
			}))
		}
    }

	const getMaterialManufacturers = async() => {
		const res = await fetch('/api/company/materials/manufacturer', {
            method: 'GET'
        })
		const data = await res.json()
		if(res.ok){
			setManufacturers(data)
			setIsLoaded(prevState => ({
				...prevState,
				manufacturers: true,
			}))
		}
    }

	useEffect(() => {
		getMaterial();
		getMaterialCategories();
		getMaterialManufacturers();
		getConstants('units', callback_units );
	}, [getMaterial])

	const columns = [
		{
			field: 'unique_material_id',
			headerName: 'Artikelnummer',
			type: 'string',
			align: 'right',
			minWidth: 150,
			editable: false,
		},
		{
			field: 'reference_id',
			headerName: 'Referenznummer',
			type: 'string',
			align: 'right',
			minWidth: 150,
			editable: true,
		},
		{
			field: 'article_description',
			headerName: 'Artikelbezeichnung',
			type: 'string',
			flex:'1',
			minWidth: 150,
			editable: true,
		},
		{
			field: 'price',
			headerName: 'Nettopreis',
			type: 'number',
			minWidth: 100,
			editable: true,
			valueFormatter: ({ value }) => formatNumber(value, 4,'€'),
		},
		{
			field: 'unit_key',
			headerName: 'Einheit',
			type: 'singleSelect',
			minWidth: 75,
			editable: true,
			valueOptions: convertStringSelection(units),
			valueFormatter: ({ value }) => units[value],
		},
		{
			field: 'category',
			headerName: 'Kategorie',
			type: 'singleSelect',
			minWidth: 150,
			editable: true,
			valueOptions: convertStringSelection(selectionCategories),
			valueFormatter: ({ value }) => selectionCategories[value],
		},
		{
			field: 'manufacturer',
			headerName: 'Hersteller',
			type: 'singleSelect',
			minWidth: 150,
			editable: true,
			valueOptions: convertStringSelection(manufacturers),
			valueFormatter: ({ value }) => manufacturers[value],
		},
		{
			field: 'created',
			headerName: 'erstellt am',
			type: 'date',
			minWidth: 80,
			editable: false,
		},
		{
			field: 'rating',
			headerName: 'Populär',
			renderCell: CustomizedRating,
			minWidth: 200,
			type: 'number',
			hide: true,
		}
	];


	const handleSave = async(row) =>{
        const json = JSON.stringify({
			...row,
			category: checkSelectionZero(row.category),
			manufacturer: checkSelectionZero(row.manufacturer),
		})
        const res = await fetch('/api/company/materials', {
            method: 'PATCH',
            headers: {
                'Content-Type': 'application/json',
                'X-CSRFToken': getCookie('csrftoken')
            },
            body: json
        })
		const data = await res.json()
		if(res.ok){
			apiRef.current.updateRows([{ ...row, isNew: false }]);
			enqueueSnackbar('Es wurde erfolgreich gespeichert!', {
                variant: 'success'
            })
			getMaterial()
			setErrors({})
		} else{
			setErrors(data)
		}
    }

	const addCompanyMaterial = async(row) =>{
        const json = JSON.stringify(row)
        const res = await fetch('/api/company/materials', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'X-CSRFToken': getCookie('csrftoken')
            },
            body: json
        })
		const data = await res.json()
		if(res.ok){
			enqueueSnackbar('Das Material wurde erfolgreich zu deiner Materialdatenbak hinzugefügt!', {
                variant: 'success'
            })
			getMaterial()
			setErrors({})
		} else{
			console.log(data)
			setErrors(data)
		}
    }

	const handleDelete = async(id) => {

        const json = JSON.stringify({
			material_id: id
		})
        const res = await fetch('/api/company/materials', {
            method: 'DELETE',
            headers: {
                'Content-Type': 'application/json',
                'X-CSRFToken': getCookie('csrftoken')
            },
            body: json
        })
		return res.ok
    }

	const getConfirmMessage = row => `Möchtest du wirklich das Material (${row.unique_material_id} / ${row.article_description}) aus deiner Datenbank unwiderruflich löschen?`

    return(
		<CustomPage
			title="Materialverwaltung"
		>
			<MaterialTable
				materials={materials}
				columns={columns}
				apiRef={apiRef}
				isLoading={isLoading || (!isLoaded.material && !isLoaded.categories)}
				title={'Materialdatenbank'}
				pagInfo={pagInfo}
				pageSize={pageSize}
				setPageSize={setPageSize}
				handlePageChange={handlePageChange}
				actionIconBar={
					<CustomEditToolbar
						apiRef={apiRef}
						selectionCategories={selectionCategories}
						units={units}
						categories={categories}
						getMaterialCategories={getMaterialCategories}
						manufacturers={manufacturers}
						addCompanyMaterial={addCompanyMaterial}
						getMaterialManufacturers={getMaterialManufacturers}
						getMaterial={getMaterial}
					/>
				}
				errors={errors}
				onDeleteRow={handleDelete}
				onSaveRow={handleSave}
				getConfirmMessage={getConfirmMessage}
				searchTerm={searchTerm}
				setSearchTerm={setSearchTerm}
				onOrderByGetParametersChange={handleOrderByGetParametersChange}
			/>
        </CustomPage>
    )
}

export default MaterialDatabase
