import { useContext, useState, useEffect, useCallback } from 'react'

import { Box, Paper, Divider, Button, IconButton, Stack, Avatar, Typography } from '@mui/material'
import AddCircleIcon from '@mui/icons-material/AddCircle'
import CancelIcon from '@mui/icons-material/Cancel'
import DeleteIcon from '@mui/icons-material/Delete'

import Chat from '../../Chat'
import NewChats from './NewChats'
import ExistingChats from './ExistingChats'
import TooltipIconButton from '../TooltipIconButton'
import ConfirmDialog from '../../Widgets/ConfirmDialog'

import AccountContext from '../../../context/AccountContext'
import { getCookie } from '../../../utils/getCookie'

import img from '../../../assets/svgs/chat.svg'


const Contacts = ({ contactsURL, notifications, newChatsComponent: NewChatsComponent = NewChats, deleteChatUrl }) => {
    const [contacts, setContacts] = useState([])
    const [account] = useContext(AccountContext)
    const [selectedContact, setSelectedContact] = useState();
    const [showNewChats, setShowNewChats] = useState(false)

    const [socketUrl, setSocketUrl] = useState(null);

    const handleExistingChatClick = (event, contact) => {
        if (contact.chat) {
            setSocketUrl('/chat/' + contact.chat + '/')
        } else {
            setSocketUrl(null)
        }
        setSelectedContact(contact)
        console.log(contact)
    }

    const handleNewChatClick = (e, contact) => {
        setSocketUrl(null)
        setSelectedContact(contact)
    }

    const getContacts = useCallback(() =>{
        console.log('get contacts')
        fetch(contactsURL, {
            method: 'GET'
        }).then(response => {
            response.json().then(data => {
                if(response.ok){
                    setContacts(data)
                    // console.log(data)
                }else{
                }
            }).catch(error => {
                console.error(error)
            })
        }).catch(error => {
            console.error(error)
        })
    }, [contactsURL])

    const startChat = () => {
        fetch(contactsURL + '/' + selectedContact.id, {
            method: 'GET'
        }).then(response => {
            response.json().then(data => {
                if(response.ok){
                    getContacts()
                    setSelectedContact(oldC => ({...oldC, chat: data.chat_id}))
                    setSocketUrl('/chat/' + data.chat_id + '/')
                    setShowNewChats(false)
                }else{
                }
            }).catch(error => {
                console.error(error)
            })
        }).catch(error => {
            console.error(error)
        })
        console.log(selectedContact)
    }

    const deleteChat = async () => {
        const res = await fetch(deleteChatUrl + selectedContact.id, {
            method: 'DELETE',
            headers: {
                'Content-Type': 'application/json',
                'X-CSRFToken': getCookie('csrftoken')
            }
        })
        if (res.ok) {
            getContacts()
            setSelectedContact(null)
            setSocketUrl(null)
        }
    }

    useEffect(getContacts, [contactsURL, getContacts])

    useEffect(() => {
        for (let n of notifications) {
            const chat_exists = contacts.some(company =>
                company.members.some(member =>
                    member.chat === n.chat_id
                )
            )
            if (!chat_exists) {
                getContacts()
                return
            }
        }
    }, [notifications, contacts, getContacts])

    return(
        <Paper
            sx={{
                display: 'flex',
                flexDirection: 'row',
                position: 'relative',
                flex: '1 1 0',
            }}
        >
            <Stack sx={{
                width: 330,
                height: '100%',
                position: 'relative'
            }}>
                {showNewChats ?
                    <NewChatsComponent
                        contacts={contacts}
                        account={account}
                        handleListItemClick={handleNewChatClick}
                        selectedContact={selectedContact}
                    />
                    :
                    <ExistingChats
                        contacts={contacts}
                        account={account}
                        notifications={notifications}
                        handleListItemClick={handleExistingChatClick}
                        selectedContact={selectedContact}
                        getContacts={getContacts}
                    />
                }
                <IconButton
                    color='primary'
                    sx={{
                        position: 'absolute',
                        right: '10px',
                        bottom: '10px'
                    }}
                    className='newChatButton'
                    onClick={() => setShowNewChats(o => !o)}
                >
                    {showNewChats ?
                        <CancelIcon fontSize='large' />
                        :
                        <AddCircleIcon fontSize='large' />
                    }
                </IconButton>
            </Stack>
            <Divider orientation='vertical'/>
            <Stack sx={{ width: '100%' }} alignItems={selectedContact ? 'normal' : 'center'} justifyContent='center'>
                {selectedContact ?
                    <>
                        <Stack direction='row' justifyContent='space-between' sx={{ p: 2 }}>
                            <Stack direction='row' alignItems='center' gap={2}>
                                <Avatar src={selectedContact.avatar} />
                                <Typography variant='subtitle_m'>{selectedContact.firstname} {selectedContact.lastname}</Typography>
                            </Stack>
                            {(deleteChatUrl && socketUrl) &&
                                <ConfirmDialog
                                    title='Chat löschen'
                                    message='Wenn Sie den Chat löschen, wird dieser nicht mehr angezeigt. Die Nachrichten werden erst final gelöscht, wenn alle Teilnehmer den Chat gelöscht haben.'
                                    onConfirm={deleteChat}
                                    renderButton={(onClick) => (
                                        <TooltipIconButton
                                            onClick={onClick}
                                            title="Chat löschen"
                                            icon={<DeleteIcon color="error" />}
                                        />
                                    )}
                                />
                            }
                        </Stack>
                        <Divider />
                    </>
                    :
                    <Box
                        component='img'
                        src={img}
                        alt=''
                        sx={{ width: '50%' }}
                    />
                }
                {socketUrl ?
                    <Chat socketUrl={socketUrl} />
                    :
                    Boolean(selectedContact) &&
                        <Stack justifyContent='center' alignItems='center' sx={{ width: '100%', height: '100%' }}>
                            <Button variant="outlined" onClick={startChat}>Chat starten</Button>
                        </Stack>
                }
            </Stack>
        </Paper>
    )
}

export default Contacts
