import {
    Checkbox,
    Stack,
    ListItem,
    ListItemAvatar,
    Avatar,
    IconButton,
    Typography
} from '@mui/material'

import { BsX } from "react-icons/bs";
import {DoDisturbAltOutlined , CircleOutlined} from '@mui/icons-material';
import { getCookie } from '../../../../../utils/getCookie'
import { formatTimestamp } from '../../../../../utils/date_functions';
import { PALETTE } from '../../../../theme';


const AggreementItem = (props) => {

    const { id, idx, project_id, subject, disagree, disagree_partner, created, description, getProjectAgreements, locked, partner_a_name, partner_b_name } = props

    const updateAgreementItem = async () => {
        const body = {
            id: id,
            disagree: !disagree
        }
        const res = await fetch('/api/project/' + project_id + '/agreements/agreement', {
            method: 'PATCH',
            headers: {
                'Content-Type': 'application/json',
                'X-CSRFToken': getCookie('csrftoken')
            },
            body: JSON.stringify(body),
        })
        if (res.ok) {
            getProjectAgreements()
        }
    }

    const deleteAgreementItem = async () => {
        const body = {
            id: id,
        }
        const res = await fetch('/api/project/' + project_id + '/agreements/agreement', {
            method: 'DELETE',
            headers: {
                'Content-Type': 'application/json',
                'X-CSRFToken': getCookie('csrftoken')
            },
            body: JSON.stringify(body),
        })
        if (res.ok) {
            getProjectAgreements()
        }
    }

    const opacity = disagree ? 0.7 : 1

    return (
        <ListItem
            sx={{
                justifyContent: 'space-between',
            }}
        >
            <Stack
                direction='row'
                alignItems='center'
                spacing={2}
                sx={{
                    opacity: opacity,
                }}
            >
                <ListItemAvatar>
                    <Avatar
                        variant="rounded"
                        sx={{ width: 32, height: 32, bgcolor: disagree ? PALETTE.error.main : PALETTE.primary.main }}
                    >
                        {`${idx}`}
                    </Avatar>
                </ListItemAvatar>
                <Stack>
                    <Stack
                        sx={{
                            '*': {
                                textDecoration: disagree ? 'line-through': 'none'
                            }
                        }}
                    >
                        <Typography variant='subtitle_m'>
                            {subject}
                        </Typography>
                        <Typography variant='text_m' sx={{ whiteSpace: 'pre-wrap', wordBreak: 'break-all' }}>
                            {description}
                        </Typography>
                        <Typography variant='text_s' color='background.light20'>
                            {formatTimestamp(created)}
                        </Typography>
                    </Stack>
                    {disagree_partner !== 0 &&
                        <Typography variant='text_s' color="error" sx={{ fontWeight: 'bold' }}>
                            {disagree_partner === 1 ? `Abgelehnt von ${partner_a_name}` : `Abgelehnt von ${partner_b_name}`}
                        </Typography>
                    }
                </Stack>
            </Stack>
            {!locked &&
                <Stack direction='row' gap={2}>
                    <Checkbox
                        edge="end"
                        onChange={updateAgreementItem}
                        checked={disagree}
                        color="error"
                        icon={<CircleOutlined />}
                        checkedIcon={<DoDisturbAltOutlined />}
                    />
                    <IconButton onClick={deleteAgreementItem}>
                        <BsX size={25}/>
                    </IconButton>
                </Stack>
            }
        </ListItem>
    )
}

export default AggreementItem