import useCountdown from "../../../hooks/useCountdown"
import ShowTimeCountdown from "./ShowTimeCountdown";

const CustomTimeCountdown = ({ targetDate, reverse=false }) =>{
    const [days, hours, minutes, seconds] = useCountdown(targetDate, reverse);

    return(
        <ShowTimeCountdown
            days={days}
            hours={hours}
            minutes={minutes}
            seconds={seconds}
            reverse={reverse}
        />
    )
}

export default CustomTimeCountdown