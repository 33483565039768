import { useState } from "react";
import { ListItemButton, ListItemIcon, ListItemText } from "@mui/material";
import { QuestionMark } from "@mui/icons-material";

import { SETTINGS } from ".."
import SupportDialog from "./SupportDialog";



const Support = ({ activeBackground }) => {

    const [open, setOpen] = useState(false)

    const handleOpen = () => {
        setOpen(true)
    }

    const handleClose= () => {
        setOpen(false)
    }

    return (
        <>
            <ListItemButton
                sx={{
                    gap: SETTINGS.itemContentGap,
                    backgroundColor: open ? activeBackground : 'none',
                    flex: '0 0 ' + SETTINGS.itemHeight,
                    borderRadius: SETTINGS.itemBorderRadius,
                    overflow: 'hidden',
                    p: 2,
                    '&:hover': {
                        backgroundColor: open ? activeBackground : SETTINGS.colors.hoverBackground
                    }
                }}
                onClick={handleOpen}
            >
                <ListItemIcon sx={{ minWidth: 'auto', color: 'inherit' }}>
                    <QuestionMark sx={{ fontSize: SETTINGS.iconSize }} />
                </ListItemIcon>
                <ListItemText
                    primary={'Kundensupport'}
                    sx={{
                        whiteSpace: 'nowrap'
                    }}
                />
            </ListItemButton>
            <SupportDialog
                open={open}
                onClose={handleClose}
            />
        </>
    );
}

export default Support;