import { Stack, Box, Divider, Typography, Avatar, Badge } from "@mui/material";

const SubscriberList = ({ title, subscribers, account, extern }) =>{


    const handleStatusColor = {
        '1' : "background.light45",
        '2' : "primary.main",
        '3' : "secondary.main",
        '4' : "error.main",
    }

    return(
        <Stack>
            <Box sx={{mb:1}}>
                <Divider textAlign="left" flexItem>
                    <Typography
                        variant="subtitle_s"
                    >
                        {extern ? "Externe Teilnehmer" : "Teilnehmer"}
                    </Typography>
                </Divider>
            </Box>
            <Stack
                justifyContent="center"
                alignItems="flex-start"
                spacing={1}
                sx={{ maxHeight: 200, overflow: 'auto'}}
            >
                {subscribers?.map((allocator) => {
                    const member = Object.values(allocator)[0]
                    const nameAddOn = account.id === member.id ? '(Du)' : ''
                    const labelId = `list-label-${member.id}`;
                    return (
                        <Stack
                            key={labelId}
                            alignItems="center"
                            direction='row'
                            spacing={1}
                        >
                            <Badge
                                overlap="circular"
                                badgeContent=''
                                variant="dot"
                                anchorOrigin={{
                                    vertical: 'bottom',
                                    horizontal: 'right',
                                }}
                                sx={{
                                    "& .MuiBadge-badge": {
                                        backgroundColor: handleStatusColor[allocator.status]
                                    }
                                }}
                            >
                                <Avatar
                                    alt={`Avatar - ${member.firstname + ' ' + member.lastname}`}
                                    src={member.avatar}
                                />
                            </Badge>
                            <Stack>
                                <Typography variant="subtitle_s">{`${member.firstname} ${member.lastname} ${nameAddOn}`}</Typography>
                                <Typography variant="text_s">{`${member.company_name}`}</Typography>
                            </Stack>
                        </Stack>
                    );
                })}
            </Stack>
        </Stack>
    )
}

export default SubscriberList