import useScrollPosition from '@react-hook/window-scroll'

import { Box, Container, Typography } from "@mui/material"
import { useMatch } from 'react-router'

import { SETTINGS as PROJECT_TOOLBAR_SETTINGS } from 'components/NavBar/ProjectToolbar'
import { SETTINGS as NAVBAR_SETTINGS } from 'components/NavBar'


const CustomPage = ({ header, title, sx, children, maxWidth='desktop' }) => {

    const scrollY = useScrollPosition(60)
    const inProject = useMatch('/dashboard/projects/project/:id/*')

    return(
        <Container
            maxWidth={maxWidth}
            sx={{
                display: 'flex',
                flexDirection: 'column',
                flexGrow: 1,
                mt: header ? '127.5px' : 0,
                p: 3,
                pt: 5,
                gap: 2,
                ...sx
            }}
        >
            {header &&
                <Box
                    sx={{
                        position: 'fixed',
                        width: '100%',
                        p: 2,
                        paddingLeft: inProject ?
                            PROJECT_TOOLBAR_SETTINGS.collapsedWidth + 'px'
                            :
                            (NAVBAR_SETTINGS.collapsedWidth + NAVBAR_SETTINGS.openWidth) + 'px',
                        top: 0,
                        right: 0,
                        backgroundColor: 'background.content',
                        zIndex: 10,
                        opacity: scrollY > 16 ? 0.9 : 1,
                        transition: 'all 0.3s ease-in-out',
                        borderBottom: '1px solid',
                        borderColor: scrollY > 16 ? 'rgba(0, 0, 0, 0.12)' : 'background.content',
                    }}
                >
                    <Container
                        maxWidth={maxWidth}
                    >
                        {header}
                    </Container>
                </Box>
            }
            {title &&
                <Typography variant="title_page">
                    {title}
                </Typography>
            }
            {children}
        </Container>
    )
}

export default CustomPage
