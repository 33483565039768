import { useState, useEffect, useCallback } from "react";

import { Stack, Tooltip } from "@mui/material";
import { useGridApiRef, GridActionsCellItem } from '@mui/x-data-grid-pro';
import { ChangeCircle } from '@mui/icons-material';

import { getCookie } from "../../../../utils/getCookie";

import PaperDataGrid from "../../../Widgets/CustomDatagrids/NoPagination/PaperDataGrid";
import CustomPage from "components/Widgets/CustomPage";

const CompanyWages = () => {

    const apiRef_wage = useGridApiRef();
    const apiRef_salary = useGridApiRef();
    const [companyMembers , setCompanyMembers] = useState([])
	const [isLoaded, setIsLoaded] = useState(false)

    const company_members_wage = companyMembers.filter(x => x.has_salary !== true)
    const company_members_salary = companyMembers.filter(x => x.has_salary !== false)

	const getCompanyMembers = useCallback(async() => {
        const res = await fetch('/api/company/members/payment', {
            method: 'GET'
        })
		const data = await res.json()
		if(res.ok){
			setCompanyMembers(data)
			setIsLoaded(true)
			// console.log(data)
		}
    }, [])

	useEffect(()=>{
		getCompanyMembers()
	},[getCompanyMembers])

    const columns_wage = [
		{
			field: 'firstname',
			headerName: 'Vorname',
			type: 'string',
			minWidth: 150,
			editable: false,
		},
		{
			field: 'lastname',
			headerName: 'Nachname',
			type: 'string',
			minWidth: 150,
			editable: false,
		},
		{
			field: 'job_title',
			headerName: 'Job',
			type: 'string',
            flex:'1',
			minWidth: 100,
			editable: false,
		},
		{
			field: 'payment',
			headerName: 'Stundenlohn',
			type: 'number',
			minWidth: 170,
			editable: true,
		},
		{
			field: 'vacation_days',
			headerName: 'Urlaubstage',
			type: 'number',
			minWidth: 80,
			editable: true,
		},
		{
			field: 'extra_vacation_days',
			headerName: 'Resturlaubstage',
			type: 'number',
			minWidth: 80,
			editable: true,
		},
        {
			field: 'factorable',
			headerName: 'Faktorisierbare Tätigkeit',
			type: 'boolean',
			minWidth: 100,
			editable: true,
		},
		{
			field: 'actions2',
			headerName: 'Zu Gehalt Wechseln',
			type: 'actions',
			minWidth: 150,
			getActions: ({id}) => {
				return [
					<GridActionsCellItem
						icon={
							<Tooltip title={'Zu Gehalt wechseln'}>
								<ChangeCircle color='primary' />
							</Tooltip>
						}
						label="Change"
						onClick={handleChangePaymentClick(id)}
						color="secondary"
					/>
				]
			}
		}
	];

    const columns_salary = [
		{
			field: 'firstname',
			headerName: 'Vorname',
			type: 'string',
			minWidth: 150,
			editable: false,
		},
		{
			field: 'lastname',
			headerName: 'Nachname',
			type: 'string',
			minWidth: 150,
			editable: false,
		},
		{
			field: 'job_title',
			headerName: 'Job',
			type: 'string',
            flex:'1',
			minWidth: 170,
			editable: false,
		},
		{
			field: 'payment',
			headerName: 'Gehalt (brutto pro Monat)',
			type: 'number',
			minWidth: 170,
			editable: true,
		},
		{
			field: 'vacation_days',
			headerName: 'Urlaubstage',
			type: 'number',
			minWidth: 80,
			editable: true,
		},
		{
			field: 'extra_vacation_days',
			headerName: 'Resturlaubstage',
			type: 'number',
			minWidth: 80,
			editable: true,
		},
        {
			field: 'factorable',
			headerName: 'Faktorisierbare Tätigkeit',
			type: 'boolean',
			minWidth: 100,
			editable: true,
		},
		{
			field: 'can_billed',
			headerName: 'Stundenabrechung erlauben',
			type: 'boolean',
			minWidth: 100,
			editable: true,
		},
		{
			field: 'actions2',
			headerName: 'Zu Stundenlohn wechseln',
			type: 'actions',
			minWidth: 150,
			getActions: ({id}) => {
				return [
					<GridActionsCellItem
						icon={
							<Tooltip title={'Zu Stundenlohn wechseln'}>
								<ChangeCircle color='primary' />
							</Tooltip>
						}
						label="Change"
						onClick={handleChangePaymentClick(id)}
						color="secondary"
					/>
				]
			}
		}
	];

	const handleSave = async (row) => {
		saveCompanyMemberPayment(row)
	};

	const saveCompanyMemberPayment = async(row) =>{
        const json = JSON.stringify({
			company_member_id: row.id,
			payment: row.payment,
			factorable: row.factorable,
			vacation_days: row.vacation_days,
			extra_vacation_days: row.extra_vacation_days,
			can_billed: row.can_billed
		})
        // console.log(json)
        const res = await fetch('/api/company/members/payment', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'X-CSRFToken': getCookie('csrftoken')
            },
            body: json
        })
		if(res.ok){
			getCompanyMembers()
		}
    }

	const handleChangePaymentClick = (id) => async (event) => {
		changeCompanyMemberPayment(id)
	};

	const changeCompanyMemberPayment = async(id) =>{
        const json = JSON.stringify({
			company_member_id: id,
		})
        // console.log(json)
        const res = await fetch('/api/company/members/payment', {
            method: 'PATCH',
            headers: {
                'Content-Type': 'application/json',
                'X-CSRFToken': getCookie('csrftoken')
            },
            body: json
        })
		if(res.ok){
			getCompanyMembers()
		}
    }


    return(
		<CustomPage
			title="Löhne & Gehälter"
		>
			<Stack spacing={3}>
				<PaperDataGrid
					rows={company_members_wage}
					columns={columns_wage}
					apiRef={apiRef_wage}
					loading={!isLoaded}
					title={'Mitarbieter im Stundenlohn'}
					onSaveRow={handleSave}
				/>
				<PaperDataGrid
					rows={company_members_salary}
					columns={columns_salary}
					apiRef={apiRef_salary}
					loading={!isLoaded}
					title={'Mitarbieter im Gehalt'}
					onSaveRow={handleSave}
				/>
			</Stack>
		</CustomPage>
    )
}

export default CompanyWages