import { useState, useEffect } from 'react'
import { Stack, Button, Dialog, DialogTitle,  DialogContent, DialogContentText, DialogActions, Typography, Alert, AlertTitle } from "@mui/material"
import { getCookie } from '../../../../../utils/getCookie';
import { PALETTE } from 'components/theme';



const TaskHandOverDialog = (props) =>{

    const { open, onClose, project_id, task, getProjectTasks } = props
    const [supervisor, setSupervisor] = useState(null)

    const handleOnClose = () =>{
        onClose()
    }

    const handleHandOver = async() => {
        if(supervisor === null || task === null) return
        const json = JSON.stringify({
            sub_project_id: task.id,
            supervisor_id: supervisor.id
        })
        const res = await fetch('/api/project/' + project_id + '/tasks/handover', {
            method: 'PATCH',
            headers: {
                'Content-Type': 'application/json',
                'X-CSRFToken': getCookie('csrftoken')
            },
            body: json
        })
        const _data = await res.json()
        if (res.ok) {
            getProjectTasks()
            handleOnClose()
        } else{
            console.log(_data)
        }
    }

    useEffect(() => {
        if(open){
            const getProjektSupervisor = async() => {
                const res = await fetch('/api/project/' + project_id + '/tasks/handover', {
                    method: 'GET',
                })
                if (res.ok) {
                    const data = await res.json()
                    setSupervisor(data)
                }
            }
            getProjektSupervisor()
        }
    }, [open, project_id])

    return(
        <Dialog
            maxWidth="tablet"
            open={open}
            onClose={handleOnClose}
            fullWidth
        >
            <DialogTitle>Projektauftrag übergeben</DialogTitle>
            <DialogContent>
                <DialogContentText>
                    Hier kannst du deinen eigenen Auftrag deinem Auftraggeber übergeben.
                </DialogContentText>
                {supervisor !== null ?
                    <Stack gap={3} sx={{mt:2}}>
                        <Alert
                            severity="warning"
                            sx={{boxShadow: 0}}
                        >
                            <AlertTitle>Hinweis</AlertTitle>
                           {`  Du hast dir selbst einen Auftrag in einem Projekt vergeben, aber jetzt nutzt dein Auftraggeber CONMEET und Ihr wollt zusammen in einem Projekt, welches du abgeben willst, arbeiten? Kein Problem gebe deinen für dich erstellten Auftrag richtigerweise an deinen Auftraggeber im Projekt ab. Keine Angst, du gibst deine internen Kalkulationsdaten nicht preis. Der Auftrag ändert sich lediglich von einem erstellten Auftrag in einen erhaltenen. Somit haltet Ihr jetzt die Hierarchie aufrecht und könnt sauber arbeiten.`}
                        </Alert>
                        <Stack
                            direction="row"
                            justifyContent="center"
                            alignItems="center"
                            sx={{
                                mb: 2,
                                padding: 2,
                                border: 1,
                                borderRadius: '10px',
                                borderColor: PALETTE.background.light70
                            }}
                        >
                            <Typography variant="text_m" color="primary" sx={{fontWeight: 'bold', width: 300}}>Auftrag übergeben an:</Typography>
                            <Stack
                                justifyContent='center'
                                alignItems="center"
                            >
                                <Typography variant="text_m" sx={{fontWeight: 'bold'}}>{supervisor.name}</Typography>
                                <Typography variant="text_m">{supervisor.industry}</Typography>
                                <Typography variant="text_m">{supervisor.address.street} {supervisor.address.house_number}</Typography>
                                <Typography variant="text_m">{supervisor.address.postal_code} {supervisor.address.city}</Typography>
                            </Stack>
                        </Stack>

                    </Stack>
                :
                    <Alert
                        severity="warning"
                        sx={{
                            mt:2,
                            mb:2,
                            boxShadow: 0,
                        }}
                    >
                        <AlertTitle>Hinweis</AlertTitle>
                        {`Stopp! Das Projekt hast du angelegt, und somit bist du auch der Projektverantwortliche. Falls du Aufträge abgeben willst, musst du den verantwortlichen erst in dein Projekt einladen. Nachdem du einen Projektverantwortlichen eingeladen hast, übergebe die Projektleitung unter den Projekteinstellungen. Jetzt kannst du, falls die Projektleitung auch dein Auftraggeber ist, die Aufträge richtigerweise an deinen Auftraggeber übergeben.`}
                    </Alert>
                }
                <DialogActions>
                    <Button
                        color="secondary"
                        onClick={onClose}
                    >
                        Abbrechen
                    </Button>
                    <Button
                        color="primary"
                        onClick={handleHandOver}
                        disabled={supervisor === null}
                    >
                        Auftrag übergeben
                    </Button>
                </DialogActions>
            </DialogContent>
        </Dialog>
    )
}

export default TaskHandOverDialog;