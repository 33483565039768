import { Select, MenuItem, FormControl, InputLabel } from '@mui/material'

const UnControlledSelect = ({ label, onChange, variant,  value, items , size='normal', sx}) => {
    return (
            <FormControl
                variant={variant}
                fullWidth
                sx={sx}
            >
                <InputLabel size={size}>{label}</InputLabel>
                <Select
                    label={label}
                    variant={variant}
                    fullWidth
                    onChange={onChange}
                    size={size}
                    // empty value if no items
                    value={(!items || Object.keys(items).length === 0) ? '' : value}
                >
                    {Object.entries(items).map(([key, value], idx )=> {
                        return(
                            <MenuItem key={`${label}-key-${idx}`} value={key}>{value}</MenuItem>
                        )
                    })}
                </Select>
            </FormControl>
    )
}

export default UnControlledSelect