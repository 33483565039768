import { useState } from 'react';
import { useForm } from 'react-hook-form';
import { Stack, Button, Dialog, DialogTitle, DialogContent, DialogActions, DialogContentText, Switch, FormGroup, FormControlLabel } from '@mui/material';
import ControlledTextField from '../../../../Widgets/ControlledInputs/ControlledTextField';
import ControlledSelect from '../../../../Widgets/ControlledInputs/ControlledSelect';
import { checkSelectionZero } from '../../../../../utils/helper';


const AddMaterialDialog = ({ open, onClose, selectionCategories, units, manufacturers, addCompanyMaterial }) =>{

    const [checked, setChecked] = useState(false);
    const handleChange = (event) => {
        setChecked(event.target.checked);
    };

    const { control, handleSubmit, reset,formState: { errors, isValid, isDirty }} = useForm({
        mode: 'onChange',
        defaultValues:{
            reference_id: '',
            article_description: '',
            price: '',
            category: '',
            manufacturer: '',
            unit_key: '',
        }
    });

    const submit = (data) =>{
        data.category = checkSelectionZero(data.category)
        data.manufacturer = checkSelectionZero(data.manufacturer)
        addCompanyMaterial(data)
        if(!checked){
            onClose()
            reset()
        }
    }

    const handleClose = () =>{
        onClose()
        reset()
    }

    return(
        <Dialog
            open={open}
            onClose={handleClose}
            fullWidth
            maxWidth="laptop"
        >
            <DialogTitle>
                Neues Material anlegen
            </DialogTitle>
            <DialogContent>
                <DialogContentText>
                    Die Artikelnummer werden automatisch vergeben!
                </DialogContentText>
                <Stack
                    component="form"
                    id="add-material-form"
                    onSubmit={handleSubmit(submit)}
                    spacing={2}
                    sx={{mt:1.5}}
                >
                    <ControlledTextField
                        name="reference_id"
                        label="Referenznummer"
                        control={control}
                        errors={errors}
                        multiline
                    />
                    <ControlledTextField
                        name="article_description"
                        label="Artikelbezeichnung"
                        control={control}
                        rules={{ required: true }}
                        errors={errors}
                        multiline
                        rows={8}
                    />
                    <Stack
                        direction="row"
                        spacing={2}
                    >
                        <ControlledTextField
                            name="price"
                            label="Nettopreis"
                            control={control}
                            rules={{ required: true }}
                            errors={errors}
                            type="number"
                        />
                        <ControlledSelect
                            name="unit_key"
                            label="Einheit"
                            control={control}
                            rules={{ required: true }}
                            items={units}
                            errors={errors}
                        />
                    </Stack>
                    <Stack
                        direction="row"
                        spacing={2}
                    >
                        <ControlledSelect
                            name="category"
                            label="Kategorie"
                            control={control}
                            rules={{ required: false }}
                            items={selectionCategories}
                            errors={errors}
                        />
                        <ControlledSelect
                            name="manufacturer"
                            label="Hersteller"
                            control={control}
                            rules={{ required: false }}
                            items={manufacturers}
                            errors={errors}
                        />
                    </Stack>
                </Stack>
            </DialogContent>
            <DialogActions>
                <Stack
                    direction='row'
                    justifyContent='space-between'
                    alignItems="center"
                    spacing={2}
                    width="100%"
                >
                        <FormGroup
                            sx={{pl: 2, pb: 0.5}}
                        >
                            <FormControlLabel
                                control={
                                    <Switch
                                        checked={checked}
                                        onChange={handleChange}
                                        color="primary"
                                    />
                                }
                                label="mehrere Materialien nacheinander anlegen"
                            />
                        </FormGroup>
                    <Stack
                        direction='row'
                        spacing={2}
                    >
                        <Button
                            onClick={handleClose}
                            color="secondary"
                        >
                            Zurück
                        </Button>
                        <Button
                            form="add-material-form"
                            type="submit"
                            size="large"
                            disabled={!isValid || !isDirty}
                        >
                            Hinzufügen
                        </Button>
                    </Stack>
                </Stack>
            </DialogActions>
        </Dialog>
    )
}

export default AddMaterialDialog
