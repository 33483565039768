import { Stack, Typography, TextField, InputAdornment } from '@mui/material';
import { FilterAlt, Search } from '@mui/icons-material';

import TooltipIconButton from '../../TooltipIconButton';
import { PALETTE } from '../../../theme';



const PaginationToolbar = (props)=> {

    const { apiRef, title, searchTerm, setSearchTerm, actionBar } = props;

    const handleSearch = (e) => {
        setSearchTerm(e.target.value);
    }

    return (
        <Stack
            direction='row'
            justifyContent='space-between'
            alignItems='center'
            width='100%'
            sx={{ pl:2, pr:1, pt:1 , pb:1}}
        >
            <Typography variant='title_paper' color={PALETTE.primary.main}>{title}</Typography>
            <Stack
                direction='row'
                spacing={1}
                sx={{p:1}}
            >
                {actionBar}
                <TooltipIconButton
                    title="Depot filtern"
                    icon={<FilterAlt />}
                    onClick={()=> apiRef.current.showFilterPanel()}
                />
                <TextField
                    id="cdpt-filter"
                    variant="outlined"
                    placeholder='Suche...'
                    value={searchTerm}
                    onChange={handleSearch}
                    InputProps={{
                        startAdornment: (
                            <InputAdornment position="start">
                                <Search color='primary'/>
                            </InputAdornment>
                        ),
                    }}
                    size="small"
                    sx={{ maxWidth: 250}}
                />
            </Stack>
        </Stack>
    );
}

export default PaginationToolbar