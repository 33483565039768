import { Typography, Divider, Stack } from "@mui/material"
import CustomPaper from "../../../Widgets/CustomPaper";

import { formatNumber } from '../../../../utils/helper'


const ProjectTimestampsInfo = ({ projectTimestamps }) =>{

    return(
        <CustomPaper
            title="Zeitkontingent"
        >
            <Stack
                justifyContent='space-between'
                alignItems='flex-start'
                spacing={1}
                width="100%"
            >
                <Stack
                    direction="row"
                    justifyContent='space-between'
                    alignItems='center'
                    width="100%"
                >
                    <Typography variant="text_m">Kalkulierte Stunden</Typography>
                    <Typography variant="text_m" sx={{textAlign: 'right', fontWeight: 'bold'}}>{formatNumber(projectTimestamps.max_workload, 2,'Stunden')}</Typography>
                </Stack>
                <Stack
                    direction="row"
                    justifyContent='space-between'
                    alignItems='center'
                    width="100%"
                >
                    <Typography variant="text_m">Aktuelle Stunden</Typography>
                    <Typography  variant="text_m" sx={{textAlign: 'right', fontWeight: 'bold'}}>{`- ${formatNumber(projectTimestamps.current_workload, 2,'Stunden')}`}</Typography>
                </Stack>
                <Divider flexItem/>
                <Stack
                    direction="row"
                    justifyContent='space-between'
                    alignItems='center'
                    width="100%"
                >
                    <Typography variant="subtitle_m">Zeitdifferenz</Typography>
                    <Typography variant="subtitle_m" color={projectTimestamps.max_workload - projectTimestamps.current_workload >= 0 ? "primary" : "secondary"} sx={{textAlign: 'right', fontWeight: 'bold'}}>{formatNumber(projectTimestamps.max_workload - projectTimestamps.current_workload, 2,'Stunden')}</Typography>
                </Stack>
            </Stack>
        </CustomPaper>
    )
}

export default ProjectTimestampsInfo