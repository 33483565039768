import { useState, useEffect, useCallback } from "react"

import { Stack, IconButton, Divider, Box } from "@mui/material";
import { Add } from "@mui/icons-material";

import CustomPaper from "../../../Widgets/CustomPaper";
import AddNote from "./AddNote";
import Note from "./Note";

import { PALETTE } from "../../../theme";
import NoData from "components/Widgets/NoData";


const NoteBoard = ({ project, disabled }) =>{

    const [notes, setNotes] = useState([])
    const [notesLoaded, setNotesLoaded] = useState(false)
    const [open, setOpen] = useState(false)

    const getProjectNotes = useCallback( async() =>{
        fetch('/api/project/' + project.id + '/notes', {
            method: 'GET'
        }).then(response => {
            response.json().then(data => {
                if(response.ok){
                    setNotes(data)
                    setNotesLoaded(true)
                }else{
                }
            }).catch(error => {
                console.error(error)
            })
        }).catch(error => {
            console.error(error)
        })
    }, [project.id])

    useEffect(() =>{
        getProjectNotes()
    }, [getProjectNotes])

    return(
            <CustomPaper
                title='Projektinformationen' sx={{ flex: 1 }}
                actionBar={
                    <IconButton
                        color="primary"
                        size="medium"
                        onClick={()=> setOpen(true)}
                        disabled={disabled}
                    >
                        <Add fontSize="medium"/>
                    </IconButton>
                }
            >
                <Stack
                    spacing={2}
                    sx={{
                        flex: '1 1 0',
                        overflow: 'auto',
                        border: 1,
                        borderRadius: '10px',
                        borderColor: PALETTE.background.light70,
                        pr:1,
                        pt:1
                    }}
                >
                    {notes.length !== 0 ?
                        notes.map((note, idx)=> {
                            return (
                                <Box key={`todo-wrapper-${note.id}`}>
                                    <Note
                                        key={`note-${note.id}`}
                                        note={note}
                                        project={project}
                                        getProjectNotes={getProjectNotes}
                                    />
                                {notes.length !== idx + 1 &&
                                    <Divider variant='inset'/>
                                }
                            </Box>

                            );})
                    :
                        notesLoaded  &&
                            <NoData text="Du hast noch keine Projektinformation verfasst..."/>
                    }
                </Stack>
                <AddNote
                    open={open}
                    onClose={()=>setOpen(false)}
                    project={project}
                    getProjectNotes={getProjectNotes}
                />
        </CustomPaper>
    )
}

export default NoteBoard