import { Link } from 'react-router-dom';

import { Avatar, List, Divider, ListItemAvatar, ListItemButton, ListItemText, ListSubheader, Typography, Box } from '@mui/material'

import { BsLayoutSidebarInset, BsFillCalendarEventFill, BsListUl, BsEnvelopeFill, BsFillDiagram2Fill, BsFillFolderFill} from 'react-icons/bs'
import { IoAlertCircle, IoTicketSharp } from "react-icons/io5";
import { getLocalTimeStamp, formatDate } from 'utils/date_functions.js'
import { FaHandshake } from 'react-icons/fa';


const NotificationsPanel = ({ notifications, closePanel }) => {
    return (
        <List sx={{ width: '350px', p: 2 }}>
            <Box sx={{mb:2}}>
                <Typography variant="subtitle_m" color="primary" sx={{fontWeight: 'bold'}}>Benachrichtigungen</Typography>
            </Box>
            <Items
                title='Projektfeeds'
                items={notifications.project_blackboard}
                getTo={n => '/dashboard/projects/x-project/' + n.project_id + '/chat'}
                getPrimary={n => n.project_name}
                icon={<BsLayoutSidebarInset />}
                onClick={closePanel}
            />
            <Items
                title='Projektchats'
                items={notifications.private_project_chat}
                getTo={n => '/dashboard/projects/x-project/' + n.project_id + '/chat?chat=' + n.chat_id}
                getPrimary={n => n.contact_name}
                getSecondary={n => n.project_name}
                onClick={closePanel}
            />
            <Items
                title='Betriebsfeed'
                items={notifications.company_blackboard}
                getTo={n => '/dashboard/company-chat'}
                getPrimary={n => n.company_name}
                icon={<BsLayoutSidebarInset />}
                onClick={closePanel}
            />
            <Items
                title='Betriebschat'
                items={notifications.internal_chat}
                getTo={n => '/dashboard/company-chat?chat=' + n.chat_id}
                getPrimary={n => n.contact_name}
                onClick={closePanel}
            />
            <Items
                title='CONMEET Netzwerk'
                items={notifications.conmeet_connections_chat}
                getTo={n => '/dashboard/conmeet-connections?chat=' + n.chat_id}
                getPrimary={n => n.contact_name}
                getSecondary={n => n.company_name}
                onClick={closePanel}
            />
            <Items
                title='CONMEET Netzwerk - Feed'
                items={notifications.conmeet_connections_blackboard}
                getTo={n => '/dashboard/conmeet-connections'}
                getPrimary={n => 'CONMEET Netzwerk - Feed'}
                icon={<BsLayoutSidebarInset />}
                onClick={closePanel}
            />
            <Items
                title='Neue Termine'
                items={notifications.company_calendar}
                getTo={n => '/dashboard/calendar'}
                getPrimary={n => n.meeting_title}
                getSecondary={n => {
                    if (n.all_day) {
                        return 'Ganztägig'
                    }
                    const d = new Date(n.start)
                    return formatDate(d) + ', ' + getLocalTimeStamp(d)
                }}
                icon={<BsFillCalendarEventFill />}
            />
            <Items
                title='Neuer To-Do'
                items={notifications.to_do}
                getTo={n => '/dashboard/todos'}
                getPrimary={n => n.name}
                getSecondary={n => n.description}
                icon={<BsListUl/>}
            />
            <Items
                title='Projektanfragen'
                items={notifications.project_request}
                getTo={n => '/dashboard/projects/requests'}
                getPrimary={n => n.project}
                getSecondary={n => n.supervisor}
                icon={<BsEnvelopeFill/>}
            />
            <Items
                title='Beitrittsanfrage'
                items={notifications.project_join_request}
                getTo={n => '/dashboard/projects/x-project/' + n.project_id + '/invite'}
                getPrimary={n => n.project}
                getSecondary={n => n.company}
                icon={<BsFillDiagram2Fill/>}
            />
            <Items
                title='Neuer Projektauftrag'
                items={notifications.sub_project}
                getTo={n => '/dashboard/projects/x-project/' + n.project_id + '/tasks'}
                getPrimary={n => n.title}
                getSecondary={n => n.project}
                icon={<BsFillFolderFill/>}
            />
            <Items
                title='Neues Projektticket'
                items={notifications.ticket}
                getTo={n => '/dashboard/projects/x-project/' + n.project_id + '/tickets'}
                getPrimary={n => n.title}
                getSecondary={n => n.project}
                icon={<IoTicketSharp/>}
            />
            <Items
                title='Neue Projektinformation'
                items={notifications.project_note}
                getTo={n => '/dashboard/projects/x-project/' + n.project_id + '/notes'}
                getPrimary={n => n.project}
                getSecondary={n => `von ${n.creator}`}
                icon={<IoAlertCircle />}
            />
            <Items
                title='Neue Projektvereinbarung'
                items={notifications.project_agreement}
                getTo={n => '/dashboard/projects/x-project/' + n.project_id + '/agreements'}
                getPrimary={n => n.project}
                getSecondary={n => `von ${n.partner_a}`}
                icon={<FaHandshake />}
            />
        </List>
    )
}

export default NotificationsPanel

const Items = ({ title, items, icon, getTo = () => {}, getPrimary = () => {}, getSecondary = () => {}, onClick }) => {
    return (
        <Box sx={{mb:1}}>
            {items.length > 0 &&
                <>
                    <ListSubheader
                        sx={{
                            fontSize: '1rem',
                            fontWeight: 'bold',
                            lineHeight: 1.5
                        }}

                    >
                        {title}
                    </ListSubheader>
                    <Divider />
                </>
            }
            {items.map(n =>
                <ListItemButton
                    key={n.id}
                    component={Link}
                    to={getTo(n)}
                    onClick={onClick}
                >
                    <ListItemAvatar>
                        {icon ?
                            <Avatar
                                sx={{bgcolor: 'secondary.main'}}
                            >
                                {icon}
                            </Avatar>
                        :
                            <Avatar
                                alt=''
                                src={n.avatar}
                            />
                        }
                    </ListItemAvatar>
                    <ListItemText primary={getPrimary(n)} secondary={getSecondary(n)} />
                </ListItemButton>
            )}
        </Box>
    )
}
