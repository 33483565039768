import { createRef } from 'react'
import { SnackbarProvider } from 'notistack'

import { IconButton } from '@mui/material'
import CloseIcon from '@mui/icons-material/Close';

const CustomSnackbarProvider = ({ children }) => {
    const notistackRef = createRef();
    const onClickDismiss = key => () => {
        notistackRef.current.closeSnackbar(key);
    }

    return (
        <SnackbarProvider
            ref={notistackRef}
            maxSnack={3}
            anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'center',
            }}
            action={(key) => (
                <IconButton onClick={onClickDismiss(key)} sx={{
                    color: 'text.light'
                }}>
                    <CloseIcon />
                </IconButton>
            )}
        >
            {children}
        </SnackbarProvider>
    )
}

export default CustomSnackbarProvider