import { useContext, useState } from 'react'
import CompanyContext from '../../../../context/CompanyContext'

import useStyledSnackbar from '../../../../hooks/useStyledSnackbar'

import { Box, Typography, Stack, Divider, Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions, TextField, Button } from '@mui/material';
import { Email, ContentCopy, Link} from '@mui/icons-material';

import { CopyToClipboard } from 'react-copy-to-clipboard';

import InviteIMG from '../../../../assets/svgs/invite.svg'
import CustomPage from '../../../Widgets/CustomPage';
import CustomPaper from '../../../Widgets/CustomPaper';
import TooltipIconButton from '../../../Widgets/TooltipIconButton'

import { getCookie } from '../../../../utils/getCookie.js'

const CompanyInvite = () => {

    const [company] = useContext(CompanyContext)
    const [openInvite, setOpenInvite] = useState(false)
    const [email, setEmail] = useState('')

    const { enqueueSnackbar } = useStyledSnackbar()

    const copyKeySnackbar = () => {
        enqueueSnackbar('Der Registrierungsschlüssel wurde erfolgreich in die Zwischenablage gespeichert!', {
            variant: 'success'
        })
    }

    const copyLinkSnackbar = () => {
        enqueueSnackbar('Der Registrierungslink wurde erfolgreich in die Zwischenablage gespeichert!', {
            variant: 'success'
        })
    }

    const sendEmail = async () => {
        if (email === '') return
        const res = await fetch('/api/company/members/invite', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'X-CSRFToken': getCookie('csrftoken')
            },
            body: JSON.stringify({
                email: email
            })
        })
        if (res.ok) {
            setOpenInvite(false)
            setEmail('')
            enqueueSnackbar('Die Email wurde erfolgreich verschickt!', { variant: 'success' })
        } else {
            enqueueSnackbar('Beim verschicken der Email ist ein Fehler aufgetreten!', { variant: 'error' })
        }
    }

    return(
        <CustomPage
            title="Mitarbeiter einladen"
            maxWidth='laptop'
        >
            <CustomPaper title='Einladungslink'>
                <Stack
                    alignItems="center"
                    sx={{ p:2}}
                >
                    <Box
                        component="img"
                        src={InviteIMG}
                        alt="not found"
                        sx={{
                            width: 600
                        }}
                    />
                    <Box
                        sx={{ mt: 5, p: '5px 8px', display: 'flex', alignItems: 'center',  borderRadius: 2, border: '1px solid rgba(0, 0, 0, 0.12)'}}
                    >
                        <Typography
                            variant="text_m"
                            sx={{ m: 1, flex: 1, textAlign: 'center', fontWeight: 'bold' }}
                        >
                            {company.invite_token}
                        </Typography>
                        <CopyToClipboard onCopy={copyKeySnackbar} text={company.invite_token} >
                            <TooltipIconButton
                                icon={<ContentCopy />}
                                title="Registrierungsschlüssel kopieren"
                            />
                        </CopyToClipboard>
                        <Divider sx={{ height: 28, m: 0.5 }} orientation="vertical" />
                        <CopyToClipboard onCopy={copyLinkSnackbar} text={window.location.origin + '/register?key=' + company.invite_token}>
                            <TooltipIconButton
                                icon={<Link />}
                                title="Registrierungslink kopieren"
                            />
                        </CopyToClipboard>
                        <Divider sx={{ height: 28, m: 0.5 }} orientation="vertical" />
                        <TooltipIconButton
                            icon={<Email />}
                            title="Mitarbeiter per E-Mail einladen"
                            onClick={() => setOpenInvite(true)}
                        />
                    </Box>
                </Stack>
                <Dialog
                    open={openInvite}
                    onClose={() => setOpenInvite(false)}
                    maxWidth="laptop"
                >
                    <DialogTitle>
                        Mitarbeiter per E-Mail einladen
                    </DialogTitle>
                    <DialogContent>
                        <DialogContentText >
                            Geben Sie die E-Mail Adresse Ihres Mitarbeiters an.
                        </DialogContentText>
                        <TextField
                            id="email"
                            label="E-Mail"
                            type="email"
                            margin="normal"
                            value={email}
                            onChange={(event)=> setEmail(event.target.value)}
                            sx={{ width: '100%'}}
                        />
                    </DialogContent>
                    <DialogActions>
                        <Button
                            color="secondary"
                            onClick={() => setOpenInvite(false)}
                        >
                            Abbrechen
                        </Button>
                        <Button
                            onClick={sendEmail}
                        >
                            Verschicken
                        </Button>
                    </DialogActions>
                </Dialog>
            </CustomPaper>

        </CustomPage>
    )
}

export default CompanyInvite
