import { useCallback, useEffect, useState } from "react";
import {  useGridApiRef } from "@mui/x-data-grid-pro";

import MaterialTable from "./MaterialTable/MaterialTable"
import { formatPrice, formatValueWithUnit, formatDate, getTotalPrice } from "./MaterialDatagridUtils";



const MaterialTxTable = ({ open, onClose, project_id, selectedSubProject, subtitle, is_admin, is_manager, handleTXPatch, handleTXDelete, sublabel }) =>{

    const apiRef = useGridApiRef();
    const [isLoading, setIsLoading] = useState(false)
    const [txs, setTxs] = useState([])
    const [pageSize, setPageSize] = useState(50)
	const [searchTerm, setSearchTerm] = useState('')
	const [orderBy, setOrderBy] = useState('');
    const [pagInfo, setPagInfo] = useState({
		count: 0,
		next : null,
		previous: null,
	});

    const handlePageChange = (x) =>{
		getTx(pagInfo[x])
	}

	const handleOrderByGetParametersChange = params => {
		setOrderBy(params)
	}

    const getTx = useCallback(async(url) => {
		if(selectedSubProject === undefined || open === false) return
		setIsLoading(true)
        const res = await fetch(url ? url :'/api/project/' + project_id + '/sub-project/' + selectedSubProject + '/material/tx/0?limit=' + pageSize + '&search=' + searchTerm + '&' + orderBy, {
            method: 'GET'
        })
		const data = await res.json()
		if(res.ok){
			setTxs(data.results)
			setPagInfo({
				count: data.count,
				next : data.next,
				previous: data.previous,
			})
			// console.log(data)
		}
		setIsLoading(false)
    }, [project_id, selectedSubProject, pageSize, open, searchTerm, orderBy])

    useEffect(()=>{
		getTx()
	}, [open, getTx, pageSize])

    const columns = [
		{
			field: 'custom_id',
			headerName: 'TX-ID',
			type: 'string',
			minWidth: 50,
			editable: false,
			valueFormatter: (params) => {
				if (params.value == null) {
				  return '';
				}
				return `TX#${params.value}`;
			},
			sortable: false,
		},
		{
			field: 'unique_material_id',
			headerName: 'Artikel',
			type: 'string',
			minWidth: 80,
			editable: false,
			sortable: false,
		},
		{
			field: 'description',
			headerName: 'Artikelbezeichnung',
			type: 'string',
			minWidth: 250,
			editable: false,
			sortable: false,
		},
		{
			field: 'value',
			headerName: 'Menge',
			type: 'number',
			minWidth: 120,
			editable: false,
			valueFormatter: formatValueWithUnit,
		},
		...is_manager ? [
			{
				field: 'default_price',
				headerName: 'Lagerpreis',
				type: 'number',
				minWidth: 120,
				editable: false,
			    sortable: false,
				valueFormatter: formatPrice
			},
			{
				field: 'pre_price',
				headerName: 'Angebotspreis',
				type: 'number',
				minWidth: 120,
				editable: false,
				valueFormatter: formatPrice
			},
			{
				field: 'post_price',
				headerName: 'Nachkalk. Preis',
				type: 'number',
				minWidth: 120,
				editable: true,
				valueFormatter: formatPrice
			},
			{
				field: '_total_price',
				headerName: 'Gesamtpreis',
				type: 'number',
				minWidth: 120,
				editable: false,
				valueGetter: getTotalPrice,
				valueFormatter: formatPrice
			},
		] : [],
		{
			field: 'type',
			headerName: 'Art der Transaktion',
			type: 'string',
			minWidth: 150,
			editable: false,
			sortable: false,
		},
		{
			field: 'annotation',
			headerName: 'Bemerkung',
			type: 'string',
			flex:'1',
			minWidth: 80,
			editable: false,
			sortable: false,
		},
		{
			field: 'is_calculated',
			headerName: 'Kalkuliert',
			type: 'boolean',
			editable: is_manager ? true : false,
			minWidth: 40,
		},
		{
			field: 'creator',
			headerName: 'Ersteller',
			minWidth: 150,
			editable: false,
			sortable: false,
		},
		{
			field: 'created',
			headerName: 'erstellt am',
			minWidth: 150,
			align: 'right',
			valueFormatter: formatDate,
		},
	]

	const getConfirmMessage = row => `Möchtest du die Transaktion wirklich löschen?`

	return(
        <MaterialTable
            open={open}
            onClose={onClose}
            title="Depottransaktionen"
            subtitle={subtitle}
			sublabel={sublabel}
            apiRef={apiRef}
            rows={txs}
            pageSize={pageSize}
            setPageSize={setPageSize}
			rowCount={pagInfo.count}
            handlePageChange={handlePageChange}
            columns={columns}
            loading={isLoading}
			onSaveRow={is_admin ? handleTXPatch(getTx) : undefined}
			onDeleteRow={is_admin ? handleTXDelete(getTx) : undefined}
			getConfirmMessage={is_admin ? getConfirmMessage : undefined}
			setSearchTerm={setSearchTerm}
			searchTerm={searchTerm}
			onOrderByGetParametersChange={handleOrderByGetParametersChange}
        />
    )
}

export default MaterialTxTable
