import { Avatar, ListItem, ListItemAvatar, ListItemText } from '@mui/material'


const CompanyMemberListItem = ({
    data: { firstname, lastname, avatar, job_title },
    ...props
}) => {
    return (
        <ListItem {...props}>
            <ListItemAvatar>
                <Avatar src={avatar} />
            </ListItemAvatar>
            <ListItemText
                primary={firstname + ' ' + lastname}
                secondary={job_title}
            />
        </ListItem>
    )
}

export default CompanyMemberListItem