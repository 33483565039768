import { Stack, Typography } from "@mui/material"
import { formatNumber } from "../../../../../utils/helper"


const WarehouseActivityListItem = ({ tx, pos, correction=false }) =>{

    const _price = tx.price !== 0 ? tx.price : tx.standard_price
    const _prefix = tx.unit.split('/')[0]
    const _suffix = tx.unit.split('/')[1]

    return(
        <Stack
            direction='row'
            justifyContent='space-between'
            spacing={2}
        >
            <Stack
                direction='row'
                spacing={2}
            >
                <Typography variant="text_m" sx={{width: 60}}>{`Pos. ${pos}`}</Typography>
                <Typography variant="text_m" sx={{width: 85}}>{tx.unique_material_id}</Typography>
                <Typography variant="text_m" sx={{flex: 1}}>{tx.description}</Typography>
            </Stack>
            {correction ?
                <Typography variant="text_m" >{`${formatNumber(tx.value, 2, _suffix)}`} </Typography>
            :
                <Typography variant="text_m" >{`${formatNumber(tx.value, 2, _suffix)} x ${formatNumber(_price, 2, _prefix)} = ${formatNumber(_price * tx.value, 2, _prefix)}`} </Typography>
            }
        </Stack>
    )
}

export default WarehouseActivityListItem