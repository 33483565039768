import {Button, Dialog, DialogActions, DialogContent, DialogTitle, Stack} from "@mui/material";
import ControlledTextField from "../../../../Widgets/ControlledInputs/ControlledTextField";
import {useForm} from "react-hook-form";
import {getCookie} from "../../../../../utils/getCookie";
import setServerErrors from "../../../../../utils/setServerErrors";
import ControlledSelect from "../../../../Widgets/ControlledInputs/ControlledSelect";

const AddDialog = ({ open, onClose, getCustomers, genders }) => {
    const { handleSubmit, setError, control, reset, formState: { errors, isDirty, isValid }} = useForm({
        mode: 'onChange',
        defaultValues: {
            firstname: '',
            lastname: '',
            gender_key: '',
            fixed_phone_number: '',
            mobil_phone_number: '',
            email: '',
            street: '',
            house_number: '',
            add_on: '',
            postal_code: '',
            city: ''
        }
    })

    const addCustomer = async(customer) => {
        console.log(customer)
        const json = JSON.stringify({
            firstname: customer.firstname,
            lastname: customer.lastname,
            gender_key: customer.gender_key,
            fixed_phone_number: customer.fixed_phone_number,
            mobil_phone_number: customer.mobil_phone_number,
            email: customer.email,
            address: {
                street: customer.street,
                house_number: customer.house_number,
                add_on: customer.add_on,
                postal_code: customer.postal_code,
                city: customer.city
            }
        })
        const res = await fetch('/api/company/customer', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'X-CSRFToken': getCookie('csrftoken')
            },
            body: json
        })
        const data = await res.json()
        if(res.ok) {
            getCustomers()
            onClose()
            reset()
        } else {
            setServerErrors(data, setError)
        }
    }

    return (
        <Dialog
            open={open}
            onClose={onClose}
        >
            <form onSubmit={handleSubmit(addCustomer)}>
                <DialogTitle>
                    Privatkunden hinzufügen
                </DialogTitle>
                <DialogContent>
                    <Stack gap={2} sx={{ paddingTop: 1 }}>
                        <ControlledTextField
                            name='firstname'
                            label='Vorname'
                            type='text'
                            rules={{ required: true }}
                            errors={errors}
                            control={control}
                        />
                        <ControlledTextField
                            name='lastname'
                            label='Nachname'
                            type='text'
                            rules={{ required: true }}
                            errors={errors}
                            control={control}
                        />
                        <ControlledSelect
                            name='gender_key'
                            label='Geschlecht'
                            items={genders}
                            rules={{ required: true }}
                            errors={errors}
                            control={control}
                        />
                        <ControlledTextField
                            name='fixed_phone_number'
                            label='Telefon (Festnetz)'
                            type='text'
                            rules={{ required: false }}
                            errors={errors}
                            control={control}
                        />
                        <ControlledTextField
                            name='mobil_phone_number'
                            label='Telefon (Mobil)'
                            type='text'
                            rules={{ required: false }}
                            errors={errors}
                            control={control}
                        />
                        <ControlledTextField
                            name='email'
                            label='E-Mail'
                            type='email'
                            rules={{ required: false }}
                            errors={errors}
                            control={control}
                        />
                        <Stack
                            flexDirection='row'
                            gap={2}
                        >
                            <ControlledTextField
                                name='street'
                                label='Straße'
                                type='text'
                                rules={{ required: true }}
                                errors={errors}
                                control={control}
                                sx={{
                                    flex: 3
                                }}
                            />
                            <ControlledTextField
                                name='house_number'
                                label='Hausnummer'
                                rules={{ required: true }}
                                errors={errors}
                                control={control}
                                sx={{
                                    flex: 2
                                }}
                            />
                        </Stack>
                        <ControlledTextField
                            name='add_on'
                            label='Zusatz'
                            type='text'
                            errors={errors}
                            control={control}
                            sx={{
                                flex: 1
                            }}
                        />
                        <Stack
                            flexDirection='row'
                            gap={2}
                        >
                            <ControlledTextField
                                name='postal_code'
                                label='PLZ'
                                type='text'
                                rules={{ required: true }}
                                errors={errors}
                                control={control}
                                sx={{
                                    flex: 2
                                }}
                            />
                            <ControlledTextField
                                name='city'
                                label='Stadt'
                                type='text'
                                rules={{ required: true }}
                                errors={errors}
                                control={control}
                                sx={{
                                    flex: 3
                                }}
                            />
                        </Stack>
                    </Stack>
                </DialogContent>
                <DialogActions>
                    <Button
                        color="secondary"
                        onClick={onClose}
                    >
                        Abbrechen
                    </Button>
                    <Button
                        type='submit'
                        disabled={!isDirty || !isValid}
                    >
                        Hinzufügen
                    </Button>
                </DialogActions>
            </form>
        </Dialog>
    )
}

export default AddDialog
