import {useState, useEffect, useContext} from "react"
import ProjectContext from '../../../../context/ProjectContext'

import { Stack } from '@mui/material';
import { Folder } from '@mui/icons-material';

import CustomTabPanel from "../../../Widgets/CustomTabPanel";
import CheckSubProjects from "../../../CheckSubProjects";
import CustomTabs from "../../../Widgets/CustomTabs";
import ProjectStaticsContent from "./ProjectStaticContent";
import { useTaskTab } from "../../../../context/TaskTabContext";


const ProjectStatics = () =>{

    const [project] = useContext(ProjectContext)
    const [projectStatics, setProjectStatics] = useState([])
    const [tabs, setTabs] = useState([])
    const [value, setValue] = useTaskTab();
    const [isLoaded, setIsLoaded] = useState(false)

    const handleChange = (event, newValue) => {
        console.log(newValue)
        setValue(newValue);
    };

    // getProjectStatics
    useEffect(() => {
        (async () => {
            const res = await fetch('/api/project/' + project.id + '/statics', {
                method: 'GET'
            })
            const data = await res.json()
            if(res.ok){
                setProjectStatics(data)
                setTabs(data.map((projectStatic, idx) => ({ index: idx, label: projectStatic.title, icon: <Folder sx={{fontSize: '20px'}}/>})))
                setIsLoaded(true)
            }
        })()
    }, [project.id])

    return(
        <CheckSubProjects
            subs={projectStatics}
            isLoaded={isLoaded}
            info_text="Du brauchst erstmal ein Projektauftrag, um deine Statistik sehen zu können"
        >
            <Stack sx={{ flex: '1 1 0' }} gap={3}>
                <CustomTabs
                    value={value}
                    handleChange={handleChange}
                    tabs={tabs}
                />
                    {projectStatics.map((projectStatic, idx)=>{
                        return(
                            <CustomTabPanel
                                key={idx}
                                value={value}
                                index={idx}
                            >
                                <ProjectStaticsContent
                                    projectStatic={projectStatic}
                                />
                            </CustomTabPanel>
                        )
                    })}
            </Stack>
        </CheckSubProjects>


    )
}

export default ProjectStatics
