import { ACTIONS } from '../TicketHelpers'
import { Typography, Divider, Stack, IconButton, Card, CardMedia, CardContent, CardActions, CardActionArea, Chip } from '@mui/material';
import { Edit, Numbers, IosShare, Sync, Delete, HowToReg, Person} from '@mui/icons-material';

import { formatTimestamp } from '../../../../../utils/date_functions';

export const TicketCard = ({ idx, ticket, color, variant, dispatch }) =>{

    return(
        <Card
            sx={{
                // maxWidth: 500,
                minWidth: 250,
            }}
        >
            <CardActionArea onClick={() => dispatch({ type: ACTIONS.OPEN_TICKET_INFO, payload: { ticket: ticket }})}>
                <CardMedia
                    component="img"
                    height="194"
                    image={ticket.image}
                    alt="no image"
                />
                <CardContent>
                    <Typography gutterBottom variant="subtitle_m" component="div" sx={{margin: 0}}>
                        {ticket.title}
                    </Typography>
                    <Typography
                        variant="text_m"
                        component="div"
                        color= {ticket.provider_rights ? "primary" : "default"}
                    >
                        {ticket.service_provider.name}
                    </Typography>
                    <Typography gutterBottom variant="text_s" component="div">
                        Frist: {formatTimestamp(ticket.deadline)}
                    </Typography>
                    <Chip
                        label={ticket.status}
                        variant={variant}
                        color={color}
                        sx={{mb:1}}
                    />
                    <Divider textAlign="left" sx={{m:1}}>
                        <Typography variant="subtitle_s" sx={{color: 'background.light10', fontWeight: 'bold', fontSize:15}}>
                            Beschreibung
                        </Typography>
                    </Divider>
                    <Typography
                        variant="text_m"
                        color="text.primary"
                        sx={{display: 'block', height: 80, overflow: 'scroll'}}
                    >
                        {ticket.instruction}
                    </Typography>
                </CardContent>
            </CardActionArea>
            <CardActions disableSpacing>
                <Stack
                    direction='row'
                    justifyContent='space-between'
                    width='100%'
                >
                    {ticket.provider_rights  &&
                        <Stack
                            direction='row'
                        >
                            <IconButton
                                color="primary"
                                onClick={() => dispatch({ type: ACTIONS.OPEN_TICKET_SHARE, payload: { ticket: ticket }})}
                            >
                                <IosShare/>
                            </IconButton>
                            <IconButton
                                color="primary"
                                onClick={() => dispatch({ type: ACTIONS.OPEN_TICKET_STATE_CHANGE, payload: { ticket: ticket }})}
                            >
                                <Sync/>
                            </IconButton>
                            <Stack
                                justifyContent="center"
                                alignItems="center"
                            >
                                {ticket.shared.length !== 0 ?
                                    ticket.shared[idx]?.done ? <HowToReg color="primary"/> : <Person color="secondary"/>
                                :
                                    <Person color="background.light75"/>
                                }
                            </Stack>
                        </Stack>
                    }
                    {ticket.creator_rights  &&
                        <Stack
                            direction='row'
                        >
                            <IconButton onClick={() => dispatch({ type: ACTIONS.OPEN_TICKET_EDIT, payload: { ticket: ticket }})}>
                                <Edit color="primary"/>
                            </IconButton>
                            <IconButton onClick={() => dispatch({ type: ACTIONS.OPEN_TICKET_DELETE, payload: { ticket: ticket }})}>
                                <Delete color="error"/>
                            </IconButton>
                        </Stack>
                    }
                    <Chip label={<Typography variant="subtitle_m" sx={{color: 'background.light10'}}>{ticket.rank}</Typography>} variant='outlined' icon={<Numbers/>}/>
                </Stack>
            </CardActions>
        </Card>
    )
}

export default TicketCard