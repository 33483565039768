import { useGridApiRef } from '@mui/x-data-grid-pro';
import { DateColumn, TimeColumn } from '../../../Widgets/CustomDatagrids/CustomColumns'
import PaperDataGrid from "../../../Widgets/CustomDatagrids/NoPagination/PaperDataGrid";


const ProjectTimestampsTable = ({ project_timestamps }) =>{

    const apiRef = useGridApiRef();
    const getCompanyMemberName = (params) =>{
        return params.row.firstname + ' ' + params.row.lastname
    }

    const columns = [
		DateColumn({
			field: 'date',
			headerName: 'Datum',
			minWidth: 200,
			editable: false,
		}),
		{
			field: 'name',
			headerName: 'Mitarbeiter',
			type: 'string',
			minWidth: 180,
			editable: false,
            valueGetter:(getCompanyMemberName)
		},
		{
			field: 'description',
			headerName: 'Beschreibung',
			type: 'string',
			minWidth: 100,
			editable: false,
            flex:'1',
		},
		{
			field: 'type',
			headerName: 'Stundentyp',
			type: 'string',
			minWidth: 200,
			editable: false,
		},
		TimeColumn({
			field: 'workload_gross',
			headerName: 'Arbeitszeit (brutto)',
			minWidth: 150,
			editable: false,
		}),
		TimeColumn({
			field: 'breaktime',
			headerName: 'Pausenzeit',
			minWidth: 150,
			editable: false,
		}),
		TimeColumn({
			field: 'workload_net',
			headerName: 'Arbeitszeit (netto)',
			minWidth: 150,
			editable: false,
		}),
	];


    return(
        <PaperDataGrid
            title="Projektzeitkonto"
            apiRef={apiRef}
            rows={project_timestamps}
            columns={columns}
        />
    )
}

export default ProjectTimestampsTable