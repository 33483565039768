import React, { useState, useContext, useEffect } from 'react'
import CompanyContext from '../../../../context/CompanyContext'

import { Stack } from '@mui/material';
import { useGridApiRef } from '@mui/x-data-grid-pro';

import { getCookie } from '../../../../utils/getCookie'
import { getConstants } from '../../../../utils/getConstants'

import CompanyUpdateForm from './CompanyUpdateForm';
import CustomPage from '../../../Widgets/CustomPage';

import PaperDataGrid from "../../../Widgets/CustomDatagrids/NoPagination/PaperDataGrid";
import CompanyLogoUpload from './CompanyLogoUpload';



const CompanyManagement = () =>{

    const [, companyMembers, , getCompanyMembers, companyDisabledMembers] = useContext(CompanyContext)

    const apiRef = useGridApiRef()
    const apiRef_disabled = useGridApiRef()
    const [jobTitles, setJobTitles] =  useState({})
    const [errors, setErros] =  useState({})

    const saveChanges = async(row) => {
        const body = JSON.stringify(row)
        const res = await fetch('/api/company/members', {
            method: 'PATCH',
            headers: {
                'Content-Type': 'application/json',
                'X-CSRFToken': getCookie('csrftoken')
            },
            body: body
        })
        const data = await res.json()
        if (res.ok) {
            getCompanyMembers()
        } else {
            console.log(data)
            setErros(data)
            apiRef.current.setRows(companyMembers)
        }
    }

    const handleDelete = async (id)  => {
        const json = JSON.stringify({
            company_member_id: id,
        })
        const res = await fetch('/api/company/members', {
            method: 'DELETE',
            headers: {
                'Content-Type': 'application/json',
                'X-CSRFToken': getCookie('csrftoken')
            },
            body: json
        })
        if (res.ok) {
            getCompanyMembers()
        }
        return res.ok
    }

    const handleSave = async (row) => {
        saveChanges(row)
    };

    const columns = [
        {
            field: 'personnel_number',
            headerName: 'Personalnummer',
            type: 'string',
            minWidth: 150,
            editable: true,
        },
        {
            field: 'firstname',
            headerName: 'Vorname',
            type: 'string',
            minWidth: 150,
        },
        {
            field: 'lastname',
            headerName: 'Nachname',
            type: 'string',
            minWidth: 150,
        },
        {
            field: 'job_title_key',
            headerName: 'Job',
            type: 'singleSelect',
            flex:'1',
            minWidth: 100,
            editable: true,
            valueOptions: () =>{
                let job_titles_list = []
                for(let job_title in jobTitles){
                    job_titles_list.push({value: job_title, label: jobTitles[job_title]})
                }
                return job_titles_list;
            },
            valueFormatter: (params) => {
                return jobTitles[params.value];
            }
        },
        {
            field: 'is_admin',
            headerName: 'Administratorrechte',
            type: 'boolean',
            minWidth: 150,
            editable: true,
        },
        {
            field: 'is_manager',
            headerName: 'Managerrechte',
            type: 'boolean',
            minWidth: 120,
            editable: true,
        },
        {
            field: '_allow_conmeet_connections',
            headerName: 'CONMEET Netzwerk',
            type: 'boolean',
            minWidth: 170,
            editable: true,
        },
    ]

    const columns_disabled = [
        {
            field: 'personnel_number',
            headerName: 'Personalnummer',
            type: 'string',
            minWidth: 150,
            editable: true,
        },
        {
            field: 'firstname',
            headerName: 'Vorname',
            type: 'string',
            minWidth: 150,
        },
        {
            field: 'lastname',
            headerName: 'Nachname',
            type: 'string',
            minWidth: 150,
        },
        {
            field: 'job_title_key',
            headerName: 'Job',
            type: 'singleSelect',
            flex:'1',
            minWidth: 100,
            editable: true,
            valueOptions: () =>{
                let job_titles_list = []
                for(let job_title in jobTitles){
                    job_titles_list.push({value: job_title, label: jobTitles[job_title]})
                }
                return job_titles_list;
            },
            valueFormatter: (params) => {
                return jobTitles[params.value];
            }
        },
    ]

    useEffect(() => {
        getConstants('job_titles', (c) =>{setJobTitles(c)})
    }, [])

    const getConfirmMessage = row => `Möchtest du den Mitarbeiter ${row.firstname} ${row.lastname} wirklich löschen?`

    return(
        <CustomPage
            title="Verwaltung &amp; Berechtigungen"
        >
            <Stack
                spacing={3}
                >
                <Stack
                    spacing={3}
                    direction={'row'}
                    alignItems='flex-start'
                    >
                    <CompanyUpdateForm/>
                    <CompanyLogoUpload/>
                </Stack>
                <PaperDataGrid
                    rows={companyMembers}
                    columns={columns}
                    apiRef={apiRef}
                    title={'Mitarbeiter'}
                    errors={errors}
                    onSaveRow={handleSave}
                    onDeleteRow={handleDelete}
                    getConfirmMessage={getConfirmMessage}
                />
                <PaperDataGrid
                    rows={companyDisabledMembers}
                    columns={columns_disabled}
                    apiRef={apiRef_disabled}
                    title={'Gelöschte Mitarbeiter'}
                />
            </Stack>
        </CustomPage>
    )
}

export default CompanyManagement
