import { Paper, Stack, Typography } from '@mui/material'
import CustomFromIconButton from './CustomFromIconButton';


const CustomFormPaper = ({ title, onSubmit, disabled, children, loading, success, tooltip="Speichern"}) =>{

    return(
        <Paper
            component="form"
            id={`form-id-${title}`}
            onSubmit={onSubmit}
            elevation={1}
            sx={{ 
                display: 'flex',
                flexDirection: 'column',
            }}
        >
            <Stack
                direction={'row'}
                justifyContent={'space-between'}
                alignItems='center'
                sx={{ pl:2, pr:2, pt:1 , pb:0 }}
            >
                <Typography variant="title_paper">
                    {title}
                </Typography>
                <CustomFromIconButton
                    loading={loading}
                    success={success}
                    disabled={disabled}
                />
            </Stack>
            <Stack sx={{ flexGrow: 1, p:2 }} >
                {children}
            </Stack>
        </Paper>
    )
}

export default CustomFormPaper