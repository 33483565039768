import { useState } from "react";

import { Badge, Stack, ToggleButton, ToggleButtonGroup, Typography, IconButton } from "@mui/material";
import { styled } from '@mui/material/styles';

import Todo from "components/Dashboard/Todos/Todo";
import NoData from "../NoData";

import { BsFillCircleFill } from "react-icons/bs";
import { BiArrowFromTop, BiArrowToBottom } from "react-icons/bi";
import { Add } from "@mui/icons-material";

const StyledBadge = styled(Badge)(({ theme }) => ({
    '& .MuiBadge-badge': {
      right: -18,
      top: 12,
      padding: '8 8px',
    },
}));

const doneFilter = (todos) => {
    return todos.filter(t => t.done !== true).length
}

const TodoManager = ({ todos, getTodos, setDialogOpen, inProject=false }) => {

    const [toggles, setToggles] = useState(['private', 'received', 'created'])
    const handleToggle = (event, toggle) => {
        if(toggle.length < 1) return
        setToggles(toggle);
    };

    return (
        <Stack
            alignItems="flex-end"
            height="100%"
            gap={3}
        >
            <ToggleButtonGroup
                color="primary"
                value={toggles}
                onChange={handleToggle}
                sx={{  height: 40, width: 180}}
            >
                <ToggleButton value={'private'} sx={{width: '100%'}}>
                    <BsFillCircleFill/>
                </ToggleButton>
                <ToggleButton value={'received'} sx={{width: '100%'}}>
                    <BiArrowToBottom size={22}/>
                </ToggleButton>
                <ToggleButton value={'created'} sx={{width: '100%'}}>
                    <BiArrowFromTop size={22}/>
                </ToggleButton>
            </ToggleButtonGroup>
            <Stack
                direction="row"
                justifyContent="space-between"
                width="100%"
                height="100%"
                gap={3}
            >
                {toggles.find(x => x === 'private') &&
                    <Stack
                        width="100%"
                    >
                        <Stack
                            direction="row"
                            justifyContent="space-between"
                            alignItems="center"
                            gap={2}
                            width="100%"
                            height={50}
                        >
                            <StyledBadge
                                badgeContent={doneFilter(todos.private)}
                                color="secondary"
                            >
                                <Typography variant="subtitle_m" color="background.light20" sx={{pl: 1}}>
                                    Eigene Aufgaben
                                </Typography>
                            </StyledBadge>
                            <IconButton
                                color="primary"
                                size="large"
                                onClick={()=> setDialogOpen('private')}
                            >
                                <Add fontSize="large"/>
                            </IconButton>
                        </Stack>
                        <Stack
                            gap={2}
                            sx={{
                                p:1,
                                overflow: 'auto',
                                maxHeight: `calc(100vh - ${inProject ? '300px' : '220px'})`
                            }}
                        >
                            {todos.private.map((t, idx) =>
                                <Todo
                                    key={`todo-${t.id}`}
                                    id={t.id}
                                    companyMember={t.recipient}
                                    description={t.description}
                                    done={t.done}
                                    getTodos={getTodos}
                                    editable={true}
                                    deadline={t.deadline}
                                    sub_project={t.sub_project}
                                    created={t.created}
                                    projectTodo={inProject}
                                />
                        )}
                        </Stack>
                        {todos.private.length === 0 &&
                            <Stack
                                sx={{ p: 2 }}
                            >
                                <NoData text="Keine Aufgaben erstellt"/>
                            </Stack>
                        }
                    </Stack>
                }
                {toggles.find(x => x === 'received') &&
                    <Stack
                        flexDirection="row"
                        width="100%"
                        gap={3}
                    >
                        <Stack
                            width="100%"
                        >
                            <Stack
                                direction="row"
                                justifyContent="space-between"
                                alignItems="center"
                                gap={2}
                                width="100%"
                                height={50}
                            >
                                <StyledBadge
                                    badgeContent={doneFilter(todos.received)}
                                    color="secondary"
                                >
                                    <Typography variant="subtitle_m" color="background.light20" sx={{pl: 1}}>
                                        Erhaltene Aufgaben
                                    </Typography>
                                </StyledBadge>
                            </Stack>
                            <Stack
                                gap={2}
                                sx={{
                                    p:1,
                                    overflow: 'auto',
                                    maxHeight: `calc(100vh - ${inProject ? '300px' : '220px'})`
                                }}
                            >
                                {todos.received.map((t, idx) =>
                                    <Todo
                                        key={`todo-${t.id}`}
                                        id={t.id}
                                        companyMember={t.creator}
                                        description={t.description}
                                        done={t.done}
                                        getTodos={getTodos}
                                        deadline={t.deadline}
                                        sub_project={t.sub_project}
                                        created={t.created}
                                        projectTodo={inProject}
                                    />
                                )}
                            </Stack>
                            {todos.received.length === 0 &&
                                <Stack
                                    sx={{ p: 2 }}
                                >
                                    <NoData text="Keine Aufgaben erhalten"/>
                                </Stack>
                            }
                        </Stack>
                    </Stack>
                }
                {toggles.find(x => x === 'created') &&
                    <Stack
                        flexDirection="row"
                        width="100%"
                        gap={3}
                    >
                        <Stack
                            width="100%"
                        >
                            <Stack
                                direction="row"
                                justifyContent="space-between"
                                alignItems="center"
                                gap={2}
                                width="100%"
                                height={50}
                            >
                                <StyledBadge
                                    badgeContent={doneFilter(todos.created)}
                                    color="secondary"
                                >
                                    <Typography variant="subtitle_m" color="background.light20" sx={{pl: 1}}>
                                        Erstellte Aufgaben
                                    </Typography>
                                </StyledBadge>
                                <IconButton
                                    color="primary"
                                    size="large"
                                    onClick={()=> setDialogOpen('created')}
                                >
                                    <Add fontSize="large"/>
                                </IconButton>
                            </Stack>
                        <Stack
                            gap={2}
                            sx={{
                                    p:1 ,
                                    overflow: 'auto',
                                    maxHeight: `calc(100vh - ${inProject ? '300px' : '220px'})`
                                }}
                            >
                                {todos.created.map((t, idx) =>
                                    <Todo
                                        key={`todo-${t.id}`}
                                        id={t.id}
                                        companyMember={t.recipient}
                                        description={t.description}
                                        done={t.done}
                                        getTodos={getTodos}
                                        editable={true}
                                        deadline={t.deadline}
                                        sub_project={t.sub_project}
                                        created={t.created}
                                        projectTodo={inProject}
                                    />
                                )}
                            </Stack>
                            {todos.created.length === 0 &&
                                <Stack
                                    sx={{ p: 2 }}
                                >
                                    <NoData text="Keine Aufgaben vergeben"/>
                                </Stack>
                            }
                        </Stack>
                    </Stack>
                }
            </Stack>
        </Stack>
    );
}

export default TodoManager;
