import { useEffect, useState, useContext } from 'react'
import FormDialog from './FormDialog'
import AccountContext from '../../../context/AccountContext';
import CustomPage from "../../Widgets/CustomPage";
import TodoManager from 'components/Widgets/TodoManager';



const Todos = ({ sx }) => {

    const [account] = useContext(AccountContext)
    const [todos, setTodos] = useState({
        created: [],
        received: [],
        private: []
    })
    const [projectKeys, setProjectKeys] = useState({})

    const getTodos = async () => {
        const res = await fetch('/api/company/todos')
        if (res.ok) {
            const json = await res.json()
            // console.log(json)
            setTodos({
                created: json.created,
                received: json.recieved, // TODO fix in backend
                private: json.private
            })
        }
    }

    const getProjectKeys = async() => {
        const res = await fetch('/api/projects/get-sub-selection', {
            method: 'GET'
        })
        const data = await res.json()
        if(res.ok){
            setProjectKeys(data)
        }
    }

    useEffect(() => {
        getTodos()
        getProjectKeys()
    }, [])

    const [dialogOpen, setDialogOpen] = useState('')

    return (
        <CustomPage title='To-dos'>
            <TodoManager
                todos={todos}
                setDialogOpen={setDialogOpen}
                getTodos={getTodos}
            />
            <FormDialog
                open={dialogOpen}
                handleClose={() => setDialogOpen('')}
                getTodos={getTodos}
                companyMemberID={account.id}
                projectKeys={projectKeys}
            />
        </CustomPage>
    )
}

export default Todos
