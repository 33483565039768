import { useState } from 'react'
import { Stack, Dialog, DialogTitle,  DialogContent, DialogContentText,Typography, IconButton, Tooltip, Box, Divider, Grid, Button } from "@mui/material"

import { BsFillLockFill, BsUnlockFill, BsFillFileEarmarkFill, BsFillFileEarmarkTextFill, BsFillFileEarmarkCheckFill } from "react-icons/bs";
import { Delete, Edit, Publish } from '@mui/icons-material'

import TaskHandOverDialog from '../TaskHandOverDialog';
import ConfirmDialog from 'components/Widgets/ConfirmDialog'

import { formatDateString, formatTimestamp } from '../../../../../utils/date_functions';
import { formatNumber } from '../../../../../utils/helper';
import { getCookie } from '../../../../../utils/getCookie'

import FolderPrivate from 'assets/svgs/conmeet_folder_private.svg'
import FolderPosted from 'assets/svgs/conmeet_folder_posted.svg'
import FolderReceived from 'assets/svgs/conmeet_folder_received.svg'



const TaskDialog = ( props ) => {

    const { open, onClose, task, project_id, handleOpenEdit, getProjectTasks } = props

    const ICONSIZE = 25
    const [openOver, setOpenOver] = useState(false)

    const handleOptions = async(type, role) => {
        const json = JSON.stringify({
            sub_project_id: task.id,
            type: type,
            role: role,
        })
        const res = await fetch('/api/project/' + project_id + '/tasks/options', {
            method: 'PATCH',
            headers: {
                'Content-Type': 'application/json',
                'X-CSRFToken': getCookie('csrftoken')
            },
            body: json,
        })
        if (res.ok) {
            getProjectTasks()
        }
    };

    const handleDelete = async() => {
        const json = JSON.stringify({
            sub_project_id: task.id
        })
        const res = await fetch('/api/project/' + project_id + '/tasks', {
            method: 'DELETE',
            headers: {
                'Content-Type': 'application/json',
                'X-CSRFToken': getCookie('csrftoken')
            },
            body: json,
        })
        if (res.ok) {
            getProjectTasks()
        }
    };

    const handleFolderImg= () =>{
        if(task.posted) return FolderPosted
        if(task.private) return FolderPrivate
        if(task.received) return FolderReceived
        return FolderPrivate
    }

    return(
        <Box>
            <Dialog
                maxWidth="laptop"
                open={open}
                onClose={onClose}
                fullWidth
            >
                <Stack
                    direction={'row'}
                    justifyContent={'space-between'}
                >
                    <DialogTitle>
                        {task.title}
                    </DialogTitle>
                    <Box
                        sx={{
                            p: 2
                        }}
                    >
                        <Typography variant="subtitle_m">
                            {formatDateString(task.expected_to_start)} - {formatDateString(task.expected_to_end)}
                        </Typography>
                    </Box>

                </Stack>
                <DialogContent>
                    <Stack
                        direction={'row'}
                        gap={2}
                    >
                        <Stack
                            gap={3}
                            sx={{ flex:1 }}
                        >
                            <DialogContentText>
                                    {task.instruction}
                            </DialogContentText>
                            {task.private ?
                                <Stack>
                                    <Typography variant="text_s">{"Auftrag"}</Typography>
                                    <Typography variant="subtitle_s">Eigener Auftrag</Typography>
                                </Stack>
                            :
                                <Stack
                                    gap={3}
                                >
                                    <Stack>
                                        <Typography variant="text_s">{"Auftrag vergeben durch"}</Typography>
                                        <Typography variant="subtitle_s">{task.customer?.name}</Typography>
                                    </Stack>
                                    <Stack>
                                        <Typography variant="text_s">{"Auftrag angenommen von"}</Typography>
                                        <Typography variant="subtitle_s">{task.service_provider !== null ? task.service_provider?.name : "--"}</Typography>
                                    </Stack>
                                </Stack>
                            }
                            {!task.received &&
                                <Stack>
                                    <Typography variant="text_s">Übergestellter Projektauftrag</Typography>
                                    <Box>
                                        <Typography
                                            variant="subtitle_s"
                                        >
                                                {task.parent_sub_project_name}
                                        </Typography>
                                    </Box>
                                </Stack>
                            }
                            {task.privat_customer_outside &&
                                <Stack>
                                    <Typography variant="subtitle_s">Privatkunde</Typography>
                                    <Typography
                                        variant="text_s"
                                    >
                                            {`${task.privat_customer_outside.firstname} ${task.privat_customer_outside.lastname} `}
                                    </Typography>
                                    <Typography
                                        variant="text_s"
                                    >
                                            {`Tel. ${task.privat_customer_outside.fixed_phone_number}`}
                                    </Typography>
                                    <Typography
                                        variant="text_s"
                                    >
                                            {`Mobil. ${task.privat_customer_outside.mobil_phone_number}`}
                                    </Typography>
                                    <Typography
                                        variant="text_s"
                                    >
                                            {`${task.privat_customer_outside.address.street} ${task.privat_customer_outside.address.house_number}${task.privat_customer_outside.address.add_on ? ', ' + task.privat_customer_outside.address.add_on : ''}`}
                                    </Typography>
                                    <Typography
                                        variant="text_s"
                                    >
                                            {`${task.privat_customer_outside.address.postal_code} ${task.privat_customer_outside.address.city} `}
                                    </Typography>
                                </Stack>
                            }
                            {task.buisness_customer_outside &&
                                <Stack>
                                    <Typography variant="subtitle_s">Gewerbekunde</Typography>
                                    <Typography
                                        variant="text_s"
                                    >
                                            {`${task.buisness_customer_outside.name}`}
                                    </Typography>
                                    <Typography
                                        variant="text_s"
                                    >
                                            {`Tel. ${task.buisness_customer_outside.fixed_phone_number}`}
                                    </Typography>
                                    <Typography
                                        variant="text_s"
                                    >
                                            {`Mobil. ${task.buisness_customer_outside.mobil_phone_number}`}
                                    </Typography>
                                    <Typography
                                        variant="text_s"
                                    >
                                            {`${task.buisness_customer_outside.address.street} ${task.buisness_customer_outside.address.house_number}${task.buisness_customer_outside.address.add_on ? ', ' + task.buisness_customer_outside.address.add_on : ''}`}
                                    </Typography>
                                    <Typography
                                        variant="text_s"
                                    >
                                            {`${task.buisness_customer_outside.address.postal_code} ${task.buisness_customer_outside.address.city} `}
                                    </Typography>
                                </Stack>
                            }
                            <Grid
                                container
                                spacing={0.5}
                            >
                                <Grid item mobile={6} laptop={6} desktop={6}>
                                    <Typography variant="text_m">Auftragssumme</Typography>
                                </Grid>
                                <Grid item mobile={6} laptop={6} desktop={6}>
                                    <Typography variant="subtitle_s">{formatNumber(task.value, 2,'€')}</Typography>
                                </Grid>
                                <Grid item mobile={6} laptop={6} desktop={6}>
                                    <Typography variant="text_m">Auftragsnummer | Aufraggeber</Typography>
                                </Grid>
                                <Grid item mobile={6} laptop={6} desktop={6}>
                                    <Typography variant="subtitle_s">{task.contract_number_customer}</Typography>
                                </Grid>
                                <Grid item mobile={6} laptop={6} desktop={6}>
                                    <Typography variant="text_m">Auftragsnummer | Dienstleister</Typography>
                                </Grid>
                                <Grid item mobile={6} laptop={6} desktop={6}>
                                    <Typography variant="subtitle_s">{task.contract_number_service_provider}</Typography>
                                </Grid>
                                <Grid item mobile={6} laptop={6} desktop={6}>
                                    <Typography variant="text_m">Verbleibende Tage</Typography>
                                </Grid>
                                <Grid item mobile={6} laptop={6} desktop={6}>
                                    <Typography variant="subtitle_s">{`${task.left_days} ${task.left_days === 1 ? "Tag" : "Tage"}`}</Typography>
                                </Grid>
                                <Grid item mobile={6} laptop={6} desktop={6}>
                                    <Typography variant="text_m">Nach Aufwand abrechnen</Typography>
                                </Grid>
                                <Grid item mobile={6} laptop={6} desktop={6}>
                                    <Typography variant="subtitle_s">{task.at_cost ? "Ja" : "Nein"}</Typography>
                                </Grid>
                                {(task.private ? true : task.received) &&
                                    <>
                                        <Grid item mobile={6} laptop={6} desktop={6}>
                                            <Typography variant="text_m">Vergebene Projektaufträge</Typography>
                                        </Grid>
                                        <Grid item mobile={6} laptop={6} desktop={6}>
                                            <Typography variant="subtitle_s">{formatNumber(task.current_task, 2,'€')}</Typography>
                                        </Grid>
                                        <Grid item mobile={6} laptop={6} desktop={6}>
                                            <Typography variant="text_m">Kalkulierte Arbeitstunden</Typography>
                                        </Grid>
                                        <Grid item mobile={6} laptop={6} desktop={6}>
                                            <Typography variant="subtitle_s">{formatNumber(parseFloat(task.max_workload), 2,'Std.')}</Typography>
                                        </Grid>
                                        <Grid item mobile={6} laptop={6} desktop={6}>
                                            <Typography variant="text_m">Fahrtkostenpauschale</Typography>
                                        </Grid>
                                        <Grid item mobile={6} laptop={6} desktop={6}>
                                            <Typography variant="subtitle_s">{formatNumber(parseFloat(task.drive_rate), 2,'€.')}</Typography>
                                        </Grid>
                                        <Grid item mobile={6} laptop={6} desktop={6}>
                                            <Typography variant="text_m">Stundenverrechnungssatz ohne Lohngewinn</Typography>
                                        </Grid>
                                        <Grid item mobile={6} laptop={6} desktop={6}>
                                            <Typography variant="subtitle_s">{formatNumber(parseFloat(task.wage_rate), 2,'€/Std.')}</Typography>
                                        </Grid>
                                        <Grid item mobile={6} laptop={6} desktop={6}>
                                            <Typography variant="text_m">Kalkulierte Materialkosten</Typography>
                                        </Grid>
                                        <Grid item mobile={6} laptop={6} desktop={6}>
                                            <Typography variant="subtitle_s">{formatNumber(task.max_material, 2,'€')}</Typography>
                                        </Grid>
                                        <Grid item mobile={6} laptop={6} desktop={6}>
                                            <Typography variant="text_m">Kalkulierte externe Auftragskosten</Typography>
                                        </Grid>
                                        <Grid item mobile={6} laptop={6} desktop={6}>
                                            <Typography variant="subtitle_s">{formatNumber(task.max_task, 2,'€')}</Typography>
                                        </Grid>
                                    </>}
                            </Grid>
                        </Stack>
                        <Box>
                            <Divider orientation='vertical'/>
                        </Box>
                        <Stack
                            spacing={3}
                            sx={{
                                width: 400,
                                p: 2
                            }}
                        >
                            <Stack
                                alignItems={'center'}
                            >
                                <Box
                                    component="img"
                                    src={handleFolderImg()}
                                    alt="not found"
                                    sx={{
                                        width: '60%',
                                    }}
                                />
                            </Stack>
                            <Stack
                                spacing={2}
                            >
                                <Stack
                                    direction='row'
                                    justifyContent='space-between'
                                    alignItems='center'
                                    spacing={2}
                                >
                                    <Tooltip
                                        title={task.locked_customer ? "Auftrag entsperren (Auftraggeber)" : "Auftrag sperren (Auftraggeber)"}
                                    >
                                        <span>
                                            <IconButton
                                                onClick={()=> handleOptions('lock', 'customer')}
                                                color={task.locked_customer ? "primary" : "secondary"}
                                                size="large"
                                                disabled={task.private ? false : !task.posted}
                                            >
                                                {task.locked_customer ? <BsFillLockFill size={ICONSIZE}/> : <BsUnlockFill size={ICONSIZE}/> }
                                            </IconButton>
                                        </span>
                                    </Tooltip>
                                    <Stack
                                        alignItems='center'
                                        sx={{flex:1}}
                                    >
                                        <Typography variant="text_s" color="primary">
                                            {task.locked_date ? "Vertrag gesperrt" : "Vertrag offen" }
                                        </Typography>
                                        <Divider flexItem/>
                                        {task.locked_date &&
                                            <Typography variant="caption" sx={{ color: 'background.light10', fontSize: 14}}>
                                                {formatTimestamp(task.locked_date)}
                                            </Typography>
                                        }
                                    </Stack>
                                    <Tooltip
                                        title={task.locked_service_provider ? "Auftrag entsperren (Dienstleister)" : "Auftrag sperren (Dienstleister)"}
                                    >
                                        <span>
                                            <IconButton
                                                onClick={()=> handleOptions('lock', 'service_provider')}
                                                color={task.locked_service_provider ? "primary" : "secondary"}
                                                size="large"
                                                disabled={task.private ? false : !task.received}
                                            >
                                                {task.locked_service_provider ? <BsFillLockFill size={ICONSIZE}/> : <BsUnlockFill size={ICONSIZE}/> }
                                            </IconButton>
                                        </span>
                                    </Tooltip>
                                </Stack>
                                <Stack
                                    direction='row'
                                    alignItems='center'
                                    spacing={2}
                                >
                                    <Tooltip
                                        title={task.done_service_provider ? "Bauabnahme zurückziehen (Dienstleister)" : "Bauabnahme beantragen (Dienstleister)"}
                                    >
                                        <span>
                                            <IconButton
                                                onClick={()=> handleOptions('done', 'service_provider')}
                                                color={task.done_service_provider ? "primary" : "default"}
                                                size="large"
                                                disabled={task.private ? false : task.posted}
                                            >
                                                {task.done_service_provider ? <BsFillFileEarmarkTextFill size={ICONSIZE}/> : <BsFillFileEarmarkFill size={ICONSIZE}/> }
                                            </IconButton>
                                        </span>
                                    </Tooltip>
                                    <Stack flex={1} gap={0.5}>
                                        <Typography variant="text_s">
                                            Dienstleister
                                        </Typography>
                                        <Divider flexItem/>
                                        <Typography variant="caption_m" color="primary">
                                            {task.done_service_provider_date ? "Bauabnahme beantragt" : "Bauabnahme beantragen" }
                                        </Typography>
                                        {task.done_service_provider_date &&
                                            <Typography variant="text_s">
                                                {formatTimestamp(task.done_service_provider_date)}
                                            </Typography>
                                        }
                                    </Stack>
                                </Stack>
                                <Stack
                                    direction='row'
                                    alignItems='center'
                                    spacing={2}

                                >
                                    <Tooltip
                                        title={task.done_customer ? "Auftragsabschluss zurückziehen (Auftraggeber)" : "Auftrag abschließen (Auftraggeber)"}
                                    >
                                        <span>
                                            <IconButton
                                                onClick={()=> handleOptions('done', 'customer')}
                                                color={task.done_customer ? "primary" : "default"}
                                                size="large"
                                                disabled={task.private ? false : task.received}
                                            >
                                                {task.done_customer ? <BsFillFileEarmarkCheckFill size={ICONSIZE}/> : <BsFillFileEarmarkFill size={ICONSIZE}/> }
                                            </IconButton>
                                        </span>
                                    </Tooltip>
                                    <Stack flex={1} gap={0.5}>
                                        <Typography variant="text_s">
                                            Auftraggeber
                                        </Typography>
                                        <Divider flexItem/>
                                        <Typography variant="caption_m" color="primary">
                                            {task.done_customer ? "Bauabnahme abgenommen" : "Bauabnahme abnehmen" }
                                        </Typography>
                                        {task.done_customer &&
                                            <Typography variant="text_s">
                                                {formatTimestamp(task.done_customer_date)}
                                            </Typography>
                                        }
                                    </Stack>
                                </Stack>
                            </Stack>
                            <Stack
                                spacing={2}
                            >
                                {task.private &&
                                    <Tooltip
                                        title={"Auftrag übergeben"}
                                    >
                                        <span>
                                            <Button
                                                onClick={()=> setOpenOver(true)}
                                                color="primary"
                                                size="large"
                                                disabled={task.locked_customer || task.locked_service_provider}
                                                startIcon={<Publish fontSize="large"/>}
                                                variant={'outlined'}
                                                fullWidth
                                            >
                                                Auftrag übergeben
                                            </Button>
                                        </span>
                                    </Tooltip>
                                }
                                <Tooltip
                                    title={"Auftrag bearbeiten"}
                                >
                                    <span>
                                        <Button
                                            onClick={()=> {handleOpenEdit(task)}}
                                            color="primary"
                                            size="large"
                                            disabled={task.locked_customer || task.locked_service_provider}
                                            startIcon={<Edit fontSize="large"/>}
                                            variant={'outlined'}
                                            fullWidth
                                        >
                                            Auftrag bearbeiten
                                        </Button>
                                    </span>
                                </Tooltip>
                                <Tooltip
                                    title={"Auftrag löschen"}
                                >
                                    <span>
                                        <ConfirmDialog
                                            title='Achtung'
                                            message={'Möchtest du wirklich den Projektauftrag löschen?'}
                                            onConfirm={()=> handleDelete()}
                                            renderButton={(onClick) => (
                                                <Button
                                                    onClick={onClick}
                                                    color="error"
                                                    size="large"
                                                    disabled={task.locked_customer || task.locked_service_provider }
                                                    startIcon={<Delete  fontSize="large"/>}
                                                    variant={'outlined'}
                                                    fullWidth
                                                >
                                                    Auftrag löschen
                                                </Button>
                                            )}
                                        />
                                    </span>
                                </Tooltip>
                            </Stack>
                        </Stack>    
                    </Stack>
                </DialogContent>
            </Dialog>
            <TaskHandOverDialog
                open={openOver}
                onClose={() => setOpenOver(false)}
                task={task}
                project_id={project_id}
                getProjectTasks={getProjectTasks}
            />
        </Box>
    )
}

export default TaskDialog