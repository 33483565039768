import { Stack, Button, Typography } from '@mui/material';

import { getCookie } from '../../../../utils/getCookie';
import { formatTimestamp } from '../../../../utils/date_functions';
import useStyledSnackbar from '../../../../hooks/useStyledSnackbar'

import CustomPaper from '../../../Widgets/CustomPaper';
import ConfirmDialog from '../../../Widgets/ConfirmDialog';


const ProjectDelete = ({ project, getProject, setRedirect }) =>{

    const { enqueueSnackbar } =  useStyledSnackbar()

    const handleDelete = async() =>{
        const json = JSON.stringify("")
        const res = await fetch('/api/project/' + project.id, {
            method: 'DELETE',
            headers: {
                'Content-Type': 'application/json',
                'X-CSRFToken': getCookie('csrftoken')
            },
            body: json
        })

        if(res.ok){
            enqueueSnackbar(`Das Projekt wird gelöscht!`, {
                variant: 'success'
            })
            setRedirect(true)
        }
        if(res.status === 403){
            enqueueSnackbar(`Das Projekt kann nicht gelöscht werden, da sich noch andere Unternehmen im Projekt befinden!`, {
                variant: 'error'
            })
        }
    }

    return(
        <CustomPaper
            title='Projekt löschen'
        >
            <Stack spacing={2}>
                <Typography variant="text_m_justify" >
                    {project.all_companies !== 1 ?
                            'Das Projekt kann nicht gelöscht werden, da sich noch andere Unternehmen im Projekt befinden!'
                        :
                            'Vorsicht! Hier kannst du das Projekt löschen. Allerdings kannst du das Projekt nur löschen wenn kein anderes Unternehmen im Projekt ist. Falls andere Unternehmen im Projekt sind, ist wahrscheinlich die Funktion Projekt abschließen deine gesuchte Funktion.'
                    }
                </Typography>
                <ConfirmDialog
                    title='Achtung'
                    message={project.delete_date ? "Bist du dir sicher das du den Löschvorgang widerrufen möchtest?" : 'Bist du dir sicher das du das Projekt löschen möchtest?'}
                    onConfirm={handleDelete}
                    renderButton={(onClick) => (
                        <Button
                            onClick={onClick}
                            variant="outlined"
                            color={project.delete_date ? "primary" : "error"}
                            size="large"
                            disabled={project.all_companies !== 1}
                        >
                            {project.delete_date ? "Löschvorgang widerrufen" : "Projekt löschen"}
                        </Button>
                    )}
                />
            </Stack>
        </CustomPaper>
    )
}

export default ProjectDelete
