import { useState } from 'react'
import { useForm } from "react-hook-form";

import { Stack, Stepper, Step, StepLabel, StepContent, Typography, Paper, Button, Zoom, useMediaQuery} from '@mui/material';

import RegisterKeyForm from './RegisterKeyFrom'
import RegisterForm from './RegisterForm';
import RegisterFormRights from './RegisterRightsForm';

import setServerErrors from 'utils/setServerErrors'
import LogoFullWhite from 'components/Widgets/Logos/LogoFullWhite';
import { Box } from '@mui/system';

const Register = () => {

    const [activeStep, setActiveStep] = useState(0);
    const [registrationType, setRegistrationType] = useState({
        type: '',
        key: '',
        state: '',
        success: false,
    })
    const [agreementId, setAgreementId] = useState(null)

    const { control, handleSubmit, watch, setError, clearErrors, formState: { errors, isValid, isSubmitted } } = useForm({
        mode:"onChange",
        defaultValues:{
            gender_key: '',
            username: '',
            firstname: '',
            lastname: '',
            email: '',
            phone_number: '',
            password: '',
            password2: '',
            name: '',
            industry_key: '',
            central_phone_number: '',
            street: '',
            house_number: '',
            add_on: '',
            postal_code: '',
            city: '',
            agreement: false,
        }
    });

    const handleRegistration = async(data) => {
        if(!data.agreement && agreementId !== null) return

        let json = JSON.stringify({
            address: {
                street: data.street,
                house_number: data.house_number,
                add_on: data.add_on,
                postal_code: data.postal_code,
                city: data.city
            },
            ...registrationType,
            ...data,
            agreement_id: agreementId
        })

        const res = await fetch('/account/register', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: json
        })
        const res_json = await res.json()
        if (res.ok) {
            console.log(res_json)
            handleNext()
        } else {
            console.log(res_json)
            setServerErrors(res_json, setError)
            handleBack()
        }
    }

    const handleNext = () => {
        if(!registrationType.success) return console.log("you have to check your key first")
        setActiveStep((prevActiveStep) => prevActiveStep + 1);
    };

    const handleBack = () => {
        setActiveStep((prevActiveStep) => prevActiveStep - 1);
    };

    const steps = [
        {
          label: 'Trage dein Registerungsschüssel ein',
          description: `bla bla`,
          component:
                    <RegisterKeyForm
                        registrationType={registrationType}
                        setRegistrationType={setRegistrationType}
                    />
        },
        {
          label: 'Gebe deine Benutzerdaten an',
          description: `bla bla`,
          component:
                     <RegisterForm
                        registrationType={registrationType}
                        control={control}
                        errors={errors}
                    />
        },
        {
          label: 'Datenschutzrichtlinien bestätigen',
          description: `bla bla`,
          component:
                    <RegisterFormRights
                        control={control}
                        errors={errors}
                        setAgreementId={setAgreementId}
                    />
        }
    ];

    const compact = useMediaQuery('(max-width: 850px)');
    const iconCompact = useMediaQuery('(max-width: 480px)');

    return(
        <Stack
            gap={3}
            sx={{
                backgroundColor: 'background.main',
                justifyContent: 'center',
                alignItems: 'center',
                height: '100vh',
                overflow: 'hidden',
                padding: 1,
            }}
        >
            <Box sx={{ flexShrink: 0 }}>
                <LogoFullWhite width={iconCompact ? 300 : 500} height={80}/>
            </Box>
            <Paper
                component='form'
                id="register-form"
                onSubmit={e => {
                    e.preventDefault()
                    if(activeStep !== 2) return
                    clearErrors()
                    handleSubmit(handleRegistration)(e)
                }}
                sx={{
                    p: compact ? 2 : 5,
                    maxWidth: 1000,
                    overflowY: 'auto',
                }}
            >
                <Stepper
                    activeStep={activeStep}
                    orientation="vertical"
                >
                    {steps.map((step, idx) => {
                        let stepProps = { optional: <></>, error: false }
                        if (Object.keys(errors).length > 0 && isSubmitted && idx === 1 ) {
                            stepProps.optional = (
                                <Typography
                                    color="error"
                                    variant="text_s"
                                >
                                    Fehlerhafte Eingabe. Bitte überprüfen!
                                </Typography>
                            )
                            stepProps.error = true;
                        }
                        return(
                            <Step key={`register-step-${idx}`}>
                                <StepLabel {...stepProps}>{step.label}</StepLabel>
                                    <StepContent>
                                        {step.component}
                                    <Stack
                                        direction='row'
                                        spacing={2}
                                        sx={{ mt: 2}}
                                    >
                                        <Zoom in={activeStep !== 0} style={{ transitionDelay: 800 }}>
                                            <Button
                                                onClick={handleBack}
                                                color="secondary"
                                            >
                                                Zurück
                                            </Button>
                                        </Zoom>
                                        {activeStep !==  steps.length - 1 &&
                                            <Zoom in={true} style={{ transitionDelay: 500 }}>
                                                <Button
                                                    onClick={handleNext}
                                                    variant="outlined"
                                                    color="primary"
                                                    disabled={!registrationType.success}
                                                >
                                                    Weiter
                                                </Button>
                                            </Zoom>
                                        }
                                        {activeStep ===  steps.length - 1 &&
                                            <Zoom in={true} style={{ transitionDelay: 500 }}>
                                                <Button
                                                    form-id="register-form"
                                                    type="submit"
                                                    variant="outlined"
                                                    color="primary"
                                                    disabled={(!watch('agreement'))}
                                                >
                                                    Registieren
                                                </Button>
                                            </Zoom>
                                        }
                                    </Stack>
                                </StepContent>
                            </Step>
                    )})}
                </Stepper>
                {activeStep === steps.length && (
                    <Stack
                        justifyContent='center'
                        spacing={2}
                        sx={{ p: 5 }}
                    >
                        <Typography
                            variant="text_m"
                        >
                            <strong>Herzlichen Glückwunsch!</strong> <br/> Sie haben sich erfolgreich als <strong>{registrationType.type}</strong> bei CONMEET registriert.
                        </Typography>
                        <Typography
                            color='secondary.main'
                            variant="text_m"
                        >
                            Bitte folgen Sie den Anweisungen in der Aktivierungsemail.
                        </Typography>
                    </Stack>
                )}
            </Paper>
        </Stack>
    )
}

export default Register
