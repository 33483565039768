import { Stack, Typography } from "@mui/material"
import DateTimeDisplaySegment from './DateTimeDisplaySegment'

const ShowTimeCountdown = ({ days, hours, minutes, seconds, reverse }) => {

	const isDanger = (days <= 10 && !reverse ? true : false)

	return (
		<Stack
			direction='row'
			justifyContent='space-evenly'
			alignItems='center'
			spacing={2}
		>
			<DateTimeDisplaySegment value={days} label={'Tage'} isDanger={isDanger}/>
			<Typography>:</Typography>
			<DateTimeDisplaySegment value={hours} label={'Stunden'} isDanger={isDanger}/>
			<Typography>:</Typography>
			<DateTimeDisplaySegment value={minutes} label={'Minuten'} isDanger={isDanger}/>
			<Typography>:</Typography>
			<DateTimeDisplaySegment value={seconds} label={'Sekunden'} isDanger={isDanger}/>
		</Stack>
	);
};

export default ShowTimeCountdown
