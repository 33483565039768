import { Controller } from 'react-hook-form'

import { TextField } from '@mui/material'
import { DatePicker } from '@mui/x-date-pickers-pro'

const ControlledDatePicker = ({ name, control, label, rules, disabled=false}) =>{

    return(
        <Controller
            control={control}
            name={name}
            rules={rules}
            render={({field: { value, onChange }}) => (
                <DatePicker
                    renderInput={(props) => <TextField {...props} fullWidth/>}
                    value={value}
                    onChange={onChange}
                    label={label}
                    inputFormat='dd.MM.yyyy'
                    mask='__.__.____'
                    disabled={disabled}
                />
            )}
        />
    )
}

export default ControlledDatePicker