import { useForm } from 'react-hook-form'

import { getCookie } from "../../../../../utils/getCookie";

import { Box, Button,  DialogContent, Dialog, DialogTitle, DialogActions, ListItem, ListItemAvatar, ListItemText, Avatar, DialogContentText } from "@mui/material";
import { Cancel } from "@mui/icons-material";

import ControlledProjectMemberSelect from '../../../../Widgets/ControlledInputs/ControlledProjectMemberSelect'


const TicketShareDialog = (props) =>{

    const { open, onClose, ticket, project_id, getTicketChains } = props;

    const { control, handleSubmit, reset, formState: { errors }} = useForm({
        mode: 'onChange',
        defaultValues: {
            recipient: ''
        }
    })

    const shareTicket = async (data) => {
        let ticket_id = ticket.id
        const json = JSON.stringify({
            project_ticket: ticket_id,
            ...data,
        })
        const res = await fetch('/api/project/' + project_id + '/ticket/share', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'X-CSRFToken': getCookie('csrftoken')
            },
            body: json,
        })
        if (res.ok) {
            getTicketChains()
            reset()
            onClose()
        }
    }

    const CancelShareTicket = async (allocator_id) => {
        const json = JSON.stringify({
            allocator_id: allocator_id,
        })
        const res = await fetch('/api/project/' + project_id + '/ticket/share', {
            method: 'DELETE',
            headers: {
                'Content-Type': 'application/json',
                'X-CSRFToken': getCookie('csrftoken')
            },
            body: json,
        })
        if (res.ok) {
            getTicketChains()
            onClose()
        }
    }

    return(
        <Dialog
            open={open}
            onClose={onClose}
            maxWidth={'tablet'}
        >
            <DialogTitle>
                Ticket teilen
            </DialogTitle>
            <DialogContent>
                <DialogContentText>
                    {ticket?.shared.length !== 0 ?
                        "Derzeit wird das Ticket vom folgenden Mitarbeiter bearbeitet." :
                        "Hier können Sie den Mitarbeiter auswählen, der das Ticket bearbeiten soll."
                    }
                </DialogContentText>
                    {ticket?.shared.length !== 0 ?
                        <Box sx={{mt:2}}>
                            {ticket?.shared.map((allocator, idx)=>{
                                let pm = allocator.project_member
                                return(
                                    <ListItem
                                        key={'shared-'+ idx}
                                            secondaryAction={
                                                <Button
                                                    endIcon={<Cancel/>}
                                                    variant='outlined'
                                                    onClick={()=> CancelShareTicket(allocator.id)}
                                                    color="primary"
                                                    size="small"
                                                >
                                                    Nicht teilen
                                                </Button>
                                            }
                                        >
                                        <ListItemAvatar>
                                            <Avatar
                                                src={pm.avatar}
                                                alt="avatar"
                                            >
                                                PM
                                            </Avatar>
                                        </ListItemAvatar>
                                        <ListItemText
                                            primary={`${pm.firstname} ${pm.lastname}`}
                                            secondary={pm.job_title}
                                        />
                                    </ListItem>
                                )
                            })}
                        </Box>
                    :
                        <Box
                            component="form"
                            id='share-form'
                            onSubmit={handleSubmit(shareTicket)}
                            sx={{mt:2}}
                        >
                            <ControlledProjectMemberSelect
                                control={control}
                                errors={errors}
                                name='recipient'
                                label='Empfänger'
                                project_id={project_id}
                                rules={{ required: true }}
                            />
                        </Box>
                    }
                </DialogContent>
                <DialogActions>
                    <Button
                        onClick={onClose}
                        color="secondary"
                    >
                        Schließen
                    </Button>
                    <Button
                        type="submit"
                        form="share-form"
                        color="primary"
                        disabled={ticket?.shared.length !== 0}
                    >
                        Teilen
                    </Button>
                </DialogActions>
        </Dialog>
    )
}

export default TicketShareDialog