import { Tooltip, Stack, IconButton } from '@mui/material'


const TooltipIconButton = ({ onClick = () => {}, disabled, icon, title, type, size='medium', sx={}, color='primary' }) => {

    return (
        <Tooltip
            title={title}
        >
            <Stack direction='column' justifyContent='center'>
                <IconButton
                    {...color ? { color } : {}}
                    onClick={onClick}
                    disabled={disabled}
                    type={type}
                    size={size}
                    sx={sx}
                >
                    {icon}
                </IconButton>
            </Stack>
        </Tooltip>
    )
}

export default TooltipIconButton
