import { Button, Dialog, DialogTitle, DialogContent, Stack, DialogActions } from '@mui/material'
import { useForm } from 'react-hook-form'

import ControlledTextField from '../../Widgets/ControlledInputs/ControlledTextField'
import ControlledDateTimePicker from '../../Widgets/ControlledInputs/ControlledDateTimePicker'
import ControlledCompanyMemberSelect from '../../Widgets/ControlledInputs/ControlledCompanyMemberSelect'
import ControlledSelect from '../../Widgets/ControlledInputs/ControlledSelect'

import { getCookie } from '../../../utils/getCookie'
import { checkSelectionZero } from '../../../utils/helper'
import ProjectMemberSelection from 'components/Widgets/ProjectMemberSelection/ProjectMemberSelection'


const FormDialog = ({ open, handleClose: handleClose_, getTodos, companyMemberID, projectKeys, projectTodo=false, projectId='' }) => {
    const { control, handleSubmit, watch, setValue, reset, formState: { errors, isDirty, isValid }} = useForm({
        mode: 'onChange',
        defaultValues: {
            description: '',
            deadline: null,
            recipient: '',
            sub_project: '',
            project: projectId
        }
    })

    const createTodo = async data => {
        data.sub_project = checkSelectionZero(data.sub_project)
        if (open === 'private') {
            data.recipient = companyMemberID
        }
        const res = await fetch('/api/company/todos', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'X-CSRFToken': getCookie('csrftoken')
            },
            body: JSON.stringify(data),
        })
        if (res.ok) {
            handleClose()
            getTodos()
        }
    }

    const handleClose = () => {
        handleClose_()
        reset()
    }

    return (
        <Dialog
            maxWidth="tablet"
            open={Boolean(open)}
            onClose={handleClose}
            fullWidth
        >
            <DialogTitle>Neue Aufgabe erstellen</DialogTitle>
            <DialogContent>
                <form id='todo-form' onSubmit={handleSubmit(createTodo)}>
                    <Stack gap={2} sx={{ pt: 1 }}>
                        <ControlledTextField
                            control={control}
                            errors={errors}
                            name='description'
                            label='Beschreibung'
                            type='text'
                            rules={{ required: true }}
                            multiline
                            minRows={10}
                        />
                        {open !== 'private' &&
                            (projectTodo ?
                                <ProjectMemberSelection
                                    selected={watch('recipient') ? [watch('recipient')] : []}
                                    setSelected={s => setValue('recipient', s[s.length - 1])}
                                    companyMemberMode
                                />
                            :
                                <ControlledCompanyMemberSelect
                                    control={control}
                                    errors={errors}
                                    name='recipient'
                                    label='Empfänger'
                                    rules={{ required: true }}
                                />
                            )
                        }
                        <ControlledSelect
                            control={control}
                            name="sub_project"
                            label={`Projektauftrag ${projectTodo ? '' : '(optional)'}`}
                            errors={errors}
                            items={projectKeys}
                        />
                        <ControlledDateTimePicker
                            control={control}
                            name='deadline'
                            label='Deadline (optional)'
                        />
                    </Stack>
                </form>
            </DialogContent>
            <DialogActions>
                <Button
                    onClick={handleClose}
                    color="secondary"
                >
                    Abbrechen
                </Button>
                <Button
                    type='submit'
                    form='todo-form'
                    disabled={!isValid || !isDirty}
                >
                    Erstellen
                </Button>
            </DialogActions>
        </Dialog>
    )
}

export default FormDialog
