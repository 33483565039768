import {Stack, Box, Avatar, LinearProgress, Typography } from "@mui/material"
import CustomPaper from "../../../Widgets/CustomPaper";
import NoDataImg from '../../../../assets/svgs/no_data.svg'

import { formatNumber } from '../../../../utils/helper'


const ProjectTimestampsDiagram = ({ memberTimestamps }) =>{

    return(
        <CustomPaper
            title="Summierte Arbeitszeiten der Mitarbeiter"
        >
            <Box
                sx={{
                    overflow: 'auto',
                    maxHeight: 400
                }}
            >
                {memberTimestamps.length !== 0 ?
                    memberTimestamps.map((mt, idx)=>{
                        return(
                            <Stack
                                key={`${mt.id}-${idx}`}
                                direction='row'
                                justifyContent='flex-start'
                                alignItems='center'
                                spacing={2}
                                mb={2}
                            >
                                <Avatar
                                    alt={mt.name}
                                    src={mt.avatar}
                                    sx={{ width: 40, height: 40 }}
                                />
                                <Typography variant="text_m" width={300}>{mt.name}</Typography>
                                <Box
                                    width="100%"
                                >
                                    <LinearProgress
                                        variant="determinate"
                                        color="secondary"
                                        value={mt.sum_workload_net_in_percent}
                                        sx={{ height:10, borderRadius: "0.3rem",}}
                                    />
                                </Box>
                                    <Typography
                                        variant="text_m"
                                        width={150}
                                        sx={{textAlign: 'right'}}
                                    >
                                        {formatNumber(mt.sum_workload_net, 2,'Std.')}
                                    </Typography>
                            </Stack>
                        )
                    })
                :
                    <Stack
                        justifyContent="center"
                        alignItems="center"
                        spacing={1}
                    >
                        <Box
                            component="img"
                            src={NoDataImg}
                            alt="image not found"
                            height={120}
                        />
                        <Typography variant="text_m">Keine Zeiten gebucht!</Typography>
                    </Stack>
                }
            </Box>
        </CustomPaper>
    )
}

export default ProjectTimestampsDiagram