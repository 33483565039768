import { useGridApiContext } from '@mui/x-data-grid-pro';
import { DatePicker, TimePicker } from "@mui/x-date-pickers-pro";
import { TextField } from '@mui/material';

import {getISOTimeStamp, getLocalDate, getLocalTime, getLocalTimeStamp} from '../../../utils/date_functions';

/**
-----------------------------------------------------------------
	CustomDatagrid - Column - Datepicker
-----------------------------------------------------------------
**/

const date_options = {
	weekday: 'long',
	year: 'numeric',
	month: 'long',
	day: 'numeric'
};

const DateCell = (params) =>{
	const { id, value, field } = params;
	const apiRef = useGridApiContext();

	const handleChange = (newValue) => { // The new value entered by the user
	  	apiRef.current.setEditCellValue({ id, field, value: newValue });
	};

	return <DatePicker
				value={value}
				onChange={handleChange}
				mask="__.__.____"
				renderInput={(params) => <TextField {...params} helperText={null}/>}
			/>
}

export const DateColumn = ({field='', headerName='', minWidth=120, editable=false}) =>{

	const _valueParser = (date) =>{
		if (date === null) return date
		return getLocalDate(date)
	}

	const _valueFormatter = ({ value })  =>{
		return value && new Date(value).toLocaleDateString('de-DE', date_options)}

	const _renderEditCell = ((params) =>{
		return <DateCell {...params}/>
	})

    return (
		{
			field: field,
			headerName: headerName,
			minWidth: minWidth,
			editable: editable,
			valueParser: _valueParser,
			valueFormatter: _valueFormatter,
			renderEditCell: _renderEditCell,
		}
	)
}

/**
-----------------------------------------------------------------
	CustomDatagrid - Column - Native Timepicker
-----------------------------------------------------------------
**/

const TimeCell = (params) =>{
	const { id, value, field } = params;
	const apiRef = useGridApiContext();

	const handleChange = (e) => { // The new value entered by the user
		if (isNaN(e)) return
		apiRef.current.setEditCellValue({ id, field, value: getISOTimeStamp(e) });
	};

	const timeSplit = value.split(':')

	return <TimePicker
				value={new Date().setHours(timeSplit[0], timeSplit[1], 0)}
				onChange={handleChange}
				renderInput={(params) => <TextField {...params} helperText={null}/>}
			/>
}

export const TimeColumn = ({field='', headerName='', minWidth=80, editable=false}) =>{

	const _valueFormatter = ({ value }) => {
		const timeSplit = value.split(':')
		return timeSplit[0] + ':' + timeSplit[1]
	}

	const _renderEditCell = ((params) =>{
		return <TimeCell {...params}/>
	})

	return (
		{
			field: field,
			headerName: headerName,
			minWidth: minWidth,
			editable: editable,
			valueFormatter: _valueFormatter,
			renderEditCell: _renderEditCell,
		}
	)
}
