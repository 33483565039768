import { Stack, Typography, Fade } from "@mui/material"
import img from '../../../assets/svgs/no_data.svg'


const NoData = ({ text }) => {
    return (
        <Fade in={true} timeout={500} style={{ transitionDelay: '250ms' }}>
            <Stack gap={2} sx={{ p: 2, height: '100%', justifyContent: 'center' }} alignItems='center'>
                    <img src={img} alt='' style={{ height: 170 }} />
                    <Typography variant="text_m">{ text }</Typography>
            </Stack>
        </Fade>
    )
}

export default NoData
