import Calendar from './Calendar'
import CustomPage from '../../Widgets/CustomPage'


const CompanyCalendar = () => {

    return (
        <CustomPage title='Organisation'>
            <Calendar/>
        </CustomPage>
    )
}

export default CompanyCalendar
