import { IconButton, Stack, Tooltip } from "@mui/material";
import OrderMailToButton from "components/Widgets/OderMailToButton";

import { BsFillFileEarmarkPdfFill } from "react-icons/bs";

import { formatTimestamp } from "../../../../utils/date_functions";
import { formatNumber } from "../../../../utils/helper";

export function formatValueWithUnit(params){
	const unit = params.api.getRow(params.id).unit
	if (params.value == null) {
		return "";
	}
	return `${formatNumber(params.value, 2, '')} ${unit.split('/')[1]}`;
}

export function formatPrice({value}){
	return `${ formatNumber(value, 2, '€')}`
}

export function formatDate({value}) {
	return formatTimestamp(value)
}

export function getFormattedDate(params) {
	return formatTimestamp(params.row.created)
}

export function getAvailableBalance(params) {
	return formatNumber(params.row.balance + params.row.reserved, 2, params.row.unit.split('/')[1])
}

export function getTotalOrderValue(params) {
	return params.row.value - params.row.correction_value
}

export function getTotalPrice(params){
	const value  = params.row.value - params.row.correction_value
	const standard_price = params.row.standard_price
	const price = params.row.price
	return  value * (price !==  0 ? price : standard_price)
}

export function getStatusValue(params) {
	let value = 0
	if (params.row.balance >= params.row.stock_alert_2){
		value = 3
	} else if (params.row.balance >= params.row.stock_alert_1){
		value = 2
	} else {
		value = 1
	}
	return value
}

export function CustomPDFMailCell(params){
	const typeName = params.row.type
	const fileLink = params.row.file
	const supplierEmail = params.row.supplier_email
	const creator = params.row.creator
	const companyName = params.row.company_name

	const disabled = fileLink === null
	return(
		<Stack
			direction="row"
			alignItems="center"
			justifyContent="center"
			gap={0.5}
		>
			<Tooltip title="Dokument" disableHoverListener={disabled}>
				<span>
					<IconButton
						color='primary'
						href={fileLink}
						target="_blank"
						download={typeName}
						disabled={disabled}
					>
						<BsFillFileEarmarkPdfFill size={20} />
					</IconButton>
				</span>
			</Tooltip>
			<OrderMailToButton
				typeName={typeName}
				fileLink={fileLink}
				supplierEmail={supplierEmail}
				creator={creator}
				companyName={companyName}
				disabled={disabled}
			/>
		</Stack>
	)
}

