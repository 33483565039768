import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { Stack } from "@mui/system";

import ControlledSelect from "../../../../../Widgets/ControlledInputs/ControlledSelect";
import ControlledTextField from "../../../../../Widgets/ControlledInputs/ControlledTextField";
import useDebounce from "../../../../../../hooks/useDebounce";


const SearchMaterialBar = ({ getSearchedMaterials }) =>{

    const [categories, setCategories] = useState({});
    const [manufacturers, setManufacturers] = useState({});
    const { control, handleSubmit, watch, formState: { errors } } = useForm({
        mode: 'onChange',
        defaultValues:{
            unique_material_id: '',
            article_description: '',
            category: '',
            manufacturer: '',
        }
    });

    const getMaterialCategories = async() => {
		const res = await fetch('/api/company/materials/category', {
            method: 'GET'
        })
		const data = await res.json()
		if(res.ok){
			setCategories(data.selection)
		}
    }

    const getMaterialManufacturers = async() => {
		const res = await fetch('/api/company/materials/manufacturer', {
            method: 'GET'
        })
		const data = await res.json()
		if(res.ok){
			setManufacturers(data)
		}
    }

    useEffect(()=>{
		getMaterialCategories();
        getMaterialManufacturers();
	}, [])

    const debouncedMaterialSearch = useDebounce(getSearchedMaterials)

    useEffect(() => {
        const subscription = watch(() => handleSubmit(debouncedMaterialSearch)());
        return () => subscription.unsubscribe();
    }, [watch, handleSubmit, debouncedMaterialSearch]);

    return(
        <Stack
            component='form'
            onSubmit={handleSubmit(getSearchedMaterials)}
            spacing={2}
        >
            <Stack
                direction='row'
                spacing={2}
            >
                <ControlledTextField
                    name="unique_material_id"
                    label="Artikelnummer"
                    type='number'
                    control={control}
                    errors={errors}
                />
                <ControlledTextField
                    name="article_description"
                    label="Artikelbeschreibung"
                    control={control}
                    errors={errors}
                />
            </Stack>
            <Stack
                direction='row'
                spacing={2}
            >
                <ControlledSelect
                    name="category"
                    label="Kategorie"
                    control={control}
                    errors={errors}
                    items={categories}
                />
                <ControlledSelect
                    name="manufacturer"
                    label="Hersteller"
                    control={control}
                    errors={errors}
                    items={manufacturers}
                />
            </Stack>
        </Stack>
    )
}

export default SearchMaterialBar