import Box from '@mui/material/Box';
import ImageList from '@mui/material/ImageList';
import ImageListItem from '@mui/material/ImageListItem';
import ImageListItemBar from '@mui/material/ImageListItemBar';

import Title from "./Title";
import Tooltip from "./Tooltip";
import SettingsButton from "./SettingsButton";

import VideoPopup from '../../../Widgets/VideoPopup'


const VideoGrid = ({ files, getUploads, }) => {
    return (
        <ImageList cols={3} gap={16}>
            {files.map(video =>
                <ImageListItem key={video.id}>
                    <Tooltip document={video}>
                        <Box sx={{ overflow: 'hidden', borderRadius: 2 }}>
                            <VideoPopup src={video.file} />
                            <ImageListItemBar
                                title={<Title document={video} />}
                                subtitle={video.name + ' - ' + new Date(video.timestamp).toLocaleString()}
                                sx={{ overflow: 'hidden', borderBottomLeftRadius: 8, borderBottomRightRadius: 8 }}
                                actionIcon={<SettingsButton document={video} getUploads={getUploads} />}
                            />
                        </Box>
                    </Tooltip>
                </ImageListItem>
            )}
        </ImageList>
    )
}

export default VideoGrid