import {useContext, useState} from "react";
import {MdSettings} from "react-icons/md";
import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Divider,
    IconButton, Stack,
} from "@mui/material";


import AccountContext from "../../../../../context/AccountContext";
import Outdated from "./Outdated";
import Share from "./Share";


const SettingsButton = ({ document, getUploads, color='white' }) => {
    const [account] = useContext(AccountContext)

    const [ open, setOpen ] = useState()

    const handleClose = () => setOpen(false)
    const handleOpen = () => setOpen(true)

    if (account.id !== document.company_member)
        return null

    return (
        <>
            <IconButton onClick={handleOpen} color={color}>
                <MdSettings />
            </IconButton>
            <Dialog
                open={open}
                onClose={handleClose}
                maxWidth="tablet"
                fullWidth
            >
                <DialogTitle>
                    Optionen
                </DialogTitle>
                <DialogContent>
                    <Stack gap={2}>
                        <Outdated document={document} getUploads={getUploads} />
                        <Divider flexItem/>
                        <Share document={document} />
                    </Stack>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose} color="secondary">Zurück</Button>
                </DialogActions>
            </Dialog>
        </>
    )
}

export default SettingsButton
