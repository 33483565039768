import { IconButton, Tooltip } from "@mui/material";
import { MdMail } from "react-icons/md";

const generateOrderEmailText = (creator, typeName, fileLink) => {
	return(encodeURIComponent(`Sehr geehrte Damen und Herren,

		unter folgenden Link finden Sie eine automatisch generierte ${typeName}.

		${fileLink}

		Bitte beachten Sie alle Hinweise und Angaben auf diesem Dokument. Der Link zum Dokument läuft nach 14 Tage ab.

		Senden Sie uns bitte eine Bestätigung zur ${typeName}.

		Vielen Dank!


		Mit freundlichen Grüßen

		${creator}`))
}

const OrderMailToButton = ({typeName, fileLink, supplierEmail, creator, companyName, size=20, disabled}) =>{
	return(
		<a href={`mailto:${supplierEmail}?subject=${`${typeName} | ${companyName}`}&body=${generateOrderEmailText(creator, typeName, fileLink)}`}>
			<Tooltip title="Per Mail versenden" disableHoverListener={disabled}>
				<span>
					<IconButton color='primary' disabled={disabled}>
						<MdMail size={size} />
					</IconButton>
				</span>
			</Tooltip>
		</a>
	)
}

export default OrderMailToButton
