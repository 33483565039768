import { Badge, Card, CardActionArea, Divider, Stack, Typography } from "@mui/material"


const CustomIconQuadPaperButton = ({
        mainlabel,
        mainsublabel,
        label1,
        sublabel1,
        sublabelColor="primary.main",
        icon1,
        count1,
        onClick1,
        disabled=false,
        label2,
        sublabel2,
        icon2,
        count2,
        onClick2,
        label3,
        sublabel3,
        icon3,
        count3,
        onClick3,
        label4,
        sublabel4,
        icon4,
        count4,
        onClick4,
    }) =>{

    return(
        <Card
            sx={{
                width: '100%',
                opacity: disabled ? 0.6 : 1
            }}
        >
            <Stack
                direction="row"
                justifyContent='center'
                alignItems='center'
                sx={{p:1}}
                gap={1}
            >
                <Typography variant="caption_m">
                    {mainlabel}
                </Typography>
                {mainsublabel &&
                    <Typography variant="caption_m">
                        |
                    </Typography>
                }
                <Typography variant="caption_m" color={"primary"}>
                    {mainsublabel}
                </Typography>
            </Stack>
            <Divider flexItem/>
            <Stack
                direction="row"
            >
                <CardActionArea
                    onClick={onClick1}
                    disabled={disabled}
                    sx={{height: '100%'}}
                >
                    <Stack
                        justifyContent='center'
                        alignItems='center'
                        gap={1.5}
                        sx={{
                            padding: 4,
                            height: '100%',
                        }}
                    >
                        <Badge
                            color="primary"
                            badgeContent={count1}
                            max={99999}
                        >
                            {icon1}
                        </Badge>
                        <Stack
                            justifyContent='center'
                            alignItems='center'
                            gap={0.2}
                        >
                            <Typography variant="caption_m" sx={{lineHeight: 1.1}} color="background.light20">{label1}</Typography>
                            {sublabel1 && <Typography variant="text_s" color={sublabelColor}>{sublabel1}</Typography>}
                        </Stack>
                    </Stack>
                </CardActionArea>
                <CardActionArea
                    onClick={onClick2}
                    disabled={disabled}
                    sx={{height: '100%'}}
                >
                    <Stack
                        justifyContent='center'
                        alignItems='center'
                        gap={1.5}
                        sx={{
                            padding: 4,
                            height: '100%',
                        }}
                    >
                        <Badge
                            color="primary"
                            badgeContent={count2}
                            max={99999}
                        >
                            {icon2}
                        </Badge>
                        <Stack
                            justifyContent='center'
                            alignItems='center'
                            gap={0.2}
                        >
                            <Typography variant="caption_m" sx={{lineHeight: 1.1}} color="background.light20">{label2}</Typography>
                            {sublabel2 && <Typography variant="text_s" color={sublabelColor}>{sublabel2}</Typography>}
                        </Stack>
                    </Stack>
                </CardActionArea>
                <CardActionArea
                    onClick={onClick3}
                    disabled={disabled}
                    sx={{height: '100%'}}
                >
                    <Stack
                        justifyContent='center'
                        alignItems='center'
                        gap={1.5}
                        sx={{
                            padding: 4,
                            height: '100%',
                        }}
                    >
                        <Badge
                            color="primary"
                            badgeContent={count3}
                            max={99999}
                        >
                            {icon3}
                        </Badge>
                        <Stack
                            justifyContent='center'
                            alignItems='center'
                            gap={0.2}
                        >
                            <Typography variant="caption_m" sx={{lineHeight: 1.1}} color="background.light20">{label3}</Typography>
                            {sublabel3 && <Typography variant="text_s" color={sublabelColor}>{sublabel3}</Typography>}
                        </Stack>
                    </Stack>
                </CardActionArea>
                <CardActionArea
                    onClick={onClick4}
                    disabled={disabled}
                    sx={{height: '100%'}}
                >
                    <Stack
                        justifyContent='center'
                        alignItems='center'
                        gap={1.5}
                        sx={{
                            padding: 4,
                            height: '100%',
                        }}
                    >
                        <Badge
                            color="primary"
                            badgeContent={count4}
                            max={99999}
                        >
                            {icon4}
                        </Badge>
                        <Stack
                            justifyContent='center'
                            alignItems='center'
                            gap={0.2}
                        >
                            <Typography variant="caption_m" sx={{lineHeight: 1.1}} color="background.light20">{label4}</Typography>
                            {sublabel4 && <Typography variant="text_s" color={sublabelColor}>{sublabel4}</Typography>}
                        </Stack>
                    </Stack>
                </CardActionArea>
            </Stack>
        </Card>
    )
}

export default CustomIconQuadPaperButton
