import { useEffect, useState, useCallback, useRef } from "react";

import { DialogContent, Dialog, DialogTitle, Stack, IconButton, Tooltip, FormGroup, FormControlLabel, Switch, } from "@mui/material";
import { Add, Clear } from "@mui/icons-material"

import AddEvent from "./AddEvent";
import EditEvent from "./EditEvent";
import ProjectSlotItem from "./ProjectSlotItem";

import { PALETTE } from "../../../../theme";
import { getCookie } from "../../../../../utils/getCookie";

const date_options = {
	weekday: 'long',
	year: 'numeric',
	month: 'long',
	day: 'numeric'
};


const DayPlannerDialog = (props) =>{

    const { open, onClose, dayDate, workingGroups, projectKeys, getEvents } = props;

    const [dayEvents, setDayEvents] = useState([])
    const [selecetedEvent, setSelecetedEvent] = useState(null)
    const [openAdd, setOpenAdd] = useState(false);
    const [openEdit, setOpenEdit] = useState(false);
    const haveToLoadAllEvents = useRef(false)

    const [showNotAllEvents, setShowNotAllEvents] = useState(true);

    const handleShowNotAllEvents= (event) => {
        setShowNotAllEvents(event.target.checked);
    };

    const handleSelectedEvent = (event) =>{
        setSelecetedEvent(event)
        setOpenEdit(true)
    }

    const handleOnClose = () =>{
        if(haveToLoadAllEvents.current){
            getEvents()
        }
        onClose()
    }

    const getDayEvents = useCallback(async() => {
        const res = await fetch('/api/company/workplanner/day?date=' + dayDate.toDateString(), {
            method: 'GET',
            headers: {
                'X-CSRFToken': getCookie('csrftoken')
            }
        })
        const data = await res.json()
        if (res.ok) {
            setDayEvents(data)
        }
    }, [dayDate])

    useEffect(() => {
        getDayEvents()
    }, [getDayEvents]);

    return(
        <>
            <Dialog
                open={open}
                onClose={handleOnClose}
                fullScreen
                fullWidth
            >
                <Stack
                    direction='row'
                    justifyContent='space-between'
                    alignItems="center"
                    sx={{
                        p: 2
                    }}
                >
                    <DialogTitle>
                        {dayDate.toLocaleDateString('de-DE', date_options)}
                    </DialogTitle>
                    <Stack
                        direction='row'
                        alignItems='center'
                        spacing={2}
                    >
                        <Tooltip
                            title="Neues Event"
                        >
                            <IconButton
                                color="primary"
                                onClick={()=> setOpenAdd(true)}
                                size="large"
                            >
                                <Add fontSize="large"/>
                            </IconButton>
                        </Tooltip>
                        <FormGroup>
                            <FormControlLabel
                                control={
                                    <Switch
                                        checked={showNotAllEvents}
                                        onChange={handleShowNotAllEvents}
                                        color="primary"
                                    />
                                }
                                label="Nur Projekte mit Events anzeigen"
                            />
                        </FormGroup>
                        <IconButton
                            color="secondary"
                            onClick={handleOnClose}
                            size="large"
                        >
                            <Clear fontSize="large"/>
                        </IconButton>
                    </Stack>
                </Stack>
                <DialogContent
                    width="100%"
                    sx={{
                        border: 1,
                        borderRadius: '10px',
                        borderColor: PALETTE.background.light70,
                        mr:2,
                        ml:2,
                        mb:2,
                    }}
                >
                    <Stack
                        spacing={3}
                    >
                        {dayEvents.map((projectSlot)=>{
                            if(showNotAllEvents && projectSlot.events.length === 0) return null
                            return(
                                <ProjectSlotItem
                                    key={`project-slot-${projectSlot.id}`}
                                    projectSlot={projectSlot}
                                    handleSelectedEvent={handleSelectedEvent}
                                    getDayEvents={getDayEvents}
                                    haveToLoadAllEvents={haveToLoadAllEvents}
                                />
                            )
                        })}
                    </Stack>
                </DialogContent>
            </Dialog>
            <AddEvent
                open={openAdd}
                onClose={()=> setOpenAdd(false)}
                dayDate={dayDate}
                workingGroups={workingGroups}
                projectKeys={projectKeys}
                getDayEvents={getDayEvents}
                haveToLoadAllEvents={haveToLoadAllEvents}
            />
            <EditEvent
                open={openEdit}
                onClose={()=> setOpenEdit(false)}
                projectKeys={projectKeys}
                selectedEvent={selecetedEvent}
                getDayEvents={getDayEvents}
                haveToLoadAllEvents={haveToLoadAllEvents}
            />
        </>
    )
}

export default DayPlannerDialog
