import { Divider, Stack, Typography } from "@mui/material"
import { formatNumber } from "../../../../../../utils/helper"



const RequestMaterial = ({ requestMaterial }) =>{
    const material = requestMaterial.project_material

    const unit_str = material.unit.split('/')
    const unit_suf = unit_str[1]

    return(
        <Stack
            flexDirection="row"
            justifyContent="space-between"
            alignItems="flex-end"
        >
            <Stack
                gap={0.2}
                width="80%"
            >
                <Typography
                        variant="caption_m"
                    >
                        {`Art.-Nr. ${material.unique_material_id} | ${material.manufacturer_name}`}
                </Typography>
                <Typography
                        variant="subtitle_s"
                        sx={{fontSize: 12}}
                    >
                        {material.article_description}
                </Typography>
                <Typography
                        variant="text_m"
                    >
                        {`Standardpreis ${formatNumber(material.price, 2, material.unit)}`}
                </Typography>
                <Stack
                    direction='row'
                    alignItems="center"
                    spacing={1}
                >
                    {requestMaterial.available ?
                        <Typography
                                variant="text_s"
                                color="primary"
                                sx={{fontWeight: 'bold'}}
                            >
                                {`Verfügbar`}
                        </Typography>
                    :
                        <Typography
                                variant="text_s"
                                color="secondary"
                                sx={{fontWeight: 'bold'}}
                            >
                                {`Nicht verfügbar`}
                        </Typography>
                    }
                </Stack>
                <Typography
                        variant="text_s"
                    >
                        {`Anmerkung: ${requestMaterial.annotation ? requestMaterial.annotation : '--'}`}
                </Typography>
            </Stack>
            <Stack
                width="20%"
                justifyContent="flex-end"
                alignItems="flex-end"
                gap={0}
            >
                <Typography variant="text_s">
                    {formatNumber(requestMaterial.value, 2, unit_suf)}
                </Typography>
                <Typography variant="text_s">
                    {`x ${formatNumber(requestMaterial.price, 2, material.unit)}`}
                </Typography>
                <Divider light flexItem/>
                <Typography variant="value">
                    {`${formatNumber(requestMaterial.value * requestMaterial.price, 2, '€')}`}
                </Typography>
            </Stack>
        </Stack>
    )
}

export default RequestMaterial