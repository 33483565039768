import { Fragment } from 'react'
import { Controller } from 'react-hook-form'

import { TextField, InputAdornment } from '@mui/material'

const ControlledTextField = ({
    control,
    name,
    type,
    rules,
    errors,
    variant,
    label,
    suffix,
    multiline,
    rows,
    minRows,
    maxRows,
    disabled=false,
    placeholder='',
    sx
}) => {
    return (
        <Controller
            control={control}
            name={name}
            rules={rules}
            render={({field: { value, onChange }}) => (
                <TextField
                    type={type}
                    value={value}
                    onChange={onChange}
                    error={Boolean(errors[name])}
                    variant={variant}
                    label={label}
                    multiline={multiline}
                    rows={rows}
                    minRows={minRows}
                    maxRows={maxRows}
                    disabled={disabled}
                    fullWidth
                    placeholder={placeholder}
                    helperText={<>
                        {errors[name]?.type === 'server' ?
                            errors[name].message.map((m, i) => (
                                <Fragment key={i}>
                                    {m}
                                    <br />
                                </Fragment>
                            ))
                            :
                            errors[name]?.message
                        }
                    </>}
                    InputProps={{
                        endAdornment: <InputAdornment position="end">{suffix}</InputAdornment>,
                    }}
                    sx={sx}
                />
            )}
        />
    )
}

export default ControlledTextField