import PaperPaginationDataGrid from "../../Widgets/CustomDatagrids/Pagination/PaperPaginationDataGrid";

const MaterialTable = ({
    apiRef,
    materials,
    columns,
    pageSize,
    pagInfo,
    setPageSize,
    handlePageChange,
    isLoading,
    title,
    actionIconBar,
    onSaveRow,
    onDeleteRow,
    getConfirmMessage,
    searchTerm,
    setSearchTerm,
    onOrderByGetParametersChange,
    errors
}) => {

    return(
        <PaperPaginationDataGrid
            rows={materials}
            columns={columns}
            apiRef={apiRef}
            pageSize={pageSize}
            onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
            rowCount={pagInfo.count}
            onPageChange={handlePageChange}
            loading={isLoading}
            title={title}
            actionBar={actionIconBar}
            onDeleteRow={onDeleteRow}
            onSaveRow={onSaveRow}
            getConfirmMessage={getConfirmMessage}
            searchTerm={searchTerm}
            setSearchTerm={setSearchTerm}
            onOrderByGetParametersChange={onOrderByGetParametersChange}
            errors={errors}
        />
    )
}

export default MaterialTable
