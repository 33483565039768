import { useState } from 'react';
import { Stack, IconButton, Tooltip } from '@mui/material';
import { Add, DoneAll, Task } from '@mui/icons-material';

import { getCookie } from '../../../../../utils/getCookie';

import TimeRecordingAddDialog from '../../TimeRecordingAddDialog';
import TimeREcordingMonthBill from '../../TimeRecordingMonthBill';



const TimestampToolbar = ({ company_member_id, selectedDate, is_manager, projectKeys, timestampTypeKeys, handleUpdate, hideAllConfirm=true }) =>{

    const [open, setOpen] = useState(false)
    const [openMonthBill, setOpenMonthBill] = useState(false)

    const handleConfirmAll = async() =>{
        const json = JSON.stringify({
            selected_date: selectedDate,
            company_member_id: company_member_id,
        })
        const res = await fetch('/api/time-recording/management', {
            method: 'PATCH',
            headers: {
                'Content-Type': 'application/json',
                'X-CSRFToken': getCookie('csrftoken')
            },
            body: json
        })
        if(res.ok){
            handleUpdate()
        }
    }

    return(
        <>
            <Stack
                direction='row'
                justifyContent="center"
                alignItems="center"
                gap={1}
            >
                {is_manager && !hideAllConfirm &&
                    <Tooltip
                        title="Alle Zeiteinträge bestätigen"
                    >
                        <IconButton
                            color="primary"
                            onClick={handleConfirmAll}
                        >
                            <DoneAll/>
                        </IconButton>
                    </Tooltip>
                }
                <Tooltip
                    title="Neuer Zeiteintrag"
                >
                    <IconButton
                        color="primary"
                        onClick={()=> setOpen(true)}
                    >
                        <Add/>
                    </IconButton>
                </Tooltip>
                <Tooltip
                    title="Stundenübersicht"
                >
                    <IconButton
                        color="primary"
                        onClick={()=> setOpenMonthBill(true)}
                    >
                        <Task/>
                    </IconButton>
                </Tooltip>
            </Stack>
            <TimeRecordingAddDialog
                open={open}
                onClose={()=> setOpen(false)}
                company_member_id={company_member_id}
                projectKeys={projectKeys}
                timestampTypeKeys={timestampTypeKeys}
                handleUpdate={handleUpdate}
            />
            <TimeREcordingMonthBill
                open={openMonthBill}
                onClose={()=> setOpenMonthBill(false)}
                company_member_id={company_member_id}
                selectedDate={selectedDate}
            />
        </>
    )
}

export default TimestampToolbar