import { useContext, useState }  from 'react'
import { Navigate } from 'react-router-dom';
import ProjectContext from '../../../../context/ProjectContext'
import AccountContext from '../../../../context/AccountContext'

import { Stack, Typography, Button } from '@mui/material'
import { Delete } from '@mui/icons-material';

import { getCookie } from '../../../../utils/getCookie';

import useStyledSnackbar from '../../../../hooks/useStyledSnackbar'

import ProjectTeamTree from './ProjectTeamTree';
import CustomPaper from '../../../Widgets/CustomPaper';
import ConfirmDialog from '../../../Widgets/ConfirmDialog';
import ProjectTeamMemberPermissionTable from './ProjectTeamMemberPermissionTable';



const ProjectMembers = () => {

    const [account] = useContext(AccountContext)
    const [project, projectMember, projectTeamMembers, projectMemberTree, , getProjectTeamMembers] = useContext(ProjectContext)
    const { enqueueSnackbar } =  useStyledSnackbar()
    const [redirect, setRedirect] = useState(false)
    

    const handleProjectLeave = async() =>{
        const json = JSON.stringify({

        })
        const res = await fetch('/api/project/' + project.id + '/team-members/leave', {
            method: 'DELETE',
            headers: {
                'Content-Type': 'application/json',
                'X-CSRFToken': getCookie('csrftoken')
            },
            body: json
        })
        if(res.ok){
            setRedirect(true)
            enqueueSnackbar(`Dein Unternehmen hat erfolgreich das Projekt verlassen!`, {
                variant: 'success'
            })
        } else{
            enqueueSnackbar(`Es ist leider ein Fehler aufgetreten!`, {
                variant: 'error'
            })
        }
    }

    if(redirect){
        return(
            <Navigate to='/dashboard'/>
        )
    }

    return(
        <Stack
            gap={3}
        >
            <ProjectTeamTree
                data={projectMemberTree}
            />
            <Stack spacing={3}>
                <ProjectTeamMemberPermissionTable
                    project={project}
                    projectMember={projectMember}
                    projectTeamMembers={projectTeamMembers}
                    getProjectTeamMembers={getProjectTeamMembers}
                />
                {account.company.id !== project.company.id &&
                    <CustomPaper
                        title="Projekt verlassen?"
                    >
                        <Stack spacing={2}>
                            <Typography variant="text_m">
                                Wenn du das Projekt verlässt werden alle Projektaufträge innerhalb des Projektes unwiderruflich gelöscht.
                            </Typography>
                            <ConfirmDialog
                                title='Projekt wirklich verlassen?'
                                message={`Bist du dir sicher?`}
                                onConfirm={handleProjectLeave}
                                renderButton={(onClick) => (
                                    <Button
                                        variant='outlined'
                                        color='error'
                                        onClick={onClick}
                                        startIcon={<Delete/>}
                                    >
                                        Verlassen
                                    </Button>
                                )}
                            />
                        </Stack>
                    </CustomPaper>
                }
            </Stack>
        </Stack>
    )
}

export default ProjectMembers