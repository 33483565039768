import { useCallback, useEffect, useState } from "react"
import { Box, Stack, Dialog, DialogContent, DialogContentText, DialogTitle, Divider, Typography, DialogActions, Button } from "@mui/material"
import { formatNumber } from "../../../../../utils/helper"
import { getCookie } from "../../../../../utils/getCookie"
import useStyledSnackbar from "../../../../../hooks/useStyledSnackbar"
import NoData from "../../../../Widgets/NoData"
import Paywall from "components/Widgets/Paywall"


const MaterialCargeBackInfo = ({ material }) =>{
    const unit_str = material.unit.split('/')
    const unit_suf = unit_str[1]

    return(
        <Stack
            direction="row"
            justifyContent="space-between"
            alignItems="center"
        >
            <Stack
                sx={{flex: 1}}
            >
                <Typography
                    variant="subttile_s"
                    color="primary"
                >
                    {`Art.-Nr. ${material.unique_material_id} | ${material.manufacturer}`}
                </Typography>
                <Typography
                    variant="text_m"
                >
                    {material.description}
                </Typography>
            </Stack>
            <Typography
                    variant="value"
                >
                    {`${formatNumber(material.balance, 2, unit_suf)}`}
            </Typography>
        </Stack>
    )
}


const ChargeBackDialog = ({ open, onClose, project_id, selectedSubProject }) =>{

    const { enqueueSnackbar } = useStyledSnackbar()
    const [chargeBackMaterials, setchargeBackMaterials] = useState([])

    const getBalance = useCallback(async() => {
		if(selectedSubProject === undefined || open === false) return
        const res = await fetch('/api/project/' + project_id + '/sub-project/' + selectedSubProject + '/material/charge-back', {
            method: 'GET'
        })
		const data = await res.json()
		if(res.ok){
            setchargeBackMaterials(data)
            console.log(data)
		}
    }, [project_id, selectedSubProject, open])

    useEffect(()=>{
		getBalance()
	}, [open, getBalance])

    const handleChargeBack = async() => {
		if(selectedSubProject === undefined || open === false) return

        const json = JSON.stringify({
            chargeBackMaterials: chargeBackMaterials,
        })

        const res = await fetch('/api/project/' + project_id + '/sub-project/' + selectedSubProject + '/material/charge-back', {
            method: 'PATCH',
            headers: {
                'Content-Type': 'application/json',
                'X-CSRFToken': getCookie('csrftoken')
            },
            body: json
        })

		// const data = await res.json()
		if(res.ok){
            onClose()
            enqueueSnackbar('Die Materialien wurden erfolgreich ins Lager gebucht', {
                variant: 'success'
            })
		}
    }

    return(
        <Dialog
            open={open}
            onClose={onClose}
            maxWidth="laptop"
        >
            <Paywall>
                <DialogTitle>
                    Material ins Lager bringen
                </DialogTitle>
                <DialogContent>
                    <DialogContentText sx={{mb: 2}}>
                        Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et justo duo dolores et ea rebum.
                    </DialogContentText>
                    <Stack sx={{ maxHeight: 600}}>
                        {chargeBackMaterials.map((m, idx) => {
                            return(
                                <Box key={m.id} sx={{pb: 1}}>
                                    <MaterialCargeBackInfo material={m}/>
                                    {chargeBackMaterials.length > idx + 1 && <Divider sx={{mt:1}} flexItem/>}
                                </Box>
                            )
                        })}
                        {chargeBackMaterials.length === 0 && <NoData text="Keine überschüssiges Material vorhanden"/>}
                    </Stack>
                </DialogContent>
                <DialogActions>
                    <Button
                        color="secondary"
                        onClick={onClose}
                    >
                        Abbrechen
                    </Button>
                    <Button
                        onClick={handleChargeBack}
                        disabled={chargeBackMaterials.length === 0}
                    >
                        Ins Lager buchen
                    </Button>
                </DialogActions>
            </Paywall>
        </Dialog>
    )
}

export default ChargeBackDialog