
export const uniqueMaterialIdGetter = (params, categories) =>{
    let key = 'XX'
    const found = categories.find(c => c.id === parseInt(params.row.category))
    if(found){
        key = found.key.toUpperCase()
        console.log(found.key)
    }
    return `${key}-${String(params.row.unique_material_id).padStart(4, '0')}`;
}

export const convertStringSelection = (dict) =>{
    let temp = []
    for (const [key, value] of Object.entries(dict)) {
        temp.push({ value: key, label: value })
    }
    return temp
}