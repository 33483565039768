import { useCallback, useEffect, useState } from "react"
import { Box, Stack, Dialog, DialogContent, DialogContentText, DialogTitle, Divider, Typography } from "@mui/material"
import { PALETTE } from "../../../../theme"
import { formatNumber } from "../../../../../utils/helper"
import { formatPrice, formatValueWithUnit } from "../MaterialTables/MaterialDatagridUtils"
import { DataGridPro } from "@mui/x-data-grid-pro"



const OfferDialog = ({ open, onClose, project_id, selectedSubProject }) =>{

    const [sum, setSum] = useState({
        offered: 0,
        not_offered: 0
    })

    const [offermaterial, setOfferMaterial] = useState([])

    const getOfferMaterial = useCallback(async() => {
		if(selectedSubProject === undefined || open === false) return
        const res = await fetch('/api/project/' + project_id + '/sub-project/' + selectedSubProject + '/material/offer', {
            method: 'GET'
        })
		const data = await res.json()
		if(res.ok){
            setOfferMaterial(data)
            console.log()
		}
    }, [project_id, selectedSubProject, open])

    useEffect(()=>{
		getOfferMaterial()
	}, [open, getOfferMaterial])

    useEffect(() => {
        let offerSum = 0
        let notOfferSum = 0
        offermaterial.forEach(m =>{
            let value = m.value_offered * m.pre_price
            if(m.is_offered){
                offerSum += value
            } else{
                notOfferSum += value
            }
        })
        setSum({
            offered: offerSum,
            not_offered: notOfferSum
        })
    },[offermaterial])

    const columns= [
        { field: 'index_', headerName: 'Pos.', width: 50, valueGetter: (params) => params.row.is_offered ? params.api.getRowIndex(params.id) + 1 : '#'},
        { field: 'unique_material_id', headerName: 'Artikelnummer', width: 150 },
        { field: 'description', headerName: 'Artikelbezeichnung', flex: 1 },
        { field: 'value_offered', headerName: 'Menge', width: 150, type:"number", valueFormatter: formatValueWithUnit },
        { field: 'pre_price', headerName: 'Einzelpreis', width: 150, type:"number", valueFormatter: formatPrice },
        { field: 'is_offered', headerName: 'Im Angebot', type:"boolean", width: 100 },
    ];

    return(
        <Dialog
            open={open}
            onClose={onClose}
            fullWidth
            maxWidth="laptop"
        >
            <DialogTitle>
                Angebotendes Material
            </DialogTitle>
            <DialogContent>
                <DialogContentText>
                    Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et justo duo dolores et ea rebum.
                </DialogContentText>
                <Box
                    sx={{
                        height: 600,
                        width: '100%',
                        '& .row-not-offered':{
                            opacity: 0.5
                        },
                    }}
                >
                    <DataGridPro
                        rows={offermaterial}
                        columns={columns}
                        getRowClassName={(params) => `${!params.row.is_offered ? 'row-not-offered' : ''}`}
                        initialState={{
                            columns: {
                                columnVisibilityModel: {
                                  is_offered: false,
                                },
                            },
                        }}
                        hideFooter
                    />
                </Box>
                {/* <Divider sx={{mt: 2, mb: 2}} flexItem/> */}
                <Stack
                    spacing={1}
                >
                    <Stack
                        direction="row"
                        justifyContent="space-between"
                        sx={{mt: 0}}
                    >
                        <Typography variant="subtitle_s" sx={{ color: PALETTE.secondary.main}}>
                            Nicht im Angebot
                        </Typography>
                        <Typography variant="subtitle_s" sx={{ color: PALETTE.secondary.main}}>
                            {formatNumber(sum.not_offered, 2, '€')}
                        </Typography>
                    </Stack>
                    <Divider flexItem/>
                    <Stack
                        direction="row"
                        justifyContent="space-between"
                        sx={{pt: 1}}
                    >
                        <Typography variant="subtitle_m">
                            Angebotssumme
                        </Typography>
                        <Typography variant="value">
                            {formatNumber(sum.offered, 2, '€')}
                        </Typography>
                    </Stack>
                </Stack>
                <Divider sx={{mt: 1}} flexItem/>
                <Divider sx={{mt: 0.5}} flexItem/>
            </DialogContent>
        </Dialog>
    )
}

export default OfferDialog