import { Stack, Typography, TextField, Button } from "@mui/material"

import useStyledSnackbar from '../../../../../../hooks/useStyledSnackbar'

import { getCookie } from '../../../../../../utils/getCookie'
import CustomPaper from "../../../../../Widgets/CustomPaper"


const InviteCompanyEmail = ({ project }) => {

    const { enqueueSnackbar } = useStyledSnackbar()

    const handleSubmit = async e => {
        e.preventDefault()
        const data = new FormData(e.target)
        const body = Object.fromEntries(data.entries())

        const res = await fetch('/api/project/' + project.id + '/companyinvite/email', {
            method: 'POST',
            body: JSON.stringify(body),
            headers: {
                'Content-Type': 'application/json',
                'X-CSRFToken': getCookie('csrftoken')
            }
        })
        if (res.ok) {
            enqueueSnackbar('Die Einladung wurde verschickt!', { variant: 'success' })
            e.target.reset()
        } else {
            enqueueSnackbar('Die Einladung konnte nicht verschickt werden!', { variant: 'error' })
        }

    }

    return(
        <CustomPaper
            title="Unternehmen nicht gefunden?"
        >
            <Stack
                component='form'
                onSubmit={handleSubmit}
                gap={2}
            >
                <Typography
                    variant="text_m_justify"
                >
                    Falls Sie das gewünschte Unternhemen nicht in der <strong>CONMEET Community</strong> gefunden haben.
                    Laden Sie das Unternehmen direkt per E-Mail ins Projekt ein.
                </Typography>
                <TextField
                        label="Email des Unternehmens"
                        variant="outlined"
                        fullWidth
                        required
                        name='email'
                        type='email'
                />
                <Button
                    variant="outlined"
                    size="large"
                    color="primary"
                    fullWidth
                    type='submit'
                >
                    Einladung verschicken
                </Button>
            </Stack>
        </CustomPaper>
    )
}

export default InviteCompanyEmail
