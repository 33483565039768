import { useState, useEffect, useContext } from 'react';
import useMediaQuery from '@mui/material/useMediaQuery';
import { Link } from 'react-router-dom';
import { useForm } from "react-hook-form";
import { getCookie } from '../../utils/getCookie'
import { setCsrfToken } from '../../utils/setCsrfToken'
import UserContext from '../../context/UserContext';

import { Stack, Box, Typography, Button, Collapse, Alert, Link as MuiLink } from '@mui/material'

//react-icons
import ControlledTextField from 'components/Widgets/ControlledInputs/ControlledTextField';
import LogoFullBlack from 'components/Widgets/Logos/LogoFullBlack';
import AppleStoreLink from 'components/Widgets/AppleStoreLink';
import PlayStoreLink from 'components/Widgets/PlayStoreLink';

const Login = () => {
    const breakpoint = useMediaQuery('(min-width: 920px)');

    const [, getUser] = useContext(UserContext)
    const { control, handleSubmit, formState: { errors, isDirty, isValid }} = useForm({
        mode: 'onChange',
        defaultValues:{
            username: '',
            password: '',
        }
    });

    const [errorMessage, setErrorMessage] = useState('')

    const handleLogin = async(data) => {
        const json = JSON.stringify(data)

        const res = await fetch('/account/login', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'X-CSRFToken': getCookie('csrftoken')
            },
            body: json
        })

        if (res.ok) {
            const data = await res.json()
            // console.log(data)
            getUser()
        } else {
            const data = await res.json()
            setErrorMessage(data.detail)
            // console.log(data)
        }
    }

    useEffect(setCsrfToken, [])

    return(
        <Stack
            direction={breakpoint ? 'row' : 'column'}
            alignItems='center'
            sx={{
                height: '100vh',
                position: 'relative'
            }}
        >
            <Box
                sx={{
                    backgroundImage: 'url("login_worker.jpg")',    //'url(https://source.unsplash.com/xdS9XEoKBLY/2670x4000)',
                    backgroundRepeat: 'no-repeat',
                    backgroundSize: 'cover',
                    backgroundPosition: 'center',
                    height:'100%',
                    flex: '1 1 0',
                    display: breakpoint ? 'block' : 'none'
                }}
            />
            <Stack
                justifyContent="center"
                alignItems="center"
                sx={{
                    height: '100%',
                    pt: 5,
                    flex: 1,
                }}
            >
                <LogoFullBlack width={300} height={150}/>
                <Stack
                    component="form"
                    onSubmit={handleSubmit(handleLogin)}
                    spacing={4}
                    sx={{
                        maxWidth: 450,
                    }}
                >
                    <Stack>
                        <Typography
                            color="primary.main"
                            variant="subtitle_m"
                        >
                            Anmeldung
                        </Typography>
                        <Typography
                            color="background.light5"
                            variant="title_m"
                        >
                            Willkommen zurück!
                        </Typography>
                        <Typography
                            variant="text_m"
                        >
                            Melde dich an und los geht's...
                        </Typography>
                    </Stack>
                    <Stack
                        gap={1}
                    >
                        <ControlledTextField
                            name="username"
                            label="Benutzername"
                            control={control}
                            rules={{required: true}}
                            errors={errors}
                        />
                        <ControlledTextField
                            name="password"
                            label="Passwort"
                            control={control}
                            rules={{required: true}}
                            errors={errors}
                            type="password"
                        />
                        <Button
                            type="submit"
                            color="primary"
                            variant="contained"
                            size="large"
                            fullWidth
                            disabled={!isDirty || !isValid}
                        >
                            Anmelden
                        </Button>
                        <Collapse in={!!errorMessage}>
                            <Alert severity="error">{errorMessage}</Alert>
                        </Collapse>
                    </Stack>
                    <Stack gap={0.5}>
                        <Stack
                            direction='row'
                            justifyContent="space-between"
                            gap={2}
                        >
                            <Typography
                                variant="text_s"
                            >
                                Noch kein Konto?
                            </Typography>
                            <Typography
                                variant="link_s"
                            >
                                <Link to="/register">Registierung</Link>
                            </Typography>
                        </Stack>
                        <Stack direction='row' justifyContent="space-between" spacing={1}>
                            <Typography
                                variant="text_s"
                            >
                                Passwort vergessen?
                            </Typography>
                            <Typography
                                variant="link_s"
                            >
                                <Link to="/password-reset/request">Passwort zurücksetzen</Link>
                            </Typography>
                        </Stack>
                    </Stack>
                </Stack>
                <Stack
                    flexDirection="row"
                    justifyContent='center'
                    alignItems='center'
                    gap={2}
                    sx={{
                        mt: 'auto',
                        p: 4,
                    }}
                >
                    <AppleStoreLink height={40}/>
                    <PlayStoreLink height={40 + 12}/>
                </Stack>
                <Stack
                    justifyContent="center"
                    alignItems="center"
                    sx={{p:2}}
                    gap={0.5}
                >
                    <Stack
                        flexDirection="row"
                        gap={1}
                    >
                        <Typography variant="text_s" sx={{ fontSize: 14 }}>
                            <MuiLink
                                href='https://beyond-structures.de/'
                                target="_blank"
                                rel="noreferrer"
                                underline="none"
                                color="default"
                            >
                                beyond structures GmbH & Co. KG |
                            </MuiLink>
                        </Typography>
                        <Typography variant="text_s" sx={{ fontSize: 14 }}>
                            <MuiLink
                                href='https://conmeet.io/impressum'
                                target="_blank"
                                rel="noreferrer"
                                underline="none"
                            >
                                Impressum
                            </MuiLink>
                        </Typography>
                    </Stack>
                    <Typography variant="text_s">
                        &copy; Alle Rechte vorbehalten 2023
                    </Typography>

                </Stack>
            </Stack>
        </Stack>
    )
}
export default Login;
