import { useState, useEffect, useContext, useCallback } from 'react'

import { Stack, Avatar, Box, Chip, Typography } from '@mui/material'

import AccountContext from '../../context/AccountContext'
import ControlledSelect from './ControlledInputs/ControlledSelect'


const CompanyMemberMultiSelection = ({ control, errors, name, label , includeCurrentUser=false, onlyWorkers=false}) => {

    const [account] = useContext(AccountContext)
    const [companyMembers, setCompanyMembers] = useState({})

    const getCompanyMembers = useCallback(async () => {
        const res = await fetch('/api/company/members?onlyWorkers=' + (onlyWorkers ? '1' : '0'), {
            method: 'GET'
        })
        const json = await res.json()
        if (res.ok) {
            const transformed = {}
            for (const cm of json) {
                if(includeCurrentUser || cm.id !== account.id){
                    transformed[cm.id] = cm
                }
            }
            setCompanyMembers(transformed)
        }
    }, [account, includeCurrentUser, onlyWorkers])

    useEffect(() => {
        getCompanyMembers()
    }, [getCompanyMembers])

    return (
        <ControlledSelect
            control={control}
            errors={errors}
            name={name}
            label={label}
            items={companyMembers}
            renderValue={(selected) => (
                <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                    {selected.map((key) => (
                        <Chip
                            key={key}
                            label={companyMembers[key].firstname + ' ' + companyMembers[key].lastname}
                            avatar={<Avatar alt='' src={companyMembers[key].avatar} />}
                        />
                    ))}
                </Box>
            )}
            itemRender={item =>
                <Stack
                    direction="row"
                    alignItems='center'
                    spacing={1}
                >
                    <Avatar alt='' src={item.avatar} />
                    <Typography>{item.firstname} {item.lastname}</Typography>
                </Stack>
            }
            multiple
        />
    )
}

export default CompanyMemberMultiSelection
