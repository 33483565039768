import { Avatar, Button, Chip, Dialog, DialogActions, DialogContent, DialogTitle, Divider, FormControlLabel, MenuItem, Select, Stack, Switch, Typography } from "@mui/material"
import { LoadingButton } from '@mui/lab';
import { Box } from "@mui/system"
import ControlledSelect from "components/Widgets/ControlledInputs/ControlledSelect"
import ControlledSwitch from "components/Widgets/ControlledInputs/ControlledSwitch"
import ProjectContext from "context/ProjectContext"
import { useContext, useEffect, useState } from "react"
import { useForm } from "react-hook-form"
import { getCookie } from "utils/getCookie"
import { VISIBILITIES } from "."
import useStyledSnackbar from "hooks/useStyledSnackbar";
import AccountContext from "context/AccountContext";

const SettingsDialog = ({ file, handleCloseSettings, updateDocumentState }) => {
    const { control, handleSubmit, watch, formState: { errors, isDirty, isValid }, reset, setValue } = useForm({
        mode: 'onChange',
        defaultValues: {
            outdated: false,
            plan: false,
            visibility: VISIBILITIES.private,
            shared_with: []
        }
    })
    const [ lockedSharedWith, setLockedSharedWith ] = useState([])
    const handleSharedWithChange = (e) => {
        let n = Array.from(new Set([...e.target.value, ...lockedSharedWith]))
        setValue('shared_with', n)
    }

    const [account] = useContext(AccountContext)
    const [ ,, projectTeamMembers ] = useContext(ProjectContext)

    const [ company, setCompany ] = useState(0)
    const handleCompanyChange = (e) => {
        setCompany(e.target.value)
    }

    const [ loading, setLoading ] = useState(false)

    useEffect(() => {
        if (!file) return
        reset({
            outdated: file.outdated,
            plan: file.plan,
            visibility: file.visibility,
            shared_with: file.shared_with,
        })
        setLockedSharedWith(file.shared_with)
        setLoading(false)
    }, [file])

    const { enqueueSnackbar } = useStyledSnackbar()
    const updateDocument = async (d) => {
        setLoading(true)
        try {
            const res = await fetch('/documents/' + file.id, {
                method: 'PATCH',
                body: JSON.stringify(d),
                headers: {
                    'Content-Type': 'application/json',
                    'X-CSRFToken': getCookie('csrftoken')
                }
            })
            const json = await res.json()
            if (res.ok) {
                updateDocumentState(json)
                reset({
                    outdated: json.outdated,
                    plan: json.plan,
                    visibility: json.visibility,
                    shared_with: json.shared_with,
                })
                enqueueSnackbar('Es wurde erfolgreich gespeichert!', { variant: 'success' })
                setLoading(false)
            } else {
                enqueueSnackbar(json, { variant: 'error' })
            }
        } catch {
            enqueueSnackbar('Es ist ein Fehler aufgetreten!', { variant: 'error' })
        }
    }

    return (
        <Dialog
            open={Boolean(file)}
            onClose={handleCloseSettings}
            maxWidth="tablet"
            fullWidth
        >
            <DialogTitle>
                Optionen
            </DialogTitle>
            <DialogContent>
                <Stack
                    id='settingsForm'
                    component='form'
                    onSubmit={handleSubmit(updateDocument)}
                    gap={2}
                >
                    <ControlledSwitch
                        control={control}
                        name='outdated'
                        color='primary'
                        label='Als veraltet markieren'
                    />
                    <ControlledSwitch
                        control={control}
                        name='plan'
                        color='primary'
                        label='Als Plan markieren'
                    />
                    <ControlledSelect
                        control={control}
                        name="visibility"
                        label="Sichtbarkeit"
                        rules={{ required: true }}
                        errors={errors}
                        items={{
                            [VISIBILITIES.public]: 'Öffentlich | Alle',
                            ...((account.is_admin || account.is_manager) ? { [VISIBILITIES.privileged]: 'Öffentlich | Planer' } : {}),
                            [VISIBILITIES.internal_all]: 'Intern | Alle',
                            ...((account.is_admin || account.is_manager) ? { [VISIBILITIES.internal_privileged]: 'Intern | Planer' } : {}),
                            [VISIBILITIES.private]: 'Privat',
                        }}
                    />
                    <Divider textAlign='left'><Typography variant='subtitle_m'>Dokument teilen</Typography></Divider>
                    <Stack
                        direction='row'
                        gap={2}
                        flexWrap='wrap'
                    >
                        {projectTeamMembers.map(c => {
                            return c.project_members.map(pm => {
                                if (!watch('shared_with').includes(pm.id)) return
                                return <Chip
                                    key={pm.id}
                                    label={pm.firstname + ' ' + pm.lastname}
                                    avatar={<Avatar alt='' src={pm.avatar} />}
                                />
                            })
                        })}
                    </Stack>
                    <Select
                        value={company}
                        onChange={handleCompanyChange}
                    >
                        {projectTeamMembers.map((c, k) => (
                            <MenuItem value={k} key={k}>{c.name}</MenuItem>
                        ))}
                    </Select>
                    <Select
                        value={watch('shared_with')}
                        onChange={handleSharedWithChange}
                        renderValue={(selected) => (
                            <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                                {selected.map((key) => {
                                    for (let pm of projectTeamMembers[company].project_members) {
                                        if (pm.id === key)
                                            return <Chip
                                                key={key}
                                                label={pm.firstname + ' ' + pm.lastname}
                                                avatar={<Avatar alt='' src={pm.avatar} />}
                                            />
                                    }
                                })}
                            </Box>
                        )}
                        multiple
                    >
                        {projectTeamMembers[company]?.project_members.map(pm => {
                            if (pm.cm_id === account.id) return

                            return <MenuItem value={pm.id} key={pm.id}>
                                <Stack
                                    direction="row"
                                    alignItems='center'
                                    spacing={1}
                                >
                                    <Avatar alt='' src={pm.avatar} />
                                    <Typography>{pm.firstname} {pm.lastname}</Typography>
                                </Stack>
                            </MenuItem>
                        })}
                    </Select>
                </Stack>
            </DialogContent>
            <DialogActions>
                <Button onClick={handleCloseSettings} color="secondary">Zurück</Button>
                <LoadingButton loading={loading} type='submit' form='settingsForm' color="primary">Speichern</LoadingButton>
            </DialogActions>
        </Dialog>
    )
}

export default SettingsDialog
