export const TooltipContent = {
    company_member_wages: "Wenn alle deine Mitarbeiter angelegt sind errechnet sich der Wert automatisch.",
    company_member_salaries:"Wenn alle deine Mitarbeiter angelegt sind errechnet sich der Wert automatisch.",
    company_member_salaries_factorable: ".....",
    social_taxes:"Hier kann dir dein Lohnbüro helfen.",
    rent_extra_costs:"Trage hier deine Miet- und Nebenkosten ein.",
    office_management:"Zum Beispiel… Papier, Druckerfarbe, Frankierkosten etc…",
    selling_expenses:"Trage hier die Kosten deiner Vertriebsausgaben ein.",
    marketing_promotion:"Denk dran.. \"Wer nicht wirbt, der stirbt!\"",
    insurance:"Bist du gut Versichert? Trage hier all deine Versicherungskosten ein.",
    operational_taxes:"Diese Zahl einzutragen fällt einem schwer.",
    finance_costs:"Investitionen sind wichtig! Trage deine Kosten hier ein.",
    fuel_costs:"Deine jährlichen Spritkosten.",
    other_costs:" Falls dir ein Kostenpunkt gefehlt hat, trage es hier ein.",
    days_per_year:"Die Arbeit nehmen wir dir ab.",
    saturdays_sundays_per_year:"Auch das übernehmen wir für dich.",
    national_holidays_per_year:"Hier trägst du bitte alle Feiertage deines Bundeslandes ein",
    vacation_days:"Wenn alle deine Mitarbeiter angelegt sind errechnet sich der Wert automatisch.",
    sick_days:"Hier trägst du den Durschnittswert aller Krankheitstage deiner Mitarbeiter ein.",
    hours_per_day:"Hier wird die tägliche Arbeitszeit pro Mitarbeiter eingetragen.",
    correction_factor:"Immer 100%... ist nicht die Realität. Baubesprechung... Stau... Wartezeiten... all dies senkt die tatsächliche Produktivität. Trage hier deinen Wert ein. Tipp: Im Durschnitt liegt der Faktor zwischen 80 und 90 %, aber deine Mitarbeiter kennst du am besten.",
    fact_company_members:"Wenn alle deine Mitarbeiter angelegt sind errechnet sich der Wert automatisch.",
    wage_profit:"Lohngewinn",
    fix_wage_rate:"sdsdasdas",
}

export const InfoText = `
    Hier kannst du deinen Stundenverrechnungssatz errechnen lassen.
    Bitte denke daran, dass alle Werte sich stetig ändern.
    Hier bekommst du aber eine gute Hilfe immer deinen Stundenverrechnungssatz ziemlich genau zu haben.
    Um deinen Stundenverrechnungssatz ganz genau errechnen zu können ziehe bitte deinen Steuerberater/in und- oder deine Buchhaltung hinzu.
    Wir weisen darauf hin das conMEET keine Haftung für die Errechnung übernehmen kann.
`