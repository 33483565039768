import { useState } from "react";
import { useForm } from "react-hook-form";

import { Button, Stack, Divider, ToggleButton, ToggleButtonGroup, DialogContent, Dialog, DialogTitle, Typography } from "@mui/material";

import useStyledSnackbar from "../../../../hooks/useStyledSnackbar";

import { getCookie } from "../../../../utils/getCookie";
import { getLocalDate, getISOTimeStamp } from "../../../../utils/date_functions";

import ControlledDatePicker from '../../../Widgets/ControlledInputs/ControlledDatePicker'
import ControlledDateRangePicker from "../../../Widgets/ControlledInputs/ControlledDateRangePicker"
import ControlledTimePicker from "../../../Widgets/ControlledInputs/ControlledTimepicker"
import ControlledSelect from "../../../Widgets/ControlledInputs/ControlledSelect"
import ControlledTextField from "../../../Widgets/ControlledInputs/ControlledTextField"
import { checkSelectionZero } from "../../../../utils/helper";
import { RiTimerFill } from "react-icons/ri";
import { PALETTE } from "components/theme";
import ControlledSwitch from "components/Widgets/ControlledInputs/ControlledSwitch";


const TimeRecordingAddDialog = ({ open, onClose, company_member_id, timestampTypeKeys, projectKeys, handleUpdate }) => {

    const [toggle, setToggle] = useState('daily');
    const [breaektime, setBreaktime] = useState([]);
    const { enqueueSnackbar } = useStyledSnackbar()
    const currentDate = new Date()

    const { control, handleSubmit, watch, formState: { errors, isValid }} = useForm({
        mode: 'onChange',
        defaultValues:{
            date: new Date(),
            start: new Date(currentDate.setHours(7,0,0,0)),
            end: new Date(currentDate.setHours(15,45,0,0)),
            date_range:  [currentDate, new Date(currentDate.setDate(currentDate.getDate() + 1))],
            type_key: '',
            sub_project_key: '',
            description: '',
            with_drive_rate: false,
        }
    });

    const handleToggle = (event, newValue) => {
        if (newValue === null) return
        setToggle(newValue);
    };

    const handleBreaktime = (event, newValue) => {
        setBreaktime(newValue);
    };

    const addTimeRecording = async(data) => {
        data.sub_project_key = checkSelectionZero(data.sub_project_key)
        data.start = getISOTimeStamp(data.start)
        data.end = getISOTimeStamp(data.end)
        data.date = getLocalDate(data.date)
        data.breaktime = breaektime.reduce((partialSum, a) => partialSum + a, 0)
        data.date_range = data.date_range.map(d => getLocalDate(d))

        const json = JSON.stringify({
            variant: toggle,
            company_member_id: company_member_id,
            ...data
        })
        const res =  await fetch('/api/time-recording', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'X-CSRFToken': getCookie('csrftoken')
            },
            body: json
        })
        if(res.ok){
            handleUpdate()
            enqueueSnackbar('Die Zeit wurde erfolgreich erfasst!', {
                variant: 'success'
            })
            onClose()
        } else{
            enqueueSnackbar('Upsi, die Zeit wurde nicht erfasst! Es ist irgendwas fehlgelaufen.', {
                variant: 'error'
            })
        }
    }

    return(
        <Dialog
            open={open}
            onClose={onClose}
            maxWidth="tablet"
            fullWidth
        >
            <DialogTitle>
                Neue Zeiteintragung
            </DialogTitle>
            <DialogContent>
                <Stack
                    component="form"
                    onSubmit={handleSubmit(addTimeRecording)}
                    spacing={2}
                >
                    <ToggleButtonGroup
                        color="primary"
                        value={toggle}
                        exclusive
                        onChange={handleToggle}
                        size="small"
                        fullWidth
                        >
                        <ToggleButton value="daily" aria-label="daily">
                            Täglich
                        </ToggleButton>
                        <ToggleButton value="range" aria-label="range">
                            Mehrtägig
                        </ToggleButton>
                    </ToggleButtonGroup>
                    <Stack direction='column' gap={2}>
                        <ControlledDatePicker
                            name="date"
                            label="Tag"
                            control={control}
                            rules={{ required: toggle === 'range' ? false : true }}
                            disabled={ toggle !== 'range' ? false : true}
                            errors={errors}
                        />
                        <ControlledDateRangePicker
                            name="date_range"
                            startText="Beginn"
                            endText="Ende"
                            control={control}
                            rules={{ required: toggle === 'daily' ? false : true }}
                            disabled={toggle !== 'daily' ? false : true}
                            errors={errors}
                            showIcon={false}
                        />
                    </Stack>
                    <Divider />
                    <Stack
                        direction='row'
                        alignItems='center'
                        spacing={2}
                    >
                        <ControlledTimePicker
                            name="start"
                            label="Startzeit"
                            control={control}
                            rules={{ required: true }}
                            errors={errors}
                        />
                        <ControlledTimePicker
                            name="end"
                            label="Endzeit"
                            control={control}
                            rules={{ required: true }}
                            errors={errors}
                            minTime={watch('start')}
                        />
                    </Stack>
                    <Stack gap={1}>
                        <Stack
                            direction="row"
                            justifyContent="flex-start"
                            alignItems="center"
                            gap={1}
                        >
                            <RiTimerFill size={22} color={PALETTE.primary.main}/>
                            <Typography variant="subtitle_m">Pausenzeit: </Typography>
                            <Typography variant="value" color="primary">{`${breaektime.reduce((partialSum, a) => partialSum + a, 0)} Min.`}</Typography>
                        </Stack>
                        <ToggleButtonGroup
                            color="secondary"
                            value={breaektime}
                            onChange={handleBreaktime}
                            fullWidth
                        >
                            <ToggleButton value={10}>
                                10 min.
                            </ToggleButton>
                            <ToggleButton value={15}>
                                15 min.
                            </ToggleButton>
                            <ToggleButton value={20}>
                                20 min.
                            </ToggleButton>
                            <ToggleButton value={30}>
                                30 min.
                            </ToggleButton>
                            <ToggleButton value={45}>
                                45 min.
                            </ToggleButton>
                            <ToggleButton value={60}>
                                60 min.
                            </ToggleButton>
                        </ToggleButtonGroup>
                    </Stack>
                    <ControlledSelect
                        name="type_key"
                        label="Art der Zeiterfassung"
                        control={control}
                        rules={{ required: true }}
                        items={timestampTypeKeys}
                        errors={errors}
                    />
                    <ControlledSelect
                        name="sub_project_key"
                        label="Projektauftrag zuweisen"
                        control={control}
                        rules={{ required: true }}
                        items={projectKeys}
                        errors={errors}
                    />
                    <ControlledTextField
                        name="description"
                        label="Beschreibung der Tätigkeit"
                        control={control}
                        rules={{ required: true }}
                        multiline
                        rows={4}
                        errors={errors}
                    />
                    <ControlledSwitch
                        name="with_drive_rate"
                        label="Mit Fahrtenpauschale"
                        control={control}
                    />
                    <Button
                        type="submit"
                        variant="outlined"
                        color="primary"
                        size="large"
                        disabled={!isValid}
                    >
                        Hinzufügen
                    </Button>
                </Stack>
            </DialogContent>
        </Dialog>
    )
}

export default TimeRecordingAddDialog