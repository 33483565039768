import { useEffect, useState } from "react";


const useSuppliers = () => {

    const [suppliers, setSuppliers] = useState([])

    const getSuppliers = async () => {
        const res = await fetch('/api/company/supplier', {
            method: 'GET',
        })
        const _data = await res.json()

        if (res.ok) {
            const transformed = {}
            for (const supplier of _data) {
                transformed[supplier.id] = supplier.name + ' | ' + supplier.contact_person
            }
            setSuppliers(transformed)
        }
    }

    useEffect(() => {
        getSuppliers()
    }, [])

    return { suppliers }
}

export default useSuppliers