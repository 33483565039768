import { useContext, useEffect, useState, useRef, useCallback } from "react"
import { Dialog, DialogTitle, DialogContent } from '@mui/material'

import ProjectContext from '../../../../../context/ProjectContext'
import useStyledSnackbar from '../../../../../hooks/useStyledSnackbar'
import LeftRightList from '../../../../Widgets/LeftRightList'
import CompanyMemberListItem from "../../../../Widgets/CompanyMemberListItem"

import { getCookie } from "../../../../../utils/getCookie"



const ProjectAddMemberDialog = ({ open, onClose }) => {

    const { enqueueSnackbar } = useStyledSnackbar()
    const [project, , , , , getProjectTeamMembers] = useContext(ProjectContext)

    const [companyMembersIn, setCompanyMembersIn] = useState([])
    const [companyMembersOut, setCompanyMembersOut] = useState([])

    const initialCompanyMembersIn = useRef()
    const initialCompanyMembersOut = useRef()

    const getAddedItems = (initialArray, newArray) => newArray.filter(i => !initialArray.includes(i))
    const getIDs = array => array.map(i => i.id)

    const getCompanyMembersInOut = useCallback(async () => {
        const res = await fetch('/api/project/' + project.id + '/members/add')
        const json = await res.json()
        setCompanyMembersIn(json.in)
        initialCompanyMembersIn.current = json.in
        setCompanyMembersOut(json.out)
        initialCompanyMembersOut.current = json.out
    }, [project.id])

    const handleSave = async () => {
        const body = {
            "add": getAddedItems(getIDs(initialCompanyMembersIn.current), getIDs(companyMembersIn)),
            "delete": getAddedItems(getIDs(initialCompanyMembersOut.current), getIDs(companyMembersOut))
        }

        const res = await fetch('/api/project/' + project.id + '/members/add', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'X-CSRFToken': getCookie('csrftoken'),
                },
                body: JSON.stringify(body)
            }
        )
        if (res.ok) {
            getCompanyMembersInOut()
            getProjectTeamMembers()
            enqueueSnackbar('Mitarbeiter im Projekt gespeichert!', { variant: 'success'})
        }
    }

    useEffect(() => {
        getCompanyMembersInOut()
    }, [getCompanyMembersInOut])

    return (
        <Dialog
            open={open}
            onClose={onClose}
            fullWidth
            maxWidth="laptop"
        >
            <DialogTitle>
                Mitarbeiter zum Projekt hinzufügen
            </DialogTitle>
            <DialogContent>
               <LeftRightList
                    leftItems={companyMembersOut}
                    setLeftItems={setCompanyMembersOut}
                    leftTitle='Nicht hinzugefügt'

                    rightItems={companyMembersIn}
                    setRightItems={setCompanyMembersIn}
                    rightTitle='Hinzufügt'

                    itemName='Mitarbeiter'
                    listItemRender={(i, props) =>
                        <CompanyMemberListItem key={i.id} data={i} {...props} />
                    }
                    handleSave={handleSave}
                />
            </DialogContent>
        </Dialog>
    )
}

export default ProjectAddMemberDialog
