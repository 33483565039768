import { useState } from 'react'

import { Grid, TextField, Typography, Box, Stack, Zoom } from "@mui/material";
import City from '../../../../assets/svgs/city.svg'

import ProjectSearchCard from './ProjectSearchCard'
import CustomPage from '../../../Widgets/CustomPage';

import useDebounce from '../../../../hooks/useDebounce'
import CustomPaper from '../../../Widgets/CustomPaper';


const ProjectsSearch = () =>{

    const [projects, setProjects] = useState([])
    const [searchTerm, setSearchTerm] = useState('')

    const onChange = async(search_str) =>{
        const res = await fetch('/api/projects/search?city=' + search_str, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
            },
        })
        if (res.ok) {
            const data = await res.json()
            console.log(data)
            setProjects(data)
        }
    }

    const handleSearch = (search_str) =>{
        if (search_str === ''){
            setSearchTerm(search_str)
            setProjects([])
            return
        }
        debouncedOnChange(search_str)
        setSearchTerm(search_str)
    }

    const handleReset = () =>{
        setProjects([])
        setSearchTerm('')
    }

    const debouncedOnChange = useDebounce(onChange)

    return(
        <CustomPage
            title="Projekte in deiner Umgebung"
        >
            <Stack
                direction='row'
                gap={3}
                height="100%"
            >
                <CustomPaper
                    title="Projektsuche"
                    sx={{
                        maxWidth: 280,
                        maxHeight: 300,
                    }}
                >
                    <Stack
                        justifyContent='space-between'
                        height="100%"
                        gap={3}
                    >
                        <Typography variant='text_m_justify'>
                            Du bist auf der Suche nach einem Projekt?
                            Hier siehst du ob dein Projekt schon besteht. Falls ja, kannst du dem Projekt beitreten oder gegebenenfalls den Beitritt anfragen.
                        </Typography>
                        <TextField
                            value={searchTerm}
                            onChange={(e)=> (handleSearch(e.target.value))}
                            label="Name der Stadt"
                            variant="outlined"
                        />
                    </Stack>
                </CustomPaper>
                {projects.length !== 0 ?
                    <Grid
                        container
                        spacing={3}
                    >
                        {projects.map((project, idx)=>{
                            return(
                                <Zoom
                                    key={`fade-search-project-container-${idx}`}
                                    in={true}
                                    style={{ transitionDelay: idx * 250 + 'ms'}}
                                    timeout={500}
                                >
                                    <Grid
                                        item
                                        desktop={4}
                                        laptop={6}
                                        tablet={12}
                                        mobile={12}
                                        key={`search-project-${idx}`}
                                    >
                                        <ProjectSearchCard project={project} handleReset={handleReset}/>
                                    </Grid>
                                </Zoom>
                            )
                        })}
                    </Grid>
                :
                    <Stack
                        justifyContent="center"
                        alignItems="center"
                        spacing={2}
                        height="100%"
                    >
                        <Box
                            component="img"
                            src={City}
                            alt="not found"
                            sx={{
                                p:3,
                                width:'40%',
                            }}
                        />
                        <Typography
                            variant="text_m"
                        >
                               {searchTerm !== '' ? 'Keine Projekte in der Stadt gefunden' : 'Suche nach aktuellen Baustellen in deiner Umgebung...'}
                        </Typography>
                    </Stack>
                }
            </Stack>
        </CustomPage>
    )
}

export default ProjectsSearch;
