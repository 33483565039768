import { useState } from 'react';
import { Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, IconButton, Stack, Typography } from '@mui/material'
import ImageList from '@mui/material/ImageList';
import ImageListItem from '@mui/material/ImageListItem';
import ImageListItemBar from '@mui/material/ImageListItemBar';
import PlayCircleOutlineIcon from '@mui/icons-material/PlayCircleOutline';

import Title from "./Title";
import Tooltip from "./Tooltip";
import SettingsButton from "./SettingsButton";

import ImagePopup from '../../../Widgets/ImagePopup'
import { getFileExtension } from 'utils/file_extension';
import { FILE_TYPE_EXTENSIONS } from '.';
import { fileNameFromUrl } from 'utils/file_name_from_url';
import theme from 'components/theme';


const FileGrid = ({ files, getUploads, popupFile, popupExt, handleOpenFile, handleCloseFile, handleOpenSettings }) => {
    return (
        <Box>
            <ImageList cols={4} gap={16} sx={{ overflow: 'visible' }}>
                {files.map(file => {
                    const ext = '.' + getFileExtension(file.file)
                    return (
                        <ImageListItem key={file.id}>
                            <Tooltip document={file}>
                                <Stack
                                    sx={{
                                        overflow: 'hidden',
                                        borderRadius: 2,
                                        aspectRatio: '1/1',
                                        background: FILE_TYPE_EXTENSIONS.images.includes(ext) ||
                                            FILE_TYPE_EXTENSIONS.videos.includes(ext) ?
                                            'black' : theme.palette.background.light10,
                                        '&:hover .playButton': {
                                            color: 'white !important'
                                        }
                                    }}
                                >
                                    <Box
                                        sx={{
                                            height: '100%',
                                            width: '100%'
                                        }}
                                        component='a'
                                        {...(FILE_TYPE_EXTENSIONS.images.includes(ext) ||
                                            FILE_TYPE_EXTENSIONS.videos.includes(ext) ?
                                            {}
                                        :
                                            {
                                                href: file.file,
                                                target: '_blank'
                                            }
                                        )}
                                        onClick={() => handleOpenFile(file)}
                                    >
                                        {FILE_TYPE_EXTENSIONS.images.includes(ext) ?
                                            <img
                                                src={file.file}
                                                alt=''
                                                style={{
                                                    objectFit: 'cover',
                                                    height: '100%',
                                                    width: '100%'
                                                }}
                                            />
                                        :
                                        (FILE_TYPE_EXTENSIONS.videos.includes(ext) ?
                                            <Box sx={{ width: '100%', height: '100%' }}>
                                                <video
                                                    src={file.file}
                                                    style={{
                                                        width: '100%',
                                                        height: '100%',
                                                        opacity: 0.5,
                                                        objectFit: 'cover'
                                                    }}
                                                    preload="metadata"
                                                ></video>
                                                <IconButton
                                                    className='playButton'
                                                    size='large'
                                                    sx={{
                                                        position: 'absolute',
                                                        top: '50%',
                                                        left: '50%',
                                                        color: 'rgb(196, 196, 196)',
                                                        transform: 'translateX(-50%) translateY(-50%)',
                                                    }}
                                                >
                                                    <PlayCircleOutlineIcon fontSize="inherit" />
                                                </IconButton>
                                            </Box>
                                        :
                                        <Stack justifyContent='center' alignItems='center' sx={{ width: '100%', height: '100%', p: 2 }}>
                                            <Typography sx={{ overflowWrap: 'anywhere', color: theme.palette.text.light}}>{fileNameFromUrl(file.file)}</Typography>
                                        </Stack>
                                        )}
                                    </Box>
                                    <ImageListItemBar
                                        title={<Title document={file} />}
                                        subtitle={`${file.company_member.firstname} ${file.company_member.lastname} - ${new Date(file.created).toLocaleString()}`}
                                        sx={{ overflow: 'hidden', borderBottomLeftRadius: 8, borderBottomRightRadius: 8 }}
                                        actionIcon={<SettingsButton document={file} onOpen={handleOpenSettings} />}
                                    />
                                </Stack>
                            </Tooltip>
                        </ImageListItem>
                    )
                })}
            </ImageList>
        </Box>
    )
}

export default FileGrid
