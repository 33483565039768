import { useState, useEffect, useContext, useReducer, useCallback } from "react"
import ProjectContext from '../../../../context/ProjectContext'
import AccountContext from '../../../../context/AccountContext'

import { getConstants } from "../../../../utils/getConstants";

import { Box, Stack, ToggleButtonGroup, ToggleButton, Button} from '@mui/material';
import { Add } from '@mui/icons-material';

import TicketDialog from "./TicketDialog";
import TicketEditDialog from "./TicketEditDialog";
import TicketChangeStateDialog from "./TicketChangeStateDialog";
import TicketAddDialog from "./TicketAddDialog";
import TicketDeleteDialog from "./TicketDeleteDialog";
import TicketChainAddDialog from "./TicketChainAddDialog";
import TicketChainDeleteDialog from "./TicketChainDeleteDialog";
import TicketShareDialog from "./TicketShareDialog ";
import TicketChains from "./TicketChains";

import { ACTIONS, handleTicketFilter, handleTicketFilterInfo } from "./TicketHelpers";
import ProjectTicketsLimited from "./ProjectTicketsLimited";
import CustomPaper from "../../../Widgets/CustomPaper";
import NoData from "components/Widgets/NoData";


const reducer = (state, action) =>{
    switch(action.type){
        case ACTIONS.OPEN_TICKET_INFO:
            return {
                ...state,
                selectedTicket: action.payload.ticket,
                openTicketInfo: true
            }
        case ACTIONS.CLOSE_TICKET_INFO:
            return {
                ...state,
                openTicketInfo: false,
            }
        case ACTIONS.OPEN_TICKET_EDIT:
            return {
                ...state,
                selectedTicket: action.payload.ticket,
                openTicketEdit: true
            }
        case ACTIONS.CLOSE_TICKET_EDIT:
            return {
                ...state,
                openTicketEdit: false,
            }
        case ACTIONS.OPEN_TICKET_ADD:
            return {
                ...state,
                selectedTicketChain: action.payload.ticketChain,
                openTicketAdd: true
            }
        case ACTIONS.CLOSE_TICKET_ADD:
            return {
                ...state,
                openTicketAdd: false,
            }
        case ACTIONS.OPEN_TICKET_DELETE:
            return {
                ...state,
                selectedTicket: action.payload.ticket,
                openTicketDelete: true
            }
        case ACTIONS.CLOSE_TICKET_DELETE:
            return {
                ...state,
                openTicketDelete: false,
            }
        case ACTIONS.OPEN_TICKET_SHARE:
            return {
                ...state,
                selectedTicket: action.payload.ticket,
                openTicketShare: true
            }
        case ACTIONS.CLOSE_TICKET_SHARE:
            return {
                ...state,
                openTicketShare: false,
            }
        case ACTIONS.OPEN_TICKET_STATE_CHANGE:
            return {
                ...state,
                selectedTicket: action.payload.ticket,
                openTicketChangeSate: true
            }
        case ACTIONS.CLOSE_TICKET_STATE_CHANGE:
            return {
                ...state,
                openTicketChangeSate: false,
            }
        case ACTIONS.OPEN_TICKET_CHAIN_ADD:
            return {
                ...state,
                openTicketChainAdd: true
            }
        case ACTIONS.CLOSE_TICKET_CHAIN_ADD:
            return {
                ...state,
                openTicketChainAdd: false,
            }
        case ACTIONS.OPEN_TICKET_CHAIN_DELETE:
            return {
                ...state,
                selectedTicketChain: action.payload.ticketChain,
                openTicketChainDelete: true
            }
        case ACTIONS.CLOSE_TICKET_CHAIN_DELETE:
            return {
                ...state,
                openTicketChainDelete: false,
            }
        default:
            return state
    }
}

const ProjectTickets = () =>{

    const [project] = useContext(ProjectContext)
    const [account] = useContext(AccountContext)
    const [ticketChains, setTicketChains] = useState([])
    const [subCompanies, setSubCompanies] = useState({});

    const [states, setStates] =  useState([])
    const [filter, setFilter] = useState('all');
    const [expandedAccordion, setExpandedAccordion] = useState('panel_0');
    const [state, dispatch] = useReducer(reducer, {
        openTicketInfo: false,
        openTicketEdit: false,
        openTicketAdd: false,
        openTicketDelete: false,
        openTicketShare: false,
        openTicketChangeSate: false,
        openTicketChainAdd: false,
        openTicketChainDelete: false,
        selectedTicket: null,
        selectedTicketChain: {},
    })

    const handleChangeFilter = (event, newFilter) => {
        if(newFilter != null){
            setFilter(newFilter);
        }
    };

    const handleChangeAccordion = (panel) => (event, isExpanded) => {
        setExpandedAccordion(isExpanded ? panel : false);
    };

    const getTicketChains = useCallback(async (data) => {
        const res = await fetch('/api/project/' + project.id + '/ticketchains', {
            method: 'GET',
        })
        if (res.ok) {
            const data = await res.json()
            setTicketChains(data)
            // console.log(data)
        }
    }, [project.id])

    const getSubCompanies = useCallback(async (data) => {
        const res = await fetch('/api/project/' + project.id + '/subcompanies?supervisor=1', {
            method: 'GET',
        })
        if (res.ok) {
            const data = await res.json()
            setSubCompanies(data)
        }
    }, [project.id])

    const callback_states = (c) =>{
        setStates(c)
    }

    useEffect(()=> {
        getTicketChains();
        getSubCompanies()
        getConstants('project_ticket_states', callback_states);
    }, [getTicketChains, getSubCompanies])

    return(
        <Box>
            {(account.is_admin || account.is_manager) ?
                <Box>
                    <Stack spacing={3}>
                        <Stack
                            direction='row'
                            alignItems='center'
                            spacing={2}
                        >
                            <ToggleButtonGroup
                                color="primary"
                                value={filter}
                                onChange={handleChangeFilter}
                                size="small"
                                exclusive
                                fullWidth
                            >
                                <ToggleButton value={'all'}>Alle</ToggleButton>
                                <ToggleButton value={'get'}>Erhaltene</ToggleButton>
                                <ToggleButton value={'post'}>Vergebene</ToggleButton>
                            </ToggleButtonGroup>
                            <Button
                                variant="text"
                                startIcon={<Add/>}
                                color="primary"
                                sx={{width: 250}}
                                onClick={() => dispatch({ type: ACTIONS.OPEN_TICKET_CHAIN_ADD })}
                            >
                                Neue Ticketkette
                            </Button>
                        </Stack>
                        <CustomPaper
                            title="Ticketketten"
                        >
                            {handleTicketFilter(filter, ticketChains).length !== 0 ?
                                handleTicketFilter(filter, ticketChains).map((ticketchain, idx) =>{
                                    let panel_name = 'panel_' + idx
                                    return(
                                        <TicketChains
                                            key={'ticketchain-'+ idx}
                                            idx={idx}
                                            ticketchain={ticketchain}
                                            dispatch={dispatch}
                                            panel_name={panel_name}
                                            expandedAccordion={expandedAccordion}
                                            handleChangeAccordion={handleChangeAccordion}
                                        />
                                    )
                                })
                            :
                                <NoData text={handleTicketFilterInfo(filter)} />
                            }
                        </CustomPaper>
                    </Stack>
                    <TicketDialog
                        open={state.openTicketInfo}
                        onClose={() => dispatch({ type: ACTIONS.CLOSE_TICKET_INFO })}
                        ticket={state.selectedTicket}
                    />
                    <TicketEditDialog
                        open={state.openTicketEdit}
                        onClose={() => dispatch({ type: ACTIONS.CLOSE_TICKET_EDIT})}
                        ticket={state.selectedTicket}
                        project_id={project.id}
                        getTicketChains={getTicketChains}
                        subCompanies={subCompanies}
                    />
                    <TicketAddDialog
                        open={state.openTicketAdd}
                        onClose={() => dispatch({ type: ACTIONS.CLOSE_TICKET_ADD})}
                        ticketChain={state.selectedTicketChain}
                        project_id={project.id}
                        getTicketChains={getTicketChains}
                        subCompanies={subCompanies}
                    />
                    <TicketDeleteDialog
                        open={state.openTicketDelete}
                        onClose={() => dispatch({ type: ACTIONS.CLOSE_TICKET_DELETE})}
                        ticket={state.selectedTicket}
                        project_id={project.id}
                        getTicketChains={getTicketChains}
                    />
                    <TicketChangeStateDialog
                        open={state.openTicketChangeSate}
                        onClose={() => dispatch({ type: ACTIONS.CLOSE_TICKET_STATE_CHANGE})}
                        ticket={state.selectedTicket}
                        states={states}
                        project_id={project.id}
                        getTicketChains={getTicketChains}
                    />
                    <TicketChainAddDialog
                        open={state.openTicketChainAdd}
                        onClose={() => dispatch({ type: ACTIONS.CLOSE_TICKET_CHAIN_ADD})}
                        project_id={project.id}
                        getTicketChains={getTicketChains}
                        subCompanies={subCompanies}
                    />
                    <TicketChainDeleteDialog
                        open={state.openTicketChainDelete}
                        onClose={() => dispatch({ type: ACTIONS.CLOSE_TICKET_CHAIN_DELETE})}
                        ticketChain={state.selectedTicketChain}
                        project_id={project.id}
                        getTicketChains={getTicketChains}
                    />
                    <TicketShareDialog
                        open={state.openTicketShare}
                        onClose={() => dispatch({ type: ACTIONS.CLOSE_TICKET_SHARE})}
                        ticket={state.selectedTicket}
                        project_id={project.id}
                        getTicketChains={getTicketChains}
                    />
                </Box>
            :
                <>
                    <ProjectTicketsLimited
                        ticketChains={ticketChains}
                        dispatch={dispatch}
                        project_id={project.id}

                    />
                    <TicketDialog
                        open={state.openTicketInfo}
                        onClose={() => dispatch({ type: ACTIONS.CLOSE_TICKET_INFO })}
                        ticket={state.selectedTicket}
                    />
                </>

            }
        </Box>
    )
}

export default ProjectTickets