import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';

import {
    Box,
    Stack,
    Button,
    Dialog,
    DialogTitle,
    DialogContent,
    DialogContentText,
    DialogActions,
    Typography,
    FormGroup,
    FormControlLabel,
    Switch,
    TextField,
    InputAdornment,
    Divider,
} from '@mui/material';

import { getCookie } from '../../../../../utils/getCookie';
import useStyledSnackbar from '../../../../../hooks/useStyledSnackbar';

import ControlledTextField from '../../../../Widgets/ControlledInputs/ControlledTextField';
import MaterialInfoStack from './MaterialInfoStack';


const UsedMaterialDialog = (props) =>{

    const { open, onClose, materials, setSelectedMaterials, sub_project_id, project_id, getSubProjectMaterial, getValues } = props

    const { enqueueSnackbar } =  useStyledSnackbar()
    const [materials_, setMaterials_] = useState([]);
    const { handleSubmit, control, reset, formState: { errors, isDirty, isValid }} = useForm({
        mode: 'onChange',
        defaultValues: {
            annotation: '',
        }
    })

    const [correction, setCorrection] = useState(false);
    const handleCorrection = (event) => {
        setCorrection(event.target.checked);
    };

    const handleOnClose = () =>{
        onClose()
        reset()
        setCorrection(false)
    }

    const handleValueChange = (e, idx, key) => {
        let data_ = [...materials]
        data_[idx][key] = e.target.value
        setMaterials_(data_);
    };

    const handleUsedTx = async(data) =>{

        const json = JSON.stringify({
            ...data,
            project_materials: materials_,
            correction: correction
        })
		const res = await fetch('/api/project/' + project_id  + '/sub-project/'+ sub_project_id +'/material/tx/used', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'X-CSRFToken': getCookie('csrftoken')
            },
            body: json
        })
        const _data = await res.json()
        if(res.ok){
            handleOnClose()
            setSelectedMaterials([])
            getValues()
            getSubProjectMaterial()
            enqueueSnackbar(`Die Transaktion wurde erfolgreich durchgefürht!`, {
                variant: 'success'
            })
        } else {
            Object.entries(_data).forEach(([field, message]) => {
                enqueueSnackbar(`${field}: ${message} `, {
                    variant: 'error'
                })
            })
        }
    }

    useEffect(()=>{
        let m_ = []
        materials.forEach(m => {
            m.debit_value =  ''
            m.debit_price =  ''
            m_.push(m)
        });
        setMaterials_(m_)
    },[materials])

    return(
        <Dialog
            open={open}
            onClose={handleOnClose}
            fullWidth
            maxWidth="laptop"
        >
            <DialogTitle>
                Materialverbrauch aufschreiben
            </DialogTitle>
            <DialogContent>
                <DialogContentText sx={{mb:2, width: 900}}>
                    Hier wird das Material aufgeschrieben bzw. verbucht, was für den Projektauftrag verbraucht wurde.
                </DialogContentText>
                <Stack
                    spacing={1}
                    component="form"
                    id='booking-form'
                    onSubmit={handleSubmit(handleUsedTx)}
                    sx={{ mt:2, mb: 2 }}
                >
                    <Box sx={{ height: 480, overflow: 'auto'}}>
                        {materials.map((material, idx)=>{
                            return(
                                <Box key={idx}>
                                    <Stack
                                        key={idx}
                                        direction={'row'}
                                        justifyContent="center"
                                        alignItems='center'
                                        sx={{p: 1, m: 0}}
                                    >
                                        <MaterialInfoStack material={material}/>
                                        <TextField
                                            placeholder="0"
                                            type="number"
                                            label="Menge"
                                            size="small"
                                            InputProps={{
                                                endAdornment: <InputAdornment position="end">{material.unit?.split('/')[1]}</InputAdornment>,
                                            }}
                                            sx={{ width: 200}}
                                            value={material.debit_value}
                                            onChange={(e) => handleValueChange(e, idx, 'debit_value')}
                                        />
                                    </Stack>
                                    {materials.length > idx + 1 && <Divider flexItem/>}
                                </Box>
                            )
                        })}
                    </Box>
                    <FormGroup>
                        <FormControlLabel control={
                            <Switch
                                checked={correction}
                                onChange={handleCorrection}
                            />
                        }
                        label="Korrektur" />
                    </FormGroup>
                    <ControlledTextField
                        control={control}
                        errors={errors}
                        name="annotation"
                        label="Anmerkung zum Materialverbrauch"
                        rules={{required: true}}
                        rows={3}
                        multiline
                    />
                </Stack>
                <Box>
                    <Typography variant="text_s">{materials.length} Artikel ausgewählt</Typography>
                </Box>
            </DialogContent>
            <DialogActions>
                <Button
                    onClick={handleOnClose}
                    color="secondary"
                >
                    Abbrechen
                </Button>
                <Button
                    type="submit"
                    form="booking-form"
                    color="primary"
                    disabled={!isDirty || !isValid}
                >
                    {'Material aufschreiben'}
                </Button>
            </DialogActions>
        </Dialog>
    )
}

export default UsedMaterialDialog