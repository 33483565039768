import { useContext} from "react"
import ProjectContext from '../../../../context/ProjectContext'

import { Stack } from "@mui/material";

import NoteBoard from "./NoteBoard";

const ProjectNotes = () => {

    const [project, projectMember] = useContext(ProjectContext)

    return (
        <Stack
            sx={{ flex : 1 }}
            spacing={3}
        >
            <NoteBoard
                project={project}
                disabled={!projectMember.is_admin && !projectMember.is_manager}
            />
        </Stack>
    );
}

export default ProjectNotes
