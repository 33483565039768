import { useForm } from "react-hook-form";

import { Stack, Button, Typography } from '@mui/material';

import { getCookie } from "../../../../../utils/getCookie";

import useStyledSnackbar from '../../../../../hooks/useStyledSnackbar';
import ControlledTextField from '../../../../Widgets/ControlledInputs/ControlledTextField';


const AddManufacturer = ({ getMaterialManufacturers }) => {

    const { enqueueSnackbar } = useStyledSnackbar()
    const { control, handleSubmit, reset , formState: { errors, isDirty, isValid } } = useForm({
        mode: 'onChange',
        defaultValues:{
            name: '',
        }
    });

    const handleAdd = async(data) =>{
        const json = JSON.stringify(data)
		const res = await fetch('/api/company/materials/manufacturer', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'X-CSRFToken': getCookie('csrftoken')
            },
            body: json
        })
        const res_data = await res.json()
        if(res.ok){
            reset()
            getMaterialManufacturers()
            enqueueSnackbar('Der Hersteller wurde erfolgreich hinzugefügt!', {
                variant: 'success'
            })
        } else{
            console.log(res_data)
            enqueueSnackbar('Es ist ein Fehler aufgetreten', {
                variant: 'error'
            })
        }
    }

    return(
        <Stack
            component='form'
            id="form-handleAdd"
            onSubmit={handleSubmit(handleAdd)}
            spacing={2}
            width="100%"
        >
            <Typography variant="body1">Hier kannst eine neuen Hersteller anlegen</Typography>
            <ControlledTextField
                name="name"
                label="Name des neuen Herstellers"
                control={control}
                rules={{ required: true }}
                errors={errors}
            />
            <Button
                color="primary"
                form="form-handleAdd"
                type="submit"
                variant="outlined"
                disabled={!isValid || !isDirty}
            >
                Hinzufügen
            </Button>
        </Stack>

    )
}

export default AddManufacturer