import { ToggleButtonGroup, ToggleButton, Chip } from '@mui/material'


const CustomTabs = ({ value, handleChange, tabs, fullWidth=true, buttonSx={} }) => {
    return (
        <ToggleButtonGroup
            color="primary"
            value={value}
            onChange={(e, v) => {
                if (v === null) return
                handleChange(e, v)
            }}
            size="small"
            exclusive
            fullWidth={fullWidth}
        >
            {tabs.map(t =>
                <ToggleButton
                    key={t.index}
                    value={t.index}
                    sx={[
                        { display: 'flex', flexDirection: 'row', gap: 1 },
                        buttonSx
                    ]}
                >
                    {t.icon}
                    <span
                        style={{
                            textOverflow: 'ellipsis',
                            whiteSpace: 'nowrap',
                            overflow: 'hidden',
                            lineHeight: '1.2rem'
                        }}
                    >
                        {t.label}
                    </span>
                    {t.badgeCount > 0 && <Chip color="primary" label={t.badgeCount} />}
                </ToggleButton>
            )}
        </ToggleButtonGroup>
    )
}

export default CustomTabs
