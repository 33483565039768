import { useMemo } from 'react';

import {
    Avatar,
    Checkbox,
    Stack,
    IconButton,
    Typography,
    Box,
} from '@mui/material'

import { Delete } from '@mui/icons-material';
import { getCookie } from '../../../utils/getCookie'
import { formatTimestamp } from '../../../utils/date_functions';


const Todo = ({ id, companyMember, done, created, deadline, description, getTodos, editable, sub_project, projectTodo=false }) => {
    const updateTodo = async () => {
        const body = {
            id: id,
            done: !done
        }

        const res = await fetch('/api/company/todos/' + id, {
            method: 'PATCH',
            headers: {
                'Content-Type': 'application/json',
                'X-CSRFToken': getCookie('csrftoken')
            },
            body: JSON.stringify(body),
        })

        if (res.ok) {
            getTodos()
        }
    }

    const deleteTodo = async () => {
        const res = await fetch('/api/company/todos/' + id, {
            method: 'DELETE',
            headers: {
                'X-CSRFToken': getCookie('csrftoken')
            }
        })

        if (res.ok) {
            getTodos()
        }
    }

    const deadlineColor = useMemo(() => {
        const deadlineDate = new Date(deadline)
        const todayDate = new Date()

        return (
            (done && 'inherit') ||
            ((deadlineDate - todayDate) < 0 && 'error.main') ||
            ((deadlineDate - todayDate) / 3600000 <= 12 && 'warning.main') ||
            'inherit'
        )
    }, [deadline, done])

    const opacity = done ? 0.7 : 1

    return (
        <Stack
            direction="row"
            justifyContent="center"
            sx={{
                p: 2,
                justifyContent: 'space-between',
                '*': {
                    textDecoration: done ? 'line-through': 'none'
                },
                transition: '250ms',
                borderRadius: '0.8rem',
                boxShadow: '0 0.1rem 0.3rem rgba(0, 0, 0, 0.12),  0 1px 2px rgba(0, 0, 0, 0.24)',
            }}
        >
            <Stack
                direction='row'
                alignItems='flex-start'
                gap={2}
                sx={{
                    opacity: opacity,
                }}
            >
                <Stack
                    justifyContent="center"
                    alignItems="center"
                    gap={1}
                    sx={{pt:1}}
                >
                    <Avatar
                        alt=''
                        src={companyMember.avatar}
                        sx={{ height: 55, width: 55}}
                    />
                    <Box sx={{ height: 40, width: 40}}>
                        <Checkbox
                            edge="end"
                            onChange={updateTodo}
                            checked={done}
                            color="primary"
                        />
                    </Box>
                    <Box sx={{ height: 40, width: 40}}>
                    {editable &&
                        <IconButton onClick={deleteTodo} color="error">
                            <Delete />
                        </IconButton>
                    }
                    </Box>
                </Stack>
                <Stack
                    height="100%"
                    justifyContent="space-between"
                    alignItems="space-between"
                >
                    <Typography gutterBottom variant='text_m' sx={{ mr: 0, mb: 2, whiteSpace: 'pre-wrap', wordBreak: 'break-all' }}>
                        {description}
                    </Typography>
                    <Stack>
                        {sub_project &&
                            <Typography variant='caption_m' sx={{ color: 'primary.main' }}>
                                {`Projektauftrag - ${sub_project}`}
                            </Typography>
                        }
                        {deadline &&
                            <Typography
                                variant='text_s'
                                sx={{
                                    color: 'text.primary',
                                    '> span': {
                                        color: deadlineColor
                                    },
                                    fontWeight: '600'
                                }}
                            >
                                {'Deadline: '}
                                <span>{formatTimestamp(deadline)}</span>
                            </Typography>
                        }
                        {projectTodo &&
                            <Typography variant='text_s' sx={{ color: 'text.primary' }}>
                                {companyMember.company_name}
                            </Typography>
                        }
                        <Typography variant='text_s' sx={{ color: 'text.primary' }}>
                            {companyMember.firstname + ' ' + companyMember.lastname}
                            {' - '}
                            {formatTimestamp(created)}
                        </Typography>
                    </Stack>
                </Stack>
            </Stack>
        </Stack>
    )
}

export default Todo
