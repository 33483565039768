import { Dialog, DialogTitle, DialogContent, DialogActions, Button, Typography } from "@mui/material"

const NotificationDialog = ({ handleClose, open, title, text, buttonText }) => {
    return (
        <Dialog
            open={open}
            onClose={handleClose}
        >
            <DialogTitle id="alert-dialog-title">
                {title}
            </DialogTitle>
            <DialogContent>
                <Typography variant="text_m">
                    {text}
                </Typography>
            </DialogContent>
            <DialogActions>
                <Button onClick={handleClose} autoFocus>
                    {buttonText}
                </Button>
            </DialogActions>
        </Dialog>
    )
}

export default NotificationDialog