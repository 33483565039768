/*
    All texts or explanations
*/

export const projectAgreementText = `
    Hier kannst du mit externen Projektmitgliedern Vereinbarungen treffen.
    Achte darauf, dass dein Handshake Verbindlich ist.
    Formuliere zunächst dein Anliegen ohne Handshake an deinem externen Projektpartner.
    Dein Gegenüber wird sich dein Aliegen durchlesen und gegebenfalls abändern.
    Wenn du damit einverstanden bist, kannst du deinen Handshake geben.
    Sobald eine Vertragspartei einen Handshake gegeben hat kann der Inhalt nicht mehr geändert werden und wird mit einem Zeitstempel fixiert.
    Also sei dir immer Bewusst: „Ein Wort ist ein Wort!“.
`

export const projectBlackboardInfoText = `
    Bitte beachte das deine Nachricht alle Projektbeteiligten sehen können!
`

export const companyBlackboardInfoText = `
    Bitte beachte das deine Nachricht alle Mitarbeiter sehen können!
`