import theme from "components/theme"
import CustomPage from "components/Widgets/CustomPage"
import CustomPaper from "components/Widgets/CustomPaper"
import ProjectHeader from "components/Widgets/ProjectHeader"
import { useEffect, useState } from "react"

import img_archiver_exe from 'assets/img/archiver-exe.png'
import img_archiver_project_selection from 'assets/img/archiver-project-selection.png'
import img_archiver_status_generating from 'assets/img/archiver-status-generating.png'
import img_archiver_status_done from 'assets/img/archiver-status-done.png'
import img_archiver_files from 'assets/img/archiver-files.png'
import img_archiver_new from 'assets/img/archiver-new.png'

import styles from './ProjectDocumentation.module.scss'
import { Typography } from "@mui/material"
import { Stack } from "@mui/system"


const ProjectDocumentation = () => {

    const [ downloadUrl, setDownloadUrl ] = useState('')

    useEffect(() => {
        (async () => {
            const res = await fetch('/apk/download-archiver', {
                method: 'GET'
            })
            const json = await res.json()
            console.log(json)
            setDownloadUrl(json.url)
        })()
    }, [])

    return (
        <CustomPaper
            title='Anleitung zur Projektarchivierung'
        >
            <Stack
                direction='column'
                gap={3}
                /* sx={{ maxflexBasis: 700 }} */
            >
                <Typography>
                    Sie haben die Möglichkeit, eine Projektdokumentation zu erzeugen, die Sie lokal auf Ihrem Rechner
                    speichern können.
                    Laden Sie dazu zunächst das Programm über den folgenden Link herunter:
                </Typography>

                <Stack direction='row' gap={3}>
                    <Typography variant='value_highlight' sx={{ padding: 2, flexBasis: 120, flexShrink: 0, borderRight: '1px solid ' + theme.palette.text.disabled }}>
                        1.
                    </Typography>
                    <a
                        style={{
                            color: theme.palette.primary.main,
                            textDecoration: 'underline'
                        }}
                        href={downloadUrl}
                        download={downloadUrl.split('/').at(-1)}
                    >
                        Hier herunterladen
                    </a>
                </Stack>
                <Stack direction='row' justifyContent='space-between' gap={3}>
                    <Typography variant='value_highlight' sx={{ padding: 2, flexBasis: 120, flexShrink: 0, borderRight: '1px solid ' + theme.palette.text.disabled }}>
                        2.
                    </Typography>
                    <img className={styles.img} src={img_archiver_exe} alt="" />
                    <Typography>
                        Dann kann die heruntergeladene ZIP-Datei in einen beliebigen Ordner entpackt und die exe-Datei gestartet werden.
                    </Typography>
                </Stack>
                <Stack direction='row' gap={3}>
                    <Typography variant='value_highlight' sx={{ padding: 2, flexBasis: 120, flexShrink: 0, borderRight: '1px solid ' + theme.palette.text.disabled }}>
                        3.
                    </Typography>
                    <img className={styles.img} src={img_archiver_project_selection} alt="" />
                    <Typography>
                        Nachdem Sie sich angemeldet haben, können Sie ein Projekt in der Drop-Down-Liste auswählen.
                        Um eine neue Projektdokumentation zu erstellen, klicken Sie auf &bdquo;Neu generieren&ldquo;.
                    </Typography>
                </Stack>
                <Stack direction='row' gap={3}>
                    <Typography variant='value_highlight' sx={{ padding: 2, flexBasis: 120, flexShrink: 0, borderRight: '1px solid ' + theme.palette.text.disabled }}>
                        4.
                    </Typography>
                    <img className={styles.img} src={img_archiver_new} alt="" />
                    <Typography>
                        Hier können Sie auswählen, welche Informationen die Dokumentation enthalten soll.
                    </Typography>
                </Stack>
                <Stack direction='row' gap={3}>
                    <Typography variant='value_highlight' sx={{ padding: 2, flexBasis: 120, flexShrink: 0, borderRight: '1px solid ' + theme.palette.text.disabled }}>
                        5.
                    </Typography>
                    <img className={styles.img} src={img_archiver_status_generating} alt="" />
                    <Typography>
                        Wenn Sie den Archivierungsprozess gestartet haben, wird die Dokumentation auf unseren Servern generiert.
                        Je nachdem, wie viele Informationen hierfür geladen werden müssen, kann dies etwas länger dauern.
                        Das Programm kann geschlossen werden, ohne dass dieser Prozess gestört wird.
                        Um den Status zu prüfen, klicken Sie den &bdquo;Aktualisieren&ldquo;-Button.
                    </Typography>
                </Stack>
                <Stack direction='row' gap={3}>
                    <Typography variant='value_highlight' sx={{ padding: 2, flexBasis: 120, flexShrink: 0, borderRight: '1px solid ' + theme.palette.text.disabled }}>
                        6.
                    </Typography>
                    <img className={styles.img} src={img_archiver_status_done} alt="" />
                    <Typography>
                        Wenn die Projektdokumentation fertig erstellt wurde, haben Sie die Möglichkeit, diese auf
                        Ihrem Rechner zu speichern. Wählen Sie dazu am besten einen leeren Ordner als Speicherort aus und klicken
                        Sie auf Speichern.
                    </Typography>
                </Stack>
                <Stack direction='row' gap={3}>
                    <Typography variant='value_highlight' sx={{ padding: 2, flexBasis: 120, flexShrink: 0, borderRight: '1px solid ' + theme.palette.text.disabled }}>
                        7.
                    </Typography>
                    <img className={styles.img} src={img_archiver_files} alt="" />
                    <Typography>
                        Wenn der Speicherprozess durchgelaufen ist, befindet sich in Ihrem gewählten Ordner die Datei &bdquo;Dokumentation.html&ldquo;.
                        Diese Datei können Sie in einem Browser öffnen, um die Dokumentation einzusehen. Alle Dateien, die darin
                        eingebunden sind, befinden sich in dem &bdquo;files&ldquo; Ordner. Die Daten liegen jetzt vollständig bei
                        Ihnen und sind nicht mehr abhängig von unseren Servern.
                    </Typography>
                </Stack>
            </Stack>
        </CustomPaper>
    )
}

export default ProjectDocumentation
