import { Typography, Stack, IconButton, Accordion, AccordionSummary, AccordionDetails } from '@mui/material';
import { ArrowForwardIosSharp, Add, Delete } from '@mui/icons-material';

import { ACTIONS } from '../TicketHelpers'
import noImage from '../../../../../assets/svgs/image_upload.svg'
import { handleChipColorVariant } from "../../../../../utils/helper";
import TicketCard from "../TicketCard";

export const TicketChains = (props) => {

    const { idx, ticketchain, dispatch, panel_name, expandedAccordion, handleChangeAccordion } = props

    return(
        <Accordion
            expanded={expandedAccordion === panel_name}
            onChange={handleChangeAccordion(panel_name)}
        >
            <AccordionSummary
                expandIcon={<ArrowForwardIosSharp sx={{ fontSize: '1rem' }}/>}
            >
                <Stack
                    direction='row'
                    justifyContent='space-between'
                    alignItems='center'
                    width="100%"
                >
                    <Stack
                        direction='row'
                        alignItems='center'
                        spacing={4}
                    >
                        <Stack sx={{width: 300}}>
                            <Typography
                                variant="subtitle_m"
                            >
                                {`${ticketchain.name}`}
                            </Typography>
                            <Typography
                                variant="caption_m"
                            >
                                {`Ticketkette #${idx + 1}`}
                            </Typography>
                        </Stack>
                        <Stack>
                            <Typography
                                variant="text_s"
                                sx={{
                                    color: 'text.primary',
                                    width: 300,
                                    textOverflow: 'ellipsis'
                                }}
                            >
                                {`Ersteller`}
                            </Typography>
                            <Typography
                                variant="subtitle_s"
                                sx={{
                                    color: 'text.primary',
                                }}
                            >
                                {`${ticketchain.creator?.name}`}
                            </Typography>
                        </Stack>
                        <Stack>
                            <Typography
                                variant="text_s"
                                sx={{
                                    color: 'text.primary',
                                }}
                            >
                                {`Projektauftrag`}
                            </Typography>
                            <Typography
                                variant="subtitle_s"
                                sx={{
                                    color: 'text.primary',
                                }}
                            >
                                {`${ticketchain.sub_project? ticketchain.sub_project.title: 'ohne'}`}
                            </Typography>
                        </Stack>
                    </Stack>
                    <Stack>
                        <Typography
                            variant="text_s"
                            sx={{
                                color: 'text.primary',
                            }}
                        >
                            {`Abgeschlossen`}
                        </Typography>
                        <Typography
                            variant="subtitle_s"
                            sx={{
                                color: 'text.primary',
                            }}
                        >
                            {`${ticketchain.completed} / ${ticketchain.tickets.length}`}
                        </Typography>
                    </Stack>
                </Stack>
            </AccordionSummary>
            <AccordionDetails>
                <Stack gap={3}>
                    <Stack>
                        <Typography
                            variant="subtitle_s"
                            sx={{
                                color: 'text.primary',
                            }}
                        >
                            {`Beschreibung`}
                        </Typography>
                        <Typography
                            variant="text_m"
                            sx={{
                                color: 'text.primary',
                            }}
                        >
                            {ticketchain.description}
                        </Typography>
                    </Stack>
                    <Stack
                        direction='row'
                        spacing={3}
                    >
                        {ticketchain.tickets.map((ticket, idx) => {
                            let cv = handleChipColorVariant(ticket.status);
                            let color = cv[0]
                            let variant = cv[1]
                            ticket.image = ticket.image ? ticket.image : noImage
                            return(
                                <TicketCard
                                    key={idx}
                                    idx={idx}
                                    ticket={ticket}
                                    color={color}
                                    variant={variant}
                                    dispatch={dispatch}
                                />
                            )
                        })}
                        {ticketchain.external ? <></> :
                            <Stack
                                justifyContent='flex-end'
                                spacing={1}
                            >
                                <IconButton
                                    size="small"
                                    onClick={() => dispatch({ type: ACTIONS.OPEN_TICKET_ADD, payload: { ticketChain: ticketchain }})}
                                >
                                    <Add fontSize="small" color="primary"/>
                                </IconButton>
                                <IconButton
                                    size="small"
                                    onClick={() => dispatch({ type: ACTIONS.OPEN_TICKET_CHAIN_DELETE, payload: { ticketChain: ticketchain }})}
                                >
                                    <Delete fontSize="small" color="error"/>
                                </IconButton>
                            </Stack>
                        }
                    </Stack>
                </Stack>
            </AccordionDetails>
        </Accordion>
    )
}

export default TicketChains