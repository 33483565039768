import { Grid, } from "@mui/material";

import { PALETTE } from "../../../../theme/index";

import StaticMultiStackBarchart from "./StaticMultiStackBarchart";
import StaticSingleBarchart from "./StaticSingleBarchart";
import SubProjectInfoCard from "./SubProjectInfoCard";
import FinanceCard from "./FinanceCard";
import StaticDoubleStackBarchart from "./StaticDoubleStackBarchart";


const ProjectStaticsContent = ({ projectStatic }) =>{

    const height = 175
    const barSize = 75
    const maxFactor = 1.2

    const currentWorkload = projectStatic.current_workload
    const maxWorkload  = projectStatic.max_workload
    const currentDriveRate = projectStatic.current_drive_rate_costs
    const currentWorkloadCosts = projectStatic.current_workload_costs

    const currentTask = projectStatic.current_task
    const max_task = projectStatic.max_task

    const currentMaterial = projectStatic.current_material
    const maxMaterial = projectStatic.max_material

    const projectValue = projectStatic.value
    const currentMarge = projectStatic.current_marge


    const data = {
        material: [
            {
                name: '',
                current: currentMaterial,
            },
        ],
        workload: [
            {
                name: '',
                current: currentWorkload,
            },
        ],
        workloadCosts: [
            {
                name: '',
                currentWorkloadCosts: currentWorkloadCosts - currentDriveRate,
                currentDriveRateCosts: currentDriveRate,
            },
        ],
        task: [
            {
                name: '',
                current: currentTask,
            },
        ],
        total: [
            {
                name: '',
                material: currentMaterial,
                workload: currentWorkloadCosts,
                task: currentTask,
            },
        ],
    }

    return(
        <Grid
            container
            spacing={3}
        >
            <Grid
                item
                desktop={7}
                labtop={7}
                tablet={12}
                mobile={12}
            >
                <StaticSingleBarchart
                    title="Materialkosten"
                    max={maxMaterial}
                    data={data.material}
                    height={height}
                    maxFactor={maxFactor}
                    fill={PALETTE.primary.dark}
                    unit="€"
                    barSize={barSize}
                />
            </Grid>
            <Grid
                item
                desktop={5}
                labtop={5}
                tablet={12}
                mobile={12}
            >
                <StaticSingleBarchart
                    title="Auftragskosten (verg.)"
                    max={max_task}
                    data={data.task}
                    height={height}
                    maxFactor={maxFactor}
                    fill={PALETTE.primary.light}
                    unit="€"
                    barSize={barSize}
                />
            </Grid>
            <Grid
                item
                desktop={5}
                labtop={5}
                tablet={12}
                mobile={12}
            >
                <StaticSingleBarchart
                    title="Arbeitstunden"
                    max={maxWorkload}
                    data={data.workload}
                    height={height}
                    maxFactor={maxFactor}
                    fill={PALETTE.primary.main}
                    unit="Std."
                    barSize={barSize}
                />
            </Grid>
            <Grid
                item
                desktop={7}
                labtop={7}
                tablet={12}
                mobile={12}
            >
                <StaticDoubleStackBarchart
                    title="Lohnkosten"
                    max={maxWorkload * projectStatic.wage_rate}
                    data={data.workloadCosts}
                    height={height}
                    maxFactor={maxFactor}
                    fill1={PALETTE.primary.main}
                    fill2={PALETTE.secondary.main}
                    unit="€"
                    barSize={barSize}
                />
            </Grid>
            <Grid
                item
                desktop={4}
                labtop={4}
                tablet={6}
                mobile={12}
            >
                <FinanceCard
                    currentMaterial={currentMaterial}
                    currentWorkloadCosts={currentWorkloadCosts}
                    currentTask={currentTask}
                    marge={currentMarge}
                    total={projectValue}
                />
            </Grid>
            <Grid
                item
                desktop={8}
                labtop={8}
                tablet={6}
                mobile={12}
            >
                <SubProjectInfoCard
                    projectStatic={projectStatic}
                />
            </Grid>
            <Grid
                item
                desktop={12}
                labtop={12}
                tablet={12}
                mobile={12}
            >
                <StaticMultiStackBarchart
                    title="Gesamtkosten"
                    max={projectValue}
                    data={data.total}
                    height={height}
                    maxFactor={maxFactor}
                    fill1={PALETTE.primary.dark}
                    fill2={PALETTE.primary.main}
                    fill3={PALETTE.primary.light}
                    unit="€"
                    barSize={barSize}
                />
            </Grid>
        </Grid>
    )
}

export default ProjectStaticsContent