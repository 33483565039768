import { Checkbox, Divider, List, ListItem, Stack, Typography } from '@mui/material'



const SelectList = ({ items, selectedItems, setSelectedItems, listItemRender, itemName, title, ...props }) => {
    const getIndex = (list, item) => {
        for (let i = 0; i < list.length; i++) {
            if (list[i].id === item.id) {
                return i
            }
        }
        return null
    }

    const handleChange = item => {
        setSelectedItems(old => {
            let newList = [...old]
            const index = getIndex(newList, item)
            if (index == null) {
                newList.push(item)
            } else {
                newList.splice(index, 1)
            }
            return newList
        })
    }

    const toggleSelectAll = () => setSelectedItems(old => old.length === items.length ? [] : [...items])

    return(
        <Stack 
            sx={{ 
                height: '100%', 
                width: '100%',
                flex: '1 1 0',
            }}
        >
            <Typography variant="title_paper">{title}</Typography>
            <List 
                sx={{ 
                    height: '100%',
                    minHeight: 400,
                    flexGrow: 1, 
                    pt: 2
                }}
            >
                <Stack sx={{ height: '100%' }}>
                    <Stack sx={{ flexGrow: '1', pb: 2 }}>
                        {items.map(i => listItemRender(i, {
                            secondaryAction: (
                                <Checkbox
                                    checked={getIndex(selectedItems, i) !== null}
                                    onChange={() => handleChange(i)}
                                />
                            )
                        }))}
                    </Stack>
                    <Divider />
                    <ListItem
                        secondaryAction={
                            <Checkbox
                                checked={selectedItems.length === items.length && items.length > 0}
                                indeterminate={selectedItems.length !== 0 && selectedItems.length !== items.length}
                                onChange={toggleSelectAll}
                            />
                        }
                        sx={{ p: 2 }}
                    >
                        <Typography variant='text_m'>{`${selectedItems.length}/${items.length} ${itemName} ausgewählt`}</Typography>
                    </ListItem>
                </Stack>
            </List>
        </Stack>
    )
}

export default SelectList