import { MenuItem } from "@mui/material"
import { roundTo } from 'round-to';

//  Helper functions
export const convertDate = (datestring) =>{
    let date = new Date(Date.parse(datestring))
    return date.toLocaleDateString('de-De')
}

export const convertTime = (datestring) =>{
    let time = new Date(datestring)
    return time.toLocaleTimeString()
}

export const getRandomInt = (min, max) => {
    let num = Math.random() * (max - min) + min
    return parseFloat(num.toFixed(2));
  }

export const buildSelectionMenuItems = (data) =>{
    let temp = []
    for(let i in data){
        temp.push(<MenuItem key={i} value={i}>{data[i]}</MenuItem>)
    }
    return temp
}

export const buildSelection = (data) =>{
    let temp = []
    for(let i in data){
        temp.push(<MenuItem key={data[i][0]} value={data[i][0]}>{data[i][1]}</MenuItem>)
    }
    return temp
}

export const get_only_companies_from_project_members = (projectMembers, company_id) =>{
    let temp = []
    for(let company in projectMembers){
        if (projectMembers[company].belonging){
            continue
        }
        temp.push(<MenuItem key={projectMembers[company].id} value={projectMembers[company].id}>{projectMembers[company].name}</MenuItem>)
    }
    return temp
}

export const autoHideDuration = ( statefunc, delay ) =>{
    let timer = setTimeout(() =>  statefunc(false), delay * 1000);
    return () => {
        clearTimeout(timer);
    };
}

export const handleChipColorVariant = (status) => {
    let color_str = 'default'
    let variant_str = 'outlined'
    switch(status){
        case 'Ungesehen':
            break;
        case 'Zur Kenntnis genommen':
            variant_str = 'filled'
            break;
        case 'Problem':
            color_str = 'error'
            variant_str = 'filled'
            break;
        case 'In Bearbeitung':
            color_str = 'primary'
            break;
        case 'Verspätung':
            color_str = 'warning'
            break;
        case 'Erledigt':
            color_str = 'primary'
            variant_str = 'filled'
            break;
        default:
            break
    }
    return [color_str, variant_str]
}

export const convertDictToValuesAndStringSelection = (dict) =>{
    let temp = []
    for (const [key, value] of Object.entries(dict)) {
        temp.push({
            value: key,
            label: value,
        })
    }
    return temp
}

export const customParseFloat = (value) => {
    if(value !== '') return parseFloat(value)
    return 0
}

export const customParseInt = (value) => {
    if(value !== '') return parseInt(value)
    return 0
}

export const formatNumber = (value, precision, suffix) =>{
    if(value === undefined || value === null || isNaN(value)){ value = 0 }
    return `${roundTo(value, precision).toLocaleString('de-DE',{minimumFractionDigits: precision})} ${suffix}`
}

export const getTitlebyGender = (gender) =>{
    switch(gender){
        case 'm':
            return 'Herr'
        case 'f':
            return 'Frau'
        case 'd':
            return ''
        default:
            return ''
    }
}

export const checkSelectionZero = (selection) =>{
    if(selection === "0"){
        return ''
    }
    return selection
}
