import React, {useState} from 'react'

import { getCookie } from '../../../../../utils/getCookie'

import { Button, Stack, Chip, Typography, Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions } from "@mui/material";
import { SupervisorAccount, Business, Lock, LockOpen } from '@mui/icons-material';

import ProjectCardWrapperSR from '../../../../Widgets/ProjectCards/ProjectCardWrapperSR';

import useStyledSnackbar from '../../../../../hooks/useStyledSnackbar'
import NotificationDialog from '../../../../Widgets/Dialogs/NotificationDialog'
import UnControlledSelect from '../../../../Widgets/UnControlledInputs/UnControlledSelect';
import { PALETTE } from 'components/theme';


const ProjectSearchCard = ({ project, handleReset}) =>{

    const [denied, setDenied] = useState(false)
    const [open, setOpen] = useState(false)
    const [selectedCompany, setSelectedCompany] = useState('')

    const { enqueueSnackbar } =  useStyledSnackbar()

    const handleJoinProject = async() =>{

        const json = JSON.stringify({
            project_id: project.id,
            supervisor_id: selectedCompany
        })

        const res = await fetch('/api/projects/search/requests', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'X-CSRFToken': getCookie('csrftoken')
            },
            body: json
        })

        if (res.ok) {
            const data = await res.json()
            // console.log(data)
            if(data.message === 'already_exists'){
                setDenied(true)
            }
            if(data.message === 'successfully_requested'){
                enqueueSnackbar('Es wurde erfolgreich eine Anfrage zum Beitritt erstellt!', {
                    variant: 'success'
                })
            }
            if(data.message === 'successfully_joined'){
                enqueueSnackbar('Du bist jetzt im Projekt!', {
                    variant: 'success'
                })
            }
            handleReset()
            setOpen(false)
        }
    }

    return(
        <ProjectCardWrapperSR
            project={project}
        >
            <Button
                onClick={()=> {project.official ?  setOpen(true) : handleJoinProject()}}
                size="large"
                variant={project.official === true ? "outlined" : "contained"}
                color="primary"
                disabled={project.already_in}
                fullWidth
            >
                    {project.official === true ? "Beitritt anfragen" : "Beitreten"}
            </Button>
            <Stack
                direction="row"
                justifyContent="flex-end"
                alignContent="center"
                spacing={1}
                sx={{p:1}}
            >
                {project.already_in ? <Chip label="Bist im Projekt" variant='filled' color="success"/> : <></>}
                <Stack
                    direction="row"
                    justifyContent="space-between"
                    alignItems="center"
                    spacing={1}
                >
                     {project.official ? <Lock fontSize="small" sx={{color: PALETTE.background.light20 }}/> : <LockOpen fontSize="small" sx={{color: PALETTE.background.light20 }}/>}
                </Stack>
                <Stack
                    direction="row"
                    justifyContent="space-between"
                    alignItems="center"
                    spacing={1}
                >
                    <SupervisorAccount sx={{color: PALETTE.background.light20 }}/>
                    <Typography
                        variant="value"
                        fontSize={18}
                    >
                        {project.all_members}
                    </Typography>

                </Stack>
                <Stack
                    direction="row"
                    justifyContent="space-between"
                    alignItems="center"
                    spacing={1}
                >
                    <Business sx={{color: PALETTE.background.light20 }}/>
                    <Typography
                        variant="value"
                        fontSize={18}
                    >
                        {project.all_companies}
                    </Typography>
                </Stack>
                <Dialog
                    open={open}
                    onClose={() => setOpen(false)}
                    maxWidth="tablet"
                >
                    <DialogTitle>
                        Bei welchem Unternehmen möchten Sie den Beitritt anfragen?
                    </DialogTitle>
                    <DialogContent>
                        <DialogContentText>
                            Sie sollten das Unternehmen wählen für das Sie auch im Projekt arbeiten werden.
                        </DialogContentText>
                        <UnControlledSelect
                            label="Unternehmen"
                            value={selectedCompany}
                            onChange={(e) => setSelectedCompany(e.target.value)}
                            items={project.companies_in_project}
                            sx={{mt:2}}
                        />
                    </DialogContent>
                    <DialogActions>
                        <Button
                            onClick={() => setOpen(false)}
                            color="secondary"
                        >
                            Abbrechen
                        </Button>
                        <Button
                            onClick={handleJoinProject}
                        >
                            Anfragen
                        </Button>
                    </DialogActions>
                </Dialog>
                <NotificationDialog
                    open={denied}
                    handleClose={()=> setDenied(false)}
                    title="Aufgepasst!"
                    text={`Sie sind bereits mit Ihrem Unternehmen im Projekt ${project.name} oder haben bereits eine Beitrittsanfrage gestellt..`}
                    buttonText="Schließen"
                />
            </Stack>
        </ProjectCardWrapperSR>
    )
}

export default ProjectSearchCard