import { Dialog, DialogTitle, DialogContent, DialogActions, Button, Typography } from '@mui/material'
import { useState } from 'react'

const ConfirmDialog = ({ title, renderButton, message, confirmButtonText = "Ok", cancelButtonText = "Abbrechen", onConfirm, onCancel = () => { } }) => {

	const [open, setOpen] = useState(false)

	const handleCancel = () => {
		setOpen(false)
		onCancel()
	}

	const handleConfirm = () => {
		setOpen(false)
		onConfirm()
	}

	return (
		<>
			{renderButton(() => setOpen(true))}
			<Dialog
				maxWidth="tablet"
				open={open}
				onClose={handleCancel}
			>
				<DialogTitle>{title}</DialogTitle>
				<DialogContent>
					<Typography variant='text_m'>{message}</Typography>
				</DialogContent>
				<DialogActions>
					<Button autoFocus onClick={handleCancel} sx={{ color: 'text.secondary' }}>
						{cancelButtonText}
					</Button>
					<Button onClick={handleConfirm} sx={{ color: 'error.main' }}>{confirmButtonText}</Button>
				</DialogActions>
			</Dialog>
		</>
	)
}

export default ConfirmDialog
