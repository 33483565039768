import { Stack, Divider, Typography} from '@mui/material'
import ProjectImageIconStack from './ProjectImageIconStack'

import './ProjectsCard.css'

import { formatDateString } from '../../../utils/date_functions'


const ProjectCardWrapper = ({project, children}) =>{

    return(
        <Stack
            className="project-card"
            direction='row'
            sx={{
                minWidth: 200,
                height: '100%',
            }}
        >
            <ProjectImageIconStack
                img={project.img}
                members={project.all_members}
                companies={project.all_companies}
                official={project.official}
            />
            <Divider orientation="vertical" flexItem/>
            <Stack
                padding={1.5}
                width="100%"
            >
                <Stack
                    direction="row"
                    justifyContent="space-between"
                >
                    <Stack>
                        <Typography variant="subtitle_s" color='secondary.main'>Projekt</Typography>
                        <Typography variant="subtitle_m">{project.name}</Typography>
                    </Stack>
                    <Typography variant="text_s">
                        {`${formatDateString(project.expected_to_start)} - ${formatDateString(project.expected_to_end)}`}
                    </Typography>
                </Stack>
                <Typography variant="text_s">
                        {`${project.address.street} ${project.address.house_number}, ${project.address.postal_code} ${project.address.city}`}
                </Typography>
                {children}
            </Stack>
        </Stack>
    )
}

export default ProjectCardWrapper