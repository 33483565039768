import { Stack, Typography, Fade } from "@mui/material"
import { PALETTE } from "components/theme"
import { FaTasks } from "react-icons/fa"

const AtCostHint = ({ text, size=45, height=180 }) => {
    return (
        <Fade in={true} timeout={500} style={{ transitionDelay: '250ms' }}>
            <Stack gap={0.5} sx={{ p: 2, height: height, justifyContent: 'center' }} alignItems='center'>
                    <FaTasks color={PALETTE.primary.main} size={size}/>
                    <Typography variant="text_m">{ text }</Typography>
            </Stack>
        </Fade>
    )
}

export default AtCostHint
