import { Box, Grid, Stack, Typography } from '@mui/material';

import CompanyInfoCard from './CompanyInfoCard';
import CustomPaper from '../../../../Widgets/CustomPaper';
import GroupsIcon from '@mui/icons-material/Groups';

const CompanyInfo = ({ company, company_members, jobTitles }) =>{

    return(
        <Stack
            direction="row"
            spacing={3}
            alignItems='flex-start'
        >
            <CustomPaper
                title="Stammdaten"
                width="100%"
                sx={{ 
                    flex : 1,
                }}
            >
                <Grid
                    container
                    spacing={3}
                >
                    <Grid item desktop={6} labtop={6} tablet={6} mobile={12}>
                        <CompanyInfoCard title="Unternehmen" subtitle={company.name} variant="text"/>
                    </Grid>
                    <Grid item desktop={6} labtop={6} tablet={6} mobile={12}>
                        <CompanyInfoCard title="Branche" subtitle={company.industry} variant="text"/>
                    </Grid>
                    <Grid item desktop={6} labtop={6} tablet={6} mobile={12}>
                        <CompanyInfoCard title="Telefon" subtitle={company.central_phone_number} variant="text"/>
                    </Grid>
                    <Grid item desktop={6} labtop={6} tablet={6} mobile={12}>
                        <CompanyInfoCard title="Adresse" subtitle={` ${company.address.street} ${company.address.house_number}, ${company.address.postal_code} ${company.address.city}`} variant="text"/>
                    </Grid>
                    <Grid item desktop={6} labtop={6} tablet={6} mobile={12}>
                        <CompanyInfoCard title="Version" subtitle={company.paid ? "Pro" : "Kostenlos"} variant="text"/>
                    </Grid>
                </Grid>
            </CustomPaper>
            <CustomPaper
                title="Unternehmensstärke"
                actionBar={<GroupsIcon color='primary'/>}
            >
                <Stack 
                    gap={3}
                >
                    {company.logo !== null &&
                        <Box
                            component={'img'}
                            src={company.logo}
                            sx={{
                                width: 300,
                                borderRadius: '8px',
                                justifyContent: 'center',
                                alignSelf: 'center',
                            }}
                        />
                    }
                    <Stack gap={0.5}>
                        {Object.entries(jobTitles).map(([key, value], idx) => {
                            const count = company_members.filter(x => x.job_title_key === key).length
                            if(count === 0) return null
                            return(
                                <Stack
                                    key={`job-title-item-${idx}`}
                                    direction="row"
                                    justifyContent="space-between"
                                    spacing={3}
                                    width="100%"
                                >
                                    <Typography variant="text_m">{value}</Typography>
                                    <Typography variant="value" color="primary.main">{count}</Typography>
                                </Stack>
                            )
                        })}
                    </Stack>
                </Stack>
            </CustomPaper>
        </Stack>
    )
}

export default CompanyInfo