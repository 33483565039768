import { useState, useContext, useCallback, useEffect } from 'react'

import { ListItemButton, ListItemText, ListItemIcon, Popover, Badge, Fab, Button, Dialog } from '@mui/material'
import NotificationsIcon from '@mui/icons-material/Notifications'
import CloseIcon from '@mui/icons-material/Close';

import { NotificationContext } from 'context/NotificationContext'
import useWebSocket from 'hooks/useWebSocket'

import NotificationsPanel from './NotificationsPanel'


const Notifications = () => {
    const [anchorEl, setAnchorEl] = useState(null)

    const open = Boolean(anchorEl)

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    }

    const handleClose = () => {
        setAnchorEl(null);
    }

    const [ notifications, setNotifications ] = useContext(NotificationContext)
    const [ wsOpen, setWsOpen ] = useState(false)

    useEffect(() => {
        if (!wsOpen) return
        const getNotifications = () => {
            sendJsonMessage({
                command: 'get_notifications',
            })
        }
        console.log('Notifications WS connected')
        getNotifications()
        const intv = setInterval(() => {
            getNotifications()
        }, 4000)

        return () => clearInterval(intv)
    }, [wsOpen])

    const onOpen = useCallback(() => {
        setWsOpen(true)
    }, [])

    const onClose = useCallback(() => {
        console.log('Notifications WS closed')
        setWsOpen(false)
    }, [])

    const onMessageJson = useCallback((e, message) => {
        // if (JSON.stringify(notifications) !== JSON.stringify(message.notifications)){
            // console.log('Notifications updated')
            setNotifications(message.notifications)
            // console.log(message)
        // }
    }, [])

    const {
        sendJsonMessage
    } = useWebSocket({
        path: '/notifications/ws',
        onOpen: onOpen,
        onClose: onClose,
        onMessageJson: onMessageJson,
        connectionLostMessage: 'Verbindung verloren. Du kannst momentan keine Benachrichtigungen erhalten...'
    })

    const totalNotificationCount = Object.entries(notifications).reduce((sum, n) => sum + n[1].length, 0)

    return (
        <>
            <Button
                variant='contained'
                color='secondary'
                endIcon={open ? <CloseIcon /> : <NotificationsIcon />}
                onClick={open ? handleClose : handleClick}
                disabled={totalNotificationCount === 0}
                sx={{
                    position: 'fixed',
                    zIndex: 9999,
                    top: 0,
                    left: '50%',
                    transform: 'translateX(-50%)',
                    borderTopLeftRadius: 0,
                    borderTopRightRadius: 0,
                }}
            >
                {totalNotificationCount === 0 ?
                    'Keine Benachrichtigungen'
                : totalNotificationCount === 1 ?
                    '1 Benachrichtigung'
                :
                    totalNotificationCount + ' Benachrichtigungen'
                }
            </Button>
            <Dialog
                open={open}
                onClose={handleClose}
            >
                <NotificationsPanel
                    notifications={notifications}
                    closePanel={handleClose}
                />
            </Dialog>
        </>
    )
}

export default Notifications
