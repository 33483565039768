import { useState, useEffect, useCallback } from "react";
import { useLocation } from 'react-router-dom';

import { Container, Stack, Box, Button, Typography, Divider, FormControlLabel, Switch, TextField, InputAdornment } from "@mui/material";
import { useForm } from "react-hook-form";

import useStyledSnackbar from "hooks/useStyledSnackbar";

import LogoFullWhite from "components/Widgets/Logos/LogoFullWhite";
import DragNDrop from "components/Widgets/DragNDrop";
import AccessDenied from "components/Widgets/AccessDenied";
import ControlledCheckbox from "components/Widgets/ControlledInputs/ControlledCheckbox";
import ControlledTextField from "components/Widgets/ControlledInputs/ControlledTextField";
import ControlledDatePicker from "components/Widgets/ControlledInputs/ControlledDatePicker";

import { PALETTE } from "components/theme";
import { getCookie } from "utils/getCookie";
import { addMonths } from "date-fns";
import NoData from "components/Widgets/NoData";
import { formatNumber } from "utils/helper";
import ConfirmDialog from "components/Widgets/ConfirmDialog";



const SupplierMaterialRequests = () => {

    const compact = false
    const location = useLocation()
    const { enqueueSnackbar } =  useStyledSnackbar()

    const [selectedSupplierRequest, setSelectedSupplierRequest] = useState(null)
    const [requestedMaterials, setRequestedMaterials] = useState([])
    const [key, setKey] = useState(null)
    const [file, setFile] = useState(null)
    const [isFilled, setIsFilled]  = useState(false)
    const [sum, setSum] = useState(0)

    const { control, handleSubmit, watch, formState: { errors } } = useForm({
        mode:"onChange",
        defaultValues:{
            order_id: '',
            confirmed: false,
            supplier_annotation: '',
            expired_on: addMonths(new Date(), 3)
        }
    });

    const handleSum = (arr) =>{
        let _sum = 0
        arr.forEach((rm)=>{
            if(rm.available){
                _sum += parseFloat(rm.value ? rm.value : 0) * parseFloat(rm.price ? rm.price : 0)
            }
        })
        setSum(_sum)
    }

    const handleAvailableChange = (idx, bool) => {
        let _data = [...requestedMaterials]
        _data[idx]['available'] = !bool
        setRequestedMaterials(_data);
        handleSum(_data)
    };

    const handleChange = (e, idx, key) => {
        let _data = [...requestedMaterials]
        _data[idx][key] = e.target.value
        setRequestedMaterials(_data);
        handleSum(_data)
    };

    const getSupplierRequest = useCallback(async(key) =>{
        const res = await fetch('/api/supplier/material/request/' + key, {
            method: 'GET'
        })
        const data = await res.json()
        if(res.ok){
            if(data.filled){
                setIsFilled(true)
            } else {
                setSelectedSupplierRequest(data)
                let transformed = []
                data.request_materials.forEach(rm => {
                    rm.annotation = rm.annotation !== null ? rm.annotation : ''
                    transformed.push(rm)
                });
                setRequestedMaterials(transformed)
            }
        }
    }, [])

    const _handleSubmit = async(data) =>{

        const json = JSON.stringify({
            ...selectedSupplierRequest,
            ...data,
            request_materials: requestedMaterials
        })

        const formData = new FormData()
        formData.append('file', file)
        formData.append('json', json)

		const res = await fetch('/api/supplier/material/request/' + key, {
            method: 'POST',
            headers: {
                'X-CSRFToken': getCookie('csrftoken')
            },
            body: formData
        })
        const _data = await res.json()
        if(res.ok){
            enqueueSnackbar(`Dein Angebote wurde erfolgreich verschickt!`, {
                variant: 'success'
            })
            getSupplierRequest(key)
        } else {
            Object.entries(_data).forEach(([field, message]) => {
                enqueueSnackbar(`${field}: ${message} `, {
                    variant: 'error'
                })
            })
        }
    }

    useEffect(()=>{
        const _key = new URLSearchParams(location.search).get('key')
        if(_key !== null){
            setKey(_key)
            getSupplierRequest(_key)
        }
    },[])

    return (
        <Box sx={{ height: '100vh' }}>
            <Stack
                flexDirection="row"
                justifyContent="center"
                alignItems="center"
                sx={{
                    backgroundColor: 'background.main',
                    p:2,
                    height: 100,
                }}
            >
                <Box sx={{ flexShrink: 0 }}>
                    <LogoFullWhite width={compact ? 300 : 500} height={60}/>
                </Box>
            </Stack>
            <Container
                maxWidth="desktop"
                sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    flex: 1,
                    height:  'calc(100vh - 100px)',
                }}
            >
                {!isFilled ?
                    <Box>
                        {key && selectedSupplierRequest?
                            <Stack
                                flexDirection="row"
                                component="form"
                                id="rm-form"
                                onSubmit={handleSubmit(_handleSubmit)}
                                gap={8}
                                sx={{
                                    p:2
                                }}
                            >
                                <Stack
                                    width="25%"
                                    gap={2}
                                >
                                    <Stack
                                        gap={2}
                                        sx={{ mt: 2 }}
                                    >
                                        <Typography variant="title_m">Materialanfrage</Typography>
                                        <Stack>
                                            <Typography variant="subtitle_s" color="primary">An</Typography>
                                            <Typography variant="text_m">{selectedSupplierRequest.supplier.name}</Typography>
                                            <Typography variant="text_m">{selectedSupplierRequest.supplier.contact_person}</Typography>
                                            <Typography variant="text_m">{`${selectedSupplierRequest.supplier.address.street} ${selectedSupplierRequest.supplier.address.house_number}${selectedSupplierRequest.supplier.address.add_on}`}</Typography>
                                            <Typography variant="text_m">{`${selectedSupplierRequest.supplier.address.postal_code} ${selectedSupplierRequest.supplier.address.city}`}</Typography>
                                        </Stack>
                                        <Divider light flexItem/>
                                        <Stack>
                                            <Typography variant="subtitle_s" color="secondary">Von</Typography>
                                            <Typography variant="text_m">{selectedSupplierRequest.creator.company_name}</Typography>
                                            <Typography variant="text_m">{`${selectedSupplierRequest.creator.gender_key !== 'm'? 'Frau' : 'Herr'} ${selectedSupplierRequest.creator.firstname} ${selectedSupplierRequest.creator.lastname}`}</Typography>
                                            <Typography variant="text_m">{`${selectedSupplierRequest.company_address.street} ${selectedSupplierRequest.company_address.house_number}${selectedSupplierRequest.company_address.add_on}`}</Typography>
                                            <Typography variant="text_m">{`${selectedSupplierRequest.company_address.postal_code} ${selectedSupplierRequest.company_address.city}`}</Typography>
                                        </Stack>
                                        <Typography variant="text_m">
                                            {`Hallo, ${selectedSupplierRequest.supplier.contact_person}, ich würde gerne Angebot für die nebenstehende Artikel bekommen. Bitte fülle das Formular aus. Zusätzlich kannst du gerne das Angebot als PDF anhängen. Vielen Dank!`}
                                        </Typography>
                                        <Stack>
                                            <Typography variant="text_m" sx={{fontWeight: 'bold'}}>
                                                {`Anmerkung`}
                                            </Typography>
                                            <Typography variant="text_m">
                                                {`${selectedSupplierRequest.annotation}`}
                                            </Typography>
                                        </Stack>
                                        <DragNDrop
                                            setFile={setFile}
                                            acceptFileTypes={{
                                                'application/pdf': ['.pdf']
                                            }}
                                            text={'Angebot als PDF anhängen (optional)'}
                                            acceptSubtext={'Akzeptiert und hinzugefügt'}
                                            rejectedSubtext={'Falsches Format. Bitte als PDF hochladen'}
                                        />
                                        <ControlledTextField
                                            control={control}
                                            errors={errors}
                                            name="order_id"
                                            label="Angebotsnummer"
                                            rules={{required: false}}
                                        />
                                        <ControlledDatePicker
                                            name="expired_on"
                                            control={control}
                                            rules={{ required: false}}
                                            label="Gültig bis"
                                        />
                                        <ControlledTextField
                                            control={control}
                                            errors={errors}
                                            name="supplier_annotation"
                                            label="Anmerkung zum Angebot"
                                            rules={{required: false}}
                                            rows={3}
                                            multiline
                                        />
                                        <Typography variant="text_s">
                                            {selectedSupplierRequest.agreement}
                                        </Typography>
                                        <ControlledCheckbox
                                            control={control}
                                            name="confirmed"
                                            label="Ja, ich habe die Berechtigung."
                                            disabled={selectedSupplierRequest.filled}
                                        />
                                    </Stack>
                                    <Stack gap={1.5}>
                                        <ConfirmDialog
                                            title="Angebot abschicken?"
                                            message="Bist du dir sicher, dass du das Angebot abschicken möchtest?"
                                            onConfirm={() => document.getElementById("rm-form").requestSubmit()}
                                            renderButton={(onClick) => (
                                                <Button
                                                    variant="outlined"
                                                    onClick={onClick}
                                                    disabled={!watch('confirmed') || selectedSupplierRequest.filled }
                                                >
                                                    Angebot abgeben
                                                </Button>
                                            )}
                                        />
                                        <Typography variant="text_s" sx={{fontWeight: 'bold'}}>
                                            {`Angebotssumme: ${formatNumber(sum, 2, '€')}`}
                                        </Typography>
                                    </Stack>
                                </Stack>
                                <Stack
                                    width="75%"
                                    gap={1}
                                    sx={{
                                        overflow: 'auto',
                                        border: 1,
                                        p: 2,
                                        borderRadius: '10px',
                                        height: 'calc(100vh - 132px)',
                                        borderColor: PALETTE.background.light70
                                    }}
                                >
                                    {requestedMaterials.map((rm, idx) => {
                                        const _idx = requestedMaterials.length - idx
                                        const material = rm.project_material
                                        return(
                                            <Box key={_idx}>
                                                <Stack
                                                    gap={0.5}
                                                >
                                                    <Stack
                                                        direction='row'
                                                        width="100%"
                                                        justifyContent="space-between"
                                                        alignItems="center"
                                                        gap={2}
                                                    >
                                                        <Stack
                                                            direction='row'
                                                            alignItems="center"
                                                            gap={2}
                                                        >
                                                            <Typography variant="value_highlight" sx={{width: 65}}>
                                                                {idx + 1}.
                                                            </Typography>
                                                            <Stack>
                                                                <Typography
                                                                        variant="title_m"
                                                                        sx={{fontSize: 14}}
                                                                    >
                                                                        {material.article_description}
                                                                </Typography>
                                                                <Typography
                                                                        variant="text_m"
                                                                    >
                                                                        {`Hersteller: ${material.manufacturer_name}`}
                                                                </Typography>
                                                            </Stack>
                                                        </Stack>
                                                        <Stack>
                                                            <Typography
                                                                    variant="value"
                                                                >
                                                                    {`${formatNumber(rm.value * rm.price, 2, '€')}`}
                                                            </Typography>
                                                        </Stack>
                                                    </Stack>
                                                    <Stack
                                                        direction='row'
                                                        width="100%"
                                                        justifyContent="space-between"
                                                        alignItems="center"
                                                        gap={1}
                                                        sx={{
                                                            p:1
                                                        }}
                                                    >
                                                        <FormControlLabel
                                                            control={
                                                                <Switch
                                                                    checked={rm.available}
                                                                    onChange={() => handleAvailableChange(idx, rm.available)}
                                                                    name="available"
                                                                />
                                                            }
                                                            label="Verfügbar"
                                                        />
                                                        <Stack
                                                            direction='row'
                                                            width="100%"
                                                            justifyContent="flex-end"
                                                            alignItems="center"
                                                            gap={2}
                                                        >
                                                            <TextField
                                                                placeholder="0"
                                                                type="number"
                                                                label="Menge"
                                                                size="small"
                                                                InputProps={{
                                                                    endAdornment: <InputAdornment position="end">{rm.project_material.unit?.split('/')[1]}</InputAdornment>,
                                                                }}
                                                                sx={{ width: 150}}
                                                                value={rm.value}
                                                                onChange={(e) => handleChange(e, idx, 'value')}
                                                                disabled={!rm.available}
                                                            />
                                                            <TextField
                                                                placeholder="0"
                                                                label="Einzelpreis"
                                                                type="number"
                                                                size="small"
                                                                InputProps={{
                                                                    endAdornment: <InputAdornment position="end">€</InputAdornment>,
                                                                }}
                                                                sx={{ width: 150}}
                                                                value={rm.price}
                                                                onChange={(e)=> handleChange(e, idx, 'price')}
                                                                disabled={!rm.available}
                                                            />
                                                            <TextField
                                                                label="Anmerkung"
                                                                size="small"
                                                                sx={{ width: 400}}
                                                                value={rm.supplier_annotation}
                                                                onChange={(e)=> handleChange(e, idx, 'annotation')}
                                                                disabled={!rm.available}
                                                            />
                                                        </Stack>
                                                    </Stack>
                                                </Stack>
                                                {_idx > 1 && <Divider flexItem/>}
                                            </Box>
                                        )
                                    })}
                                </Stack>
                            </Stack>
                        :
                            <Stack
                                height={600}
                                justifyContent="center"
                                alignItems="center"
                            >
                                <AccessDenied size={222} text="Kein Zutritt"/>
                            </Stack>
                        }
                    </Box>
                :
                    <Stack
                        height={600}
                        justifyContent="center"
                        alignItems="center"
                    >
                        <NoData text="Angebot erfolgreich abgegeben!"/>
                    </Stack>
                }
            </Container>
        </Box>
    );
}

export default SupplierMaterialRequests;