import React, { useState, useEffect } from 'react';
import {BrowserRouter as Router, Routes, Route, Navigate}  from 'react-router-dom';

import { UserProvider } from '../../context/UserContext'

import Login from '../Login';
import Dashboard from '../Dashboard';
import Register from '../Register'
import LoadingScreen from '../LoadingScreen';
import CheckAuth from '../CheckAuth'
import ActivateAccount from '../ActivateAccount';
import PasswordReset from 'components/PasswordReset';
import Agreement from 'components/Agreement';
import SupplierMaterialRequests from 'components/SupplierMaterialRequests';

const App = () => {

    const [user, setUser] = useState({
        isLoggedIn: false,
        isLoading: true,
        username: '',
        id: '',
        email: '',
    })

    useEffect(() => {
        getUser()
    }, [])

    const getUser = () => {
        setUser({
            isLoading: true
        })

        fetch('/account', {
            method: 'GET'
        }).then(response => {
            response.json().then(data => {
                if(response.ok){
                    setUser({
                        isLoggedIn: true,
                        isLoading: false,
                        username: data.username,
                        id: data.id,
                        email: data.email,
                    })
                }else{
                    setUser({
                        isLoggedIn: false,
                        isLoading: false,
                        username: '',
                        id: '',
                        email: '',
                    })
                }
            }).catch(error => {
                console.error(error)
                setUser({
                    isLoggedIn: false,
                    isLoading: false,
                    username: ''
                })
            })
        }).catch(error => {
            console.error(error)
            setUser({
                isLoggedIn: false,
                isLoading: false,
                username: ''
            })
        })
    }

    if(user.isLoading){
        return(
            <div className="wrapper">
                <LoadingScreen/>
            </div>
        )
    }
    return(
            <UserProvider value={[user, getUser]}>
                <Router>
                    <Routes>
                        <Route path='/dashboard/*' element={
                            <CheckAuth redirectTo='/login' requiredLoginStatus={true}>
                                <Dashboard />
                            </CheckAuth>
                        }/>
                        <Route path='/login' element={
                            <CheckAuth redirectTo='/dashboard' requiredLoginStatus={false}>
                                <Login />
                            </CheckAuth>
                        }/>
                        <Route path='/agreement/:id' element={
                            <CheckAuth redirectTo='/login' requiredLoginStatus={true}>
                                <Agreement />
                            </CheckAuth>
                        }/>
                        <Route path='/activation' element={
                            <CheckAuth redirectTo='/dashboard' requiredLoginStatus={false}>
                                <ActivateAccount />
                            </CheckAuth>
                        }/>
                        <Route path='/register' element={
                            <CheckAuth redirectTo='/dashboard' requiredLoginStatus={false}>
                                <Register />
                            </CheckAuth>
                        }/>
                        <Route path='/password-reset/*' element={
                            <CheckAuth redirectTo='/dashboard' requiredLoginStatus={false}>
                                <PasswordReset />
                            </CheckAuth>
                        }/>
                        <Route path='/supplier/material/request' element={
                            <SupplierMaterialRequests/>
                        }/>
                        <Route path='/' element={<Navigate to='/dashboard' />} />
                    </Routes>
                </Router>
            </UserProvider>
    )
}

export default App;
