import {DataGridPro, GridActionsCellItem, GridRowModes} from '@mui/x-data-grid-pro';

import {Alert, AlertTitle, Stack, LinearProgress, IconButton} from "@mui/material"

import CustomNoRows from '../CustomNoRows';
import { PALETTE } from '../../../theme';
import {Cancel, Delete, Edit, Save} from "@mui/icons-material";
import ConfirmDialog from "../../ConfirmDialog";
import useStyledSnackbar from 'hooks/useStyledSnackbar';
import { useEffect } from 'react';


const CustomDatagrid = ({
    Component=DataGridPro,
    Toolbar,
    toolbarProps,
    rows,
    columns,
    apiRef,
    loading,
    initialPageSize=10,
    errors={},
    pageSize,
    onPageSizeChange,
    onPageChange,
    rowCount,
    onDeleteRow,
    onSaveRow,
    getConfirmMessage,
    ...props
}) => {

    const handleEditClick = (id) => (event) => {
        event.stopPropagation();
        apiRef.current.setRowMode(id, GridRowModes.Edit);
    };

    const handleCancelClick = (id) => (event) => {
        event.stopPropagation();
        apiRef.current.setRowMode(id, GridRowModes.View);

        const row = apiRef.current.getRow(id);
        if (row.isNew) {
            apiRef.current.updateRows([{ id, _action: 'delete' }]);
        }
    };

    const handleSaveClick = (id) => async (event) => {
        event.stopPropagation();
        const isValid = await apiRef.current.commitRowChange(id);
        if (isValid) {
            apiRef.current.setRowMode(id, GridRowModes.View);
            const row = apiRef.current.getRow(id);
            onSaveRow(row)
        }
    };

    const handleDeleteClick = (id) => async() => {
        if(await onDeleteRow(id)){
            apiRef.current.updateRows([{ id: id, _action: 'delete' }]);
        }
    }

    const columns_ = (!onDeleteRow && !onSaveRow) ? columns : columns.concat([{
        field: 'actions',
        headerName: (onDeleteRow && onSaveRow) ? 'Bearbeiten/Löschen' : (onSaveRow ? 'Bearbeiten' : 'Löschen'),
        type: 'actions',
        minWidth: 150,
        getActions: ({ id }) => {
            const isInEditMode = apiRef.current.getRowMode(id) === GridRowModes.Edit;
            const row = apiRef.current.getRow(id);

            if (isInEditMode) {
                return [
                    <GridActionsCellItem
                        icon={<Save/>}
                        label="Save"
                        onClick={handleSaveClick(id)}
                        color="primary"
                    />,
                    <GridActionsCellItem
                        icon={<Cancel/>}
                        label="Cancel"
                        className="textPrimary"
                        onClick={handleCancelClick(id)}
                        color="background"
                    />,
                ];
            }
            else{
                return [
                    ...(onSaveRow ? [<GridActionsCellItem
                        icon={<Edit/>}
                        label="Edit"
                        className="textPrimary"
                        onClick={handleEditClick(id)}
                        color="primary"
                    />] : []),
                    ...(onDeleteRow ? [<ConfirmDialog
                        title='Vorsichtig!'
                        message={getConfirmMessage(row)}
                        onConfirm={handleDeleteClick(id)}
                        renderButton={(onClick) => (
                            <IconButton
                                onClick={onClick}
                                size="small"
                                color="error"
                            >
                                <Delete fontSize="small"/>
                            </IconButton>
                        )}
                    />] : [])
                ];
            }
        },
    }])

    const { enqueueSnackbar } = useStyledSnackbar()

    useEffect(() => {
        if (errors.non_field_errors) {
            enqueueSnackbar('Fehler: ' + errors.non_field_errors, { variant: 'error' })
        }
        Object.entries(errors).forEach(([field, errors], idx) => {
            const column = columns.find(c => c.field === field)
            if (!column) return null
            enqueueSnackbar(column.headerName + ': ' + errors, { variant: 'error' })
        })
    }, [errors])


    return(
        <Stack gap={2} sx={{ height: '100%' }}>
            <Component
                autoHeight
                rows={rows}
                columns={columns_}
                apiRef={apiRef}
                density="compact"
                editMode="row"
                pageSize={pageSize}
                onPageSizeChange={onPageSizeChange}
                onPageChange={onPageChange}
                rowCount={rowCount}
                rowsPerPageOptions={[5, 10, 25, 50, 100]}
                pagination
                loading={loading}
                components={{
                    Toolbar: Toolbar,
                    NoRowsOverlay: CustomNoRows,
                    LoadingOverlay: LinearProgress,
                }}
                componentsProps={{
                    toolbar: toolbarProps,
                    filterPanel: {
                        columnsSort: 'asc',
                        filterFormProps: {
                            // Customize inputs by passing props
                            linkOperatorInputProps: {
                                variant: 'outlined',
                                size: 'small',
                            },
                            columnInputProps: {
                                variant: 'outlined',
                                size: 'small',
                                sx: {
                                    mt: 'auto',
                                    minWidth: 200,
                                },
                            },
                            operatorInputProps: {
                                variant: 'outlined',
                                size: 'small',
                                sx: { mt: 'auto' },
                            },
                            valueInputProps: {
                                variant: 'outlined',
                                size: 'small',
                                sx: { mt: 'auto' },
                            },
                            deleteIconProps: {
                            sx: {
                                '& .MuiSvgIcon-root': { color: PALETTE.primary.main},
                            },
                            },
                        },
                        sx: {
                            // Customize inputs using css selectors
                            '& .MuiDataGrid-filterForm': { p: 2 },
                            '& .MuiDataGrid-filterFormLinkOperatorInput': { mr: 2 },
                            '& .MuiDataGrid-filterFormColumnInput': { mr: 2, width: 150 },
                            '& .MuiDataGrid-filterFormOperatorInput': { mr: 2 },
                            '& .MuiDataGrid-filterFormValueInput': { width: 200 },
                        },
                    },
                }}
                sx={{
                    '& .MuiDataGrid-virtualScroller': {
                        minHeight: '242px'
                    }
                }}
                // localeText={{
                //     toolbarColumns: "my columns",
                //     toolbarFilters: "my filters",
                //     toolbarDensity: "my density",
                //     toolbarExport: "my export"
                //   }}
                {...props}
            />
        </Stack>
    )
}

export default CustomDatagrid
