import { Stack, Typography, Fade } from "@mui/material"
import { PALETTE } from "components/theme"
import { IoWarning } from "react-icons/io5"


const AccessDenied = ({ text, size }) => {
    return (
        <Fade in={true} timeout={500} style={{ transitionDelay: '250ms' }}>
            <Stack gap={2} sx={{ p: 2, height: '100%', justifyContent: 'center' }} alignItems='center'>
                    <IoWarning size={size} color= {PALETTE.error.main}/>
                    <Typography variant="text_m">{ text }</Typography>
            </Stack>
        </Fade>
    )
}

export default AccessDenied
