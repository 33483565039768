import { useCallback, useContext, useEffect, useState } from "react"
import { Stack } from "@mui/material"

import AccountContext from "../../../../context/AccountContext"
import ProjectContext from "../../../../context/ProjectContext"

import FormDialog from "../../Todos/FormDialog"
import TodoManager from "components/Widgets/TodoManager"



const ProjectTodos = () => {

    const [account] = useContext(AccountContext)
    const [project, , , , , , subProjects] = useContext(ProjectContext)
    const [todos, setTodos] = useState({
        created: [],
        received: [],
        private: [],
    })

    const getProjectTodos = useCallback( async() =>{
        const res = await fetch('/api/project/' + project.id + '/todos', {
            method: 'GET'
        })
        const data = await res.json()
        if(res.ok){
            setTodos({
                created: data.created,
                received: data.recieved, // TODO fix in backend
                private: data.private
            })
        }
    }, [project.id])

    useEffect(() =>{
        getProjectTodos()
    }, [getProjectTodos])


    const [dialogOpen, setDialogOpen] = useState('')

    return(
        <Stack>
            <TodoManager
                todos={todos}
                setDialogOpen={setDialogOpen}
                getTodos={getProjectTodos}
                inProject={true}
            />
            <FormDialog
                open={dialogOpen}
                handleClose={() => setDialogOpen(false)}
                getTodos={getProjectTodos}
                companyMemberID={account.id}
                projectKeys={subProjects}
                projectTodo={true}
                projectId={project.id}
            />
        </Stack>
    )
}

export default ProjectTodos
