import React, {useState, useEffect} from 'react';
import { Navigate, Route, Routes, useLocation, useMatch }  from 'react-router-dom';

import Projects from './Projects'
import NavBar from '../NavBar'
import { NAVBARS, PACKAGE_KEYS } from 'components/NavBar/Data'
import ProjectAdd from './Projects/ProjectAdd'
import Company from './Company'
import Project from '../Dashboard/Project';
import ProjectsRequests from './Projects/ProjectsRequests';
import ProjectsSearch from './Projects/ProjectsSearch';
import CheckPermission from '../CheckPermission';
import Account from './Account';
import LoadingScreen from '../LoadingScreen';
import MaterialDatabase from './MaterialDatabase';
import CompanyCalendar from './CompanyCalendar';
import TimeRecording from './TimeRecording';
import TimeRecordingManagement from './TimeRecording/TimeRecordingManagement';
import XProject from './x-project'
import CompanyChat from './CompanyChat'
import ConmeetConnections from './ConmeetConnections';
import WorkPlanner from './WorkPlanner';
import CompanySupplier from './CompanySupplier';
import CompanyCustomer from './CompanyCustomer';
import PossibleProjects from './Projects/PossibleProjects';

import { AccountProvider } from '../../context/AccountContext';
import { NotificationContextWrapper } from '../../context/NotificationContext'
import Todos from "./Todos";
import CompanyWarehouse from './CompanyWarehouse';
import Paywall from "../Widgets/Paywall";
import TimeRecordingExport from './TimeRecording/TimeRecordingExport';
import CheckPackageKey from 'components/CheckPackageKey';
import { SETTINGS as NAVBAR_SETTINGS } from 'components/NavBar'

import Notifications from 'components/Widgets/Notifications';



const Dashboard = () => {
    const location = useLocation()
    const inProject = useMatch('/dashboard/projects/project/:id/*')

    const [account, setAccount] = useState()
    const [isLoaded, setIsLoaded] = useState(false)

    const getAccount = async() => {
        const res = await fetch('/api/account', {
            method: 'GET'
        })
        const data = await res.json()
        if(res.ok){
            console.log(data)
            setAccount(data)
            setIsLoaded(true)
        }
    }

    useEffect(()=>{
        getAccount()
    }, [])

    if(!isLoaded){
        return(
            <LoadingScreen open={!isLoaded}/>
        )
    }
    return(
        <AccountProvider value={[account, getAccount, setAccount]}>
            <NotificationContextWrapper>
                <div className="wrapper">
                    <Routes>
                        <Route path={'projects/project/:id/*'} element={
                            <></>
                        } />
                        <Route path='/*' element={
                            <NavBar
                                data={NAVBARS[account.company.package_key]}
                                basePath={location.pathname}
                            />
                        } />
                    </Routes>
                    <div
                        className="content"
                        style={{
                            width: inProject ?
                                '100%'
                                :
                                'calc(100% - ' + (NAVBAR_SETTINGS.collapsedWidth + NAVBAR_SETTINGS.openWidth) + 'px)',
                            marginLeft: inProject ?
                                0
                                :
                                NAVBAR_SETTINGS.collapsedWidth + NAVBAR_SETTINGS.openWidth
                        }}
                    >
                        <Routes>
                            <Route path='' element={<Navigate to='projects' />} />
                            <Route path='projects' element={<Projects />} />
                            <Route path='projects/search' element={
                                <Paywall>
                                    <CheckPermission redirectTo='/dashboard' >
                                        <ProjectsSearch />
                                    </CheckPermission>
                                </Paywall>
                            }/>
                            <Route path='projects/requests' element={
                                <CheckPermission redirectTo='/dashboard' >
                                    <ProjectsRequests />
                                </CheckPermission>
                            }/>
                            <Route path='projects/possible' element={
                                <Paywall>
                                    <CheckPermission redirectTo='/dashboard' >
                                        <PossibleProjects />
                                    </CheckPermission>
                                </Paywall>
                            }/>
                            <Route path='projects/addproject' element={
                                <Paywall>
                                    <CheckPermission redirectTo='/dashboard' >
                                        <ProjectAdd />
                                    </CheckPermission>
                                </Paywall>
                            }/>
                            <Route path='projects/x-project/:id/*' element={
                                <XProject />
                            } />
                            <Route path={'projects/project/:id/*'} element={
                                <Project />
                            }/>
                            <Route path={'calendar/*'} element={<CompanyCalendar />}/>
                            <Route path={'timerecording/*'} element={
                                    <TimeRecording />
                            } />
                            <Route path='timerecording/checking' element={
                                    <CheckPermission redirectTo='/dashboard' >
                                        <TimeRecordingManagement />
                                    </CheckPermission>
                            }/>
                            <Route path='timerecording/export' element={
                                <Paywall>
                                    <CheckPermission redirectTo='/dashboard' >
                                        <TimeRecordingExport/>
                                    </CheckPermission>
                                </Paywall>
                            }/>
                            <Route path='company-chat' element={<CompanyChat />}/>
                            <Route path='todos' element={<Todos />}/>
                            <Route path='conmeet-connections' element={<ConmeetConnections />}/>
                            <Route path='workplanner/*' element={
                                <Paywall>
                                    <CheckPermission redirectTo='/dashboard' >
                                        <WorkPlanner />
                                    </CheckPermission>
                                </Paywall>
                            }/>
                            <Route path='warehouse/*' element={
                                <Paywall>
                                    <CheckPackageKey 
                                        redirectTo='/dashboard'
                                        packageKeys={[PACKAGE_KEYS.GU, PACKAGE_KEYS.HWU]}    
                                    >
                                        <CheckPermission redirectTo='/dashboard' >
                                            <CompanyWarehouse />
                                        </CheckPermission>
                                    </CheckPackageKey>
                                </Paywall>
                            }/>
                            <Route path='customer/*' element={
                                <Paywall>
                                    <CheckPermission redirectTo='/dashboard' >
                                        <CompanyCustomer />
                                    </CheckPermission>
                                </Paywall>
                            }/>
                            <Route path='supplier/*' element={
                                <CheckPackageKey 
                                    redirectTo='/dashboard'
                                    packageKeys={[PACKAGE_KEYS.GU, PACKAGE_KEYS.HWU]}
                                >
                                    <CheckPermission redirectTo='/dashboard' >
                                        <CompanySupplier />
                                    </CheckPermission>
                                </CheckPackageKey>
                            }/>
                            <Route path={'company/*'} element={<Company />}/>
                            <Route path={'account/*'} element={<Account />}/>
                            <Route path={'load/*'} element={<LoadingScreen />}/>
                            <Route path={'material/*'} element={
                                <CheckPackageKey 
                                    redirectTo='/dashboard'
                                    packageKeys={[PACKAGE_KEYS.GU, PACKAGE_KEYS.HWU]}
                                >
                                    <CheckPermission redirectTo='/dashboard' >
                                        <MaterialDatabase/>
                                    </CheckPermission>
                                </CheckPackageKey>
                            }/>
                        </Routes>
                        <Notifications />
                    </div>
                </div>
            </NotificationContextWrapper>
        </AccountProvider>
    )
}

export default Dashboard;
