import React, { useContext } from 'react';
import { Navigate } from 'react-router-dom';
import AccountContext from '../../context/AccountContext'

const CheckPermission = ({children, redirectTo, onlyAdmin=false}) => {
    const [account] = useContext(AccountContext)

    return account.is_admin || (account.is_manager && !onlyAdmin)?
        children
        : <Navigate to={redirectTo} />
};

export default CheckPermission;