import React, { useState, useEffect, useContext, useCallback } from 'react'

import {Box, Button, CircularProgress, Collapse, FormControlLabel, MenuItem, Select, Stack, Switch, ToggleButton, ToggleButtonGroup} from '@mui/material';
import { BsFillAspectRatioFill, BsFillCameraVideoFill, BsFillFileEarmarkFill, BsImageFill } from 'react-icons/bs'
import ViewModuleIcon from '@mui/icons-material/ViewModule';
import ViewListIcon from '@mui/icons-material/ViewList';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

import LoadingScreen from '../../../LoadingScreen'
import FileList from './FileList';
import FileGrid from './FileGrid';
import ProjectHeader from '../../../Widgets/ProjectHeader'
import CustomPage from '../../../Widgets/CustomPage';
import CustomTabs from '../../../Widgets/CustomTabs';
import CustomPaper from 'components/Widgets/CustomPaper';

import ProjectContext from '../../../../context/ProjectContext'
import InfiniteScroll from 'react-infinite-scroll-component';
import Files from './Files';
import UploadDialog from './UploadDialog';
import AccountContext from 'context/AccountContext';
import NoData from 'components/Widgets/NoData';

export const VISIBILITIES = {
    shared: '0',
    private: '1',
    internal_all: '2',
    internal_privileged: '3',
    privileged: '4',
    public: '5',
}


const FILE_TYPES = {
    images: '0',
    videos: '1',
    pdfs: '2',
}

export const FILE_TYPE_EXTENSIONS = {
    images: ['.jpg', '.JPG', '.jpeg', '.JPEG', '.png', '.PNG'],
    videos: ['.mp4', '.MP4'],
    pdfs: ['.pdf', '.PDF'],
}


const ProjectDocuments = () => {
    const [project] = useContext(ProjectContext)
    const [account] = useContext(AccountContext)

    const OWN_DEFAULT = false
    const [ own, setOwn ] = useState(OWN_DEFAULT)
    const handleOwnChange = (e) => {
        setOwn(e.target.checked)
    }

    const [ order, setOrder ] = useState(0);
    const handleOrderChange = (e) => {
        setOrder(e.target.value)
    }

    const [ view, setView ] = useState('grid');
    const handleViewChange = (e, n) => {
        if (!n) return
        setView(n)
    }

    const VISIBILITY_DEFAULT = []
    const [ visibility, setVisibility ] = useState(VISIBILITY_DEFAULT);
    const handleVisibilityChange = (e, n) => {
        setVisibility(n)
    }

    const FILE_TYPE_DEFAULT = []
    const [ fileType, setFileType ] = useState(FILE_TYPE_DEFAULT);
    const handleFileTypeChange = (e, n) => {
        if(n.length === 1 && n.includes(FILE_TYPES.pdfs)){ setView('list') }
        if(n.length === 0){ setView('grid') }
        setFileType(n)
    }

    const PLANS_DEFAULT = false
    const [ plans, setPlans ] = useState(PLANS_DEFAULT);
    const handlePlansChange = (e) => {
        setPlans(e.target.checked)
    }

    const [ filterOpen, setFilterOpen ] = useState(true);
    const toggleFilterOpen = () => {
        setFilterOpen(o => !o)
    }

    const resetFilters = () => {
        setOwn(OWN_DEFAULT)
        setVisibility(VISIBILITY_DEFAULT)
        setFileType(FILE_TYPE_DEFAULT)
        setPlans(PLANS_DEFAULT)
    }

    const filterActive = own !== OWN_DEFAULT || visibility.length > 0 || fileType.length > 0 || plans !== PLANS_DEFAULT

    const [ pagination, setPagination ] = useState()
    const [ files, setFiles ] = useState([])
    const fetchNextFiles = async () => {
        const res = await fetch(pagination.next)
        const json = await res.json()
        setFiles(o => [...o, ...json.results])
        setPagination(json)
    }
    const fetchInitialFiles = useCallback(async () => {
        const file_types = fileType?.map(t => {
                if (t === FILE_TYPES.images)
                    return FILE_TYPE_EXTENSIONS.images
                if (t === FILE_TYPES.videos)
                    return FILE_TYPE_EXTENSIONS.videos
                if (t === FILE_TYPES.pdfs)
                    return FILE_TYPE_EXTENSIONS.pdfs
            })
            .flat()
            .map(x => 'file_name_contains=' + x)
            .join('&')
        const vis = visibility?.map(x => 'visibility=' + x).join('&')
        const res = await fetch('/documents/project/' + project.id
            + '?order_by=' + (order === 0 ? '-created' : 'created')
            + '&plan=' + (plans ? '1' : '0')
            + (!vis ? '' : '&' + vis)
            + (!file_types ? '' : '&' + file_types)
            + '&own=' + (own ? '1' : '0'))
        const json = await res.json()
        setFiles(json.results)
        setPagination(json)
    }, [project.id, order, own, visibility, fileType, plans])
    useEffect(() => {
        fetchInitialFiles()
    }, [fetchInitialFiles])
    const updateDocumentState = d => {
        setFiles(o => {
            let n = [...o]
            const index = n.findIndex(e => e.id === d.id)
            if (index > -1) {
                n[index] = d
            }
            return n
        })
    }

    if (!pagination) return <LoadingScreen />

    return(
        <Stack gap={3}>
            <Stack sx={{ flex: '1 1 0' }} direction='column' gap={3}>
                <CustomPaper
                    actionBar={
                        <Stack direction='column' gap={2} sx={{ flex: 1 }}>
                            <Stack direction='row' justifyContent='space-between' gap={2}>
                                <Stack direction='row' gap={2}>
                                    <Button variant="text" onClick={toggleFilterOpen}>
                                        Filter {filterActive ? '*' : ''} {filterOpen ? <ExpandLessIcon /> : <ExpandMoreIcon />}
                                    </Button>
                                    <Button variant="text" onClick={resetFilters} disabled={!filterActive}>Filter zurücksetzen</Button>
                                </Stack>
                                <Stack direction='row' gap={2}>
                                    <UploadDialog
                                        title={'title'}
                                        getUploads={fetchInitialFiles}
                                    />
                                    <Select
                                        value={order}
                                        onChange={handleOrderChange}
                                        sx={{ width: 200 }}
                                        size="small"
                                    >
                                        <MenuItem value={0}>Datum absteigend</MenuItem>
                                        <MenuItem value={1}>Datum aufsteigend</MenuItem>
                                    </Select>
                                    <ToggleButtonGroup
                                        orientation="horizontal"
                                        value={view}
                                        exclusive
                                        onChange={handleViewChange}
                                    >
                                        <ToggleButton value="grid" aria-label="grid">
                                            <ViewModuleIcon />
                                        </ToggleButton>
                                        <ToggleButton value="list" aria-label="list">
                                            <ViewListIcon />
                                        </ToggleButton>
                                    </ToggleButtonGroup>
                                </Stack>
                            </Stack>
                            <Collapse in={filterOpen}>
                                <Stack
                                    direction='row'
                                    justifyContent='space-between'
                                    gap={4}
                                    sx={{ pb: 2 }}
                                >
                                    <Stack direction='column' gap={2} sx={{ flex: 1 }}>
                                        <ToggleButtonGroup
                                            orientation="horizontal"
                                            value={visibility}
                                            onChange={handleVisibilityChange}
                                            fullWidth
                                            color="primary"
                                        >
                                            <ToggleButton value={VISIBILITIES.public}>Öffentlich | Alle</ToggleButton>
                                            {(account.is_admin || account.is_manager) && <ToggleButton value={VISIBILITIES.privileged}>Öffentlich | Planer</ToggleButton>}
                                            <ToggleButton value={VISIBILITIES.internal_all}>Intern | Alle</ToggleButton>
                                            {(account.is_admin || account.is_manager) && <ToggleButton value={VISIBILITIES.internal_privileged}>Intern | Planer</ToggleButton>}
                                            <ToggleButton value={VISIBILITIES.private}>Private</ToggleButton>
                                            <ToggleButton value={VISIBILITIES.shared}>Geteilt</ToggleButton>
                                        </ToggleButtonGroup>
                                        <ToggleButtonGroup
                                            orientation="horizontal"
                                            value={fileType}
                                            onChange={handleFileTypeChange}
                                            fullWidth
                                            color="primary"
                                        >
                                            <ToggleButton value={FILE_TYPES.images}>Bilder</ToggleButton>
                                            <ToggleButton value={FILE_TYPES.videos}>Videos</ToggleButton>
                                            <ToggleButton value={FILE_TYPES.pdfs}>PDFs</ToggleButton>
                                        </ToggleButtonGroup>
                                    </Stack>
                                    <Stack direction='column' gap={2}>
                                        <FormControlLabel control={<Switch checked={plans} onChange={handlePlansChange} />} label="Nur Pläne anzeigen" />
                                        <FormControlLabel control={<Switch checked={own} onChange={handleOwnChange} />} label="Nur eigene anzeigen" />
                                    </Stack>
                                </Stack>
                            </Collapse>
                        </Stack>
                    }
                >
                    {files.length > 0 ?
                        <InfiniteScroll
                            dataLength={files.length}
                            next={fetchNextFiles}
                            hasMore={pagination.next !== null}
                            loader={
                                <Stack sx={{ height: '50px', }} alignItems='center'>
                                    <CircularProgress />
                                </Stack>
                            }
                            // scrollableTarget="activityDialogContent"
                        >
                            <Files files={files} view={view} updateDocumentState={updateDocumentState} />
                        </InfiniteScroll>
                    :
                        <NoData text='Keine Dokumente vorhanden' />
                    }
                </CustomPaper>
            </Stack>
        </Stack>
    )
}

export default ProjectDocuments
