import { useState, useEffect, useContext, useCallback } from "react";

import { Stack } from "@mui/material";

import AccountContext from "../../../context/AccountContext";

import TimeRecordingChart from "./TimeRecordingChart";
import TimestampsTable from "./TimestampTable";
import CustomPage from '../../Widgets/CustomPage'

import { getConstants } from "../../../utils/getConstants";
import { getAllDatesInMonth, getLocalDate } from "../../../utils/date_functions";
import TimeRecordingInfoBar from "./TimeRecordingInfoBar";



const TimeRecording = () =>{

    const [account] = useContext(AccountContext)

    const [selectedDate, setSelectedDate] = useState(new Date())
    const [timestampTypeKeys, setTimestampTypeKeys] =  useState({})
    const [projectKeys, setProjectKeys] = useState({})

    const [timechartdata, setTimeChartdata] = useState(null)
    const [timestamps, setTimestamps] = useState([])
    const [timestampValues, setTimestampsValues] = useState({})

    const [isLoading, setIsLoading] = useState({
        timestamps: false,
        chartData: false,
        values: false,
    })

    const getProjectKeys = async() => {
        const res = await fetch('/api/projects/get-sub-selection?includeCompleted=1&allProjects=' + (account.is_manager ? '1' : '0'), {
            method: 'GET'
        })
        const data = await res.json()
        if(res.ok){
            setProjectKeys(data)
        }
    }

    const handleUpdate = useCallback(() => {

        let initialChartData = getAllDatesInMonth(selectedDate.getFullYear(), selectedDate.getMonth())

        setIsLoading({
            timestamps: true,
            chartData: true,
            values: true
        })

        const getTimestamps = async() => {
            const res = await fetch('/api/time-recording?company_member_id=' + account.id + '&date=' + getLocalDate(selectedDate), {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json'
                },
            })
            const data = await res.json()
            if(res.ok){
                setTimestamps(data)
                setIsLoading((prevState) => ({ ...prevState, timestamps: false }))
                // console.log(data)
            }
        }

        const getTimeRecordingValues = async() => {
            const res = await fetch('/api/time-recording/values?company_member_id=' + account.id + '&date=' + getLocalDate(selectedDate), {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json'
                },
            })
            const data = await res.json()
            if(res.ok){
                setTimestampsValues(data)
                setIsLoading((prevState) => ({ ...prevState, values: false }))
                console.log(data)
            }
        }

        const getTimeChartdata = async() => {

            const res = await fetch('/api/time-recording/chart?company_member_id=' + account.id + '&date=' + getLocalDate(selectedDate), {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json'
                },
            })
            const data = await res.json()
            if(res.ok){
                for(let d of data){
                    const index = new Date(d.date).getDate() - 1
                    const _d = initialChartData[index]
                    initialChartData[index] = {
                        ..._d,
                        ...d,
                        Pause: (_d.Pause ? _d.Pause : 0)  + d?.Pause
                    }
                }
                setTimeChartdata(initialChartData)
                setIsLoading((prevState) => ({ ...prevState, chartData: false }))
            }
        }
        getTimestamps()
        getTimeChartdata()
        getTimeRecordingValues()
    }, [selectedDate, account])

    useEffect(() => {
        handleUpdate()
    }, [handleUpdate])

    useEffect(() => {
        getProjectKeys();
        getConstants('timestamp_types', (c) =>{setTimestampTypeKeys(c)});
    }, []);


    return(
        <CustomPage
            title="Zeiterfassung"
        >
            <Stack spacing={3}>
                <TimeRecordingChart
                    companyMember={account}
                    timechartdata={timechartdata}
                    selectedDate={selectedDate}
                    setSelectedDate={setSelectedDate}
                />
                <TimeRecordingInfoBar
                    data={timestampValues}
                />
                <TimestampsTable
                    company_member_id={account.id}
                    selectedDate={selectedDate}
                    rows={timestamps}
                    is_manager={account.is_manager}
                    handleUpdate={handleUpdate}
                    timestampTypeKeys={timestampTypeKeys}
                    projectKeys={projectKeys}
                    isLoading={isLoading.timestamps || isLoading.chartData || isLoading.values}
                    hideAllConfirm={!account.is_manager}
                />
            </Stack>
        </CustomPage>
    )
}

export default TimeRecording