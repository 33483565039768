import { Grid } from '@mui/material'
import TicketCardLimited from '../TicketCardLimited'
import { handleChipColorVariant } from "../../../../../utils/helper";
import NoData from 'components/Widgets/NoData';
import noImage from '../../../../../assets/svgs/image_upload.svg'



const ProjectTicketsLimited = ({ ticketChains, dispatch, project_id}) =>{

    return(
        <>
            {ticketChains.length !== 0 ?
                <Grid
                    container
                    spacing={2}
                >
                    {ticketChains.map((share_allocator, idx)=>{
                        let done = share_allocator.done
                        let cv = handleChipColorVariant(share_allocator.project_ticket.status);
                        let color = cv[0]
                        let variant = cv[1]
                        share_allocator.project_ticket.image = share_allocator.project_ticket.image ? share_allocator.project_ticket.image : noImage
                        return(
                            <Grid
                                key={idx}
                                item
                                desktop={4}
                                labtop={6}
                                tablet={12}
                                mobil={12}
                            >
                                <TicketCardLimited
                                    key={idx}
                                    ticket={share_allocator.project_ticket}
                                    color={color}
                                    variant={variant}
                                    dispatch={dispatch}
                                    project_id={project_id}
                                    done={done}
                                />
                            </Grid>
                        )
                    })}
                </Grid>
            :
                <NoData text="Keine Tickets"/>
            }

        </>
    )
}

export default ProjectTicketsLimited