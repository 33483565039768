/* eslint-disable no-mixed-operators */
import IconButton from '@mui/material/IconButton';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import DownloadIcon from '@mui/icons-material/Download';
import { faFileImage, faFilePdf, faFile } from "@fortawesome/free-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {RiErrorWarningFill} from "react-icons/ri";
import {Stack} from "@mui/material";

import Tooltip from "./Tooltip";
import SettingsButton from "./SettingsButton";


const FileList = ({ files, getUploads }) => {

    return (
        <Table>
            <TableHead>
                <TableRow>
                    <TableCell></TableCell>
                    <TableCell>Name</TableCell>
                    <TableCell>Beschreibung</TableCell>
                    <TableCell>Hochgeladen durch</TableCell>
                    <TableCell>Zeitstempel</TableCell>
                    <TableCell></TableCell>
                </TableRow>
            </TableHead>
            <TableBody>
                {files.map(f => {
                    const re = /(?:\.([^.]+))?$/
                    const extension = re.exec(f.file_name)[1]
                    return (
                        <Tooltip document={f}>
                        <TableRow key={f.id}>
                            <TableCell>
                                    <Stack
                                        direction='row'
                                        alignItems='center'
                                        gap={1}
                                        sx={{
                                            height: 22
                                        }}
                                    >
                                        <FontAwesomeIcon
                                            size='lg'
                                            icon={
                                                ['jpg', 'png'].includes(extension) && faFileImage ||
                                                'pdf' === extension && faFilePdf ||
                                                faFile
                                            }
                                        />
                                        {f.outdated && <RiErrorWarningFill size={22} />}
                                    </Stack>
                            </TableCell>
                            <TableCell>{f.file_name}</TableCell>
                            <TableCell>{f.description}</TableCell>
                            <TableCell>{f.name}</TableCell>
                            <TableCell>{new Date(f.timestamp).toLocaleString()}</TableCell>
                            <TableCell sx={{ p: 0 }}>
                                {/* <SettingsButton document={f} getUploads={getUploads} color='primary' /> */}
                                {/* TODO: test on live server (doesn't work with react proxy) */}
                                <IconButton color='primary' href={f.file} download={f.file_name}>
                                    <DownloadIcon fontSize='inherit' />
                                </IconButton>
                            </TableCell>
                        </TableRow>
                        </Tooltip>
                    )
                })}
            </TableBody>
        </Table>
    )
}

export default FileList
