import { useState, useEffect, useContext, useCallback } from 'react'
import { Stack, Avatar, Typography, FormControl, InputLabel, Select, MenuItem } from '@mui/material'
import AccountContext from '../../context/AccountContext'


const CompanyMemberSelection = ({ label, selectedCompanyMember, setSelectedCompanyMember, includeCurrentUser=false, onlyWorkers=false, includeDisabled=false, sx}) => {

    const [account] = useContext(AccountContext)
    const [value_, setValue_] = useState('')
    const [companyMembers, setCompanyMembers] = useState({})

    const getCompanyMembers = useCallback(async () => {
        const res = await fetch('/api/company/members?onlyWorkers=' + (onlyWorkers ? '1' : '0') + '&includeDisabled=' + (includeDisabled ? '1' : '0'), {
            method: 'GET'
        })
        const json = await res.json()
        if (res.ok) {
            const transformed = {}
            for (const cm of json) {
                if(includeCurrentUser || cm.id !== account.id){
                    transformed[cm.id] = cm
                }
            }
            setCompanyMembers(transformed)
        }
    }, [account, includeCurrentUser, onlyWorkers])

    useEffect(() => {
        getCompanyMembers()
    }, [getCompanyMembers])

    const handleOnChange = (event) => {
        setValue_(event.target.value)
        setSelectedCompanyMember(companyMembers[event.target.value])
    }


    return (
        <FormControl
            variant="outlined"
            fullWidth
            sx={sx}
        >
            <InputLabel>{label}</InputLabel>
            <Select
                label={label}
                variant="outlined"
                fullWidth
                onChange={handleOnChange}
                // empty value if no items
                value={(!companyMembers || Object.keys(companyMembers).length === 0) ? '' : value_}
            >
                {Object.entries(companyMembers).map(([key, companyMember], idx) => {
                    return(
                        <MenuItem key={`${label}-key-${idx}`} value={key}>
                            <Stack
                                direction="row"
                                alignItems='center'
                                spacing={1}
                            >
                                <Avatar alt='' src={companyMember.avatar} />
                                <Typography variant='text_m'>{companyMember.firstname} {companyMember.lastname}</Typography>
                            </Stack>
                        </MenuItem>
                    )
                })}
            </Select>
        </FormControl>
    )
}

export default CompanyMemberSelection
