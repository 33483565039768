import React, {useContext, useEffect, useState} from "react";
import CustomPaper from "../../Widgets/CustomPaper";
import Chat from "../../Chat";


const ConmeetConnectionsBlackboard = ({ sx }) =>{
    const [ chatId, setChatId ] = useState()

    useEffect(() => {
        const getChat = async () => {
            const res = await fetch('/api/conmeet-connections/blackboard', {
                method: 'GET',
            })
            if (res.ok) {
                const data = await res.json()
                setChatId(data.chat_id)
            }
        }
        getChat()
    }, [])

    return (
        <CustomPaper
            title='Feed'
            subtitle="Alle Beiträge"
            sx={{ flex: '1 1 0', overflow: 'hidden' }}
            padding={0}
        >
            {chatId &&
                <Chat
                    socketUrl={'/chat/' + chatId + '/'}
                    isProjectBlackboard={true}
                    showCompanyNames={true}
                />
            }
        </CustomPaper>
    )
}

export default ConmeetConnectionsBlackboard
