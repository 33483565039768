import { Bar, XAxis, YAxis, BarChart, ReferenceLine, LabelList } from 'recharts';

import "./ProjectCardBarchart.css"
import { PALETTE } from 'components/theme/index.jsx';
import WarningHint from 'components/Widgets/WarningHint';
import AtCostHint from 'components/Widgets/AtCostHint';

const allLinearGradients = ()=>{

    const COLORSARRAY = [PALETTE.primary.dark, PALETTE.primary.light, PALETTE.secondary.main, PALETTE.secondary.light];
    let gradientArry = []

    for( let i = 0; i < COLORSARRAY.length; i++){
        gradientArry.push(
            <linearGradient key={`cG-${i}`} id={`customGradient-${i}`}>
                <stop offset="2%"  stopColor={PALETTE.background.content} />
                <stop offset="80%" stopColor={COLORSARRAY[i]}/>
            </linearGradient>
        )
    }
    return gradientArry
}

const customIcon = (props) =>{

    const { x, y, fill, height, label } = props;
    const _x = x - 35

    if(label === 'material'){
        return (
            <svg className="customIcon" x={_x} y={y} width={height} height={height} fill={fill} viewBox="0 0 16 16">
                <path d="M8.186 1.113a.5.5 0 0 0-.372 0L1.846 3.5l2.404.961L10.404 2l-2.218-.887zm3.564 1.426L5.596 5 8 5.961 14.154 3.5l-2.404-.961zm3.25 1.7-6.5 2.6v7.922l6.5-2.6V4.24zM7.5 14.762V6.838L1 4.239v7.923l6.5 2.6zM7.443.184a1.5 1.5 0 0 1 1.114 0l7.129 2.852A.5.5 0 0 1 16 3.5v8.662a1 1 0 0 1-.629.928l-7.185 2.874a.5.5 0 0 1-.372 0L.63 13.09a1 1 0 0 1-.63-.928V3.5a.5.5 0 0 1 .314-.464L7.443.184z"/>
            </svg>
        )
    }
    if(label === 'workload'){
        return (
            <svg className="customIcon" x={_x} y={y} width={height} height={height} fill={fill} viewBox="0 0 16 16">
                <path d="M6 .5a.5.5 0 0 1 .5-.5h3a.5.5 0 0 1 0 1H9v1.07a7.001 7.001 0 0 1 3.274 12.474l.601.602a.5.5 0 0 1-.707.708l-.746-.746A6.97 6.97 0 0 1 8 16a6.97 6.97 0 0 1-3.422-.892l-.746.746a.5.5 0 0 1-.707-.708l.602-.602A7.001 7.001 0 0 1 7 2.07V1h-.5A.5.5 0 0 1 6 .5zm2.5 5a.5.5 0 0 0-1 0v3.362l-1.429 2.38a.5.5 0 1 0 .858.515l1.5-2.5A.5.5 0 0 0 8.5 9V5.5zM.86 5.387A2.5 2.5 0 1 1 4.387 1.86 8.035 8.035 0 0 0 .86 5.387zM11.613 1.86a2.5 2.5 0 1 1 3.527 3.527 8.035 8.035 0 0 0-3.527-3.527z"/>
            </svg>
        )
    }
    if(label === 'task'){
        return (
            <svg className="customIcon" x={_x} y={y} width={height} height={height} fill={fill} viewBox="0 0 16 16">
                <path d="M6.5 1A1.5 1.5 0 0 0 5 2.5V3H1.5A1.5 1.5 0 0 0 0 4.5v1.384l7.614 2.03a1.5 1.5 0 0 0 .772 0L16 5.884V4.5A1.5 1.5 0 0 0 14.5 3H11v-.5A1.5 1.5 0 0 0 9.5 1h-3zm0 1h3a.5.5 0 0 1 .5.5V3H6v-.5a.5.5 0 0 1 .5-.5z"/>
                <path d="M0 12.5A1.5 1.5 0 0 0 1.5 14h13a1.5 1.5 0 0 0 1.5-1.5V6.85L8.129 8.947a.5.5 0 0 1-.258 0L0 6.85v5.65z"/>
            </svg>
        )
    }
    if(label === 'total'){
        return (
            <svg className="customIcon" x={_x} y={y} width={height} height={height} fill={fill} viewBox="0 0 16 16">
                <path d="M4 9.42h1.063C5.4 12.323 7.317 14 10.34 14c.622 0 1.167-.068 1.659-.185v-1.3c-.484.119-1.045.17-1.659.17-2.1 0-3.455-1.198-3.775-3.264h4.017v-.928H6.497v-.936c0-.11 0-.219.008-.329h4.078v-.927H6.618c.388-1.898 1.719-2.985 3.723-2.985.614 0 1.175.05 1.659.177V2.194A6.617 6.617 0 0 0 10.341 2c-2.928 0-4.82 1.569-5.244 4.3H4v.928h1.01v1.265H4v.928z"/>
            </svg>
        )
    }
}

const customBarShape = (props) => {

    const {id, x, y, width, height} = props;

    return (
        <svg className="bar">
            <defs>
                {allLinearGradients()}
            </defs>
            <rect
                width={width}
                height={height}
                x={x}
                y={y}
                fill={`url('#customGradient-${id}')`}
                rx="4" ry="4"
            />
        </svg>
    );
}

const ProjectsChart = ({ width, projectValue, currentMaterial, currentWorkloadCost, currentTasksCosts, at_cost=false }) =>{

    const data = [
        {
            name: 'project',
            material: currentMaterial,
            workload: currentWorkloadCost,
            task: currentTasksCosts,
            total: currentMaterial + currentWorkloadCost + currentTasksCosts,
        }
    ];

    const maxError = data[0].total > (projectValue * 1.2)

    return(
        !at_cost ?
            !maxError ?
                projectValue !== 0  ?
                    <BarChart
                        data={data}
                        layout='vertical'
                        margin={{
                            top: 10,
                            right: 10,
                            left: 35,
                            bottom: 10,
                        }}

                        width={width}
                        height={180}
                    >
                        <XAxis type='number' hide='true' domain={[0, dataMax => dataMax < projectValue ? (Math.round(projectValue * 1.2)) : dataMax * 1.2]} />
                        <YAxis type="category" dataKey="name" hide='true'/>
                        <Bar
                            dataKey="material"
                            id='0'
                            fill={PALETTE.primary.dark}
                            shape={customBarShape}
                        >
                            <LabelList
                                label="material"
                                fill={PALETTE.primary.dark}
                                content={customIcon}
                            />
                        </Bar>
                        <Bar
                            dataKey="workload"
                            id='1'
                            fill={PALETTE.primary.light}
                            shape={customBarShape}
                        >
                            <LabelList
                                label="workload"
                                fill={PALETTE.primary.light}
                                content={customIcon}
                            />
                        </Bar>
                        <Bar dataKey="task" id='2' fill={PALETTE.secondary.main} shape={customBarShape}><LabelList label="task" fill={PALETTE.secondary.main}content={customIcon} /></Bar>
                        <Bar dataKey="total" id='3'fill={PALETTE.secondary.light} shape={customBarShape}><LabelList label="total" fill={PALETTE.secondary.light} content={customIcon} /></Bar>
                        {projectValue !== 0 && <ReferenceLine x={projectValue} strokeWidth={1} stroke={PALETTE.background.light15}/>}
                    </BarChart>
                :
                    <WarningHint text="Keine Projektauftragssumme ist hinterlegt!" size={85} height={180}/>
            :
                <WarningHint text="Achtung, der Projektauftrag läuft aus dem Ruder!" size={85} redAlert height={180}/>
        :
            <AtCostHint text="Auftrag wird nach Aufwand berechnet" size={85} height={180}/>
    )

}

export default ProjectsChart