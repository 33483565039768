import { Controller } from 'react-hook-form'
import { Select, MenuItem, FormControl, InputLabel} from '@mui/material'

const ControlledSelect = ({ control, name, rules, errors, variant, label, items, multiple, itemRender, renderValue, disabled=false }) => {
    return (
        <Controller
            control={control}
            name={name}
            rules={rules}
            render={({ field: { value, onChange } }) => (
                <FormControl
                    variant={variant}
                    fullWidth
                    disabled={disabled}
                >
                    <InputLabel>{label}</InputLabel>
                    <Select
                        label={label}
                        variant={variant}
                        fullWidth
                        multiple={multiple}
                        renderValue={renderValue}
                        error={Boolean(errors[name])}
                        onChange={onChange}
                        // empty value if no items
                        value={(!items || Object.keys(items).length === 0) ? '' : value}
                    >
                        {Object.entries(items).map(([key, value], idx) => {
                            return(
                                <MenuItem key={`${label}-key-${idx}`} value={key}>
                                    {itemRender ? itemRender(value) : value}
                                </MenuItem>
                            )
                        })}
                    </Select>
                </FormControl>
            )}
        />
    )
}

export default ControlledSelect
