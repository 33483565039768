import { useContext } from "react"

import { NotificationContext } from "../../../context/NotificationContext"

import NewChats from "./NewChats"
import Contacts from "../../Widgets/Chat/Contacts"
import CustomPage from "../../Widgets/CustomPage"
import { Stack, useMediaQuery } from "@mui/material"
import ConmeetConnectionsBlackboard from "./ConmeetConnectionsBlackboard"

const ConmeetConnections = () => {
    const breakpoint = useMediaQuery('(min-width: 1420px)');

    const [notifications] = useContext(NotificationContext)

    return (
        <CustomPage
            title="CONMEET Netzwerk"
        >
            <Stack
                sx={{ flex: 1 }}
                direction={breakpoint ? 'row' : 'column'}
                alignItems='stretch'
                gap={3}
            >
                <Stack sx={{ flex: '1 0 0', minHeight: '700px' }}>
                    <ConmeetConnectionsBlackboard />
                </Stack>
                <Stack sx={{ flex: '2 0 0', minHeight: '700px' }}>
                    <Contacts
                        contactsURL={'/api/conmeet-connections/chat'}
                        notifications={notifications.conmeet_connections_chat}
                        newChatsComponent={NewChats}
                        deleteChatUrl={'/api/conmeet-connections/chat/'}
                    />
                </Stack>
            </Stack>
        </CustomPage>
    )
}

export default ConmeetConnections
