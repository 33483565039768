import { useEffect, useState } from 'react'
// import CompanyContext from '../../../../context/CompanyContext'

import { Stack } from '@mui/material';

import CustomPage from '../../../Widgets/CustomPage'
import CapacityChart from './CapacityChart';
import ResourceChart from './ResourceChart';


const CompanyStatistic = () => {

    // const [company, companyMembers] = useContext(CompanyContext)
    const [statistic, setStatistic] = useState({
        capacity: {
            chart_data: [],
            fact_hours: 0
        },
        resource: {
            chart_data: [],
        }
    })

    const height = 250
    const barSize = 70
    const maxFactor = 1.2

    const getCompanyStatistic = async () => {
        const res = await fetch('/api/company/statistics', {
            method: 'GET',
        })
        const data = await res.json()
        if (res.ok) {
            setStatistic(data)
            // console.log(data)
        }
    }

    useEffect(()=>{
        getCompanyStatistic()
    },[])

    return(
        <CustomPage title='Unternehmensauslastung'>
            <Stack spacing={3}>
                <CapacityChart
                    title="Auslastung"
                    max={statistic.capacity.total_hours}
                    data={statistic.capacity.chart_data}
                    height={height}
                    maxFactor={maxFactor}
                    unit="Std."
                    barSize={barSize}
                />
                <ResourceChart
                    title="Ressource"
                    data={statistic.resource.chart_data}
                    height={400}
                    unit="h"
                    barSize={barSize}
                />
            </Stack>
        </CustomPage>
    )
}

export default CompanyStatistic