import { useState, useEffect } from 'react'
import { Stack, LinearProgress, Typography, Grid } from '@mui/material'

import ProjectCardWrapperLimited from '../ProjectCardWrapperLimited'
import ProjectIconBar from '../ProjectIconBar'
import '../ProjectsCard.css'
import { formatDateString } from 'utils/date_functions'


const ProjectLeaderCardWithSubs = ({ project }) =>{

    const [swipeIndex, setSwipeIndex] = useState(0)

    useEffect(() => {
        const intervall = setInterval(() => {
            setSwipeIndex(idx => (idx + 1) % project.sub_projects.length)
        }, 10000);
        return () => clearInterval(intervall);
    }, [project.sub_projects.length]);

    return(
        <ProjectCardWrapperLimited
            project={project}
        >
            <Grid
                container
                spacing={1}
            >
                <Grid
                    item
                    desktop={6}
                    labtop={6}
                    tablet={12}
                    mobile={12}
                >
                    <Stack>
                        <Typography variant="caption_s">Projektleitung</Typography>
                        <Typography variant="text_s">{project.company.name}</Typography>
                    </Stack>
                </Grid>
                <Grid
                    item
                    desktop={6}
                    labtop={6}
                    tablet={12}
                    mobile={12}
                >
                    <Stack>
                        <Typography variant="caption_s">Projektadresse</Typography>
                        <Typography variant="text_s"> {`${project.address.street} ${project.address.house_number}, ${project.address.postal_code} ${project.address.city}`}</Typography>
                    </Stack>
                </Grid>
                <Grid
                    item
                    desktop={6}
                    labtop={6}
                    tablet={12}
                    mobile={12}
                >
                    <Stack>
                        <Typography variant="caption_s">Auftraggeber</Typography>
                        <Typography variant="text_s">{project.sub_projects[swipeIndex].customer.name}</Typography>
                    </Stack>
                </Grid>
                <Grid
                    item
                    desktop={6}
                    labtop={6}
                    tablet={12}
                    mobile={12}
                >
                    <Stack>
                        <Typography variant="caption_s">Auftrag</Typography>
                        <Typography variant="text_s">{project.sub_projects[swipeIndex].title}</Typography>
                    </Stack>
                </Grid>
                <Grid
                    item
                    desktop={6}
                    labtop={6}
                    tablet={12}
                    mobile={12}
                >
                    <Stack>
                        <Typography variant="caption_s">Projektzeitraum</Typography>
                        <Typography variant="text_s">
                            {formatDateString(project.expected_to_start)} - {formatDateString(project.expected_to_end)}
                        </Typography>
                    </Stack>
                </Grid>
            </Grid>
            <Stack
                direction='row'
                justifyContent='center'
                alignItems='center'
                gap={5}
            >
                <Stack
                    flexDirection='row'
                    justifyContent='center'
                    alignItems='center'
                    width="100%"
                    gap={2}
                >
                    <LinearProgress
                        sx={{
                            height: 5,
                            width: '100%',
                            borderRadius: "1rem",
                        }}
                        variant="determinate"
                        color="primary"
                        value={project.sub_projects[swipeIndex].progress}
                    />
                    <Typography variant="value" sx={{ width: 50, color: 'background.light10', textAlign:'center'}}>
                        {`${swipeIndex + 1} / ${project.sub_projects.length}`}
                    </Typography>
                </Stack>
                <ProjectIconBar
                    official={project.official}
                    members={project.all_members}
                    companies={project.all_companies}
                />
            </Stack>
        </ProjectCardWrapperLimited>
    )
}

export default ProjectLeaderCardWithSubs