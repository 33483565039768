import { useContext, useState } from 'react'
import { matchPath } from 'react-router-dom'

import { Stack } from '@mui/material'

import NavList from './NavList'

import theme, { PALETTE, THEME_SETTINGS } from '../theme'
import { ICONSIZE } from '../NavBar/Data/Default'
import ProjectContext from 'context/ProjectContext'


export const SETTINGS = {
    transitionDuration: '0.1s',
    collapsedWidth: 48 + 16,
    openWidth: 200,
    listPadding: 1,
    listItemGap: 0.5,
    itemContentGap: 2,
    itemHeight: '48px',
    itemBorderRadius: 2,
    iconSize: ICONSIZE,
    fontSize: '16px',
    colors: {
        mainNav: 'background.content',
        // mainNav: 'background.main',
        mainActiveBackground: '#e8f1f1',
        hoverBackground: 'background.light80',
        textColor: 'text.secondary',
        iconActive: 'primary.main'
    }
}

const ProjectToolbar = ({ data, basePath }) => {
    const match = matchPath(basePath, window.location.pathname)
    const [ collapsed, setCollapsed ] = useState(true)
    const [, projectMember] = useContext(ProjectContext)

    return (
        <Stack
            onMouseEnter={() => setCollapsed(false)}
            onMouseLeave={e => {
                if (e.clientX < 0) return
                setCollapsed(true)
            }}
            direction='column'
            sx={{
                backgroundColor: SETTINGS.colors.subNav,
                boxShadow: THEME_SETTINGS.boxShadow,
                zIndex: 999,
                minHeight: 0,
                maxHeight: '100vh',
                position: 'fixed',
                top: '50%',
                transform: 'translateY(-50%)',
                borderTopRightRadius: 8,
                borderBottomRightRadius: 8,
                overflow: 'hidden'
            }}
        >
            <Stack
                direction='row'
                sx={{
                    backgroundColor:
                    SETTINGS.colors.mainNav,
                    color: SETTINGS.colors.textColor,
                    flex: 1,
                    overflow: 'hidden'
                }}
            >
                <Stack
                    sx={{ backgroundColor: SETTINGS.colors.mainNav }}
                    justifyContent='space-between'
                    alignItems='center'
                >
                    <NavList
                        items={data[1]}
                        SETTINGS={SETTINGS}
                        match={match}
                        collapsed={collapsed}
                        openWidth={SETTINGS.openWidth}
                        activeBackground={SETTINGS.colors.mainActiveBackground}
                        sx={{
                            "::-webkit-scrollbar-thumb": {
                                background: SETTINGS.colors.hoverBackground,
                                borderRadius: '5px',
                            },
                        }}
                        projectMember={projectMember}
                    />
                </Stack>
            </Stack>
        </Stack>
    )
}

export default ProjectToolbar
