import { useCallback, useEffect, useState } from "react";
import { useGridApiRef } from "@mui/x-data-grid-pro";
import DialogPaginationDataGrid from "../../../Widgets/CustomDatagrids/Pagination/DialogPaginationDataGrid"
import { formatPrice, formatValueWithUnit, getFormattedDate } from "./WarehouseTableUtils";


const WarehouseReservationTable = (props) =>{

    const {
        open,
        onClose,
		subtitle,
		handleTXPatch,
		handleTXDelete
    } = props

	const [searchTerm, setSearchTerm] = useState('');
	const [orderBy, setOrderBy] = useState('');
    const apiRef = useGridApiRef();
	const [isLoading, setIsLoading] = useState(false)
    const [txs, setTxs] = useState([])
    const [pageSize, setPageSize] = useState(50)
    const [pagInfo, setPagInfo] = useState({
		count: 0,
		next : null,
		previous: null,
	});

    const handlePageChange = (x) =>{
		getTx(pagInfo[x])
	}

	const handleOrderByGetParametersChange = params => {
		setOrderBy(params)
	}

    const getTx = useCallback(async(url) => {
		if(open === false) return
		setIsLoading(true)
        const res = await fetch(url ? url :'/api/company/warehouse/tx/4?search=' + searchTerm +'&limit=' + pageSize + '&' + orderBy, {
            method: 'GET'
        })
		const data = await res.json()
		if(res.ok){
			setTxs(data.results)
			setPagInfo({
				count: data.count,
				next : data.next,
				previous: data.previous,
			})
			// console.log(data)
		}
		setIsLoading(false)
    }, [pageSize, open, searchTerm, orderBy])

    useEffect(()=>{
		getTx()
	}, [open, getTx, pageSize])


	const columns = [
		{
			field: 'custom_id',
			headerName: 'R-ID',
			type: 'string',
			minWidth: 50,
			editable: false,
			valueFormatter: (params) => {
				if (params.value == null) {
				  return '';
				}
				return `R#${params.value}`;
			},
			sortable: false,
		},
		{
			field: 'unique_material_id',
			headerName: 'Artikelnummer',
			type: 'string',
			minWidth: 120,
			editable: false,
			sortable: false,
		},
		{
			field: 'description',
			headerName: 'Artikelbezeichnung',
			type: 'string',
			minWidth: 350,
			editable: false,
			sortable: false,
		},
		{
			field: 'value',
			headerName: 'Menge',
			type: 'number',
			minWidth: 120,
			editable: true,
			valueFormatter: formatValueWithUnit,
		},
		{
			field: 'annotation',
			headerName: 'Bemerkung',
			type: 'string',
			flex:'1',
			minWidth: 80,
			editable: false,
			sortable: false,
		},
        {
			field: 'sub_project_name',
			headerName: 'Projektauftrag',
			type: 'string',
			minWidth: 250,
			editable: false,
			sortable: false,
		},
		{
			field: 'creator',
			headerName: 'Ersteller',
			type: 'string',
			minWidth: 150,
			editable: false,
			sortable: false,
		},
		{
			field: 'created',
			headerName: 'reserviert am',
			minWidth: 200,
			editable: false,
			valueGetter: getFormattedDate,
		},
	]

	const getConfirmMessage = row => `Möchtest du die Reservierung wirklich löschen?`

    return(
        <DialogPaginationDataGrid
            open={open}
            onClose={onClose}
            title="Lagerreservierungen"
            subtitle={subtitle}
            apiRef={apiRef}
            rows={txs}
            pageSize={pageSize}
            setPageSize={setPageSize}
            rowCount={pagInfo.count}
            handlePageChange={handlePageChange}
            columns={columns}
            loading={isLoading}
			searchTerm={searchTerm}
			setSearchTerm={setSearchTerm}
			onSaveRow={handleTXPatch}
			onDeleteRow={handleTXDelete(getTx)}
			getConfirmMessage={getConfirmMessage}
			onOrderByGetParametersChange={handleOrderByGetParametersChange}
        />
    )
}

export default WarehouseReservationTable
