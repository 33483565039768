import { Stack } from '@mui/material';

import CustomPage from '../../Widgets/CustomPage'
import BuisnessCustomerTable from './BuisnessCustomerTable';
import PrivateCustomerTable from './PrivateCustomerTable';


const CompanyCustomer = () => {

    return(
        <CustomPage title='Kunden'>
            <Stack spacing={3}>
				<PrivateCustomerTable/>
				<BuisnessCustomerTable/>
            </Stack>
        </CustomPage>
    )
}

export default CompanyCustomer