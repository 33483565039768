import { useState, useContext, useEffect, useCallback } from "react"

import { Box, Grid } from "@mui/material";

import ProjectContext from '../../../../context/ProjectContext'
import ProjectEventCard from "./ProjectEventCard";
import NoData from "components/Widgets/NoData";


const ProjectNotes = () => {

    const [project] = useContext(ProjectContext)
    const [projectEvents, setProjectEvents] = useState([])
    // const [eventsLoaded, setEventsLoaded] = useState(false)

    const getProjectEvents = useCallback( async() =>{
        const res = await fetch('/api/project/' + project.id + '/events', {
            method: 'GET'
        })
        const _data = await res.json()
        if(res.ok){
            setProjectEvents(_data)
            // setEventsLoaded(true)
        }
    }, [project.id])


    useEffect(() =>{
        getProjectEvents()
    }, [getProjectEvents])

    return (
        <Box>
            <Grid
                container
                justifyContent='flex-start'
                alignItems='flex-start'
                height="100%"
                spacing={3}
            >
                {projectEvents.length !== 0 ?
                    projectEvents.map((event, idx)=> {
                        return (
                            <Grid
                                key={`event-grid-item-${idx}`}
                                item
                                desktop={4}
                                laptop={6}
                                tablet={12}
                                mobile={12}
                            >
                                <ProjectEventCard key={`project-event-card-${idx}`} event={event}/>
                            </Grid>
                        );})
                    :
                        <Grid
                            item
                            desktop={12}
                            laptop={12}
                            tablet={12}
                            mobile={12}
                        >
                            <NoData text="Du hast derzeit keine anstehenden Projekttermine..."/>
                        </Grid>
                }
            </Grid>
        </Box>
    );
}

export default ProjectNotes