import { Stack, Box, Typography, LinearProgress } from '@mui/material';

import CustomPaper from "../../../../Widgets/CustomPaper/index.jsx";

import { formatDateString } from '../../../../../utils/date_functions.js';


const SubProjectInfoCard = ({ projectStatic }) => {

    return(
        <CustomPaper
            title="Auftragsbeschreibung"
        >
            <Stack
                direction='row'
                justifyContent='space-between'
                alignItems='center'
                spacing={3}
            >
                <Stack
                    spacing={1}
                    width="100%"
                >
                    <Box
                        sx={{
                            height: 80,
                            overflow: 'auto',
                        }}
                    >
                        <Typography
                            variant="text_m_justify"
                        >
                            {projectStatic.instruction}
                        </Typography>
                    </Box>
                    <Typography variant="text_s">
                        {`Auftragszeitraum: ${formatDateString(projectStatic.expected_to_start)} - ${formatDateString(projectStatic.expected_to_end)}`}
                    </Typography>
                    <LinearProgress
                        color='primary'
                        variant="determinate"
                        value={projectStatic.progress}
                        sx={{ width: '100%'}}
                    />
                </Stack>
            </Stack>
        </CustomPaper>
    )
}

export default SubProjectInfoCard