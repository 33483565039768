import { useEffect, useRef, useState } from "react"
import useStyledSnackbar from "hooks/useStyledSnackbar";
import { useEffectDebugger } from "utils/devtools";


const RECONNECT_LIMIT = 100

const useWebSocket = ({
    host = process.env.NODE_ENV === 'development' ?
        window.location.hostname + ':8000'
        :
        window.location.hostname,
    path,
    onOpen,
    onClose,
    onMessage,
    onMessageJson,
    connectionLostMessage
}) => {
    const wsScheme = window.location.protocol === 'https:' ? 'wss' : 'ws'
    const socketUrl = wsScheme + '://' + host + path

    const ws = useRef(null)

    const [reconnect, setReconnect] = useState(false)
    const reconnectLimit = useRef(RECONNECT_LIMIT)
    const [connectionLost, setConnectionLost] = useState(false)
	const { enqueueSnackbar, closeSnackbar } = useStyledSnackbar()


    useEffect(() => {
        ws.current = new WebSocket(socketUrl)

        const wsCurrent = ws.current
        return () => {
            wsCurrent.close()
        }
    }, [socketUrl, reconnect])

    useEffectDebugger(() => {
        let isMounted = true
        let opened = false
        ws.current.onopen = () => {
            opened = true
            reconnectLimit.current = RECONNECT_LIMIT
            onOpen && onOpen()
            setConnectionLost(false)
        }
        ws.current.onclose = e => {
            onClose && onClose()
            if (isMounted && opened) {
                setConnectionLost(true)
            }
            setTimeout(() => {
                if (isMounted && reconnectLimit.current > 0) {
                    setReconnect(x => !x)
                    reconnectLimit.current -= 1
                }
            }, 2000)
        }
        ws.current.onmessage = e => {
            onMessage && onMessage(e)

            let json = ''
            try {
                json = JSON.parse(e.data)
            } catch {
                console.log(socketUrl + ' couldn\'t parse JSON')
            }
            onMessageJson && onMessageJson(e, json)
        }
        return () => {
            isMounted = false
        }
    }, [socketUrl, onOpen, onClose, onMessage, onMessageJson, reconnect, reconnectLimit])

    useEffect(() => {
        let snackbar = null
        if (connectionLost && connectionLostMessage) {
            snackbar = enqueueSnackbar(connectionLostMessage, { variant: 'error', persist: true })
        }

        return () => {
            snackbar && closeSnackbar(snackbar)
        }
    }, [connectionLost])

    const sendMessage = m => {
        if (ws.current.readyState !== WebSocket.OPEN) return
        ws.current.send(m)
    }

    const sendJsonMessage = m => {
        sendMessage(JSON.stringify(m))
    }

    return {
        sendMessage,
        sendJsonMessage,
    }
}

export default useWebSocket
