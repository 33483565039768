import { Stack, Typography, Tooltip, Divider } from '@mui/material';

import { BsFillCaretDownFill, BsFillCaretUpFill, BsDash, BsCreditCard, BsArrowRightShort } from "react-icons/bs";

import CustomPaper from "../../../../Widgets/CustomPaper/index.jsx";
import { PALETTE } from '../../../../theme/index.jsx';

import { formatNumber } from '../../../../../utils/helper.js';


const FinanceCard = ( props ) =>{

    const { currentMaterial, currentWorkloadCosts, currentTask, marge, total } = props

    return(
        <CustomPaper
            title="Kostenermittlung"
            actionBar={
                <>
                    {marge < 0 ? <BsFillCaretDownFill color={PALETTE.secondary.main} size={35}/> :  <BsFillCaretUpFill color={PALETTE.primary.main} size={35}/>}
                </>
            }
        >
            <Stack
                direction='row'
                justifyContent='space-between'
                alignItems='center'
            >
                <BsCreditCard color={PALETTE.background.main} size={25}/>
                <Tooltip
                    title="Auftragsvolumen"
                    arrow
                >
                    <Typography variant="value" color={PALETTE.background.main}>{formatNumber(total,2,'€')}</Typography>
                </Tooltip>
            </Stack>
            <Stack
                direction='row'
                justifyContent='space-between'
                alignItems='center'
            >
                <BsDash color={PALETTE.primary.dark} size={25}/>
                <Tooltip
                    title="Materialkosten"
                    arrow
                >
                    <Typography variant="value" color={PALETTE.primary.dark}>{formatNumber(currentMaterial, 2,'€')}</Typography>
                </Tooltip>
            </Stack>
            <Stack
                direction='row'
                justifyContent='space-between'
                alignItems='center'
            >
                <BsDash color={PALETTE.primary.main} size={25}/>
                <Tooltip
                    title="Lohnkosten inkl. Fahrtkosten"
                    arrow
                >
                    <Typography variant="value" color={PALETTE.primary.main}>{formatNumber(currentWorkloadCosts, 2,'€')}</Typography>
                </Tooltip>
            </Stack>
            <Stack
                direction='row'
                justifyContent='space-between'
                alignItems='center'
            >

                <BsDash color={PALETTE.primary.light} size={25}/>
                <Tooltip
                    title="Vergebene Aufträge"
                    arrow
                >
                    <Typography variant="value" color={PALETTE.primary.light}>{formatNumber(currentTask, 2,'€')}</Typography>
                </Tooltip>
            </Stack>
            <Divider/>
            <Stack
                direction='row'
                justifyContent='space-between'
                alignItems='center'
            >
                {marge < 0 ? <BsArrowRightShort color={PALETTE.secondary.main} size={25}/> :  <BsArrowRightShort color={PALETTE.primary.main} size={25}/>}
                <Tooltip
                    title="Marge"
                    arrow
                >
                    <Typography variant="value" color= {marge < 0 ? PALETTE.secondary.main :  PALETTE.primary.main}>{formatNumber(marge, 2,'€')}</Typography>
                </Tooltip>
            </Stack>
        </CustomPaper>
    )
}

export default FinanceCard