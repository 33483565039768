import { NavbarARCH, ProjectToolbarARCH } from './ARCH'
import { NavbarGU, ProjectToolbarGU } from './GU'
import { NavbarHWU, ProjectToolbarHWU } from './HWU'

export const PACKAGE_KEYS = {
    'Default': 0,
    'ARCH': 1,
    'GU': 2,
    'HWU': 3,
}

export const NAVBARS = {
    0: [[]],
    1: NavbarARCH,
    2: NavbarGU,
    3: NavbarHWU,
}

export const PROJECT_TOOLBARS = {
    0: [[]],
    1: ProjectToolbarARCH,
    2: ProjectToolbarGU,
    3: ProjectToolbarHWU,
}

