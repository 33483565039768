import React, { useContext } from 'react';
import { Navigate } from 'react-router-dom';
import AccountContext from '../../context/AccountContext'

const CheckPackageKey= ({children, redirectTo, packageKeys }) => {
    const [account] = useContext(AccountContext)

    return packageKeys.includes(account.company.package_key) ?
        children
        : <Navigate to={redirectTo} />
};

export default CheckPackageKey