import { Stack, Box, Divider, Grid, Typography } from '@mui/material'
import { formatTimestamp } from '../../../../utils/date_functions'

import '../ProjectsCard.css'

const ProjectCardWrapperSR = ({ project, children}) => {

    return(
        <Stack
            className="project-search-request-card"
            spacing={1}
        >
            <Box
                component="img"
                alt="image not found"
                src={project.img}
                sx={{ p:1, height: 180}}
            />
            <Stack>
                <Typography variant="subtitle_s" color='secondary.main'>Projekt</Typography>
                <Typography variant="subtitle_m" color='background.main'>{project.name}</Typography>
            </Stack>
            <Divider sx={{ mb:1, mt:1 }}/>
            <Grid
                container
                spacing={1}
            >
                <Grid
                    item
                    desktop={12}
                    labtop={12}
                    tablet={12}
                    mobile={12}
                >
                    <Stack>
                        <Typography variant="caption_s" color="primary.main">Projektleitung</Typography>
                        <Typography variant="text_m">{project.company.name}</Typography>
                    </Stack>
                </Grid>
                <Grid
                    item
                    desktop={6}
                    labtop={6}
                    tablet={12}
                    mobile={12}
                >
                    <Stack>
                        <Typography variant="caption_s" color="primary.main">Projektadresse</Typography>
                        <Typography variant="text_m">{project.address.street} {project.address.house_number}, {project.address.postal_code} {project.address.city}</Typography>
                    </Stack>
                </Grid>

                <Grid
                    item
                    desktop={6}
                    labtop={6}
                    tablet={6}
                    mobile={12}
                >
                    <Stack>
                        <Typography variant="caption_s" color="primary.main">erstellt am</Typography>
                        <Typography variant="text_m">{formatTimestamp(project.created)}</Typography>
                    </Stack>
                </Grid>
            </Grid>
            {children}
        </Stack>
    )
}

export default ProjectCardWrapperSR