import { useState } from "react"

import {
    List,
    Collapse,
    Typography,
    ListItemText,
    ListItemAvatar,
    Avatar, Stack,
    ListItemButton
} from '@mui/material';
import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';


const NewChats = ({ contacts, account, handleListItemClick, selectedContact }) => {
    const [open, setOpen] = useState({
        0: contacts.length === 1
    })

    const toggleOpen = id => setOpen(oldOpen => ({...oldOpen, [id]: !oldOpen[id]}))

    return (
        <Stack sx={{ height: '100%' }} className="NewChats">
            <Typography variant='h6' sx={{ textAlign: 'center', padding: '5px' }}>Neuen Chat starten</Typography>
            <List sx={{ overflow: 'auto' }}>
                {contacts.map((company, i) => {
                    const newContacts = company.members.filter(contact => contact.id !== account.id && contact.chat == null)
                    return (
                        <div key={i}>
                            <ListItemButton onClick={() => toggleOpen(i)} >
                                <ListItemText primary={company.name} />
                                {open[i] ? <ExpandLess /> : <ExpandMore />}
                            </ListItemButton>
                            <Collapse in={open[i]}>
                                <List>
                                    {newContacts.length === 0 ?
                                        <ListItemText primary={'Keine neuen Kontakte'} sx={{ paddingLeft: 4 }} />
                                        :
                                        newContacts.map((contact, i) => {
                                        return (
                                            <ListItemButton
                                                key={i}
                                                onClick={ e => handleListItemClick(e, contact)}
                                                selected={selectedContact === contact}
                                            >
                                                <ListItemAvatar>
                                                    <Avatar alt={contact.firstname + ' ' + contact.lastname} src={contact.avatar} />
                                                </ListItemAvatar>
                                                <ListItemText primary={contact.firstname + ' ' + contact.lastname} />
                                            </ListItemButton>
                                        )
                                    })}
                                </List>
                            </Collapse>
                        </div>
                    )
                })}
            </List>
        </Stack>
    )
}

export default NewChats