import { useRef } from 'react';

import debounce from 'lodash.debounce'


const useDebounce = (func, wait=200) => {
    const debounced = useRef(debounce(func, wait))

    return debounced.current
}

export default useDebounce
