import { Stack, IconButton, Tooltip } from '@mui/material';
import { FilterAlt, Add  } from '@mui/icons-material';
import AddDialog from "./AddDialog";
import {useState} from "react";
import TooltipIconButton from "../../../../Widgets/TooltipIconButton";


const CustomPrivateCustomerToolbar = ({ apiRef, getCustomers, genders }) => {
    const [ open, setOpen ] = useState(false)

    return(
        <Stack
            direction='row'
        >
            <TooltipIconButton
                title='Neuer Kunde'
                onClick={() => setOpen(true)}
                icon={<Add />}
            />
            <AddDialog
                open={open}
                onClose={() => setOpen(false)}
                getCustomers={getCustomers}
                genders={genders}
            />
        </Stack>
    )
}

export default CustomPrivateCustomerToolbar