import { Controller } from 'react-hook-form'

import { Checkbox, FormControlLabel } from '@mui/material'

const ControlledCheckbox = ({ control, name, label, disabled=false }) => {
    return (
        <FormControlLabel
            control={
            <Controller
                name={name}
                control={control}
                render={({field: { value, onChange }}) => (
                <Checkbox
                    color="primary"
                    checked={value}
                    onChange={(e) => onChange(e.target.checked)}
                    disabled={disabled}
                />
                )}
            />
            }
            label={label}
        />
    )
}

export default ControlledCheckbox