import { useState, Fragment } from 'react'

import { IconButton, Stack, Collapse, Avatar, Typography } from '@mui/material'
import DownloadForOfflineIcon from '@mui/icons-material/DownloadForOffline';
import ReplyIcon from '@mui/icons-material/Reply';
import ChatBubbleIcon from '@mui/icons-material/ChatBubble';

import VideoPopup from '../Widgets/VideoPopup';
import ImagePopup from '../Widgets/ImagePopup'

import './Message.scss'

import { formatTimestamp } from '../../utils/date_functions'
import { MdGroups, MdPublic } from 'react-icons/md';
import { PALETTE } from 'components/theme';
import { BsFillMegaphoneFill } from 'react-icons/bs';

const Message = ({
    message,
    messages,
    account,
    isProjectBlackboard,
    handleReplyClick,
    isEmbeddedReply,
    showPublicSymbol,
    showCompanyName=false
}) => {
    const [ showReplies, setShowReplies ] = useState(false)

    const replies = isProjectBlackboard ?
        messages?.slice(0)?.reverse().filter(m2 => m2?.reply_to?.id === message.id)
        :
        null

    return (
        <div
            className={
                (isProjectBlackboard ? 'ProjectBlackboardMessage' : 'Message') +
                (message.company_member_id === account.id ? ' right' : ' left') +
                ' type' + message.msg_type +
                (isEmbeddedReply ? ' embedded-reply' : '')
            }
        >
            {isProjectBlackboard &&
                <div className="header">
                    <Stack direction='row' gap={1} alignItems='center'>
                        <Avatar src={message.avatar} />
                        <Stack>
                            <Typography variant='subtitle_s'>{message.firstname} {message.lastname}</Typography>
                            {showCompanyName && <Typography variant='text_s'>{message.company_name}</Typography>}
                            <Typography variant='text_s'>{formatTimestamp(message.timestamp)}</Typography>
                        </Stack>
                    </Stack>

                    {showPublicSymbol && message.public && <MdGroups size={26} style={{ color: PALETTE.primary.main }} />}
                </div>
            }
            <div className='bubble'>
                {(!isEmbeddedReply && !isProjectBlackboard && 'reply_to' in message) &&
                    <Message message={message.reply_to} account={account} isProjectBlackboard={isProjectBlackboard} isEmbeddedReply={true} />
                }
                {message.msg_type === 1 && <ImagePopup src={message.file_url} />}
                {message.msg_type === 2 && <VideoPopup src={message.file_url} />}
                {message.msg_type === 3 &&
                    <a href={message.file_url} target="_blank" rel="noreferrer">
                        <DownloadForOfflineIcon />
                        <span>Download</span>
                    </a>
                }
                <Typography variant="message">{message.message}</Typography>
                {!isProjectBlackboard && <span className='timestamp'>{formatTimestamp(message.timestamp)}</span>}
            </div>
            {!isEmbeddedReply &&
                <div className="buttons">
                    {isProjectBlackboard &&
                        <div>
                            <IconButton onClick={() => {
                                setShowReplies(old => !old)
                            }}>
                                <ChatBubbleIcon/>
                            </IconButton>
                            <span>{replies && replies.length}</span>
                        </div>
                    }
                    <IconButton className='reply-button' onClick={() => handleReplyClick(message)}>
                        <ReplyIcon/>
                    </IconButton>
                </div>
            }
            {isProjectBlackboard &&
                <Collapse className="replies" in={showReplies}>
                    <Stack gap={2} direction='column-reverse'>
                        {replies?.map(m2 => {
                            if (m2?.reply_to?.id !== message.id) return null

                            return (
                                <Fragment key={m2.id}>
                                    {/* <Divider sx={{mb: '10px'}} /> */}
                                    <Message
                                        message={m2}
                                        account={account}
                                        isProjectBlackboard={isProjectBlackboard}
                                        handleReplyClick={handleReplyClick}
                                        isEmbeddedReply={true}
                                    />
                                </Fragment>
                            )
                        })}
                    </Stack>
                </Collapse>
            }
        </div>
    )
}

export default Message
