import { useState, useEffect } from "react";
import { useForm } from "react-hook-form";
import { Dialog, DialogContent, DialogTitle, DialogActions, DialogContentText, Button, Stack } from "@mui/material"

import { getCookie } from "../../../../../utils/getCookie";

import ControlledSelect from "../../../../Widgets/ControlledInputs/ControlledSelect";


const AddAgreementsDialog = ({ open, onClose, project_id, getProjectAgreements }) =>{

    const [projectCompanies, setProjectCompanies] = useState({})
    const { control, handleSubmit, reset, formState: { errors, isDirty, isValid }} = useForm({
        mode: 'onChange',
        defaultValues:{
            partner_b:'',
        }
    });

    const handleClose = () =>{
        onClose()
        reset()
    }

    const onSubmit = async(data) => {
        const json = JSON.stringify(data)
        const res = await fetch('/api/project/' + project_id + '/agreements', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'X-CSRFToken': getCookie('csrftoken')
            },
            body: json
        })
        const _data = await res.json()
        if (res.ok) {
            getProjectAgreements()
            handleClose()
        } else{
            console.log(_data)
        }
    }

    useEffect(() => {
        const getProjectCompanies = async() => {
            const res = await fetch('/api/project/' + project_id + '/companies', {
                method: 'GET',
            })
            if (res.ok) {
                const data = await res.json()
                setProjectCompanies(data)
            }
        }
        getProjectCompanies()
    }, [project_id])

    return(
        <Dialog
            maxWidth="tablet"
            open={open}
            onSubmit={handleSubmit(onSubmit)}
            onClose={handleClose}
        >
            <form>
                <DialogTitle>
                    Neue Vereinbarung
                </DialogTitle>
                <DialogContent>
                    <Stack spacing={2}>
                        <DialogContentText>
                            Hier kannst du eine Vereinbarung mit einer anderen Unternehmen im Projekt gemeinsam erstellen.
                        </DialogContentText>
                        <ControlledSelect
                            control={control}
                            name="partner_b"
                            label="Vereinbarung mit"
                            items={projectCompanies}
                            errors={errors}
                        />
                    </Stack>
                </DialogContent>
                <DialogActions>
                    <Button
                        color="secondary"
                        onClick={handleClose}
                    >
                        Abbrechen
                    </Button>
                    <Button
                        type="submit"
                        color="primary"
                        disabled={!isValid || !isDirty}
                    >
                        Erstellen
                    </Button>
                </DialogActions>
            </form>
        </Dialog>
    )
}

export default AddAgreementsDialog