/* eslint-disable no-mixed-operators */
import IconButton from '@mui/material/IconButton';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import DownloadIcon from '@mui/icons-material/Download';
import VisibilityIcon from '@mui/icons-material/Visibility';
import { faFileImage, faFilePdf, faFile } from "@fortawesome/free-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {RiErrorWarningFill} from "react-icons/ri";
import {Stack} from "@mui/material";

import Tooltip from "./Tooltip";
import SettingsButton from "./SettingsButton";
import { FILE_TYPE_EXTENSIONS } from '.';
import { getFileExtension } from 'utils/file_extension';
import { fileNameFromUrl } from 'utils/file_name_from_url';
import { PALETTE } from 'components/theme';


const FileList = ({ files, getUploads, popupFile, popupExt, handleOpenFile, handleCloseFile, handleOpenSettings }) => {

    return (
        <Table>
            <TableHead>
                <TableRow>
                    <TableCell></TableCell>
                    <TableCell>Name</TableCell>
                    <TableCell>Beschreibung</TableCell>
                    <TableCell>Hochgeladen durch</TableCell>
                    <TableCell>Zeitstempel</TableCell>
                    <TableCell></TableCell>
                </TableRow>
            </TableHead>
            <TableBody>
                {files.map(f => {
                    const ext = '.' + getFileExtension(f.file)
                    return (
                        <Tooltip document={f} key={f.id}>
                            <TableRow>
                                <TableCell>
                                    <Stack
                                        direction='row'
                                        alignItems='center'
                                        gap={1}
                                        sx={{
                                            height: 22
                                        }}
                                    >
                                        {/* <FontAwesomeIcon */}
                                        {/*     size='lg' */}
                                        {/*     icon={ */}
                                        {/*         ['jpg', 'png'].includes(extension) && faFileImage || */}
                                        {/*         'pdf' === extension && faFilePdf || */}
                                        {/*         faFile */}
                                        {/*     } */}
                                        {/* /> */}
                                        {f.outdated && <RiErrorWarningFill size={22} color={PALETTE.error.main}/>}
                                    </Stack>
                                </TableCell>
                                <TableCell>{fileNameFromUrl(f.file)}</TableCell>
                                <TableCell>{f.description}</TableCell>
                                <TableCell>{f.company_member.firstname} {f.company_member.lastname}</TableCell>
                                <TableCell>{new Date(f.created).toLocaleString()}</TableCell>
                                <TableCell sx={{ p: 0 }}>
                                    <IconButton
                                        color='primary'
                                        {...(FILE_TYPE_EXTENSIONS.images.includes(ext) ||
                                            FILE_TYPE_EXTENSIONS.videos.includes(ext) ?
                                            {}
                                            :
                                            {
                                                href: f.file,
                                                target: '_blank'
                                            }
                                        )}
                                        onClick={() => handleOpenFile(f)}
                                    >
                                        <VisibilityIcon fontSize='inherit' />
                                    </IconButton>
                                    <SettingsButton document={f} onOpen={handleOpenSettings} color='primary' />
                                </TableCell>
                            </TableRow>
                        </Tooltip>
                    )
                })}
            </TableBody>
        </Table>
    )
}

export default FileList
