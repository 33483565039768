import { useState, useContext, useEffect, useCallback } from "react"

import ProjectContext from '../../../../context/ProjectContext'

import { Box, Stack, Grid, IconButton, Typography, Button, Divider, List, ListItem, ListItemButton, Tooltip } from "@mui/material";
import { Add, Delete } from "@mui/icons-material";
import { FaHandshake } from "react-icons/fa";
import { BsFillLockFill, BsUnlockFill } from "react-icons/bs";

import { getCookie } from "../../../../utils/getCookie";
import { PALETTE } from "../../../theme";

import CustomPaper from "../../../Widgets/CustomPaper";
import AggreementItem from "./AgreementItem";
import AddAgreementsDialog from "./AddAgreementsDialog";
import AddAgreementsItemDialog from "./AddAgreementItemDialog";

import { formatTimestamp } from "../../../../utils/date_functions";
import { projectAgreementText } from "../../../../utils/texts";


const ProjectAgreements = () => {

    const ICONSIZE = 30
    const [project] = useContext(ProjectContext)
    const [agreements, setAgreements] = useState([])
    const [isLoaded, setIsLoaded] = useState(false)
    const [open, setOpen] = useState(false)
    const [openAgreementItemDialog, setOpenAgreementItemDialog] = useState(false)
    const [selectedCarrier, setSelectedCarrier] = useState(null)

    const getProjectAgreements = useCallback( async() =>{
        const res = await fetch('/api/project/' + project.id + '/agreements', {
            method: 'GET'
        })
        const data = await res.json()
        if(res.ok){
            setAgreements(data)
            setIsLoaded(true)
        }
    }, [project.id])


    useEffect(() =>{
        getProjectAgreements()
    }, [getProjectAgreements])

    const handleOpenAgreementItemDialog = (id) =>{
        setSelectedCarrier(id)
        setOpenAgreementItemDialog(true)
    }

    const handleDeleteProjectAgreement = async(carrier_id) =>{
        const body ={
            carrier_id: carrier_id
        }
        const res = await fetch('/api/project/' + project.id + '/agreements', {
            method: 'DELETE',
            headers: {
                'Content-Type': 'application/json',
                'X-CSRFToken': getCookie('csrftoken')
            },
            body: JSON.stringify(body),
        })
        // const data = await res.json()
        if(res.ok){
            getProjectAgreements()
        }
    }

    const handleLockProjectAgreement = async(carrier_id) =>{
        const body ={
            carrier_id: carrier_id
        }
        const res = await fetch('/api/project/' + project.id + '/agreements', {
            method: 'PATCH',
            headers: {
                'Content-Type': 'application/json',
                'X-CSRFToken': getCookie('csrftoken')
            },
            body: JSON.stringify(body),
        })
        // const data = await res.json()
        if(res.ok){
            getProjectAgreements()
        }
    }

    if(!isLoaded){
        return(
            <></>
        )
    }

    if(agreements.length === 0){
        return(
            <Stack
                alignItems='center'
                spacing={0}
            >
                <Stack
                    width={440}
                    alignItems='center'
                    sx={{
                        p:5,
                        border: 1,
                        borderRadius: '10px',
                        borderColor: PALETTE.background.light70
                    }}
                >
                    <Typography variant="subtitle_m">
                        Projektvereinbarungen
                    </Typography>
                    <FaHandshake color={PALETTE.primary.main} size={150}/>
                    <Typography variant="text_m_justify">
                         {projectAgreementText}
                    </Typography>
                    <Button
                        color="primary"
                        variant="outlined"
                        size="large"
                        startIcon={<Add/>}
                        onClick={()=>{setOpen(true)}}
                        fullWidth
                        sx={{marginTop: 3}}
                    >
                        Neue Vereinbarung
                    </Button>
                </Stack>
                <AddAgreementsDialog
                    open={open}
                    onClose={()=>setOpen(false)}
                    project_id={project.id}
                    getProjectAgreements={getProjectAgreements}
                />
            </Stack>
        )
    }
    return (
        <Stack
            alignItems='flex-end'
        >
            <Button
                color="primary"
                variant="text"
                size="large"
                startIcon={<Add/>}
                onClick={()=>{setOpen(true)}}
                sx={{marginBottom: 1}}
            >
                Neue Vereinbarung
            </Button>
            <Grid
                container
                spacing={3}
            >
                {agreements.map((agreeCarrier, idx)=>{
                    return(
                        <Grid
                            item
                            key={`agreement-grid-${idx}`}
                            desktop={6}
                            laptop={6}
                            tablet={12}
                            mobile={12}
                        >
                            <CustomPaper
                                key={`agreement-carrier-${idx}`}
                                title={`#Vereinbarung`}
                                actionBar={
                                    <Tooltip
                                        title="Vereinbarung löschen"
                                    >
                                        <span>
                                            <IconButton
                                                onClick={()=>handleDeleteProjectAgreement(agreeCarrier.id)}
                                                disabled={agreeCarrier.locked_partner_a || agreeCarrier.locked_partner_b}
                                                color="error"
                                            >
                                                <Delete/>
                                            </IconButton>
                                        </span>
                                    </Tooltip>
                                }
                            >
                                <Stack
                                    spacing={1}
                                >
                                    <Stack
                                        alignItems='center'
                                    >
                                        <Typography variant="subtitle_m" sx={{textAlign:'center'}}>
                                            {agreeCarrier.partner_a_name}
                                        </Typography>
                                        <FaHandshake color={PALETTE.primary.main} size={30}/>
                                        <Typography variant="subtitle_m" sx={{textAlign:'center'}}>
                                            {agreeCarrier.partner_b_name}
                                        </Typography>
                                    </Stack>
                                </Stack>
                                <Stack
                                    direction='row'
                                    justifyContent='space-between'
                                    alignItems='center'
                                    spacing={2}
                                    sx={{p:2}}
                                >
                                    <Tooltip
                                        title={agreeCarrier.locked_partner_a ? `Vereinbarung entsperren (${agreeCarrier.partner_a_name})` : `Vereinbarung sperren (${agreeCarrier.partner_a_name})`}
                                    >
                                        <span>
                                            <IconButton
                                                onClick={()=> handleLockProjectAgreement(agreeCarrier.id)}
                                                color={agreeCarrier.locked_partner_a ? "primary" : "secondary"}
                                                size="large"
                                                disabled={!agreeCarrier.is_partner_a}
                                            >
                                                {agreeCarrier.locked_partner_a ? <BsFillLockFill size={ICONSIZE}/> : <BsUnlockFill size={ICONSIZE}/> }
                                            </IconButton>
                                        </span>
                                    </Tooltip>
                                    <Stack
                                        alignItems='center'
                                        sx={{flex:1}}
                                    >
                                        <Typography variant="text_s" color="primary">
                                            {agreeCarrier.locked_date ? "Vereinbarung getroffen" : agreeCarrier.locked_partner_a || agreeCarrier.locked_partner_b ? "Vereinbarung teilweise gesperrt" :  "Vereinbarung offen" }
                                        </Typography>
                                        <Divider flexItem/>
                                        {agreeCarrier.locked_date &&
                                            <Typography variant="text_s" color="background.light20">
                                                {formatTimestamp(agreeCarrier.locked_date)}
                                            </Typography>
                                        }
                                    </Stack>
                                    <Tooltip
                                        title={agreeCarrier.locked_partner_b ? `Vereinbarung entsperren (${agreeCarrier.partner_b_name})` : `Vereinbarung sperren (${agreeCarrier.partner_b_name})`}
                                    >
                                        <span>
                                            <IconButton
                                                onClick={()=> handleLockProjectAgreement(agreeCarrier.id)}
                                                color={agreeCarrier.locked_partner_b ? "primary" : "secondary"}
                                                size="large"
                                                disabled={agreeCarrier.is_partner_a}
                                            >
                                                {agreeCarrier.locked_partner_b ? <BsFillLockFill size={ICONSIZE}/> : <BsUnlockFill size={ICONSIZE}/> }
                                            </IconButton>
                                        </span>
                                    </Tooltip>
                                </Stack>
                                <List sx={{ p: 0, flex: 1, maxHeight: 600, overflow: 'auto'}}>
                                    <ListItem>
                                        <ListItemButton
                                            onClick={()=>handleOpenAgreementItemDialog(agreeCarrier.id)}
                                            sx={{ gap: 2, color: 'text.primary' }}
                                            disabled={agreeCarrier.locked_partner_a || agreeCarrier.locked_partner_b}
                                        >
                                            <Add color="primary"/>
                                            <Typography variant="text_m">
                                                Neuer Vereinbarungspunkt erstellen
                                            </Typography>
                                        </ListItemButton>
                                    </ListItem>
                                    {agreeCarrier.agreements.map((a, idx) =>
                                        <Box key={`agreement-${a.id}`}>
                                            <AggreementItem
                                                key={a.id}
                                                id={a.id}
                                                idx={idx + 1}
                                                project_id={project.id}
                                                subject={a.subject}
                                                description={a.description}
                                                disagree={a.disagree}
                                                disagree_partner={a.disagree_partner}
                                                partner_a_name={agreeCarrier.partner_a_name}
                                                partner_b_name={agreeCarrier.partner_b_name}
                                                getProjectAgreements={getProjectAgreements}
                                                locked={agreeCarrier.locked_partner_a || agreeCarrier.locked_partner_b}
                                                created={a.created}
                                            />
                                                {agreeCarrier.agreements.length !== idx + 1 &&
                                                    <Divider variant='inset'/>
                                                }
                                        </Box>
                                    )}
                                    {agreeCarrier.agreements.length  === 0 &&
                                        <ListItem>
                                            <Typography variant="text_m" sx={{textAlign:'center'}} width="100%">
                                                    Noch keine Vereinbarungspunkte erstellt
                                            </Typography>
                                        </ListItem>
                                    }
                                </List>
                            </CustomPaper>
                        </Grid>
                    )
                })}

            </Grid>
            <AddAgreementsDialog
                open={open}
                onClose={()=> setOpen(false)}
                project_id={project.id}
                getProjectAgreements={getProjectAgreements}
            />
            <AddAgreementsItemDialog
                open={openAgreementItemDialog}
                onClose={()=> setOpenAgreementItemDialog(false)}
                project_id={project.id}
                agreement_carrier_id={selectedCarrier}
                getProjectAgreements={getProjectAgreements}
            />
        </Stack>
    );
}

export default ProjectAgreements
