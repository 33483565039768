import { useState, useEffect, useCallback } from 'react';
import { useForm } from 'react-hook-form';

import {
    Box,
    Stack,
    Button,
    Dialog,
    DialogTitle,
    DialogContent,
    DialogContentText,
    DialogActions,
    Typography,
    ListItem,
    TextField,
    InputAdornment,
    Divider,
} from '@mui/material';

import { getCookie } from '../../../../../utils/getCookie';
import useStyledSnackbar from '../../../../../hooks/useStyledSnackbar';

import ControlledTextField from '../../../../Widgets/ControlledInputs/ControlledTextField';
import ControlledSelect from '../../../../Widgets/ControlledInputs/ControlledSelect';
import { formatNumber } from '../../../../../utils/helper';
import MaterialInfoStack from './MaterialInfoStack';
import MailOrderDialog from './MailOrderDialog';



const OrderMaterialDialog = (props) =>{

    let sum = 0
    const { open, onClose,  materials, setSelectedMaterials, sub_project_id, project_id, getSubProjectMaterial, getValues } = props
    const { enqueueSnackbar } =  useStyledSnackbar()

    const [openDialog, setOpenDialog] = useState(false)
    const [materials_, setMaterials_] = useState([]);
    const [suppliers, setSuppliers] = useState([])
    const [res, setRes] = useState(null)
    const { handleSubmit, control, reset, formState: { errors, isDirty, isValid }} = useForm({
        mode: 'onChange',
        defaultValues: {
            supplier: '',
            place_of_delivery: '',
            annotation: '',
        }
    })

    const getSuppliers = async () => {
        const res = await fetch('/api/company/supplier', {
            method: 'GET',
        })
        const _data = await res.json()
        if (res.ok) {
            const transformed = {}
            for (const supplier of _data) {
                transformed[supplier.id] = supplier.name + ' / ' + supplier.contact_person
            }
            setSuppliers(transformed)
        }
    }

    const handleOnClose = useCallback(() =>{
        reset()
        setSelectedMaterials([])
        onClose()
    },[reset])

    const handleValueChange = (e, idx, key) => {
        let data_ = [...materials]
        data_[idx][key] = e.target.value
        setMaterials_(data_);
    };

    const handleOrderTx = async(data) =>{
        setOpenDialog(true)
        const json = JSON.stringify({
            ...data,
            type_key: 3,
            project_materials: materials_
        })
		const res = await fetch('/api/project/' + project_id  + '/sub-project/'+ sub_project_id +'/material/tx/order', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'X-CSRFToken': getCookie('csrftoken')
            },
            body: json
        })
        const _data = await res.json()
        if(res.ok){
            setRes(_data)
            getValues()
            getSubProjectMaterial()
            enqueueSnackbar(`Die Bestellung wurde erfolgreich durchgefürht!`, {
                variant: 'success'
            })
        } else {
            Object.entries(_data).forEach(([field, message]) => {
                enqueueSnackbar(`${field}: ${message} `, {
                    variant: 'error'
                })
            })
            setOpenDialog(false)
        }
    }

    useEffect(()=>{
        getSuppliers()
    },[])

    useEffect(()=>{
        if(openDialog === false){
            handleOnClose()
        }
    },[openDialog, handleOnClose])

    useEffect(()=>{
        let m_ = []
        materials.forEach(m => {
            m.debit_value =  ''
            m.debit_price =  ''
            m_.push(m)
        });
        setMaterials_(m_)
    },[materials])

    return(
        <Dialog
            open={open}
            onClose={handleOnClose}
            maxWidth="laptop"
        >
            <DialogTitle>
                {`Material bestellen`}
            </DialogTitle>
            <DialogContent>
                <DialogContentText>
                    Hier kannst du deine Materialien für den Projektauftrag bestellen. Der Bestelleinzelpreis dient zur Nachkalkulation und muss nicht direkt angegeben werden. Der Bestelleinzelpreis kann der jederzeit verändert werden.
                </DialogContentText>
                <Stack
                    spacing={2}
                    component="form"
                    id='order-form'
                    onSubmit={handleSubmit(handleOrderTx)}
                    sx={{ mt:2, mb: 2 }}
                >
                    <Box sx={{ width: "100%", height: 380, overflow: 'auto'}}>
                        {materials.map((material, idx) => {
                            const debit_value = parseFloat(material.debit_value !== '' ? material.debit_value : 0)
                            const debit_price = parseFloat(material.debit_price !== ''  ? material.debit_price : material.stock_price_per_piece )
                            sum += debit_value * debit_price
                            return(
                                <Box key={`material-list-item${idx}`}>
                                    <ListItem>
                                        <Stack
                                            direction='row'
                                            justifyContent="space-between"
                                            alignItems='center'
                                            width="100%"
                                            gap={2}
                                        >
                                            <MaterialInfoStack material={material}/>
                                            <Stack
                                                direction={'row'}
                                                justifyContent='center'
                                                alignItems="center"
                                                spacing={2}
                                            >
                                                <TextField
                                                    placeholder="0"
                                                    type="number"
                                                    label="Menge"
                                                    size="small"
                                                    InputProps={{
                                                        endAdornment: <InputAdornment position="end">{material.unit?.split('/')[1]}</InputAdornment>,
                                                    }}
                                                    sx={{ width: 130}}
                                                    value={material.debit_value}
                                                    onChange={(e) => handleValueChange(e, idx, 'debit_value')}
                                                />
                                                <TextField
                                                    placeholder="0"
                                                    label="Bestelleinzelpreis"
                                                    type="number"
                                                    size="small"
                                                    InputProps={{
                                                        endAdornment: <InputAdornment position="end">€</InputAdornment>,
                                                    }}
                                                    sx={{ width: 200}}
                                                    value={material.debit_price}
                                                    onChange={(e)=> handleValueChange(e, idx, 'debit_price')}
                                                />
                                            </Stack>
                                        </Stack>
                                    </ListItem>
                                    {materials.length > idx + 1 && <Divider flexItem/>}
                                </Box>
                            )
                        })}
                    </Box>
                    <Stack
                        direction='row'
                        spacing={2}
                    >
                        <ControlledSelect
                            control={control}
                            errors={errors}
                            rules={{required: true}}
                            name="supplier"
                            label="Lieferant"
                            items={suppliers}
                        />
                        <ControlledTextField
                            control={control}
                            errors={errors}
                            name="place_of_delivery"
                            label="Lieferort"
                            rules={{required: false}}
                        />
                    </Stack>
                    <ControlledTextField
                        control={control}
                        errors={errors}
                        name="annotation"
                        label="Anmerkung zur Bestellung"
                        rules={{required: true}}
                        rows={3}
                        multiline
                    />
                </Stack>
                <Box>
                    <Typography variant="text_s">{`${materials.length} Artikel ausgewählt | Bestellsumme ${formatNumber(sum, 2, '€')}`}</Typography>
                </Box>
            </DialogContent>
            <DialogActions>
                <Button
                    color="secondary"
                    onClick={handleOnClose}
                >
                    Abbrechen
                </Button>
                <Button
                    type="submit"
                    form="order-form"
                    disabled={!isDirty || !isValid || openDialog}
                >
                    Bestellen
                </Button>
            </DialogActions>
            <MailOrderDialog
                open={openDialog}
                setOpen={setOpenDialog}
                res={res}
                setRes={setRes}
                project_id={project_id}
                sub_project_id={sub_project_id}
            />
        </Dialog>
    )
}

export default OrderMaterialDialog