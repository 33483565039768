import { useState }  from 'react'

import { Typography, Stack } from '@mui/material';

import CompanySearch from "../../../../../Widgets/CompanySearch"
import InviteCompanyDialog from './InviteCompanyDialog';
import CustomPaper from '../../../../../Widgets/CustomPaper';

const InviteCompany = ({ project, getActiveInvites }) =>{

    const [open, setOpen] = useState(false)
    const [selectedCompany, setSelectedCompany] = useState({
        name: null,
        address: {
            street: null,
            house_number: null,
            add_on: null,
            postal_code: null,
            city: null
        }
    })

    const handleSelectedCompany = (data) =>{
        setSelectedCompany(data)
        setOpen(true)
    }

    return(
        <CustomPaper
            title="Unternehmen einladen"
        >
            <Stack
                spacing={2}
            >
                <Typography variant="text_m_justify">
                    Lade ein Unternehmen ins Projekt ein. Du findest alle Unternehmen die bereits bei in <strong>CONMEET Community</strong> sind in der Suche.
                    Gebe einfach den Namen des Unternehmens ein, mit dem Sie in diesem Projekt arbeiten möchten.
                </Typography>
                <CompanySearch
                    handleSelection={handleSelectedCompany}
                />
            </Stack>
            <InviteCompanyDialog
                project={project}
                open={open}
                handleClose={()=> setOpen(false)}
                selectedCompany={selectedCompany}
                getActiveInvites={getActiveInvites}
            />
        </CustomPaper>
    )
}

export default InviteCompany