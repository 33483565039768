import { Stack, Divider, Typography, Box} from '@mui/material'

import './ProjectsCard.css'

const ProjectCardWrapperLimited = ({ project, children }) =>{

    return(
        <Stack
            className="project-card-limited"
            sx={{
                minWidth: 200,
                height: '100%'
            }}
        >
            <Box
                component="img"
                alt="image not found"
                src={project.img}
                sx={{ p:1, height: 180}}
            />
            <Stack>
                <Typography sx={{ color: 'secondary.main', fontSize: 14, fontWeight: 'bold', letterSpacing: '0.3rem'}}>Projekt</Typography>
                <Typography sx={{ color: 'background.main', fontSize: 16, fontWeight: 'bold', letterSpacing: '0.4rem'}}>{project.name}</Typography>
            </Stack>
            <Divider sx={{ mb:1, mt:1 }}/>
            {children}
        </Stack>
    )
}

export default ProjectCardWrapperLimited