import { useState } from 'react';
import { useForm } from 'react-hook-form';
import { Link } from 'react-router-dom';

import { Button, Paper, Stack, Typography } from '@mui/material'
import ThumbUp from '@mui/icons-material/ThumbUp'

import ControlledTextField from 'components/Widgets/ControlledInputs/ControlledTextField';
import LogoFullWhite from 'components/Widgets/Logos/LogoFullWhite'

import { getCookie } from 'utils/getCookie';
import setServerErrors from 'utils/setServerErrors';


const Request = () => {
    const [state, setState] = useState('input')

    const { control, handleSubmit, setError, formState: { errors, isValid, isDirty } } = useForm({
        mode:"onChange",
        defaultValues:{
            email: '',
        }
    });

    const requestReset = async data => {
        const res = await fetch('/account/password_reset/', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'X-CSRFToken': getCookie('csrftoken')
            },
            body: JSON.stringify(data)
        })
        if (res.ok) {
            setState('done')
        } else {
            const json = await res.json()
            setServerErrors(json, setError)
        }
    }

    return (
        <Stack
            spacing={3}
            sx={{
                backgroundColor: 'background.main',
                justifyContent: 'center',
                alignItems: 'center',
                height: '100vh',
                p: 2
            }}
        >
            <LogoFullWhite width='100%' height={80}/>
            <Paper
                component='form'
                onSubmit={handleSubmit(requestReset)}
                sx={{
                    p: 5,
                }}
            >
                {state === 'input' &&
                    <Stack direction='column' gap={2}>
                        <ControlledTextField
                            control={control}
                            name='email'
                            rules={{ required: true }}
                            type='email'
                            label='E-Mail'
                            errors={errors}
                        />
                        <Button
                            type="submit"
                            color="primary"
                            variant="contained"
                            size="large"
                            fullWidth
                            disabled={!isDirty || !isValid}
                        >
                            Passwort zurücksetzen
                        </Button>
                    </Stack>
                }
                {state === 'done' &&
                    <>
                        <Stack justifyContent='center' alignItems='center' gap={2}>
                            <ThumbUp color="primary" fontSize='large'/>
                            <Typography variant="overline" sx={{ color: 'success.main'}}>
                                Wenn ein Account mit der E-Mail existiert, erhalten Sie darüber die nötigen Informationen zum zurücksetzen des Passworts.
                            </Typography>
                            <Link to='/login' style={{ textDecoration: 'none' }}>
                                <Button variant='outlined' color='primary'>
                                    Login
                                </Button>
                            </Link>
                        </Stack>
                    </>
                }
            </Paper>
        </Stack>

    )
}

export default Request
