import DialogPaginationDataGrid from "../../../../../Widgets/CustomDatagrids/Pagination/DialogPaginationDataGrid"


const MaterialTable = (props) =>{

    const {
        open,
        onClose,
        apiRef,
        title,
        subtitle,
        sublabel="test",
        rows,
        columns,
        pageSize,
        setPageSize,
        handlePageChange,
        rowCount,
        loading,
        errors={},
        onSaveRow,
        onDeleteRow,
        getConfirmMessage,
        setSearchTerm,
        searchTerm,
        onOrderByGetParametersChange,
        actionBar
    } = props

    return(
        <DialogPaginationDataGrid
            open={open}
            onClose={onClose}
            title={title}
            errors={errors}
            rows={rows}
            columns={columns}
            apiRef={apiRef}
            pageSize={pageSize}
            onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
            rowCount={rowCount}
            onPageChange={handlePageChange}
            loading={loading}
            subtitle={subtitle}
            sublabel={sublabel}
            onSaveRow={onSaveRow}
            onDeleteRow={onDeleteRow}
            getConfirmMessage={getConfirmMessage}
            setSearchTerm={setSearchTerm}
			searchTerm={searchTerm}
		    onOrderByGetParametersChange={onOrderByGetParametersChange}
            actionBar={actionBar}
        />
    )
}

export default MaterialTable
