import { useCallback, useEffect, useState } from "react";
import { useGridApiRef } from "@mui/x-data-grid-pro";
import DialogPaginationDataGrid from "../../../Widgets/CustomDatagrids/Pagination/DialogPaginationDataGrid"
import { CustomPDFMailCell, formatPrice, formatValueWithUnit, getFormattedDate, getTotalOrderValue, getTotalPrice } from "./WarehouseTableUtils";


const WarehouseOrderTable = (props) =>{

    const {
        open,
        onClose,
		subtitle,
		handleTXPatch,
		handleTXDelete
    } = props

	const [searchTerm, setSearchTerm] = useState('');
	const [orderBy, setOrderBy] = useState('');
    const apiRef = useGridApiRef();
	const [isLoading, setIsLoading] = useState(false)
    const [txs, setTxs] = useState([])
    const [pageSize, setPageSize] = useState(50)
    const [pagInfo, setPagInfo] = useState({
		count: 0,
		next : null,
		previous: null,
	});

    const handlePageChange = (x) =>{
		getTx(pagInfo[x])
	}

	const handleOrderByGetParametersChange = params => {
		setOrderBy(params)
	}

    const getTx = useCallback(async(url) => {
		if(open === false) return
		setIsLoading(true)
        const res = await fetch(url ? url :'/api/company/warehouse/tx/2?search=' + searchTerm +'&limit=' + pageSize + '&' + orderBy, {
            method: 'GET'
        })
		const data = await res.json()
		if(res.ok){
			setTxs(data.results)
			setPagInfo({
				count: data.count,
				next : data.next,
				previous: data.previous,
			})
			// console.log(data)
		}
		setIsLoading(false)
    }, [pageSize, open, searchTerm, orderBy])

    useEffect(()=>{
		getTx()
	}, [open, getTx, pageSize])


	const columns = [
		{
			field: 'custom_id',
			headerName: 'B-ID',
			type: 'string',
			minWidth: 50,
			editable: false,
			valueFormatter: (params) => {
				if (params.value == null) {
				  return '';
				}
				return `B#${params.value}`;
			},
			sortable: false,
		},
		{
			field: 'unique_material_id',
			headerName: 'Artikelnummer',
			type: 'string',
			minWidth: 120,
			editable: false,
			sortable: false,
		},
		{
			field: 'description',
			headerName: 'Artikelbezeichnung',
			type: 'string',
			minWidth: 250,
			editable: false,
			sortable: false,
		},
		{
			field: 'value',
			headerName: 'Menge',
			type: 'number',
			minWidth: 80,
			editable: true,
			valueFormatter: formatValueWithUnit,
		},
		{
			field: 'correction_value',
			headerName: 'Korrektur',
			type: 'number',
			minWidth: 120,
			editable: false,
			valueFormatter: formatValueWithUnit,
		},
		{
			field: '_value',
			headerName: 'Gesamtmenge',
			type: 'number',
			minWidth: 120,
			editable: false,
			valueGetter: getTotalOrderValue,
			valueFormatter: formatValueWithUnit,
		},
		{
			field: 'standard_price',
			headerName: 'Standardpreis',
			type: 'number',
			minWidth: 120,
			editable: false,
			valueFormatter: formatPrice,
		},
		{
			field: 'price',
			headerName: 'Bestelleinzelpreis',
			type: 'number',
			minWidth: 120,
			editable: true,
			valueFormatter: formatPrice,
		},
		{
			field: '_total_price',
			headerName: 'Gesamtpreis',
			type: 'number',
			minWidth: 120,
			editable: false,
			valueGetter: getTotalPrice,
			valueFormatter: formatPrice
		},
		// {
		// 	field: 'type',
		// 	headerName: 'Transaktion',
		// 	type: 'string',
		// 	minWidth: 200,
		// 	editable: false,
		// 	hide: true,
		// 	sortable: false,
		// },
		{
			field: 'supplier_name',
			headerName: 'Lieferant',
			type: 'string',
			minWidth: 120,
			editable: false,
			sortable: false,
		},
		{
			field: 'supplier_contact_person',
			headerName: 'Ansprechpartner',
			type: 'string',
			minWidth: 100,
			editable: false,
			sortable: false,
		},
		{
			field: 'place_of_delivery',
			headerName: 'Lieferort',
			type: 'string',
			minWidth: 120,
			editable: false,
			sortable: false,
		},
		{
			field: 'annotation',
			headerName: 'Bemerkung',
			type: 'string',
			flex:'1',
			minWidth: 200,
			editable: false,
			sortable: false,
		},
		{
			field: 'sub_project_name',
			headerName: 'Projektauftrag',
			type: 'string',
			minWidth: 150,
			editable: false,
			sortable: false,
		},
		{
			field: 'creator',
			headerName: 'Ersteller',
			type: 'string',
			minWidth: 150,
			editable: false,
			sortable: false,
		},
		{
			field: 'file',
			headerName: 'Dokument',
			type: 'actions',
			minWidth: 10,
			renderCell: CustomPDFMailCell,
			editable: false,
			sortable: false,
		},
		{
			field: 'done',
			headerName: 'Versendet',
			type: 'boolean',
			editable: true,
			minWidth: 40,
		},
		{
			field: 'created',
			headerName: 'bestellt am',
			minWidth: 200,
			editable: false,
			valueGetter: getFormattedDate,
		},
	]

	const getConfirmMessage = row => `Möchtest du die Transaktion wirklich löschen?`

    return(
        <DialogPaginationDataGrid
            open={open}
            onClose={onClose}
            title="Lagerbestellungen"
            subtitle={subtitle}
            apiRef={apiRef}
            rows={txs}
            pageSize={pageSize}
            setPageSize={setPageSize}
            rowCount={pagInfo.count}
            handlePageChange={handlePageChange}
            columns={columns}
            loading={isLoading}
			searchTerm={searchTerm}
			setSearchTerm={setSearchTerm}
			onSaveRow={handleTXPatch}
			onDeleteRow={handleTXDelete(getTx)}
			getConfirmMessage={getConfirmMessage}
			onOrderByGetParametersChange={handleOrderByGetParametersChange}
        />
    )
}

export default WarehouseOrderTable
