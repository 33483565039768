import {useCallback, useEffect, useState} from "react"
import { Stack, Box, Avatar, Typography, Divider, ListItem, IconButton, ListItemAvatar, Chip } from "@mui/material"

import NoData from "../../../../../Widgets/NoData"
import { getCookie } from "../../../../../../utils/getCookie"
import { formatTimestamp } from '../../../../../../utils/date_functions'
import { BsX } from "react-icons/bs"

import CustomLightWeightAccordion from "../../../../../Widgets/CustomLightWeightAccordion"
import { PALETTE } from "../../../../../theme"



const MissingMaterial = ({ project_id, selectedSubProject }) =>{

    const [missingMaterials, setMissingMaterials] = useState([])

    const deleteMissingProjectMaterial = async(pcmm_id) => {
        const json = JSON.stringify({pcmm_id: pcmm_id})
        const res = await fetch('/api/project/' + project_id + '/' + selectedSubProject + '/material/missing/app', {
            method: 'DELETE',
            headers: {
                'Content-Type': 'application/json',
                'X-CSRFToken': getCookie('csrftoken')
            },
            body: json
        })
        if(res.ok){
            getMissingMaterials()
        }
    }

    const getMissingMaterials = useCallback(async() => {
        if(selectedSubProject === undefined) return
        const res = await fetch('/api/project/' + project_id + '/sub-project/' + selectedSubProject + '/material/missing', {
            method: 'GET'
        })
        const data = await res.json()
        if(res.ok){
            setMissingMaterials(data)
            console.log(data)
        }
    }, [project_id, selectedSubProject])

    useEffect(() => {
        getMissingMaterials()
    }, [getMissingMaterials])

    return(
        <CustomLightWeightAccordion
            accordionbar={
                <Stack
                    flexDirection='row'
                    justifyContent="flex-start"
                    alignItems="center"
                    width="100%"
                    gap={1}
                >
                    <Typography variant="subtitle_m">Materialnachtragungen</Typography>
                    {missingMaterials.length !== 0 && <Chip color="primary" label={`${missingMaterials.length}`}/>}
                </Stack>
            }
        >
            <Box
                sx={{
                    maxHeight: 300,
                    overflow: 'auto',
                    pt:1,
                    pb: 1,
                    pr: 2,
                    border: 1,
                    borderRadius: '7px',
                    borderColor: PALETTE.background.light70
                }}
            >
                {missingMaterials.length === 0 &&
                    <NoData text='Derzeit keine Nachtragungen' />
                }
                {missingMaterials.map((mm, idx) => {
                    return (
                        <Box
                            key={`mm-${idx}`}
                        >
                            <ListItem sx={{
                                justifyContent: 'space-between',
                            }}>
                                <Stack
                                    direction='row'
                                    alignItems='flex-start'
                                >
                                    <ListItemAvatar
                                        sx={{ pt: 1 }}
                                    >
                                        <Avatar
                                            alt=''
                                            src={mm.creator.avatar}
                                        />
                                    </ListItemAvatar>
                                    <Stack>
                                        <Typography gutterBottom variant='text_m' sx={{ mr: 4, mb: 1, whiteSpace: 'pre-wrap', wordBreak: 'break-all' }}>
                                            {mm.text}
                                        </Typography>
                                        <Typography variant='text_s' sx={{ color: 'text.primary' }}>
                                            {mm.creator.firstname + ' ' + mm.creator.lastname}
                                            {' - '}
                                            {formatTimestamp(mm.created)}
                                        </Typography>
                                    </Stack>
                                </Stack>
                                <IconButton
                                    edge="end"
                                    aria-label={"delete-" + mm.id}
                                    onClick={() => deleteMissingProjectMaterial(mm.id)}
                                    size="small"
                                >
                                    <BsX size={27}/>
                                </IconButton>
                            </ListItem>
                            {missingMaterials.length !== (idx + 1) && <Divider variant='inset'/>}
                        </Box>
                    );
                })}
            </Box>
        </CustomLightWeightAccordion>
    )
}

export default MissingMaterial
