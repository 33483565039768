import { useContext, useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';

import {
    Box,
    Stack,
    Button,
    Dialog,
    DialogTitle,
    DialogContent,
    DialogContentText,
    DialogActions,
    List,
    ListItem,
    Typography,
    FormGroup,
    FormControlLabel,
    Switch,
    TextField,
    InputAdornment,
    Divider,
} from '@mui/material';

import { getCookie } from '../../../../../utils/getCookie';
import useStyledSnackbar from '../../../../../hooks/useStyledSnackbar';

import ControlledTextField from '../../../../Widgets/ControlledInputs/ControlledTextField';
import { formatNumber } from '../../../../../utils/helper';
import MaterialInfoStack from './MaterialInfoStack';
import Paywall from 'components/Widgets/Paywall';
import AccountContext from 'context/AccountContext';


const WarehouseMaterialDialog = (props) =>{

    const { open, onClose, materials, setSelectedMaterials, sub_project_id, project_id, getSubProjectMaterial, getValues } = props
    const [materials_, setMaterials_] = useState([]);
    const { enqueueSnackbar } =  useStyledSnackbar()
    const [account] = useContext(AccountContext)
    let sum = 0
    let globalError = false
    const { handleSubmit, control, reset, formState: { errors, isDirty, isValid }} = useForm({
        mode: 'onChange',
        defaultValues: {
            annotation: '',
        }
    })

    const [freeBooking, setFreeBooking] = useState(!account.company.paid ? true : false);
    const handleFreeBooking = (event) => {
        if(!account.company.paid){
            setFreeBooking(true)
            enqueueSnackbar(`Dies ist eine kostenpflichtige Funktion, wenn du die Funktion nutzen möchtest wende dich an uns. Danke.`, {
                variant: 'error'
            })
            return
        }
        setFreeBooking(event.target.checked);
    };

    const handleOnClose = () =>{
        onClose()
        reset()
    }

    const handleValueChange = (e, idx, key) => {
        let data_ = [...materials]
        data_[idx][key] = e.target.value
        setMaterials_(data_);
    };

    const handleWarehouseTx = async(data) =>{

        const json = JSON.stringify({
            ...data,
            free_booking: freeBooking,
            project_materials: materials_
        })
		const res = await fetch('/api/project/' + project_id  + '/sub-project/'+ sub_project_id +'/material/tx/warehouse', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'X-CSRFToken': getCookie('csrftoken')
            },
            body: json
        })
        const data_ = await res.json()
        if(res.ok){
            handleOnClose()
            setSelectedMaterials([])
            getValues()
            getSubProjectMaterial()
            enqueueSnackbar(`Die Transaktion wurde erfolgreich durchgefürht!`, {
                variant: 'success'
            })
        } else {
            Object.entries(data_).forEach(([field, message]) => {
                enqueueSnackbar(`${field}: ${message} `, {
                    variant: 'error'
                })
            })
        }
    }

    useEffect(()=>{
        let m_ = []
        materials.forEach(m => {
            m.debit_value =  ''
            m.debit_price =  ''
            m_.push(m)
        });
        setMaterials_(m_)
    },[materials])

    return(
        <Dialog
            open={open}
            onClose={handleOnClose}
            maxWidth="laptop"
        >
            <DialogTitle>
                Material aus dem Lager holen
            </DialogTitle>
            <DialogContent>
                <DialogContentText>
                    Hier können Sie das Materialdepot des ausgewählten Materials aufstocken. Zudem kannst du dich noch entstanden ob es eine freie Buchung anstatt einer Lagerbuchung seinen soll.
                    Bei einer Lagerbuchung wird das Lager belastet und bei einer freien Buchung nicht.
                </DialogContentText>
                <Stack
                    spacing={3}
                    component="form"
                    id='stock-form'
                    onSubmit={handleSubmit(handleWarehouseTx)}
                    sx={{ mt:2, mb: 2 }}
                >
                    <List
                        sx={{
                            height: 450,
                            overflow: 'auto',
                        }}
                    >
                        {materials_.map((material, idx)=>{
                            const debit_value = parseFloat(material.debit_value !== '' ? material.debit_value : 0)
                            const debit_price = parseFloat(material.debit_price !== ''  ? material.debit_price : 0)
                            sum += debit_value * (freeBooking ? debit_price : material.stock_price_per_piece)
                            const error = debit_value > material.stock_balance && !freeBooking
                            if(error){ globalError = true}
                            return(
                                <Box key={`material-list-item${idx}`}>
                                    <ListItem>
                                        <Stack
                                            direction='row'
                                            justifyContent="space-between"
                                            alignItems='center'
                                            width="100%"
                                            gap={2}
                                        >
                                            <MaterialInfoStack material={material}/>
                                            <Stack
                                                direction={'row'}
                                                justifyContent='center'
                                                alignItems="center"
                                                spacing={2}
                                            >
                                                <TextField
                                                    placeholder="0"
                                                    type="number"
                                                    label="Menge"
                                                    size="small"
                                                    InputProps={{
                                                        endAdornment: <InputAdornment position="end">{material.unit?.split('/')[1]}</InputAdornment>,
                                                    }}
                                                    sx={{ width: 160}}
                                                    value={material.debit_value}
                                                    onChange={(e) => handleValueChange(e, idx, 'debit_value')}
                                                    error={error}
                                                    helperText={error ? 'Nicht verfügbar' : ''}
                                                />
                                                <TextField
                                                    placeholder="0"
                                                    label="Einzelpreis"
                                                    type="number"
                                                    size="small"
                                                    InputProps={{
                                                        endAdornment: <InputAdornment position="end">€</InputAdornment>,
                                                    }}
                                                    sx={{ width: 160}}
                                                    value={material.debit_price}
                                                    onChange={(e)=> handleValueChange(e, idx, 'debit_price')}
                                                    disabled={!freeBooking}
                                                />
                                            </Stack>
                                        </Stack>
                                    </ListItem>
                                    {materials.length > idx + 1 && <Divider flexItem/>}
                                </Box>
                            )
                        })}
                    </List>
                    <Stack
                        spacing={1}
                    >
                        <FormGroup>
                            <FormControlLabel control={
                                <Switch
                                    checked={freeBooking}
                                    onChange={handleFreeBooking}
                                />
                            }
                            label="Freie Buchung" />
                        </FormGroup>
                        <ControlledTextField
                            control={control}
                            errors={errors}
                            name="annotation"
                            label="Anmerkung"
                            rules={{required: true}}
                            rows={3}
                            multiline
                        />
                    </Stack>
                </Stack>
                <Box>
                    <Typography variant="test_s">{materials.length} Artikel ausgewählt  | Gesamtsumme: {formatNumber(sum, 2, '€')}</Typography>
                </Box>
            </DialogContent>
            <DialogActions>
                <Button
                    onClick={handleOnClose}
                    color="secondary"
                >
                    Abbrechen
                </Button>
                <Button
                    type="submit"
                    form="stock-form"
                    color="primary"
                    disabled={!isDirty || !isValid || globalError}
                >
                    Bestätigen
                </Button>
            </DialogActions>
        </Dialog>
    )
}

export default WarehouseMaterialDialog