import { useState, useEffect, useCallback, useMemo } from 'react'

import { Stack, Typography, TextField, Grid, Paper, IconButton } from '@mui/material'
import { DatePicker } from '@mui/x-date-pickers-pro';
import { ArrowBack, ArrowForward } from '@mui/icons-material';

import DayItem from './DayItem';
import NewGroupDialog from './NewGroupDialog';
import TransferMemberDialog from './TransferMemberDialog';
import DayPlannerDialog from './DayPlannerDialog';
import DeleteGroupDialog from './DeleteGroupDialog';

import { getCookie } from "../../../../utils/getCookie";
import { addMonths } from 'date-fns';


const getDaysInMonth = (year, month) =>{
    return new Date(year, month + 1, 0).getDate()
}

const FullWorkplanner = () =>{

    const [selectedDate, setSelectedDate] = useState(new Date())
    const [events, setEvents] = useState([])
    const [workingGroups, setWorkingGroups] = useState([])
    const [projectKeys, setProjectKeys] = useState({})
    const [availableCompanyMembers, setAvailableCompanyMembers] = useState(0)

    const currentYear = useMemo(() => selectedDate.getFullYear(), [selectedDate])
    const currentMonth = useMemo(() => selectedDate.getMonth(), [selectedDate])

    const handleAddMonth = (amount) =>{
        setSelectedDate((prevDate) =>  addMonths(prevDate, amount))
    }

    // dialog
    const [dialogOpen, setDialogOpen] = useState(false)
    const [dialogDayDate, setDialogDayDate] = useState(new Date())

    const getEvents = useCallback(async() => {
        const res = await fetch('/api/company/workplanner?date=' + selectedDate.toDateString(), {
            method: 'GET',
            headers: {
                'X-CSRFToken': getCookie('csrftoken')
            }
        })
        const data = await res.json()
        if (res.ok) {
            setEvents(data.events)
            setAvailableCompanyMembers(data.available_company_members)
        }
    }, [selectedDate])


    const getWorkingGroups = async () => {
        const res = await fetch('/api/company/workplanner/working_groups', {
            method: 'GET'
        })
        const json = await res.json()
        if (res.ok) {
            setWorkingGroups(json)
        }
    }

    const getProjectKeys = async() => {
        const res = await fetch('/api/projects/get-sub-selection', {
            method: 'GET'
        })
        const data = await res.json()
        if(res.ok){
            delete data['0']
            setProjectKeys(data)
        }
    }

    useEffect(() => {
        getWorkingGroups()
        getProjectKeys()
    }, []);

    useEffect(() => {
        getEvents()
    }, [getEvents]);

    const openDialog = useCallback((dayDate) => {
        setDialogDayDate(dayDate)
        setDialogOpen(true)
    }, [])

    return(
        <Paper
            sx={{
                display: 'flex',
                flexDirection: 'column',
                // flex: 1
            }}
        >
            <Stack
                direction='row'
                justifyContent='space-between'
                alignItems='center'
                sx={{p: 2}}
            >
                <Stack
                    direction='row'
                    justifyContent='center'
                    alignItems='center'
                    spacing={2}
                >
                    <IconButton
                        color="primary"
                        onClick={()=> handleAddMonth(-1)}
                        size="large"
                    >
                        <ArrowBack fontSize="large"/>
                    </IconButton>
                    <IconButton
                        color="primary"
                        onClick={()=> handleAddMonth(1)}
                        size="large"
                    >
                        <ArrowForward fontSize="large"/>
                    </IconButton>
                </Stack>
                <Typography
                    variant="title_paper"
                >
                    {selectedDate.toLocaleDateString('de-DE', { month: 'long', year: 'numeric'})}
                </Typography>
                <Stack
                    direction='row'
                    spacing={2}
                >
                    <Stack direction='row' spacing={0}>
                        <NewGroupDialog
                            getWorkingGroups={getWorkingGroups}
                        />
                        <TransferMemberDialog
                            workingGroups={workingGroups}
                            getWorkingGroups={getWorkingGroups}
                        />
                        <DeleteGroupDialog
                            workingGroups={workingGroups}
                            getWorkingGroups={getWorkingGroups}
                        />
                    </Stack>
                    <DatePicker
                        views={['month', 'year']}
                        label="Jahr"
                        value={selectedDate}
                        onChange={(newValue) => {
                            setSelectedDate(newValue);
                        }}
                        renderInput={(params) => <TextField {...params} helperText={null} />}
                    />
                </Stack>
            </Stack>
            <Grid container>
                {[...Array(getDaysInMonth(currentYear, currentMonth)).keys()].map((idx) => {
                    const day = idx + 1
                    return (
                        <Grid
                            key={`grid-day-item-${day}`}
                            item
                            desktop={3}
                            laptop={3}
                            tablet={6}
                            mobil={6}
                            sx={{
                                height: 137
                            }}
                        >
                            <DayItem
                                key={`day-item-${day}`}
                                year={currentYear}
                                month={currentMonth}
                                day={day}
                                events={events}
                                openDialog={openDialog}
                                availableCompanyMembers={availableCompanyMembers}
                            />
                        </Grid>
                    )
                })}
            </Grid>
            <DayPlannerDialog
                open={dialogOpen}
                onClose={() => { setDialogOpen(false) }}
                dayDate={dialogDayDate}
                workingGroups={workingGroups}
                projectKeys={projectKeys}
                getEvents={getEvents}
            />
        </Paper>
    )
}

export default FullWorkplanner
