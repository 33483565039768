import { useState, useContext, useEffect, useCallback } from 'react';
import {
    Box,
    Stack,
    Typography,
    Divider,
    IconButton,
    FormControl,
    FormControlLabel,
    RadioGroup,
    Radio,
    Chip,
    Tooltip,
    Avatar,
    Badge,
} from '@mui/material'
import { Edit, Delete } from '@mui/icons-material';

import AccountContext from '../../../../context/AccountContext';
import { getCookie } from '../../../../utils/getCookie'
import { formatTimestamp } from '../../../../utils/date_functions'

import CustomPaper from '../../../Widgets/CustomPaper';
import SubscriberList from './SubscriberList';
import EditEventDialog from './EditEventDialog';
import ConfirmDialog from 'components/Widgets/ConfirmDialog';


const SelectedEvent = ({ selectedEventId, setSelectedEventId, getEvents }) => {

    const [value, setValue] = useState(1);
    const [account] = useContext(AccountContext)
    const [openEdit, setOpenEdit] = useState(false)
    const [event, setEvent] = useState({
        id: '',
        title: '',
        place: '',
        start: '',
        end: '',
        company_member: {},
        company_subscribers: [],
        project_subscribers: [],
        project: null,
        created: '',
    })
    const [eventIsInitialLoaded, setEventIsInitialLoaded] = useState(false)

    const ownEvent = event?.company_member?.id === account.id
    const externalEvent = event?.company_member?.company !== account.company.id
    const projectEvent = event?.project !== null

    const getEvent = useCallback(async () => {
        const res = await fetch('/api/company/calendar/event/' + selectedEventId)
        const json = await res.json()
        if (res.ok) {
            setEvent(json)
            setEventIsInitialLoaded(true)
            console.log(json)
        }
    }, [selectedEventId])

    // console.log("own event: ", ownEvent)
    // console.log("extern event: ", externalEvent)
    // console.log("project event: ", projectEvent)

    const handleChange = (event) => {
        let newEventStatus =  event.target.value
        setValue(newEventStatus);
        handleParticipation(newEventStatus)
    };

    const handleParticipation = async(value) => {
        const body = {
            meeting_id: event?.id,
            new_status: value,
            external_event: externalEvent,
        }
        const res = await fetch('/api/company/calendar/event/status', {
            method: 'PATCH',
            headers: {
                'Content-Type': 'application/json',
                'X-CSRFToken': getCookie('csrftoken')
            },
            body: JSON.stringify(body)
        })
        const _meeting = await res.json()
        if (res.ok) {
            setSelectedEventId(_meeting)
            getEvents()
        }
    }

    const deleteEvent = async () => {
        const body = {}
        const res = await fetch('/api/company/calendar/event/' + selectedEventId, {
            method: 'DELETE',
            headers: {
                'Content-Type': 'application/json',
                'X-CSRFToken': getCookie('csrftoken')
            },
            body: JSON.stringify(body)
        })
        if (res.ok) {
            getEvents()
            setSelectedEventId(null)
        }
    }

    const handleClose = () =>{
        setOpenEdit(false)
    }

    useEffect(()=>{
        getEvent()
    },[getEvent])

    useEffect(()=>{
        if(!eventIsInitialLoaded) return
        if(ownEvent) return
        if(!externalEvent){
            let ea = event?.company_subscribers.find(allocator => allocator.company_member.id === account.id)
            if(ea){
                setValue(ea.status)
            }
        } else {
            let ea = event?.project_subscribers.find(allocator => allocator.project_member.cm_id === account.id)
            if(ea){
                setValue(ea.status)
            }
        }
    },[event, externalEvent, account, ownEvent, eventIsInitialLoaded])

    return (
        eventIsInitialLoaded &&
            <CustomPaper
                title={`${event?.title}`}
                sx={{ width: 350 }}
                actionBar={
                    <Stack
                        direction='row'
                        spacing={1}
                    >
                        {ownEvent &&
                            <Tooltip
                                title="Termin bearbeiten"
                            >
                                <IconButton
                                    aria-label="edit"
                                    color="primary"
                                    onClick={()=>{setOpenEdit(true)}}
                                    disabled={!event}
                                >
                                    <Edit/>
                                </IconButton>
                            </Tooltip>
                        }
                            <ConfirmDialog
                                title={ownEvent ? "Termin löschen": "Teilnahme löschen"}
                                message={ownEvent ? 'Bist du dir sicher, dass du den Termin löschen willst?' : 'Bist du dir sicher, dass du an dem Termin nicht teilnehmen willst?' }
                                onConfirm={deleteEvent}
                                renderButton={(onClick) => (
                                    <IconButton
                                        aria-label="delete"
                                        color="error"
                                        onClick={onClick}
                                    >
                                        <Delete/>
                                    </IconButton>
                                )}
                            />
                    </Stack>
                }
            >
                <Stack gap={2} sx={{ flex: 1, overflow: 'auto'}}>
                    <Stack>
                        {!event?.allDay ?
                            <>
                                <Typography variant="text_m" sx={{whiteSpace: 'break-spaces'}}>{`Beginn: \t\t ${formatTimestamp(event?.start)}`}</Typography>
                                <Typography variant="text_m" sx={{whiteSpace: 'break-spaces'}}>{`Ende: \t\t ${formatTimestamp(event?.end)}`}</Typography>
                            </>
                        :
                        <Typography variant="text_m" sx={{whiteSpace: 'break-spaces'}}>{`Zeitraum: \t Ganztätig`}</Typography>
                        }
                        {projectEvent && <Typography variant="text_m" sx={{whiteSpace: 'break-spaces'}}>{`Projekt: \t\t ${event?.project?.name}`}</Typography>}
                    </Stack>
                    <Typography variant="text_m" sx={{whiteSpace: 'break-spaces'}}>{`Ort: \t \t \t ${event?.place !== null ? event.place : "----"}`}</Typography>
                    <Typography variant="text_m" sx={{whiteSpace: 'break-spaces'}}>{`Erstellt am: \t ${formatTimestamp(event?.created)}`}</Typography>
                    <Stack
                        direction='row'
                        spacing={1}
                    >
                        {!externalEvent && <Chip color="primary" variant="outlined" size="small" label={event?.public ? 'Öffentlich' : 'Nicht Öffentlich'}/>}
                        {projectEvent && <Chip color="primary" variant="filled" size="small" label='Mit Projekt verknüpft'/>}
                        {externalEvent && <Chip color="secondary" variant="filled" size="small" label="Externe Einladung"/>}
                    </Stack>
                    <Stack
                        spacing={1}
                    >
                        <Typography variant="text_m">{`Eingeladen von:`}</Typography>
                        <Stack
                            direction="row"
                            alignItems='center'
                            spacing={1}
                        >
                            <Badge
                                color="primary"
                                overlap="circular"
                                badgeContent=''
                                variant="dot"
                                anchorOrigin={{
                                    vertical: 'bottom',
                                    horizontal: 'right',
                                }}
                            >
                                <Avatar alt='' src={event?.company_member.avatar} />
                            </Badge>
                            <Stack>
                                <Typography variant="subtitle_s">{`${event?.company_member.firstname} ${event?.company_member.lastname}`}</Typography>
                                <Typography variant="text_s">{`${event?.company_member.company_name}`}</Typography>
                            </Stack>
                        </Stack>
                    </Stack>
                    {!ownEvent &&
                        <FormControl color='primary'>
                            <Box>
                                <Divider textAlign="left" flexItem>
                                    <Typography
                                        variant="subtitle_s"
                                    >
                                        Rückmeldung
                                    </Typography>
                                </Divider>
                            </Box>
                            <RadioGroup
                                color='primary'
                                value={value}
                                onChange={handleChange}
                                sx={{pl:2}}
                            >
                                <FormControlLabel value={1} control={<Radio color='default'/>} label="keine Rückmeldung" />
                                <FormControlLabel value={2} control={<Radio color='primary'/>} label="Annehmen" />
                                <FormControlLabel value={3} control={<Radio color='secondary'/>} label="Unter Vorbehalt" />
                                <FormControlLabel value={4} control={<Radio color='error'/>} label="Ablehnen" />
                            </RadioGroup>
                        </FormControl>
                    }
                    <Stack
                        spacing={1}
                    >
                        <Box >
                            <Divider textAlign="left" flexItem>
                                <Typography
                                    variant="subtitle_s"
                                >
                                    Beschreibung
                                </Typography>
                            </Divider>
                        </Box>
                        <Typography sx={{ minHeight: 50, maxHeight: 150, overflow: 'auto'}} variant="text_m">{event?.description}</Typography>
                    </Stack>
                    {event?.company_subscribers.length !== 0 &&
                        <SubscriberList
                            extern={externalEvent}
                            subscribers={event?.company_subscribers}
                            account={account}
                        />
                    }
                    {projectEvent &&
                        <SubscriberList
                            extern={!externalEvent}
                            subscribers={event?.project_subscribers}
                            account={account}
                        />
                    }
                </Stack>
                <EditEventDialog
                    open={openEdit}
                    event={event}
                    setEvent={setEvent}
                    selectedEventId={selectedEventId}
                    getEvents={getEvents}
                    onClose={handleClose}
                />
            </CustomPaper>

    )
}

export default SelectedEvent