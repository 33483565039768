import { useState, useEffect, useMemo } from 'react'

import ProjectCardBarchart from '../ProjectCardBarchart'
import '../ProjectsCard.css'

import useWidth from '../../../../hooks/useWidth'

import { Stack, Typography, LinearProgress, CircularProgress, Grid, Divider } from '@mui/material'
import { ArrowDropUp, ArrowDropDown } from "@mui/icons-material"
import ProjectCardWrapper from '../ProjectCardWrapper'

import { formatNumber } from '../../../../utils/helper'


const ProjectCardWithSubs = ({ project }) =>{

    const [ width, ref ] = useWidth(0)
    const [swipeIndex, setSwipeIndex] = useState(0)

    const task =  useMemo(() => project.sub_projects[swipeIndex], [swipeIndex])

    useEffect(() => {
        const intervall = setInterval(() => {
            setSwipeIndex(idx => (idx + 1) % project.sub_projects.length)
        }, 10000);
        return () => clearInterval(intervall);
      }, [project.sub_projects.length]);

    return(
        <ProjectCardWrapper
            project={project}
        >
            <Stack
                ref={ref}
                justifyContent='center'
                alignItems="center"
                width="100%"
                height={200}
            >
                {width !== 0 ?
                    <ProjectCardBarchart
                        width={width}
                        projectValue={task.value}
                        currentMaterial={task.current_material}
                        currentWorkloadCost={task.current_workload_costs}
                        currentTasksCosts={task.current_task}
                        at_cost={task.at_cost}
                    />
                :
                    <CircularProgress/>
                }
            </Stack>
            <Typography variant="subtitle_s" gutterBottom>{`Auftrag: ${task.title}`}</Typography>
            <Divider/>
            <Grid
                container
                spacing={1}
                sx={{mt:1, mb:2}}
            >
                <Grid
                    item
                    mobile={12}
                    tablet={6}
                    laptop={6}
                    desktop={6}
                >
                    <Stack sx={{p : '2px 10px 2px 10px'}}>
                        <Typography variant="caption_s">Auftraggeber</Typography>
                        <Typography variant="text_s">{task.customer.name}</Typography>
                    </Stack>
                </Grid>
                <Grid
                    item
                    mobile={12}
                    tablet={6}
                    laptop={6}
                    desktop={6}
                >
                    <Stack sx={{p : '2px 10px 2px 10px'}}>
                        <Typography variant="caption_s">Projektsumme</Typography>
                        <Typography variant="text_s">{task.at_cost ? "-" : formatNumber(task.value, 2,'€')}</Typography>
                    </Stack>
                </Grid>
                <Grid
                    item
                    mobile={12}
                    tablet={6}
                    laptop={6}
                    desktop={6}

                >
                    <Stack sx={{p : '2px 10px 2px 10px'}}>
                        <Typography variant="caption_s">Auftrag</Typography>
                        <Typography variant="text_s">{`${swipeIndex + 1} / ${project.sub_projects.length}`}</Typography>
                    </Stack>
                </Grid>
                <Grid
                    item
                    mobile={12}
                    tablet={6}
                    laptop={6}
                    desktop={6}
                >
                    <Stack
                        className='margin-box'
                        flexDirection="row"
                        justifyContent="space-between"
                        alignItems="center"
                        gap={1}
                    >
                        <Stack>
                            <Typography variant="caption_s">Marge</Typography>
                            <Typography variant="text_s">{task.at_cost ? "-" : formatNumber(task.current_marge, 2,'€')}</Typography>
                        </Stack>
                        <Stack
                            flexDirection="row"
                            gap={0.5}

                        >
                            {task.current_marge >= 0 ? <ArrowDropUp color='primary'/>: <ArrowDropDown color='secondary'/>}
                            <Typography variant="value">{formatNumber(task.current_marge_in_percent, 2,'%')}</Typography>
                        </Stack>
                    </Stack>
                </Grid>
            </Grid>
            <LinearProgress
                variant="determinate"
                color="secondary"
                value={task.progress}
                sx={{height:5}}
            />
        </ProjectCardWrapper>
    )
}

export default ProjectCardWithSubs