import { Fragment } from "react";
import { useController } from 'react-hook-form'
import { Stack, TextField } from '@mui/material'
import { CompareArrows } from '@mui/icons-material';

import { DateRangePicker } from '@mui/x-date-pickers-pro'

const ControlledDateRangePicker = ({ name, control, startText, endText, rules: rules_={}, disabled=false, showIcon=true, calendarRange=2}) =>{
    const requiredValidator = v => {
        return Boolean(v[0] && v[1]) && (rules_.validate ? rules_.validate(v) : true)
    }
    const rules = {...rules_}
    if (rules_.required) {
        rules.validate = requiredValidator
    }
    const { field } = useController({ control, name: name, rules: rules });

    return(
        <DateRangePicker
            startText={startText}
            endText={endText}
            calendars={calendarRange}
            value={field.value}
            onChange={(newRange) => {
                field.onChange(newRange)
            }}
            inputFormat='dd.MM.yyyy'
            mask='__.__.____'
            disabled={disabled}
            renderInput={(startProps, endProps) => (
                <Fragment>
                    <Stack
                        direction='row'
                        alignItems="center"
                        spacing={2}
                        sx={{width: '100%'}}
                    >
                        <TextField {...startProps} fullWidth/>
                        {showIcon && <CompareArrows color={!disabled ? 'primary' : 'text.disabled'} fontSize="medium"/>}
                        <TextField {...endProps} fullWidth/>
                    </Stack>
                </Fragment>
            )}
        />
    )
}

export default ControlledDateRangePicker
