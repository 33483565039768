import { useCallback, useEffect, useState } from "react";
import { useGridApiRef } from "@mui/x-data-grid-pro";

import MaterialTable from "./MaterialTable/MaterialTable"
import { formatDate, formatValueWithUnit } from "./MaterialDatagridUtils";
import { Download } from "@mui/icons-material";
import { IconButton, Link, Tooltip } from "@mui/material";


const MaterialCorrectionTable = ({ open, onClose, project_id, selectedSubProject, subtitle, is_admin, is_manager, handleTXPatch, handleTXDelete, sublabel }) =>{

    const apiRef = useGridApiRef();
	const [isLoading, setIsLoading] = useState(false)
    const [txs, setTxs] = useState([])
    const [pageSize, setPageSize] = useState(50)
	const [searchTerm, setSearchTerm] = useState('')
	const [orderBy, setOrderBy] = useState('');
    const [pagInfo, setPagInfo] = useState({
		count: 0,
		next : null,
		previous: null,
	});

    const handlePageChange = (x) =>{
		getTx(pagInfo[x])
	}

	const handleOrderByGetParametersChange = params => {
		setOrderBy(params)
	}

    const getTx = useCallback(async(url) => {
		if(selectedSubProject === undefined || open === false) return
		setIsLoading(true)
        const res = await fetch(url ? url :'/api/project/' + project_id + '/sub-project/' + selectedSubProject + '/material/tx/4?limit=' + pageSize + '&search=' + searchTerm + '&' + orderBy,  {
            method: 'GET'
        })
		const data = await res.json()
		if(res.ok){
			setTxs(data.results)
			setPagInfo({
				count: data.count,
				next : data.next,
				previous: data.previous,
			})
			// console.log(data)
		}
		setIsLoading(false)
    }, [project_id, selectedSubProject, pageSize, open, searchTerm, orderBy])

    useEffect(()=>{
		getTx()
	}, [open, getTx, pageSize])

	const columns = [
		{
			field: 'custom_id',
			headerName: 'MV-ID',
			type: 'string',
			minWidth: 40,
			editable: false,
			valueFormatter: (params) => {
				if (params.value == null) {
				  return '';
				}
				return `#MV${params.value}`;
			},
			sortable: false,
		},
		{
			field: 'unique_material_id',
			headerName: 'Artikel',
			type: 'string',
			minWidth: 50,
			editable: false,
			sortable: false,
		},
		{
			field: 'description',
			headerName: 'Artikelbezeichnung',
			type: 'string',
			minWidth: 400,
			editable: false,
			sortable: false,
		},
		{
			field: 'value',
			headerName: 'Menge',
			type: 'number',
			minWidth: 100,
			editable: false,
			valueFormatter: formatValueWithUnit
		},
		{
			field: 'annotation',
			headerName: 'Bemerkung',
			type: 'string',
			flex:'1',
			minWidth: 80,
			editable: false,
			sortable: false,
		},
		{
			field: 'creator',
			headerName: 'Ersteller',
			type: 'string',
			minWidth: 150,
			sortable: false,
		},
		{
			field: 'created',
			headerName: 'erstellt am',
			minWidth: 150,
			align: 'right',
			editable: false,
			valueGetter: formatDate,
		},
	]

	const getConfirmMessage = row => `Möchtest du die Transaktion wirklich löschen?`

	return(
        <MaterialTable
            open={open}
            onClose={onClose}
			title="Materialverbrauch"
			subtitle={subtitle}
			sublabel={sublabel}
            apiRef={apiRef}
            rows={txs}
            pageSize={pageSize}
            setPageSize={setPageSize}
			rowCount={pagInfo.count}
            handlePageChange={handlePageChange}
            columns={columns}
            loading={isLoading}
            initialPageSize={100}
			// onSaveRow={is_admin ? handleTXPatch(getTx) : undefined}
			onDeleteRow={is_admin ? handleTXDelete(getTx) : undefined}
			getConfirmMessage={is_admin ? getConfirmMessage : undefined}
			setSearchTerm={setSearchTerm}
			searchTerm={searchTerm}
			onOrderByGetParametersChange={handleOrderByGetParametersChange}
			actionBar={
				<Tooltip
					title="Zusammenfassung herunterladen"
				>
					<Link
						href={`/api/project/${project_id}/sub-project/${selectedSubProject}/material/tx/used/export`}
						download
					>
						<IconButton
							color="primary"
						>
							<Download/>
						</IconButton>
					</Link>
				</Tooltip>
			}
	/>
    )
}

export default MaterialCorrectionTable
