import { getCookie } from '../../../../../../utils/getCookie'

import { Stack, Typography, IconButton } from "@mui/material"
import { Clear, Add }from '@mui/icons-material';

import { PALETTE } from '../../../../../theme';
import CustomPaper from '../../../../../Widgets/CustomPaper';


const CompanyRequests = ({ project, companyRequests, getCompanyRequests }) =>{

    const acceptRequest = async(pc_allocator_id) => {
        const json = JSON.stringify({
            pc_allocator_id: pc_allocator_id
        })
        const res = await fetch('/api/project/' + project.id + '/companyrequests', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'X-CSRFToken': getCookie('csrftoken')
            },
            body: json
        })
        // const data = await res.json()
        if(res.ok){
            getCompanyRequests()
            // console.log(data)
        }
    }

    const deleteRequest = async(pc_allocator_id) => {
        const json = JSON.stringify({
            pc_allocator_id: pc_allocator_id
        })
        const res = await fetch('/api/project/' + project.id + '/companyrequests', {
            method: 'DELETE',
            headers: {
                'Content-Type': 'application/json',
                'X-CSRFToken': getCookie('csrftoken')
            },
            body: json
        })
        // const data = await res.json()
        if(res.ok){
            getCompanyRequests()
            // console.log(data)
        }
    }

    return(
        <CustomPaper
            title="Aktive Anfragen"
        >
            <Stack
                spacing={1}
            >
                <Typography variant="text_m">Hier finden Sie alle aktive Anfragen</Typography>
                <Stack
                    spacing={2}
                >
                    {companyRequests.length !== 0 ?
                        companyRequests.map((request, idx)=>{
                            return (
                                <Stack
                                    key={"company-requests-" + idx}
                                    direction="row"
                                    justifyContent="space-between"
                                    alignItems="flex-start"
                                    sx={{
                                        p:2,
                                        border: 1,
                                        borderRadius: '10px',
                                        borderColor: PALETTE.background.light70
                                    }}
                                >
                                    <Stack>
                                        <Typography
                                            variant="subtitle_m"
                                        >
                                            {request.company.name}
                                        </Typography>
                                        <Typography
                                            variant="text_m"
                                            color="primary"
                                        >
                                            {request.company.industry}
                                        </Typography>
                                        <Typography
                                            variant="text_s"
                                        >
                                            {request.company.address.street} {request.company.address.house_number} <br/> {request.company.address.postal_code} {request.company.address.city}
                                        </Typography>
                                    </Stack>
                                    <Stack
                                        direction="row"
                                        spacing={2}
                                    >
                                        <IconButton
                                            onClick={()=>{acceptRequest(request.id)}}
                                            color="primary"
                                            size="small"
                                        >
                                            <Add/>
                                        </IconButton>
                                        <IconButton
                                            onClick={()=>{deleteRequest(request.id)}}
                                            color="background"
                                            aria-label="delete"
                                            size="small"
                                        >
                                            <Clear/>
                                        </IconButton>
                                    </Stack>
                                </Stack>
                            );
                        }) :
                        <Typography variant="caption_m" color="secondary"> Derzeit gibt es keine Teilnahmeanfragen...</Typography>
                    }
                </Stack>
            </Stack>
        </CustomPaper>
    )
}

export default CompanyRequests