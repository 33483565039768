import { BarChart, Bar, XAxis, YAxis } from 'recharts';

import { Stack, CircularProgress, Typography } from '@mui/material';
import { BsCircleFill } from "react-icons/bs";

import  useWidth  from '../../../../hooks/useWidth'

import { PALETTE } from "../../../theme/index.jsx";
import CustomPaper from "../../../Widgets/CustomPaper/index.jsx";


const monthTickFormatter = (tick) => {
    const date = new Date(tick);
    return date.toLocaleDateString('de-DE',{ month:'long' });
};

const renderQuarterTick = (tickProps) => {
    const { x, y, payload } = tickProps;
    const { value, offset } = payload;
    const date = new Date(value);
    const month = date.getMonth();
    const quarterNo = Math.floor(month / 3) + 1;

    if (month % 3 === 1) {
        return <text x={x} y={y + 5} textAnchor="middle">{`Q${quarterNo}`}</text>;
    }

    const isLast = month === 11;

    if (month % 3 === 0 || isLast) {
        const pathX = Math.floor(isLast ? x + offset : x - offset) + 0.5;
        return <path d={`M${pathX},${y - 4}v${-35}`} stroke={PALETTE.secondary.main}/>;
    }
    return null;
};

const ResourceChart = ({ title, data, height, unit, barSize}) =>{

    const [ width, ref ] = useWidth(0)

    return(
        <CustomPaper
            title={title}
            padding={0}
            actionBar={
                <Stack
                    direction={'row'}
                    spacing={2}
                >
                    <Stack
                        direction='row'
                        justifyContent="center"
                        alignItems="center"
                        spacing={1}
                    >
                        <BsCircleFill size={10} color={PALETTE.primary.main}/>
                        <Typography variant="text_m">Verfügbare Stunden</Typography>
                    </Stack>
                    <Stack
                        direction='row'
                        justifyContent="center"
                        alignItems="center"
                        spacing={1}
                    >
                        <BsCircleFill size={10} color={PALETTE.primary.dark}/>
                        <Typography variant="text_m">Verplante Stunden</Typography>
                    </Stack>
                </Stack>
            }
        >
            <Stack>
                <Stack
                    justifyContent="center"
                    alignItems="center"
                    width="100%"
                    height={height}
                    ref={ref}
                >
                        {width !== 0 ?
                            <BarChart
                                width={width}
                                height={height}
                                data={data}
                                margin={{
                                    top: 15,
                                    right: 60,
                                    left: 60,
                                    bottom: 35,
                                }}
                                barGap={'5%'}
                            >
                                <XAxis dataKey="date" tickFormatter={monthTickFormatter} />
                                <XAxis
                                    dataKey="date"
                                    axisLine={false}
                                    tickLine={false}
                                    interval={0}
                                    tick={renderQuarterTick}
                                    height={1}
                                    scale="band"
                                    xAxisId="quarter"
                                />
                                <YAxis unit={unit}/>
                                <Bar
                                    dataKey="max_hours"
                                    fill={PALETTE.primary.main}
                                    barSize={barSize / 2}
                                    animationDuration={1000}
                                    radius={[5, 5, 0, 0]}
                                />
                                <Bar
                                    dataKey="current_hours"
                                    fill={PALETTE.primary.dark}
                                    barSize={barSize / 2}
                                    animationDuration={1000}
                                    radius={[5, 5, 0, 0]}
                                />
                            </BarChart>
                        :
                            <CircularProgress/>
                        }
                </Stack>
            </Stack>
        </CustomPaper>
    )
}

export default ResourceChart