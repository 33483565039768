import { useState, useEffect } from "react";
import { getCookie } from "../../../../../utils/getCookie";
import { Box, Stack, Button, Typography, Tooltip, DialogContent, Dialog, DialogTitle, DialogActions, Radio, RadioGroup, FormControlLabel, IconButton, Divider } from "@mui/material";
import CompanyMemberListItem from "../../../../Widgets/CompanyMemberListItem";
import { FaUsersCog } from "react-icons/fa";


const TransferMemberDialog = ({ workingGroups, getWorkingGroups }) =>{

    const [companyMembers, setCompanyMembers] = useState([])
    const [open, setOpen] = useState(false)

    const [groupSelected, setGroupSelected] = useState({});
    const handleGroupSelection = company_member_id => event => {
        setGroupSelected({ ...groupSelected, [company_member_id]: event.target.value });
    };

    const onClose = () => setOpen(false)

    const handleSave = async () => {
        const json = JSON.stringify({
            group_selection: groupSelected,
        })
        const res = await fetch('/api/company/workplanner/working_groups', {
            method: 'PATCH',
            headers: {
                'Content-Type': 'application/json',
                'X-CSRFToken': getCookie('csrftoken')
            },
            body: json,
        })
        if (res.ok) {
            onClose();
            getWorkingGroups();
        }
    }

    const getCompanyMembers = async () => {
        const res = await fetch('/api/company/members', {
            method: 'GET'
        })
        const json = await res.json()
        if (res.ok) {
            setCompanyMembers(json)
        }
    }

    useEffect(() => {
        getCompanyMembers()
    }, [])

    // initialGroupSelection
    useEffect(() => {
        let selection = {}
        for(let member of companyMembers){
            if(member.working_group != null){
                selection[member.id] = member.working_group
            } else{
                selection[member.id] = '0'
            }
        }
        setGroupSelected(selection)
    }, [companyMembers]);

    return (
        <>
            <Tooltip
                title="Arbeitsgruppen verwalten"
            >
                <IconButton color='primary' size="large" onClick={() => setOpen(true)}><FaUsersCog /></IconButton>
            </Tooltip>
            <Dialog
                open={open}
                onClose={onClose}
                maxWidth={'desktop'}
                fullWidth={true}
                scroll={'paper'}
            >
                <DialogTitle>
                    Arbeitsgruppen verwalten
                </DialogTitle>
                <DialogContent>
                    <Stack
                        sx={{p:1.5}}
                    >
                        {companyMembers.map((member, idx)=>{
                            return(
                                <Box key={idx}>
                                    <Stack
                                        key={idx}
                                        direction='row'
                                        justifyContent='space-between'
                                        alignItems='center'
                                        spacing={3}
                                    >
                                        <Stack
                                            direction='row'
                                            justifyContent='center'
                                            alignItems='center'
                                            sx={{
                                                minWidth: 300,
                                                maxWidth: 300,
                                            }}
                                        >
                                            <CompanyMemberListItem
                                                data={member}
                                            />
                                        </Stack>
                                        <Divider orientation="vertical" flexItem/>
                                        <RadioGroup
                                            row
                                            name="radio-buttons-group"
                                            value={groupSelected[member.id]}
                                            onChange={handleGroupSelection(member.id)}
                                        >
                                            {workingGroups.map((group, idx)=>{
                                                return(
                                                    <FormControlLabel
                                                        key={idx}
                                                        value={group.id}
                                                        control={<Radio color="primary"/>}
                                                        label={
                                                            <Typography
                                                                sx={{
                                                                    fontSize: 16,
                                                                    overflow: 'hidden',
                                                                    textOverflow: 'ellipsis',
                                                                    whiteSpace: 'nowrap',
                                                                    width: 150
                                                                }}
                                                            >
                                                                {group.name}
                                                            </Typography>
                                                        }

                                                    />
                                                )
                                            })}
                                            <FormControlLabel
                                                key={'none'}
                                                value={0}
                                                control={<Radio color="secondary"/>}
                                                label={
                                                    <Typography
                                                        sx={{
                                                            fontStyle: 'italic',
                                                            opacity: 0.7,
                                                            fontSize: 16,
                                                            overflow: 'hidden',
                                                            textOverflow: 'ellipsis',
                                                            whiteSpace: 'nowrap',
                                                            width: 150
                                                        }}
                                                    >
                                                        keine Arbeitsgruppe
                                                    </Typography>
                                            }/>
                                        </RadioGroup>
                                    </Stack>
                                    {companyMembers.length > idx + 1  && <Divider sx={{margin: 0}}/>}
                                </Box>
                            )
                        })}
                    </Stack>
                </DialogContent>
                <DialogActions>
                    <Button
                        onClick={onClose}
                        color="secondary"
                    >
                        Abbrechen
                    </Button>
                    <Button
                        onClick={handleSave}
                    >
                        Speichern
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    )
}

export default TransferMemberDialog
