import { useCallback, useContext, useEffect, useState } from "react"
import { Dialog, DialogContent, DialogContentText, Grid, Box, Stack, Alert, AlertTitle, Typography } from "@mui/material"

import ProjectContext from "context/ProjectContext"



const ProjectShield = ({ open, onClose }) => {

    const [project] = useContext(ProjectContext)
    const [shieldData, setShieldData] = useState({
        request_company_has_logo: false,
        logo_urls: [],
    })

    const getShieldData = useCallback(async () => {
        const res = await fetch(`/api/project/${project.id}/shield`, {
            method: 'GET'
        })
        const data = await res.json()
        if(res.ok){
            setShieldData(data)
        }
    }, [project.id])

    useEffect(()=>{
        if (open){
            getShieldData()
        }
    }, [open, getShieldData])

    return (
        <Dialog
            open={open}
            onClose={onClose}
            maxWidth='laptop'   
        > 
            <Stack
                
                sx={{
                    pt: 3,
                    pl: 3,
                }}
            >
                <Typography 
                    variant="title_m" 
                    sx={{
                        color: 'primary.main',
                    }}
                >
                    Projektschild
                </Typography>
                <Typography variant="subtitle_m">{project.name}</Typography>
            </Stack>
            <DialogContent>
                <DialogContentText gutterBottom>
                    {project.description}
                </DialogContentText>
                <Stack
                    gap={3}
                    sx={{
                        pt: 2
                    }}
                >
                    <Grid
                        container
                        spacing={2}
                        alignItems={'center'}
                    >
                        {shieldData.logo_urls.map((url, index) => (
                            <Grid
                                item
                                desktop={4}
                                laptop={4}
                                tablet={6}
                                mobile={12}
                                key={index}
                            >
                                <Box
                                    component='img'
                                    src={url}
                                    alt='not found'
                                    sx={{
                                        borderRadius: 8,
                                        width: '100%'
                                    }}
                                />   
                            </Grid>
                        ))}                         
                    </Grid>
                    {shieldData.logo_urls.length === 0 &&
                        <Alert
                            severity='info'
                            sx={{
                                boxShadow: 0
                            }}
                        >
                            <AlertTitle>{'Info'}</AlertTitle>
                            {'Keiner der Projektpartner hat ein Logo hochgeladen. Mach den Anfang und lade dein Logo hoch.'}
                        </Alert>
                    }
                    {!shieldData.request_company_has_logo &&
                        <Alert
                            severity='warning'
                            sx={{
                                boxShadow: 0
                            }}
                        >
                            <AlertTitle>{'Hinweis'}</AlertTitle>
                            {'Das Logo deiner Firma ist noch nicht hochgeladen. Bitte lade es hoch, um es auf deinem Projektschild zu sehen.'}
                        </Alert>
                    }
                </Stack>
            </DialogContent>
        </Dialog>
    )
}

export default ProjectShield