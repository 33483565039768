import { Button, CircularProgress, Dialog, DialogActions, DialogContent, DialogTitle, IconButton, Stack, Tooltip } from "@mui/material"
import OrderMailToButton from "components/Widgets/OderMailToButton"
import { BsFillFileEarmarkPdfFill } from "react-icons/bs"
import { getCookie } from "utils/getCookie"


const WarehouseMailOrderDialog = ({ open, setOpen, res, setRes,}) =>{

    const onClose = () =>{
        setOpen(false)
        setRes(null)
    }

    const handleSubmit = async() => {
        const json = JSON.stringify({
            company_stock_material_carrier_id: res.carrier_id,
            done: true,
        })
        const respsonse = await fetch('/api/company/warehouse/carrier',  {
            method: 'PATCH',
            headers: {
                'Content-Type': 'application/json',
                'X-CSRFToken': getCookie('csrftoken')
            },
            body: json
        })
        if(respsonse.ok){
            onClose()
        }
    }

    return(
        <Dialog
            open={open}
            onClose={onClose}
            maxWidth="tablet"
            fullWidth
        >
            <DialogTitle>
                {res === null ? "Dokument wird erstellt..." : "Dokument direkt versenden?"}
            </DialogTitle>
            <DialogContent>
                <Stack
                    alignItems="center"
                    justifyContent="center"
                    sx={{
                        height: 200
                    }}
                >
                    {res === null ?
                        <CircularProgress color="secondary" />
                    :
                        <Stack
                            direction="row"
                            alignItems="center"
                            justifyContent="center"
                            gap={10}
                        >
                            <Tooltip title="Dokument herunterladen">
                                <IconButton color='primary' href={res.file} target="_blank" download={res.type}>
                                    <BsFillFileEarmarkPdfFill size={75} />
                                </IconButton>
                            </Tooltip>
                            <OrderMailToButton
                                typeName={res.type}
                                fileLink={res.file}
                                supplierEmail={res.supplier_email}
                                creator={res.creator}
                                companyName={res.company_name}
                                size={75}
                            />
                        </Stack>
                    }
                </Stack>
                <DialogActions>
                    <Button
                        color="secondary"
                        onClick={onClose}
                    >
                        Mach ich später
                    </Button>
                    <Button
                        color="primary"
                        onClick={handleSubmit}
                        variant="outlined"
                    >
                        Erledigt
                    </Button>
                </DialogActions>
            </DialogContent>
        </Dialog>
    )
}

export default WarehouseMailOrderDialog
