import React from 'react'

import * as Bs from "react-icons/bs"
import * as Ri from "react-icons/ri"
import * as Ai from "react-icons/ai"
import * as Io  from "react-icons/io5";
import * as Io4  from "react-icons/io";
import * as Md from "react-icons/md"
import * as Fa from "react-icons/fa"

import LogoIcon from 'components/Widgets/Logos/LogoIcon'

import { PALETTE } from 'components/theme'




export const ICONSIZE = '18px'

export const DROPDOWNS = {
    projects: {
        projects: {
            title: 'Projekte',
            path: '/dashboard/projects',
            icon: <Bs.BsGrid3X3GapFill size={ICONSIZE} />,
        },
        add: {
            title: 'Projekt erstellen',
            path: '/dashboard/projects/addproject',
            icon: <Fa.FaPlus size={ICONSIZE} />,
            show: account => account.is_admin || account.is_manager,
        },
        possible: {
            title: 'Potenzielle Projekte',
            path: '/dashboard/projects/possible',
            icon: <Fa.FaQuestion size={ICONSIZE} />,
            show: account => account.is_admin || account.is_manager,
        },
        requests: {
            title: 'Projektanfragen',
            path: '/dashboard/projects/requests',
            icon: <Bs.BsEnvelopeFill size={ICONSIZE} />,
            show: account => account.is_admin || account.is_manager,
            notificationCount: notifications => notifications.project_request.length
        },
        search: {
            title: 'Projektsuche',
            path: '/dashboard/projects/search',
            icon: <Fa.FaSearch size={ICONSIZE} />,
            show: account => account.is_admin || account.is_manager,
        }
    },
    timerecording: {
        overview: {
            title: 'Zeiterfassung',
            path: '/dashboard/timerecording',
            icon: <Ri.RiTimerFill size={ICONSIZE} />,
        },
        checking: {
            title: 'Überprüfung',
            path: '/dashboard/timerecording/checking',
            icon: <Bs.BsFillCalendarCheckFill size={ICONSIZE} />,
            show: account => account.is_admin || account.is_manager,
        },
        export: {
            title: 'Export',
            path: '/dashboard/timerecording/export',
            icon: <Bs.BsFillFileEarmarkTextFill size={ICONSIZE} />,
            show: account => account.is_admin || account.is_manager,
        },
    },
    company: {
        overview: {
            title: 'Übersicht',
            path: '/dashboard/company',
            icon: <Ri.RiBuilding2Fill size={ICONSIZE} />,
        },
        statistics: {
            title: 'Auslastung',
            path: '/dashboard/company/statistics',
            icon: <Bs.BsBarChartLineFill size={ICONSIZE} />,
            show: account => account.is_admin || account.is_manager,
        },
        invite: {
            title: 'Mitarbeiter einladen',
            path: '/dashboard/company/invite',
            icon: <Io.IoPersonAdd size={ICONSIZE} />,
            show: account => account.is_admin || account.is_manager,
        },
        salaries: {
            title: 'Löhne & Gehälter',
            path: '/dashboard/company/salaries',
            icon: <Fa.FaMoneyCheck size={ICONSIZE} />,
            show: account => account.is_admin,
        },
        wageRate: {
            title: 'Stundenverrechnung',
            path: '/dashboard/company/wage-rate',
            icon: <Bs.BsCalculatorFill size={ICONSIZE} />,
            show: account => account.is_admin,
        },
        management: {
            title: 'Management',
            path: '/dashboard/company/management',
            icon: <Md.MdManageAccounts size={ICONSIZE} />,
            show: account => account.is_admin,
        },
    }
}

export const ITEMS = {
    projects: {
        title: 'Projekte',
        path: '/dashboard/projects',
        icon: <Bs.BsGrid3X3GapFill size={ICONSIZE} />
    },
    calendar: {
        title: 'Kalender',
        path: '/dashboard/calendar',
        icon: <Bs.BsFillCalendarEventFill size={ICONSIZE} />,
        notificationCount: notifications => notifications.company_calendar.length
    },
    internalChat: {
        title: 'Betriebschat',
        path: '/dashboard/company-chat',
        icon: <Io.IoChatbox size={ICONSIZE} />,
        notificationCount: notifications => notifications.internal_chat.length + notifications.company_blackboard.length
    },
    todo: {
        title: 'To-dos',
        path: '/dashboard/todos',
        icon: <Fa.FaCheckSquare size={ICONSIZE} />,
        notificationCount: notifications => notifications.to_do.length
    },
    timerecording: {
        title: 'Zeiterfassung',
        path: '/dashboard/timerecording',
        icon: <Ri.RiTimerFill size={ICONSIZE} />,
        show: account => true,
    },
    company: {
        title: 'Unternehmen',
        path: '/dashboard/company',
        icon: <Ri.RiBuilding2Fill size={ICONSIZE} />,
    },
    conmeetConnections: {
        title: 'CONMEET Netzwerk',
        path: '/dashboard/conmeet-connections',
        icon: <LogoIcon color={PALETTE.text.light} size={ICONSIZE} />,
        notificationCount: notifications => notifications.conmeet_connections_chat.length,
        show: account => account.allow_conmeet_connections
    },
    customer: {
        title: 'Kunden',
        path: '/dashboard/customer',
        icon: <Bs.BsPersonFill size={ICONSIZE} />,
        show: account => account.is_admin || account.is_manager,
    },
    supplier: {
        title: 'Lieferanten',
        path: '/dashboard/supplier',
        icon: <Fa.FaTruck size={ICONSIZE} />,
        show: account => account.is_admin || account.is_manager,
    },
}



// ---------------------------------------------------------------
// Project
// ---------------------------------------------------------------

export const PROJECT = {
    tasks: {
        title: 'Aufträge',
        relative: true,
        path: '/tasks',
        icon: <Bs.BsFillFolderFill size={ICONSIZE} />,
        notificationCount: notifications => notifications.sub_project.length,
        show: account => account.is_admin || account.is_manager,
    },
    chat: {
        title: 'Chat',
        relative: true,
        path: '/chat',
        icon: <Io.IoChatbox size={ICONSIZE} />,
        notificationCount: (notifications, { projectId }) =>
            notifications.private_project_chat.filter(n => n.project_id === projectId).length +
            notifications.project_blackboard.filter(n => n.project_id === projectId).length
    },
    members: {
        title: 'Team',
        relative: true,
        path: '/members',
        icon: <Bs.BsFillDiagram3Fill size={ICONSIZE} />,
    },
    timestamps: {
        title: 'Arbeitsstunden',
        relative: true,
        path: '/timestamps',
        icon: <Ri.RiTimerFill size={ICONSIZE} />,
        show: account => account.is_admin || account.is_manager,
    },
    agreements: {
        title: 'Vereinbarungen',
        relative: true,
        path: '/agreements',
        icon: <Fa.FaHandshake size={ICONSIZE} />,
    },
    events: {
        title: 'Termine',
        relative: true,
        path: '/events',
        icon: <Bs.BsFillCalendarEventFill size={ICONSIZE} />,
    },
    notes: {
        title: 'Informationen',
        relative: true,
        path: '/notes',
        icon: <Io.IoAlertCircle size={ICONSIZE} />,
    },
    todos: {
        title: 'Projekttodos',
        relative: true,
        path: '/todos',
        icon: <Fa.FaCheckSquare size={ICONSIZE} />,
    },
    tickets: {
        title: 'Tickets',
        relative: true,
        path: '/tickets',
        icon: <Io.IoTicketSharp size={ICONSIZE} />,
        notificationCount: notifications => notifications.ticket.length,
    },
    documents: {
        title: 'Dokumente',
        relative: true,
        path: '/documents',
        icon: <Bs.BsInboxesFill size={ICONSIZE} />,
    },
    chatmedia: {
        title: 'Chat-Medien',
        relative: true,
        path: '/chatmedia',
        icon: <Md.MdPermMedia size={ICONSIZE} />,
    },
    invite: {
        title: 'Einladungen',
        relative: true,
        path: '/invite',
        icon: <Bs.BsEnvelopeFill size={ICONSIZE} />,
        show: account => account.is_admin || account.is_manager,
    },
    documentation: {
        title: 'Archivierung',
        relative: true,
        path: '/documentation',
        icon: <Fa.FaFileDownload size={ICONSIZE} />,
        show: account => account.is_admin || account.is_manager,
    },
    management: {
        title: 'Einstellungen',
        relative: true,
        path: '/management',
        icon: <Io.IoSettingsSharp size={ICONSIZE} />,
        show: (_, projectMember) => projectMember.is_leader,
    },
}

