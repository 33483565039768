import { useState, useEffect } from 'react';

import { Delete, Edit, Save, Cancel } from '@mui/icons-material';
import { useGridApiRef, GridActionsCellItem } from '@mui/x-data-grid-pro';

import CustomBuisnessCustomerToolbar from './CustomBuisnessCustomerToolbar';
import { getCookie } from "../../../../utils/getCookie";
import PaperDataGrid from "../../../Widgets/CustomDatagrids/NoPagination/PaperDataGrid";


const getAddress = key => params => {
    return params.row.address[key]
}

const BuisnessCustomerTable = () => {

    const apiRef = useGridApiRef();
    const [customers, setCustomers] = useState([])
    const [isLoaded, setIsLoaded] = useState(false)
    const [errors, setErrors] = useState({});

    const getCustomers = async () => {
        const res = await fetch('/api/company/buisness_customer', {
            method: 'GET',
        })
        if (res.ok) {
            const data = await res.json()
            setCustomers(data)
            setIsLoaded(true)
            console.log(data)
        }
    }

    useEffect(()=>{
        getCustomers()
    }, [])

	const handleSave = async (row) => {
		editCustomer(row)
	  };

	const editCustomer = async(row) =>{
		const json = JSON.stringify({
            ...row,
            address: {
                street: row.street,
                house_number: row.house_number,
                add_on: row.add_on,
                postal_code: row.postal_code,
                city: row.city
            }
        })
        const res = await fetch('/api/company/buisness_customer', {
            method: 'PATCH',
            headers: {
                'Content-Type': 'application/json',
                'X-CSRFToken': getCookie('csrftoken')
            },
            body: json
        })
		const data = await res.json()
		if(res.ok){
			apiRef.current.updateRows([{ ...data, isNew: false }]);
            setErrors({})
		} else{
			console.log(data)
            setErrors(data)
		}
    }

	const handleDelete = async(rowId) => {
        const json = JSON.stringify({customer_id: rowId})
        const res = await fetch('/api/company/buisness_customer', {
            method: 'DELETE',
            headers: {
                'Content-Type': 'application/json',
                'X-CSRFToken': getCookie('csrftoken')
            },
            body: json
        })
		return res.ok
    }

    const columns = [
        {
			field: 'id',
			headerName: 'Kunden-ID',
			type: 'number',
			minWidth: 100,
            hide: true,
		},
		{
			field: 'name',
			headerName: 'Name',
			type: 'string',
			minWidth: 200,
            editable: true,
		},
		{
			field: 'contact_person',
			headerName: 'Ansprechpartner',
			type: 'string',
			minWidth: 250,
            editable: true,
		},
        {
			field: 'fixed_phone_number',
			headerName: 'Telefon (Festnetz)',
			type: 'string',
			minWidth: 150,
            editable: true,
		},
		{
			field: 'mobil_phone_number',
			headerName: 'Telefon (Mobil)',
			type: 'string',
			minWidth: 150,
            editable: true,
		},
		{
			field: 'email',
			headerName: 'E-Mail',
			type: 'string',
            flex:'1',
			minWidth: 150,
            editable: true,
		},
		{
			field: 'street',
			headerName: 'Straße',
			type: 'string',
			minWidth: 200,
            editable: true,
            valueGetter: getAddress('street'),
		},
		{
			field: 'house_number',
			headerName: 'Hausnr.',
			type: 'string',
			minWidth: 30,
            editable: true,
            valueGetter: getAddress('house_number'),
		},
        {
			field: 'add_on',
			headerName: 'Zusatz',
			type: 'string',
			minWidth: 20,
            editable: true,
            valueGetter: getAddress('add_on'),
		},
        {
			field: 'postal_code',
			headerName: 'PLZ',
			type: 'string',
			minWidth: 50,
            editable: true,
            valueGetter: getAddress('postal_code'),
		},
        {
			field: 'city',
			headerName: 'Stadt',
			type: 'string',
			minWidth: 70,
            editable: true,
            valueGetter: getAddress('city'),
		},
	]

	const getConfirmMessage = row => `Möchtest du den Gewerbekunden ${row.name} wirklich löschen?`

	return(
        <PaperDataGrid
            title="Gewerbekunden"
            rows={customers}
            columns={columns}
            apiRef={apiRef}
			actionBar={<CustomBuisnessCustomerToolbar apiRef={apiRef} getCustomers={getCustomers} />}
            loading={!isLoaded}
            errors={errors}
            initialPageSize={20}
			onSaveRow={handleSave}
			onDeleteRow={handleDelete}
			getConfirmMessage={getConfirmMessage}
        />
    )
}

export default BuisnessCustomerTable
