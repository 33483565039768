import { Box, Link } from "@mui/material";
import AppleStoreImage from "../../../assets/svgs/Download_on_the_App_Store_Badge_DE_RGB_blk_092917.svg"



const AppleStoreLink = ({ height="100%" }) => {
    return (
        <Link
            height={height}
            href='https://apps.apple.com/de/app/conmeet/id6443846597'
            target="_blank"
            rel="noreferrer"
        >
            <Box
                height={height}
                sx={{ backgroundColor: 'white'}}
            >
                <img
                    height="100%"
                    alt='Laden im App Store'
                    src={AppleStoreImage}
                />
            </Box>
        </Link>
    );
}

export default AppleStoreLink;