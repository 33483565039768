import { Paper,} from "@mui/material"

import PaginationDataGrid from "../PaginationDataGrid"
import CustomDataGrid from "../../CustomDataGrid";
import PaginationToolbar from "../PaginationToolbar";


const PaperPaginationDataGrid = ({
     apiRef,
     title,
     subtitle,
     rows,
     columns,
     pageSize,
     onPageSizeChange,
     onPageChange,
     rowCount,
     loading,
     actionBar,
     searchTerm,
     setSearchTerm,
     errors={},
     ...props
 }) => (
    <Paper>
        <CustomDataGrid
            rows={rows}
            columns={columns}
            apiRef={apiRef}
            Component={PaginationDataGrid}
            loading={loading}
            errors={errors}
            Toolbar={PaginationToolbar}
            toolbarProps={{
                apiRef: apiRef,
                title: title,
                subtitle: subtitle,
                searchTerm: searchTerm,
                setSearchTerm: setSearchTerm,
                actionBar: actionBar
            }}
            initialPageSize={25}
            pageSize={pageSize}
            onPageSizeChange={onPageSizeChange}
            onPageChange={onPageChange}
            rowCount={rowCount}
            {...props}
        />
    </Paper>
)


export default PaperPaginationDataGrid
