import { useForm } from "react-hook-form";

import { Stack, Button, Typography, Dialog, DialogTitle, DialogContent, DialogActions } from '@mui/material';

import { getCookie } from "../../../../../utils/getCookie";

import useStyledSnackbar from '../../../../../hooks/useStyledSnackbar';
import ControlledTextField from '../../../../Widgets/ControlledInputs/ControlledTextField';
import { useEffect } from "react";


const EditCategory = ({ open, handleClose,  category, getMaterialCategories, setHaveToLoad }) => {

    const { enqueueSnackbar } = useStyledSnackbar()
    const { control, handleSubmit, reset, setValue, formState: { errors, isDirty, isValid } } = useForm({
        mode: 'onChange',
        defaultValues:{
            name: '',
            key: '',
        }
    });

    const handleEdit = async(data) =>{
        const json = JSON.stringify(data)
		const res = await fetch('/api/company/materials/category', {
            method: 'PATCH',
            headers: {
                'Content-Type': 'application/json',
                'X-CSRFToken': getCookie('csrftoken')
            },
            body: json
        })
        const res_data = await res.json()
        if(res.ok){
            handleClose()
            setHaveToLoad(true)
            reset()
            getMaterialCategories()
            enqueueSnackbar('Die Kategorie wurde erfolgreich verändert!', {
                variant: 'success'
            })
        } else{
            console.log(res_data)
            enqueueSnackbar('Es ist ein Fehler aufgetreten', {
                variant: 'error'
            })
        }
    }

    useEffect(()=>{
        if(category){
            for (const [key, value] of Object.entries(category)) {
                setValue(key, value, { shouldValidate: false, shouldDirty: false })
            }
        }
    },[category, setValue])

    return(
        <Dialog
            open={open}
            onClose={handleClose}
            maxWidth="tablet"
        >
            <DialogTitle>
                Kategorie verändern
            </DialogTitle>
            <DialogContent>
            <Stack
                component='form'
                id="form-handle-edit"
                onSubmit={handleSubmit(handleEdit)}
                spacing={2}
                width="100%"
            >
                <Typography variant="body1">Hier kannst die Kategorie verändern</Typography>
                <ControlledTextField
                    name="name"
                    label="Name der Kategorie"
                    control={control}
                    rules={{ required: true }}
                    errors={errors}
                />
                <ControlledTextField
                    name="key"
                    label="Kategorienschüssel"
                    control={control}
                    rules={{ required: true, maxLength: {value: 3, message: "max. 4 Ziffern!"} }}
                    errors={errors}
                />
            </Stack>
            </DialogContent>
            <DialogActions>
                <Button
                    onClick={handleClose}
                >
                    Schließen
                </Button>
                <Button
                    form="form-handle-edit"
                    color="primary"
                    type="submit"
                    disabled={!isValid || !isDirty}
                >
                    Speichern
                </Button>
            </DialogActions>
        </Dialog>
    )
}

export default EditCategory