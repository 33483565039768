import { useContext } from 'react'
import { Link, useParams } from 'react-router-dom'

import { Badge, ListItemButton, ListItemText, ListItemIcon } from '@mui/material'

import { NotificationContext } from '../../context/NotificationContext'


const NavItem = ({ item, basePath, isActive, onClick, activeBackground, SETTINGS }) => {
    const params = useParams()
    const projectId = parseInt(params.id)
    const [ notifications ] = useContext(NotificationContext)

    const notificationCount = item.notificationCount ?
        item.notificationCount(notifications, { projectId: projectId })
        :
        0

    return (
        <ListItemButton
            sx={{
                gap: SETTINGS.itemContentGap,
                backgroundColor: isActive ? activeBackground : 'none',
                flex: '0 0 ' + SETTINGS.itemHeight,
                borderRadius: SETTINGS.itemBorderRadius,
                overflow: 'hidden',
                p: 2,
                '&:hover': {
                    backgroundColor: isActive ? activeBackground : SETTINGS.colors.hoverBackground
                }
            }}
            onClick={onClick}
            component={Link}
            to={(item.relative ? basePath : '') + item.path}
        >
            <ListItemIcon sx={{ minWidth: 'auto', color: isActive ? SETTINGS.colors.iconActive : 'inherit' }}>
                <Badge badgeContent={notificationCount} color="secondary">
                    {item.icon}
                </Badge>
            </ListItemIcon>
            <ListItemText
                variant="bright"
                primary={item.title}
                secondary={item.subtitle}
                sx={{
                    whiteSpace: 'nowrap',
                }}
            />
        </ListItemButton>
    )
}

export default NavItem
