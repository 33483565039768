import { Controller } from 'react-hook-form'

import { TextField } from '@mui/material'
import { TimePicker } from '@mui/x-date-pickers-pro'

const ControlledTimePicker = ({ name, control, label, rules, minTime, maxTime}) =>{

    return(
        <Controller
            control={control}
            name={name}
            rules={rules}
            render={({field: { value, onChange }}) => (
                <TimePicker
                    renderInput={(props) => <TextField {...props} fullWidth/>}
                    value={value}
                    onChange={onChange}
                    label={label}
                    inputFormat='HH:mm'
                    mask='__:__'
                    minTime={minTime}
                    maxTime={maxTime}
                />
            )}
        />
    )
}

export default ControlledTimePicker