import { useContext }from 'react'

import { Stack } from '@mui/material'
import useMediaQuery from '@mui/material/useMediaQuery';

import Contacts from '../../../Widgets/Chat/Contacts';

import { NotificationContext } from '../../../../context/NotificationContext'
import ProjectContext from '../../../../context/ProjectContext';

import './ProjectChat.css'
import ProjectBlackBoard from '../ProjectBlackboard';

const ProjectChat = () => {
    const [notifications] = useContext(NotificationContext)
    const [project] = useContext(ProjectContext)

    const breakpoint = useMediaQuery('(min-width: 1420px)');

    return (
        <Stack
            sx={{ minHeight: '100%' }}
            direction={breakpoint ? 'row' : 'column'}
            alignItems='stretch'
            gap={3}
        >
            <Stack sx={{ flex: '1 0 0', minHeight: '700px' }}>
                <ProjectBlackBoard />
            </Stack>
            <Stack sx={{ flex: '2 0 0', minHeight: '700px' }}>
                <Contacts
                    contactsURL={'/api/project/' + project.id + '/chat'}
                    notifications={notifications.private_project_chat.filter(n => n.project_id === project.id)}
                />
            </Stack>
        </Stack>

    )
}

export default ProjectChat
