import { useState, useEffect, useContext } from 'react';
import { useForm } from 'react-hook-form';
import AccountContext from 'context/AccountContext';
import {
    Box,
    Stack,
    Button,
    TextField,
    Dialog,
    List,
    ListItem,
    ListItemIcon,
    Typography,
    IconButton,
    InputAdornment,
    FormControlLabel,
    FormGroup,
    Switch,
    Divider,
    ToggleButtonGroup,
    ToggleButton,
    CircularProgress
} from '@mui/material';

import { Add, Clear, Remove } from '@mui/icons-material';

import useStyledSnackbar from '../../../../../../hooks/useStyledSnackbar'

import { getCookie } from '../../../../../../utils/getCookie';
import { checkSelectionZero, formatNumber } from '../../../../../../utils/helper';

import ControlledTextField from '../../../../../Widgets/ControlledInputs/ControlledTextField';
import ControlledSelect from '../../../../../Widgets/ControlledInputs/ControlledSelect';

import SearchMaterialBar from './SearchMaterialbar';

import NoData from '../../../../../Widgets/NoData';
import LogoIcon from '../../../../../Widgets/Logos/LogoIcon';
import { PALETTE } from '../../../../../theme';
import { BsFillLockFill, BsFillUnlockFill } from 'react-icons/bs';
import MissingMaterial from './MissingMaterial';

import MailOrderDialog from '../MailOrderDialog';


const handleVerb = (type_key) =>{
    switch(type_key){
        case 0:
            return 'hinzufügen'
        case 1:
            return 'hinzufügen und aus Lager buchen'
        case 2:
            return 'hinzufügen und frei buchen'
        case 3:
            return 'hinzufügen und bestellen'
        default:
            return ''
    }
}

const SearchMaterialDialog = ({ open, onClose, project_id, selectedSubProject, getSubProjectMaterial, getValues, suppliers, sublabel }) =>{

    const { enqueueSnackbar } = useStyledSnackbar()
    const [account] = useContext(AccountContext)
    let globalError = false
    const [searchMaterials, setSearchMaterials] = useState([]);
    const [materials, setMaterials] = useState([]);
    const [sum, setSum] = useState({
        offer_sum: 0,
        tx_sum: 0
    })
    const [isLoading, setIsLoading] = useState(false)
    const [txTypeKey, setTxTypeKey] = useState(0);
    const [verb, setVerb] = useState('buchen');
    const handleTxTypeKey = (event, value) => {
        if (value !== null) {
            setTxTypeKey(value);
            setVerb(handleVerb(value))
        }
    };

    const [openDialog, setOpenDialog] = useState(false)
    const [res, setRes] = useState(null)

    const [isCalculated, setIsCalculated] = useState(true);
    const handleIsCalculated = (event) => {
        setIsCalculated(event.target.checked);
    };

    const { control, handleSubmit, reset, formState: { errors, isDirty, isValid } } = useForm({
        mode: 'onChange',
        defaultValues: {
            place_of_delivery: '',
            annotation: '',
            supplier_id: '',
        }
    });

    const handleOnClose = () => {
        onClose()
        console.log('------------------')
        setSum({
            offer_sum: 0,
            tx_sum: 0
        })
        reset()
    }

    const handleAddMaterial = (material) => () => {
        setSearchMaterials(searchMaterials.filter(m => m.id !== material.id));
        let add = materials.some(m => m.id === material.id)
        if(!add){
            setMaterials(prevMaterial => [...prevMaterial, {...material, ...{ offer_value: '', value: ''} }])
        }
    };

    const handleRemoveMaterial = (material, idx) => () => {
        setMaterials(materials.filter(m => m.id !== material.id));
        let add = searchMaterials.some(m => m.id === material.id)
        if(!add){
            setSearchMaterials(prevMaterial => [...prevMaterial, {...material, ...{ offer_value: '', value: ''}}])
        }
    };

    const handleValueChange = (e, idx, key) => {
        let _data = [...materials]
        _data[idx][key] = e.target.value
        setMaterials(_data);
    };

    const handleFillMaterialDepot = async(data) =>{

        let is_valid = true
        materials.forEach(( m, idx ) =>{
            const _value = m.value !== '' ? m.value : 0
            materials[idx].value = _value
            materials[idx].offer_value = m.offer_value !== '' ? m.offer_value : 0
            if(_value === 0 ){
                is_valid = false
            }
        })

        if(!is_valid && txTypeKey !== 0){
            enqueueSnackbar(`Mindestens ein Material hat keine Menge angeben!`, {
                variant: 'error'
            })
            return
        }

        materials.forEach(( m, idx ) => {
            materials[idx].value = m.value !== '' ? m.value : 0
            materials[idx].offer_value = m.offer_value !== '' ? m.offer_value : 0
        })

        const json = JSON.stringify({
            ...data,
            supplier_id: checkSelectionZero(data.supplier_id),
            materials: materials,
            type_key: txTypeKey,
            is_calculated: isCalculated,
        })

		const res = await fetch('/api/project/' + project_id + '/sub-project/' + selectedSubProject + '/material', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'X-CSRFToken': getCookie('csrftoken')
            },
            body: json
        })
        const _data = await res.json()
        if(res.ok){
            getSubProjectMaterial()
            getValues()

            enqueueSnackbar('Die Materialien wurden erfolgreich zum Depot hinzugefügt!', {
                variant: 'success'
            })

            if(txTypeKey === 3){
                setRes(_data)
                setOpenDialog(true)
            } else {
                handleOnClose()
            }
        } else {
            console.log(_data)
        }
    }

    const getSearchedMaterials = async(data) =>{
        setIsLoading(true)
        const json = JSON.stringify({
            ...data,
            category: checkSelectionZero(data.category),
            manufacturer: checkSelectionZero(data.manufacturer)
        })
		const res = await fetch('/api/company/materials/search', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'X-CSRFToken': getCookie('csrftoken')
            },
            body: json
        })
        const _data = await res.json()
        if(res.ok){
            setSearchMaterials(_data)
            setIsLoading(false)
        }
    }

    // handleSumValue
    useEffect(() => {
        let offer_sum = 0
        let tx_sum = 0
        materials.forEach(material => {

            const haveValueOffered = material.value_offered !== undefined &&  material.value_offered !== ''
            const havePrePrice = material.pre_price !== undefined &&  material.pre_price !== ''
            const haveValue = material.value !== undefined &&  material.value !== ''
            const havePostPrice = material.post_price !== undefined &&  material.post_price !== ''

            const stockPrice = material.company_stock_material.stock_price
            const haveStockPrice = stockPrice  !== undefined &&  stockPrice !== '' && stockPrice !== 0

            offer_sum += parseFloat(haveValueOffered ? material.value_offered : 0) * parseFloat(havePrePrice ? material.pre_price : 0)
            tx_sum += parseFloat(haveValue ? material.value : 0) * (txTypeKey === 1 ? stockPrice : parseFloat(havePostPrice ? material.post_price : haveStockPrice ? stockPrice : material.price))

        })
        setSum({
            offer_sum: offer_sum,
            tx_sum: txTypeKey !== 0 ? tx_sum : 0
        })
    }, [materials, txTypeKey])

    useEffect(()=>{
        if(openDialog === false){
            handleOnClose()
        }
    },[openDialog])

    return(
        <Dialog
            open={open}
            onClose={handleOnClose}
            fullScreen
        >
            <Stack
                direction='row'
                justifyContent='space-between'
                alignItems="center"
                sx={{ p: 2 }}
            >
                <Stack
                    direction='row'
                    justifyContent='center'
                    alignItems="center"
                >
                    <LogoIcon size='40px'/>
                    <Stack
                        justifyContent="flex-start"
                        alignItems="flex-start"
                        sx={{pl: 2}}
                    >
                        <Typography variant="title_paper">
                            Material zum Projektauftrag hinzufügen
                        </Typography>
                        <Typography variant="subtitle_s" color="primary">
                            {sublabel}
                        </Typography>
                    </Stack>
                </Stack>
                <Stack
                    direction='row'
                    alignItems='center'
                    spacing={2}
                >
                    <IconButton
                        color="secondary"
                        onClick={handleOnClose}
                        size="large"
                    >
                        <Clear fontSize="large"/>
                    </IconButton>
                </Stack>
            </Stack>
            <Divider flexItem/>
            <Stack
                direction='row'
                alignContent="stretch"
                width="100%"
                height="100%"
                spacing={3}
                sx={{ flex: 1, minHeight: 0 }}
            >
                <Stack
                    width="100%"
                    spacing={2}
                    sx={{ flex : 0.8 , pl:3, pt: 3, pb: 3}}
                >
                    <SearchMaterialBar
                        getSearchedMaterials={getSearchedMaterials}
                    />
                    <MissingMaterial
                        project_id={project_id}
                        selectedSubProject={selectedSubProject}
                    />
                    <Stack
                        sx={{ flex : 1, overflow: 'hidden' }}
                    >
                        <List
                            sx={{
                                width: "100%",
                                flex : 1,
                                overflow: 'auto',
                                mt:0,
                                p:2,
                                border: 1,
                                borderRadius: '7px',
                                borderColor: PALETTE.background.light70
                            }}
                        >
                            {isLoading ?
                                    <Stack
                                        height="100%"
                                        width="100%"
                                        justifyContent='center'
                                        alignItems="center"
                                    >
                                        <CircularProgress/>
                                    </Stack>
                                :
                                searchMaterials.length !== 0 ?
                                    searchMaterials.map((material, idx) => {
                                        return (
                                        <ListItem
                                            key={`material-listitem ${material.id}-${idx}`}
                                            disablePadding
                                            sx={{pb: 1}}
                                        >
                                            <ListItemIcon>
                                                <IconButton
                                                    onClick={handleAddMaterial(material)}
                                                >
                                                    <Add color="primary"/>
                                                </IconButton>
                                            </ListItemIcon>
                                            <Stack
                                                sx={{flex: 1}}
                                            >
                                                <Typography
                                                        variant='subtitle_s'
                                                    >
                                                        {material.description}
                                                </Typography>
                                                <Typography
                                                        variant='text_m'
                                                    >
                                                        {`Art.-Nr. ${material.unique_material_id} | ${material.manufacturer}`}
                                                </Typography>
                                            </Stack>
                                            <Typography
                                                    sx={{ fontSize: 14, fontWeight: 'bold', color: 'background.light10'}}
                                                >
                                                    {`${formatNumber(material.price, 2, material.unit)}`}
                                            </Typography>
                                        </ListItem>
                                        );
                                    })
                                :
                                    <NoData text="Kein Suchergebnis"/>
                            }
                        </List>
                    </Stack>
                </Stack>
                <Divider orientation="vertical" flexItem/>
                <Stack
                    spacing={3}
                    sx={{ flex : 1.2, pr:3, pt: 3, pb: 3}}
                    width={'100%'}
                >
                    <ToggleButtonGroup
                        color="primary"
                        value={txTypeKey}
                        exclusive
                        onChange={handleTxTypeKey}
                        size="small"
                        fullWidth
                    >
                        <ToggleButton value={0}>
                            Ohne Transaktion
                        </ToggleButton>
                        <ToggleButton
                            value={1}
                            disabled={!account.company.paid}
                        >
                            Mit Lagerbuchung
                        </ToggleButton>
                        <ToggleButton value={2}>
                            Mit freier Buchung
                        </ToggleButton>
                        <ToggleButton
                            value={3}
                            disabled={!account.company.paid}
                        >
                            Mit Bestellung
                        </ToggleButton>
                    </ToggleButtonGroup>
                    <Stack
                        width={'100%'}
                        sx={{ flex : 1, overflow: 'hidden' }}
                    >
                        <List
                            sx={{
                                width: "100%",
                                overflow: 'auto',
                                flex: 1,
                                p:2,
                                border: 1,
                                borderRadius: '7px',
                                borderColor: PALETTE.background.light70
                            }}
                        >
                            {materials.length !== 0 ?
                                materials.map((material, idx) => {
                                    const unit_str = material.unit.split('/')
                                    const unit_suf = unit_str[1]

                                    const aviable_balance = material.company_stock_material.balance + material.company_stock_material.reserved
                                    const error = material.value > aviable_balance && (txTypeKey === 1)
                                    if(error){ globalError = true}
                                    return (
                                        <ListItem
                                            key={`material-listitem ${material.id}-${idx}`}
                                            disablePadding
                                            sx={{pb: 2}}
                                        >
                                                <ListItemIcon>
                                                    <IconButton
                                                        onClick={handleRemoveMaterial(material, idx)}
                                                    >
                                                        <Remove color="primary"/>
                                                    </IconButton>
                                                </ListItemIcon>
                                                <Stack
                                                    sx={{flex: 1}}
                                                >
                                                    <Typography
                                                            variant='subtitle_s'
                                                        >
                                                            {material.description}
                                                    </Typography>
                                                    <Typography
                                                            variant='text_m'
                                                        >
                                                            {`Art.-Nr. ${material.unique_material_id} | ${material.manufacturer}`}
                                                    </Typography>
                                                    <Typography
                                                            sx={{ fontSize: 12, color: 'background.light20'}}
                                                        >
                                                            {`Standardpreis ${formatNumber(material.price, 2, material.unit)} | Lagerpreis ${formatNumber(material.company_stock_material.stock_price, 2, material.unit)}`}
                                                    </Typography>
                                                    <Stack
                                                        direction={'row'}
                                                        alignItems="center"
                                                        spacing={1}
                                                    >
                                                        <Typography
                                                                sx={{ fontSize: 14, color: 'primary.main'}}
                                                            >
                                                                {`Im Lager ${formatNumber(aviable_balance, 2, unit_suf)}`}
                                                        </Typography>
                                                        {material.company_stock_material.is_locked ?
                                                            <BsFillLockFill size={10} color={PALETTE.error.main}/>
                                                        :
                                                            <BsFillUnlockFill size={10} color={PALETTE.primary.main}/>
                                                        }
                                                    </Stack>
                                                </Stack>
                                                <Stack
                                                    direction={'row'}
                                                    justifyContent='center'
                                                    alignItems="center"
                                                    spacing={2}
                                                >
                                                    <Stack
                                                        spacing={1}
                                                    >
                                                        <TextField
                                                            placeholder="0"
                                                            type="number"
                                                            label="Angebotsmenge"
                                                            size="small"
                                                            InputProps={{
                                                                endAdornment: <InputAdornment position="end">{unit_suf}</InputAdornment>,
                                                            }}
                                                            sx={{ width: 200  }}
                                                            value={materials.offer_value}
                                                            onChange={(e) => handleValueChange(e, idx, 'value_offered')}

                                                        />
                                                        <TextField
                                                            placeholder="0"
                                                            label="Menge"
                                                            type="number"
                                                            size="small"
                                                            InputProps={{
                                                                endAdornment: <InputAdornment position="end">{unit_suf}</InputAdornment>,
                                                            }}
                                                            sx={{ width: 200}}
                                                            value={materials.value}
                                                            onChange={(e)=> handleValueChange(e, idx, 'value')}
                                                            disabled={txTypeKey === 0 || material.company_stock_material.is_locked}
                                                            error={error}
                                                            helperText={error ? 'Nicht verfügbar' : '' }
                                                        />
                                                    </Stack>
                                                    <Stack
                                                        spacing={1}
                                                    >
                                                        <TextField
                                                            placeholder="0"
                                                            type="number"
                                                            label="Angebotspreis"
                                                            size="small"
                                                            InputProps={{
                                                                endAdornment: <InputAdornment position="end">€</InputAdornment>,
                                                            }}
                                                            sx={{ width: 200 }}
                                                            value={materials.pre_price}
                                                            onChange={(e) => handleValueChange(e, idx, 'pre_price')}
                                                        />
                                                        <TextField
                                                            placeholder="0"
                                                            label={txTypeKey !== 3 ? "Preis" : "Bestellpreis"}
                                                            type="number"
                                                            size="small"
                                                            InputProps={{
                                                                endAdornment: <InputAdornment position="end">€</InputAdornment>,
                                                            }}
                                                            sx={{ width: 200}}
                                                            value={materials.post_price}
                                                            onChange={(e)=> handleValueChange(e, idx, 'post_price')}
                                                            disabled={txTypeKey === 0 || txTypeKey === 1 || material.company_stock_material.is_locked}
                                                        />
                                                    </Stack>
                                                </Stack>
                                        </ListItem>
                                    );
                                })
                            :
                                <NoData text="Kein Material ausgewählt"/>
                            }
                        </List>
                    </Stack>
                    <Stack
                        component="form"
                        onSubmit={handleSubmit(handleFillMaterialDepot)}
                        spacing={2}
                    >
                        {txTypeKey !== 0 &&
                            <Stack spacing={2}>
                                {txTypeKey === 3 &&
                                    <Stack
                                        direction='row'
                                        spacing={2}
                                    >
                                        <ControlledSelect
                                            control={control}
                                            errors={errors}
                                            rules={{required: txTypeKey === 3 ? true: false }}
                                            name="supplier_id"
                                            label={`Lieferant ${txTypeKey === 3 ? ' ': '(optional)'}`}
                                            items={suppliers}
                                        />
                                        <ControlledTextField
                                            control={control}
                                            errors={errors}
                                            name="place_of_delivery"
                                            label="Lieferort (optional)"
                                            rules={{required: false}}
                                        />
                                    </Stack>
                                }
                                <ControlledTextField
                                    control={control}
                                    errors={errors}
                                    name="annotation"
                                    label="Anmerkung"
                                    rules={{required: true}}
                                    rows={3}
                                    multiline
                                />
                                <FormGroup>
                                    <FormControlLabel control={
                                        <Switch
                                            checked={isCalculated}
                                            onChange={handleIsCalculated}
                                        />
                                    }
                                    label="Transaktionsmenge als kalkuliert kennzeichnen" />
                                </FormGroup>
                            </Stack>
                        }
                        <Button
                            color="primary"
                            type="submit"
                            variant="outlined"
                            disabled={txTypeKey !== 0 && (!isValid || !isDirty || globalError)}
                        >
                            {`Material ${verb}`}
                        </Button>
                    </Stack>
                    <Box>
                        <Typography variant="text_s">{`${materials.length} Artikel ausgewählt  |  Angebotsteilsumme ${formatNumber(sum.offer_sum, 2 , '€')} |  ${txTypeKey === 3 ? 'Bestellsumme' : 'Transaktionssumme'} ${formatNumber(sum.tx_sum, 2 , '€')}`}</Typography>
                    </Box>
                </Stack>
            </Stack>
            <MailOrderDialog
                open={openDialog}
                setOpen={setOpenDialog}
                res={res}
                setRes={setRes}
                project_id={project_id}
                sub_project_id={selectedSubProject}
            />
        </Dialog>
    )
}

export default SearchMaterialDialog