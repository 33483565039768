import { useState, useEffect, useCallback, useContext } from 'react'

import { Avatar, Box, Chip, Stack, Typography} from '@mui/material'

import AccountContext from '../../../context/AccountContext'
import ControlledSelectGrouping from './ControlledSelectGrouping'

const ProjectTeamMemberMultiSelection = ({ control, errors, name, label, multiple=false, rules, project_id, disabled=false}) => {

    const [account] = useContext(AccountContext)
    const [projectTeamMembers, setProjectTeamMembers] = useState([])

    const getProjectTeamMembers = useCallback(async() => {
        if (project_id === '') return
        const res = await fetch('/api/project/' + project_id + '/team-members', {
            method: 'GET'
        })
        const json = await res.json()
        if (res.ok) {
            const transformed = []
            for (const ptm of json) {
                if(ptm.id === account.company.id){continue; }
                const temp = {
                    subheader: ptm.name,
                    subitems : {}
                }
                for(const pm of ptm.project_members){
                    temp.subitems[pm.id] = pm
                }
                transformed.push(temp)
            }
            console.log(transformed)
            setProjectTeamMembers(transformed)
        }
    }, [project_id])

    useEffect(() => {
        getProjectTeamMembers()
    }, [getProjectTeamMembers])

    const getProjectMemberLabel = (key) =>{
        let label = ''
        projectTeamMembers.forEach((company)=>{
            if(key in company.subitems){
                label = `${company.subitems[key].firstname} ${company.subitems[key].lastname} / ${company.subheader}`
                return
            }
        })
        return label
    }

    return (
        <ControlledSelectGrouping
            control={control}
            errors={errors}
            name={name}
            label={label}
            items={projectTeamMembers}
            multiple={multiple}
            rules={rules}
            disabled={disabled}
            renderValue={multiple ?
                (selected) => (
                    <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                        {selected.map((key) => {
                            return(
                                <Chip key={key} label={getProjectMemberLabel(key)} />
                            )
                        })}
                    </Box>
                )
                :
                null
            }
            itemRender={item =>
                <Stack gap={2} direction='row' alignItems='center'>
                    <Avatar alt='' src={item.avatar} />
                    <Typography>{item.firstname} {item.lastname}</Typography>
                </Stack>
            }
        />
    )
}

export default ProjectTeamMemberMultiSelection