import * as Bs from "react-icons/bs"
import * as Ai from "react-icons/ai"
import * as Md from "react-icons/md"
import * as Fa from "react-icons/fa"

import { ITEMS as DEFAULT_ITEMS, DROPDOWNS as DEFAULT_DROPDOWNS, PROJECT as DEFAULT_PROJECT_ITEMS, ICONSIZE  } from './Default'


const ITEMS = {
    workplanner: {
        title: 'Arbeitsplanung',
        path: '/dashboard/workplanner',
        icon: <Md.MdWork size={ICONSIZE} />,
        show: account => account.is_admin || account.is_manager,
    },
    warehouse: {
        title: 'Lager',
        path: '/dashboard/warehouse',
        icon: <Fa.FaWarehouse size={ICONSIZE} />,
        show: account =>  account.is_admin || account.is_manager,
    },
    materialDatabase: {
        title: 'Materialdatenbank',
        path: '/dashboard/material',
        icon: <Ai.AiFillDatabase size={ICONSIZE} />,
        show: account => account.is_admin || account.is_manager,
    },
}

const PROJECT_ITEMS = {
    statistics: {
        title: 'Statistik',
        relative: true,
        path: '/statistics',
        icon: <Bs.BsBarChartLineFill size={ICONSIZE} />,
        show: account => account.is_admin || account.is_manager,
    },
    materials: {
        title: 'Material',
        relative: true,
        path: '/materials',
        icon: <Bs.BsStack size={ICONSIZE} />,
        show: account => account.is_admin || account.is_manager,
    },
}

export const NavbarGU = [
    [
        {
            ...DEFAULT_ITEMS.projects,
            dropDown: Object.values(DEFAULT_DROPDOWNS.projects)
        },
        DEFAULT_ITEMS.calendar,
        DEFAULT_ITEMS.internalChat,
        DEFAULT_ITEMS.todo,
        ITEMS.workplanner,
        {
            ...DEFAULT_ITEMS.timerecording,
            dropDown: Object.values(DEFAULT_DROPDOWNS.timerecording)
        },
        {
            ...DEFAULT_ITEMS.company,
            dropDown: Object.values(DEFAULT_DROPDOWNS.company)
        },
        ITEMS.warehouse,
        DEFAULT_ITEMS.customer,
        DEFAULT_ITEMS.supplier,
        ITEMS.materialDatabase,
        DEFAULT_ITEMS.conmeetConnections,
    ],
]
export const ProjectToolbarGU = [
    [
    ],
    [
        PROJECT_ITEMS.statistics,
        DEFAULT_PROJECT_ITEMS.tasks,
        DEFAULT_PROJECT_ITEMS.chat,
        DEFAULT_PROJECT_ITEMS.members,
        DEFAULT_PROJECT_ITEMS.timestamps,
        PROJECT_ITEMS.materials,
        DEFAULT_PROJECT_ITEMS.agreements,
        DEFAULT_PROJECT_ITEMS.events,
        DEFAULT_PROJECT_ITEMS.notes,
        DEFAULT_PROJECT_ITEMS.todos,
        DEFAULT_PROJECT_ITEMS.tickets,
        DEFAULT_PROJECT_ITEMS.documents,
        DEFAULT_PROJECT_ITEMS.chatmedia,
        DEFAULT_PROJECT_ITEMS.invite,
        DEFAULT_PROJECT_ITEMS.documentation,
        DEFAULT_PROJECT_ITEMS.management,
    ]
]
