import { useEffect, useState, useContext } from 'react';
import AccountContext from '../../../../context/AccountContext'

import { Stack, Box, Button } from '@mui/material';
import { useGridApiRef } from '@mui/x-data-grid-pro';
import { MdHideSource, MdPersonAdd } from 'react-icons/md';

import { getCookie } from '../../../../utils/getCookie'
import UnControlledSelect from '../../../Widgets/UnControlledInputs/UnControlledSelect';
import ConfirmDialog from '../../../Widgets/ConfirmDialog';
import PaperDataGrid from "../../../Widgets/CustomDatagrids/NoPagination/PaperDataGrid";
import ProjectAddMemberDialog from './ProjectAddMemberDialog';



const ProjectTeamMemberPermissionTable = ({ project, projectMember, projectTeamMembers , getProjectTeamMembers}) =>{

    const [account] = useContext(AccountContext)
    const apiRef = useGridApiRef()
    const [selectedCompany, setSelectedCompany] = useState(projectTeamMembers[0])
    const [value, setValue] = useState(projectTeamMembers[0].id)
    const [items, setItems] = useState({})
    const [open, setOpen] = useState(false)

    const handleOpen = () => {
        setOpen(true)
    }

    const handleOnClose = () => {
        setOpen(false)
    }

    const disabled = (account.company.id === selectedCompany?.id) 

    const handleChange = (event) => {
        let company_id = parseInt(event.target.value)
        const selected_company = projectTeamMembers.find(company => company.id === company_id)
        setSelectedCompany(selected_company)
        setValue(company_id);
    };

    const getItems = () =>{
        let temp = {}
        projectTeamMembers.forEach((ptm, idx) =>{
            temp[ptm.id] = ptm.name
        })
        setItems(temp)
    }

    useEffect(() => {
        getItems()
        const selected_company = projectTeamMembers.find(company => company.id === selectedCompany?.id)
        setSelectedCompany(selected_company)
    }, [projectTeamMembers])

    const handleProjectMemberPermission = async(row) => {
        const json = JSON.stringify(row)
        const res = await fetch('/api/project/'+ project.id + '/member', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'X-CSRFToken': getCookie('csrftoken')
            },
            body: json
        })
        if(res.ok){
            getProjectTeamMembers()
        }
    }
    const handleLockProjectCompany = async() =>{
        const json = JSON.stringify({
            company_id: selectedCompany.id
        })
        const res = await fetch('/api/project/' + project.id + '/team-members', {
            method: 'PATCH',
            headers: {
                'Content-Type': 'application/json',
                'X-CSRFToken': getCookie('csrftoken')
            },
            body: json
        })
        if(res.ok){
            getProjectTeamMembers()
            setSelectedCompany(selectedCompany)
        }
    }

    const columns = [
		{
			field: 'firstname',
			headerName: 'Vorname',
			type: 'string',
			minWidth: 150,
		},
		{
			field: 'lastname',
			headerName: 'Nachname',
			type: 'string',
			minWidth: 150,
		},
		{
			field: 'job_title',
			headerName: 'Job',
			type: 'string',
			width: 250,
        },
        {
            field: 'phone_number',
            headerName: 'Telefonnummer',
            type: 'string',
            flex:'1',
            minWidth: 150,
        },
        ...projectMember.is_leader ? [
            {
                field: 'is_manager',
                headerName: 'Unternehmensmanager',
                type: 'boolean',
                minWidth: 150,
            },
            {
                field: 'can_post_feed',
                headerName: 'Feedrechte',
                type: 'boolean',
                minWidth: 100,
                editable: true
            },
            {
                field: 'can_post_chat',
                headerName: 'Chatrechte',
                type: 'boolean',
                minWidth: 100,
                editable: true
            }
        ]:[]
    ]


	const handleSave = async (row) => {
		handleProjectMemberPermission(row)
	};

    return(
        <Stack>
            <PaperDataGrid
                title="Projektteam"
                rows={selectedCompany.project_members}
                columns={columns}
                apiRef={apiRef}
                hideSearch={true}
                onSaveRow={projectMember.is_leader ? handleSave : null}
                actionBar={
                        <Stack
                            direction='row'
                            justifyContent='center'
                            alignItems='center'
                            gap={3}
                        >
                            {(account.is_admin || account.is_manager) &&
                                <Box>
                                    <Button 
                                        variant="outlined" 
                                        startIcon={<MdPersonAdd size={22} />}
                                        size='large'
                                        onClick={handleOpen}
                                    >
                                        Mitarbeiter hinzufügen
                                    </Button>
                                </Box>
                            }
                            {projectMember.is_leader &&
                                <Box>
                                    <ConfirmDialog
                                        title={selectedCompany.locked_on ? 'Unternehmen entsperren?':'Unternehmen wirklich für das Projekt sperren?'}
                                        message={selectedCompany.locked_on ? 'Wenn du das Unternhemen entsperrst, bekommt das Unternehmen wieder alle aktuellen Informationen aus dem Projekt.' :`Wenn du das Unternhemen sperrst, erhält das Unternehmen keine aktuellen Informationen aus dem Projekt.`}
                                        onConfirm={handleLockProjectCompany}
                                        renderButton={(onClick) => (
                                            <Box>
                                                <Button 
                                                    variant="outlined" 
                                                    disabled={disabled}
                                                    startIcon={<MdHideSource size={22} />}
                                                    color={!selectedCompany.locked_on ? 'error' : 'primary'}
                                                    size='large'
                                                    onClick={onClick}
                                                >
                                                    {selectedCompany.locked_on ? 'Unternehmen entsperren': 'Unternehmen sperren'}
                                                </Button>
                                            </Box>
                                        )}
                                    />
                                </Box>
                            }
                            <UnControlledSelect
                                label="Unternehmen"
                                value={value}
                                onChange={handleChange}
                                items={items}
                                sx={{ mt: 1.5, mb: 1.5, width: 250}}
                            />
                        </Stack>
                }
            />
            <ProjectAddMemberDialog
                open={open}
                onClose={handleOnClose}
            />
        </Stack>
    )
}

export default ProjectTeamMemberPermissionTable