import { PlaylistRemoveRounded } from "@mui/icons-material";
import { Stack, Box, Typography, Divider } from "@mui/material"
import { PALETTE } from "../../../../theme";
import EventItem from "./EventItem";


const ProjectSlotItem = (props) =>{

    const { projectSlot, handleSelectedEvent, getDayEvents, haveToLoadAllEvents } = props;

    return(
        <Stack
            direction='row'
            spacing={2}
            height={350}
            sx={{
                width: '100%',
            }}
        >
            <Stack
                sx={{
                    flex: '0 0 200px',
                }}
            >
                <Stack
                    justifyContent='center'
                    alignItems='center'
                    height="100%"
                    spacing={1}
                >
                    <Box
                        component="img"
                        src={projectSlot.img}
                        alt="not found"
                        sx={{
                            width: 130,
                        }}
                    />
                    <Typography
                        variant="subtitle_s"
                        component='div'
                        sx={{ maxWidth: 240, overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap' }}
                    >
                        {projectSlot.name}
                    </Typography>
                </Stack>
            </Stack>
            <Divider orientation='vertical' flexItem/>
            <Stack
                direction='row'
                spacing={2}
                width="100%"
                sx= {{ overflowX: 'auto', p: 0.5}}
            >
                {projectSlot.events.length !== 0 ?
                    projectSlot.events.map(( event ) =>{
                        return(
                            <EventItem
                                key={`project-slot-${event.id}`}
                                event={event}
                                handleSelectedEvent={handleSelectedEvent}
                                getDayEvents={getDayEvents}
                                haveToLoadAllEvents={haveToLoadAllEvents}
                            />
                        )
                    })
                :
                    <Stack
                        justifyContent='center'
                    >
                        <PlaylistRemoveRounded sx={{ fontSize: 80, color: PALETTE.background.light45}}/>
                    </Stack>
                }
            </Stack>
        </Stack>
    )
}

export default ProjectSlotItem