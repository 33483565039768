import { useEffect, useCallback } from "react";
import { Controller, useForm } from "react-hook-form";

import { Stack, Grid, Typography, Paper, Divider, Button, FormControl, RadioGroup, Radio, FormControlLabel, Alert, AlertTitle } from "@mui/material";

import { getCookie } from '../../../../utils/getCookie';
import useStyledSnackbar from '../../../../hooks/useStyledSnackbar'
import ControlledTextFieldWithLeftTextAndToolTip from "../../../Widgets/ControlledInputs/ControlledTextFieldWithLeftTextAndToolTip";
import CustomDivider from "../../../Widgets/CustomDivider";

import { customParseFloat, customParseInt } from '../../../../utils/helper'

import { InfoText, TooltipContent } from "./TooltipContent";
import { formatNumber } from "../../../../utils/helper";

import { roundTo } from 'round-to'
import CustomPage from "components/Widgets/CustomPage";


const CompanyWageRate = () => {

    const { enqueueSnackbar } = useStyledSnackbar()

    const { control, handleSubmit, setValue, watch, formState: { errors , isDirty, isValid }} = useForm({
        mode: "onChange",
        defaultValues: {
            company_member_wages: 0,
            company_member_wage_avg: 0,
            company_member_salaries_factorable: 0,
            company_member_salaries: 0,
            social_taxes: 0,
            rent_extra_costs: 0,
            office_management: 0,
            selling_expenses: 0,
            marketing_promotion: 0,
            insurance: 0,
            operational_taxes: 0,
            finance_costs: 0,
            fuel_costs: 0,
            other_costs: 0,
            days_per_year: 0,
            saturdays_sundays_per_year: 0,
            national_holidays_per_year: 0,
            vacation_days: 0,
            sick_days: 0,
            hours_per_day: 0,
            fact_company_members: 0,
            correction_factor: 0,
            wage_profit: 0,
            fix_wage_profit: 0,
            fix_wage_rate: 0,
            fix_type: null,
        }
    });

    const company_member_wages = customParseFloat(watch('company_member_wages'))
    const company_member_wage_avg = customParseFloat(watch('company_member_wage_avg'))
    const company_member_salaries_factorable = customParseFloat(watch('company_member_salaries_factorable'))
    const company_member_salaries = customParseFloat(watch('company_member_salaries'))
    const social_taxes = customParseFloat(watch('social_taxes'))
    const rent_extra_costs = customParseFloat(watch('rent_extra_costs'))
    const office_management = customParseFloat(watch('office_management'))
    const selling_expenses = customParseFloat(watch('selling_expenses'))
    const marketing_promotion = customParseFloat(watch('marketing_promotion'))
    const insurance = customParseFloat(watch('insurance'))
    const operational_taxes = customParseFloat(watch('operational_taxes'))
    const finance_costs = customParseFloat(watch('finance_costs'))
    const fuel_costs = customParseFloat(watch('fuel_costs'))
    const other_costs = customParseFloat(watch('other_costs'))
    const days_per_year = customParseInt(watch('days_per_year'))
    const saturdays_sundays_per_year = customParseInt(watch('saturdays_sundays_per_year'))
    const national_holidays_per_year = customParseInt(watch('national_holidays_per_year'))
    const vacation_days = customParseFloat(watch('vacation_days'))
    const sick_days = customParseInt(watch('sick_days'))
    const hours_per_day = customParseInt(watch('hours_per_day'))
    const fact_company_members = customParseFloat(watch('fact_company_members'))
    const correction_factor = customParseFloat(watch('correction_factor'))
    const wage_profit = customParseFloat(watch('wage_profit'))
    const fix_wage_rate = customParseFloat(watch('fix_wage_rate'))
    const fix_wage_profit = customParseFloat(watch('fix_wage_profit'))

    const totalCompanyCostsWithoutWages = company_member_salaries + company_member_salaries_factorable + social_taxes + rent_extra_costs + office_management + selling_expenses + marketing_promotion + insurance + operational_taxes + finance_costs + fuel_costs + other_costs
    const totalFactDays = days_per_year - saturdays_sundays_per_year - national_holidays_per_year - vacation_days - sick_days
    const totalFactHours = totalFactDays * hours_per_day * fact_company_members * (correction_factor / 100)
    const totalCompanyCosts = totalCompanyCostsWithoutWages + company_member_wages
    const resultWageRate = totalFactHours ? totalCompanyCosts / totalFactHours : 0
    const resultWageRateWithWageProfit = resultWageRate * (wage_profit / 100 + 1)
    const resultFixWageRateWithWageProfit = fix_wage_rate * (fix_wage_profit / 100 + 1)


    const handleCompanyMemberWages = useCallback(() =>{
        let result = (company_member_wage_avg * totalFactHours).toFixed(2)
        setValue('company_member_wages', result)
    }, [company_member_wage_avg, totalFactHours, setValue])

    useEffect(() => {
        handleCompanyMemberWages()
    },[handleCompanyMemberWages])

    const saveCompanyWageRate = async(data) =>{
        const json = JSON.stringify({
			...data,
            wage_rate: roundTo(resultWageRate, 2),
		})
        const res = await fetch('/api/company/wagerate', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'X-CSRFToken': getCookie('csrftoken')
            },
            body: json
        })
        // const _data = await res.json()
        if(res.ok){
            enqueueSnackbar('Der Stundenverrechnungssatz wurde erfolgreich gespeichert!', {
                variant: 'success'
            })
        } else{
            // console.log(_data)
            enqueueSnackbar('Der Stundenverrechnungssatz wurde nicht gespeichert!', {
                variant: 'error'
            })
        }
    }

	useEffect(() => {
        const getCompanyWageRateInfos = async() => {
            const res = await fetch('/api/company/wagerate', {
                method: 'GET'
            })
            const data = await res.json()
            if(res.ok){
                for(const key in data){
                    setValue(key, data[key])
                }
            }
        }
        getCompanyWageRateInfos()
    }, [setValue])

    return(
        <CustomPage
            title="Stundenverrechnung"
        >
            <Grid
                component='form'
                onSubmit={handleSubmit(saveCompanyWageRate)}
                container
                spacing={3}
            >
                <Grid
                    item
                    mobile={12}
                    tablet={12}
                    laptop={6}
                    desktop={6}
                >
                    <Stack spacing={3}>
                        <Paper
                            elevation={2}
                            sx={{p:3}}
                        >
                            <Stack spacing={0.5}>
                                <Typography variant="subtitle_m">Berechnung der Unternehmenskosten (jährlich)</Typography>
                                <ControlledTextFieldWithLeftTextAndToolTip
                                    control={control}
                                    errors={errors}
                                    name="company_member_wages"
                                    type="number"
                                    tooltip={TooltipContent.company_member_wages}
                                    leftText="Löhne der Mitarbeiter"
                                    disabled={watch('fix_type')}
                                    // value={(valuesOLD.company_member_wage_avg * totalFactHours).toFixed(2)}
                                    readOnly={true}
                                    rules={{ required: true }}
                                    suffix="€"
                                />
                                <ControlledTextFieldWithLeftTextAndToolTip
                                    control={control}
                                    errors={errors}
                                    name="company_member_salaries"
                                    type="number"
                                    tooltip={TooltipContent.company_member_salaries}
                                    leftText="Gehälter der Mitarbeiter"
                                    disabled={watch('fix_type')}
                                    readOnly={true}
                                    rules={{ required: true }}
                                    suffix="€"
                                />
                                <ControlledTextFieldWithLeftTextAndToolTip
                                    control={control}
                                    errors={errors}
                                    name="company_member_salaries_factorable"
                                    type="number"
                                    tooltip={TooltipContent.company_member_salaries_factorable}
                                    leftText="Gehälter der Mitarbeiter (faktorisierbar)"
                                    disabled={watch('fix_type')}
                                    readOnly={true}
                                    rules={{ required: true }}
                                    suffix="€"
                                />
                                <ControlledTextFieldWithLeftTextAndToolTip
                                    control={control}
                                    errors={errors}
                                    name="social_taxes"
                                    type="number"
                                    tooltip={TooltipContent.social_taxes}
                                    leftText="Sozialabgaben"
                                    disabled={watch('fix_type')}
                                    rules={{ required: true }}
                                    suffix="€"
                                />
                                <ControlledTextFieldWithLeftTextAndToolTip
                                    control={control}
                                    errors={errors}
                                    name="rent_extra_costs"
                                    type="number"
                                    tooltip={TooltipContent.rent_extra_costs}
                                    leftText="Mieten und Nebenkosten"
                                    disabled={watch('fix_type')}
                                    rules={{ required: true }}
                                    suffix="€"
                                />
                                <ControlledTextFieldWithLeftTextAndToolTip
                                    control={control}
                                    errors={errors}
                                    name="office_management"
                                    type="number"
                                    tooltip={TooltipContent.office_management}
                                    leftText="Büro und Verwaltung"
                                    disabled={watch('fix_type')}
                                    rules={{ required: true }}
                                    suffix="€"
                                />
                                <ControlledTextFieldWithLeftTextAndToolTip
                                    control={control}
                                    errors={errors}
                                    name="selling_expenses"
                                    type="number"
                                    tooltip={TooltipContent.selling_expenses}
                                    leftText="Vertriebskosten"
                                    disabled={watch('fix_type')}
                                    rules={{ required: true }}
                                    suffix="€"
                                />
                                <ControlledTextFieldWithLeftTextAndToolTip
                                    control={control}
                                    errors={errors}
                                    name="marketing_promotion"
                                    type="number"
                                    tooltip={TooltipContent.marketing_promotion}
                                    leftText="Marketing und Werbung"
                                    disabled={watch('fix_type')}
                                    rules={{ required: true }}
                                    suffix="€"
                                />
                                <ControlledTextFieldWithLeftTextAndToolTip
                                    control={control}
                                    errors={errors}
                                    name="insurance"
                                    type="number"
                                    tooltip={TooltipContent.insurance}
                                    leftText="Versicherungen"
                                    disabled={watch('fix_type')}
                                    rules={{ required: true }}
                                    suffix="€"
                                />
                                <ControlledTextFieldWithLeftTextAndToolTip
                                    control={control}
                                    errors={errors}
                                    name="operational_taxes"
                                    type="number"
                                    tooltip={TooltipContent.operational_taxes}
                                    leftText="Betriebliche Steuern"
                                    disabled={watch('fix_type')}
                                    rules={{ required: true }}
                                    suffix="€"
                                />
                                <ControlledTextFieldWithLeftTextAndToolTip
                                    control={control}
                                    errors={errors}
                                    name="finance_costs"
                                    type="number"
                                    tooltip={TooltipContent.finance_costs}
                                    leftText="Finanzierungskosten (Tilgung, Zinsen)"
                                    disabled={watch('fix_type')}
                                    rules={{ required: true }}
                                    suffix="€"
                                />
                                <ControlledTextFieldWithLeftTextAndToolTip
                                    control={control}
                                    errors={errors}
                                    name="fuel_costs"
                                    type="number"
                                    tooltip={TooltipContent.fuel_costs}
                                    leftText="Kraftstoffkosten"
                                    disabled={watch('fix_type')}
                                    rules={{ required: true }}
                                    suffix="€"
                                />
                                <ControlledTextFieldWithLeftTextAndToolTip
                                    control={control}
                                    errors={errors}
                                    name="other_costs"
                                    type="number"
                                    tooltip={TooltipContent.other_costs}
                                    leftText="Sonstige Kosten"
                                    disabled={watch('fix_type')}
                                    rules={{ required: true }}
                                    suffix="€"
                                />
                                <CustomDivider height={15}/>
                                <Stack
                                    direction='row'
                                    justifyContent="space-between"
                                    alignItems='center'
                                >
                                    <Typography variant="subtitle_m">Gesamtkosten des Unternehmens</Typography>
                                    <Typography variant="subtitle_m">{formatNumber(totalCompanyCosts, 2,'€')}</Typography>
                                </Stack>
                            </Stack>
                        </Paper>
                        <Alert
                            severity="warning"
                        >
                            <AlertTitle>Information</AlertTitle>
                            {InfoText}
                        </Alert>
                    </Stack>
                </Grid>
                <Grid
                    item
                    mobile={12}
                    tablet={12}
                    laptop={6}
                    desktop={6}
                >
                    <Stack spacing={3}>
                        <Paper
                            elevation={2}
                            sx={{p:3}}
                        >
                            <Stack spacing={0.5}>
                                <Typography variant="subtitle_m">Berechnung der fakturierfähigen Stunden</Typography>
                                <ControlledTextFieldWithLeftTextAndToolTip
                                    control={control}
                                    errors={errors}
                                    name="days_per_year"
                                    type="number"
                                    tooltip={TooltipContent.days_per_year}
                                    leftText="Tage im Jahr"
                                    disabled={watch('fix_type')}
                                    readOnly={true}
                                    rules={{ required: true }}
                                    suffix="Tage"
                                />
                                <ControlledTextFieldWithLeftTextAndToolTip
                                    control={control}
                                    errors={errors}
                                    name="saturdays_sundays_per_year"
                                    type="number"
                                    tooltip={TooltipContent.saturdays_sundays_per_year}
                                    leftText="Samstage und Sonntage pro Jahr"
                                    disabled={watch('fix_type')}
                                    readOnly={true}
                                    rules={{ required: true }}
                                    suffix="Tage"
                                />
                                <ControlledTextFieldWithLeftTextAndToolTip
                                    control={control}
                                    errors={errors}
                                    name="national_holidays_per_year"
                                    type="number"
                                    tooltip={TooltipContent.national_holidays_per_year}
                                    leftText="Feiertage pro Jahr"
                                    disabled={watch('fix_type')}
                                    rules={{ required: true }}
                                    suffix="Tage"
                                />
                                <ControlledTextFieldWithLeftTextAndToolTip
                                    control={control}
                                    errors={errors}
                                    name="vacation_days"
                                    type="number"
                                    tooltip={TooltipContent.vacation_days}
                                    leftText="Durchschnittliche Urlaubstage im Jahr"
                                    disabled={watch('fix_type')}
                                    readOnly={true}
                                    rules={{ required: true }}
                                    suffix="Tage"
                                />
                                <ControlledTextFieldWithLeftTextAndToolTip
                                    control={control}
                                    errors={errors}
                                    name="sick_days"
                                    type="number"
                                    tooltip={TooltipContent.sick_days}
                                    leftText="durch Krankheit bedingte Ausfälle pro Jahr"
                                    disabled={watch('fix_type')}
                                    rules={{ required: true }}
                                    suffix="Tage"
                                />
                                <CustomDivider height={15}/>
                                <ControlledTextFieldWithLeftTextAndToolTip
                                    control={control}
                                    errors={errors}
                                    name="hours_per_day"
                                    type="number"
                                    tooltip={TooltipContent.hours_per_day}
                                    leftText="Stunden pro Tag"
                                    disabled={watch('fix_type')}
                                    rules={{ required: true }}
                                    suffix="Stunden"
                                />
                                <ControlledTextFieldWithLeftTextAndToolTip
                                    control={control}
                                    errors={errors}
                                    name="fact_company_members"
                                    type="number"
                                    tooltip={TooltipContent.fact_company_members}
                                    leftText="Fakturierfähige Mitarbeiter"
                                    readOnly={true}
                                    disabled={watch('fix_type')}
                                    rules={{ required: true }}
                                    suffix="Mitarbeiter"
                                />
                                <ControlledTextFieldWithLeftTextAndToolTip
                                    control={control}
                                    errors={errors}
                                    name="correction_factor"
                                    type="number"
                                    tooltip={TooltipContent.correction_factor}
                                    leftText="Korrekturfaktor"
                                    disabled={watch('fix_type')}
                                    rules={{ required: true }}
                                    suffix="%"
                                />
                                <CustomDivider height={15}/>
                                <Stack
                                    direction='row'
                                    justifyContent="space-between"
                                    alignItems='center'
                                    sx={{pt: 1}}
                                >
                                    <Typography variant="text_m">Tatsächliche Arbeitstage</Typography>
                                    <Typography variant="text_m">{formatNumber(totalFactDays, 2,'Tage')}</Typography>
                                </Stack>
                                <Stack
                                    direction='row'
                                    justifyContent="space-between"
                                    alignItems='center'
                                >
                                    <Typography variant="text_m">Tatsächliche Arbeitsstunden</Typography>
                                    <Typography variant="text_m">{formatNumber(totalFactDays * hours_per_day, 2,'Stunden')}</Typography>
                                </Stack>
                                <Stack
                                    direction='row'
                                    justifyContent="space-between"
                                    alignItems='center'
                                >
                                    <Typography variant="subtitle_m">Fakturierfähige Stunden</Typography>
                                    <Typography variant="subtitle_m">{formatNumber(totalFactHours, 2,'Stunden')}</Typography>
                                </Stack>
                            </Stack>
                        </Paper>
                        <Paper
                            elevation={2}
                            sx={{p:3}}
                        >
                            <Typography variant="subtitle_m" color="primary">Berechnung des Stundenverrechnungssatzes</Typography>
                            <Stack
                                direction='row'
                                justifyContent="space-between"
                                alignItems='center'
                                sx={{mt:1}}
                            >
                                <Typography variant="text_m">Gesamtkosten des Unternehmens</Typography>
                                <Typography variant="text_m">{formatNumber(totalCompanyCosts, 2,'€')}</Typography>
                            </Stack>
                            <Stack
                                direction='row'
                                justifyContent="space-between"
                                alignItems='center'
                            >
                                <Typography variant="text_m">Fakturierfähige Stunden</Typography>
                                <Typography variant="text_m">{formatNumber(totalFactHours, 2,'Stunden')}</Typography>
                            </Stack>
                            <Stack
                                direction='row'
                                justifyContent="space-between"
                                alignItems='center'
                                sx={{mb:1}}
                            >
                                <Typography variant="text_m">Stundenverrechnungssatz ohne Lohngewinn</Typography>
                                <Typography variant="text_m">{formatNumber(resultWageRate, 2,'€/Std')}</Typography>
                            </Stack>
                            <ControlledTextFieldWithLeftTextAndToolTip
                                control={control}
                                errors={errors}
                                name="wage_profit"
                                type="number"
                                tooltip={TooltipContent.wage_profit}
                                leftText="Lohngewinn"
                                disabled={watch('fix_type')}
                                suffix="%"
                            />
                            <Stack
                                direction='row'
                                justifyContent="space-between"
                                alignItems='center'
                                sx={{mt:1}}
                            >
                                <Typography variant="subtitle_m" sx={{color : watch('fix_type') ? 'text.disabled' : 'text.main'}}>Stundenverrechnungssatz mit Lohngewinn</Typography>
                                <Typography variant="subtitle_m" sx={{color : watch('fix_type') ? 'text.disabled' : 'text.main'}}>{formatNumber(resultWageRateWithWageProfit, 2,'€/Std')}</Typography>
                            </Stack>
                            <Divider textAlign="center" sx={{mt:1, mb: 1}}><Typography variant="caption_m">oder</Typography></Divider>
                            <Stack spacing={0.5}>
                                <ControlledTextFieldWithLeftTextAndToolTip
                                    control={control}
                                    errors={errors}
                                    name="fix_wage_rate"
                                    type="number"
                                    tooltip={TooltipContent.fix_wage_rate}
                                    leftText="Fester Stundenverrechnungssatz ohne Lohngewinn"
                                    disabled={!watch('fix_type')}
                                    suffix="€"
                                />
                                <ControlledTextFieldWithLeftTextAndToolTip
                                    control={control}
                                    errors={errors}
                                    name="fix_wage_profit"
                                    type="number"
                                    tooltip={TooltipContent.wage_profit}
                                    leftText="Lohngewinn"
                                    disabled={!watch('fix_type')}
                                    suffix="%"
                                />
                            </Stack>
                            <Stack
                                direction='row'
                                justifyContent="space-between"
                                alignItems='center'
                                sx={{mt:1}}
                            >
                                <Typography variant="subtitle_m" sx={{color : !watch('fix_type') ? 'text.disabled' : 'text.main'}}>Fester Stundenverrechnungssatz mit Lohngewinn</Typography>
                                <Typography variant="subtitle_m" sx={{color : !watch('fix_type') ? 'text.disabled' : 'text.main'}}>{formatNumber(resultFixWageRateWithWageProfit, 2,'€/Std')}</Typography>
                            </Stack>
                            <FormControl component="fieldset">
                                <Controller
                                    name="fix_type"
                                    control={control}
                                    rules={{ required: false }}
                                    render={({ field }) => (
                                        <RadioGroup
                                            row
                                            {...field}
                                            value={`${field.value}`}
                                            onChange={(e) => field.onChange(e.target.value === "true")}
                                        >
                                            <FormControlLabel 
                                                value="false"
                                                control={<Radio color="primary"/>} 
                                                label="Berechneter Stundenverrechnungssatz"
                                            />
                                            <FormControlLabel 
                                                value="true"
                                                control={<Radio color="primary"/>} 
                                                label="Fester Stundenverrechnungssatz" 
                                            />
                                        </RadioGroup>
                                    )}
                                />
                            </FormControl>
                            <Button
                                color="primary"
                                variant="outlined"
                                fullWidth
                                type="onSubmit"
                                disabled={!isDirty || !isValid}
                                sx={{mt:2}}
                            >
                                Stundenverrechnungssatz speichern
                            </Button>
                        </Paper>
                    </Stack>
                </Grid>
            </Grid>
        </CustomPage>
    )
}

export default CompanyWageRate