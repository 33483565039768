import { Stack, Typography } from '@mui/material'
import { Group, Business, Lock, LockOpen } from "@mui/icons-material"

const ProjectIconBar = ({ official, members, companies}) =>{

    return(
        <Stack
            direction="row"
            justifyContent="center"
            alignContent="center"
            spacing={2}
        >
            {official ? <Lock sx={{ color: 'background.light20'}}/> : <LockOpen sx={{ color: 'background.light20'}}/>}
            <Stack direction="row" alignContent="center" spacing={0.5}>
                <Typography variant="value">{members}</Typography>
                <Group sx={{ color: 'background.light20'}}/>
            </Stack>
            <Stack direction="row" alignContent="center" spacing={0.5}>
                <Typography variant="value">{companies}</Typography>
                <Business sx={{ color: 'background.light20'}}/>
            </Stack>
        </Stack>
    )
}

export default ProjectIconBar