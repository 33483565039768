import { useController } from 'react-hook-form'

import { TextField } from '@mui/material'
import { DateTimePicker } from '@mui/x-date-pickers-pro'

const ControlledDateTimePicker = (props) =>{
    const { name, control, label} = props
    const { field } = useController({ control, name });

    return(
        <DateTimePicker
            renderInput={(props) => <TextField {...props} fullWidth/>}
            value={field.value}
            onChange={field.onChange}
            label={label}
            inputFormat='dd.MM.yyyy HH:mm'
            mask='__.__.____ __:__'
            {...props}
        />
    )
}

export default ControlledDateTimePicker