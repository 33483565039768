import { useState, useEffect } from "react"
import { useForm } from "react-hook-form"

import { Button, Collapse, Dialog, DialogActions, DialogContent, DialogTitle, FormGroup, Stack } from '@mui/material'

import { getCookie } from "../../../../../utils/getCookie"
import { addHours } from "date-fns"

import ControlledProjectTeamMemberSelect from '../../../../Widgets/ControlledInputs/ControlledProjectTeamMemberSelect'
import ControlledSelect from '../../../../Widgets/ControlledInputs/ControlledSelect'
import ControlledTextField from '../../../../Widgets/ControlledInputs/ControlledTextField'
import ControlledDateTimePicker from '../../../../Widgets/ControlledInputs/ControlledDateTimePicker'
import ControlledSwitch from '../../../../Widgets/ControlledInputs/ControlledSwitch'
import CompanyMemberMultiSelection from '../../../../Widgets/CompanyMemberMultiSelection'
import ControlledTimePicker from "../../../../Widgets/ControlledInputs/ControlledTimepicker"
import { getLocalDateTime } from "../../../../../utils/date_functions"


const AddEventDialog = ({ open, onClose, newEvent, getEvents }) => {

    const [projects, setProjects] = useState({})
    const { control, handleSubmit, reset, setValue, watch, formState: { errors } } = useForm({
        mode: 'onChange',
        defaultValues: {
            title: '',
            description: '',
            place: '',
            start: null,
            end: null,
            company_subscribers: [],
            public: false,
            allDay: false,
            project_event: false,
            project: '',
            project_subscribers: []
        }
    })

    const project_event = watch('project_event')

    const getProjects = async () => {
        const res = await fetch('/api/projects/get-selection?allProjects=0', {
            method: 'GET',
        })
        const _data = await res.json()
        if (res.ok) {
                setProjects(_data)
        }
    }

    const addEvent = async data => {
        const body = {
            ...data,
            company_subscribers: data.company_subscribers.map(s => parseInt(s)),
            project_subscribers: data.project_subscribers.map(s => parseInt(s))
        }

        const res = await fetch('/api/company/calendar', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'X-CSRFToken': getCookie('csrftoken')
            },
            body: JSON.stringify(body)
        })
        if (res.ok) {
            handleClose()
            getEvents()
        }
    }

    const handleClose = () =>{
        onClose()
        reset()
    }

    useEffect(()=>{
        if(open === true && project_event === true){
            getProjects()
        }
    },[open, project_event])

    useEffect(()=>{
        setValue('start', addHours(newEvent.start, 10))
        setValue('end', addHours(newEvent.start, 12))
    },[setValue, newEvent])

    const changeStartTime = watch('start')
    useEffect(()=>{
        setValue('end', addHours( changeStartTime, 1), {shouldValidate: true, shouldDirty: true})
    }, [changeStartTime, setValue])

    return (
        <Dialog
            open={open}
            onClose={handleClose}
            fullWidth
            maxWidth={'tablet'}
        >
            <Stack
                direction='row'
                justifyContent='space-between'
            >
                <DialogTitle>{`Neuer Termin`}</DialogTitle>
                <DialogTitle>{`${getLocalDateTime(newEvent.start)}`}</DialogTitle>
            </Stack>
            <DialogContent>
                <Stack
                    component='form'
                    id="form-add-event"
                    onSubmit={handleSubmit(addEvent)}
                    justifyContent='space-between'
                    spacing={2}
                    sx={{ flexBasis: 250, flexGrow: 1 }}
                >
                    <ControlledTextField
                        control={control}
                        name='title'
                        label='Titel'
                        errors={errors}
                        type='text'
                        rules={{ required: true }}
                    />
                    <ControlledTextField
                        control={control}
                        name='place'
                        label='Ort'
                        errors={errors}
                        type='text'
                        rules={{ required: false }}
                    />
                    <ControlledTextField
                        control={control}
                        name='description'
                        label='Beschreibung'
                        errors={errors}
                        type='text'
                        rules={{ required: true }}
                        multiline
                        rows={5}
                    />
                        {watch('allDay') ?
                            <Stack
                                direction='row'
                                spacing={2}
                            >
                                <ControlledDateTimePicker
                                    control={control}
                                    name='start'
                                    label='Beginn'
                                />
                                <ControlledDateTimePicker
                                    control={control}
                                    name='end'
                                    label='Ende'
                                    minDateTime={watch('start')}
                                />
                            </Stack>
                        :
                            <Stack
                                direction='row'
                                spacing={2}
                            >
                                <ControlledTimePicker
                                    control={control}
                                    name='start'
                                    label='Beginn'
                                />
                                <ControlledTimePicker
                                    control={control}
                                    name='end'
                                    label='Ende'
                                    minTime={watch('start')}
                                />
                            </Stack>
                        }
                    <CompanyMemberMultiSelection
                        control={control}
                        errors={errors}
                        name='company_subscribers'
                        label='Teilnehmer'
                    />
                    <FormGroup sx={{pl: 1}}>
                        <ControlledSwitch
                            control={control}
                            name='public'
                            color='primary'
                            label='Öffentlich'
                        />
                        <ControlledSwitch
                            control={control}
                            name='allDay'
                            color='primary'
                            label='Ganztägig'
                        />
                        <ControlledSwitch
                            control={control}
                            name='project_event'
                            color='primary'
                            label='Mit Projektpartnern'
                        />
                    </FormGroup>
                    <Collapse in={watch('project_event')}>
                        <Stack gap={2}>
                            <ControlledSelect
                                control={control}
                                errors={errors}
                                name='project'
                                label='Projekt'
                                items={projects}
                            />
                            <ControlledProjectTeamMemberSelect
                                control={control}
                                errors={errors}
                                name='project_subscribers'
                                label='Externe Teilnehmer'
                                project_id={watch('project')}
                                rules={{ required: watch('project_event')}}
                                disabled={watch('project') === ''}
                                multiple={true}
                            />
                        </Stack>
                    </Collapse>
                </Stack>
            </DialogContent>
            <DialogActions>
                <Button
                    onClick={handleClose}
                    color="secondary"
                >
                    Abbrechen
                </Button>
                <Button
                    form="form-add-event"
                    type="submit"
                >
                    Erstellen
                </Button>
            </DialogActions>
        </Dialog>
    )
}

export default AddEventDialog