import { useState, useRef, useContext, Fragment, createRef, useEffect, useCallback } from 'react'

import { CircularProgress, Divider, Box, TextField, IconButton, Stack, Button, FormControlLabel, Switch, Typography } from '@mui/material'
import AddIcon from '@mui/icons-material/Add'
import InsertEmoticonIcon from '@mui/icons-material/InsertEmoticon';
import CancelIcon from '@mui/icons-material/Cancel';

import Picker from '@emoji-mart/react'
import data from '@emoji-mart/data'
// import 'emoji-mart/css/emoji-mart.css'

import Message from './Message'

import AccountContext from '../../context/AccountContext'
import { getCookie } from '../../utils/getCookie'
import useWebSocket from '../../hooks/useWebSocket'

import './style.css'
import theme, { PALETTE } from 'components/theme';
import { MdGroups, MdPublic } from 'react-icons/md';
import { BsFillCloudUploadFill, BsFillImageFill, BsFillMegaphoneFill } from 'react-icons/bs';
import TooltipIconButton from 'components/Widgets/TooltipIconButton';
import { useDropzone } from 'react-dropzone';
import DragNDrop from 'components/Widgets/DragNDrop';

const Chat = ({ socketUrl, isProjectBlackboard, sx, inputDisabled=false, publicSwitch=false, showCompanyNames=false }) => {
    const [ messages, setMessages ] = useState([])
    const messageInputRef = createRef()
    const [ showEmojiPicker, setShowEmojiPicker ] = useState(false)
    const [ gotAllMessages, setGotAllMessages ] = useState(false)
    const [ wsOpen, setWsOpen ] = useState(false)
    const [ isPublic, setIsPublic ] = useState(false)
    const handleIsPublicChange = (e) => {
        setIsPublic(e.target.checked)
    }

    const [account] = useContext(AccountContext)

    const onMessageJson = useCallback((e, message) => {
        console.log(message)
        if ([0, 1, 2, 3].includes(message?.msg_type)) {
            setMessages(msgs => [message].concat(msgs))
        } else if (message.messages) {
            setMessages(msgs => msgs.concat(message.messages))
            if (message.gotAllMessages) {
                setGotAllMessages(true)
            }
        }
    }, [])

    const onOpen = useCallback(() => {
        console.log('Chat WS connected')
        setMessages([])
        messageInputRef.current && (messageInputRef.current.value = '')
        setGotAllMessages(false)
        setWsOpen(true)
    }, [])

    const onClose = useCallback(() => {
        console.log('Chat WS closed')
        setWsOpen(false)
    }, [])

    const {
        sendJsonMessage
    } = useWebSocket({
        path: socketUrl,
        onOpen: onOpen,
        onClose: onClose,
        onMessageJson: onMessageJson,
        connectionLostMessage: isProjectBlackboard ? 'Verbindung zum Feed verloren...' : 'Verbindung zum Chat verloren...'
    })

    const getMessages = useCallback(() => {
        sendJsonMessage({
            'command': 'get_chat_room_messages',
            'last_id': messages.length > 0 ? messages.at(-1).id : null
        })
    }, [messages, sendJsonMessage])

    useEffect(() => {
        if (wsOpen && messages.length === 0 && gotAllMessages === false) {
            getMessages()
        }
    }, [messages, gotAllMessages, getMessages, wsOpen])

    const sendMessage = (ref, uploadedFileID) => {
        let obj = {
            'command': 'send',
            'message': ref.value,
        }
        if (uploadedFileID) {
            obj['upload_id'] = uploadedFileID
        }
        if (replyTo !== null) {
            obj['reply_to'] = replyTo.id
        }
        if (publicSwitch) {
            obj['public'] = isPublic
        }
        sendJsonMessage(obj)
        ref.value = ''
        setShowEmojiPicker(false)
        setOpenFileUpload(false)
        setSelectedFile(null)
        setReplyTo(null)
        setIsPublic(false)
    }

    const handleMessageSubmit = () => {
        if (messageInputRef.current.value === '') return
        if (selectedFile) {
            uploadFile(messageInputRef.current)
        } else {
            sendMessage(messageInputRef.current)
        }
    }

    const selectEmoji = emoji => {
        messageInputRef.current.value += emoji.native
    }

    const [openFileUpload, setOpenFileUpload] = useState(false)
    const [selectedFile, setSelectedFile] = useState(null)
    const [uploading, setUploading] = useState(false)
    const [ fileErrorText, setFileErrorText ] = useState(null)
    const onDrop = useCallback(acceptedFiles => {
        if (acceptedFiles.length === 0) {
            setSelectedFile(null)
            return
        }
        if (acceptedFiles.length > 1) {
            setSelectedFile(null)
            setFileErrorText('Es kann nur eine Datei hochgeladen werden!')
            return
        }
        setFileErrorText(null)
        setSelectedFile(acceptedFiles[0])
    }, [])
    const { getRootProps, getInputProps, isDragActive, open: openFileDiaglog } = useDropzone({
        onDrop,
        noClick: true,
    })
    const fileUploadOpen = openFileUpload || selectedFile || fileErrorText
    const toggleFileUpload = () => {
        if (fileUploadOpen) {
            setOpenFileUpload(false)
            setSelectedFile(null)
            setFileErrorText(null)
        } else {
            setOpenFileUpload(true)
        }
    }

    const uploadFile = async ref => {
        const formData = new FormData()
        formData.append('file', selectedFile)
        setUploading(true)
        const res = await fetch('/upload/', {
            method: 'POST',
            body: formData,
            headers: {
                'X-CSRFToken': getCookie('csrftoken')
            }
        })
        if (res.ok) {
            const json = await res.json()
            sendMessage(ref, json)
        }
        setUploading(false)
    }

    const [ replyTo, setReplyTo ] = useState(null)
    const handleReplyClick = message => {
        setReplyTo(message)
        messageInputRef.current.focus()
        console.log(messageInputRef.current)
    }

    const lastScroll = useRef(false)
    const handleScroll = e => {
        if (messages.length > 0 && !gotAllMessages) {
            const top = (e.target.scrollHeight +
                (isProjectBlackboard ? -e.target.scrollTop : e.target.scrollTop) -
                e.target.clientHeight) <= 30
            console.log(top)
            if (lastScroll.current !== top) {
                if (top) {
                    getMessages()
                }
            }
            lastScroll.current = top
        }
    }

    return (
        <Stack
            sx={{ overflow: 'hidden', flex: '1 1 0', ...sx }}
            className={"Chat" + (isProjectBlackboard ? ' project-blackboard' : '')}
            {...getRootProps()}
        >
            <Stack
                className='background-container'
                sx={{
                    flexGrow: 1,
                    overflow: 'hidden'
                }}
            >
                <Box
                    sx={{ flexGrow: 1 }}
                    className={"messages" + (isProjectBlackboard ? ' project-blackboard' : '')}
                    onScroll={handleScroll}
                >
                    {messages?.slice(0).map(m => {
                        if (isProjectBlackboard && m.reply_to) return null
                        return (
                            <Fragment key={m.id}>
                                {isProjectBlackboard && <Divider sx={{mb: '10px'}} />}
                                <Message
                                    message={m}
                                    messages={messages}
                                    account={account}
                                    isProjectBlackboard={isProjectBlackboard}
                                    handleReplyClick={handleReplyClick}
                                    showPublicSymbol={publicSwitch}
                                    showCompanyName={showCompanyNames}
                                />
                            </Fragment>
                        )
                    })}
                    {!gotAllMessages &&
                        <Stack
                            alignItems='center'
                            sx={{ flex: '0 0 50px', overflow: 'hidden'}}
                            justifyContent='center'
                        >
                            <CircularProgress />
                        </Stack>
                    }
                </Box>
            </Stack>
            <Divider sx={{ mb:2 }}/>
            {replyTo &&
                <>
                    <Box sx={{ pl: 2, pr: 2 }} className="reply-to">
                        <IconButton size="small" onClick={() => setReplyTo(null)}>
                            <CancelIcon />
                        </IconButton>
                        <Message
                            message={replyTo}
                            account={account}
                            isProjectBlackboard={isProjectBlackboard}
                            handleReplyClick={handleReplyClick}
                            showPublicSymbol={publicSwitch}
                            showCompanyName={showCompanyNames}
                        />
                    </Box>
                    <Divider sx={{mt:2, mb:2}}/>
                </>
            }
            {fileUploadOpen &&
                <>
                    <Box sx={{ pl: 2, pr: 2 }} className="file-upload">
                        <input {...getInputProps()} />
                        <Box>
                            <IconButton variant="contained" component="span" color='primary' onClick={openFileDiaglog}>
                                <BsFillCloudUploadFill size={45} />
                            </IconButton>
                        </Box>
                        {selectedFile ?
                            <Typography variant='text_m' sx={{ textAlign: 'center' }}>{selectedFile.name}</Typography>
                        : fileErrorText ?
                            <Typography variant='text_m' sx={{ textAlign: 'center', color: theme.palette.error.main }}>
                                <b>{fileErrorText}</b>
                            </Typography>
                        :
                            <Typography variant='text_m' sx={{ textAlign: 'center' }}>
                                Drücke den <b>Upload-Button</b> oder<br/><b>ziehe</b> eine Datei in das <b>Chat-Fenster</b>
                            </Typography>
                        }
                    </Box>
                    <Divider sx={{mt:2, mb:2}}/>
                </>
            }

            <Stack spacing={0.5}
                direction="row"
                sx={{
                    pl: 2,
                    pr: 2,
                    pb: 2,
                    position: 'relative',
                    alignItems: 'center'
                }}
            >
                <TextField
                    multiline
                    variant="outlined"
                    size="small"
                    fullWidth
                    onKeyDown={e => {
                            if(e.code === 'Enter' && !e.shiftKey && !uploading) {
                                e.preventDefault()
                                handleMessageSubmit()
                            }
                        }
                    }
                    disabled={uploading || inputDisabled}
                    placeholder={inputDisabled ? 'Keine Post-Rechte' : ''}
                    inputRef={messageInputRef}
                />
                <div>
                    <IconButton size="small" onClick={() => { setShowEmojiPicker(!showEmojiPicker) }} disabled={inputDisabled}>
                        <InsertEmoticonIcon/>
                    </IconButton>
                    <div
                        style={{
                            visibility: !showEmojiPicker && 'hidden',
                            position: 'absolute',
                            bottom: 80,
                            right: 20
                        }}
                    >
                        <Picker
                            onEmojiSelect={selectEmoji}
                            data={data}
                        />
                    </div>
                </div>
                <TooltipIconButton
                    onClick={toggleFileUpload}
                    title={fileUploadOpen ? (selectedFile ? 'Datei löschen' : 'Schließen') : 'Datei hinzufügen'}
                    disabled={inputDisabled}
                    icon={fileUploadOpen ? <CancelIcon sx={{ fontSize: 22 }} /> : <BsFillImageFill size={22} />}
                    color={null}
                />
                {publicSwitch &&
                    <TooltipIconButton
                        onClick={() => { setIsPublic(p => !p) }}
                        sx={{ color: isPublic ? PALETTE.primary.main : PALETTE.secondary.main }}
                        title={
                            replyTo ?
                                ''
                            : isPublic ?
                                "Für alle Unternehmen sichtbar"
                            :
                                "Für dein Unternehmen sichtbar"
                        }
                        icon={<MdGroups size={22} />}
                        disabled={replyTo}
                    />
                }
            </Stack>
        </Stack>
    )
}

export default Chat


// TODO
// permissions
