import { useState } from "react"
import { Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, Divider, IconButton, Stack } from '@mui/material'
import { getFileExtension } from "utils/file_extension"
import { FILE_TYPE_EXTENSIONS } from "."
import FileGrid from "./FileGrid"
import FileList from "./FileList"
import Outdated from "./SettingsButton/Outdated"
import Share from "./SettingsButton/Share"
import SettingsDialog from "./SettingsDialog"


const Files = ({ files, view, updateDocumentState }) => {
    const [ popupFile, setPopupFile ] = useState(null)
    const popupExt = popupFile ? '.' + getFileExtension(popupFile.file) : ''
    const handleCloseFile = () => {
        setPopupFile(null)
    }
    const handleOpenFile = (f) => {
        const ext = '.' + getFileExtension(f.file)
        if (FILE_TYPE_EXTENSIONS.images.includes(ext) || FILE_TYPE_EXTENSIONS.videos.includes(ext))
            setPopupFile(f)
    }

    const [ settingsFile, setSettingsFile ] = useState(null)
    const handleOpenSettings = f => {
        setSettingsFile(f)
    }
    const handleCloseSettings = () => {
        setSettingsFile(null)
    }

    return (<>
        {view === 'grid' && <FileGrid
            files={files}
            popupFile={popupFile}
            popupExt={popupExt}
            handleOpenFile={handleOpenFile}
            handleCloseFile={handleCloseFile}
            handleOpenSettings={handleOpenSettings}
            handleCloseSettings ={handleCloseSettings}
        />}
        {view === 'list' && <FileList
            files={files}
            popupFile={popupFile}
            popupExt={popupExt}
            handleOpenFile={handleOpenFile}
            handleCloseFile={handleCloseFile}
            handleOpenSettings={handleOpenSettings}
            handleCloseSettings ={handleCloseSettings}
        />}
        <Dialog
            open={FILE_TYPE_EXTENSIONS.images.includes(popupExt) || FILE_TYPE_EXTENSIONS.videos.includes(popupExt)}
            onClose={handleCloseFile}
        >
            <DialogTitle>{popupFile?.description}</DialogTitle>
            <DialogContent
                sx={{
                    'overflow': 'hidden',
                    '& > img, & > video': {
                        maxWidth: '100%',
                        maxHeight: 'calc(100vh - 64px - 64px - 52.5px)',
                    }
                }}
            >
                {FILE_TYPE_EXTENSIONS.images.includes(popupExt) &&
                    <img src={popupFile.file} alt='' />}
                {FILE_TYPE_EXTENSIONS.videos.includes(popupExt) &&
                    <video src={popupFile.file} controls preload="metadata"></video>}
            </DialogContent>
            <DialogActions>
                <Button onClick={handleCloseFile}>Schließen</Button>
            </DialogActions>
        </Dialog>
        <SettingsDialog
            file={settingsFile}
            handleCloseSettings={handleCloseSettings}
            updateDocumentState={updateDocumentState}
        />
    </>)
}

export default Files
