import { useState, useEffect, useCallback } from 'react'
import { useLocation } from 'react-router-dom';

import { Stack, Button, Typography,  TextField, useMediaQuery } from '@mui/material';
import { ThumbUp } from '@mui/icons-material';

import useStyledSnackbar from '../../hooks/useStyledSnackbar'


const RegisterKeyForm = ({ registrationType, setRegistrationType }) =>{

    const location = useLocation()
    const urlKey = new URLSearchParams(location.search).get('key')
    const [key, setKey] = useState(urlKey || '')

    const { enqueueSnackbar } = useStyledSnackbar()
    const compact = useMediaQuery('(max-width: 850px)');

    const checkRegistrationKey = useCallback(async key => {
        const json = JSON.stringify({
            key: key
        })
        const res = await fetch('/account/registration-key', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: json
        })
        if (res.ok) {
            const data = await res.json()
            setRegistrationType({
                type: data,
                key: key,
                state: 'success',
                success: true,
            })
            enqueueSnackbar('Gültiger Registierungsschüssel!', {
                variant: 'success'
            })
        } else {
            setRegistrationType({
                type:'not-valid',
                key: '',
                state:'error',
                success: false,
            })
            enqueueSnackbar('Ungültiger Registierungsschüssel!', {
                variant: 'error'
            })
        }
    }, [enqueueSnackbar, setRegistrationType])

    useEffect(() => {
        if(!urlKey) return
        checkRegistrationKey(urlKey)
    }, [urlKey, checkRegistrationKey])

    return(
        <Stack
            direction="column"
            justifyContent="center"
            alignItems="center"
            width="100%"
            spacing={3}
        >
            <Typography variant="text_m_justify">
                Herzlich Willkommen bei CONMEET! <br/>
                Bitte trage hier deinen Registrierungsschlüssel ein.
                Falls du noch keinen hast, schreibe uns bitte eine Mail
                an die <b>info@conmeet.io</b> oder rufe uns unter der <b>0175/2049801</b> an.
                Bei Fragen rund um den Anmeldevorgang und Einrichtung deines Unternehmens stehen
                wir dir selbstverständlich zur Seite. <br/> <br/>
                Viel Spaß und frohes Schaffen!
            </Typography>
            {!registrationType.success ?
                <Stack
                    justifyContent='center'
                    alignItems="stretch"
                    spacing={2}
                    // sx={{ minWidth: 350, height: 120 }}
                >
                    <TextField
                        id="key-input"
                        label="Registierungsschüssel"
                        value={key}
                        onChange={(e) => setKey(e.target.value)}
                    />
                    <Button
                        onClick={() => checkRegistrationKey(key)}
                        variant="outlined"
                        color="primary"
                        disabled={key === ''}
                    >
                        Prüfen
                    </Button>
                </Stack>
            :
                <Stack
                    justifyContent='center'
                    alignItems="center"
                    spacing={1}
                    sx={{ height: 120 }}
                >
                    <ThumbUp
                        color="primary"
                        fontSize='large'
                    />
                    <Typography
                        color="primary.main"
                        variant="text_m_b"
                    >
                        Der Registierungsschüssel ist gültig!
                    </Typography>
                </Stack>
            }
        </Stack>

    )
}

export default RegisterKeyForm
