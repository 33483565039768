import { useState, useEffect, useContext, useCallback }  from 'react'
import ProjectContext from '../../../../../context/ProjectContext'

import { Grid, Stack } from '@mui/material';

import InviteCompany from './InviteCompany';
import CompanyRequests from './CompanyRequests';
import ActiveInvites from './ActiveInvites';
import InviteCompanyEmail from './InviteCompanyEmail';



const ProjectInvite = () => {

    const [project] = useContext(ProjectContext)

    const [companyRequests, setCompanyRequests] = useState([])
    const [activeInvites, setActiveInvites] = useState([])

    const getCompanyRequests = useCallback(async() =>{
        const res = await fetch('/api/project/' + project.id + '/companyrequests', {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
            },
        })
        const data = await res.json()
        if(res.ok){
            setCompanyRequests(data)
            console.log('company requests:', data)
        }
    }, [project.id])

    const getActiveInvites = useCallback(async() => {
        const res = await fetch('/api/project/' + project.id + '/companyinvite', {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
            },
        })
        const data = await res.json()
        if(res.ok){
            setActiveInvites( data)
            console.log('company invites:', data)
        }
    }, [project.id])

    useEffect(()=>{
        getCompanyRequests()
        getActiveInvites()
    }, [getCompanyRequests, getActiveInvites])

    return (
        <Grid
            container
            spacing={3}
        >
            <Grid
                item
                desktop={4}
                laptop={4}
                tablet={12}
                mobile={12}
            >
                <InviteCompany
                    project={project}
                    getActiveInvites={getActiveInvites}
                />
            </Grid>
            <Grid
                item
                desktop={4}
                laptop={4}
                tablet={12}
                mobile={12}
            >
                <Stack
                    spacing={3}
                >
                    <ActiveInvites
                        project={project}
                        activeInvites={activeInvites}
                        getActiveInvites={getActiveInvites}
                    />
                    <CompanyRequests
                        project={project}
                        companyRequests={companyRequests}
                        getCompanyRequests={getCompanyRequests}
                    />
                </Stack>
            </Grid>
            <Grid
                item
                desktop={4}
                laptop={4}
                tablet={12}
                mobile={12}
            >
                <InviteCompanyEmail
                    project={project}
                />
            </Grid>
        </Grid>
    );
}

export default ProjectInvite