import { useContext, useState } from 'react'

import { Stack, Box, Typography, Divider, Tooltip, IconButton } from '@mui/material'

import ProjectContext from '../../../context/ProjectContext'

import CustomTimeCountdown from '../CustomTimeCountdown'
import { Clear, BrandingWatermark } from '@mui/icons-material'
import { Link } from 'react-router-dom'
import ProjectShield from '../ProjectShield'



const SubHeader = ({ subTitle }) => {

    const [project] = useContext(ProjectContext)
    const [shieldOpen, setShieldOpen] = useState(false)

    return (
            <Stack
                direction='row'
                justifyContent='space-between'
                alignItems='center'
            >
                <Stack
                    direction='row'
                    alignItems='center'
                    spacing={3}
                >
                    <Box
                        component="img"
                        src={project.img}
                        alt="not found"
                        sx={{ height: 100}}
                    />
                    <Divider orientation='vertical' flexItem/>
                    <Stack>
                        <Typography variant="subtitle_m" color='secondary.main' sx={{lineHeight: 1}}>Projekt</Typography>
                        <Typography variant='title_m' sx={{lineHeight: 1.2}}>
                            {project.name}
                        </Typography>
                        <Typography variant='text_s'>{`${project.address.street} ${project.address.house_number}${project.address.add_on}, ${project.address.postal_code} ${project.address.city}`}</Typography>
                        <Stack
                            direction='row'
                            alignItems='center'
                            spacing={0.5}
                        >
                            <Typography variant='breadcrumb'>
                                {`${subTitle}`}
                            </Typography>
                        </Stack>
                    </Stack>
                </Stack>
                {project.completed_on &&
                    <Stack
                        height={'100%'}
                        justifyContent='center'
                        alignItems={'center'}
                        sx={{pt:1.5}}
                        gap={0.5}
                    >
                        <Typography variant='subtitle_s' color="error">Die Projektleitung hat das Projekt abgeschlossen seit:</Typography>
                        <CustomTimeCountdown targetDate={project.completed_on} reverse={true}/>

                    </Stack>
                }
                <Stack
                    gap={0.5}
                    direction='row'
                >
                    <Tooltip
                        title='Projektschild'
                    >
                        <IconButton
                            color="primary"
                            size="large"
                            onClick={()=> setShieldOpen(true)}
                        >
                            <BrandingWatermark fontSize='large'/>
                        </IconButton>
                    </Tooltip>
                    <Tooltip
                        title='Projekt schließen'
                    >
                        <IconButton
                            component={Link}
                            to="/dashboard/projects"
                            color="secondary"
                            size="large"
                        >
                            <Clear fontSize='large'/>
                        </IconButton>
                    </Tooltip>
                </Stack>
                <ProjectShield
                    open={shieldOpen}
                    onClose={()=> setShieldOpen(false)}
                />
            </Stack>
    )
}

export default SubHeader
