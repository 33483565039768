import React, { useContext } from "react";
import ProjectContext from '../../../../context/ProjectContext'
import { projectBlackboardInfoText } from "../../../../utils/texts";
import Chat from '../../../Chat'
import CustomPaper from "../../../Widgets/CustomPaper";
import InformationDialog from "../../../Widgets/Dialogs/InformationDialog";


const ProjectBlackBoard = ({ sx }) =>{
    const [project, projectMember] = useContext(ProjectContext)

    return (
        <CustomPaper
            title='Projektfeed'
            subtitle="Alle Beiträge aller Projektmitglieder"
            sx={{ flex: '1 1 0', overflow: 'hidden' }}
            padding={0}
        >
            <Chat
                socketUrl={'/chat/' + project.chat_id + '/'}
                isProjectBlackboard={true}
                inputDisabled={!projectMember.can_post_feed}
                publicSwitch={true}
                showCompanyNames={true}
            />
        </CustomPaper>
    )
}

export default ProjectBlackBoard
