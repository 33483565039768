import { BarChart, Bar, XAxis, YAxis, ReferenceLine } from 'recharts';

import { Stack, Box, CircularProgress, Typography, Divider } from '@mui/material';

import  useWidth  from '../../../../hooks/useWidth'

import { PALETTE } from "../../../theme/index.jsx";
import CustomPaper from "../../../Widgets/CustomPaper/index.jsx";

import no_data_img from '../../../../assets/svgs/no_data.svg'
import { formatNumber } from '../../../../utils/helper';

const CapacityChart = ({ title, max, data, height, maxFactor, fill, unit, barSize}) =>{

    const [ width, ref ] = useWidth(0)
    const current = data[0]?.current
    const calc = data[0]?.calc

    return(
        <CustomPaper
            title={title}
            padding={0}
        >
            <Stack spacing={5}>
                <Stack
                    width="100%"
                    direction='row'
                    justifyContent='space-evenly'
                    alignItems='center'
                    spacing={3}
                    sx={{
                        pr: 3,
                        pl: 3,
                    }}
                >
                    <Stack
                        width="100%"
                        sx={{
                            padding: 2,
                            border: 1,
                            borderRadius: '10px',
                            borderColor: PALETTE.background.light70
                        }}
                    >
                        <Stack
                            width="100%"
                            direction='row'
                            justifyContent='space-between'
                            alignItems="center"
                        >
                            <Typography
                                variant='text_m'
                                color="primary.dark"
                            >
                                {`Verplante Stunden`}
                            </Typography>
                            <Typography
                                variant='value'
                                color="primary.dark"
                                sx={{
                                    textAlign: 'right',
                                }}
                            >
                                {`${formatNumber(calc, 2, unit)}`}
                            </Typography>
                        </Stack>
                        <Stack
                            width="100%"
                            direction='row'
                            justifyContent='space-between'
                            alignItems="center"
                        >
                            <Typography
                                variant='text_m'
                                color="primary.main"
                            >
                                {`Aktuelle Stunden`}
                            </Typography>
                            <Typography
                                variant='value'
                                color="primary.main"
                                sx={{
                                    textAlign: 'right',
                                }}
                            >
                                {`- ${formatNumber(current, 2, unit)}`}
                            </Typography>
                        </Stack>
                        <Divider sx={{marginTop: 0.5, marginBottom: 0.5}}/>
                        <Stack
                            width="100%"
                            direction='row'
                            justifyContent='space-between'
                            alignItems="center"
                        >
                            <Typography
                                variant='text_m'
                                color="primary.light"
                                sx={{ fontWeight: 'bold'}}
                            >
                                {`Zu planende Stunden`}
                            </Typography>
                            <Typography
                                variant='value'
                                color="primary.light"
                                sx={{
                                    textAlign: 'right',
                                }}
                            >
                                {`${formatNumber(calc - current, 2, unit)}`}
                            </Typography>
                        </Stack>
                    </Stack>
                    <Stack
                        width="100%"
                        sx={{
                            padding: 2,
                            border: 1,
                            borderRadius: '10px',
                            borderColor: PALETTE.background.light70
                        }}
                    >
                        <Stack
                            width="100%"
                            direction='row'
                            justifyContent='space-between'
                            alignItems="center"
                        >
                            <Typography
                                variant='text_m'
                                color="secondary.main"
                            >
                                {`Verfügbare Stunden im Jahr`}
                            </Typography>
                            <Typography
                                variant='value'
                                color="secondary.main"
                                sx={{
                                    textAlign: 'right',
                                }}
                            >
                                {`${formatNumber(max, 2, unit)}`}
                            </Typography>
                        </Stack>
                        <Stack
                            width="100%"
                            direction='row'
                            justifyContent='space-between'
                            alignItems="center"
                        >
                            <Typography
                                variant='text_m'
                                color="primary.dark"
                            >
                                {`Verplante Stunden`}
                            </Typography>
                            <Typography
                                variant='value'
                                color="primary.dark"
                                sx={{
                                    textAlign: 'right',
                                }}
                            >
                                {`- ${formatNumber(calc, 2, unit)}`}
                            </Typography>
                        </Stack>
                        <Divider sx={{marginTop: 0.5, marginBottom: 0.5}}/>
                        <Stack
                            width="100%"
                            direction='row'
                            justifyContent='space-between'
                            alignItems="center"
                        >
                            <Typography
                                variant='text_m'
                                color="primary.light"
                                sx={{ fontWeight: 'bold'}}
                            >
                                {`Frei planbare Stunden`}
                            </Typography>
                            <Typography
                                variant='value'
                                color="primary.light"
                                sx={{
                                    textAlign: 'right',
                                }}
                            >
                                {`${formatNumber(max - calc, 2, unit)}`}
                            </Typography>
                        </Stack>
                    </Stack>
                </Stack>
                <Stack
                    justifyContent="center"
                    alignItems="center"
                    width="100%"
                    height={height}
                    ref={ref}
                >
                    {current > 0 ?
                        width !== 0 ?
                            <BarChart
                                width={width}
                                height={height}
                                data={data}
                                layout='vertical'
                                margin={{
                                    top: 0,
                                    right: 60,
                                    left: 0,
                                    bottom: 15,
                                }}
                                barGap={'5%'}
                            >
                                <XAxis
                                    type="number"
                                    unit={unit}
                                    tickLine={false}
                                    tickCount={5}
                                    interval="preserveEnd"
                                    domain={[0, dataMax => dataMax < max ? (Math.round(max * maxFactor)) : (Math.round(dataMax * maxFactor))]}
                                />
                                <YAxis
                                    type="category"
                                    dataKey="name"
                                    hide={true}
                                    tickLine={false}
                                />
                                <Bar
                                    dataKey="current"
                                    fill={PALETTE.primary.main}
                                    // barSize={barSize}
                                    animationDuration={1000}
                                    radius={[0, 10, 10, 0]}
                                />
                                <Bar
                                    dataKey="calc"
                                    fill={PALETTE.primary.dark}
                                    // barSize={barSize}
                                    animationDuration={1000}
                                    radius={[0, 10, 10, 0]}
                                />
                                <ReferenceLine
                                    x={max}
                                    label={{position: 'insideTopRight',  value: `${max} verfügbare ${unit}`, fill: PALETTE.background.light10, fontSize: 15 }}
                                    strokeWidth={1.5}
                                    stroke={PALETTE.secondary.light}
                                />
                            </BarChart>
                        :
                            <CircularProgress/>
                    :
                        <>
                            <Box
                                component="img"
                                src={no_data_img}
                                alt="not found"
                                sx={{ height: '70%', p:1}}
                            />
                            <Typography variant="text_m" >Noch keine Daten vorhanden</Typography>
                        </>
                    }
                </Stack>
            </Stack>
        </CustomPaper>
    )
}

export default CapacityChart