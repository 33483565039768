import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { getCookie } from "../../../../../utils/getCookie";
import { Button,  DialogContent, DialogContentText, Dialog, DialogTitle, DialogActions, Stack, Alert, AlertTitle } from "@mui/material";

import ControlledTextField from "../../../../Widgets/ControlledInputs/ControlledTextField";
import ControlledDateTimePicker from "../../../../Widgets/ControlledInputs/ControlledDateTimePicker";
import ControlledSelect from "../../../../Widgets/ControlledInputs/ControlledSelect";
import ControlledSlider from "../../../../Widgets/ControlledInputs/ControlledSlider";
import ImageUploader from "../../../../Widgets/ImageUplaoder";
import Paywall from "components/Widgets/Paywall";
import { addWeeks } from "date-fns";

const TicketChainAddDialog = (props) =>{

    const { open, onClose, project_id, getTicketChains, subCompanies } = props;
    const [selectedFile, setSelectedFile] = useState(null)
    const [subProjects, setSubProjects] = useState({});

    const { control, handleSubmit, watch, formState: { errors, isDirty, isValid }, reset } = useForm({
        mode: 'onChange',
        defaultValues: {
            name: '',
            description: '',
            title: '',
            instruction: '',
            service_provider: '',
            sub_project:'',
            deadline: addWeeks(new Date(), 2),
            rank: 10,
        }
    })

    const handleOnCLose = () => {
        onClose()
        reset()
        setSelectedFile(null)
    }

    const handleAddTicketChain = async (data) => {

        const json = JSON.stringify(data)
        const formData = new FormData()
        formData.append('file', selectedFile)
        formData.append('json', json)

        const res = await fetch('/api/project/' + project_id + '/ticketchains', {
            method: 'POST',
            headers: {
                'X-CSRFToken': getCookie('csrftoken')
            },
            body: formData,
        })
        if (res.ok) {
            getTicketChains()
            handleOnCLose()
        } else {
            const data = await res.json()
            console.log(data)
        }
    }

    useEffect(() => {
        const getSubProjects = async() => {
            const res = await fetch('/api/project/' + project_id + '/parent_sub_projects', {
                method: 'GET',
            })
            if (res.ok) {
                const data = await res.json()
                delete data['0'];
                setSubProjects(data)
            }
        }
        getSubProjects()
    }, [project_id])

    return(
        <Dialog
            open={open}
            onClose={handleOnCLose}
            maxWidth={'laptop'}
        >
            <Paywall>
                <DialogTitle>
                    Ticketkette hinzufügen
                </DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        Hier kannst du eine Ticketkette mit einem Ticket erstellen.
                    </DialogContentText>
                    {Object.keys(subCompanies).length !== 0 && Object.keys(subProjects).length !== 0 ?
                        <Stack
                            component='form'
                            id='add-chain-ticket-form'
                            onSubmit={handleSubmit(handleAddTicketChain)}
                            spacing={2}
                            sx={{mt:2}}
                        >
                            <Stack
                                direction="row"
                                spacing={5}
                            >
                                <ImageUploader
                                    selectedFile={selectedFile}
                                    setSelectedFile={setSelectedFile}
                                    width={320}
                                />
                                <Stack
                                    spacing={2}
                                    width={500}
                                >
                                    <ControlledTextField
                                        name="name"
                                        label="Name der Ticketkette"
                                        control={control}
                                        rules={{ required: true }}
                                        errors={errors}
                                    />
                                    <ControlledTextField
                                        name="description"
                                        label="Beschreibung der Ticketkette"
                                        control={control}
                                        rules={{ required: true }}
                                        errors={errors}
                                        multiline
                                        rows={3}
                                    />
                                    <ControlledTextField
                                        name="title"
                                        label="Titel des ersten Tickets"
                                        control={control}
                                        rules={{ required: true }}
                                        errors={errors}
                                    />
                                    <ControlledTextField
                                        name="instruction"
                                        label="Beschreibung"
                                        control={control}
                                        rules={{ required: true }}
                                        errors={errors}
                                        multiline
                                        rows={4}
                                    />
                                    <ControlledSlider
                                        name="rank"
                                        control={control}
                                        rules={{ required:  true }}
                                        label="Priorität"
                                        step={1}
                                        min={1}
                                        max={100}
                                    />
                                    <ControlledDateTimePicker
                                        name="deadline"
                                        label="Frist"
                                        control={control}
                                        rules={{ required:  true }}
                                        errors={errors}
                                    />
                                    <ControlledSelect
                                        name="sub_project"
                                        label="Projektauftrag"
                                        control={control}
                                        rules={{ required: true}}
                                        errors={errors}
                                        items={subProjects}
                                    />
                                    <ControlledSelect
                                        name="service_provider"
                                        label="Empfänger"
                                        control={control}
                                        rules={{ required: true}}
                                        errors={errors}
                                        items={subCompanies}
                                    />
                                </Stack>
                            </Stack>
                        </Stack>
                    :
                        <Alert
                            severity="warning"
                            sx={{ mt:2, mb: 2, boxShadow: 0}}
                        >
                            <AlertTitle>Information</AlertTitle>
                                Du kannst erst Tickets erstellen, wenn du ein Auftrag erhalten oder vergeben hast.
                        </Alert>
                    }
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleOnCLose} color="secondary">Zurück</Button>
                    <Button
                        color="primary"
                        type="submit"
                        form="add-chain-ticket-form"
                        autoFocus
                        disabled={!isDirty || !isValid || watch('deadline') === null}
                    >
                        Speichern
                    </Button>
                </DialogActions>
            </Paywall>
        </Dialog>
    )
}

export default TicketChainAddDialog