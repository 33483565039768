import { BarChart, Bar, XAxis, YAxis, ReferenceLine } from 'recharts';

import { Stack, Box, CircularProgress, Typography } from '@mui/material';

import  useWidth  from '../../../../../hooks/useWidth'

import { PALETTE } from "../../../../theme/index.jsx";
import CustomPaper from "../../../../Widgets/CustomPaper/index.jsx";

import no_data_img from '../../../../../assets/svgs/no_data.svg'
import { formatNumber } from '../../../../../utils/helper'


const StaticsSingleBarchart = ({ title, max, data, height, maxFactor, fill, unit, barSize}) =>{

    const [ width, ref ] = useWidth(0)
    const current = data[0]?.current

    return(
        <CustomPaper
            title={title}
            padding={0}
            actionBar={
                <Typography
                    variant="value"
                    sx={{
                        color: fill,
                        fontSize: 28,
                    }}
                >
                        {formatNumber(current, 2, unit)}
                </Typography>
            }
        >
            <Stack
                justifyContent="center"
                alignItems="center"
                width="100%"
                height={height}
                ref={ref}
            >
                {current > 0 ?
                    width !== 0 ?
                        <BarChart
                            width={width}
                            height={height}
                            data={data}
                            layout='vertical'
                            margin={{
                                top: 0,
                                right: 60,
                                left: 0,
                                bottom: 15,
                            }}
                        >
                            <XAxis
                                type="number"
                                unit={unit}
                                tickLine={false}
                                tickCount={5}
                                interval="preserveEnd"
                                domain={[0, dataMax => dataMax < max ? (Math.round(max * maxFactor)) : (Math.round(dataMax * maxFactor))]}
                            />
                            <YAxis
                                type="category"
                                dataKey="name"
                                hide={true}
                                tickLine={false}
                            />
                            <Bar
                                dataKey="current"
                                fill={fill}
                                barSize={barSize}
                                animationDuration={1000}
                                radius={[0, 10, 10, 0]}
                            />
                            <ReferenceLine
                                x={max}
                                label={{position: 'insideTopRight',  value: `Kalk. ${title} ${formatNumber(max, 2, unit)}`, fill: PALETTE.background.light10, fontSize: 15 }}
                                strokeWidth={1.5}
                                stroke={PALETTE.secondary.light}
                            />
                        </BarChart>
                    :
                        <CircularProgress/>
                :
                    <>
                        <Box
                            component="img"
                            src={no_data_img}
                            alt="not found"
                            sx={{ height: '70%', p:1}}
                        />
                        <Typography variant="text_" >Noch keine Daten vorhanden</Typography>
                    </>
                }
            </Stack>
        </CustomPaper>
    )
}

export default StaticsSingleBarchart