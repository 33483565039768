import { useEffect } from "react"
import { useForm } from "react-hook-form"

import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Stack } from '@mui/material'

import { getCookie } from "../../../../../utils/getCookie"

import ControlledTextField from '../../../../Widgets/ControlledInputs/ControlledTextField'
import ControlledDateTimePicker from '../../../../Widgets/ControlledInputs/ControlledDateTimePicker'
import ControlledSwitch from '../../../../Widgets/ControlledInputs/ControlledSwitch'



const EditEventDialog = ({ open, onClose, event, getEvents, setEvent, selectedEventId }) => {

    const { control, handleSubmit, setValue, watch, formState: { errors, isDirty, isValid } } = useForm({
        defaultValues: {
            title: event.title,
            description: event.description,
            place: event.place,
            start: event.start,
            end: event.end,
            public: event.public,
            allDay: event.allDay,
        }
    })

    const editEvent = async data => {
        const body = {
            event_id: event.id,
            title: data.title,
            description: data.description,
            place: data.place,
            start: data.start,
            end: data.end,
            public: data.public,
            allDay: data.allDay,
        }

        const res = await fetch('/api/company/calendar/event/' + selectedEventId, {
            method: 'PATCH',
            headers: {
                'Content-Type': 'application/json',
                'X-CSRFToken': getCookie('csrftoken')
            },
            body: JSON.stringify(body)
        })
        const _data = await res.json()
        if (res.ok) {
            setEvent({
                ...event,
                ..._data,
                start: new Date(_data.start),
                end: new Date(_data.end),
                company: event.company,
            })
            getEvents()
            handleClose()

        }
    }

    const handleClose = () =>{
        onClose()
    }

    useEffect(()=>{
        for (const [key, value] of Object.entries(event)) {
            setValue(key, value, { shouldValidate: true, shouldDirty: false })
        }
    },[setValue, event, selectedEventId])

    return (
        <Dialog
            open={open}
            onClose={handleClose}
            fullWidth
            maxWidth={'tablet'}
        >
            <DialogTitle>
                Termin bearbeiten
            </DialogTitle>
            <DialogContent>
                <Stack
                    component='form'
                    id="form-edit-event"
                    onSubmit={handleSubmit(editEvent)}
                    justifyContent='space-between'
                    spacing={2}
                    sx={{ mt:1, flexBasis: 250, flexGrow: 1 }}
                >
                    <ControlledTextField
                        control={control}
                        name='title'
                        label='Titel'
                        errors={errors}
                        type='text'
                        rules={{ required: true }}
                    />
                    <ControlledTextField
                        control={control}
                        name='description'
                        label='Beschreibung'
                        errors={errors}
                        type='text'
                        rules={{ required: true }}
                        multiline
                        rows={5}
                    />
                    <Stack
                        direction='row'
                        spacing={2}
                    >
                        <ControlledDateTimePicker
                            control={control}
                            name='start'
                            label='Beginn'
                        />
                        <ControlledDateTimePicker
                            control={control}
                            name='end'
                            label='Ende'
                            minDateTime={watch('start')}
                        />
                    </Stack>
                    <ControlledTextField
                        control={control}
                        name='place'
                        label='Ort'
                        errors={errors}
                        type='text'
                        rules={{ required: false }}
                    />
                    <ControlledSwitch
                        control={control}
                        name='public'
                        color='primary'
                        label='Öffentlich'
                    />
                    <ControlledSwitch
                        control={control}
                        name='allDay'
                        color='primary'
                        label='Ganztätig'
                    />
                </Stack>
            </DialogContent>
            <DialogActions>
                <Button
                    onClick={handleClose}
                    color="secondary"
                >
                    Abbrechen
                </Button>
                <Button
                    form="form-edit-event"
                    type="submit"
                    disabled={!isValid || !isDirty}
                >
                    Änderungen speichern
                </Button>
            </DialogActions>
        </Dialog>
    )
}

export default EditEventDialog