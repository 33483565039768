import React, { useState, useEffect, useContext, useCallback } from 'react'

import { BsFillCameraVideoFill, BsFillFileEarmarkFill, BsImageFill } from 'react-icons/bs'

import VisibilityTabs from './VisibilityTabs';
import LoadingScreen from '../../../LoadingScreen'
import ImageGrid from './ImagesGrid';
import FileList from './FileList';
import VideoGrid from './VideoGrid'
import CustomTabs from '../../../Widgets/CustomTabs';
import CustomTabPanel from '../../../Widgets/CustomTabPanel';

import ProjectContext from '../../../../context/ProjectContext'
import {Stack} from '@mui/material';

const ProjectDocuments = () => {
    const [project] = useContext(ProjectContext)

    const [ files, setFiles ] = useState(null)
    const [ value, setValue ] = useState(0)

    const [ selectedVisibility, setSelectedVisibility] = useState(0);
    const handleVisibilityChange = (e) => {
        setSelectedVisibility(e.target.value)
    }

    const getUploads = useCallback(async () => {
        const res = await fetch('/upload/' + project.id)
        const json = await res.json()
        setFiles(json)
    }, [project.id])

    useEffect(() => {
        getUploads()
    }, [getUploads])

    if (files == null) return <LoadingScreen />

    return(
        <Stack gap={3}>
            <CustomTabs
                tabs={[
                    { index: 0, label: 'Fotos', icon: <BsImageFill size={18} /> },
                    { index: 1, label: 'Videos', icon: <BsFillCameraVideoFill size={18} /> },
                    { index: 2, label: 'Dateien', icon: <BsFillFileEarmarkFill size={18} /> },
                ]}
                handleChange={ (e, newValue) => setValue(newValue) }
                value={value}
            />
            <CustomTabPanel value={value} index={0}>
                <VisibilityTabs
                    files={files.images}
                    component={ImageGrid}
                    title='Fotos'
                    fileType={'IMAGE_FILE'}
                    getUploads={getUploads}
                    selectedVisibility={selectedVisibility}
                    handleVisibilityChange={handleVisibilityChange}
                />
            </CustomTabPanel>
            <CustomTabPanel value={value} index={1}>
                <VisibilityTabs
                    files={files.videos}
                    component={VideoGrid}
                    title='Videos'
                    fileType={'VIDEO_FILE'}
                    getUploads={getUploads}
                    selectedVisibility={selectedVisibility}
                    handleVisibilityChange={handleVisibilityChange}
                />
            </CustomTabPanel>
            <CustomTabPanel value={value} index={2}>
                <VisibilityTabs
                    files={files.others}
                    component={FileList}
                    title='Andere Dateien'
                    fileType={'OTHER_FILE'}
                    getUploads={getUploads}
                    selectedVisibility={selectedVisibility}
                    handleVisibilityChange={handleVisibilityChange}
                />
            </CustomTabPanel>
        </Stack>
    )
}

export default ProjectDocuments
