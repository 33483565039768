import { useState } from "react"
import { useGridApiRef } from "@mui/x-data-grid-pro"
import { getCookie } from "../../../../utils/getCookie"
import { getAvailableBalance, getStatusValue } from "./WarehouseTableUtils"
import PaperPaginationDataGrid from "../../../Widgets/CustomDatagrids/Pagination/PaperPaginationDataGrid";
import { Chip, Switch } from "@mui/material"
import { BsFillAlarmFill, BsFillExclamationTriangleFill, BsFillHandThumbsUpFill } from "react-icons/bs";
import { formatPrice, formatValueWithUnit } from "../../Project/ProjectMaterial/MaterialTables/MaterialDatagridUtils"


const CustomStoreCell = (params) => {
    if (params.row.balance >= params.row.stock_alert_2){
        return(
            <Chip icon={<BsFillHandThumbsUpFill size={12}/>} label="Ok" variant="outlined" color="primary" size="small" sx={{flex: 1}}/>
        )
    }
    if (params.row.balance >= params.row.stock_alert_1){
        return(
            <Chip icon={<BsFillAlarmFill size={12}/>} label="Nachbstellen" variant="outlined" color="secondary" size="small" sx={{flex: 1}}/>
        )
    }
    return(
        <Chip icon={<BsFillExclamationTriangleFill size={12}/>} label="Hütte brennt!" variant="outlined" color="error" size="small" sx={{flex: 1}}/>
    )
}

const WarehouseTable = ({ onOrderByGetParametersChange, selectedRows, setSelectedRows, materials, getStockMaterial, getStockValues, pagInfo, pageSize, setPageSize, handlePageChange, isLoading, searchTerm, setSearchTerm }) =>{

    const apiRef = useGridApiRef();

    const [columnVisibilityModel, setColumnVisibilityModel] = useState({
        stock_alert_1: false,
        stock_alert_2: false,
        is_locked: false,
        is_hidden: false,
        actions: false,
    });

    const [checked, setChecked] = useState(false);

    const handleChange = (event) => {
        const checked = event.target.checked
        if(checked){
            setColumnVisibilityModel({
                stock_alert_1: true,
                stock_alert_2: true,
                is_locked: true,
                is_hidden: true,
                actions: true,
            })
        } else {
            setColumnVisibilityModel({
                stock_alert_1: false,
                stock_alert_2: false,
                is_locked: false,
                is_hidden: false,
                actions: false,
            })
        }
        setChecked(checked);
    };

    const columns = [
        {
            field: '_status',
            headerName: 'Lagerstatus',
            type: 'string',
            minWidth: 150,
            editable: false,
            // renderHeader: (params) => (
            //     <BsFillAlarmFill size={20} color={PALETTE.background.light20}/>
            // ),
            renderCell: CustomStoreCell,
            valueGetter: getStatusValue
        },
        {
            field: 'unique_material_id',
            headerName: 'Artikelnummer',
            type: 'number',
            align: 'right',
            minWidth: 150,
            editable: false,
            sortable: false
        },
        {
            field: 'description',
            headerName: 'Artikelbezeichnung',
            type: 'string',
            flex:'1',
            minWidth: 150,
            editable: false,
            sortable: false
        },
        {
            field: 'category',
            headerName: 'Kategorie',
            type: 'string',
            minWidth: 30,
            editable: false,
            sortable: false
        },
        {
            field: 'manufacturer',
            headerName: 'Hersteller',
            type: 'string',
            minWidth: 30,
            editable: false,
            sortable: false
        },
        {
            field: 'balance',
            headerName: 'Bestand',
            type: 'number',
            minWidth: 120,
            editable: false,
            valueFormatter: formatValueWithUnit,
        },
        {
            field: 'reserved',
            headerName: 'Reserviert',
            type: 'number',
            minWidth: 120,
            editable: false,
            valueFormatter: formatValueWithUnit,
        },
        {
            field: '_balance',
            headerName: 'Verfügbar',
            type: 'number',
            minWidth: 120,
            editable: false,
            valueGetter: getAvailableBalance,
            sortable: false
        },
        {
            field: 'price_per_piece',
            headerName: '\u00D8 Einzelpreis',
            type: 'number',
            minWidth: 120,
            editable: false,
            valueFormatter: formatPrice,
        },
        {
            field: 'total_value',
            headerName: 'Gesamtwert',
            type: 'number',
            minWidth: 120,
            editable: false,
            valueFormatter: formatPrice,
        },
        {
            field: 'stock_alert_1',
            headerName: 'Mindestbestand',
            type: 'number',
            minWidth: 120,
            editable: true,
            valueFormatter: formatValueWithUnit,
        },
        {
            field: 'stock_alert_2',
            headerName: 'Meldebestand',
            type: 'number',
            minWidth: 120,
            editable: true,
            valueFormatter: formatValueWithUnit,
        },
        {
            field: 'unit',
            headerName: 'Einheit',
            type: 'string',
            minWidth: 30,
            editable: false,
            sortable: false
        },
        {
			field: 'is_locked',
			headerName: 'Gesperrt',
			type: 'boolean',
			editable: true,
			minWidth: 40,
		},
        {
			field: 'is_hidden',
			headerName: 'Ausgeblendet',
			type: 'boolean',
			editable: true,
			minWidth: 40,
            hide: true,
		},
    ];

    const handlePatchStockMaterial = async(row) => {
        const json = JSON.stringify({
            stock_material_id : row.id,
            stock_alert_1: row.stock_alert_1,
            stock_alert_2: row.stock_alert_2,
            is_locked: row.is_locked,
            is_hidden: row.is_hidden,
        })
        const res = await fetch('/api/company/warehouse', {
            method: 'PATCH',
            headers: {
                'Content-Type': 'application/json',
                'X-CSRFToken': getCookie('csrftoken')
            },
            body: json
        })
        if(res.ok){
            getStockMaterial()
            getStockValues()
        }
    }

    const handleSelectionModelChange = (ids) => {
        const selectedIDs = new Set(ids);
        const selectedRows = materials.filter((row) =>
            selectedIDs.has(row.id),
        );
        setSelectedRows(selectedRows);
    }

    return(
        <PaperPaginationDataGrid
            rows={materials}
            columns={columns}
            apiRef={apiRef}
            pageSize={pageSize}
            checkboxSelection
            onSelectionModelChange={handleSelectionModelChange}
            selectionModel={selectedRows.map(r => r.id)}
            onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
            disableSelectionOnClick
            rowCount={pagInfo.count}
            onPageChange={handlePageChange}
            loading={isLoading}
            title={'Lagerbestand'}
            onSaveRow={handlePatchStockMaterial}
            columnVisibilityModel={columnVisibilityModel}
            onColumnVisibilityModelChange={(newModel) =>
                setColumnVisibilityModel(newModel)
            }
            actionBar={
                <Switch
                    checked={checked}
                    onChange={handleChange}
                />
            }
            initialState={{ pinnedColumns: { right: ['actions'] } }}
            searchTerm={searchTerm}
            setSearchTerm={setSearchTerm}
			onOrderByGetParametersChange={onOrderByGetParametersChange}
        />
    )
}

export default WarehouseTable
