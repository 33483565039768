import { useState } from 'react'

import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import PlayCircleOutlineIcon from '@mui/icons-material/PlayCircleOutline';
import IconButton from '@mui/material/IconButton';

import './style.css'

const VideoPopup = ({ src }) => {
    const [open, setOpen] = useState(false);

    const handleClickOpen = () => {
        setOpen(true)
    }

    const handleClose = () => {
        setOpen(false)
    }

    return (
        <div className='VideoPopup'>
            <div className='preview' onClick={handleClickOpen} style={{ backgroundColor: 'black' }}>
                <video
                    src={src}
                    style={{
                        width: '100%',
                        opacity: 0.5,
                        aspectRatio: 1/1,
                        objectFit: 'cover'
                    }}
                    preload="metadata"
                ></video>
                <IconButton className='button' size='large'>
                    <PlayCircleOutlineIcon fontSize="inherit" />
                </IconButton>
            </div>
            <Dialog open={open} onClose={handleClose}>
                <DialogTitle>Video</DialogTitle>
                <DialogContent
                    sx={{
                        'overflow': 'hidden',
                        '& > video': {
                            maxWidth: '100%',
                            maxHeight: 'calc(100vh - 64px - 64px - 52.5px)',
                        }
                    }}
                >
                    <video src={src} controls preload="metadata"></video>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose}>Schließen</Button>
                </DialogActions>
            </Dialog>
        </div>
    )
}

export default VideoPopup