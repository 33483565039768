import { useGridApiRef } from "@mui/x-data-grid-pro";

import PaperPaginationDataGrid from "../../../../Widgets/CustomDatagrids/Pagination/PaperPaginationDataGrid";
import { formatPrice, formatValueWithUnit,  getDiffOffer, getPreBalance } from "./MaterialDatagridUtils";

import { getCookie } from "../../../../../utils/getCookie";
import { formatNumber } from "../../../../../utils/helper";
import { PALETTE } from "../../../../theme";


const CustomHighlightCell = (cellValues) => {
	let postiv = true
	if (cellValues.value <= 0){ postiv = false }
	return(
		<div
			style={{
					color: postiv ?  PALETTE.primary.main : PALETTE.secondary.main,
					fontWeight: 'bold',
			}}
		>
			{formatNumber(cellValues.value, 2, cellValues.row.unit.split('/')[1] || '' )}
		</div>
	)
}

const ProjectMaterialTable = ({ onOrderByGetParametersChange, selectedRows, setSelectedRows, project_id, selectedSubProject, materials, getSubProjectMaterial, getValues, pagMaterialsInfo, pageSize, setPageSize, handlePageChange, isLoading, is_admin, is_manager, searchTerm, setSearchTerm }) =>{

    const apiRef = useGridApiRef();

    const columns = [
		{
			field: 'unique_material_id',
			headerName: 'Artikelnummer',
			type: 'number',
			align: 'right',
			minWidth: 120,
			editable: false,
			sortable: false,
		},
		{
			field: 'description',
			headerName: 'Artikelbezeichnung',
			type: 'string',
			flex:'1',
			minWidth: 150,
			editable: false,
			sortable: false,
		},
		{
			field: 'value_offered',
			headerName: 'Angeboten',
			type: 'number',
			valueFormatter: formatValueWithUnit,
			minWidth: 100,
			editable: true,
		},
		{
			field: 'add',
			headerName: 'Wareneingang',
			type: 'number',
			renderCell: (cellValues) => {
				return CustomHighlightCell(cellValues)
			},
			minWidth: 120,
			editable: false,
			sortable: false,
		},
		{
			field: 'used',
			headerName: 'Verbraucht',
			type: 'number',
			valueFormatter: formatValueWithUnit,
			minWidth: 100,
			editable: false,
			sortable: false,
		},
		{
			field: 'balance',
			headerName: 'Depot',
			type: 'number',
			minWidth: 120,
			editable: false,
			renderCell: (cellValues) => {
				return CustomHighlightCell(cellValues)
			},
		},
		{
			field: 'diff_offer',
			headerName: 'Angebotsdifferenz',
			type: 'number',
			valueGetter: getDiffOffer,
			renderCell: (cellValues) => {
				return CustomHighlightCell(cellValues)
			},
			minWidth: 150,
			editable: false,
			sortable: false,
		},
		...is_manager ? [
			{
				field: 'default_price',
				headerName: 'Lagerpreis',
				type: 'number',
				minWidth: 100,
				valueFormatter: formatPrice,
				editable: true,
			},
			{
				field: 'pre_price',
				headerName: 'Angebotspreis',
				type: 'number',
				minWidth: 100,
				valueFormatter: formatPrice,
				editable: true,
			},
			{
				field: 'post_price_avg',
				headerName: '\u00D8 Nachkalk. Nettopreis',
				type: 'number',
				minWidth: 100,
				valueFormatter: formatPrice,
				editable: false,
				sortable: false,
			},
			{
				field: 'is_offered',
				headerName: 'Im Angebot',
				type: 'boolean',
				minWidth: 30,
				editable: true,
			},
		]:[],
	];

    const handleDelete = async(id) => {
        const json = JSON.stringify({
			project_material_id: id
		})
        const res = await fetch('/api/project/' + project_id + '/sub-project/' + selectedSubProject + '/material', {
            method: 'DELETE',
            headers: {
                'Content-Type': 'application/json',
                'X-CSRFToken': getCookie('csrftoken')
            },
            body: json
        })
		if(res.ok){
			getSubProjectMaterial()
			getValues()
		}
		return res.ok
    }

	const handlePatchProjectMaterial = async(row) => {
        const json = JSON.stringify({
            project_material_id : row.id,
            value_offered: row.value_offered,
            default_price: row.default_price,
			pre_price: row.pre_price,
            is_offered: row.is_offered,
        })
        const res = await fetch('/api/project/' + project_id + '/sub-project/' + selectedSubProject + '/material', {
            method: 'PATCH',
            headers: {
                'Content-Type': 'application/json',
                'X-CSRFToken': getCookie('csrftoken')
            },
            body: json
        })
        if(res.ok){
			getSubProjectMaterial()
			getValues()
        }
    }

	const handleSelectionModelChange = (ids) => {
		const selectedIDs = new Set(ids);
		const selectedRows = materials.filter((row) =>
			selectedIDs.has(row.id),
		);
		setSelectedRows(selectedRows);
	}

	const getConfirmMessage = row => `Möchtest du das Material wirklich löschen?`

	return(
		<PaperPaginationDataGrid
			rows={materials}
			columns={columns}
			apiRef={apiRef}
			pageSize={pageSize}
			checkboxSelection
			onSelectionModelChange={handleSelectionModelChange}
			selectionModel={selectedRows.map(r => r.id)}
			disableSelectionOnClick={true}
			onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
			rowCount={pagMaterialsInfo.count}
			onPageChange={handlePageChange}
			loading={isLoading}
			title='Materialdepot'
			onSaveRow={handlePatchProjectMaterial}
			onDeleteRow={is_admin ? handleDelete : undefined}
			getConfirmMessage={getConfirmMessage}
			setSearchTerm={setSearchTerm}
			searchTerm={searchTerm}
			initialState={{ pinnedColumns: { right: ['actions'] } }}
			onOrderByGetParametersChange={onOrderByGetParametersChange}
		/>
    )
}

export default ProjectMaterialTable
