import { useState, useEffect, useCallback } from "react"
import { Button, Chip, Fade, Grid, Stack, Typography } from "@mui/material"

import { Add } from "@mui/icons-material"

import CustomPage from "../../../Widgets/CustomPage"
import CheckData from "../../../CheckData"

import PossibleProjectCard from "./PossibleProjectCard"
import PossibleProjectAddDialog from "./PossibleProjectAddDialog"
import PossibleProjectEditDialog from "./PossibleProjectEditDialog"

import { getConstants } from "../../../../utils/getConstants"


const PossibleProjects = () =>{

    const [openAdd, setOpenAdd] = useState(false)
    const [openEdit, setOpenEdit] = useState(false)
    const [isLoaded, setIsLoaded] = useState(false)
    const [possibleProjects, setPossibleProjects] = useState([])
    const [selectedPossibleProject, setSelectedPossibleProject] = useState(null)

    const [customers, setCustomers] = useState([])
    const [buisnessCustomers, setBuisnessCustomers] = useState([])
    const [projectTypes, setProjectTypes]=  useState({})
    const [statusTypes, setStatusTypes]=  useState({})

    const handleOpenAdd = (p_project) =>{
        setSelectedPossibleProject(p_project)
        setOpenAdd(true)
    }

    const handleCloseAdd = () =>{
        setOpenAdd(false)
    }

    const handleOpenEdit = (p_project) =>{
        setSelectedPossibleProject(p_project)
        setOpenEdit(true)
    }

    const handleCloseEdit = () =>{
        setOpenEdit(false)
    }


    const getPossibleProjects = useCallback(async () => {
        const res = await fetch('/api/company/possibleproject', {
            method: 'GET',
        })
        const data = await res.json()
        if (res.ok) {
            setIsLoaded(true)
            setPossibleProjects(data)
            // console.log("possible projects", data)
        }
    }, [])

    useEffect(() => {
        getPossibleProjects()
        getConstants('project_types', (c) => setProjectTypes(c))
        getConstants('possible_projects_status_types', (c) => setStatusTypes(c))
    }, [getPossibleProjects])

    useEffect(() => {
        const getCustomers = async() => {
            const res = await fetch('/api/company/customer', {
                method: 'GET',
            })
            const _data = await res.json()
            if (res.ok) {
                const transformed = { 0: "Nicht vorhanden"}
                for (const customer of _data) {
                    transformed[customer.id] = customer.firstname + ' ' + customer.lastname
                }
                setCustomers(transformed)
            }
        }
        const getBuisnessCustomers = async() => {
            const res = await fetch('/api/company/buisness_customer', {
                method: 'GET',
            })
            const _data = await res.json()
            if (res.ok) {
                const transformed = { 0: "Nicht vorhanden"}
                for (const customer of _data) {
                    transformed[customer.id] = customer.name
                }
                setBuisnessCustomers(transformed)
            }
        }
        getCustomers()
        getBuisnessCustomers()
    }, [])

    return(
        <CustomPage
            title="Potenzielle Projekte"
        >
            <Stack>
                <Stack
                    direction='row'
                    justifyContent='space-between'
                    alignItems='center'
                    spacing={3}
                    sx={{ marginBottom: 3}}
                >
                    <Stack
                        direction='row'
                        justifyContent='center'
                        alignItems='center'
                        spacing={1}
                    >
                        <Typography variant="text_m">Anzahl aller potenziellen Projekte</Typography>
                        <Chip color="primary" label={`${possibleProjects.length}`}/>
                    </Stack>
                    <Button
                        variant="text"
                        startIcon={<Add/>}
                        color="primary"
                        onClick={() => handleOpenAdd()}
                    >
                        Neues potenzielles Projekt
                    </Button>
                </Stack>
                <Stack>
                    <CheckData
                        data={possibleProjects}
                        isLoaded={isLoaded}
                        info_text="Du hast derzeit keine potenzielle Projekte"
                    >
                        <Grid
                            container
                            spacing={3}
                        >
                            {possibleProjects.map((pp, idx)=>{
                                return(
                                    <Fade
                                        key={idx}
                                        in={isLoaded}
                                        style={{ transitionDelay: idx * 250 + 'ms'}}
                                        timeout={500}
                                    >
                                        <Grid
                                            key={`possible-project-card-${idx}`}
                                            item
                                            desktop={3}
                                            laptop={4}
                                            tablet={12}
                                            mobile={12}
                                        >
                                            <PossibleProjectCard
                                                pp={pp}
                                                statusTypes={statusTypes}
                                                getPossibleProjects={getPossibleProjects}
                                                handleOpenEdit={handleOpenEdit}
                                            />
                                        </Grid>
                                    </Fade>
                                )
                            })}
                        </Grid>
                    </CheckData>
                </Stack>
            </Stack>
            <PossibleProjectAddDialog
                open={openAdd}
                onClose={handleCloseAdd}
                customers={customers}
                buisnessCustomers={buisnessCustomers}
                projectTypes={projectTypes}
                statusTypes={statusTypes}
                getPossibleProjects={getPossibleProjects}
            />
            <PossibleProjectEditDialog
                open={openEdit}
                onClose={handleCloseEdit}
                customers={customers}
                buisnessCustomers={buisnessCustomers}
                projectTypes={projectTypes}
                statusTypes={statusTypes}
                pp={selectedPossibleProject}
                getPossibleProjects={getPossibleProjects}
            />
        </CustomPage>
    )
}

export default PossibleProjects