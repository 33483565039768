import { Stack, Typography } from "@mui/material"


const ActivityMaterialListItem = ({ tx, pos, correction=false }) =>{

    const unique_material_id = !correction ? tx.project_material.unique_material_id : tx.tx.project_material.unique_material_id
    const article_description = !correction ? tx.project_material.article_description : tx.tx.project_material.article_description
    const unit = !correction ? tx.project_material.unit.split('/')[1] : tx.tx.project_material.unit.split('/')[1]
    const value = !correction ? tx.value : tx.value

    return(
        <Stack
            direction='row'
            justifyContent='space-between'
            spacing={2}
        >
            <Stack
                direction='row'
                spacing={2}
            >
                <Typography variant="text_m" sx={{width: 60}}>{`Pos. ${pos}`}</Typography>
                <Typography variant="text_m" sx={{width: 85}}>{unique_material_id}</Typography>
                <Typography variant="text_m" sx={{flex: 1}}>{article_description}</Typography>
            </Stack>
            <Typography variant="text_m">{`${value} ${unit}`}</Typography>
        </Stack>
    )
}

export default ActivityMaterialListItem