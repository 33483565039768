import { Stack } from "@mui/material"
import useMediaQuery from '@mui/material/useMediaQuery';

import { SETTINGS } from '.'
import LogoFullWhite from "components/Widgets/Logos/LogoFullWhite";


const Logo = ({ collapsed, openWidth }) => {
    const matches = useMediaQuery('(min-height: 1012px)');

    return (
        <Stack
            sx={{
                mt: matches ? 6 : 3,
                mb: matches ? 6 : 3,
                mx: 1,
                width: collapsed ? 36 : openWidth - 16,
                transition: 'all ' + SETTINGS.transitionDuration + ' ease-in-out',
                overflow: 'hidden'
            }}
            alignItems='center'
        >
            <div
                style={{
                    width: 220,
                    marginLeft: collapsed ? 40 : 0,
                    transition: 'all ' + SETTINGS.transitionDuration + ' ease-in-out',
                }}
            >
                <LogoFullWhite width={220} height={40}/>
            </div>
        </Stack>
    )
}

export default Logo
