import { useState } from 'react';
import { Delete, Edit } from '@mui/icons-material';
import { Stack, Box, Button, Divider, Dialog, DialogTitle, DialogContent, DialogActions, ListItem, ListItemAvatar, ListItemText, Avatar, IconButton } from '@mui/material';

import { getCookie } from "../../../../../utils/getCookie";

import useStyledSnackbar from '../../../../../hooks/useStyledSnackbar';

import AddCategory from "./AddCategory";
import ConfirmDialog from '../../../../Widgets/ConfirmDialog';
import EditCategory from './EditCategory';


const CategoryDialog = ({ open, onClose, categories, getMaterialCategories, getMaterial}) =>{

    const { enqueueSnackbar } = useStyledSnackbar()
    const [haveToLoad, setHaveToLoad] = useState(false)
    const [openEdit, setOpenEdit] = useState(false)
    const [selectedCategory, setSelectedCategory] = useState(null)

    const handleClose = () =>{
        if(haveToLoad){
            getMaterial()
        }
        onClose()
    }

    const handleEdit = (cat) =>{
        setOpenEdit(true)
        setSelectedCategory(cat)
    }

    const handleDelete = async(category_id) =>{
        if(category_id === '') return
        const json = JSON.stringify({
            category_id: category_id
        })
		const res = await fetch('/api/company/materials/category', {
            method: 'DELETE',
            headers: {
                'Content-Type': 'application/json',
                'X-CSRFToken': getCookie('csrftoken')
            },
            body: json
        })
        if(res.ok){
            setHaveToLoad(true)
            getMaterialCategories()
            enqueueSnackbar('Die Kategorie wurde erfolgreich gelöscht!', {
                variant: 'success'
            })
        }else{
            enqueueSnackbar('Es ist ein Fehler aufgetreten', {
                variant: 'error'
            })
        }
    }

    return(
        <>
            <Dialog
                open={open}
                onClose={handleClose}
                fullWidth
                maxWidth="tablet"
            >
                <DialogTitle>
                    Kategorien
                </DialogTitle>
                <DialogContent>
                    <Stack
                        spacing={5}
                        width="100%"
                    >
                        <AddCategory
                            getMaterialCategories={getMaterialCategories}
                        />
                        <Box
                            height={350}
                            width={'100%'}
                            sx={{
                                overflow: 'auto',

                            }}
                        >
                            {categories.map((category, idx) =>{
                                return(
                                    <Box key={`catergory-list-item${category.id}`}>
                                        <ListItem>
                                            <ListItemAvatar>
                                                <Avatar>
                                                    {`${category.key[0]}${idx}`}
                                                </Avatar>
                                            </ListItemAvatar>
                                            <ListItemText primary={category.name} secondary={`Schüssel: ${category.key}`}/>
                                            <IconButton
                                                onClick={()=> handleEdit(category)}
                                                color="primary"
                                            >
                                                <Edit/>
                                            </IconButton>
                                            <ConfirmDialog
                                                title='Achtung'
                                                message={'Möchtest du wirklich diese Kategorie löschen?'}
                                                onConfirm={()=> handleDelete(category.id)}
                                                renderButton={(onClick) => (
                                                    <IconButton
                                                        onClick={onClick}
                                                    >
                                                        <Delete/>
                                                    </IconButton>
                                                )}
                                            />
                                        </ListItem>
                                        {categories.length - 1 !== idx && <Divider/>}
                                    </Box>
                                )
                            })}
                        </Box>
                    </Stack>
                </DialogContent>
                <DialogActions>
                    <Button
                        onClick={handleClose}
                    >
                        Schließen
                    </Button>
                </DialogActions>
            </Dialog>
            <EditCategory
                open={openEdit}
                handleClose={()=>setOpenEdit(false)}
                setHaveToLoad={setHaveToLoad}
                category={selectedCategory}
                getMaterialCategories={getMaterialCategories}
            />
        </>
    )
}

export default CategoryDialog