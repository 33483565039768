import { useState, useEffect, useCallback } from "react";
import { Stack, DialogContent, Dialog, DialogTitle, Box, Typography, Divider, Alert, AlertTitle } from "@mui/material";

import { getLocalDateTime, secondsToHours, getLocalDate } from "utils/date_functions";
import { formatNumber } from "utils/helper";
import { PALETTE } from "../../../theme";


const TimeREcordingMonthBill = ({ open, onClose, company_member_id, selectedDate }) => {

    let sum = 0
    const [dayTimestamps, setDayTimestamps] = useState({})

    const getMonthBill = useCallback(async() => {
        if(open === false) return
        const res = await fetch('/api/time-recording/month-bill?company_member_id=' + company_member_id + '&date=' + getLocalDate(selectedDate), {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json'
            },
        })
        const data = await res.json()
        if(res.ok){
            setDayTimestamps(data)
        }
    },[company_member_id, selectedDate, open])

    useEffect(()=> {
        getMonthBill()
    },[getMonthBill])

    return(
        <Dialog
            open={open}
            onClose={onClose}
            maxWidth="laptop"
            fullWidth
        >
            <DialogTitle>
                {`Aktuelle Stundenübersicht - ${selectedDate.toLocaleString('de-DE', { month: 'long', year: 'numeric'})}`}
            </DialogTitle>
            <DialogContent>
                <Stack
                    gap={3}
                >
                    <Stack
                        gap={4}
                        sx={{
                            height: 700,
                            overflow: 'auto',
                            border: 1,
                            borderRadius: '10px',
                            borderColor: PALETTE.background.light70,
                            p: 1,
                        }}
                    >
                        {Object.entries(dayTimestamps).map(([day, container], idx)=>{
                            const detail = container.detail
                            const calc = container.calc
                            sum += secondsToHours(calc !== null ? calc?.total_time_net : 0)
                            return(
                                <Stack
                                    key={`day-times-${idx}`}
                                >
                                    <Typography variant="subtitle_s">{getLocalDateTime(new Date(day))}</Typography>
                                    <Divider sx={{pt:0.5}}flexItem/>
                                    <Stack>
                                        <Stack
                                            direction="row"
                                            gap={2}
                                            sx={{pt:1, pb: 1}}
                                        >
                                            {detail.map((type, idx)=> {
                                                return(
                                                    <Typography
                                                        key={`day-type-item-${idx}`}
                                                        variant="caption_m"
                                                        color="primary.main"
                                                    >
                                                        {type.type} {formatNumber(secondsToHours(type.total_time), 2, 'Std.')}
                                                    </Typography>
                                                )
                                            })}
                                        </Stack>
                                        <Divider flexItem/>
                                        <Stack
                                            direction="row"
                                        >
                                            {calc?.total_time_gross !== undefined ?
                                                <Alert
                                                    severity={calc.check_legal_breaktime?.result ? 'success' : 'error'}
                                                    sx={{flex: 1, m: 2, boxShadow: 0}}
                                                >
                                                    <AlertTitle>{calc.check_legal_breaktime?.result ? 'Gesetzliche Pausen wurden eingehalten' : 'Gesetzliche Pausen wurden nicht eingehalten'}</AlertTitle>
                                                    {calc.check_legal_breaktime?.error_message}
                                                </Alert>
                                            :
                                                <Box width="100%"/>
                                            }
                                            <Stack key={`day-type-item-${idx}`} justifyContent="flex-end" alignItems="flex-end" sx={{p:2}} gap={0.5}>
                                                <Stack
                                                    direction="row"
                                                    justifyContent="space-between"
                                                    width={250}
                                                >
                                                    <Typography variant="text_m">Bruttoarbeitsstunden</Typography>
                                                    <Typography variant="text_m">{formatNumber(secondsToHours(calc?.total_time_gross), 2, 'Std.')}</Typography>
                                                </Stack>
                                                <Stack
                                                    direction="row"
                                                    justifyContent="space-between"
                                                    width={250}
                                                >
                                                    <Typography variant="text_m">Pause</Typography>
                                                    <Typography variant="text_m">{formatNumber(secondsToHours(calc?.breaktime), 2, 'Std.')}</Typography>
                                                </Stack>
                                                <Stack
                                                    direction="row"
                                                    width={250}
                                                    justifyContent="space-between"
                                                >
                                                    <Typography variant="subtitle_s">Nettoarbeitsstunden</Typography>
                                                    <Typography variant="subtitle_s">{formatNumber(secondsToHours(calc?.total_time_net), 2, 'Std.')}</Typography>
                                                </Stack>
                                            </Stack>
                                        </Stack>
                                        <Divider flexItem/>
                                    </Stack>
                            </Stack>
                            )
                        })}
                    </Stack>
                    <Box>
                        <Stack
                            direction="row"
                            width="100%"
                            justifyContent="space-between"
                            alignItems="center"
                        >
                            <Typography variant="subtitle_m">Gesamt Nettoarbeitsstunden</Typography>
                            <Typography variant="value">{formatNumber(sum, 2, 'Std.')}</Typography>
                        </Stack>
                        <Divider sx={{mb:0.5}}flexItem/>
                        <Divider flexItem/>
                    </Box>
                </Stack>
            </DialogContent>
        </Dialog>
    )
}

export default TimeREcordingMonthBill