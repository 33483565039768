import { useState, useEffect, useCallback, useContext} from 'react'
import { Route, useParams, Routes, useLocation }  from 'react-router-dom';

import { ProjectProvider } from '../../../context/ProjectContext'
import { TaskTabWrapper } from 'context/TaskTabContext';

import CustomPage from '../../Widgets/CustomPage';
import ProjectHeader from '../../Widgets/ProjectHeader';
import LoadingScreen from '../../LoadingScreen';

import { PACKAGE_KEYS, PROJECT_TOOLBARS } from 'components/NavBar/Data'
import ProjectStatics from './ProjectStatics';
import ProjectChat from './ProjectChat';
import ProjectTasks from './ProjectTasks';
import ProjectTickets from './ProjectTickets';
import ProjectTimestamps from './ProjectTimestamps';
import ProjectMaterial from './ProjectMaterial';
import ProjectAgreements from './ProjectAgreements';
import ProjectNotes from './ProjectNotes';
import ProjectTodos from './ProjectTodos';
import ProjectEvents from './ProjectEvents';
import ProjectMembers from './ProjectTeam';
import ProjectManagement from './ProjectManagement';
import ProjectDocuments from './ProjectDocuments';
import ProjectChatMedia from './ProjectChatMedia';
import ProjectInvite from './ProjectTeam/ProjectInvite';
import ProjectDocumentation from './ProjectDocumentation';

import Paywall from 'components/Widgets/Paywall';
import CheckProjectPermission from '../../CheckProjectPermission';
import CheckPackageKey from 'components/CheckPackageKey';

import './style.css'

import ProjectToolbar from 'components/NavBar/ProjectToolbar';
import { SETTINGS as PROJECT_TOOLBAR_SETTINGS } from 'components/NavBar/ProjectToolbar'
import AccountContext from 'context/AccountContext';


const Project = () => {

    let { id } = useParams()
    const location = useLocation()
    const [account] = useContext(AccountContext)

    const [project, setProject] = useState({})
    const [projectTeamMembers, setProjectTeamMembers] = useState({})
    const [projectMemberTree, setProjectMemberTree] = useState({})
    const [projectMember, setProjectMember] = useState('')
    const [subProjects, setSubProjects] = useState()

    const [projectLoaded, setProjectLoaded] = useState(false)
    const [projectMembersLoaded, setProjectMembersLoaded] = useState(false)
    const [projectMemberLoaded, setProjectMemberLoaded] = useState(false)

    const pathSegments = location.pathname.split('/');
    const lastPathSegment = pathSegments[pathSegments.length - 1];

    const getProject = useCallback(async() =>{
        const res = await fetch('/api/project/' + id, {
            method: 'GET'
        })
        const data = await res.json()
        if(res.ok){
            setProject(data)
            setProjectLoaded(true)
            // console.log("Project: ", data)
        }
    }, [id])

    const getProjectMember = useCallback(async() =>{
        const res =await fetch('/api/project/' + id + '/member', {
            method: 'GET'
        })
        const data = await res.json()
        if(res.ok){
            setProjectMember(data)
            setProjectMemberLoaded(true)
            // console.log("Your project permission ", data)
        }
    }, [id])

    const getProjectTeamMembers = useCallback(() =>{
        fetch('/api/project/' + id + '/team-members', {
            method: 'GET'
        }).then(response => {
            response.json().then(data => {
                if(response.ok){
                    setProjectTeamMembers(data)
                    setProjectMembersLoaded(true)
                    // console.log("Project team: ", data)
                }else{
                }
            }).catch(error => {
                console.error(error)
            })
        }).catch(error => {
            console.error(error)
        })
    }, [id])

    const getProjectMemberTree = useCallback(async() =>{
        const res = await fetch('/api/project/' + id + '/members/tree', {
            method: 'GET'
        })
        const data = await res.json()
        if(res.ok){
            setProjectMemberTree(data)
        }
    }, [id])

    const getSubProjects =  useCallback(async() =>{
        const res = await fetch('/api/project/' + id + '/sub_projects', {
            method: 'GET'
        })
        const data = await res.json()
        if(res.ok){
            setSubProjects(data)
        }
    }, [id])

    useEffect(() => {
        getProject();
        getProjectMember();
        getProjectTeamMembers();
        getProjectMemberTree();
        getSubProjects()
    }, [getProject, getProjectMember, getProjectTeamMembers, getProjectMemberTree, getSubProjects])


    if(!projectLoaded || !projectMembersLoaded || !projectMemberLoaded){
        return(
            <LoadingScreen/>
        )
    }
    return(
        <ProjectProvider
            value={[
                project,
                projectMember,
                projectTeamMembers,
                projectMemberTree,
                getProject,
                getProjectTeamMembers,
                subProjects
            ]}
        >
            <ProjectToolbar
                data={PROJECT_TOOLBARS[account.company.package_key]}
                basePath={'/dashboard/projects/project/:id/*'}
            />
            <TaskTabWrapper>
                <div
                    style={{
                        marginLeft: PROJECT_TOOLBAR_SETTINGS.collapsedWidth + 'px',
                        width: 'calc(100% - ' + PROJECT_TOOLBAR_SETTINGS.collapsedWidth + 'px)',
                        flex: 1,
                        display: 'flex',
                        flexDirection: 'column',
                    }}
                >
                    <CustomPage
                        header={
                            <ProjectHeader subTitle={PROJECT_TOOLBARS[account.company.package_key][lastPathSegment]?.title} />
                        }
                    >
                        <Routes>
                            <Route path='statistics' element={
                                <CheckPackageKey
                                    redirectTo={location.pathname.split('/statistics')[0] + '/tasks'}
                                    packageKeys={[PACKAGE_KEYS.GU, PACKAGE_KEYS.HWU]}
                                >
                                    <CheckProjectPermission redirectTo={location.pathname.split('/statistics')[0] + '/tickets'} safety_level="manager">
                                        <ProjectStatics />
                                    </CheckProjectPermission>
                                </CheckPackageKey>
                            }/>
                            <Route path='chat' element={<ProjectChat />}/>
                            <Route path='tasks' element={
                                <CheckProjectPermission redirectTo={location.pathname + '/tickets'} safety_level="manager">
                                    <ProjectTasks/>
                                </CheckProjectPermission>
                            }/>
                            <Route path='timestamps' element={
                                <CheckProjectPermission redirectTo={location.pathname.split('/timestamps')[0] + '/tickets'} safety_level="manager">
                                    <ProjectTimestamps />
                                </CheckProjectPermission>
                            }/>
                            <Route path='materials' element={
                                <CheckPackageKey
                                    redirectTo={location.pathname.split('/materials')[0] + '/tasks'}
                                    packageKeys={[PACKAGE_KEYS.GU, PACKAGE_KEYS.HWU]}
                                >
                                    <CheckProjectPermission redirectTo={location.pathname.split('/materials')[0] + '/tickets'} safety_level="manager">
                                        <ProjectMaterial />
                                    </CheckProjectPermission>
                                </CheckPackageKey>
                            }/>
                            <Route path='agreements' element={
                                <CheckProjectPermission redirectTo={location.pathname.split('/agreements')[0] + '/tickets'} safety_level="manager">
                                    <ProjectAgreements />
                                </CheckProjectPermission>
                            }/>
                            <Route path='notes' element={<ProjectNotes />}/>
                            <Route path='todos' element={<ProjectTodos />}/>
                            <Route path='events' element={<ProjectEvents />}/>
                            <Route path='tickets' element={<ProjectTickets />}/>
                            <Route path='members' element={<ProjectMembers />}/>
                            <Route path='documents' element={<ProjectDocuments />}/>
                            <Route path='chatmedia' element={<ProjectChatMedia />}/>
                            <Route path='invite' element={
                                <Paywall>
                                    <CheckProjectPermission redirectTo={location.pathname.split(':id')[0] + id + '/projectboard'} safety_level="manager">
                                        <ProjectInvite />
                                    </CheckProjectPermission>
                                </Paywall>
                            }/>
                            <Route path='documentation' element={
                                <Paywall>
                                    <CheckProjectPermission redirectTo={location.pathname.split(':id')[0] + id + '/projectboard'} safety_level="manager">
                                        <ProjectDocumentation />
                                    </CheckProjectPermission>
                                </Paywall>
                            }/>
                            <Route path='management' element={
                                <CheckProjectPermission redirectTo={location.pathname.split(':id')[0] + id + '/projectboard'} safety_level="leader">
                                    <ProjectManagement />
                                </CheckProjectPermission>
                            }/>
                        </Routes>
                    </CustomPage>
                </div>
            </TaskTabWrapper>
        </ProjectProvider>
    )
}

export default Project;
