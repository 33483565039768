import { useEffect, useState, useCallback } from "react";
import CopyToClipboard from "react-copy-to-clipboard";
import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Divider, Grid, IconButton, ListItem, ListItemButton, ListItemIcon, ListItemText, Stack, Tooltip, Typography } from "@mui/material";
import { Delete } from "@mui/icons-material";
import { BsFillFileEarmarkPdfFill } from "react-icons/bs";
import { MdFactCheck, MdLink, MdLocalGroceryStore, MdTimelapse } from "react-icons/md";

import NoData from "components/Widgets/NoData";
import ConfirmDialog from "components/Widgets/ConfirmDialog";
import MailOrderDialog from "../MailOrderDialog";
import RequestMaterial from "./RequestMaterial";

import useStyledSnackbar from "hooks/useStyledSnackbar";

import { getCookie } from "utils/getCookie";
import { formatTimestamp } from "utils/date_functions";

import theme, { PALETTE } from "components/theme";



const SupplierResponseDialog = ({ open, onClose, getValues, project_id, selectedSubProject }) => {

    const { enqueueSnackbar } =  useStyledSnackbar()
    const [supplierResponse, setSupplierResponse] = useState([])
    const [selectedSupplierResponse, setSelectedSupplierResponse] = useState(null)
    const [orderResponse, setOrderResponse] = useState(null)
    const [orderDialog, setOrderDialog] = useState(false)

    const handleOnClose = () =>{
        setSelectedSupplierResponse(null)
        onClose()
    }

    const getSupplierResponse = useCallback(async() =>{
        const res = await fetch('/api/project/' + project_id  + '/sub-project/'+ selectedSubProject +'/material/supplier/request', {
            method: 'GET'
        })
        const data = await res.json()
        if(res.ok){
            console.log(data)
            setSupplierResponse(data)
        }
    }, [project_id, selectedSubProject])

    const handleOrder = async(id) =>{
        setOrderDialog(true)
        const json = JSON.stringify({id: id})
        const res = await fetch('/api/project/' + project_id  + '/sub-project/'+ selectedSubProject +'/material/supplier/request', {
            method: 'PATCH',
            headers: {
                'Content-Type': 'application/json',
                'X-CSRFToken': getCookie('csrftoken')
            },
            body: json
        })
        const data = await res.json()
        if(res.ok){
            setOrderResponse(data)
            getValues()
            enqueueSnackbar(`Die Bestellung wurde erfolgreich durchgefürht!`, {
                variant: 'success'
            })
        } else {
            setOrderDialog(false)
        }
    }

    const deleteSupplierResponse = async(id) =>{
        const json = JSON.stringify({id: id})
        const res = await fetch('/api/project/' + project_id  + '/sub-project/'+ selectedSubProject +'/material/supplier/request', {
            method: 'DELETE',
            headers: {
                'Content-Type': 'application/json',
                'X-CSRFToken': getCookie('csrftoken')
            },
            body: json
        })
        if(res.ok){
            getSupplierResponse()
        }
    }

    useEffect(()=>{
        if(open !== false){
            getSupplierResponse()
        }
    }, [open, getSupplierResponse])

    return (
        <Dialog
            open={open}
            onClose={handleOnClose}
            maxWidth="desktop"
            fullWidth

        >
            <DialogTitle>
                Materialanfragen
            </DialogTitle>
            <DialogContent>
                <DialogContentText gutterBottom>
                    Hier kannst du deine Materialanfragen anschauen und direkt als Bestellung rausschicken.
                </DialogContentText>
                <Stack height={750}>
                    {supplierResponse.length !== 0 ?
                        <Stack
                            flexDirection="row"
                            width="100%"
                            height="100%"
                            gap={3}
                            sx={{
                                mt:1,
                            }}
                        >
                            <Stack
                                width="30%"
                                sx={{
                                    overflow: 'auto',
                                    border: 1,
                                    borderRadius: '10px',
                                    borderColor: PALETTE.background.light70
                                }}
                            >
                                {supplierResponse.map((supRes, idx)=>{
                                    const _idx = supplierResponse.length - idx
                                    return(
                                        <>
                                            <ListItem
                                                key={`suppler-response-${idx}`}
                                                secondaryAction={
                                                    <ConfirmDialog
                                                        title="Materialanfrage löschen"
                                                        message="Bist du dir sicher, dass du die Materialanfrage löschen willst?"
                                                        onConfirm={() => deleteSupplierResponse(supRes.id)}
                                                        renderButton={(onClick) => (
                                                            <IconButton
                                                                edge="end"
                                                                color="error"
                                                                onClick={onClick}
                                                            >
                                                                <Delete fontSize="small"/>
                                                            </IconButton>
                                                        )}
                                                    />
                                                }
                                                disablePadding
                                            >
                                                <ListItemButton onClick={()=> setSelectedSupplierResponse(supRes)} dense>
                                                    <ListItemIcon>
                                                    {supRes.filled ? <MdFactCheck size={22} color={PALETTE.primary.main}/> : <MdTimelapse size={22} color={theme.palette.secondary.main}/>}
                                                    </ListItemIcon>
                                                    <ListItemText
                                                        primary={`${_idx} | ${supRes.supplier.name} | ${supRes.supplier.contact_person}`}
                                                        secondary={`${formatTimestamp(supRes.created)} | ${supRes.filled ? 'Erhalten' : 'Ausstehend'}`}
                                                    />
                                                </ListItemButton>
                                            </ListItem>
                                            {_idx > 1 && <Divider light/>}
                                        </>
                                    )
                                })}
                            </Stack>
                            <Stack
                                width="70%"
                            >
                                {selectedSupplierResponse ?
                                    <Stack
                                        height="100%"
                                        gap={3}
                                    >
                                        <Stack
                                            flexDirection="row"
                                            gap={1}
                                            alignItems="center"
                                            sx={{pl: 1}}
                                        >
                                            {selectedSupplierResponse.filled ? <MdFactCheck size={22} color={PALETTE.primary.main}/> : <MdTimelapse size={22} color={theme.palette.secondary.main}/>}
                                            <Typography variant="subtitle_m">Materialanfrage</Typography>
                                        </Stack>
                                        <Grid
                                            container
                                            spacing={1}
                                        >
                                            <Grid
                                                item
                                                mobile={12}
                                                tablet={3}
                                                laptop={3}
                                                desktop={3}
                                            >
                                                <Stack sx={{p : '0px 10px 0px 10px'}}>
                                                    <Typography variant="caption_s">Lieferant</Typography>
                                                    <Typography variant="text_s">{selectedSupplierResponse.supplier.name}</Typography>
                                                </Stack>
                                            </Grid>
                                            <Grid
                                                item
                                                mobile={12}
                                                tablet={3}
                                                laptop={3}
                                                desktop={3}
                                            >
                                                <Stack sx={{p : '0px 10px 0px 10px'}}>
                                                    <Typography variant="caption_s">Ausgefüllt von</Typography>
                                                    <Typography variant="text_s">{selectedSupplierResponse.supplier.contact_person}</Typography>
                                                </Stack>
                                            </Grid>
                                            <Grid
                                                item
                                                mobile={12}
                                                tablet={3}
                                                laptop={3}
                                                desktop={3}
                                            >
                                                <Stack sx={{p : '0px 10px 0px 10px'}}>
                                                    <Typography variant="caption_s">E-Mail</Typography>
                                                    <Typography variant="text_s">{selectedSupplierResponse.supplier.email}</Typography>
                                                </Stack>
                                            </Grid>
                                            <Grid
                                                item
                                                mobile={12}
                                                tablet={3}
                                                laptop={3}
                                                desktop={3}
                                            >
                                                <Stack
                                                    flexDirection="row"
                                                    alignItems="center"
                                                    gap={2}
                                                    sx={{p : '0px 10px 0px 10px'}}
                                                >
                                                    <CopyToClipboard text={selectedSupplierResponse.url}>
                                                        <Tooltip title="Link für den Lieferanten kopieren" disableHoverListener={false}>
                                                            <span>
                                                                <IconButton color='primary'>
                                                                    <MdLink size={18}/>
                                                                </IconButton>
                                                            </span>
                                                        </Tooltip>
                                                    </CopyToClipboard>
                                                    <Tooltip title={selectedSupplierResponse.file !== null ? "Angebot herunterladen" : "Keine Datei vorhanden"}>
                                                        <span>
                                                            <IconButton color='primary' href={selectedSupplierResponse.file} target="_blank" download="Angebot" disabled={selectedSupplierResponse.file !== null ? false : true }>
                                                                <BsFillFileEarmarkPdfFill size={18} />
                                                            </IconButton>
                                                        </span>
                                                    </Tooltip>
                                                </Stack>
                                            </Grid>
                                            <Grid
                                                item
                                                mobile={12}
                                                tablet={3}
                                                laptop={3}
                                                desktop={3}
                                            >
                                                <Stack sx={{p : '0px 10px 0px 10px'}}>
                                                    <Typography variant="caption_s">Angebotsnummer</Typography>
                                                    <Typography variant="text_s">{selectedSupplierResponse.order_id ? selectedSupplierResponse.order_id : '--'}</Typography>
                                                </Stack>
                                            </Grid>
                                            <Grid
                                                item
                                                mobile={12}
                                                tablet={3}
                                                laptop={3}
                                                desktop={3}
                                            >
                                                <Stack sx={{p : '0px 10px 0px 10px'}}>
                                                    <Typography variant="caption_s">Ausgefüllt am</Typography>
                                                    <Typography variant="text_s">{selectedSupplierResponse.confirmed_on ? formatTimestamp(selectedSupplierResponse.confirmed_on) : '--'}</Typography>
                                                </Stack>
                                            </Grid>
                                            <Grid
                                                item
                                                mobile={12}
                                                tablet={3}
                                                laptop={3}
                                                desktop={3}
                                            >
                                                <Stack sx={{p : '0px 10px 0px 10px'}}>
                                                    <Typography variant="caption_s">Gültig bis</Typography>
                                                    <Typography variant="text_s">{selectedSupplierResponse.confirmed ? formatTimestamp(selectedSupplierResponse.expired_on) : '--'}</Typography>
                                                </Stack>
                                            </Grid>
                                            <Grid
                                                item
                                                mobile={12}
                                                tablet={3}
                                                laptop={3}
                                                desktop={3}
                                            >
                                                <Stack sx={{p : '0px 10px 0px 10px'}}>
                                                    <Typography variant="caption_s">Angefordert von</Typography>
                                                    <Typography variant="text_s">{selectedSupplierResponse.creator.firstname} {selectedSupplierResponse.creator.lastname}</Typography>
                                                </Stack>
                                            </Grid>
                                            <Grid
                                                item
                                                mobile={12}
                                                tablet={3}
                                                laptop={3}
                                                desktop={3}
                                            >
                                                <Stack sx={{p : '0px 10px 0px 10px'}}>
                                                    <Typography variant="caption_s">Erstellt am</Typography>
                                                    <Typography variant="text_s">{formatTimestamp(selectedSupplierResponse.created)}</Typography>
                                                </Stack>
                                            </Grid>
                                            <Grid
                                                item
                                                mobile={12}
                                                tablet={3}
                                                laptop={3}
                                                desktop={3}
                                            >
                                                <Stack sx={{p : '0px 10px 0px 10px'}}>
                                                    <Typography variant="caption_s">Anmerkung</Typography>
                                                    <Typography variant="text_s">{selectedSupplierResponse.annotation ? selectedSupplierResponse.annotation : '--'}</Typography>
                                                </Stack>
                                            </Grid>
                                            <Grid
                                                item
                                                mobile={12}
                                                tablet={6}
                                                laptop={6}
                                                desktop={6}
                                            >
                                                <Stack sx={{p : '0px 10px 0px 10px'}}>
                                                    <Typography variant="caption_s">Anmerkung vom Lieferanten</Typography>
                                                    <Typography variant="text_s">{selectedSupplierResponse.supplier_annotation ? selectedSupplierResponse.supplier_annotation : '--'}</Typography>
                                                </Stack>
                                            </Grid>
                                        </Grid>
                                        <Stack
                                            height="100%"
                                            gap={1}
                                            sx={{
                                                overflow: 'auto',
                                                border: 1,
                                                p: 1,
                                                borderRadius: '10px',
                                                borderColor: PALETTE.background.light70
                                            }}
                                        >
                                            {selectedSupplierResponse.request_materials.map((rm, idx)=>{
                                                return(
                                                    <>
                                                        <RequestMaterial requestMaterial={rm}/>
                                                        {selectedSupplierResponse.request_materials.length > idx + 1 && <Divider flexItem/>}
                                                    </>
                                                )
                                            })}
                                        </Stack>
                                        <ConfirmDialog
                                            title="Material bestellen?"
                                            message="Bist du dir sicher, dass du die Materialien beim Lieferanten bestellen möchtest?"
                                            onConfirm={() => {handleOrder(selectedSupplierResponse.id)}}
                                            renderButton={(onClick) => (
                                                <Button
                                                    variant="outlined"
                                                    onClick={onClick}
                                                    startIcon={<MdLocalGroceryStore/>}
                                                    disabled={!selectedSupplierResponse.confirmed}
                                                >
                                                    Beim Lieferanten bestellen
                                                </Button>
                                            )}
                                        />
                                    </Stack>
                                :
                                    <NoData text="Wähle eine Materialanfrage aus.."/>
                                }
                            </Stack>
                        </Stack>
                    :
                        <NoData text="Du hast derzeit keine Materialanfrage bei einem deiner Lieferanten."/>
                    }
                </Stack>
            </DialogContent>
            <DialogActions>
                <Button
                    color="secondary"
                    onClick={handleOnClose}
                >
                    Schließen
                </Button>
            </DialogActions>
            <MailOrderDialog
                open={orderDialog}
                setOpen={setOrderDialog}
                res={orderResponse}
                setRes={setOrderResponse}
                project_id={project_id}
                sub_project_id={selectedSubProject}
            />
        </Dialog>
    );
}

export default SupplierResponseDialog;