import { Routes, Route }  from 'react-router-dom';

import Reset from './Reset';
import Request from './Request';


const PasswordReset = () => {
    return (
        <Routes>
            <Route path='/' element={<Reset />} />
            <Route path='/request' element={<Request />} />
        </Routes>
    )
}

export default PasswordReset
