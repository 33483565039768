import {useContext, useEffect, useState} from "react";
import { useForm } from "react-hook-form";

import {
    Button,
    Dialog,
    DialogTitle,
    DialogContent,
    Stack,
    DialogActions,
    DialogContentText,
    Typography
} from '@mui/material';

import { getCookie } from '../../../../../utils/getCookie'

import ControlledTextField from '../../../../Widgets/ControlledInputs/ControlledTextField';
import ControlledDateRangePicker from '../../../../Widgets/ControlledInputs/ControlledDateRangePicker';
import ControlledSelect from "../../../../Widgets/ControlledInputs/ControlledSelect";

import { getLocalDate } from '../../../../../utils/date_functions'
import { checkSelectionZero } from "../../../../../utils/helper";
import AccountContext from "../../../../../context/AccountContext";
import ControlledSwitch from "components/Widgets/ControlledInputs/ControlledSwitch";


const TaskEditDialog = (props) =>{

    const { open, onClose, task, project_id } = props

    const [account] = useContext(AccountContext)

    const [customers, setCustomers] = useState([])
    const [buisnessCustomers, setBuisnessCustomers] = useState([])
    const { control, handleSubmit, reset, setValue, watch, formState: { errors, isDirty, isValid }} = useForm({
        mode: 'onChange',
        defaultValues:{
            title: '',
            instruction: '',
            value: '',
            max_workload: '',
            max_material: '',
            max_task: '',
            privat_customer_outside: '',
            buisness_customer_outside: '',
            date_range: [null, null],
            wage_rate: '',
            contract_number_customer:'',
            contract_number_service_provider:'',
            at_cost: false,
            drive_rate: 0,
        }
    });

    const handleEditProjectTask = async(data) => {
        const json = JSON.stringify({
                ...data,
                privat_customer_outside: checkSelectionZero(data.privat_customer_outside),
                buisness_customer_outside: checkSelectionZero(data.buisness_customer_outside),
                sub_project_id: task.id,
                date_range: [getLocalDate(data.date_range[0]), getLocalDate(data.date_range[1])],
        })
        const res = await fetch('/api/project/' + project_id + '/tasks', {
            method: 'PATCH',
            headers: {
                'Content-Type': 'application/json',
                'X-CSRFToken': getCookie('csrftoken')
            },
            body: json,
        })
        const d = await res.json()
        if (res.ok) {
            onClose()
            reset()
            console.log(d)
        }else{
            console.log(d)
        }
    };

    useEffect(() => {
        if(task == null) return
        let date_range = [null, null]
        for (const [key, value] of Object.entries(task)) {
            if (key === 'expected_to_start') {
                date_range[0] = new Date(value)
                continue
            }
            if (key === 'expected_to_end') {
                date_range[1] = new Date(value)
                continue
            }
            if (key === 'privat_customer_outside' || key === 'buisness_customer_outside' ) {
                if(value !== null){
                    setValue(key, value.id, {
                        shouldValidate: true,
                        shouldDirty: true
                    })
                } else {
                    setValue(key, "0", {
                        shouldValidate: true,
                        shouldDirty: true
                    })
                }
                continue
            }
            if (key === 'contract_number_customer' || key === 'contract_number_service_provider' ) {
                if(value === null){
                    setValue(key, '',{
                        shouldValidate: true,
                        shouldDirty: true
                    })
                }
                continue
            }
            setValue(key, value, {
                shouldValidate: true,
                shouldDirty: true
            })
        }
        setValue('date_range', date_range, {
            shouldValidate: true,
            shouldDirty: true
        })
    }, [task, setValue])

    useEffect(() => {
        if (!account.company.paid)
            return

        const getCustomers = async() => {
            const res = await fetch('/api/company/customer', {
                method: 'GET',
            })
            const _data = await res.json()
            if (res.ok) {
                const transformed = { 0: "Nicht vorhanden"}
                for (const customer of _data) {
                    transformed[customer.id] = customer.firstname + ' ' + customer.lastname
                }
                setCustomers(transformed)
            }
        }
        const getBuisnessCustomers = async() => {
            const res = await fetch('/api/company/buisness_customer', {
                method: 'GET',
            })
            const _data = await res.json()
            if (res.ok) {
                const transformed = { 0: "Nicht vorhanden"}
                for (const customer of _data) {
                    transformed[customer.id] = customer.name
                }
                setBuisnessCustomers(transformed)
            }
        }
        getCustomers()
        getBuisnessCustomers()
    }, [account.company.paid])

    return(
        <Dialog
            maxWidth="laptop"
            open={open}
            onSubmit={handleSubmit(handleEditProjectTask)}
            onClose={onClose}
            fullWidth
        >
            <form>
                <DialogTitle>Projektauftrag bearbeiten</DialogTitle>
                <DialogContent>
                    <Stack
                        spacing={2}
                    >
                        <DialogContentText>
                            Bearbeiten Sie ihren Projektauftrag, um die Ihre Projetkstatistik zu verbessern..
                        </DialogContentText>
                        <Stack
                           direction="row"
                           sx={{
                               mt:1.5
                           }}
                           gap={2}
                        >
                            {(task?.posted || task?.private)&&
                                <Stack
                                    gap={2}
                                    width="100%"
                                >
                                    <ControlledTextField
                                        name="title"
                                        control={control}
                                        rules={{ required: true }}
                                        label="Name des Projektauftrages"
                                        errors={errors}
                                    />
                                    <ControlledTextField
                                        name="contract_number_customer"
                                        control={control}
                                        rules={{ required: false }}
                                        label="Auftragsnummer - Auftraggeber (optional)"
                                        errors={errors}
                                    />
                                    <ControlledTextField
                                        name="instruction"
                                        control={control}
                                        rules={{ required: true }}
                                        label="Projektauftragsbeschreibung"
                                        multiline
                                        rows={5}
                                        errors={errors}
                                    />
                                    <ControlledTextField
                                        name="value"
                                        control={control}
                                        rules={{ required: true,  maxLength: { value: 12, message: "Zu viele Ziffern! max. 8 Ziffern" } }}
                                        label="Projektauftragssumme"
                                        errors={errors}
                                        type="number"
                                        suffix="€"
                                    />
                                    <ControlledDateRangePicker
                                        name="date_range"
                                        control={control}
                                        rules={{ required: true}}
                                        startText="Start des Projektauftrages"
                                        endText="Ende des Projektauftrages"
                                    />
                                    <ControlledSelect
                                        name="privat_customer_outside"
                                        control={control}
                                        label="Privatkunde (optional)"
                                        rules={{ required: false }}
                                        errors={errors}
                                        items={customers}
                                    />
                                    <ControlledSelect
                                        name="buisness_customer_outside"
                                        control={control}
                                        label="Gewerbekunde (optional)"
                                        rules={{ required: false }}
                                        errors={errors}
                                        items={buisnessCustomers}
                                    />
                                </Stack>}
                            {(task?.received || task?.private) &&
                                <Stack
                                    gap={2}
                                    width="100%"
                                >
                                    <ControlledTextField
                                        name="contract_number_service_provider"
                                        control={control}
                                        rules={{ required: false }}
                                        label="Auftragsnummer - Dienstleister (optional)"
                                        errors={errors}
                                    />
                                    <ControlledTextField
                                        name="max_workload"
                                        control={control}
                                        rules={{ required: true }}
                                        label="Kalkulierte Arbeitsstunden"
                                        errors={errors}
                                        type="number"
                                        suffix="Std."
                                    />
                                    <ControlledTextField
                                        name="drive_rate"
                                        control={control}
                                        label="Fahrtkostenpauschale"
                                        rules={{ required: false }}
                                        errors={errors}
                                        suffix="€"
                                        type="number"
                                    />
                                    <ControlledTextField
                                        name="wage_rate"
                                        control={control}
                                        label="Stundenverrechnungssatz ohne Lohngewinn"
                                        rules={{ required: true }}
                                        errors={errors}
                                        suffix="€/Std."
                                        type="number"
                                    />
                                    <ControlledTextField
                                        name="max_material"
                                        control={control}
                                        rules={{ required: true }}
                                        label="Kalkulierte Materialkosten"
                                        errors={errors}
                                        type="number"
                                        suffix="€"
                                    />
                                    <ControlledTextField
                                        name="max_task"
                                        control={control}
                                        rules={{ required: true }}
                                        label="Kalkulierte externe Auftragskosten"
                                        errors={errors}
                                        type="number"
                                        suffix="€"
                                    />
                                    <Stack>
                                        <ControlledSwitch
                                            name="at_cost"
                                            control={control}
                                            label="Auftrag nach Aufwand berechnen"
                                            color="primary"
                                        />
                                        <Typography variant="text_m" sx={{pt:1, pb:1}}>
                                            {watch('at_cost') === true ?
                                                "Bitte gibe trotzdem die geschätzten Kosten für die Vorkalkulation des Auftrags ein, um den Überblick über die Nachkalkulation des Auftrags zu gewährleisten."
                                            :
                                                ""
                                            }
                                        </Typography>
                                    </Stack>
                                </Stack>}
                        </Stack>
                    </Stack>
                </DialogContent>
                <DialogActions sx={{ mb: 2, p:2}}>
                    <Button
                        onClick={onClose}
                        color="secondary"
                    >
                        Abbrechen
                    </Button>
                    <Button
                        type="submit"
                        color="primary"
                        variant="outlined"
                        disabled={!isDirty || !isValid}
                    >
                            Änderungen speichern
                    </Button>
                </DialogActions>
            </form>
        </Dialog>
    )
}

export default TaskEditDialog