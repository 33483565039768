import { useState, useContext, createContext} from 'react'


const TaskTabContext = createContext()

export const TaskTabWrapper = ({children}) => {
    const state = useState(0)


    return (
        <TaskTabContext.Provider value={state}>
            {children}
        </TaskTabContext.Provider>
    )
}

export const useTaskTab = () => {
    return useContext(TaskTabContext)
}

