import { useState, memo, useMemo, useEffect } from 'react'
import { AvatarGroup, Avatar, ButtonBase, Typography, Divider, Stack } from '@mui/material'
import { BsFillCalendarEventFill, BsFillPersonFill } from "react-icons/bs";

import { PALETTE } from '../../../../theme';
import { convertTimeString } from '../../../../../utils/date_functions';


export const filterEventsByDay = (dayDate, events) => {
    return events.filter(e => new Date(e.day).toDateString() === dayDate.toDateString())
}

const DayItem = ({ events, openDialog, year, month, day: dayDateDay, availableCompanyMembers }) =>{

    const [cleanEvents, setCleanEvents] = useState([])
    const [cmPlannedCounter, setCmPlannedCounter] = useState(0)

    const dayDate = useMemo(() => new Date(year, month, dayDateDay), [year, month, dayDateDay])
    const currentDate = new Date()
    const isToday = currentDate.toDateString() === dayDate.toDateString()
    const dayOfWeek = dayDate.getDay()
    const isWeekend = (dayOfWeek === 6) || (dayOfWeek  === 0)

    const day = dayDate.getDate().toLocaleString('de-DE', {
        minimumIntegerDigits: 2,
        useGrouping: false
    })
    const day_str = dayDate.toLocaleDateString('de-DE', {
         weekday: 'short'
    })

    useEffect(() => {
        setCleanEvents(filterEventsByDay(dayDate, events))
    }, [dayDate, events])

    useEffect(() => {
        let _temp = []
        cleanEvents.forEach(e =>{
            _temp = _temp.concat(e.allocator.map(function(allo){
                return allo.company_member.id
            }))
        })
        _temp = [...new Set(_temp)]
        setCmPlannedCounter(_temp.length)
    }, [cleanEvents])


    return (
        <div
            key={`day-${day}`}
            onClick={() => openDialog(dayDate)}
            style={{
                display: 'flex',
                flexDirection: 'row',
                flex: 1,
                borderTop: '1px solid ' + PALETTE.text.disabled,
                width: '100%',
                height: '100%',

            }}
        >
            <ButtonBase
                sx={{
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'flex-start',
                    width: '100%',
                    height: '100%',
                    borderRadius: '5px',
                    backgroundColor : isToday ? 'rgba(0,128,128,0.1)' : 'inherit'
                }}
            >
                <div
                    style={{
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'center',
                        alignItems: 'center',
                        paddingLeft: '8px',
                        paddingRight: '8px',
                        gap: '2px',
                        width: '50px',
                    }}
                >
                    <p style={{ fontSize: 22, color: isToday ?  PALETTE.primary.main : isWeekend  ? PALETTE.error.main : PALETTE.background.light10, fontWeight: 'bold' }}>{day}</p>
                    <p style={{ fontSize: 20, color: isToday ?  PALETTE.primary.main : isWeekend  ? PALETTE.error.main : PALETTE.background.light10 }}>{day_str}</p>
                </div>
                <div
                    style={{
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'flex-start',
                        alignItems: 'flex-start',
                        // borderLeft: '4px double ' + PALETTE.text.disabled,
                        overflow: 'auto',
                        width: '100%',
                        height: '100%'
                    }}
                >
                    {cleanEvents.length !== 0 &&
                        <>
                            <Stack
                                flexDirection="row"
                                justifyContent="space-evenly"
                                alignItems= 'center'
                                width='100%'
                                sx={{p:0.5}}
                            >
                                <Stack
                                    flexDirection="row"
                                    justifyContent='center'
                                    alignItems='center'
                                >
                                    <BsFillPersonFill size={17} color={PALETTE.primary.main}/>
                                    <Typography variant="value" sx={{mt: 0.4, ml:0.5 }}>{`${cmPlannedCounter} / ${availableCompanyMembers}`}</Typography>
                                </Stack>
                                <Stack
                                    flexDirection="row"
                                    justifyContent='center'
                                    alignItems='center'
                                >
                                    <BsFillCalendarEventFill size={14} color={PALETTE.primary.main}/>
                                    <Typography variant="value" sx={{mt: 0.4, ml:0.5}}>{`${cleanEvents.length}`}</Typography>
                                </Stack>
                            </Stack>
                            <Divider sx={{ mb:1 }}flexItem/>
                            {cleanEvents.map((event, idx)=>{
                                return(
                                    <Stack
                                        key={`event-container-${event.id}`}
                                        justifyContent="flex-start"
                                        alignItems="flex-start"
                                        width='100%'
                                        spacing={0}
                                        sx={{pl:1, pr: 1}}
                                    >
                                        <Stack
                                            flexDirection="row"
                                            justifyContent="space-between"
                                            width='100%'
                                        >
                                            <Typography
                                                variant="text_m"
                                                sx={{ overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap' }}
                                            >
                                                {`${event.title}`}
                                            </Typography>
                                            <Typography variant="text_m" sx ={{ml: 2}}>{`${convertTimeString(event.since)} - ${convertTimeString(event.till)}`}</Typography>
                                        </Stack>
                                        <Typography
                                                variant="text_m"
                                                sx={{ fontSize: 13, fontWeight: 'bold', overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap' }}
                                            >
                                                {`${event.sub_project.project}`}
                                        </Typography>
                                        <Typography
                                                variant="caption_m"
                                                color="primary.main"
                                                sx={{ overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap' }}
                                            >
                                                {`Auftrag: ${event.sub_project.title}`}
                                        </Typography>
                                        <AvatarGroup max={8} sx={{ mt:0.5}}>
                                            {event.allocator.map((allocator)=>{
                                                return(
                                                    <Avatar
                                                        key={`company_member-avatar-${allocator.company_member.id}`}
                                                        alt={`company_member-avatar-${allocator.company_member.id}`}
                                                        src={allocator.company_member.avatar}
                                                    />
                                                )
                                            })}
                                        </AvatarGroup>
                                        {cleanEvents.length > idx + 1   && <Divider sx={{ mt:1, mb:1}} flexItem/>}
                                    </Stack>
                                )
                            })}
                        </>
                    }
                </div>
            </ButtonBase>
        </div>
    )
}

export default memo(DayItem)
