import { useState, useCallback, useContext, useEffect, useRef } from 'react'
import { Calendar as BigCalendar, dateFnsLocalizer } from 'react-big-calendar'

import { Stack } from '@mui/material'

import AccountContext from '../../../../context/AccountContext'

import { format, parse, startOfWeek, getDay } from 'date-fns'
import { de } from 'date-fns/locale'

import CustomPaper from '../../../Widgets/CustomPaper'
import AddEventDialog from './AddEventDialog'
import SelectedEvent from './SelectedEvent'

import { PALETTE } from '../../../theme'
import { getLocalDate } from '../../../../utils/date_functions'

import './style.scss'

const locales = {
    'de': de,
}

const localizer = dateFnsLocalizer({
    format,
    parse,
    startOfWeek,
    getDay,
    locales,
})

const messages = {
    date: 'Datum',
    time: 'Zeit',
    event: 'Termin',
    allDay: 'Ganztägig',
    week: 'Woche',
    work_week: 'Arbeitswoche',
    day: 'Tag',
    month: 'Monat',
    previous: '<',
    next: '>',
    yesterday: 'Gestern',
    tomorrow: 'Morgen',
    today: 'Heute',
    agenda: 'Agenda',
    noEventsInRange: 'Keine Termine',
    showMore: total => `+${total} Termine`,
}


const Calendar = () => {

    const [account] = useContext(AccountContext)
    const [openAdd, setOpenAdd] = useState(false)
    const [newEvent, setNewEvent] = useState({
        start: null,
        end: null
    });
    const [events, setEvents] = useState([])
    const [selectedEventId, setSelectedEventId] = useState(null);
    const [date, setDate] = useState(new Date())
    const prevMonth = useRef(new Date().getMonth())
    const initialLoad = useRef(false)

    const onNavigate = useCallback((newDate) => setDate(newDate), [setDate])

    const getEvents = useCallback(async () => {
        const res = await fetch('/api/company/calendar?date=' + getLocalDate(date))
        const json = await res.json()
        if (res.ok) {

            for(var i = 0; i < json.length; i++){
                json[i].start = new Date(json[i].start)
                json[i].end = new Date(json[i].end)
            }
            setEvents(json)
            if (!initialLoad.current){
                initialLoad.current = true
            }
        }
    }, [date])

    useEffect(() => {
        let nextMonth = date.getMonth()
        if (prevMonth.current !== nextMonth || !initialLoad.current){
            getEvents()
            prevMonth.current = nextMonth
        }
    }, [date, getEvents])

    const handleSelectSlot = useCallback(
        ({ start, end }) => {
            setNewEvent({
                start: start,
                end: end
            });
            setOpenAdd(true)
        },[]
    )

    const handleSelectEvent = useCallback(
        (event) => {
            if(event.id !== selectedEventId){
                setSelectedEventId(event.id)
            } else{
                setSelectedEventId(null)
            }
        },[selectedEventId]
    )

    const eventPropGetter = (event, start, end, isSelected) => {
        if (event.company_member_id !== account.id){
            return { style: {
                        backgroundColor: isSelected ? PALETTE.secondary.dark : PALETTE.secondary.main,
                        outlineColor: PALETTE.secondary.dark,
                }}
        }
    }

    const handleClose = () =>{
        setOpenAdd(false)
    }

    return (
        <Stack
            direction="row"
            spacing={3}
            sx={{flex : 1}}
        >
            <CustomPaper
                title="Kalender"
                sx={{ flex : 1 }}
            >
                <BigCalendar
                    date={date}
                    culture={"de"}
                    localizer={localizer}
                    events={events}
                    messages={messages}
                    startAccessor="start"
                    endAccessor="end"
                    // style={{ height: '100%',  }}
                    onSelectEvent={handleSelectEvent}
                    onSelectSlot={handleSelectSlot}
                    eventPropGetter={eventPropGetter}
                    selectable
                    onNavigate={onNavigate}
                    showMultiDayTimes={true}
                    dayLayoutAlgorithm={'no-overlap'}
                />
            </CustomPaper>
            <Stack>
                {selectedEventId &&
                    <SelectedEvent
                        selectedEventId={selectedEventId}
                        setSelectedEventId={setSelectedEventId}
                        getEvents={getEvents}
                    />
                }
            </Stack>
            <AddEventDialog
                open={openAdd}
                onClose={handleClose}
                newEvent={newEvent}
                getEvents={getEvents}
            />
        </Stack>
    )
}

export default Calendar