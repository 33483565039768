import { useEffect, useRef, useState } from "react";

const delay = 150

const useWidth = () => {
    const ref = useRef();
    const [ width, setWidth ] = useState(0);
    useEffect(() => {
        let timeout = false
        const updateWidth = () => {
            setWidth(0)
            clearTimeout(timeout)
            timeout = setTimeout(() => {
                const newWidth = ref.current.clientWidth
                setWidth(newWidth)
            }, delay)
        }
        updateWidth()
        window.addEventListener('resize', updateWidth)
        return () => { window.removeEventListener('resize', updateWidth) }
    }, [])

    return [ width, ref ]
}

export default useWidth