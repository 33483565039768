import { Stack, Box, Typography } from '@mui/material'
import { Group, Business, Lock, LockOpen } from "@mui/icons-material"


const ProjectImageIconStack = ({ img, members, companies, official=true }) =>{

    return(
        <Stack>
            <Box
                component="img"
                src={img}
                alt="not found"
                sx={{
                    p: 2,
                    height: '100%',
                    width: 250,
                }}
            />
            <Stack
                direction="row"
                justifyContent="center"
                spacing={3}
                sx={{ mb: 2, color: 'background.light' }}
            >
                {official ? <Lock sx={{ color: 'background.light20'}}/> : <LockOpen sx={{ color: 'background.light20'}}/>}
                <Stack direction="row" alignContent="center" spacing={0.5}>
                    <Typography variant="value">{members}</Typography>
                    <Group sx={{ color: 'background.light20'}}/>
                </Stack>
                <Stack direction="row" alignContent="center" spacing={0.5}>
                    <Typography variant="value">{companies}</Typography>
                    <Business  sx={{ color: 'background.light20'}}/>
                </Stack>
            </Stack>
        </Stack>
    )
}

export default ProjectImageIconStack