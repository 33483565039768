import { useEffect, useState, useRef } from 'react'
import { useLocation, Link } from 'react-router-dom'

import { Button, CircularProgress, Paper, Stack, Typography } from '@mui/material'
import ThumbUp from '@mui/icons-material/ThumbUp'
import ErrorIcon from '@mui/icons-material/Error'

import LogoFullWhite from 'components/Widgets/Logos/LogoFullWhite'

import { getCookie } from '../../utils/getCookie'


const ActivateAccount = () => {
    const location = useLocation()
    const token = new URLSearchParams(location.search).get('token')
    const [state, setState] = useState('checking')
    const checked = useRef(false)

    useEffect(() => {
        if (!token?.length || checked.current) return
        checked.current = true;
        (async () => {
            const res = await fetch('/account/activate', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'X-CSRFToken': getCookie('csrftoken')
                },
                body: JSON.stringify({
                    token: token
                })
            })
            setState(res.ok ? 'success' : 'failed')
        })()
    }, [token])

    return (
        <Stack
            spacing={2}
            sx={{
                backgroundColor: 'background.main',
                justifyContent: 'center',
                alignItems: 'center',
                height: '100vh',
                p:1
            }}
        >
            <LogoFullWhite width='100%' height={80}/>
            <Paper
                sx={{ p: 5 }}
            >
                {state === 'checking' ?
                    <CircularProgress />
                    :
                    <Stack
                        justifyContent='center'
                        alignItems="center"
                        spacing={1}
                    >
                        {state === 'success' ?
                            <>
                                <ThumbUp color="primary" fontSize='large'/>
                                <Typography variant="overline" sx={{ color: 'success.main'}}>
                                    Ihr Account ist jetzt aktiviert!
                                </Typography>
                                <Link to='/login' style={{ textDecoration: 'none' }}>
                                    <Button variant='outlined' color='primary'>
                                        Login
                                    </Button>
                                </Link>
                            </>
                            :
                            <>
                                <ErrorIcon sx={{ color: 'error.main' }} fontSize='large' />
                                <Typography variant="overline" sx={{ color: 'error.main' }}>
                                    Aktivierung fehlgeschlagen
                                </Typography>
                            </>
                        }
                    </Stack>
                }
            </Paper>
        </Stack>
    )
}

export default ActivateAccount
