import { useState, useEffect, useCallback } from 'react';
import { useForm } from 'react-hook-form';

import {
    Box,
    Stack,
    Button,
    Dialog,
    DialogTitle,
    DialogContent,
    DialogContentText,
    DialogActions,
    Typography,
    ListItem,
    TextField,
    InputAdornment,
    Divider,
} from '@mui/material';

import { getCookie } from '../../../../../utils/getCookie';
import useStyledSnackbar from '../../../../../hooks/useStyledSnackbar';

import ControlledTextField from '../../../../Widgets/ControlledInputs/ControlledTextField';
import ControlledSelect from '../../../../Widgets/ControlledInputs/ControlledSelect';

import MaterialInfoStack from './MaterialInfoStack';
import useSuppliers from 'hooks/useSuppliers';
import SendUrlByMailSupplierRequestDialog from './SendUrlByMailSupplierRequestDialog';



const SupplierRequestDialog = (props) =>{

    const { open, onClose,  materials, setSelectedMaterials, sub_project_id, project_id, getValues } = props
    const { enqueueSnackbar } =  useStyledSnackbar()
    const [openDialog, setOpenDialog] = useState(false)
    const [res, setRes] = useState({
        url: '',
        carrier_id: '',
        supplier_contact_person: '',
        supplier_email: '',
        company_name: '',
        creator: '',
    })

    const [materials_, setMaterials_] = useState([]);
    const { suppliers } = useSuppliers()

    const { handleSubmit, control, reset, formState: { errors, isDirty, isValid }} = useForm({
        mode: 'onChange',
        defaultValues: {
            supplier: '',
            place_of_delivery: '',
            annotation: '',
        }
    })

    const handleOnClose = useCallback(() =>{
        reset()
        setSelectedMaterials([])
        onClose()
    }, [reset, onClose])

    const handleValueChange = (e, idx, key) => {
        let data_ = [...materials]
        data_[idx][key] = e.target.value
        setMaterials_(data_);
    };

    const handleSupplierRequest = async(data) =>{
        const json = JSON.stringify({
            ...data,
            request_materials: materials_
        })
		const res = await fetch('/api/project/' + project_id  + '/sub-project/'+ sub_project_id +'/material/supplier/request', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'X-CSRFToken': getCookie('csrftoken')
            },
            body: json
        })
        const _data = await res.json()
        if(res.ok){
            setRes(_data)
            setOpenDialog(true)
            getValues()
            enqueueSnackbar(`Die Anfrage wurde erfolgreich erstellt!`, {
                variant: 'success'
            })
        } else {
            Object.entries(_data).forEach(([field, message]) => {
                enqueueSnackbar(`${field}: ${message} `, {
                    variant: 'error'
                })
            })
        }
    }

    useEffect(()=>{
        let m_ = []
        materials.forEach(m => {
            m.request_value =  ''
            m_.push(m)
        });
        setMaterials_(m_)
    },[materials])

    return(
        <Dialog
            open={open}
            onClose={handleOnClose}
            maxWidth="laptop"
            fullWidth
        >
            <DialogTitle>
                {`Material anfragen`}
            </DialogTitle>
            <DialogContent>
                <DialogContentText>
                    Hier kannst du deine Materialien bei deinen Lieferanten für den Projektauftrag anfragen.
                </DialogContentText>
                <Stack
                    spacing={2}
                    component="form"
                    id='order-form'
                    onSubmit={handleSubmit(handleSupplierRequest)}
                    sx={{ mt:2, mb: 2 }}
                >
                    <Box sx={{ width: "100%", height: 380, overflow: 'auto'}}>
                        {materials.map((material, idx) => {
                            return(
                                <Box key={`material-list-item${idx}`}>
                                    <ListItem>
                                        <Stack
                                            direction='row'
                                            justifyContent="space-between"
                                            alignItems='center'
                                            width="100%"
                                            gap={2}
                                        >
                                            <MaterialInfoStack material={material}/>
                                            <Stack
                                                direction={'row'}
                                                justifyContent='center'
                                                alignItems="center"
                                                spacing={2}
                                            >
                                                <TextField
                                                    placeholder="0"
                                                    type="number"
                                                    label="Menge"
                                                    size="small"
                                                    InputProps={{
                                                        endAdornment: <InputAdornment position="end">{material.unit?.split('/')[1]}</InputAdornment>,
                                                    }}
                                                    sx={{ width: 200}}
                                                    value={material.request_value}
                                                    onChange={(e) => handleValueChange(e, idx, 'request_value')}
                                                />
                                            </Stack>
                                        </Stack>
                                    </ListItem>
                                    {materials.length > idx + 1 && <Divider flexItem/>}
                                </Box>
                            )
                        })}
                    </Box>
                    <Stack
                        direction='row'
                        spacing={2}
                    >
                        <ControlledSelect
                            control={control}
                            errors={errors}
                            rules={{required: true}}
                            name="supplier"
                            label="Lieferant"
                            items={suppliers}
                        />
                        <ControlledTextField
                            control={control}
                            errors={errors}
                            name="place_of_delivery"
                            label="Lieferort (Optional)"
                            rules={{required: false}}
                        />
                    </Stack>
                    <ControlledTextField
                        control={control}
                        errors={errors}
                        name="annotation"
                        label="Anmerkung zur Anfrage"
                        rules={{required: false}}
                        rows={3}
                        multiline
                    />
                </Stack>
                <Box>
                    <Typography variant="text_s">{`${materials.length} Artikel ausgewählt`}</Typography>
                </Box>
            </DialogContent>
            <DialogActions>
                <Button
                    color="secondary"
                    onClick={handleOnClose}
                >
                    Schließen
                </Button>
                <Button
                    type="submit"
                    form="order-form"
                    disabled={!isDirty || !isValid}
                >
                    Anfragen
                </Button>
            </DialogActions>
            <SendUrlByMailSupplierRequestDialog
                open={openDialog}
                onClose={()=> setOpenDialog(false)}
                res={res}
            />
        </Dialog>
    )
}

export default SupplierRequestDialog