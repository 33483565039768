import { Badge, Card, CardActionArea, Stack, Typography } from "@mui/material"


const CustomIconPaperButton = ({ label, sublabel, sublabelColor="primary.main" ,icon, count, onClick, disabled=false }) =>{

    return(
        <Card
            sx={{
                width: '100%',

                opacity: disabled ? 0.6 : 1
            }}
        >
            <CardActionArea
                onClick={onClick}
                disabled={disabled}
                sx={{height: '100%'}}
            >
                <Stack
                    justifyContent='center'
                    alignItems='center'
                    gap={1.5}
                    sx={{
                        padding: 2,
                        height: '100%',
                    }}
                >
                    <Badge
                        color="primary"
                        badgeContent={count}
                        max={99999}
                    >
                        {icon}
                    </Badge>
                    <Stack
                        justifyContent='center'
                        alignItems='center'
                        gap={0.2}
                    >
                        <Typography variant="caption_m" sx={{lineHeight: 1.1}} color="background.light20">{label}</Typography>
                        {sublabel && <Typography variant="text_s" color={sublabelColor}>{sublabel}</Typography>}
                    </Stack>
                </Stack>
            </CardActionArea>
        </Card>
    )
}

export default CustomIconPaperButton