import { Box, Link } from "@mui/material";



const PlayStoreLink = ({ height="100%" }) => {
    return (
        <Link
            height={height}
            href='https://play.google.com/store/apps/details?id=com.conmeet.conmeetapp&pcampaignid=pcampaignidMKT-Other-global-all-co-prtnr-py-PartBadge-Mar2515-1'
            target="_blank"
            rel="noreferrer"
        >
            <Box height={height}>
                <img
                    height="100%"
                    alt='Jetzt bei Google Play'
                    src='https://play.google.com/intl/en_us/badges/static/images/badges/de_badge_web_generic.png'
                />
            </Box>
        </Link>
    );
}

export default PlayStoreLink;