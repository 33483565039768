import {
    IconButton,
    Paper,
} from "@mui/material"

import CustomDataGrid from "../../CustomDataGrid";
import Toolbar from "../Toolbar";
import {DataGridPro, GridActionsCellItem} from "@mui/x-data-grid-pro";
import {Cancel, Delete, Edit, Save} from "@mui/icons-material";
import ConfirmDialog from "../../../ConfirmDialog";


const PaperDataGrid = (props) =>{

    const {
        apiRef,
        title,
        subtitle,
        rows,
        columns,
        pageSize,
        onPageSizeChange,
        onPageChange,
        rowCount,
        loading,
        actionBar,
        errors={},
        hideSearch,
        onDeleteRow,
        onSaveRow,
        getConfirmMessage,
    } = props



    return (
        <Paper>
            <CustomDataGrid
                rows={rows}
                columns={columns}
                apiRef={apiRef}
                Component={DataGridPro}
                loading={loading}
                errors={errors}
                Toolbar={Toolbar}
                toolbarProps={{
                    apiRef: apiRef,
                    title: title,
                    subtitle: subtitle,
                    actionBar: actionBar,
                    hideSearch: hideSearch
                }}
                initialPageSize={25}
                pageSize={pageSize}
                onPageSizeChange={onPageSizeChange}
                onPageChange={onPageChange}
                rowCount={rowCount}
                onDeleteRow={onDeleteRow}
                onSaveRow={onSaveRow}
                getConfirmMessage={getConfirmMessage}
            />
        </Paper>
    )
}

export default PaperDataGrid