import { Grid, Stack } from "@mui/material";

import ChangePassword from './changePassword';
import ChangeUser from './changeUser';
import ChangeProfile from './changeProfile'
import ChangeProfileAvatar from './changeProfileAvatar'
import CustomPage from "../../Widgets/CustomPage";
import DeleteAccount from "./DeleteAccount";

const Account = () => {
    return(
        <CustomPage title='Kontoeinstellungen'>
            <Grid container spacing={3}>
                <Grid item mobile={12} desktop={7}>
                    <Stack gap={3}>
                        <ChangeUser />
                        <ChangePassword />
                        <ChangeProfile />
                    </Stack>
                </Grid>
                <Grid item mobile={12} desktop={5}>
                    <Stack gap={3}>
                        <ChangeProfileAvatar />
                        <DeleteAccount />
                    </Stack>
                </Grid>
            </Grid>
        </CustomPage>
    )
}

export default Account
