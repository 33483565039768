import { useState } from 'react'

import { TextField, ListItem, ListItemAvatar, Avatar, ListItemText, Divider, Stack } from '@mui/material'
import { Send }from '@mui/icons-material';

import useDebounce from '../../../hooks/useDebounce';

import { getCookie } from '../../../utils/getCookie'

const CompanySearch = ({ handleSelection, autoFocus=false, sx }) => {
    const [companies, setCompanies] = useState([])

    const fetchCompanies = async (data) => {
        if (!data) {
            setCompanies([])
            return
        }
        const body = JSON.stringify({
            search: data,
        })
        try {
            const res = await fetch('/api/company/search', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'X-CSRFToken': getCookie('csrftoken')
                },
                body: body
            })
            const json = await res.json()
            if (res.ok) {
                setCompanies(json)
                console.log(json)
            }
        } catch (error) {
            console.error(error)
        }
    }

    const debouncedFetchCompanies = useDebounce(fetchCompanies)

    return (
        <Stack sx={sx}>
            <TextField
                onChange={(e)=> (debouncedFetchCompanies(e.target.value))}
                label="Name des Unternehmens"
                variant="outlined"
                fullWidth
                autoFocus={autoFocus}
            />
            <Stack sx={{overflow: 'auto'}} >
                {companies.map((company, idx)=>{
                        return(
                            <div key={company.id}>
                                <ListItem
                                    button
                                    dense={true}
                                    onClick={() => handleSelection(company)}
                                    sx={{ pl: 0, pr: 0, pt: 1, pb: 1 }}
                                >
                                    <ListItemAvatar size="small">
                                        <Avatar
                                            variant="rounded"
                                            sx={{ bgcolor: 'primary.main'}}
                                        >
                                            <Send />
                                        </Avatar>
                                    </ListItemAvatar>
                                    <ListItemText primary={company.name} secondary={company.industry}/>
                                </ListItem>
                                {companies.length - 1 !== idx && <Divider />}
                            </div>
                        )
                    })
                }
            </Stack>
        </Stack>
    )
}

export default CompanySearch